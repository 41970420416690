/* ------------------------------------*\
    #UPLOAD
\*------------------------------------ */

/**
 * Upload component which utilizes the native `<input type="file">` element.
 * It’s prepared to be used with drag-and-drop JS extensions in providing
 * a `.has-mouseover` state class when a file is dragged upon the upload
 * container.
 */

$upload-background:                                 rgba($COLOR-HIGHLIGHT, 0.05) !default;
$upload-background-hover:                           false !default;
$upload-background-hover-color:                     $COLOR-WHITE !default;
$upload-padding-vertical:                           $GLOBAL-SPACING-UNIT !default;
$upload-padding-horizontal:                         $GLOBAL-SPACING-UNIT !default;
$upload-border-width:                               2px !default;
$upload-border-style:                               dashed !default;
$upload-border-color:                               $COLOR-HIGHLIGHT !default;
$upload-border-width-hover:                         null !default;
$upload-border-color-hover:                         $upload-border-color !default;
$upload-border-width-mouseover:                     null !default;
$upload-border-color-mouseover:                     $upload-border-color-hover !default;
$upload-border:                                     $upload-border-width $upload-border-style $upload-border-color !default;
$upload-border-radius:                              0 !default;
$upload-border-width-active:                        null !default;
$upload-border-style-active:                        null !default;
$upload-border-color-active:                        null !default;
$upload-border-active:                              $upload-border-width-active $upload-border-style-active $upload-border-color-active !default;
$upload-description-spacing:                        $GLOBAL-SPACING-UNIT-SMALL !default;
$upload-description-supplementary-text-spacing:     0 !default;
$upload-description-text-size:                      $FS0 !default;
$upload-description-text-font-weight:               $GLOBAL-FONT-WEIGHT-BOLD !default;
$upload-description-text-spacing-bottom:            $GLOBAL-SPACING-UNIT-XSMALL !default;
$upload-description-text-color:                     inherit !default;
$upload-description-text-supplementary-font-size:   $FS-1 !default;
$upload-description-text-supplementary-text-color:  $COLOR-SUBTLE !default;
$upload-description-text-supplementary-font-weight: $GLOBAL-FONT-WEIGHT-REGULAR !default;
$upload-content-alignment:                          center !default;
$upload-icon:                                       get-icon(semantic-upload) !default;
$upload-icon-size:                                  icon-size(medium) !default;
$upload-icon-color:                                 inherit !default;
$upload-icon-spacing-bottom:                        $GLOBAL-SPACING-UNIT-SMALL !default;
$upload-mouseover-background:                       false !default;
$upload-mouseover-background-color:                 transparent !default;
$upload-mouseover-icon:                             get-icon(semantic-upload) !default;
$upload-mouseover-icon-size:                        $upload-icon-size !default;
$upload-mouseover-icon-color:                       $COLOR-HIGHLIGHT !default;
$upload-mouseover-text-size:                        $FS0 !default;
$upload-mouseover-font-weight:                      $GLOBAL-FONT-WEIGHT-REGULAR !default;
$upload-mouseover-text-color:                       inherit !default;
$upload-loader-text-size:                           $FS0 !default;
$upload-loader-text-spacing-top:                    $GLOBAL-SPACING-UNIT-SMALL !default;
$upload-loader-text-color:                          $COLOR-INACTIVE !default;
$upload-disabled-background:                        $upload-background !default;
$upload-disabled-border-color:                      $upload-border-color !default;
$upload-disabled-icon:                              get-icon(semantic-warning) !default;
$upload-disabled-icon-size:                         $upload-icon-size !default;
$upload-disabled-icon-color:                        $COLOR-INACTIVE !default;
$upload-disabled-text-size:                         $FS0 !default;
$upload-disabled-font-weight:                       $GLOBAL-FONT-WEIGHT-REGULAR !default;
$upload-disabled-text-color:                        $upload-disabled-icon-color !default;
$upload-disabled-text-spacing-bottom:               $GLOBAL-SPACING-UNIT-XSMALL !default;
$upload-disabled-text-supplementary-text-size:      $FS-2 !default;
$upload-disabled-text-supplementary-text-color:     $COLOR-INACTIVE !default;

/**
 * [1] Reset default `<label>` spacing.
 * [2] Reference for the mouseover element.
 * [3] Disable the input (i.e. upload function) when loading or disabled.
 * [4] Hide original `<input>` but keep it accessible.
 * [5] Just hide visually, so the height of the container does not change.
 * [6] Create a padding for the mouseover element by using the calc function
 *     for the width property. Since this element is positioned `absolute`,
 *     a width of `100%` wouldn’t work. That’s why we subtract the the padding
 *     value off of both sides.
 * [7] Center the state elements inside the upload container.
 *     Avoids layout shift by positioning manually (see [5]).
 * [8] Override the buttons default transition so it doesn’t look weird.
 * [9] It would work to omit the `left` declaration, however it is advised to
 *     always apply a `left` (or `right`) value to an `absolute` positioned
 *     element. A value of `0` wouldn’t work correctly, so we use the padding
 *     value instead.
 * [10] Place `.c-upload__input` over the entire upload component to allow drag
 *      and drop of files onto the file input field. This is only done when no
 *      hovering takes place to allow interacting with buttons (or other
 *      elements) inside the component.
 * [11] Disabled variant styles for browser which do not support `:has` yet.
 */
.c-upload {
    position: relative; /* [2] */
    display: block;
    margin-bottom: 0; /* [1] */
    padding: $upload-padding-vertical $upload-padding-horizontal;
    text-align: $upload-content-alignment;
    background-color: $upload-background;
    cursor: pointer;

    &::after,
    &::before {
        @include transition();
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: block;
        pointer-events: none;

        @if ($upload-border) {
            border: $upload-border;
        }
        @if ($upload-border-radius != 0) {
            border-radius: $upload-border-radius;
        }

        opacity: 0;
    }

    &::before {
        opacity: 1;
    }

    &::after {
        @if ($upload-border) {
            border-color: $upload-border-color-hover;
            border-width: $upload-border-width-hover;
        }
    }

    &:not(.has-mouseover):hover {
        @if ($upload-background-hover) {
            background-color: $upload-background-hover-color;
        }

        &::after {
            opacity: 1;
        }

        &::before {
            opacity: 0;
        }

    }

    &:focus,
    &:active {
        @if ($upload-border-active) {
            border: $upload-border-active;
        }
    }

    &.has-mouseover {
        @if ($upload-mouseover-background) {
            background-color: $upload-mouseover-background-color;
        }

        @if ($upload-border) {

            &::before {
                opacity: 0;
                border-width: $upload-border-width-mouseover;
            }

            &::after {
                opacity: 1;
                border-color: $upload-border-color-mouseover;
            }

        }

    }

    &.is-loading {

        &,
        .c-upload__input {
            pointer-events: none; /* [3] */
        }

    }

    &.has-mouseover,
    &.is-loading {

        .c-upload__description,
        .c-upload__actions {
            visibility: hidden; /* [5] */
        }

    }

    &:has(.c-upload__input:active) {
        &::before,
        &::after {
            border-style: solid;
        }
    }

    &:has(.c-upload__input[disabled]) {
        background-color: $upload-disabled-background;
        pointer-events: none; /* [3] */

        @if ($upload-border) {
            &::before,
            &::after {
                border-color: $upload-disabled-border-color;
            }
        }

        .c-upload__disabled {
            display: block;
        }

        .c-upload__description,
        .c-upload__actions {
            visibility: hidden; /* [5] */
        }

    }

    // @TODO: Deprecate when browser support for `:has` is sufficient.
    &.is-disabled { /* [11] */
        background-color: $upload-disabled-background;

        @if ($upload-border) {

            &::before,
            &::after {
                border-color: $upload-disabled-border-color;
            }

        }

        &,
        .c-upload__input {
            pointer-events: none;
        }

        .c-upload__disabled {
            display: block;
        }

        .c-upload__description,
        .c-upload__actions {
            visibility: hidden;
        }

    }

}

    .c-upload__input {
        position: absolute; /* [4] */
        z-index: -1; /* [4] */
        width: 0.1px; /* [4] */ /* stylelint-disable-line scale-unlimited/declaration-strict-value */
        height: 0.1px; /* [4] */ /* stylelint-disable-line scale-unlimited/declaration-strict-value */
        opacity: 0; /* [4] */
        overflow: hidden; /* [4] */

        .c-upload:not(:hover) & {
            z-index: 1; /* [10] */
            top: 0; /* [10] */
            left: 0; /* [10] */
            width: 100%; /* [10] */
            height: 100%; /* [10] */
        }
    }

    .c-upload__description {
        display: block;
    }

        .c-upload__description-text {
            @include responsive-font-size(
                $font-size: $upload-description-text-size
            );
            display: block;
            margin-bottom: 0;
            font-weight: $upload-description-text-font-weight;
            color: $upload-description-text-color;

            &:not(:last-child) {
                margin-bottom: $upload-description-text-spacing-bottom;
            }

        }

        .c-upload__description-supplementary-text {
            @include responsive-font-size($upload-description-text-supplementary-font-size);
            display: block;
            margin-bottom: $upload-description-supplementary-text-spacing;
            font-weight: $upload-description-text-supplementary-font-weight;
            color: $upload-description-text-supplementary-text-color;
        }

    .c-upload__icon {
        @if ($upload-icon) {
            @include icon-font($defaults: null);
            @include font-size(
                $font-size: $upload-icon-size,
                $line-height: 1
            );
            display: block;
            margin-bottom: $upload-icon-spacing-bottom;
            color: $upload-icon-color;

            &::before {
                content: $upload-icon;
            }

        } @else {
            display: none;
        }
    }

    .c-upload__mouseover,
    .c-upload__loader,
    .c-upload__disabled {
        position: absolute; /* [7] */
        top: 50%; /* [7] */
        left: $upload-padding-horizontal; /* [9] */
        display: block;
        width: calc(100% - (#{$upload-padding-horizontal * 2})); /* [6] */
        transform: translateY(-50%); /* [7] */
    }

    .c-upload__mouseover {
        color: $upload-mouseover-text-color;

        .c-upload:not(.has-mouseover) & {
            display: none;
        }

        > *:last-child {
            margin-bottom: 0;
        }

    }

        .c-upload__mouseover-icon {
            @include icon-font(
                $display: block,
                $line-height: null,
            );
            @include font-size(
                $font-size: $upload-mouseover-icon-size
            );
            color: $upload-mouseover-icon-color;

            &::before {
                content: $upload-mouseover-icon;
            }

        }

        .c-upload__mouseover-text {
            @include responsive-font-size(
                $font-size: $upload-mouseover-text-size
            );
            display: block;
            font-weight: $upload-mouseover-font-weight;
        }

    .c-upload__loader {
        display: block;

        .c-upload:not(.is-loading) & {
            display: none;
        }

    }

    .c-upload__loader-text {
        @include responsive-font-size(
            $font-size: $upload-loader-text-size
        );
        display: block;
        margin-top: $upload-loader-text-spacing-top;
        color: $upload-loader-text-color;
    }

    .c-upload__disabled {
        display: none;
        color: $upload-disabled-text-color;
    }

        .c-upload__disabled-icon {
            @include icon-font($line-height: null);
            @include font-size(
                $font-size: $upload-disabled-icon-size,
                $line-height: 1
            );
            color: $upload-disabled-icon-color;

            &::before {
                content: $upload-disabled-icon;
            }

        }

        .c-upload__disabled-text {
            @include responsive-font-size(
                $font-size: $upload-disabled-text-size
            );
            display: block;
            margin-bottom: 0;
            font-weight: $upload-disabled-font-weight;

            &:not(:last-child) {
                margin-bottom: $upload-disabled-text-spacing-bottom;
            }

        }

        .c-upload__disabled-supplementary-text {
            @include responsive-font-size(
                $font-size: $upload-disabled-text-supplementary-text-size
            );
            display: block;
            margin-bottom: 0;
            color: $upload-disabled-text-supplementary-text-color;
        }

    .c-upload__actions {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: $upload-description-spacing;
    }

    .c-upload__button {
        margin-bottom: 0;

        .has-mouseover & {
            transition: 0s; /* [8] */
        }

    }
