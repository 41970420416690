// /* ------------------------------------*\
//     #SPACING-CONTAINER
// \*------------------------------------ */

/**
 * [1] Only overwrite the bottom and top spacing if a specific
 *     `$spacing-between-modules` is defined and it is different from
 *     `$spacing-vertical`.
 * [2] Raise specificity to overwrite `:first-child` and `:last-child` selectors
 *     of spacing-container mixin.
 */

// Makes the element a module container. Make sure that the modules are direct
// children of this container. Modules are aligned vertically.
@mixin spacing-container($spacing-vertical, $spacing-horizontal: null, $spacing-between-modules: null) {

    > * {
        @include responsive-property(
            ("padding-top",    if($spacing-between-modules, $spacing-between-modules, $spacing-vertical)),
            ("padding-right",  if($spacing-horizontal, $spacing-horizontal, $spacing-vertical)),
            ("padding-bottom", if($spacing-between-modules, $spacing-between-modules, $spacing-vertical)),
            ("padding-left",   if($spacing-horizontal, $spacing-horizontal, $spacing-vertical))
        );

        @if ($spacing-between-modules and $spacing-between-modules != $spacing-vertical) { /* [1] */

            &:first-child {
                @include responsive-property("padding-top", $spacing-vertical);
            }

            &:last-child {
                @include responsive-property("padding-bottom", $spacing-vertical);
            }

        }
    }

}

// Removes the outer paddings for a component module (e.g. the component's
// header, body, footer).
// If `$between-modules` is true, also removes spacing between modules.
@mixin spacing-item-flush($between-modules: false) {
    @if ($between-modules) {
        &#{&} {
            padding: 0;/* [2] */
        }
    } @else {
        padding-right: 0;
        padding-left: 0;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}
