/* ------------------------------------*\
    #FOCUS-POINTS
\*------------------------------------ */

// Provide focus points for images using `object-fit: cover` to chose from. Make
// sure to adhere to `[y-axis]-[x-axis]` notation.
$focus-points: (
    "top-center",
    "top-left",
    "top-right",
    "center-left",
    "center-center",
    "center-right",
    "bottom-center",
    "bottom-left",
    "bottom-right",
) !default;

@each $_focus-point in $focus-points {
    $_position: str-replace($_focus-point, "-", " ");

    .u-focus-point-#{$_focus-point} {
        @include object-fit(
            $object-position: #{$_position},
            $important: true
        );
    }

}
