// /* ------------------------------------*\
//     #SPINNER (Settings)
// \*------------------------------------ */

$spinner-style:              circle !default;
$spinner-size:               64px !default;
$spinner-size-small:         24px !default;
$spinner-shadow:             $DROP-SHADOW-XL !default;

$spinner-border-width:       8px !default;
$spinner-border-width-small: 4px !default;
$spinner-border-color:       $COLOR-ON-SURFACE-100 !default;

$spinner-timing:             ease-in-out !default;

$spinner-text-color:         $COLOR-BASE-TEXT !default;
$spinner-text-border-radius: $GLOBAL-BORDER-RADIUS !default;
