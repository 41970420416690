/* ------------------------------------*\
    #CUSTOM-PROGRESS (Scope)
\*------------------------------------ */

@include media-query(">#{$custom-progress-description-item-breakpoint}") {

    .c-custom-progress__description__item--end {
        // Set alignment for multiple text elements.
        --description-list-text-align: right;

        // Enable text alignmet based on layout mode.
        --if-description-list-split-is-wide: ; /* stylelint-disable-line */
    }

}
