// /* ------------------------------------*\
//     #CHECKBOX (Settings)
// \*------------------------------------ */

$checkbox-size:                              18px !default;
$checkbox-radius:                            2px !default;
$checkbox-spacing:                           $GLOBAL-SPACING-UNIT-XSMALL !default;

$checkbox-label-text-size:                   $FS-1 !default;
$checkbox-label-text-font-weight:            $GLOBAL-INPUT-LABEL-FONT-WEIGHT !default;
$checkbox-label-text-color-disabled:         $GLOBAL-INPUT-LABEL-COLOR-DISABLED !default;

$checkbox-background-color:                  transparent !default;
$checkbox-background-color-checked:          $COLOR-BRAND !default;
$checkbox-background-color-checked-hover:    $COLOR-BRAND-HOVER !default;
$checkbox-background-color-error-checked:    $COLOR-ALERT !default;
$checkbox-background-color-disabled:         $checkbox-background-color !default;
$checkbox-background-color-disabled-checked: $COLOR-INACTIVE !default;

$checkbox-icon-size:                         $checkbox-size !default;
$checkbox-icon-color:                        $COLOR-WHITE !default;

$checkbox-border-width:                      2px !default;
$checkbox-border-color:                      $COLOR-ON-SURFACE-700 !default;
$checkbox-border-color-hover:                $COLOR-ON-SURFACE-900 !default;
$checkbox-border-color-focus:                $checkbox-border-color !default;
$checkbox-border-color-checked:              $checkbox-background-color-checked !default;
$checkbox-border-color-disabled:             $COLOR-INACTIVE !default;
