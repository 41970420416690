/* ------------------------------------*\
   #CARD
\*------------------------------------ */

$card-padding-horizontal:                          $GLOBAL-SPACING-UNIT !default;
$card-padding-vertical:                            $GLOBAL-SPACING-UNIT !default;
$card-module-spacing:                              $GLOBAL-SPACING-UNIT-SMALL !default;
$card-shadow:                                      $CARD-SHADOW !default;
$card-border:                                      null !default;
$card-border-radius:                               null !default;
$card-background-color:                            $COLOR-BASE-BACKGROUND !default;
$card-header-extra-spacing-bottom:                 null !default;
$card-image-extra-spacing-bottom:                  null !default;
$card-title-spacing-bottom:                        0 !default;
$card-title-font-size:                             $FS2 !default;
$card-title-font-weight:                           $GLOBAL-FONT-WEIGHT-BOLD !default;
$card-title-text-color:                            null !default;
$card-subtitle-font-family:                        null !default;
$card-subtitle-font-size:                          null !default;
$card-subtitle-font-weight:                        null !default;
$card-subtitle-text-color:                         null !default;
$card-text-alignment:                              left !default;
$card-body-text-color:                             null !default;
$card-body-extra-spacing-bottom:                   null !default;

/**
 * @TODO icons
 * - order
 * - naming (plural vs singular)
 */

$card-icon-size:                                   icon-size(large) !default;
$card-icon-color:                                  $COLOR-BRAND !default;
$card-icon-extra-spacing-bottom:                   null !default;
$card-states-icon-offset-top:                      null !default;
$card-states-icon-size:                            icon-size(large) !default;
$card-state-icon-is-aligned-to-left:               false !default;
$card-state-icon-left-offset-left:                 $GLOBAL-SPACING-UNIT-SMALL !default;
$card-default-icon-is-aligned-to-right:            false !default;
$card-icon-right-spacing:                          $GLOBAL-SPACING-UNIT-XSMALL !default;
$card-footer-alignment:                            $card-text-alignment !default;
$card-footer-font-size:                            $FS-1 !default;
$card-scrollable-lines-visible:                    14 !default;
$card-scrollable-max-height:                       $GLOBAL-LINE-HEIGHT * $card-scrollable-lines-visible !default;

$card-inactive-opacity:                            $OPACITY-DISABLED !default;
$card-inactive-shadow:                             $CARD-INACTIVE-SHADOW !default;
$card-inactive-background-color:                   null !default;
$card-inactive-border:                             null !default;

$card-divider-spacing:                             $GLOBAL-SPACING-UNIT !default;

$card-expandable-header-breakpoint:                s !default;
$card-expandable-spacing:                          $GLOBAL-SPACING-UNIT !default;
$card-expandable-button-small:                     false !default;
$card-expandable-button-size-small:                "small" !default;
$card-expandable-icon-expanded:                    rotateX(180deg) !default;
$card-expandable-text-alignment:                   $card-text-alignment !default;

// Settings for for Card Responsive Collapse
$card-responsive-collapse-icon-size:               icon-size(small) !default;
$card-responsive-collapse-icon-complexity:         null !default;
$card-responsive-collapse-icon-collapsed:          get-icon(semantic-expand, $card-responsive-collapse-icon-complexity) !default;
$card-responsive-collapse-icon-expanded:           get-icon(semantic-collapse, $card-responsive-collapse-icon-complexity) !default;
$card-responsive-collapse-icon-transform-expanded: null !default;
$card-responsive-collapse-state-expanded:          "[aria-expanded='true']" !default;

// Settings for small viewports for Card Responsive Collapse
$card-responsive-collapse-spacing:                 $GLOBAL-SPACING-UNIT !default;
$card-responsive-collapse-icon-color:              $COLOR-BRAND !default;
$card-responsive-collapse-title-font-size:         $FS3 !default;
$card-responsive-collapse-title-font-weight:       null !default;
$card-responsive-collapse-title-font-family:       null !default;
$card-responsive-collapse-title-spacing-bottom:    0 !default;
$card-responsive-collapse-header-items-direction:  row !default;
$card-responsive-collapse-header-items-align:      space-between !default;
$card-responsive-collapse-header-bottom-spacing:   null !default;
$card-responsive-collapse-spacing-left:            null !default;
$card-responsive-collapse-spacing-right:           null !default;
$card-responsive-collapse-subtitle-spacing:        null !default;
$card-responsive-collapse-subtitle-text-color:     null !default;
$card-responsive-collapse-subtitle-font-size:      $FS0 !default;
$card-responsive-collapse-subtitle-font-family:    null !default;
$card-responsive-collapse-subtitle-font-weight:    null !default;
$card-responsive-collapse-body-font-weight:        null !default;

$card-themes: (
    "alternative": (
        "background": null,
        "border":     null
    )
) !default;

$card-state-themes: (
    "success": (
        "icon-color": map-deep-get($STATUS-THEMES, "success", "icon-color"),
    ),
    "warning": (
        "icon-color": map-deep-get($STATUS-THEMES, "warning", "icon-color"),
    ),
    "error": (
        "icon-color": map-deep-get($STATUS-THEMES, "error", "icon-color"),
    )
) !default;

/**
 * [1] Fill horizontal space of parent.
 * [2] Calculate the space needed of 100% of the content by subtracting the
 *     needed space from the icon.
 * [3] Defensively reset any potential spacing that comes with plain HTML
 *     elements.
 * [4] The logical and therefore reading order of flex items is separate from
 *     the visual order. In this case the order property can allow patterns to
 *     be easily implemented in position needed.
 * [5] Reset ordering set by [4].
 * [6] Prevent too much spacing below below image in IE.
 * [7] To prevent unwanted stacking spacing at the bottom of the body, remove
 *     any unwanted spacing, no matter what element the last element in
 *     `.c-card__body` is.
 * [8] Place footer at the bottom. This only works in context, where the card
 *     is larger than the actual content, e.g. manually set height or
 *     equal-height with other items in a row.
 * [9] Center horizontally.
 * [10] Add additional `padding-right` to the element following `.c-card__icon`
 *      if [14a] the icon is placed on the left for the `.c-card--states`
 *      variant and this is  NOT the `.c-card--states` variant or [14b] if the
 *      icon is placed on the right for all variants.
 * [11] Add a `padding-top` that matches the card's outer padding to the element
 *      which is following `.c-card__icon` if the icon was taken out of the flow
 *      and moved to the right.
 * [12] Add additional `padding-left` for card-header if the state icon
 *      is placed on the left.
 * [13] Remove outer padding for images.
 * [14] Make the image always take up the entire width for cards.
 * [15] Vertical alignment for icon to title.
 * [16] Reset all spacings for `<hr>`.
 * [17] Empty class for special card variant `Card Expandable`.
 * [18] Enable enough spacing when displayed on small devices.
 * [19] Reduce the size of the wrapper accordion icon if wanted.
 * [20] Animate the wrapper accordion icon when parent has class `.is-active`.
 * [21] Set the display context for items inside the expandable body.
 * [22] Move the expandable trigger to the right-hand side.
 * [23] Set custom alignments for expandable cards.
 * [24] Conditionally apply `border-radius` to image if it is the first element
 *      inside the card block.
 *      Removes the need to overwrite e.g. if image is not
 *      the first element.
 * [25] Set spacing for small screens.
 * [26] Hide icon on small screens.
 * [27] Show toggle button only on small screens.
 * [28] Swap icon when `[aria-expanded="true"]`.
 * [29] Toggle visibility of `.c-card__body`.
 * [30] Increase click area by adding a pseudo element to the button for the
 *      Card Responsive Collapse variant.
 * [31] Reduce content spacing for Card Responsive Collapse variant.
 * [32] Remove any unwanted spacing.
 * [33] Hide footer for inactive cards to exclude, in this case, non-interactive
 *      footer content (e.g. buttons, links) for all users and input methods.
 *      We have to remove transitions from the child elements to prevent an
 *      unwanted delay.
 */

.c-card {
    @include spacing-container($card-padding-vertical, $card-padding-horizontal, $card-module-spacing);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-alignment($card-text-alignment);
    width: 100%; /* [1] */
    border: $card-border;
    border-radius: $card-border-radius;
    text-align: $card-text-alignment;
    background-color: $card-background-color;
    color: $card-body-text-color;

    @if ($card-shadow != none) {
        box-shadow: $card-shadow;
    }

    @include vary($loop: $card-themes) {
        border: vary-get("border");
        background-color: vary-get("background");
    }

    > *:not(.c-card__icon):not(.c-price-box) {
        width: 100%;
    }

    &:not(.c-card--image) {
        @if ($card-default-icon-is-aligned-to-right == true) {
            .c-card__header {
                order: -1; /* [4] */

            }

            &.c-card--states {

                .c-card__header {
                    order: 0; /* [5] */
                }

            }
        }

    }

}

    .c-card__image {
        @include spacing-item-flush(); /* [13] */

        flex-grow: 0; /* [6] */
        flex-shrink: 0; /* [6] */
        flex-basis: auto; /* [6] */
        text-align: center; /* [9] */

        + * {
            @if ($card-image-extra-spacing-bottom) {
                @include responsive-property(
                    "padding-top",
                    responsive-property-modify($card-module-spacing, $add: $card-image-extra-spacing-bottom)
                );
            }
        }

        @if ($card-border-radius) {
            /* [24] */
            &:is(:first-child) > img {
                border-top-left-radius: $card-border-radius;
                border-top-right-radius: $card-border-radius;
            }
        }

        > img {
            width: 100%; /* [14] */
        }

    }

    .c-card__icon {
        display: block; // @TODO: check if block + flexbox properties
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: $card-icon-size;

        @if ($card-default-icon-is-aligned-to-right == true) {
            position: absolute;
            top: 0;
            right: 0;
        }

        // Adding states modifier classes.
        @include vary($create: custom, $loop: $card-state-themes) {
            .c-card--states.c-card--states-#{$vary-loop-entity} & .c-icon {
                color: vary-get("icon-color");
            }
        }

        .c-icon {
            @include font-size(
                $font-size: $card-icon-size,
                $line-height: 1
            );
            color: $card-icon-color;
        }

        + * {
            @if ($card-icon-extra-spacing-bottom) {
                @include responsive-property(
                    "padding-top",
                    responsive-property-modify($card-module-spacing, $add: $card-icon-extra-spacing-bottom)
                );
            }

            @mixin add-more-padding-right() {
                @include responsive-property(
                    "padding-right",
                    responsive-property-modify($card-padding-horizontal, $add: $card-icon-size + $card-icon-right-spacing)
                ); /* [10] */
            }

            @if ($card-default-icon-is-aligned-to-right) {
                @include responsive-property("padding-top", $card-padding-vertical); /* [11] */

                @if ($card-state-icon-is-aligned-to-left) {
                    @at-root .c-card:not(.c-card--states):not(.c-card--responsive-collapse) & {
                        @include add-more-padding-right(); /* [14a] */
                    }
                } @else {
                    @include add-more-padding-right(); /* [14b] */
                }
            }
        }

    }

    .c-card__header {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;

        + * {
            @if ($card-header-extra-spacing-bottom) {
                @include responsive-property(
                    "padding-top",
                    responsive-property-modify($card-module-spacing, $add: $card-header-extra-spacing-bottom)
                );
            }
        }

    }

    .c-card__title {
        @include responsive-font-size($font-size: $card-title-font-size);
        margin-bottom: $card-title-spacing-bottom;
        font-weight: $card-title-font-weight;
        color: $card-title-text-color;
    }

    .c-card__subtitle {
        @if ($card-subtitle-font-size) {
            @include responsive-font-size($font-size: $card-subtitle-font-size);
        }

        margin-bottom: 0; /* [3] */
        font-family: $card-subtitle-font-family;
        font-weight: $card-subtitle-font-weight;
        color: $card-subtitle-text-color;
    }

    .c-card__divider {
        margin-top: $card-divider-spacing;
        margin-bottom: $card-divider-spacing;
        padding-top: 0; /* [16] */
        padding-bottom: 0; /* [16] */
    }

    .c-card__body {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        color: $card-body-text-color;

        @include media-query("<=#{$card-expandable-header-breakpoint}") {

            &.js-is-ready {

                &.is-closed {
                    display: none; /* [29] */
                }
            }
        }

        > *:last-child {
            margin-bottom: 0; /* [7] */
        }

        + * {
            @if ($card-body-extra-spacing-bottom) {
                @include responsive-property(
                    "padding-top",
                    responsive-property-modify($card-module-spacing, $add: $card-body-extra-spacing-bottom)
                );
            }
        }
    }

    .c-card__footer {
        @include responsive-font-size(
            $font-size: $card-footer-font-size
        );

        // flex container
        display: flex;
        justify-content: flex-alignment($card-footer-alignment);

        // flex item
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        margin-top: auto; /* [8] */
    }

    .c-card--states {

        .c-card__icon {
        @if ($card-state-icon-is-aligned-to-left) {
            right: auto;
            margin-top: $card-states-icon-offset-top; /* [15] */
        }

            .c-icon {
                @include font-size(
                    $font-size: $card-states-icon-size,
                    $line-height: 1
                );
            }

        }

        @if ($card-state-icon-is-aligned-to-left) {
            .c-card__header {
                padding-left: ($card-padding-vertical + $card-states-icon-size + $card-state-icon-left-offset-left); /* [12] */
            }
        }

    }

    .c-card--inactive {
        border: $card-inactive-border;
        background-color: $card-inactive-background-color;
        opacity: $card-inactive-opacity;
        box-shadow: $card-inactive-shadow;

        @include vary($loop: $card-themes) {
            border: vary-get("inactive-border");
            background-color: vary-get("inactive-background");
            opacity: vary-get("inactive-opacity");
        }

        .c-card__footer {
            visibility: hidden; /* [33] */

            * {
                transition: none; /* [33] */
            }
        }
    }

    .c-card--scrollable {
        @include scrollable(
            $container-height: $card-scrollable-max-height,
            $bg-color: $card-background-color,
            $themes: $card-themes
        );
    }

    .c-card--expandable { /* [17] */
        align-items: flex-alignment($card-expandable-text-alignment); /* [23] */
        text-align: $card-expandable-text-alignment; /* [23] */

        .c-card__expandable-panel {
            display: none;
        }

        .is-open {
            .c-btn--card-icon {
                transform: $card-expandable-icon-expanded; /* [20] */
            }

            .c-card__expandable-panel {
                display: block;
            }
        }

    }

    .c-card--responsive-collapse {

        @include media-query("<=#{$card-expandable-header-breakpoint}") {

            .c-card__header {
                @include responsive-property("padding-top", $card-responsive-collapse-spacing); /* [25] */
                @include responsive-property("padding-bottom", $card-responsive-collapse-spacing); /* [25] */
                position: relative;
            }

            .c-card__title {
                @include responsive-font-size($font-size: $card-responsive-collapse-title-font-size);
                margin-bottom: $card-responsive-collapse-title-spacing-bottom;
                font-family: $card-responsive-collapse-title-font-family;
                font-weight: $card-responsive-collapse-title-font-weight;
            }

            .c-card__subtitle {
                @include responsive-font-size($font-size: $card-responsive-collapse-subtitle-font-size);
                margin-left: $card-responsive-collapse-subtitle-spacing;
                font-family: $card-responsive-collapse-subtitle-font-family;
                font-weight: $card-responsive-collapse-subtitle-font-weight;
                color: $card-responsive-collapse-subtitle-text-color;
            }

            .c-card__body {
                @include responsive-property("margin-top", -$card-module-spacing); /* [31] */
                padding-top: 0; /* [31] */
                font-weight: $card-responsive-collapse-body-font-weight;
            }

        }

        .c-card__header-items {
            flex-direction: $card-responsive-collapse-header-items-direction;
            justify-content: $card-responsive-collapse-header-items-align;

            > *:first-child {
                margin-top: 0;

                @include media-query("<=#{$card-expandable-header-breakpoint}") {
                    @include responsive-property("padding-right", $card-padding-vertical); /* [25] */
                    margin-bottom: $card-responsive-collapse-header-bottom-spacing;
                }
            }

        }

        .c-card__icon {

            @include media-query("<=#{$card-expandable-header-breakpoint}") {
                display: none; /* [26] */
            }

        }

    }

    .c-card--responsive-collapse__toggle {
        display: none; /* [27] */

        @include media-query("<=#{$card-expandable-header-breakpoint}") {
            display: block;
            padding-right: 0; /* [32] */
            border: none;
            background: transparent;
            color: $card-responsive-collapse-icon-color;

            &::before {
                @include icon-font();
                @include transition();
                @include font-size(
                    $font-size: $card-responsive-collapse-icon-size,
                    $line-height: false,
                );
                content: $card-responsive-collapse-icon-collapsed;
                margin-left: $card-responsive-collapse-spacing-left;
                margin-right: $card-responsive-collapse-spacing-right;
            }

            &::after {
                content: ""; /* [30] */
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                width: 100%;
                height: 100%;
            }

            #{$card-responsive-collapse-state-expanded} & {

                &::before {
                    @include transition();
                    content: $card-responsive-collapse-icon-expanded;
                    transform: $card-responsive-collapse-icon-transform-expanded;
                }

            }

        }

    }

    .c-card__header-items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include media-query("<=#{$card-expandable-header-breakpoint}") {
            flex-direction: column-reverse;
            align-items: flex-start;

            > *:first-child {
                margin-top: $card-expandable-spacing; /* [18] */
            }
        }

    }

    .c-card__expandable {
        display: flex; /* [21] */
        flex-direction: column; /* [21] */
    }

    .c-card__expandable-btn {
        align-self: flex-end; /* [22] */
    }

    @if ($card-expandable-button-small != false) {

        .c-btn--card {
            max-width: map-get($ICON-SQUARE-FULL-SIZES, $card-expandable-button-size-small); /* [19] */
            max-height: map-get($ICON-SQUARE-FULL-SIZES, $card-expandable-button-size-small); /* [19] */
        }

    }
