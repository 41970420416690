// /* ------------------------------------*\
//     #BUTTONS
// \*------------------------------------ */

/**
 * [1] @TODO: Different disabled colors according to styleguide.
 *            Currently not consistent throughout styleguide, needs to be
 *            clarified with brand.
 */

$BUTTON-HEIGHT:                                            44px !default;

$BUTTON-PADDING-VERTICAL:                                  $GLOBAL-SPACING-UNIT-SMALL !default;
$BUTTON-PADDING-HORIZONTAL:                                $GLOBAL-SPACING-UNIT !default;

$BUTTON-ITEM-SPACING-VERTICAL:                             $GLOBAL-SPACING-UNIT-SMALL !default;
$BUTTON-ITEM-SPACING-HORIZONTAL:                           $GLOBAL-SPACING-UNIT-XSMALL !default;

$BUTTON-GROUP-ITEM-SPACING-VERTICAL:                      $BUTTON-ITEM-SPACING-VERTICAL !default;
$BUTTON-GROUP-ITEM-SPACING-HORIZONTAL:                    $BUTTON-ITEM-SPACING-HORIZONTAL !default;

$BUTTON-BACKGROUND-COLOR:                                  $COLOR-BRAND-SECONDARY !default;
$BUTTON-BACKGROUND-COLOR-HOVER:                            $COLOR-BRAND-SECONDARY-HOVER !default;
$BUTTON-BACKGROUND-COLOR-ACTIVE:                           $BUTTON-BACKGROUND-COLOR-HOVER !default;
$BUTTON-BACKGROUND-COLOR-FOCUS:                            $BUTTON-BACKGROUND-COLOR !default;
$BUTTON-BACKGROUND-COLOR-DISABLED:                         $COLOR-ON-SURFACE-100 !default; // [1]

$BUTTON-FONT-COLOR:                                        $COLOR-BASE-TEXT !default;
$BUTTON-FONT-COLOR-DISABLED:                               $COLOR-ON-SURFACE-300 !default; // [1]
$BUTTON-FONT-WEIGHT:                                       $GLOBAL-FONT-WEIGHT-BOLD !default;

$BUTTON-SCALE-ACTIVE:                                      0.95 !default;

$BUTTON-BORDER-RADIUS:                                     50px !default;

$BUTTON-TEXT-SIZE:                                         $FS0 !default;
$BUTTON-LINE-HEIGHT:                                       $GLOBAL-LINE-HEIGHT !default; // ^= `line-height: 1` but needs value in px for various calculations
$BUTTON-LETTER-SPACING:                                    $GLOBAL-FONT-LETTER-SPACING !default;

$BUTTON-ICON-SIZE:                                         "xsmall" !default;

$BUTTON-ICON-INTERACTION-SIZE:                             $BUTTON-HEIGHT !default;
$BUTTON-ICON-INTERACTION-SIZE-SMALL:                       32px !default; // Used for xxsmall icon size

// On light background, e.g. white, gray (default)
$BUTTON-ICON-INTERACTION-BACKGROUND-COLOR-HOVER-ON-LIGHT:  $COLOR-TRANSPARENCY-PRIMARY !default;
// On dark background, e.g. brand, base-color
$BUTTON-ICON-INTERACTION-BACKGROUND-COLOR-HOVER-ON-DARK:   $COLOR-TRANSPARENCY-TERTIARY !default;

$BUTTON-TEXT-ICON-SPACING:                                 $GLOBAL-SPACING-UNIT-XSMALL !default;

// SECONDARY
$BUTTON-SECONDARY-BACKGROUND-COLOR:                        $COLOR-ON-SURFACE-700 !default;
$BUTTON-SECONDARY-BACKGROUND-COLOR-HOVER:                  $COLOR-ON-SURFACE-600 !default;
$BUTTON-SECONDARY-BACKGROUND-COLOR-ACTIVE:                 $BUTTON-SECONDARY-BACKGROUND-COLOR-HOVER !default;
$BUTTON-SECONDARY-BACKGROUND-COLOR-FOCUS:                  $BUTTON-SECONDARY-BACKGROUND-COLOR !default;
$BUTTON-SECONDARY-BACKGROUND-COLOR-DISABLED:               $BUTTON-BACKGROUND-COLOR-DISABLED !default; // [1]

$BUTTON-SECONDARY-TEXT-COLOR:                              $COLOR-WHITE !default;
$BUTTON-SECONDARY-TEXT-COLOR-HOVER:                        $BUTTON-SECONDARY-TEXT-COLOR !default;
$BUTTON-SECONDARY-TEXT-COLOR-ACTIVE:                       $BUTTON-SECONDARY-TEXT-COLOR !default;
$BUTTON-SECONDARY-TEXT-COLOR-DISABLED:                     $BUTTON-FONT-COLOR-DISABLED !default;

// TERTIARY
$BUTTON-TERTIARY-BACKGROUND-COLOR:                         transparent !default;
$BUTTON-TERTIARY-BACKGROUND-COLOR-HOVER:                   $BUTTON-ICON-INTERACTION-BACKGROUND-COLOR-HOVER-ON-LIGHT !default;
$BUTTON-TERTIARY-BACKGROUND-COLOR-ACTIVE:                  $BUTTON-TERTIARY-BACKGROUND-COLOR-HOVER !default;
$BUTTON-TERTIARY-BACKGROUND-COLOR-FOCUS:                   $BUTTON-TERTIARY-BACKGROUND-COLOR !default;



// GHOST
$BUTTON-GHOST-BACKGROUND-COLOR:                            transparent !default;
$BUTTON-GHOST-BACKGROUND-COLOR-HOVER:                      $BUTTON-ICON-INTERACTION-BACKGROUND-COLOR-HOVER-ON-LIGHT !default;
$BUTTON-GHOST-BACKGROUND-COLOR-ACTIVE:                     $BUTTON-GHOST-BACKGROUND-COLOR-HOVER !default;
$BUTTON-GHOST-BACKGROUND-COLOR-FOCUS:                      $BUTTON-GHOST-BACKGROUND-COLOR !default;
$BUTTON-GHOST-BORDER-WIDTH:                                2px !default;
$BUTTON-GHOST-BORDER-STYLE:                                solid !default;
$BUTTON-GHOST-BORDER-COLOR:                                currentColor !default;

$BUTTON-GHOST-FONT-COLOR:                                  $COLOR-ON-SURFACE-700 !default;


// GHOST ON DARK BACKGROUND
$BUTTON-GHOST-ON-DARK-SURFACE-BACKGROUND-COLOR:            transparent !default;
$BUTTON-GHOST-ON-DARK-SURFACE-BACKGROUND-COLOR-HOVER:      $COLOR-ON-SURFACE-700 !default;
$BUTTON-GHOST-ON-DARK-SURFACE-BACKGROUND-COLOR-ACTIVE:     $BUTTON-GHOST-ON-DARK-SURFACE-BACKGROUND-COLOR-HOVER !default;
$BUTTON-GHOST-ON-DARK-SURFACE-BACKGROUND-COLOR-FOCUS:      $BUTTON-GHOST-ON-DARK-SURFACE-BACKGROUND-COLOR !default;
$BUTTON-GHOST-ON-DARK-SURFACE-BORDER-WIDTH:                2px !default;
$BUTTON-GHOST-ON-DARK-SURFACE-BORDER-STYLE:                solid !default;
$BUTTON-GHOST-ON-DARK-SURFACE-BORDER-COLOR:                currentColor !default;

$BUTTON-GHOST-ON-DARK-FONT-COLOR:                          $COLOR-WHITE !default;



// LINK
$BUTTON-LINK-TEXT-COLOR:                                   $COLOR-BASE-TEXT !default;
$BUTTON-LINK-TEXT-COLOR-HOVER:                             $COLOR-BRAND !default;
$BUTTON-LINK-TEXT-COLOR-FOCUS:                             $BUTTON-LINK-TEXT-COLOR !default;
$BUTTON-LINK-TEXT-COLOR-DISABLED:                          $COLOR-ON-SURFACE-200 !default; // [1]

$BUTTON-LINK-ICON-COLOR:                                   $BUTTON-LINK-TEXT-COLOR !default;
$BUTTON-LINK-ICON-COLOR-HOVER:                             $BUTTON-LINK-TEXT-COLOR-HOVER !default;
$BUTTON-LINK-ICON-COLOR-FOCUS:                             $BUTTON-LINK-ICON-COLOR !default;
