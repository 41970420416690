/* ------------------------------------*\
    #SECTION-NAV
\*------------------------------------ */

$section-nav-breakpoint:                         s !default;
$section-nav-breakpoint-value:                   map-get($BREAKPOINTS, $section-nav-breakpoint) !default;
// Magic number to properly define the top margin of the scroll snap area and
// to prevent `.c-section-nav__header` from having a different height
// on smaller viewports when the call-to-action is hidden.
$section-nav-height:                             64px !default;
$section-nav-background-color:                   $COLOR-WHITE !default;
$section-nav-shadow:                             $GLOBAL-BOX-SHADOW !default;
$section-nav-z-index:                            z("section-nav") !default;
$section-nav-responsive-offset-top:              0 !default;
$section-nav-border-top:                         null !default;
$section-nav-backdrop-color:                     $GLOBAL-BACKDROP-BACKGROUND !default;

$section-nav-wrapper-spacing-vertical:           $GLOBAL-SPACING-UNIT-XSMALL !default;
$section-nav-wrapper-spacing-horizontal:         $page-wrap-padding-horizontal !default;

$section-nav-item-text-size:                     $FS-1 !default;
$section-nav-item-font-weight:                   null !default;
$section-nav-item-font-weight-is-active:         $GLOBAL-FONT-WEIGHT-BOLD !default;
$section-nav-item-height:                        $GLOBAL-INPUT-HEIGHT !default;
$section-nav-item-spacing-vertical:              $GLOBAL-SPACING-UNIT-XSMALL !default;
$section-nav-item-spacing-horizontal:            $page-wrap-padding-horizontal !default;

$section-nav-link-border-bottom-width:           null !default;
$section-nav-link-border-bottom-style:           null !default;
$section-nav-link-border-bottom-color:           null !default;
$section-nav-link-border-bottom:                 $section-nav-link-border-bottom-width $section-nav-link-border-bottom-style $section-nav-link-border-bottom-color !default;
$section-nav-link-active-selector:               ".is-active" !default;
$section-nav-link-color-hover:                   null !default;
$section-nav-link-color-hover-is-active:         $COLOR-BRAND !default;
$section-nav-link-has-transition:                false !default;

$section-nav-arrow-color:                        $COLOR-BRAND !default;

$section-nav-icon-size:                          icon-size(xsmall) !default;
$section-nav-icon-prev:                          get-icon(semantic-back) !default;
$section-nav-icon-next:                          get-icon(semantic-forward) !default;
$section-nav-icon-show:                          get-icon(semantic-expand) !default;
$section-nav-icon-hide:                          get-icon(semantic-collapse) !default;

$section-nav-button-spacing-horizontal:          $GLOBAL-SPACING-UNIT-LARGE !default;

$section-nav-active-below-class:                 map-get($INTERSECTION-HOOKS, inside) + map-get($INTERSECTION-HOOKS, below-enter) !default;
$section-nav-active-above-class:                 map-get($INTERSECTION-HOOKS, inside) + map-get($INTERSECTION-HOOKS, above-enter) !default;
$section-nav-active-sticky-class:                map-get($INTERSECTION-HOOKS, above-leave) !default;

$section-nav-transition-duration:                $GLOBAL-TRANSITION-DURATION !default;

$section-nav-label-text-align:                   left !default;
$section-nav-label-color:                        $COLOR-BRAND !default;
$section-nav-label-spacing-horizontal:           $GLOBAL-SPACING-UNIT-SMALL !default;

$section-nav-list-background-color:              $section-nav-background-color !default;
$section-nav-list-spacing-vertical:              $section-nav-wrapper-spacing-vertical !default;
$section-nav-list-border-bottom:                 $section-nav-height solid $section-nav-list-background-color !default;
$section-nav-list-scroll-hint-width:             4rem !default;
$section-nav-list-scroll-hint-gradient-color-1:  rgba($section-nav-list-background-color, 0.1) !default;
$section-nav-list-scroll-hint-gradient-color-2:  $section-nav-list-background-color !default;
$section-nav-list-scroll-hint-gradient-to-left:  linear-gradient(to left, $section-nav-list-scroll-hint-gradient-color-1, $section-nav-list-scroll-hint-gradient-color-2) !default;
$section-nav-list-scroll-hint-gradient-to-right: linear-gradient(to right, $section-nav-list-scroll-hint-gradient-color-1, $section-nav-list-scroll-hint-gradient-color-2) !default;

/**
 * [1]  Move the section nav off-canvas and display it when
 *      the tracked section appears.
 * [2]  Align the buttons inside the section nav header via grid and make
 *      the section label as tall as possible. Hide it on larger viewports.
 * [3]  Align the scrollable list and a possible inside the section nav
 *      container via grid and make the list as tall as possible. Display
 *      the section container on smaller viewports via the `.is-visible` class
 *      and hide it otherwise.
 * [4]  Inherit the grid to proxy the grid behavior. Another possibility would
 *      be `display: content` if we decide against the scroll buttons.
 * [5]  Hide scrollbars as we implement custom paging and scroll hints.
 * [6]  Move the container for small viewports down the stack.
 * [7]  Truncate the content after two lines.
 * [8]  We display the `box-shadow` on certain elements when switching
 *      between viewports and visible state of the scroll list.
 * [9]  Prevent text selection on all clickable elements, especially for
 *      touch-devices, i.e. a `<button>` or `<a>` element.
 * [10] Add a backdrop and span it across the whole viewport.
 * [11] The prev/next buttons icon pseudo element.
 * [12] Make the scroll hints overlay the list to suggest the list can be
 *      scrolled in its respective direction.
 * [13] Make the scroll list scrollable via one-finger touch gestures.
 * [14] Stack the scroll hints above the list items.
 * [15] For small viewports, remove the spacing at the bottom
 *      if there is no button.
 * [16] By changing `font-weight` the link element need different widths,
 *      this causes shifting. This workaround prevents shifting on large
 *      viewports when the `.is-active` class is added.
 * [17] Prevents the document from background scrolling.
 * [18] The pseudo elements act as scroll hints by overlaying the outermost
 *      left and right part of the navigation list.
 * [19] Expose the section nav custom property to use within
 *      the section nav script.
 * [20] Establish minimum pointer/touch target size.
 * [21] Hide the call-to-action when the navigation is expanded
 *      on smaller screens.
 * [22] Ensure element is hidden. Prevents overwrites from more specific styles
 *      that might set another value for `display` property (e.g. `block` vs `flex`).
 */
.c-section-nav {
    --bron-section-nav-breakpoint: #{$section-nav-breakpoint-value}; /* [19] */
    @include transition(
        $transition-property: "visiblity, opacity, transform",
        $transition-duration: "0, 0, #{$section-nav-transition-duration}"
    );
    position: sticky;
    z-index: $section-nav-z-index;
    top: 0;
    border-top: $section-nav-border-top;
    user-select: none; /* [9] */

    @include media-query(">#{$section-nav-breakpoint}") {
        background-color: $section-nav-background-color;
        box-shadow: $section-nav-shadow; /* [8] */
    }

}

.c-section-nav--reveal {
    position: fixed;
    top: $section-nav-responsive-offset-top;
    width: 100%;
    transform: translateY(-100%); /* [1] */
    box-shadow: none;

    &#{$section-nav-active-above-class},
    &#{$section-nav-active-below-class},
    &#{$section-nav-active-sticky-class} {
        @include transition(
            $transition-property: "visiblity, opacity, transform",
            $transition-duration: "0, #{$section-nav-transition-duration}, #{$section-nav-transition-duration}"
        );
        transform: translateY(0); /* [1] */

        @include media-query(">#{$section-nav-breakpoint}") {
            box-shadow: $section-nav-shadow; /* [8] */
        }

        .c-section-nav__header {
            box-shadow: $section-nav-shadow; /* [8] */
        }

    }

    .c-section-nav__header {
        box-shadow: none;
    }

}

.c-section-nav-prevent-scroll {

    @include media-query("<=#{$section-nav-breakpoint}") {
        overflow: important(hidden, true); /* [17] */
    }

}

    .c-section-nav__header {
        @include page-wrap();
        @include responsive-property("column-gap", $section-nav-item-spacing-horizontal);
        position: relative;
        z-index: ($section-nav-z-index - 1);
        display: grid; /* [2] */
        grid-template-columns: minmax(0, 1fr) max-content; /* [2] */
        width: 100%;
        height: $section-nav-height;
        padding-top: $section-nav-wrapper-spacing-vertical;
        padding-bottom: $section-nav-wrapper-spacing-vertical;
        background-color: $section-nav-background-color;
        box-shadow: $section-nav-shadow; /* [8] */

        @include media-query(">#{$section-nav-breakpoint}") {
            display: none; /* [2] */
        }

    }

    .c-section-nav__container {
        background-color: $section-nav-background-color;

        @include media-query("<=#{$section-nav-breakpoint}") {
            @include transition(
                $transition-property: "visiblity, opacity, transform",
                $transition-duration: "0, 0, #{$section-nav-transition-duration}"
            );
            position: absolute;
            z-index: ($section-nav-z-index - 2); /* [6] */
            width: 100%;
            transform: translateY(-$section-nav-height);
            visibility: hidden;
            opacity: 0;

            &.is-visible {
                @include transition(
                    $transition-property: "visiblity, opacity, transform",
                    $transition-duration: "0, #{$section-nav-transition-duration}, #{$section-nav-transition-duration}"
                );
                transform: translateY(0);
                visibility: visible;
                opacity: 1;
                box-shadow: $section-nav-shadow; /* [8] */
            }

            &::after { /* [10] */
                content: "";
                position: fixed;
                z-index: -1;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: block;
                height: 100vh;
                background-color: $section-nav-backdrop-color;
            }

        }

        @include media-query(">#{$section-nav-breakpoint}") {
            @include page-wrap();
            display: grid; /* [3] */
            grid-template-columns: 1fr max-content; /* [3] */
            align-items: center; /* [3] */
            justify-content: space-between; /* [3] */
            width: 100%;
            padding-top: $section-nav-wrapper-spacing-vertical;
            padding-bottom: $section-nav-wrapper-spacing-vertical;
        }

    }

    .c-section-nav__scroll {
        position: relative;
        display: inherit; /* [4] */

        &:last-child {

            .c-section-nav__list {

                @include media-query("<=#{$section-nav-breakpoint}") {
                    border-bottom-width: 0; /* [15] */
                }

            }

        }

    }

    .c-section-nav__section-label {
        @include reset-button();
        display: inline-flex;
        align-items: center;
        overflow: hidden;
        text-align: $section-nav-label-text-align;
        color: $section-nav-label-color;

        &[aria-expanded="true"] {

            &::before {
                content: $section-nav-icon-hide;
            }

            + .c-section-nav__btn { /* [21] */
                display: none;
            }

        }

        &::before {
            @include font-size(
                $font-size:   $section-nav-icon-size,
                $line-height: 1,
            );
            @include icon-font($line-height: null);
            content: $section-nav-icon-show;
            color: $section-nav-arrow-color;
        }

    }

    .c-section-nav__section-label__text {
        @include responsive-font-size(
            $font-size:   $section-nav-item-text-size,
            $line-height: normal,
        );
        @include truncate($multiline: 2); /* [7] */
        padding-left: $section-nav-label-spacing-horizontal;
        font-weight: $section-nav-item-font-weight-is-active;
        hyphens: auto;
    }

    .c-section-nav__list {
        @include list-bare();
        display: flex;

        @include media-query("<=#{$section-nav-breakpoint}") {
            @include page-wrap();
            row-gap: $section-nav-item-spacing-vertical;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            max-height: calc(100vh - #{$section-nav-height});
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: $section-nav-list-spacing-vertical;
            padding-bottom: $section-nav-list-spacing-vertical;
            touch-action: pan-y; /* [13] */
            border-bottom: $section-nav-list-border-bottom;
            background-color: $section-nav-list-background-color;
        }

        @include media-query(">#{$section-nav-breakpoint}") {
            @include responsive-property("column-gap", $section-nav-item-spacing-horizontal);
            align-items: center;
            overflow-x: auto;
            overflow-y: hidden;
            touch-action: pan-x;
            scroll-snap-type: x mandatory; /* [5] */
            scroll-margin-left: $section-nav-list-scroll-hint-width; /* [5] */
            scrollbar-width: none; /* [5] */
            -ms-overflow-style: none; /* [5] */

            &::-webkit-scrollbar {
                display: none; /* [5] */
            }

            &::before,
            &::after { /* [18] */
                @include transition($transition-property: opacity);
                content: "";
                position: absolute;
                z-index: ($section-nav-z-index + 1); /* [14] */
                top: 0;
                display: block;
                width: $section-nav-list-scroll-hint-width;
                height: 100%;
                pointer-events: none;
                opacity: 0;
            }

            &::before {
                left: 0;
                background-image: $section-nav-list-scroll-hint-gradient-to-left;
            }

            &::after {
                right: 0;
                background-image: $section-nav-list-scroll-hint-gradient-to-right;
            }

            .has-overflow-left &::before,
            .has-overflow-right &::after {
                opacity: 1;
            }

        }

    }

    .c-section-nav__item {

        @include media-query("<=#{$section-nav-breakpoint}") {
            @include responsive-font-size(
                $font-size:   $section-nav-item-text-size,
                $line-height: normal,
            );
            position: relative;
            width: 100%;
        }

        @include media-query(">#{$section-nav-breakpoint}") {
            @include responsive-font-size(
                $font-size:   $section-nav-item-text-size,
                $line-height: $section-nav-item-height,
            );
        }

    }

    .c-section-nav__link {
        display: flex;
        align-items: center;
        min-height: $section-nav-item-height; /* [20] */
        border-bottom: $section-nav-link-border-bottom;
        font-weight: $section-nav-item-font-weight;
        text-decoration: none;

        @if ($section-nav-link-has-transition) {
            @include transition($transition-property: "color");
        }

        @include media-query(">#{$section-nav-breakpoint}") {
            position: relative;
            flex-direction: column;
            padding-top: 0;
            padding-bottom: 0;
            white-space: nowrap;
            text-align: center; /* [16] */

            &::before { /* [16] */
                content: attr(data-text);
                display: block;
                height: 0;
                visibility: hidden;
                overflow: hidden;
                font-weight: $section-nav-item-font-weight-is-active;
            }

        }

        &:hover {
            color: $section-nav-link-color-hover;
        }

        &#{$section-nav-link-active-selector} {
            font-weight: $section-nav-item-font-weight-is-active;
            color: $section-nav-link-color-hover-is-active;
        }

    }

    .c-section-nav__scroll-button {
        @include reset-button();
        @include responsive-property("width", $section-nav-wrapper-spacing-horizontal);
        @include transition(
            $transition-property: "visiblity, opacity, transform",
            $transition-duration: "0, 0, #{$section-nav-transition-duration}"
        );
        position: absolute;
        z-index: ($section-nav-z-index + 10);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: $section-nav-arrow-color;
        opacity: 0;
        visibility: hidden;

        @include media-query("<=#{$section-nav-breakpoint}") {
            display: important(none, true); /* [22] */
        }

        &::before { /* [11] */
            @include font-size(
                $font-size:   $section-nav-icon-size,
                $line-height: 1,
            );
            @include icon-font($line-height: null);
        }

    }

    .c-section-nav__scroll-button--prev {
        left: 0;

        &::before {
            content: $section-nav-icon-prev;
        }

        .has-overflow-left & {
            transform: translateX(-100%); /* [12] */
        }

    }

    .c-section-nav__scroll-button--next {
        right: 0;

        &::before {
            content: $section-nav-icon-next;
        }

        .has-overflow-right & {
            transform: translateX(100%); /* [12] */
        }

    }

    .has-overflow-left .c-section-nav__scroll-button--prev,
    .has-overflow-right .c-section-nav__scroll-button--next {
        @include transition(
            $transition-property: "visiblity, opacity, transform",
            $transition-duration: "0, #{$section-nav-transition-duration}, #{$section-nav-transition-duration}"
        );
        opacity: 1;
        visibility: visible;
    }

    .c-section-nav__container .c-section-nav__btn {
        @include responsive-property("left", $section-nav-wrapper-spacing-horizontal);
        @include responsive-property("right", $section-nav-wrapper-spacing-horizontal);
        position: absolute;
        bottom: $section-nav-wrapper-spacing-vertical;

        @include media-query(">#{$section-nav-breakpoint}") {
            position: initial;
            margin-left: $section-nav-button-spacing-horizontal;
        }

    }

    .c-section-nav__section {
        scroll-margin-top: $section-nav-height;
    }
