/* ------------------------------------*\
    #LOGO
\*------------------------------------ */

$logo-img-url:              $GLOBAL-IMAGE-PATH + "logo.png" !default;
$logo-img-url-small-screen: $GLOBAL-IMAGE-PATH + "logo-small-screen.png" !default;
$logo-breakpoint:           $GLOBAL-LOGO-BREAKPOINT !default;
$logo-width:                $GLOBAL-LOGO-WIDTH !default;
$logo-height:               $GLOBAL-LOGO-HEIGHT !default;
$logo-width-at-breakpoint:  $GLOBAL-LOGO-WIDTH-AT-BREAKPOINT !default;
$logo-height-at-breakpoint: $GLOBAL-LOGO-HEIGHT-AT-BREAKPOINT !default;

/**
 * [1] In addition to the logo in the HTML we have to assign the logo via CSS
 *     as a background image fallback for the former version without
 *     `img` or `picture` elements in the markup.
 * [2] To hide the fallback background image when the logo image is placed as
 *     `img` or `picture` in the HTML, an additional modifier class is necessary.
 *     To ensure compatibility with Bronson's multibrand development mode,
 *     the background image must not be removed, but must be pushed out of
 *     the container with 'background-position`. This will be reversed in
 *     `packages/bronson-default/styleguide/theme-overrides/styles/_sg-styles.scss`.
 * [3] Just apply a different logo for the background image fallback,
 *     if the small screen variant differs from the large screen variant.
 * [4] Defensively reset any transition, e.g. when `.c-logo` is on an `<a>`
 *     element, that is carrying any transition.
 * [5] Convert `.c-logo__image` to a block-level element to remove unwanted
 *     spacing around the image.
 */
.c-logo {
    display: block;
    width: $logo-width;
    height: $logo-height;
    background-image: url($logo-img-url); /* [1] */
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    transition: none; /* [4] */

    @include media-query("<=#{$logo-breakpoint}") {
        width: $logo-width-at-breakpoint;
        height: $logo-height-at-breakpoint;

        @if ($logo-img-url-small-screen) { /* [3] */
            background-image: url($logo-img-url-small-screen); /* [1] */
        }
    }
}

.c-logo--has-image {
    background-position: 999px;  /* [2] */
    background-repeat: no-repeat; /* [2] */
}

    .c-logo__image {
        display: block; /* [5] */
        max-height: 100%;
    }
