// /* ------------------------------------*\
//     #BASE-SETTINGS
// \*------------------------------------ */

// Assign the base text font-size for the entire project.
$GLOBAL-FONT-SIZE:            16px !default;



// The global baseline is our smallest common denominator concerning the
// vertical spacing. Every spacing we use in our project is based off from
// this value and fits into a vertical grid with exactly this size.
$GLOBAL-BASELINE:             8px !default;



// We define various scaling factors to create a set of different spacing
// variants.
$SCALE-FACTOR-XXSMALL:        0.5 !default;
$SCALE-FACTOR-XSMALL:         1 !default;
$SCALE-FACTOR-SMALL:          2 !default;
$SCALE-FACTOR:                3 !default;
$SCALE-FACTOR-LARGE:          4 !default;

$GLOBAL-SPACING-UNIT:         $GLOBAL-BASELINE * $SCALE-FACTOR !default;
$GLOBAL-SPACING-UNIT-XXSMALL: $GLOBAL-BASELINE * $SCALE-FACTOR-XXSMALL !default;
$GLOBAL-SPACING-UNIT-XSMALL:  $GLOBAL-BASELINE * $SCALE-FACTOR-XSMALL !default;
$GLOBAL-SPACING-UNIT-SMALL:   $GLOBAL-BASELINE * $SCALE-FACTOR-SMALL !default;
$GLOBAL-SPACING-UNIT-LARGE:   $GLOBAL-BASELINE * $SCALE-FACTOR-LARGE !default;



// Use the default spacing unit as base line-height.
$GLOBAL-LINE-HEIGHT:          $GLOBAL-SPACING-UNIT !default;

// Recommended minimal pointer target size.
$GLOBAL-POINTER-TARGET-SIZE:  44px !default;
