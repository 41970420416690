/* ------------------------------------*\
    #INSTRUCTION-STEPPER
\*------------------------------------ */

@use "sass:math" as *;

$instruction-stepper-breakpoint:                          s !default;
$instruction-stepper-step-spacing:                        $GLOBAL-SPACING-UNIT !default;
$instruction-stepper-line-gap:                            0px !default; /* stylelint-disable-line length-zero-no-unit */

$instruction-stepper-item-size:                           64px !default;
$instruction-stepper-item-border-radius:                  50% !default;
$instruction-stepper-item-background-color:               $COLOR-BASE-BACKGROUND !default;
$instruction-stepper-item-border-width:                   1px !default;
$instruction-stepper-item-border-color:                   $COLOR-BRAND !default;
$instruction-stepper-item-border:                         if($instruction-stepper-item-border-width > 0, $instruction-stepper-item-border-width solid $instruction-stepper-item-border-color, null) !default;
$instruction-stepper-item-color:                          $COLOR-BASE !default;
$instruction-stepper-item-font-family:                    null !default;
$instruction-stepper-item-font-size:                      map-get($FS3, null) !default;
$instruction-stepper-item-font-weight:                    $GLOBAL-FONT-WEIGHT-BOLD !default;
$instruction-stepper-item-icon-size:                      icon-size(medium) !default;
$instruction-stepper-item-is-active-background-color:     $COLOR-BRAND !default;
$instruction-stepper-item-is-active-border-width:         0 !default;
$instruction-stepper-item-is-active-border-color:         transparent !default;
$instruction-stepper-item-is-active-border:               if($instruction-stepper-item-is-active-border-width > 0, $instruction-stepper-item-is-active-border-width solid $instruction-stepper-item-is-active-border-color, 0) !default;
$instruction-stepper-item-is-active-color:                $COLOR-WHITE !default;
$instruction-stepper-item-is-active-font-size:            null !default;
$instruction-stepper-item-is-active-font-weight:          null !default;
$instruction-stepper-item-is-active-icon-size:            null !default;

$instruction-stepper-detail-spacing-vertical:             $GLOBAL-SPACING-UNIT-SMALL !default;
$instruction-stepper-detail-spacing-horizontal:           $GLOBAL-SPACING-UNIT-SMALL !default;
$instruction-stepper-detail-offset-at-vertical:           calc((var(--instruction-stepper-item-size) / 2) - 1ex) !default; /* [11] */ /* stylelint-disable-line unit-disallowed-list */
$instruction-stepper-title-color:                         $COLOR-BASE-TEXT !default;
$instruction-stepper-title-font-size:                     map-get($FS-1, null) !default;
$instruction-stepper-title-font-weight:                   $GLOBAL-FONT-WEIGHT-BOLD !default;
$instruction-stepper-title-line-height:                   1.5 !default;
$instruction-stepper-description-margin-top:              $GLOBAL-SPACING-UNIT-XSMALL !default;
$instruction-stepper-description-color:                   $COLOR-BASE-TEXT !default;
$instruction-stepper-description-font-size:               map-get($FS-2, null) !default;
$instruction-stepper-description-font-weight:             $GLOBAL-FONT-WEIGHT-REGULAR !default;
$instruction-stepper-description-line-height:             1.5 !default;
$instruction-stepper-description-max-width-at-horizontal: 50ch !default;
$instruction-stepper-description-max-width-at-vertical:   60ch !default;

$instruction-stepper-has-connector:                       true !default;
$instruction-stepper-connector-style:                     solid !default;
$instruction-stepper-connector-size:                      1px !default;
$instruction-stepper-connector-dot-spacing:               10px !default;
$instruction-stepper-connector-color:                     $COLOR-SUBTLE !default;
$instruction-stepper-connector-offset:                    calc(var(--instruction-stepper-item-size) / 2) !default;

$instruction-stepper-small-item-size:                     48px !default;
$instruction-stepper-small-item-font-size:                map-get($FS2, null) !default;
$instruction-stepper-small-item-icon-size:                icon-size(small) !default;

// Mixin to draw the connector line. Encapsulates the logic to draw the line.
// This calculation makes the lines independent of any width and height restrictions,
// meaning that the stepper item content can grow in any directions.
// Each line after a stepper item is drawn via a pseudo element.
// It’s possible to add a gap between stepper item and line by setting `$instruction-stepper-line-gap`.
@mixin instruction-stepper-line($direction: vertical) {

    // Guard against not allowed arguments.
    @if not ($direction == "vertical" or $direction == "horizontal") {
        @error("Instruction Stepper: 'The $direction argument can only be of type string with the values 'vertical' or 'horizontal' – got '#{$direction}'.");
    }

    /**
     * Position logic just changes in orthogonality so we swap out the properties respectively.
     * a. Control the thickness property for the line.
     * b. Control the dimension property of the line:
     *    `height` for vertical orientation and `width` for horizontal orientation.
     * c. The property that controls the line dimensional _offset.
     * d. Calculate the gap (offset) of the line by taking item size and
     *    line gap into account.
     * e. The property that controls the alignment.
     * f. Calculate the width or height of the line by taking item size,
     *    item gap, line gap and (if set) correction buffer into account.
     */
    $_thickness-property: if($direction == "vertical", width, height); /* [a] */
    $_dimension-property: if($direction == "vertical", height, width); /* [b] */
    $_offset-property:    if($direction == "vertical", top, left); /* [c] */
    $_offset:             if($direction == "vertical", calc(var(--instruction-stepper-item-size) + var(--instruction-stepper-line-gap)), calc(50% + var(--instruction-stepper-item-size) / 2 + var(--instruction-stepper-line-gap))); /* [d] */
    $_align-property:     if($direction == "vertical", left, top); /* [e] */

    &::before {
        content: "";
        position: absolute;
        display: block;
        #{$_thickness-property}: $instruction-stepper-connector-size;
        #{$_dimension-property}: calc(var(--instruction-stepper-correction-buffer) + 100% - var(--instruction-stepper-item-size) + var(--instruction-stepper-gap) - var(--instruction-stepper-line-gap) * 2); /* [f] */
        #{$_align-property}: $instruction-stepper-connector-offset;
        #{$_offset-property}: $_offset;
    }

    &:last-child::before {
        display: none;
    }

    // Connector appearance logic.
    @if ($instruction-stepper-connector-style == dotted) {
        // The width (horizontal) or height (vertical) of the first color of the linear
        // gradient must be the same size as the height (horizontal) or width
        // (vertical), which is set through the dot size. To achieve this, the proportion
        // between dot size and dot spacing is transferred to a value representing the
        // width or height of dots in percentage.
        $_gradient-first-color-length: 100% * div(strip-unit($instruction-stepper-connector-size), strip-unit($instruction-stepper-connector-dot-spacing));
        $_gradient-direction: if($direction == "vertical", bottom, right);
        $_gradient: linear-gradient(to #{$_gradient-direction}, #{$instruction-stepper-connector-color} #{$_gradient-first-color-length}, rgba(255, 255, 255, 0) 0%);
        $_dash: if($direction == "vertical", $instruction-stepper-connector-size $instruction-stepper-connector-dot-spacing, $instruction-stepper-connector-dot-spacing $instruction-stepper-connector-size);

        &::before {
            background-image: $_gradient;
            background-size: $_dash;
            background-repeat: repeat;
        }

    } @else if ($instruction-stepper-connector-style == solid) {

        &::before {
            background-color: $instruction-stepper-connector-color;
        }

    } @else {
        @warn("Instruction Stepper: '#{$instruction-stepper-connector-style}' is not a valid style for connectors.");
    }

}

/**
 * [1] Reset custom numbering.
 * [2] Make all items same-width.
 * [3] Reset default margin, padding, and list style type of lists.
 * [4] Horizontal connector.
 * [5] Vertical connector.
 * [6] Move the beginning of the horizontal/vertical connector to the center of
 *     the respective item.
 * [7] Fix overlapping `<div>`s in IE.
 * [8] Stack item above connector.
 * [9] Reset horizontal spacing.
 * [10] Remove spacing to align default and active items.
 * [11] Start title in center of item.
 *      Using baseline does not center it properly. Using `ex` is based on the
 *      x-height of the title which approx. is half of the size, needed as an offset.
 * [12] Improve readability by limiting the maximal line length.
 * [13] The `px` units must be removed because the `font-size` mixin sees
 *      CSS custom properties as a string and does not know its value. The
 *      following conversion to `REM` via CSS `calc` must be done without unit.
 * [14] A correction buffer for lines to avoid micro gaps between steps and connectors.
 */
.c-instruction-stepper {
    --instruction-stepper-gap: #{$instruction-stepper-step-spacing};
    --instruction-stepper-line-gap: #{$instruction-stepper-line-gap};
    --instruction-stepper-item-size: #{$instruction-stepper-item-size};
    --instruction-stepper-item-font-size: #{strip-unit($instruction-stepper-item-font-size)}; /* [13] */
    --instruction-stepper-item-icon-size: #{strip-unit($instruction-stepper-item-icon-size)}; /* [13] */
    --instruction-stepper-correction-buffer: #{if(strip-unit($instruction-stepper-line-gap) <= 0, 2px, 0px)}; /* [14] */ /* stylelint-disable-line length-zero-no-unit */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0; /* [3] */
    padding: 0; /* [3] */
    list-style-type: none; /* [3] */

    &.c-instruction-stepper--center {

        @include media-query(">#{$instruction-stepper-breakpoint}") {
            max-width: fit-content; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
            margin-right: auto;
            margin-left: auto;
        }

    }

    &:not(.c-instruction-stepper--vertical) {

        @include media-query(">#{$instruction-stepper-breakpoint}") {
            flex-direction: row;
        }

    }

}

    .c-instruction-stepper--numbered {
        counter-reset: instruction-stepper-count; /* [1] */
    }

    .c-instruction-stepper__step {
        position: relative;
        display: flex;
        align-items: flex-start; /* [11] */
        flex-grow: 1; /* [2] */
        flex-shrink: 1;
        min-height: var(--instruction-stepper-item-size);
        margin-bottom: $instruction-stepper-step-spacing;

        @if ($instruction-stepper-has-connector) {
            @include instruction-stepper-line($direction: vertical);

            :not(.c-instruction-stepper--vertical) > & {

                @include media-query(">#{$instruction-stepper-breakpoint}") {
                    @include instruction-stepper-line($direction: horizontal);
                }

            }

        }

        :not(.c-instruction-stepper--vertical) > & {

            @include media-query(">#{$instruction-stepper-breakpoint}") {
                flex-direction: column;
                align-items: center;
                flex-basis: 0; /* [2] */
                margin-bottom: 0;

                &:not(:last-child) {
                    margin-right: $instruction-stepper-step-spacing;
                }

            }

        }

    }

    .c-instruction-stepper__item {
        @include font-size(
            $font-size:   var(--instruction-stepper-item-font-size),
            $line-height: 1
        );
        position: relative;
        z-index: 1; /* [8] */
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
        width: var(--instruction-stepper-item-size);
        height: var(--instruction-stepper-item-size);
        border: $instruction-stepper-item-border;
        border-radius: $instruction-stepper-item-border-radius;
        font-family: $instruction-stepper-item-font-family;
        font-weight: $instruction-stepper-item-font-weight;
        text-align: center;
        background-color: $instruction-stepper-item-background-color;
        color: $instruction-stepper-item-color;

        .c-instruction-stepper--numbered & {

            &::before {
                content: counter(instruction-stepper-count);
                counter-increment: instruction-stepper-count;
            }

        }

        .c-instruction-stepper__step.is-active & {
            @if ($instruction-stepper-item-is-active-font-size) {
                @include font-size(
                    $font-size:   $instruction-stepper-item-is-active-font-size,
                    $line-height: 1
                );
            }

            border: $instruction-stepper-item-is-active-border;
            font-weight: $instruction-stepper-item-is-active-font-weight;
            background-color: $instruction-stepper-item-is-active-background-color;
            color: $instruction-stepper-item-is-active-color;

            /* stylelint-disable selector-max-class */
            .c-icon {
                @if ($instruction-stepper-item-is-active-icon-size) {
                    @include font-size(
                        $font-size:   $instruction-stepper-item-is-active-icon-size,
                        $line-height: 1
                    );
                }

                color: currentColor;
            }
            /* stylelint-enable */

        }

        .c-icon {
            @include font-size(
                $font-size:   var(--instruction-stepper-item-icon-size),
                $line-height: 1
            );
            color: currentColor;
        }

    }

    .c-instruction-stepper__detail {
        display: block;
        max-width: 100%; /* [7] */
        margin-top: $instruction-stepper-detail-offset-at-vertical; /* [11] */
        margin-left: $instruction-stepper-detail-spacing-horizontal;
        text-align: left;

        :not(.c-instruction-stepper--vertical) > .c-instruction-stepper__step & {

            @include media-query(">#{$instruction-stepper-breakpoint}") {
                margin-top: $instruction-stepper-detail-spacing-vertical;
                margin-left: 0;
                text-align: center;
            }

        }

    }

    .c-instruction-stepper__title {
        @include font-size(
            $font-size:   $instruction-stepper-title-font-size,
            $line-height: $instruction-stepper-title-line-height
        );
        display: block;
        font-weight: $instruction-stepper-title-font-weight;
        color: $instruction-stepper-title-color;
    }

    .c-instruction-stepper__description {
        @include font-size(
            $font-size:   $instruction-stepper-description-font-size,
            $line-height: $instruction-stepper-description-line-height
        );
        display: block;
        max-width: $instruction-stepper-description-max-width-at-horizontal; /* [12] */
        margin-top: $instruction-stepper-description-margin-top;
        font-weight: $instruction-stepper-description-font-weight;
        color: $instruction-stepper-description-color;

        // Vertical appearance due to small viewport
        @include media-query("<#{$instruction-stepper-breakpoint}") {
            max-width: $instruction-stepper-description-max-width-at-vertical; /* [12] */
        }

        // Vertical appearance due to `vertical` modifier
        .c-instruction-stepper--vertical & {
            max-width: $instruction-stepper-description-max-width-at-vertical; /* [12] */
        }

    }

    .c-instruction-stepper--vertical.c-instruction-stepper--reversed {
        align-items: flex-end;

        .c-instruction-stepper__step {
            flex-direction: row-reverse;

            &::before {
                right: $instruction-stepper-connector-offset;
                left: auto;
            }

        }

        .c-instruction-stepper__detail {
            margin-right: $instruction-stepper-detail-spacing-horizontal;
            margin-left: 0;
            text-align: right;
        }

    }

    .c-instruction-stepper--small {
        --instruction-stepper-item-size: #{$instruction-stepper-small-item-size};
        --instruction-stepper-item-font-size: #{strip-unit($instruction-stepper-small-item-font-size)}; /* [13] */
        --instruction-stepper-item-icon-size: #{strip-unit($instruction-stepper-small-item-icon-size)}; /* [13] */
    }
