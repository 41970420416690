/* ------------------------------------*\
   #NOTIFICATIONS
\*------------------------------------ */

$notification-border:                         null !default;
$notification-text-color:                     inherit !default;
$notification-background-color:               map-get($BACKGROUND-UTILITIES, "alternative") !default;
$notification-container-spacing:              $GLOBAL-SPACING-UNIT-XSMALL !default;
$notification-context-title-color:            null !default;
$notification-context-text-color:             $COLOR-BASE-TEXT !default;
$notification-context-background-color:       $COLOR-BASE-BACKGROUND !default;
$notification-context-border-width:           2px !default;
$notification-context-border-style:           solid !default;
$notification-context-border-color:           $COLOR-SUBTLE !default;
$notification-context-border:                 if($notification-context-border-width, $notification-context-border-width $notification-context-border-style $notification-context-border-color, null) !default;
$notification-context-spacing-vertical:       $GLOBAL-SPACING-UNIT !default;
$notification-padding-top:                    $GLOBAL-SPACING-UNIT !default;
$notification-padding-bottom:                 $GLOBAL-SPACING-UNIT !default;
$notification-font-size:                      $FS1 !default;
$notification-line-height:                    $GLOBAL-LINE-HEIGHT !default;
$notification-icon-color:                     currentColor !default;
$notification-close-icon-size:                icon-size(small) !default;
$notification-close-icon-padding:             $GLOBAL-SPACING-UNIT-SMALL !default;
$notification-close-icon:                     get-icon(semantic-close) !default;
$notification-close-icon-border-radius:       50% !default;
$notification-z-index:                        z("notifications") !default;
$notification-page-wrap:                      true !default;
$notification-enable-status-icon:             true !default;
$notification-status-icon-size:               small !default;
$notification-status-icon-spacing-horizontal: $GLOBAL-SPACING-UNIT-SMALL !default;
$notification-title-spacing-bottom:           $GLOBAL-SPACING-UNIT-SMALL !default;
$notification-title-color:                    $notification-text-color !default;
$notification-title-size:                     $FS2 !default;
$notification-title-weight:                   $GLOBAL-FONT-WEIGHT-BOLD !default;
$notification-buttons-top-spacing:            $GLOBAL-SPACING-UNIT-SMALL !default;
$notification-buttons-gap:                    $GLOBAL-SPACING-UNIT-SMALL !default;

// Merge default and overwrite settings to avoid duplications
// and overwrite only necessary settings.
$notification-button-styles:                    () !default;
$notification-button-styles-overwrites:         () !default;
$notification-button-styles-merged:             map-deep-merge($notification-button-styles, $notification-button-styles-overwrites, 2) !default;

$notification-context-button-styles:            () !default;
$notification-context-button-styles-overwrites: () !default;
$notification-context-button-styles-merged:     map-deep-merge($notification-context-button-styles, $notification-context-button-styles-overwrites, 2) !default;

// Config for theming notifications based on status.
// Map-Key: status
//
// @TODO: generalize status: modal has similar styles, extract to `_settings.status`
$notification-status-themes: (
    info: (
        "text-color":       map-deep-get($STATUS-THEMES, "info", "text-color"),
        "title-color":      null,
        "background-color": map-deep-get($STATUS-THEMES, "info", "background-color"),
        "border":           null,
        "icon":             get-icon(semantic-info),
        "icon-color":       null,
        "icon-border":      false,
        "icon-size":        null,
        "buttons":          (),
    ),
    success: (
        "text-color":       map-deep-get($STATUS-THEMES, "success", "text-color"),
        "title-color":      null,
        "background-color": map-deep-get($STATUS-THEMES, "success", "background-color"),
        "border":           null,
        "icon":             get-icon(semantic-success),
        "icon-color":       null,
        "icon-border":      false,
        "icon-size":        null,
        "buttons":          (),
    ),
    warning: (
        "text-color":       map-deep-get($STATUS-THEMES, "warning", "text-color"),
        "title-color":      null,
        "background-color": map-deep-get($STATUS-THEMES, "warning", "background-color"),
        "border":           null,
        "icon":             get-icon(semantic-warning),
        "icon-color":       null,
        "icon-border":      false,
        "icon-size":        null,
        "buttons":          (),
    ),
    error: (
        "text-color":       map-deep-get($STATUS-THEMES, "error", "text-color"),
        "title-color":      null,
        "background-color": map-deep-get($STATUS-THEMES, "error", "background-color"),
        "border":           null,
        "icon":             get-icon(semantic-error),
        "icon-color":       null,
        "icon-border":      false,
        "icon-size":        null,
        "buttons":          (),
    ),
) !default;

// Config for theming context notifications based on status.
$notification-context-status-themes: (
    info: (
        "text-color":       null,
        "title-color":      map-deep-get($STATUS-THEMES, "info", "color"),
        "background-color": null,
        "border":           null,
        "border-color":     map-deep-get($STATUS-THEMES, "info", "border-color"),
        "icon":             null,
        "icon-color":       map-deep-get($STATUS-THEMES, "info", "color"),
        "icon-border":      null,
        "icon-size":        null,
        "buttons":          (),
    ),
    success: (
        "text-color":       null,
        "title-color":      map-deep-get($STATUS-THEMES, "success", "color"),
        "background-color": null,
        "border":           null,
        "border-color":     map-deep-get($STATUS-THEMES, "success", "border-color"),
        "icon":             null,
        "icon-color":       map-deep-get($STATUS-THEMES, "success", "color"),
        "icon-border":      null,
        "icon-size":        null,
        "buttons":          (),
    ),
    warning: (
        "text-color":       null,
        "title-color":      null,
        "background-color": null,
        "border":           null,
        "border-color":     map-deep-get($STATUS-THEMES, "warning", "border-color"),
        "icon":             null,
        "icon-color":       null,
        "icon-border":      null,
        "icon-size":        null,
        "buttons":          (),
    ),
    error: (
        "text-color":       null,
        "title-color":      map-deep-get($STATUS-THEMES, "error", "color"),
        "background-color": null,
        "border":           null,
        "border-color":     map-deep-get($STATUS-THEMES, "error", "border-color"),
        "icon":             null,
        "icon-color":       map-deep-get($STATUS-THEMES, "error", "color"),
        "icon-border":      null,
        "icon-size":        null,
        "buttons":          (),
    ),
) !default;

// Merge default and overwrite settings to avoid duplications
// and overwrite only necessary settings.
$notification-status-themes-overwrites: () !default;
$notification-status-themes-merged:     map-deep-merge($notification-status-themes, $notification-status-themes-overwrites, 2) !default;

// Merge default and overwrite settings.
$notification-context-status-themes-overwrites: () !default;
$notification-context-status-themes-merged:     map-deep-merge($notification-context-status-themes, $notification-context-status-themes-overwrites, 2) !default;

// Style-hook: `[data-marketing-theme]`
$notification-marketing-themes-overwrites: () !default;
$notification-marketing-themes:            map-deep-merge($MARKETING-THEMES, $notification-marketing-themes-overwrites, 2) !default;

/// Generate CSS custom property to set button properties
///
/// @param {String} $property - Property part of the CSS custom property
/// @param {String | Boolean} $value - Value of the CSS custom property
/// @param {String} $variant [secondary] - Button variant. E.g. `primary` or `secondary`
@mixin _button-css-custom-property($property, $value, $variant: secondary) {
    @if ($value) {
        --button-#{$variant}-#{$property}: #{$value};
    }
}

/// Generate CSS custom properties from property name and value
///
/// @param {String} $name - Name of the property
/// @param {String} $value - Value of the property
/// @param {String} $prefix - Prefix to add to the property name
/// @example
///     @include _css-custom-property-from-var(
///         $name: border-color,
///         $value: red,
///     );
/// Outputs:
///     --notification-border-color: red;
@mixin _css-custom-property-from-var($name, $value, $prefix: notification) {
    @if ($value) {
        --#{if($prefix, $prefix + '-', null)}#{$name}: #{$value};
    }
}

/// Generate CSS custom properties from vary properties.
///
/// @param {String} $property - The name of the vary property
@mixin _css-custom-property-from-vary($property) {
    @include _css-custom-property-from-var(
        $name: #{$property},
        $value: vary-get($property),
    );
}

/// Generate common notification CSS custom properties
@mixin _notification-css-custom-properties() {
    // Generate status theme button CSS custom properties
    @each $property, $value in vary-get("buttons") {
        @include _button-css-custom-property(
            $property: $property,
            $value: $value,
        );
    }

    // Generate status theme CSS custom properties from vary values
    @include _css-custom-property-from-vary(background-color);
    @include _css-custom-property-from-vary(text-color);
    @include _css-custom-property-from-vary(title-color);
    @include _css-custom-property-from-vary(icon-color);
    @include _css-custom-property-from-vary(border-style);
    @include _css-custom-property-from-vary(border-width);
    @include _css-custom-property-from-vary(border-color);
    @include _css-custom-property-from-vary(border);
}



/**
 * [1]  Notifications are always sticky.
 * [2]  Notifications are displayed either at the top or at the bottom of the page.
 * [3]  Visually hide notifications as long as JS is not loaded.
 * [4]  To properly animate the opening/closing of the notifications, hide the
 *      overflow.
 * [5]  Add transition after JS is loaded to prevent weird flickering of the
 *      notification on page-load.
 * [6]  Add height of `0` when the notification is closed to be able to
 *      animate the opening/closing.
 * [8]  Vertically align the icon with the first line of the notifications text.
 * [9]  Relation for the icon.
 * [10] Spacing for the icon on the right.
 * [11] Make sure that links in notifications are always legible.
 * [13] Modifier for flex-direction behavior on mobile.
 * [14] Remove margin-bottom from paragraph.
 * [15] Add status specific color.
 * [16] Set `transform` to `none` instead of `translateY(0)` as this would
 *      hide the bottom border in combination with `overflow: hidden` on the
 *      container (only necessary for Firefox).
 * [17] Restrict the height of the container to the viewport height, so it is
 *      scrollable with many items in it.
 * [18] In case of many notifications, ensure scrollability,...
 * [19] ...but prevent on the x-axis.
 * [20] Generate button CSS custom properties
 * [21] Generate status theme button CSS custom properties
 */
.c-notifications-group {
    position: sticky; /* [1] */
    z-index: $notification-z-index;
    top: 0;
    width: 100%;
    max-height: 100vh; /* [17] */
    visibility: hidden; /* [3] */
    overflow-y: auto; /* [18] */
    overflow-x: hidden; /* [19] */

    &.js-is-ready {
        visibility: inherit;
    }

}

.js-context-notification {
    margin-bottom: $notification-context-spacing-vertical;
}

.c-notification {
    width: 100%;
    padding-top: $notification-padding-top;
    padding-bottom: $notification-padding-bottom;
    transform: none; /* [16] */
    border: var(--notification-border, #{$notification-border});
    background-color: var(--notification-background-color, #{$notification-background-color});
    color: var(--notification-text-color, #{$notification-text-color});

    .c-notifications-group.js-is-ready > &,
    .c-notification__container.js-is-ready & {
        @include transition(); /* [5] */
    }

    /* stylelint-disable-next-line order/order */
    @include vary($create: modifier, $loop: $notification-status-themes-merged) {

        @if ($notification-enable-status-icon) {
            .c-notification__status-icon {
                // Use special icon size when defined per status, otherwise use a common size
                $_icon-size: if(vary-get("icon-size"), vary-get("icon-size"), $notification-status-icon-size);

                @if (vary-get("icon-border") == true) {
                    @include icon-variant(
                        $variant:      "round",
                        $border-color: var(--notification-icon-color, #{$notification-icon-color}),
                        $size:         $_icon-size,
                    );
                } @else {
                    font-size: icon-size($_icon-size);
                }

                &::before {
                    content: vary-get("icon");
                }

            }
        }

    }


}

    /* Normal Notifications (not Context Notifications) */
    :not(.c-notification--context) {

        &.c-notification {
            /* [20] */
            @each $property, $value in $notification-button-styles-merged {
                @include _button-css-custom-property(
                    $property: $property,
                    $value: $value,
                );
            }

            @include vary($create: modifier, $loop: $notification-status-themes-merged) {
                @include _notification-css-custom-properties();
            }
        }

    }

    /* Marketing Colors Notifications */
    /* stylelint-disable-next-line no-duplicate-selectors */
    .c-notification {
        @include vary($create: custom, $loop: $notification-marketing-themes) {

            &[data-marketing-theme="#{$vary-loop-entity}"] {
                @include _notification-css-custom-properties();
            }

        }
    }

    /* Context Notifications */
    .c-notification--context {
        @include _css-custom-property-from-var(text-color, $notification-context-text-color);
        @include _css-custom-property-from-var(title-color, $notification-context-title-color);
        @include _css-custom-property-from-var(background-color, $notification-context-background-color);
        @include _css-custom-property-from-var(border-style, $notification-context-border-style);
        @include _css-custom-property-from-var(border-width, $notification-context-border-width);
        @include _css-custom-property-from-var(border-color, $notification-context-border-color);
        border: var(--notification-border, #{$notification-context-border});
        border-color: var(--notification-border-color);

        /* [20] */
        @each $property, $value in $notification-context-button-styles-merged {
            @include _button-css-custom-property(
                $property: $property,
                $value: $value,
            );
        }

        &.c-notification {
            @include vary($create: modifier, $loop: $notification-context-status-themes-merged) {
                @include _notification-css-custom-properties();
            }
        }

    }

    .c-notification__container {
        overflow: hidden; /* [4] */

        // Subsequent container. Style only applies if 2+ containers are present.
        & + & {
            margin-top: $notification-container-spacing;
        }

        .js-is-ready > & {
            @include transition(); /* [5] */
        }

        &.is-closed {
            height: 0; /* [6] */
            visibility: hidden;
        }

    }

    .c-notification__close {
        @include reset-button();
        @include transition();
        position: absolute;
        top: (($notification-line-height - $notification-close-icon-size) * 0.5); /* [8] */
        right: 0;
        height: $notification-close-icon-size;
        border-radius: if($notification-close-icon-border-radius, $notification-close-icon-border-radius, null);
        cursor: pointer;
        outline: none;

        &::before {
            @include icon-font();
            content: $notification-close-icon;
            font-size: $notification-close-icon-size;
            vertical-align: middle;
            color: var(--notification-text-color, #{$notification-text-color});
        }

    }

    .c-notification__status-icon {
        margin-right: $notification-status-icon-spacing-horizontal;
        font-size: icon-size($notification-status-icon-size);
        color: var(--notification-icon-color);

        // Only define icon definitions when using status icons with modifier
        // to avoid duplications & conflicts with `.c-icon`.
        .c-notification & {
            @if ($notification-enable-status-icon) {

                &::before {
                    @include icon-font();
                }
            } @else {
                display: none;
            }
        }

    }

    .c-notification__outer-wrap {
        @if ($notification-page-wrap) {
            @include page-wrap();
        }
    }

    .c-notification__inner-wrap {
        @include responsive-font-size(
            $font-size:   $notification-font-size,
            $line-height: $notification-line-height
        ); /* [8] */
        position: relative; /* [9] */

        display: flex;
        margin-bottom: 0;
        padding-right: ($notification-close-icon-size + $notification-close-icon-padding); /* [10] */
    }

    .c-notification__text {

        &,
        > p:last-child {
            margin-bottom: 0; /* [14] */
        }

        a {
            color: inherit; /* [11] */
        }

    }

    .c-notification__title {
        @include responsive-font-size(
            $font-size: $notification-title-size,
        );
        margin-bottom: $notification-title-spacing-bottom;
        font-weight: $notification-title-weight;
        color: var(--notification-title-color, #{$notification-title-color});
    }

    .c-notification__buttons {
        display: flex;
        gap: $notification-buttons-gap;
        flex-wrap: wrap;
        margin-top: $notification-buttons-top-spacing;
    }
