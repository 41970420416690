/* ------------------------------------*\
    #CHECKBOX
\*------------------------------------ */

$skoda-checkbox-offset:       3px !default;
$skoda-checkbox-focus-offset: 1px !default;

/**
 * [1] Vertically center checkbox with first line of label.
 * [2] Transition from transparent to target color,
 *     otherwise the transition is not smooth.
 * [3] Prevent unnecessary spacing when no text is used as label.
 */
.c-checkbox__label {

    .c-checkbox__input + &::before {
        @include focus-styles($outline-color: transparent); /* [2] */
    }

    .c-checkbox__input:focus-visible + &::before {
        @include focus-styles($outline-offset: $skoda-checkbox-focus-offset);
    }

    &::before,
    &::after {
        top: $skoda-checkbox-offset; /* [1] */
    }

    &:empty {
        padding-left: $checkbox-size; /* [3] */
    }

}
