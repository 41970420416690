/* ------------------------------------*\
    #FOOTNOTES
\*------------------------------------ */

$footnotes-font-size:               $FS-2 !default;
$footnotes-font-weight:             null !default;
$footnotes-font-color:              $COLOR-BASE !default;
$footnotes-spacing-bottom:          0 !default;
$footnotes-bullet-symbol-font-size: 0.8em !default;
$footnotes-bullet-number-font-size: 0.8em !default;
$footnotes-bullet-spacing:          $GLOBAL-SPACING-UNIT-SMALL !default;
$footnotes-default-bullet:          "*" !default;

/**
 * [1] Define * as default content.
 * [2] Show index instead of character.
 * [3] Show data-bullet instead of default character, if attribute
 *     data-footnote-bullet is given.
 * [4] Overwrite default `<li>` margin.
 */
.c-footnotes {
    @include responsive-font-size($footnotes-font-size);
    @include list-bare();
    padding-left: $footnotes-bullet-spacing;
    counter-reset: footnotes-raised-counter;
    font-weight: $footnotes-font-weight;
    color: $footnotes-font-color;
}

    .c-footnotes__item,
    .c-footnotes li {
        position: relative;
        margin-bottom: $footnotes-spacing-bottom; /* [4] */

        &::before { /* [1] */
            content: $footnotes-default-bullet;
            position: absolute;
            left: -($footnotes-bullet-spacing);
            font-size: $footnotes-bullet-symbol-font-size;
        }

    }

ol.c-footnotes,
.c-footnotes--numbered { /* [2] */

    .c-footnotes__item,
    > li {

        &::before {
            content: counter(footnotes-raised-counter);
            font-size: $footnotes-bullet-number-font-size;
            counter-increment: footnotes-raised-counter;
        }

    }

}

ul.c-footnotes { /* [3] */

    .c-footnotes__item[data-footnote-bullet],
    > li[data-footnote-bullet] {

        &::before {
            content: attr(data-footnote-bullet);
        }

    }

}
