// /* ------------------------------------*\
//     #FORMS (Settings)
// \*------------------------------------ */

$GLOBAL-INPUT-HEIGHT:                    56px !default;
$GLOBAL-INPUT-PADDING-HORIZONTAL:        $__SKODA-SPACING-STATIC-12__ !default;
$GLOBAL-INPUT-PADDING-VERTICAL:          $GLOBAL-SPACING-UNIT-XSMALL !default;
$GLOBAL-INPUT-BACKGROUND:                rgba($COLOR-ON-SURFACE-900, 0.06) !default;

$GLOBAL-INPUT-BORDER-WIDTH-FOCUS:        2px !default;
$GLOBAL-INPUT-BORDER-WIDTH-ERROR:        2px !default;
$GLOBAL-INPUT-BORDER-COLOR:              $COLOR-ON-SURFACE-600 !default;
$GLOBAL-INPUT-BORDER-COLOR-HOVER:        $COLOR-ON-SURFACE-900 !default;
$GLOBAL-INPUT-BORDER-COLOR-FOCUS:        $COLOR-BRAND !default;
$GLOBAL-INPUT-BORDER-COLOR-READONLY:     $COLOR-INACTIVE !default;
$GLOBAL-INPUT-BORDER-COLOR-FILLED:       $COLOR-ON-SURFACE-700 !default;
$GLOBAL-INPUT-BORDER-RADIUS:             $GLOBAL-BORDER-RADIUS-SMALL !default;

$GLOBAL-INPUT-FONT-COLOR:                $COLOR-BASE-TEXT !default;
$GLOBAL-INPUT-FONT-COLOR-PLACEHOLDER:    $COLOR-ON-SURFACE-600 !default;
$GLOBAL-INPUT-FONT-COLOR-READONLY:       $COLOR-BASE-TEXT !default;
$GLOBAL-INPUT-FONT-WEIGHT:               $GLOBAL-FONT-WEIGHT-REGULAR !default;

$GLOBAL-INPUT-LABEL-SIZE:                map-get($FS-2, null) !default; // opt-out of responsive size
$GLOBAL-INPUT-LABEL-SPACING:             $GLOBAL-SPACING-UNIT-XSMALL !default;
$GLOBAL-INPUT-LABEL-FONT-WEIGHT:         $GLOBAL-INPUT-FONT-WEIGHT !default;
$GLOBAL-INPUT-LABEL-COLOR:               $COLOR-ON-SURFACE-600 !default;
$GLOBAL-INPUT-LABEL-COLOR-HOVER:         $COLOR-ON-SURFACE-900 !default;
$GLOBAL-INPUT-LABEL-COLOR-FOCUS:         $COLOR-BRAND !default;
$GLOBAL-INPUT-LABEL-COLOR-ACTIVE:        $COLOR-ON-SURFACE-900 !default;
$GLOBAL-INPUT-LABEL-COLOR-DISABLED:      $COLOR-INACTIVE !default;
$GLOBAL-INPUT-LABEL-COLOR-READONLY:      $GLOBAL-INPUT-LABEL-COLOR-ACTIVE !default;

$GLOBAL-INPUT-ADDON-PADDING-HORIZONTAL:  $GLOBAL-INPUT-PADDING-HORIZONTAL !default;
$GLOBAL-INPUT-ADDON-BACKGROUND:          $GLOBAL-INPUT-BACKGROUND !default;
$GLOBAL-INPUT-ADDON-BACKGROUND-DISABLED: $COLOR-INACTIVE !default;
$GLOBAL-INPUT-ADDON-FONT-COLOR:          $GLOBAL-INPUT-FONT-COLOR !default;
$GLOBAL-INPUT-ADDON-FONT-COLOR-NO-BG:    $GLOBAL-INPUT-ADDON-FONT-COLOR !default;
$GLOBAL-INPUT-ADDON-FONT-COLOR-DISABLED: $COLOR-INACTIVE-SECONDARY !default;

$GLOBAL-INPUT-ADDON-ICON-SIZE:           xsmall !default;

$GLOBAL-INPUT-TEXTAREA-PADDING-VERTICAL: $GLOBAL-INPUT-PADDING-VERTICAL !default;

// Adjust height to allow "fake" positioning static label inside visual container.
$GLOBAL-INPUT-HEIGHT-STATIC-LABEL:       ($GLOBAL-INPUT-HEIGHT - $GLOBAL-INPUT-LABEL-SIZE - $GLOBAL-INPUT-LABEL-SPACING) !default;

$GLOBAL-INPUT-OPTION-COLOR-DISABLED:     $COLOR-INACTIVE !default;
