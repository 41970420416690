// /* ------------------------------------*\
//     #TILE-SELECT (Settings)
// \*------------------------------------ */

@use "sass:map";

// General config
$tile-select-opacity-disabled:               1 !default; // Set colors instead of reducing opacity.

// Body foreground

// Checkmark config
$tile-select-check-icon-background-size:     map.get($ICON-ROUND-FULL-SIZES, xsmall) !default;
$tile-select-check-icon-size:                icon-size(xxsmall) !default;

// Checkmark foreground
$tile-select-check-color:                    $COLOR-WHITE !default;
$tile-select-check-color-hover:              $tile-select-check-color !default;
$tile-select-check-color-disabled:           $tile-select-check-color !default;
$tile-select-check-color-disabled-selected:  $tile-select-check-color !default;
$tile-select-check-color-error:              $tile-select-check-color !default;

// Checkmark background
$tile-select-check-background-color:         $CARD-PATTERN-SELECT-INDICATOR-BACKGROUND-COLOR !default;
$tile-select-check-background-color-hover:   $CARD-PATTERN-SELECT-INDICATOR-BACKGROUND-COLOR-HOVER !default;
$tile-select-check-background-color-error:   $tile-select-check-background-color !default;

// Body content

// Body background
$tile-select-background-color:               $CARD-PATTERN-BACKGROUND-COLOR !default;

// Body border
$tile-select-border-radius:                  $CARD-PATTERN-BORDER-RADIUS !default;
$tile-select-border-color:                   $CARD-PATTERN-BORDER-COLOR !default;
$tile-select-border-color-hover:             $CARD-PATTERN-BORDER-COLOR-HOVER !default;
$tile-select-border-color-selected:          $CARD-PATTERN-BORDER-COLOR-SELECTED !default;
$tile-select-border-color-disabled-selected: $COLOR-INACTIVE-TERTIARY !default;

$tile-select-icon-size:                      icon-size($CARD-PATTERN-ICON-SIZE) !default;

$tile-select-title-font-size:                $CARD-PATTERN-TITLE-SIZE !default;
$tile-select-title-margin-top:               $CARD-PATTERN-MODULE-SPACING !default;
$tile-select-text-margin-top:                $tile-select-title-margin-top !default;

$tile-select-button-margin-top:              $GLOBAL-SPACING-UNIT-SMALL !default;
