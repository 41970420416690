// /* ------------------------------------*\
//     #INFO-ICON
// \*------------------------------------ */

$skoda-info-icon-hover:          get-icon(information-circle-filled) !default;
$skoda-info-icon-size-min:       icon-size(xsmall) !default;
$skoda-info-icon-size-preferred: 1em !default; // Size relative to text size
$skoda-info-icon-size-max:       icon-size(small) !default;

/**
 * [1] Set a preferred size for the icon that is based on the text's size.
 *     Use `clamp()` to set bounds - with defined minimum and maximum size
 *     based on defined icon sizes.
 *     This way the icon stays proportional to the (responsive) text size
 *     without need of media-queries.
 *     This also solves potential misalignments, e.g. with section-heading.
 * [2] Use negative offset due to the larger size with "whitespace" of the info-icon
 *     in order to not create spacing/padding between icon and outline.
 * [3] Use "inverted" icon on hover.
 */
.c-info-icon {
    border-radius: 50%;

    /* stylelint-disable */
    /* [1] */
    font-size: clamp(
        $skoda-info-icon-size-min,
        $skoda-info-icon-size-preferred,
        $skoda-info-icon-size-max
    );
    /* stylelint-enable */

    &.c-icon--\[semantic-info\]:where(:hover, :focus-visible) {
        --bron-icon: #{$skoda-info-icon-hover}; /* [3] */
    }

    &:focus-visible {
        outline: 0;
    }

}
