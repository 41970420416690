// /* ------------------------------------*\
//     #TEXT WRAP
// \*------------------------------------ */

/// Wrap text if it overflows the available space.
/// Other methods like soft-hypens (`&shy;`) are preferred but this forces
/// the wrap. The correct hyphenation is depending on the OS, browser and language.
/// If hyphenation is not applied, `word-break` is used, wrapping text without
/// using hyphens.
@mixin force-text-wrap() {
    word-break: break-word;
    hyphens: auto;
}
