// /* ------------------------------------*\
//     #TOOLTIP
// \*------------------------------------ */

.c-tooltip {

    &:focus-visible {
        @include focus-styles();
    }

}
