// /* ------------------------------------*\
//     #UPLOAD-ITEM (Settings)
// \*------------------------------------ */

$upload-item-border:                                 0 !default;
$upload-item-padding:                                0 !default;

$upload-item-icon-size:                              icon-size(small) !default;
$upload-item-icon-spacing-right:                     $GLOBAL-SPACING-UNIT-XSMALL !default;

$upload-item-interaction-remove-focus:               false !default;
$upload-item-interaction-icon-spacing:               $GLOBAL-SPACING-UNIT-SMALL !default;
$upload-item-interaction-icon-spacing-at-breakpoint: $upload-item-interaction-icon-spacing !default;

$upload-item-interaction-icon-size:                  icon-size(xsmall) !default;
$upload-item-interaction-icon-color:                 $COLOR-BASE-TEXT !default;

$upload-item-title-line-height:                      1.5 !default; // Vertical alignment
$upload-item-title-font-weight:                      $GLOBAL-FONT-WEIGHT-LIGHT !default;
$upload-item-title-spacing-bottom:                   0 !default;

$upload-item-subtitle-font-size:                     $FS-2 !default;
$upload-item-subtitle-font-weight:                   $GLOBAL-FONT-WEIGHT-REGULAR !default;

$upload-item-progress-spacing-top:                   $__SKODA-SPACING-STATIC-12__ !default; // Avoid collision with button states
$upload-item-progress-spacing-bottom:                $GLOBAL-SPACING-UNIT-XSMALL !default;
