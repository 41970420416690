// /* ------------------------------------*\
//     #HEADINGS (Settings)
// \*------------------------------------ */

$GLOBAL-HEADING-H1-FONT-SIZE:     $FS4 !default;
$GLOBAL-HEADING-H2-FONT-SIZE:     $FS3 !default;
$GLOBAL-HEADING-H3-FONT-SIZE:     $FS2 !default;
$GLOBAL-HEADING-H4-FONT-SIZE:     $FS1 !default;
$GLOBAL-HEADING-H5-FONT-SIZE:     $FS1 !default;
$GLOBAL-HEADING-H6-FONT-SIZE:     $FS0 !default;

// Taken from ŠKODA Flow Styleguide (Figma)
// by dividing the font size by the line height
$GLOBAL-HEADING-BASE-LINE-HEIGHT: 1.2 !default;

$GLOBAL-HEADING-H6-LINE-HEIGHT:   1.5 !default;
