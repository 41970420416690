/* ------------------------------------*\
    #LOGO-BANNER
\*------------------------------------ */

$logo-banner-breakpoint:              "s" !default;
$logo-banner-font-size:               $FS1 !default;
$logo-banner-font-size-at-breakpoint: $FS0 !default;
$logo-banner-font-weight:             $GLOBAL-FONT-WEIGHT-REGULAR !default;
$logo-banner-font-line-height:        $GLOBAL-HEADING-BASE-LINE-HEIGHT !default;
$logo-banner-color:                   $COLOR-BRAND !default;
$logo-banner-background-color:        $COLOR-HIGHLIGHT !default;
$logo-banner-padding:                 $GLOBAL-SPACING-UNIT !default;
$logo-banner-gap:                     $GLOBAL-SPACING-UNIT-SMALL !default;
$logo-banner-gap-at-breakpoint:       $GLOBAL-SPACING-UNIT-XSMALL !default;
$logo-banner-logo-height-max:         var(--logo-banner-logo-height, 32px) !default; // Magic number that fits quite well
$logo-banner-logo-height-min:         #{$logo-banner-logo-height-max} / 1.5 !default; // 1.5 times smaller than maximum height
$logo-banner-logo-height-preferred:   8vw !default; // Middle `clamp` value
$logo-banner-row-min-height:          70px !default; // Magic number from the Bluelabel/heycar design template
$logo-banner-row-padding:             $GLOBAL-SPACING-UNIT-SMALL !default;

/**
 * [1] Revert all styles to reset potential global link styles.
 * [2] Use CSS custom properties with a fallback to allow some properties
 *     (color, background color, logo height) to be customizable from the markup
 *     (through inline styles). For example:
 *     ```
 *         <a href="#" class="c-logo-banner" style="--logo-banner-color: #ffffff">
 *     ```
 * [3] We set the logo’s dimensions based on height. As a result, the width
 *     can vary and is determined by the logo’s aspect ratio.
 *     `clamp` is used to set a minimum value, a viewport width based value
 *     and a maximum value to make the logo responsive within a given range.
 */

.c-logo-banner {
    // stylelint-disable-next-line order/order
    @include responsive-font-size(
        $font-size: $logo-banner-font-size,
        $line-height: $logo-banner-font-line-height,
    );
    display: flex;
    justify-content: center;
    padding: $logo-banner-padding;
    font-weight: $logo-banner-font-weight;
    background-color: var(--logo-banner-background-color, #{$logo-banner-background-color}); /* [2] */

    @include media-query("<=#{$logo-banner-breakpoint}") {
        @include responsive-font-size($logo-banner-font-size-at-breakpoint);
        gap: $logo-banner-gap-at-breakpoint;
    }
}

    .c-logo-banner__body {
        all: revert; /* [1] */
        display: flex;
        gap: $logo-banner-gap;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        text-decoration: none;
        color: var(--logo-banner-color, #{$logo-banner-color}); /* [2] */
    }

    .c-logo-banner__logo {
        height: clamp(#{$logo-banner-logo-height-min}, #{$logo-banner-logo-height-preferred}, #{$logo-banner-logo-height-max}); /* [2] [3] */
    }

    .c-logo-banner--row {
        min-height: $logo-banner-row-min-height;
        padding: $logo-banner-row-padding;

        .c-logo-banner__body {
            flex-direction: row;
        }

    }


