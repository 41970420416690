// /* ------------------------------------*\
//     #COLORS
// \*------------------------------------ */

// Literal color palette.
// DON'T USE THESE VARS IN YOUR CODEBASE!!!
// @sass-export-section="literalColors"
$__electric-50__:  #e8fff1 !default;
$__electric-200__: #a8ffcc !default;
$__electric-300__: #78faae !default;
$__electric-500__: #59bc87 !default;
$__electric-600__: #419468 !default;

$__emerald-800__:  #0e3a2f !default;


$__neutral-0__:    #ffffff !default;
$__neutral-25__:   #f1f1f1 !default;
$__neutral-50__:   #e4e4e4 !default;
$__neutral-100__:  #d8d8d8 !default;
$__neutral-200__:  #c4c6c7 !default;
$__neutral-300__:  #9e9fa0 !default;
$__neutral-500__:  #7c7d7e !default;
$__neutral-600__:  #5a5b5c !default;
$__neutral-700__:  #464748 !default;
$__neutral-800__:  #303132 !default;
$__neutral-900__:  #161718 !default;

$__red__:          #e82b37 !default;
$__orange__:       #ec6513 !default;
$__blue__:         #2d71d7 !default;
$__green__:        #37d002 !default;

$__white__:        #ffffff !default;
$__black__:        $__neutral-900__ !default;

// Transparency Colors
$__transparency-primary__:     rgba($__neutral-900__, 0.06) !default;
$__transparency-secondary__:   rgba(#1a1b1c, 0.12) !default;
$__transparency-tertiary__:    rgba(#f3f3f3, 0.12) !default;
$__transparency-quarternary__: rgba(#f3f3f3, 0.18) !default;

// Marketing Colors
$__marketing-1__: mix($__orange__, #ffffff, 30%) !default;
$__marketing-2__: mix($__green__, #ffffff, 30%) !default;
// @end-sass-export-section


// Semantic color palette.
// USE THESE VARS IN YOUR CODEBASE.
// @sass-export-section="semanticColors"
$COLOR-SURFACE-PRIMARY:          $__neutral-25__ !default;
$COLOR-SURFACE-SECONDARY:        $__neutral-50__ !default;
$COLOR-SURFACE-QUARTERNARY:      $__neutral-800__ !default;

$COLOR-ON-SURFACE-100:           $__neutral-100__ !default;
$COLOR-ON-SURFACE-200:           $__neutral-200__ !default;
$COLOR-ON-SURFACE-300:           $__neutral-300__ !default;
$COLOR-ON-SURFACE-500:           $__neutral-500__ !default;
$COLOR-ON-SURFACE-600:           $__neutral-600__ !default;
$COLOR-ON-SURFACE-700:           $__neutral-700__ !default;
$COLOR-ON-SURFACE-900:           $__neutral-900__ !default;

$COLOR-TRANSPARENCY-PRIMARY:     $__transparency-primary__ !default;
$COLOR-TRANSPARENCY-SECONDARY:   $__transparency-secondary__ !default;
$COLOR-TRANSPARENCY-TERTIARY:    $__transparency-tertiary__ !default;
$COLOR-TRANSPARENCY-QUARTERNARY: $__transparency-quarternary__ !default;

$COLOR-INACTIVE-PRIMARY:         $__neutral-200__ !default;
$COLOR-INACTIVE-SECONDARY:       $__neutral-100__ !default;
$COLOR-INACTIVE-TERTIARY:        $__neutral-300__ !default;

$COLOR-BASE-BACKGROUND:          $__white__ !default;
$COLOR-ALT-BACKGROUND:           $COLOR-BASE-BACKGROUND !default;
$COLOR-BASE-TEXT:                $__neutral-900__ !default;
$COLOR-ALT-TEXT:                 $__white__ !default;
$COLOR-BASE:                     $__neutral-900__ !default;
$COLOR-WHITE:                    $__white__ !default;
$COLOR-BLACK:                    $__black__ !default;
$COLOR-BRAND:                    $__electric-600__ !default;
$COLOR-BRAND-HOVER:              $__electric-500__ !default;
$COLOR-BRAND-SECONDARY:          $__electric-300__ !default;
$COLOR-BRAND-SECONDARY-HOVER:    $__electric-200__ !default;
$COLOR-BRAND-TERTIARY:           $__emerald-800__ !default;
$COLOR-BRAND-QUARTERNARY:        $__electric-50__ !default;
$COLOR-SUBTLE:                   $COLOR-ON-SURFACE-500 !default;
$COLOR-PALE:                     $COLOR-ON-SURFACE-100 !default;
$COLOR-DECENT:                   $COLOR-ON-SURFACE-700 !default;
$COLOR-HIGHLIGHT:                $COLOR-BRAND !default;
$COLOR-SUCCESS:                  $__green__ !default;
$COLOR-INFO:                     $__blue__ !default;
$COLOR-ALERT:                    $__red__ !default;
$COLOR-WARNING:                  $__orange__ !default;
$COLOR-INACTIVE:                 $COLOR-INACTIVE-TERTIARY !default;
$COLOR-BORDER:                   $__neutral-50__ !default;
$COLOR-FOCUS:                    $COLOR-ON-SURFACE-900 !default;

$COLOR-MARKETING-1:              $__marketing-1__ !default;
$COLOR-MARKETING-2:              $__marketing-2__ !default;
// @end-sass-export-section

$GLOBAL-BACKDROP-BACKGROUND:     rgba($__neutral-50__, 0.8) !default;

$COLOR-ROW-HOVER:                $__neutral-25__ !default;
$COLOR-ROW-SELECTED:             $COLOR-WHITE !default;

$COLOR-INACTIVE-LAYER-COLOR:     $COLOR-WHITE !default;
$COLOR-INACTIVE-LAYER-OPACITY:   0.6 !default;

// Generate background color utility classes.
// @sass-export-section="backgroundColors"
$BACKGROUND-UTILITIES: (
    "alternative": $COLOR-ALT-BACKGROUND,
    "brand":       $COLOR-BRAND,
    "white":       $COLOR-WHITE
) !default;
// @end-sass-export-section


// Generate text color utility classes.
// @sass-export-section="textColorUtilities"
$TEXT-COLOR-UTILITIES: (
    "base":  $COLOR-BASE-TEXT,
    "muted": $COLOR-PALE,
) !default;
// @end-sass-export-section

// Make sure the keys match in both files.
// This needs to be available in ALL brands.
// @sass-export-section="themeColors"
$__theme-colors__: (
    "theme":     $COLOR-WHITE,
    "tile":      $COLOR-WHITE,
) !default;
// @end-sass-export-section
