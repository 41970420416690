/* ------------------------------------*\
   #NOTIFICATIONS
\*------------------------------------ */

$skoda-notification-focus-outline-color:                   $COLOR-WHITE !default;
$skoda-notification-on-light-focus-outline-color:          $GLOBAL-FOCUS-COLOR !default;
$skoda-notification-close-offset-horizontal-on-breakpoint: $GLOBAL-SPACING-UNIT-XXSMALL !default;
$skoda-notification-close-background-color-hover:          $BUTTON-ICON-INTERACTION-BACKGROUND-COLOR-HOVER-ON-DARK !default;
$skoda-notification-on-light-close-background-color-hover: $BUTTON-ICON-INTERACTION-BACKGROUND-COLOR-HOVER-ON-LIGHT !default;
$skoda-notification-button-border:                         $BUTTON-GHOST-ON-DARK-SURFACE-BORDER-WIDTH $BUTTON-GHOST-ON-DARK-SURFACE-BORDER-STYLE $BUTTON-GHOST-ON-DARK-SURFACE-BORDER-COLOR !default;

$skoda-notification-status-icon-offset:                    -(0.15em) !default;
$skoda-notification-status-border-width:                   $NOTIFICATION-STATUS-BORDER-WIDTH !default;
$skoda-notification-status-border-themes:                  map-remove($STATUS-THEMES, "info") !default; // Use regular status themes EXCEPT "info".

/**
 * [1] Compensate misalignment between icon and title/text.
 * [2] Avoid collision with button states.
 */
.c-notification {
    --focus-outline-color: #{$skoda-notification-focus-outline-color};

    &.c-notification--context,
    &[data-marketing-theme] {
        --focus-outline-color: #{$skoda-notification-on-light-focus-outline-color};
    }

    &:not(.c-notification--context, [data-marketing-theme]) .c-btn {
        border: $skoda-notification-button-border;

        &:focus-visible {
            --focus-outline-color: #{$skoda-notification-focus-outline-color};
        }

    }

}

    .c-notification__close {
        @include button-icon-states(
            $background-color-hover: $skoda-notification-close-background-color-hover,
            $focus-outline-color:    $skoda-notification-focus-outline-color,
        );
        top: 0;

        @include media-query("<=xs") {
            right: $skoda-notification-close-offset-horizontal-on-breakpoint; /* [2] */
        }

        .c-notification--context &,
        [data-marketing-theme] & {

            &:hover::after {
                background-color: $skoda-notification-on-light-close-background-color-hover;
            }

            &::after {
                --focus-outline-color: #{$skoda-notification-on-light-focus-outline-color};
            }

        }

    }

    .c-notification__status-icon {
        margin-top: $skoda-notification-status-icon-offset; /* [1] */
    }



// `:not()` before class selector to be compatible with `vary` mixin
:not(.c-notification--context).c-notification {
    position: relative;

    @include vary($create: modifier, $loop: $skoda-notification-status-border-themes) {
        border-left: 0;

        &::after {
            background-color: vary-get("background-color");
        }

    }

    // status border
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: $skoda-notification-status-border-width;
        height: 100%;
    }

}
