/* ------------------------------------*\
    #DATA-OVERVIEW
\*------------------------------------ */

$data-overview-breakpoint:                          s !default;
$data-overview-background-color:                    $COLOR-PALE !default;
$data-overview-box-shadow:                          false !default;
$data-overview-border:                              null !default;
$data-overview-border-radius:                       null !default;
// Paddings
$data-overview-padding-vertical:                    $GLOBAL-SPACING-UNIT !default;
$data-overview-padding-horizontal:                  $GLOBAL-SPACING-UNIT !default;
$data-overview-padding-top:                         $data-overview-padding-vertical !default;
$data-overview-padding-right:                       $data-overview-padding-horizontal !default;
$data-overview-padding-bottom:                      $data-overview-padding-vertical !default;
$data-overview-padding-left:                        $data-overview-padding-horizontal !default;
// Paddings at breakpoint (optional)
$data-overview-reduce-padding-at-breakpoint:        true !default;
$data-overview-padding-vertical-at-breakpoint:      $GLOBAL-SPACING-UNIT-SMALL !default;
$data-overview-padding-horizontal-at-breakpoint:    $GLOBAL-SPACING-UNIT-SMALL !default;
$data-overview-padding-top-at-breakpoint:           $data-overview-padding-vertical-at-breakpoint !default;
$data-overview-padding-right-at-breakpoint:         $data-overview-padding-horizontal-at-breakpoint !default;
$data-overview-padding-bottom-at-breakpoint:        $data-overview-padding-vertical-at-breakpoint !default;
$data-overview-padding-left-at-breakpoint:          $data-overview-padding-horizontal-at-breakpoint !default;

$data-overview-header-spacing-bottom:               $GLOBAL-SPACING-UNIT !default;
$data-overview-header-spacing-bottom-at-breakpoint: $GLOBAL-SPACING-UNIT-SMALL !default;
$data-overview-header-items-spacing-horizontal:     $GLOBAL-SPACING-UNIT-SMALL !default;
$data-overview-header-items-spacing-vertical:       $GLOBAL-SPACING-UNIT-SMALL !default;
$data-overview-header-items-wrapping-width:         5em !default;
$data-overview-header-content-justification:        space-between !default;

$data-overview-enable-icon:                         true !default;
$data-overview-icon:                                get-icon(semantic-edit) !default;
$data-overview-icon-size:                           icon-size(small) !default;
$data-overview-icon-color:                          $COLOR-BRAND !default;

$data-overview-title-font-size:                     $FS3 !default;
$data-overview-title-font-weight:                   $GLOBAL-FONT-WEIGHT-BOLD !default;
$data-overview-title-text-color:                    $COLOR-BRAND !default;

$data-overview-button-icon-size:                    icon-size(small) !default;
$data-overview-button-enable-label:                 false !default;

// Theming
$data-overview-themes: (
    "alternative": (
        "background-color": $COLOR-BASE-BACKGROUND,
    )
) !default;

/**
 * [1]  Fix vertical alignment with other elements in header.
 * [2]  Defensively reset any potential margin-bottom that comes with the
 *      elements that are being used as the title.
 * [4]  Defensively reset any trailing margin-bottom at the end of the
 *      component.
 * [5]  Ensure that the icon always just takes up the space equal to its size.
 * [6]  Words can be broken at appropriate hyphenation points either as
 *      determined by hyphenation characters (soft `&shy;` or hard `U+2010`
 *      hyphen) inside the word or as determined automatically by a
 *      language-appropriate hyphenation resource (e.g. the `lang` attribute for
 *      the document or parent element). Setting a language appropriate to the
 *      content seems to be more important in some browsers (e.g. Firefox) than
 *      in others (Chrome). All in all, hyphenation should be considered a
 *      progressive enhancement, which behaves differently in different browsers.
 * [7]  Break a word onto multiple lines if it’s too large to fit.
 * [8]  Allow header items to wrap.
 * [9]  Vertical space between header items have broken under each other.
 * [10] Hide button label for component breakpoint.
 * [11] Always hide the data-overview header icon as per design.
 * [12] Remove minimum width for buttons in `.c-data-overview`.
 */
.c-data-overview {
    padding-top: $data-overview-padding-top;
    padding-right: $data-overview-padding-right;
    padding-bottom: $data-overview-padding-bottom;
    padding-left: $data-overview-padding-left;
    border: $data-overview-border;
    border-radius: $data-overview-border-radius;
    @if ($data-overview-background-color) {
        background-color: $data-overview-background-color;
    }
    @if ($data-overview-box-shadow) {
        box-shadow: $data-overview-box-shadow;
    }

    @if ($data-overview-reduce-padding-at-breakpoint) {
        @include media-query("<=#{$data-overview-breakpoint}") {
            padding-top: $data-overview-padding-top-at-breakpoint;
            padding-right: $data-overview-padding-right-at-breakpoint;
            padding-bottom: $data-overview-padding-bottom-at-breakpoint;
            padding-left: $data-overview-padding-left-at-breakpoint;
        }
    }

    @include vary($loop: $data-overview-themes) {
        background-color: vary-get("background-color");
    }
}

    .c-data-overview__header {
        display: flex;
        flex-wrap: wrap; /* [8] */
        justify-content: $data-overview-header-content-justification;
        align-items: center;
        margin-top: -($data-overview-header-items-spacing-vertical); /* [9] */
        margin-bottom: $data-overview-header-spacing-bottom;

        @include media-query("<=#{$data-overview-breakpoint}") {
            margin-bottom: $data-overview-header-spacing-bottom-at-breakpoint;
        }

        > * {
            margin-top: $data-overview-header-items-spacing-vertical;  /* [9] */
        }

    }

    @if ($data-overview-enable-icon) {

        .c-data-overview__icon {
            flex-grow: 0; /* [5] */
            flex-shrink: 0; /* [5] */
            flex-basis: $data-overview-icon-size; /* [5] */
            margin-right: $data-overview-header-items-spacing-horizontal;

            .c-icon {
                @include font-size(
                    $font-size: $data-overview-icon-size,
                    $line-height: 1
                );
                display: block; /* [1] */
                color: $data-overview-icon-color;
            }

        }

    } @else {

        .c-data-overview__icon {
            display: none; /* [11] */
        }

    }

    .c-data-overview__title {
        @include responsive-font-size($data-overview-title-font-size);
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: $data-overview-header-items-wrapping-width;
        max-width: 100%;
        margin-right: $data-overview-header-items-spacing-horizontal;
        margin-bottom: 0; /* [2] */
        font-weight: $data-overview-title-font-weight;
        hyphens: auto; /* [6] */
        color: $data-overview-title-text-color;
        overflow-wrap: break-word; /* [7] */

    }

    .c-data-overview__button {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;

        .c-btn__icon::before {
            @include icon-font();
            content: $data-overview-icon;
            font-size: $data-overview-button-icon-size;
        }

        @if ($data-overview-button-enable-label) {
            .c-btn__text {
                @include media-query("<=#{$data-overview-breakpoint}") {
                    @include a11y-hidden(); /* [10] */
                }
            }
        }

        &.c-btn {
            min-width: auto; /* [12] */
        }

    }

    .c-data-overview__body {

        > *:last-child {
            margin-bottom: 0; /* [4] */
        }

    }
