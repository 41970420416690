// /* ------------------------------------*\
//     #FORM-SECTION
// \*------------------------------------ */


.c-form-section {
    @include transition(
        $transition-property: border-color,
    );
}

    .c-form-section__header-icon {
        @include button-icon-states(
            $context-selector: ".c-form-section__header",
        );
    }
