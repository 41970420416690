/* ------------------------------------*\
    #MODAL (Settings)
\*------------------------------------ */

$skoda-modal-close-button-size: $BUTTON-ICON-INTERACTION-SIZE !default;

/**
 * [1] Align icon vertically to the text.
 */
.c-modal {

}

    .c-modal__close-btn {
        @include button-icon-states(
            $size: $skoda-modal-close-button-size,
        );
    }

    .c-modal__status-icon {
        display: flex; /* [1] */
    }
