/* ------------------------------------*\
    #ACCORDION (Scopes)
\*------------------------------------ */

$scope-accordion-in-card-text-align:            left !default;
$scope-accordion-in-card-header-spacing-right:  $accordion-icon-size !default;
$scope-accordion-in-card-header-spacing-bottom: $accordion-header-spacing-top !default;

/**
 * [1] Styling of a single accordion component within a card component.
 * [2] If an accordion with only one child element is placed in a card collapsible
 *     component, paddings have to be removed.
 */

.c-accordion {

    .c-card & { /* [1] */
        border-top: none;
        border-bottom: none;
        background-color: transparent;

        &:only-child { /* [1] */

            text-align: $scope-accordion-in-card-text-align;

            .c-accordion__header {

                @if ($accordion-icon-spacing-right != "auto") {
                    padding-right: ($accordion-icon-spacing-right + $scope-accordion-in-card-header-spacing-right);
                    padding-left: 0;
                }

            }

            .c-accordion__content {
                @include responsive-property("--accordion-panel-padding-top", $scope-accordion-in-card-header-spacing-bottom);
            }

        }

    }

    .c-card:not(.c-card--expandable) & { /* [2] */

        /* stylelint-disable selector-max-specificity */
        &:only-child {

            .c-accordion__header {
                padding-top: 0;
                padding-bottom: 0;
            }

            .c-accordion__title-icon {
                top: 0;
                right: 0;
            }

            .c-accordion__panel {
                /* stylelint-disable length-zero-no-unit */
                --accordion-panel-padding-right: 0px;
                --accordion-panel-padding-bottom: 0px;
                --accordion-panel-padding-left: 0px;
                /* stylelint-enable length-zero-no-unit */
            }

        }
        /* stylelint-enable */

    }
}
