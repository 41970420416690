/* ------------------------------------*\
    #SHAME
\*------------------------------------ */

/**
 * Hacky, nasty code. These bits have to be repaired!
 *
 * Guidelines:
 * - Ticket Reference
 * - Document why this fix is needed
 */

/**
 * This CSS custom property provides the selectors list for `float-grid.js`.
 * Since the list is only complete after the `float-grid-area` mixin has been
 * processed for all Float Grid components, we choose '_shame.scss' as its
 * location.
 */
.o-float-grid {
    --float-grid-selectors: #{$float-grid-selectors};
}

/**
 * When `transition-property: all` is applied, the focus outline is not displayed
 * for buttons that have border applied (not secondary).
 * This only occurs in Safari.
 * By limiting the transition to relevant properties (bg-color, transform) fixes the issue as this
 * is the main property that needs a transition.
 */
.c-btn {
    @include safari-only() {
        transition-property: background-color, transform;
    }
}

/**
 * Use proper line-height for text.
 * line-height variables are used for multiple size and spacing calculations,
 * therefore can't be unitless.
 */
.c-btn__text,
.c-button-group__label,
.c-link__body {
    line-height: 1;
}

/**
 * Ensure proper circular shape.
 * Other properties that determine the height does not result in 1:1 ratio.
 */
.c-btn--action {
    min-height: var(--button-height);
}

/**
 * Disable outline on thumb to avoid rendering issues while dragging.
 */
@include safari-only() {
    .c-custom-range-slider__thumb {

        .c-custom-range-slider:not([disabled]) &::before {
            outline: 0 !important; /* stylelint-disable-line itcss/no-at-important */
        }

    }
}
