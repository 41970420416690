/* ------------------------------------*\
    #DATEPICKER
\*------------------------------------ */

/**
 * [1] This class is currently only a placeholder. It is part of the timepicker's
 *     HTML markup and is designed to prevent changes to the markup. Please refer
 *     to `vendor/flatpickr/flatpickr.scss` and `vendor/flatpickr/flatpickr.settings.scss`
 *     to see the styling of the current timepicker.
 */
.c-datepicker {
    /* [1] */
}
