/* ------------------------------------*\
    #CONTEXT BAR
\*------------------------------------ */

$context-bar-breakpoint:                  $GLOBAL-PAGE-HEAD-BREAKPOINT !default;
$context-bar-icon-size:                   $GLOBAL-PAGE-HEAD-MODULE-ICON-SIZE !default;
$context-bar-icon-size-at-breakpoint:     $GLOBAL-PAGE-HEAD-MODULE-ICON-SIZE-AT-BREAKPOINT !default;
$context-bar-icon-round-size:             $context-bar-icon-size !default;
$context-bar-icon-has-label:              false !default;
$context-bar-icon-color:                  $COLOR-BRAND !default;
$context-bar-icon-is-round:               false !default;
$context-bar-icon-background-color:       $COLOR-BLACK !default;
$context-bar-icon-border-color:           $context-bar-icon-background-color !default;
$context-bar-item-spacing-horizontal:     $GLOBAL-SPACING-UNIT-SMALL !default;
$context-bar-nav-label-text-color:        $COLOR-BASE-TEXT !default;
$context-bar-nav-label-font-weight:       $GLOBAL-FONT-WEIGHT-BOLD !default;
$context-bar-nav-label-font-size:         $FS-3 !default;
$context-bar-nav-label-breakpoint:        $GLOBAL-PAGE-HEAD-BREAKPOINT !default;
$context-bar-nav-label-title-font-weight: $context-bar-nav-label-font-weight !default;
$context-bar-nav-label-title-text-color:  $context-bar-nav-label-text-color !default;
$context-bar-height:                      $GLOBAL-PAGE-HEAD-HEIGHT !default;
$context-bar-height-at-breakpoint:        $GLOBAL-PAGE-HEAD-HEIGHT-AT-BREAKPOINT !default;
$context-bar-link-text-decoration:        none !default;
$context-bar-link-text-color:             $COLOR-BASE-TEXT !default;

/**
 * [1] Toggle visibility of label underneath context bar icon.
*/

.c-context-bar {
    @include responsive-property("height", $context-bar-height);
    display: flex;

    @include media-query("<=#{$context-bar-breakpoint}") {
        height: $context-bar-height-at-breakpoint;
    }

}

    .c-context-bar__panel {
        display: flex;
        width: 100%;
    }

    .c-context-bar__navs {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .c-context-bar__nav {
        @include list-bare();
        display: flex;
        align-items: center;
    }

    .c-context-bar__title {
        font-weight: $context-bar-nav-label-title-font-weight;
        color: $context-bar-nav-label-title-text-color;

        @include media-query("<=#{$context-bar-nav-label-breakpoint}") {
            display: none;
        }
    }

    .c-context-bar__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: $context-bar-item-spacing-horizontal;
        text-decoration: $context-bar-link-text-decoration;
        color: $context-bar-nav-label-text-color;
    }

    .c-context-bar__icon {
        color: $context-bar-icon-color;

        @if ($context-bar-icon-is-round) {
            @include icon-variant(
                $variant:          "round",
                $background-color: $context-bar-icon-background-color,
                $border-color:     $context-bar-icon-border-color,
                $size:             $context-bar-icon-round-size
            );
        } @else {
            font-size: icon-size($context-bar-icon-size);
        }

        @include media-query("<=#{$context-bar-nav-label-breakpoint}") {
            font-size: icon-size($context-bar-icon-size-at-breakpoint);
        }

    }

    @if ($context-bar-icon-has-label) { /* [1] */
        .c-context-bar__icon-label {
            @include responsive-font-size(
                $font-size: $context-bar-nav-label-font-size
            );

            display: block;

            &:hover {
                color: $context-bar-link-text-color;
            }
        }
    } @else {
        .c-context-bar__icon-label {
            display: none;
        }
    }

