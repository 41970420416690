/* ------------------------------------*\
    #TABLES
\*------------------------------------ */

// Breakpoints
$table-horizontal-scroll-breakpoint:             m !default;
$table-min-width:                                map-get($BREAKPOINTS, "s") !default; // @TODO check multibrand breakpoint behaviour BRON-4999

// Table
$table-color:                                    inherit !default;

// Spacings and Borders
$table-column-spacing:                           0 !default;
$table-border-width:                             1px !default;
$table-border-color:                             $COLOR-BASE !default;
$table-border-bottom-color:                      $COLOR-BASE !default;
$table-border-vertical:                          $table-border-width solid $table-border-color !default;
$table-spacing-bottom:                           $GLOBAL-SPACING-UNIT !default;
$table-spacing-vertical:                         0 !default;
$table-spacing-horizontal:                       $GLOBAL-SPACING-UNIT-SMALL !default;

// Table head
$table-thead-background-color:                   null !default;
$table-thead-border-top-width:                   0 !default;
$table-thead-border-top-color:                   $COLOR-BASE !default;
$table-thead-border-bottom-width:                $table-thead-border-top-width !default;
$table-thead-border-bottom-color:                $table-thead-border-top-color !default;
$table-thead-border-top:                         $table-thead-border-top-width solid $table-thead-border-top-color !default;
$table-thead-border-bottom:                      $table-thead-border-bottom-width solid $table-thead-border-bottom-color !default;
$table-thead-alignment-vertical:                 middle !default;
$table-thead-color:                              $COLOR-BLACK !default;
$table-thead-th-font-weight:                     $GLOBAL-FONT-WEIGHT-BOLD !default;
$table-thead-th-height:                          null !default;

// Table headings
$table-th-font-weight:                           $GLOBAL-FONT-WEIGHT-BOLD !default;

// Table cells
$table-cell-narrow-height:                       $GLOBAL-SPACING-UNIT !default;
$table-cell-default-height:                      $GLOBAL-SPACING-UNIT-LARGE !default;
$table-cell-large-height:                        $GLOBAL-SPACING-UNIT-XLARGE !default;
$table-cell-line-height:                         1.2 !default;
$table-cell-padding-vertical:                    $GLOBAL-SPACING-UNIT-XSMALL !default;
$table-cell-padding-horizontal:                  $GLOBAL-SPACING-UNIT-SMALL !default;
$table-cell-padding-small:                       ceil($GLOBAL-SPACING-UNIT-XSMALL * 0.5) !default;
$table-cell-padding-large:                       10px !default; // @TODO add global variable BRON-4999
$table-cell-alignment-vertical:                  middle !default;
$table-cell-alignment-horizontal:                left !default;
$table-cell-font-size:                           $GLOBAL-FONT-SIZE !default;
$table-cell-font-size-small:                     ceil($GLOBAL-FONT-SIZE * 0.8) !default;
$table-cell-font-weight:                         null !default;
$table-cell-border:                              $table-border-width solid $table-border-bottom-color !default;
$table-cell-color:                               $COLOR-BLACK !default;
$table-cell-transition-properties:               background-color, color, opacity !default;

// Table footer
$table-tfoot-font-weight:                        $GLOBAL-FONT-WEIGHT-BOLD !default;

// Icons

// Include or exclude `.c-info-icon` (true: include, false: exclude)
$table-info-icon-color-overwrite:                false !default;

$table-icon-selector:                            if($table-info-icon-color-overwrite, ".c-icon", ".c-icon:not(.c-info-icon)") !default;
$table-icon-size:                                icon-size(small) !default;
$table-icon-spacing:                             $GLOBAL-SPACING-UNIT-XSMALL !default;
$table-thead-icon-color:                         $COLOR-BRAND !default;
$table-cell-icon-color:                          null !default;
$table-sort-icon-size:                           icon-size(small) !default;
$table-sort-icon-asc:                            get-icon(semantic-sort-asc) !default;
$table-sort-icon-desc:                           get-icon(semantic-sort-desc) !default;

// Buttons
$table-button-reset-border-radius:               true !default;

// Table variant Zebra Style
$table-enable-color-scheme:                      true !default;
$table-cell-background-color-odd:                $COLOR-PALE !default;
$table-cell-background-color-even:               $COLOR-WHITE !default;

$table-row-state-border-width:                   $table-border-width !default;
$table-row-state-border-style:                   solid !default;
$table-row-state-border-color:                   currentColor !default;

// Responsive table variant
$table-responsive-breakpoint:                    s !default;

$table-responsive-tr-border-width:               2px !default;
$table-responsive-tr-border-style:               solid !default;
$table-responsive-tr-border-color:               $COLOR-BRAND !default;
$table-responsive-tr-border:                     $table-responsive-tr-border-width $table-responsive-tr-border-style $table-responsive-tr-border-color !default;
$table-responsive-tr-margin-vertical:            $GLOBAL-SPACING-UNIT !default;
$table-responsive-tr-padding-vertical:           $GLOBAL-SPACING-UNIT !default;

$table-responsive-rowheader-font-weight:         $table-th-font-weight !default;
$table-responsive-rowheader-color:               $COLOR-BRAND !default;
$table-responsive-rowheader-padding-bottom:      $GLOBAL-SPACING-UNIT-SMALL !default;

$table-responsive-column-gap:                    $GLOBAL-SPACING-UNIT-SMALL !default;

$table-responsive-columnheader-font-weight:      $table-thead-th-font-weight !default;
$table-responsive-columnheader-color:            $table-thead-color !default;
$table-responsive-columnheader-background-color: $table-thead-background-color !default;

$table-responsive-padding:                       0 !default;

$table-responsive-cell-border-hide-last:         true !default;

$table-inactive-layer-color:                     $COLOR-INACTIVE-LAYER-COLOR !default;
$table-inactive-layer-opacity:                   $COLOR-INACTIVE-LAYER-OPACITY !default;
$table-inactive-layer-reset-styles:              false !default;

// Table cell states

/// Selector Map for states
///
/// key: "Category" (keep in sync with state maps)
/// value: CSS Selector
///
/// @type Map
$table-state-selectors: (
    "hover":                  ":hover",
    "selected":               ".is-selected",
    "selected-hover":         ".is-selected:hover",
    "success":                ".is-success",
    "success-selected":       ".is-success.is-selected",
    "success-selected-hover": ".is-success.is-selected:hover",
    "success-hover":          ".is-success:hover",
    "error":                  ".is-error",
    "error-selected":         ".is-error.is-selected",
    "error-selected-hover":   ".is-error.is-selected:hover",
    "error-hover":            ".is-error:hover",
    "disabled":               ".is-disabled, [aria-disabled='true']",
    "disabled-selected":      ".is-disabled.is-selected, [aria-disabled='true'].is-selected",
) !default;

$table-row-states-overwrites: () !default;

/// (Nested) State Map for cells
///
/// key: "Category" (keep in sync with `$table-state-selectors`)
/// value: nested map
///         key: property
///         value: value
///
///         declaration not generated if set to `null`
///
/// @type Map
$table-row-states: (
    "hover": (
        "color":              null,
        "background-color":   $COLOR-ROW-HOVER,
    ),
    "selected": (
        "color":              null,
        "background-color":   $COLOR-ROW-SELECTED,
        // "border-width":    $table-row-state-border-width,
        "border-style":       $table-row-state-border-style,
        "border-color":       $table-row-state-border-color,
    ),
    "selected-hover": (
        "color":              null,
        "background-color":   $COLOR-ROW-HOVER,
    ),
    "success": (
        "color":              $COLOR-SUCCESS,
        "background-color":   null,
        "border-width":       $table-row-state-border-width,
        "border-color":       $table-row-state-border-color,
        "icon-color":         $COLOR-SUCCESS,
    ),
    "success-hover": (
        "color":              $COLOR-SUCCESS,
        "background-color":   null,
    ),
    "success-selected": (
        "color":              null,
        "background-color":   null,
    ),
    // Not set by default, but can be overwritten per brand.
    "success-selected-hover": (),
    "error": (
        "color":              $COLOR-ALERT,
        "background-color":   null,
        "border-width":       $table-row-state-border-width,
        "border-color":       $table-row-state-border-color,
        "icon-color":         $COLOR-ALERT,
    ),
    "error-hover": (
        "color":              null,
        "background-color":   null,
    ),
    "error-selected": (
        "color":              null,
        "background-color":   null,
    ),
    // Not set by default, but can be overwritten per brand.
    "error-selected-hover":   (),
    // Disabled: handled via overlay
    "disabled": (
        "color":              null,
        "background-color":   null,
        "border-width":       $table-border-width,
        "border-color":       $table-border-color,
    ),
    "disabled-selected": (
        "color":              null,
        "background-color":   null,
        "border-width":       $table-border-width,
        "border-color":       $table-border-color,
    ),
) !default;

$table-cell-states-overwrites: () !default;

$table-cell-states: (
    "success":  map-get($table-row-states, "success"),
    "error":    map-get($table-row-states, "error"),
    "disabled": map-get($table-row-states, "disabled"),
) !default;

$table-row-cell-states-overwrites: () !default;

$table-row-cell-states: (
    "hover": (
        "success":  map-get($table-row-states, "success-hover"),
        "error":    map-get($table-row-states, "error-hover"),
    ),
    "selected": (
        "success":  map-get($table-row-states, "success-selected"),
        "error":    map-get($table-row-states, "error-selected"),
        "disabled": map-get($table-row-states, "disabled-selected"),
    ),
) !default;

// Merge default and overwrite settings to avoid duplications
// and overwrite only necessary settings.
$table-row-states-merged:                 map-deep-merge($table-row-states, $table-row-states-overwrites, 2) !default;
$table-cell-states-merged:                map-deep-merge($table-cell-states, $table-cell-states-overwrites, 2) !default;
$table-row-cell-states-merged:            map-deep-merge($table-row-cell-states, $table-row-cell-states-overwrites, 2) !default;

// Table variant Highlight Table
$table-background-color-highlight:        $COLOR-PALE !default;
$table-background-color-highlight-alt:    $COLOR-WHITE !default;
$table-cell-highlight-border:             $table-cell-padding-horizontal solid $COLOR-WHITE !default;
$table-cell-highlight-border-alt:         $table-cell-padding-horizontal solid $COLOR-PALE !default;

// Table variant Sticky Table
$table-sticky-thead-background-color:     if($table-thead-background-color, $table-thead-background-color, $COLOR-BASE-BACKGROUND) !default;
$table-sticky-cell-background-color:      $COLOR-BASE-BACKGROUND !default;
$table-sticky-has-head-row-shadow:        true !default;
$table-sticky-head-row-shadow-offset:     0px !default; /* stylelint-disable-line length-zero-no-unit */
$table-sticky-head-shadow-size:           20px !default;
$table-sticky-has-first-column-shadow:    true !default;
$table-sticky-first-column-shadow-offset: 0px !default; /* stylelint-disable-line length-zero-no-unit */
$table-sticky-first-column-shadow-size:   $table-sticky-head-shadow-size !default;
$table-sticky-first-column-padding-left:  false !default;
$table-sticky-shadow-gradient:            true !default;
$table-sticky-shadow-color:               $GLOBAL-BOX-SHADOW-COLOR !default;
$table-sticky-shadow-opacity:             0.1 !default;
$table-sticky-shadow-transition-duration: $GLOBAL-TRANSITION-DURATION-MEDIUM !default;

$table-size-variants: (
    "narrow": (
        "padding":   $table-cell-padding-small $table-cell-padding-horizontal,
        "font-size": $table-cell-font-size-small
    ),
    "wide": (
        "padding":   $table-cell-padding-large $table-cell-padding-horizontal,
        "font-size": $table-cell-font-size
    )
) !default;

$table-highlight-themes: (
    "alternative": (
        "background-color": $table-background-color-highlight-alt,
        "border-right":     $table-cell-highlight-border-alt
    ),
) !default;

$table-sticky-themes: (
    "alternative": (
        "background-color-head":   map-get($BACKGROUND-UTILITIES, "alternative"),
        "background-color-column": map-get($BACKGROUND-UTILITIES, "alternative"),
    ),
) !default;

/*
 * [1] Use pseudo elements for horizontal table cell borders (`::before` for the
 *     top border and `::after` for the bottom border) to allow visual spacing
 *     between columns without the side effects that you have with
 *     `border-spacing`.
 * [2] Tables are always full-width by default.
 * [3] Set all columns to an equal width for the fixed width table variant.
 * [4] To make sure the styling of first row is correct when not using `<thead>`
 *     element, as well as when `<thead>` is correctly used.
 * [5] Provide a simple responsive table mechanism by just enabling horizontal
 *     scrolling below a specific breakpoint.
 * [6] Let the auto-width table be wide/narrow as it is.
 * [7] Auto tables are not full-width.
 * [8] Ensure that table is centered.
 * [9] Each column can be as wide as their content.
 * [10] Remove default spacing bottom.
 * [11] Make sure to add a bottom spacing if there is some content following
 *      the table.
 * [12] Styles for sortables in the table header.
 * [14] Keep icon and text combination inline in the data control table.
 * [15] Overwrite the border and spacing settings for buttons.
 * [16] Apply the highlight variant style to certain columns.
 * [17] Wrap text and possible inline elements in `<span>` because of parent's
 *      flex setting. Left empty here for documentation and prospective usage.
 * [18] Table modifier for wide and narrow variants.
 * [19] Collapse cell borders to eliminate visible space between cell borders.
 * [20] Set overflow to `visible` to allow sticky positioning in the first
 *      place.
 * [21] Cover the following rows with the cells of the head row.
 *      `z-index` should be larger than the `z-index` of the disabled rows
 *      overlay ([55]).
 * [22] Cover the following columns with the cells of the first column.
 * [23] Cover the following columns with the first `thead` cell if the table has
 *      sticky a head row and a sticky first column.
 * [24] For zebra style tables inherit the background color of the table row.
 * [25] Do not render a left border on any first children in the top-most rows.
 * [26] Enables borders styles for merged cells.
 * [27] Unused, for prospective future usage and avoid BEM errors.
 * [28] Flex helper to align multiple content inside a cell.
 * [29] Provide the required minimum height for each cell.
 * [30] Cell icon colors differ across brands in color and need scoping in
 *      `thead` and `tbody`.
 * [31] Increase specificity because of high `.c-button` specificity.
 * [32] Ascending and descending sort icons.
 * [33] Increase specificity and exclude `.c-info-icon` and `.c-badge__icon`
 *      from table icon styling.
 * [34] Avoid borders being cutoff if table gets scrollable.
 * [35] Disable all pointer-events to prevent hover-styles for table cell and
 *      row elements only.
 * [36] Re-enable all pointer-events for all table elements that are not
 *      affected via [35].
 * [37] Responsive variant: Increase specificity to be able to override
 *      `min-width` that is being used for the more simple responsive overscroll
 *      effect.
 * [38] Responsive variant: reset `min-width`.
 * [39] Responsive variant: Set all table elements to `display: block`. Note
 *      that the roles of the individual elements must be restored with
 *      aria-roles attributes for accessibility reasons.
 * [40] Responsive variant: Hide `thead` in an accessible way so that users can
 *      still access all content in it with assistive technologies.
 * [41] Responsive variant: Reset some `th`/`td` styles.
 * [42] Responsive variant: Hide bottom border of the last cell in a `tr`.
 * [43] Responsive variant: `[data-columnheader]` is used to repeat the column
 *      header before each cell as pseudo element.
 * [44] Responsive variant: Display the value of `[data-columnheader]` before
 *      each cell as a pseudo element.
 * [45] Responsive variant: Set position to `static` to reset absolute
 *      positioning that is needed for horizontal borders in larger viewports.
 *      See [1].
 * [46] Responsive variant: Enable hyphenation for column headers before each
 *      cell, as there often is not enough width for longer words, especially
 *      in small viewports.
 * [47] Responsive variant: If a background color is set for the responsive
 *      column header, the header should be displayed over the entire row height.
 * [48] Responsive variant: Use CSS grid in modern browsers to layout the
 *      column header and cells side by side.
 * [49] Responsive variant: Disable background color change on hover.
 * [50] Responsive variant: To make sure that really only the first `th` in a
 *      row is used as row header, we use the `:first-child` pseudo class.
 * [51] Responsive variant: Add spacing and border between `tr`s.
 * [52] Inherit `color` so that the color of the root element is not used in
 *      scoped Bronson styles.
 * [53] Optional height for table head.
 * [54] Optionally all styles for pseudo element can be reset via `all: unset`.
 * [55] Add overlay via pseudo element for disabled rows and cells.
 * [56] Make sure the overlay aligns with table borders and column spacings.
 * [57] Add background color with opacity via pseudo element for disabled
 *      rows and cells.
 * [58] Spacing when overlay is used for `is-disabled` or `[aria-disabled]=true`
 *      and `$table-column-spacing` is set.
 * [59] Position the sticky head row or first column to `-1px` (top/left) to
 *      recognize its sticky state via Intersection Observer.
 * [60] Gradients to make a sticky head row or first column stand out.
 * [61] `.has-sticky-head` is set via JS when the head row is sticky.
 * [62] `.has-sticky-column` is set via JS when the first column is sticky.
 * [63] By using CSS custom properties for some properties, we can set and use
 *      these properties more easily in certain contexts.
 *      For example, in sticky row and column states.
 * [64] Fade the shadow in and out smoothly via opacity transition.
 * [65] For brands that have `$table-thead-border-top-width` we can’t set a
 *      shadow for a sticky column in `thead` because `::before` is already
 *      used for the top border.
 */
.c-table-wrapper {

    .c-icon.c-icon.c-icon:not(.c-info-icon):not(.c-badge__icon) { /* [33] */ /* stylelint-disable-line selector-max-class, selector-max-specificity */

        @include font-size(
            $font-size: $table-icon-size,
            $line-height: 1,
        );

    }

    &:not(:last-child) {
        margin-bottom: $table-spacing-bottom; /* [11] */
    }

    &:not(.c-table-wrapper--no-scroll):not([class*="c-table--sticky"]) {
        @include media-query("<=#{$table-horizontal-scroll-breakpoint}") {
            overflow-x: auto; /* [5] */

            table {
                margin-left: ($table-row-state-border-width - 1px); /* [34] */
            }

        }
    }

    &.c-table-wrapper--auto {
        width: auto; /* [6] */
    }

    th,
    td {
        position: relative; /* [1] */
        padding: $table-cell-padding-vertical $table-cell-padding-horizontal;
        font-size: $table-cell-font-size;
        font-weight: $table-cell-font-weight;
        line-height: $table-cell-line-height;
        text-align: $table-cell-alignment-horizontal;

        /* [1] */
        &::before,
        &::after {
            content: "";
            position: absolute;
            right: $table-column-spacing;
            left: 0;
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }

        @if ($table-column-spacing > 0) {

            padding-right: $table-column-spacing;

            &:last-child {
                &::before,
                &::after {
                    right: 0;
                }
            }

        }

    }

    th {
        height: $table-thead-th-height; /* [53] */
        vertical-align: $table-thead-alignment-vertical;
        color: var(--table-cell-color, #{$table-cell-color});

        #{$table-icon-selector} {

            &::before {
                color: if($table-thead-icon-color, $table-thead-icon-color, null); /* [30] */
            }

        }

    }

    td {
        height: $table-cell-default-height; /* [29] */
        vertical-align: $table-cell-alignment-vertical;
        color: var(--table-cell-color, #{$table-cell-color});

        #{$table-icon-selector} {

            &::before {
                color: if($table-cell-icon-color, $table-cell-icon-color, null); /* [30] */
            }

        }

    }

    table {
        width: 100%; /* [2] */
        margin-bottom: 0; /* [10] */
        border-collapse: collapse; /* [19] */
        color: $table-color; /* [52] */

        @at-root .c-table-wrapper:not(.c-table-wrapper--no-scroll) table {
            @include media-query("<=#{$table-horizontal-scroll-breakpoint}") {
                min-width: $table-min-width; /* [5] */
            }
        }

        @at-root .c-table-wrapper--auto table {
            width: auto; /* [7] */
            margin-right: auto; /* [8] */
            margin-left: auto; /* [8] */
            table-layout: auto; /* [9] */

            @include media-query("<=#{$table-horizontal-scroll-breakpoint}") {
                margin-left: 0;
            }

        }

        caption {
            @include a11y-hidden();
        }

        /* stylelint-disable selector-max-compound-selectors, selector-max-combinators, selector-max-type */
        :not(thead) + tbody tr:first-child,
        thead { /* [4] */
            @if ($table-thead-background-color) {
                background-color: $table-thead-background-color;
            }

            // Head cell styling
            th,
            td {
                @if ($table-thead-border-bottom-width > 0) {
                    &::after {
                        border-bottom: $table-thead-border-bottom;
                    }
                }

                @if ($table-thead-border-top-width > 0) {
                    &::before {
                        border-top: $table-thead-border-top;
                    }
                }

                vertical-align: $table-thead-alignment-vertical;
                color: $table-thead-color;
            }

            th {
                font-weight: if($table-thead-th-font-weight, $table-thead-th-font-weight, null);
            }

        }
        /* stylelint-enable */

        /* stylelint-disable selector-max-compound-selectors, selector-max-combinators, selector-max-type */
        tbody {

            // Body cell styling
            th,
            td {
                @include transition($transition-property: #{$table-cell-transition-properties});
                background-color: var(--table-cell-background-color);

                @if ($table-border-width > 0) {
                    &::after {
                        border-bottom: $table-border-vertical;
                    }
                }
            }

            /* stylelint-disable-next-line order/order */
            @include vary($loop: $table-row-states-merged, $create: "custom") {
                // Safe-guard if opted-out of map, e.g. value is set to `null`.
                @if (map-get($table-row-states-merged, $vary-loop-entity)) {
                    // Get selector based on map-key from selector map.
                    $_selector: map-get($table-state-selectors, $vary-loop-entity);

                    // row states
                    tr#{$_selector} {

                        @if (vary-get("background-color")) {
                            --table-cell-background-color: #{vary-get("background-color")}; /* [63] */
                        }
                        @if (vary-get("color")) {
                            --table-cell-color: #{vary-get("color")}; /* [63] */
                        }

                        th,
                        td {
                            // border
                            &::after {
                                border-width: vary-get("border-width");
                                border-color: vary-get("border-color");
                            }

                        }

                    }
                }
            }

            @include vary($loop: $table-cell-states-merged, $create: "custom") {
                // Safe-guard if opted-out of map, e.g. value is set to `null`.
                @if (map-get($table-cell-states-merged, $vary-loop-entity)) {
                    // Get selector based on map-key from selector map.
                    $_selector: map-get($table-state-selectors, $vary-loop-entity);

                    // Add selector to increase / match specificity of row states.
                    tr {

                        // cell states
                        th#{$_selector},
                        td#{$_selector} {
                            background-color: vary-get("background-color");
                            color: vary-get("color");

                            // border
                            &::after {
                                border-width: vary-get("border-width");
                                border-color: vary-get("border-color");
                            }

                        }

                    }

                }
            }

            // Use "native" maps & map-functions as vary is used for theming, not such deep nesting.
            @each $row-state, $cell-states in $table-row-cell-states-merged {
                $row-selector: map-get($table-state-selectors, $row-state);

                /* stylelint-disable max-nesting-depth */
                @each $cell-state, $cell-declarations in $cell-states {
                    $cell-selector: map-get($table-state-selectors, $cell-state);

                    tr#{$row-selector} {

                        th#{$cell-selector},
                        td#{$cell-selector} {
                            background-color: map-get($cell-declarations, "background-color");
                            color: map-get($cell-declarations, "color");

                            // border
                            &::after {
                                border-color: map-get($cell-declarations, "border-color");
                            }
                        }

                    }

                }
                /* stylelint-enable max-nesting-depth */
            }

            tr,
            th,
            td {

                /* stylelint-disable-next-line max-nesting-depth */
                &.is-disabled,
                &[aria-disabled="true"] {
                    pointer-events: none;
                }

            }

            th {
                font-weight: $table-th-font-weight;
            }

            > tr:first-child {
                border-left: none; /* [25] */
            }

        }
        /* stylelint-enable */

        /* stylelint-disable selector-max-compound-selectors, selector-max-combinators, selector-max-type */
        tfoot {

            th {
                font-weight: $table-tfoot-font-weight;
            }

        }
        /* stylelint-enable */

    }

    // disabled rows and cells
    tr:where([aria-disabled="true"], .is-disabled),
    td:where([aria-disabled="true"], .is-disabled) {
        position: relative;

        // Overlay
        &::after {
            @if ($table-inactive-layer-reset-styles) {
                all: unset; /* [54] */
            }
            content: ""; /* [55] */
            position: absolute; /* [55] */
            z-index: 10; /* [55] */
            top: 0; /* [56] */
            bottom: 0; /* [56] */
            left: 0; /* [56] */
            right: 0; /* [56] */
            background-color: $table-inactive-layer-color; /* [57] */
            opacity: $table-inactive-layer-opacity; /* [57] */
            pointer-events: none;
        }

    }

    @if ($table-column-spacing > 0) {
        td:where([aria-disabled="true"], .is-disabled) {

            &::after {
                right: $table-column-spacing; /* [58] */
            }
        }

    }

}

    @include vary($create: custom, $loop: $table-size-variants) {

        .c-table--#{$vary-loop-entity} { /* [18] */

            th,
            td {
                height: vary-get("height");
                padding: vary-get("padding");
                font-size: vary-get("font-size");
            }

        }

    }

    .c-table__layout--fixed,
    .c-table--layout-fixed { /* [3] */

        table {
            table-layout: fixed;
        }

    }

    .c-table__btn { /* [15] */

        &,
        &:hover,
        &:focus,
        &:active {
            margin: 0;
            padding: 0;
            border: important(0, true);
            box-shadow: none;

            @if ($table-button-reset-border-radius) {
                border-radius: important(0, true);
            }

        }

    }

    /* stylelint-disable selector-max-class, selector-max-specificity */
    .c-table__btn-sort.c-table__btn-sort.c-table__btn-sort.c-table__btn-sort { /* [31] */
        --button-flex-direction: row-reverse;

        &,
        &:hover,
        &:focus,
        &:active {
            justify-content: flex-end;
            min-height: 0;
            border: none;
            font-size: inherit;
            font-weight: inherit;
            letter-spacing: inherit;
            line-height: inherit;
            text-transform: inherit;
            background: none; /* 35 */
            color: inherit;

            .c-btn__icon {
                @include font-size(
                    $font-size:   $table-sort-icon-size,
                    $line-height: 1
                );

                &::before {
                    @include icon-font();
                    vertical-align: bottom;
                    color: if($table-thead-icon-color, $table-thead-icon-color, null);
                }
            }
        }

        &,
        &[data-sort-order="asc"] { /* [32] */
            .c-btn__icon::before {
                content: $table-sort-icon-asc;
            }
        }

        &[data-sort-order="desc"] { /* [32] */
            .c-btn__icon::before {
                content: $table-sort-icon-desc;
            }
        }

    }
    /* stylelint-enable */

    .c-table__sortable-header-text { /* [17] */
    }

    .c-table__cell { /* [27] */
    }

    .c-table__cell__icon {
        @include font-size(
            $font-size:   $table-icon-size,
            $line-height: 1
        );
        margin-right: $table-icon-spacing;
    }

    .c-table__cell-align { /* [28] */
        display: flex;
        flex-direction: row;
        align-items: center;
    }


/**
 * Each table variant has its own min-height.
*/
.c-table--narrow {

    td {
        height: $table-cell-narrow-height; /* [29] */
    }

}

.c-table--wide {

    td {
        height: $table-cell-large-height; /* [29] */
    }

}

.c-table--no-hover {

    td,
    th,
    tr {
        &,
        td,
        th,
        tr {
            pointer-events: important(none, true); /* [35] */
        }

        > *:not(td):not(th):not(tr) {
            pointer-events: important(all, true); /* [36] */
        }

    }

}

/**
 * Individual table cells can be highlighted via the
 * `.c-table--highlight` class.
*/
.c-table--highlight {

    tr > :nth-child(n+3):not(th) { /* [16] */
        background-color: $table-background-color-highlight;

        @include vary($loop: $table-highlight-themes) {
            background-color: vary-get("background-color");
        }

        &:not(:last-child) {
            border-right: $table-cell-highlight-border;

            @include vary($loop: $table-highlight-themes) {
                border-right: vary-get("border-right");
            }
        }

    }
}



/**
 * Table with color scheme
 */
@if ($table-enable-color-scheme) {

    .c-table--colored {
        tbody {

            tr:nth-of-type(odd) {
                --table-cell-background-color: #{$table-cell-background-color-odd};
                background-color: $table-cell-background-color-odd;
            }

            tr:nth-of-type(even) {
                --table-cell-background-color: #{$table-cell-background-color-even};
                background-color: $table-cell-background-color-even;
            }
        }
    }

}



/**
 * Table with border scheme and joined cells and rows
 */
.c-table--bordered {

    border-spacing: $table-spacing-horizontal $table-spacing-vertical;

    td {
        padding-top: $table-cell-padding-vertical;
        padding-right: $table-cell-padding-horizontal;
        padding-bottom: $table-cell-padding-vertical;
        padding-left: $table-cell-padding-horizontal;
    }

    td,
    th {

        + td {
            border-left: $table-cell-border;
        }

    }

}



/**
* Table with sticky head row
*/
.c-table--sticky-head {
    overflow: visible; /* [20] */

    thead {
        position: relative;
        z-index: 20; /* [21] */

        tr {
            > * { /* stylelint-disable-line selector-max-combinators, selector-max-compound-selectors */
                position: sticky;
                top: calc(var(--bron-sticky-offset, 0px) - 1px); /* [59] */
                background-color: $table-sticky-thead-background-color; /* [21] */

                @include vary($loop: $table-sticky-themes) {
                    background-color: vary-get("background-color-head");
                }
            }

            @if ($table-sticky-has-head-row-shadow) {
                &::after { /* [60] */
                    @include transition(opacity, $table-sticky-shadow-transition-duration); /* [64] */
                    content: "";
                    position: absolute;
                    top: calc(100% + #{$table-sticky-head-row-shadow-offset});
                    right: 0;
                    left: 0;
                    height: $table-sticky-head-shadow-size;
                    opacity: 0; /* [64] */
                    @if ($table-sticky-shadow-gradient) {
                        background-image: linear-gradient(to bottom, rgba($table-sticky-shadow-color, $table-sticky-shadow-opacity), rgba($table-sticky-shadow-color, 0));
                    } @else {
                        background-color: rgba($table-sticky-shadow-color, $table-sticky-shadow-opacity);
                    }
                }
            }
        }

    }

    &.has-sticky-head { /* [61] */

        thead tr {
            position: sticky;
            z-index: 100;
            top: calc(var(--bron-sticky-offset, 0px) - 1px); /* stylelint-disable-line scale-unlimited/declaration-strict-value */

            @if ($table-sticky-has-head-row-shadow) {
                &::after {
                    opacity: 1; /* [64] */
                }
            }
        }

    }

}

/**
* Table with sticky first column
*/
.c-table--sticky-column {
    overflow: visible; /* [20] */

    @if ($table-sticky-has-first-column-shadow) {

        #{if($table-thead-border-top-width > 0, ":not(thead) > tr", "tr")} > :first-child::before { /* [65] */
            @include transition(opacity, $table-sticky-shadow-transition-duration); /* [64] */
            content: "";
            position: absolute;
            top: 0;
            left: calc(100% + #{$table-sticky-first-column-shadow-offset});
            bottom: 0;
            width: $table-sticky-first-column-shadow-size;
            opacity: 0; /* [64] */
            @if ($table-sticky-shadow-gradient) {
                background-image: linear-gradient(to right, rgba($table-sticky-shadow-color, $table-sticky-shadow-opacity), rgba($table-sticky-shadow-color, 0)); /* [60] */
            } @else {
                background-color: rgba($table-sticky-shadow-color, $table-sticky-shadow-opacity);
            }
        }

        &.has-sticky-column { /* [62] */

            tr > :first-child {
                padding-left: if($table-sticky-first-column-padding-left, $table-sticky-first-column-padding-left, null);
                background-color: var(--table-cell-background-color, #{$table-sticky-cell-background-color}); /* [22] */
                color: var(--table-cell-color);
                @include vary($loop: $table-sticky-themes) {
                    background-color: vary-get("background-color-column");
                }

                &::before {
                    opacity: 1; /* [64] */
                }
            }

        }
    }

    tr > :first-child {
        position: sticky;
        z-index: 1; /* [22] */
        left: -1px; /* [59] */ /* stylelint-disable-line scale-unlimited/declaration-strict-value */

    }

    thead {

        tr > :first-child { /* stylelint-disable-line selector-max-combinators, selector-max-compound-selectors */
            z-index: 2; /* [23] */
        }

    }

    &.c-table--colored:not(.has-sticky-column) {

        tbody {

            /* stylelint-disable selector-max-specificity */
            th:first-child,
            td:first-child {
                background-color: inherit;  /* [24] */
            }
            /* stylelint-enable selector-max-specificity */

        }

    }

}

/**
* Responsive table
*/
.c-table--responsive {
    @include media-query("<=#{$table-responsive-breakpoint}") {

        &.c-table--responsive > table { /* [37] */
            min-width: 0; /* [38] */

            tr:hover {
                /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors, selector-max-type */
                th,
                td {
                    background-color: initial; /* [49] */
                }
            }

        }

        table,
        thead,
        tbody,
        tfoot,
        tr,
        th,
        td {
            display: block; /* [39] */
        }

        thead {
            @include a11y-hidden(); /* [40] */
        }

        th,
        td {
            height: auto; /* [41] */
            padding-right: $table-responsive-padding; /* [41] */
            padding-left: $table-responsive-padding; /* [41] */

            &:last-child::after {
                @if ($table-responsive-cell-border-hide-last) {
                    display: none; /* [42] */
                }
            }

        }

        /* Column Header */
        [data-columnheader] { /* [43] */
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: $table-responsive-column-gap;

            &::before {
                content: attr(data-columnheader); /* [44] */
                position: static; /* [45] */
                font-weight: $table-responsive-columnheader-font-weight;
                vertical-align: top;
                hyphens: auto; /* [46] */
                background-color: if($table-responsive-columnheader-background-color, $table-responsive-columnheader-background-color, null);
                color: $table-responsive-columnheader-color;

                @if ($table-responsive-columnheader-background-color) {
                    margin-top: -($table-cell-padding-vertical); /* [47] */
                    margin-bottom: -($table-cell-padding-vertical); /* [47] */
                    padding: $table-cell-padding-vertical $table-cell-padding-horizontal; /* [47] */
                }
            }

        }

        /* Row Header */
        th:first-child { /* [50] */
            padding-bottom: if($table-responsive-rowheader-padding-bottom, $table-responsive-rowheader-padding-bottom, null);
            font-weight: if($table-responsive-rowheader-font-weight, $table-responsive-rowheader-font-weight, null);
            color: if($table-responsive-rowheader-color, $table-responsive-rowheader-color, null);
        }

        tr + tr {
            margin-top: if($table-responsive-tr-margin-vertical > 0, $table-responsive-tr-margin-vertical, null); /* [51] */
            padding-top: if($table-responsive-tr-padding-vertical > 0, $table-responsive-tr-padding-vertical, null); /* [51] */
            border-top: if($table-responsive-tr-border, $table-responsive-tr-border, null); /* [51] */
        }

    }
}
