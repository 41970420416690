/* ------------------------------------*\
    #INBOX-FILTER
\*------------------------------------ */

$inbox-filter-spacing-bottom:      $GLOBAL-SPACING-UNIT-LARGE !default;
$inbox-filter-background-color:    null !default;
$inbox-filter-padding:             0 !default;

$inbox-filter-title-margin-bottom: $GLOBAL-SPACING-UNIT !default;
// Magic number to make some space for the badge when the title wraps.
$inbox-filter-title-line-height:   1.5 !default;
$inbox-filter-title-font-size:     $FS1 !default;
$inbox-filter-title-font-weight:   $GLOBAL-FONT-WEIGHT-BOLD !default;

$inbox-filter-badge-margin-left:   $GLOBAL-SPACING-UNIT-XXSMALL !default;

.c-inbox-filter {
    margin-bottom: $inbox-filter-spacing-bottom;
    background-color: $inbox-filter-background-color;
}

    .c-inbox-filter__title {
        @include responsive-font-size(
            $font-size: $inbox-filter-title-font-size,
            $line-height: $inbox-filter-title-line-height,
        );
        margin: 0;
        margin-bottom: if($inbox-filter-title-margin-bottom, $inbox-filter-title-margin-bottom, null);
        padding: $inbox-filter-padding;
        font-weight: $inbox-filter-title-font-weight;
    }

    .c-inbox-filter__badge {
        margin-left: $inbox-filter-badge-margin-left;
    }

    .c-inbox-filter__body {
        padding: $inbox-filter-padding;
    }
