// /* ------------------------------------*\
//     #PROGRESS
// \*------------------------------------ */

$PROGRESS-HEIGHT:                      4px !default;
$PROGRESS-HEIGHT-LARGE:                10px !default;
$PROGRESS-PROGRESS-BACKGROUND:         $COLOR-HIGHLIGHT !default;
$PROGRESS-PROGRESS-BACKGROUND-SUCCESS: $COLOR-SUCCESS !default;
$PROGRESS-PROGRESS-BACKGROUND-ERROR:   $COLOR-ALERT !default;
$PROGRESS-BORDER-RADIUS:               null !default;
$PROGRESS-TRACK-BACKGROUND:            $COLOR-SUBTLE !default;

$PROGRESS-THEMES: (
    "alternative": (
        "background-color": $COLOR-WHITE
    )
) !default;
