/* ------------------------------------*\
   #PRODUCT-CARD
\*------------------------------------ */

/**
 * @TODO:
 * extract card-pattern styles to settings layer for reusability and consistency.
 */
$product-card-width-queries: (
    vertical:   0,
    compact:    450,
    horizontal: 680,
) !default;

$product-card-gap:                             $GLOBAL-SPACING-UNIT-SMALL !default;
$product-card-padding:                         $GLOBAL-SPACING-UNIT !default;
$product-card-shadow:                          $CARD-SHADOW !default;
$product-card-background-color:                $COLOR-BASE-BACKGROUND !default;
$product-card-border:                          null !default;
$product-card-border-radius:                   null !default;

$product-card-inactive-opacity:                $OPACITY-DISABLED !default;
$product-card-inactive-shadow:                 $CARD-INACTIVE-SHADOW !default;
$product-card-inactive-background-color:       null !default;
$product-card-inactive-border:                 null !default;

$product-card-empty-opacity:                   null !default;
$product-card-empty-shadow:                    $product-card-inactive-shadow !default;
$product-card-empty-background-color:          $product-card-inactive-background-color !default;
$product-card-empty-border:                    $product-card-inactive-border !default;

$product-card-header-module-spacing:           $GLOBAL-SPACING-UNIT-XSMALL !default;

$product-card-features-spacing:                $product-card-header-module-spacing !default;

$product-card-title-size:                      $FS3 !default;
$product-card-title-spacing:                   $GLOBAL-SPACING-UNIT-XSMALL !default;
$product-card-title-font-weight:               $GLOBAL-FONT-WEIGHT-BOLD !default;
$product-card-title-color:                     null !default;
$product-card-subtitle-size:                   $FS2 !default;
$product-card-subtitle-font-weight:            $product-card-title-font-weight !default;
$product-card-subtitle-color:                  $product-card-title-color !default;

$product-card-tag-list-title-size:             $FS2 !default;
$product-card-tag-list-title-line-height:      null !default;
$product-card-tag-list-title-font-weight:      $product-card-title-font-weight !default;
$product-card-tag-list-title-color:            $GLOBAL-HEADING-COLOR !default;

$product-card-tag-list-module-spacing:         $product-card-header-module-spacing !default;

$product-card-inline-basis-width:              0.4 !default; // 40%
$product-card-inline-element-count:            3 !default; // amount of inline elements

$product-card-media-fallback-background-color: $COLOR-PALE !default;
$product-card-media-fallback-icon-color:       $COLOR-SUBTLE !default;
$product-card-media-fallback-icon-size:        120px !default; // magic number

$product-card-price-header-spacing:            $GLOBAL-SPACING-UNIT-XSMALL !default;

$product-card-progress-spacing:                $GLOBAL-SPACING-UNIT-SMALL !default;
$product-card-progress-label-spacing:          $GLOBAL-SPACING-UNIT-XSMALL !default;
$product-card-progress-label-font-size:        $FS-1 !default;

$product-card-panel-spacing:                   $GLOBAL-SPACING-UNIT !default;
$product-card-panel-title-spacing:             $GLOBAL-SPACING-UNIT-SMALL !default;

$product-card-buttons-spacing:                 $GLOBAL-SPACING-UNIT-SMALL !default;
$product-card-buttons-max-width:               $BUTTON-MAX-WIDTH-IN-CONTAINER !default;

$product-card-expandable-button-spacing:       $GLOBAL-LINE-HEIGHT !default;

/**
 * [1] Apply spacing to first child, not to panel to avoid visible spacing
 *     when panel is collapsed.
 * [2] Scoped modifications to price-box:
 *     right-aligned, more condensed and without border.
 *     As modifications are quite special,
 *     a general modifier is hard to abstract. @TODO: reconsider
 * [3] Place features on the right side in horizontal mode.
 * [4] Display fav-button and tag below each other in larger container widths.
 * [5] Reserve enough space for tag-list to show items horizontally
 *     if space is available.
 * [6] Align to right in horizontal mode.
 * [7] Keep flex-items in place and do not distribute, e.g. in context of
 *     equal-height grid.
 * [8] Keep favorites aligned top, especially when tag-list wraps
 *     (in horizontal mode).
 * [9] Decrease available spacing for pricing to increase spacing for title and
 *      image.
 * [10] Use `grid-template-rows` to transition the height.
 *      (see https://www.stefanjudis.com/snippets/how-to-animate-height-with-css-grid/)
 *      The trigger button must be taken out of the grid via absolute positioning
 *      for this technique to work.
 * [11] Defensively reset spacing from arbitrary element.
 * [12] Roughly align bottom of tags with option tag on right side in horizontal
 *      mode if title is not multiline. As this also depends on title and
 *      fav-button size it can not be perfectly aligned.
 * [13] Align to right (due to reversed direction).
 * [14] Make the image always fit inside the element’s aspect ratio.
 * [15] Make child elements inactive by disabling pointer events and reduce
 *      opacity.
 * [16] Position button(s) absolutely in empty card variant.
 * [17] Add a fallback element with a background color and a icon.
 *      - Aspect ratio should be controlled via `.o-ratio.
 *      - Use `.c-icon` and `.c-icon--[semantic-ICON]` to set the icon.
 * [18] Align fav-button always to the right, regardless of other
 *      `.c-product-card__features__item`s.
 * [19] Move status visually to the top in small container widths.
 * [20] Overwrite `order: -1` to display status  in source order in larger
 *      container widths.
 * [21] Set `.c-product-card__inline` elements width to a percentage of the
 *      parent’s width, taking flex gaps into account. The elements are not
 *      allowed to grow, only to shrink. This keeps the widths of
 *      `.c-product-card__media` and `.c-product-card__meta` elements always the
 *      same even if one or more `.c-product-card__inline` are not present
 *      (e.g. `.c-product-card__pricing` in `.c-product-card--inactive` and
 *      `.c-product-card--empty`).
 *      `flex-basis` calculation parts:
 *          - 100% => full parent widths
 *          - #{$product-card-gap} * #{$product-card-inline-element-count - 1}
 *            => total width of all gaps
 *          - #{$product-card-inline-basis-width}
 *            => max. percentage element width in decimal format (40% => 0.4)
 * [22] Overwrite settings from [21] for the pricing element to let it grow into
 *      the available space.
 * [23] Swap position of tag label and fav-button.
 * [24] Push the tag label to the left in small container widths, even if it is
 *      the only child of `.c-product-card__features`.
 * [25] Order in vertical mode via DOM order:
 *      header + meta | media | pricing
 *      Order in horizontal mode (visually):
 *      header + media | media | pricing
 */

.c-product-card {
    position: relative; /* [16] */
    display: flex;
    gap: $product-card-gap;
    flex-wrap: wrap;
    align-content: flex-start; /* [7] */
    padding: $product-card-padding;
    border: $product-card-border;
    border-radius: $product-card-border-radius;
    background-color: $product-card-background-color;
    box-shadow: if($product-card-shadow, $product-card-shadow, null);

    > * {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
    }

    .c-price-box__header {
        padding: 0; /* [2] */
        padding-bottom: $product-card-price-header-spacing;
    }

}

    .c-product-card__media {
    }

        .c-product-card__media-fallback { /* [17] */
            background-color: $product-card-media-fallback-background-color;
        }

            .c-product-card__media-fallback__icon { /* [17] */
                display: flex;
                justify-content: center;
                align-items: center;
                color: $product-card-media-fallback-icon-color;

                &::before {
                    @include font-size(
                        $font-size:   $product-card-media-fallback-icon-size,
                        $line-height: 1
                    );
                }

            }

    .c-product-card__image {
        object-fit: contain; /* [14] */
    }

    .c-product-card__title {
        @include responsive-font-size($product-card-title-size);
        margin-bottom: $product-card-title-spacing;
        font-weight: $product-card-title-font-weight;
        color: $product-card-title-color;
    }

    .c-product-card__subtitle {
        @include responsive-font-size($product-card-subtitle-size);
        margin-bottom: 0;
        font-weight: $product-card-subtitle-font-weight;
        color: $product-card-subtitle-color;
    }

    .c-product-card__header {
        display: flex;
        gap: $product-card-gap;
        flex-direction: column;
        flex-wrap: wrap;

        > * {
            flex-grow: 1;
            flex-shrink: 1;
        }

    }

    .c-product-card__features {
        display: flex;
        flex-direction: row-reverse; /* [23] */
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: $product-card-gap;
    }

        .c-product-card__features__item--tag {
            margin-right: auto; /* [24] */
        }

    .c-product-card__tag-list {
        // flex-container
        display: flex;
        gap: $product-card-tag-list-module-spacing;
        flex-direction: column;
        justify-content: space-between; /* [12] */

        // flex-item
        flex-grow: 3; /* [5] */
    }

        .c-product-card__tag-list__title {
            @include responsive-font-size(
                $font-size:   $product-card-tag-list-title-size,
                $line-height: $product-card-tag-list-title-line-height,
            );
            margin-bottom: 0;
            font-weight: $product-card-tag-list-title-font-weight;
            color: $product-card-tag-list-title-color;
        }

    .c-product-card__body {

        > :last-child {
            margin-bottom: 0; /* [11] */
        }

    }

    .c-product-card__buttons {
        --button-flex-direction: row-reverse;

        // flex-container
        display: flex;
        gap: $product-card-buttons-spacing;
        flex-wrap: wrap;
        justify-content: center;

        // flex-item
        flex-grow: 1;
        flex-shrink: 1;
        margin-left: auto;
        margin-right: auto;

        .c-product-card__status + & {
            flex-basis: auto;
        }

    }

        .c-product-card__buttons__item {
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: 100%;
            max-width: $product-card-buttons-max-width;
        }

    .c-product-card__status {
        order: -1; /* [19] */
    }

    .c-product-card__progress {
    }

        .c-product-card__progress__wrapper {
            margin-top: $product-card-progress-spacing;
        }

        .c-product-card__progress__label {
            @include responsive-font-size($product-card-progress-label-font-size);
            margin-top: $product-card-progress-label-spacing;
            margin-bottom: 0;
            text-align: center;
        }

    .js-card-expandable__trigger {

        .c-btn__icon {
            @include transition(
                $transition-property: transform
            );
        }

        &[aria-expanded="true"] {

            .c-btn__icon {
                transform: rotateX(180deg);
            }

        }

    }

    .c-product-card__expandable {
        @include transition(); /* [10] */
        position: relative; /* [10] */
        display: grid; /* [10] */
        grid-template-rows: 0fr; /* [10] */
        padding-top: $product-card-expandable-button-spacing; /* [10] */

        &.is-open {
            grid-template-rows: 1fr; /* [10] */

        }

        > .c-btn:first-child {
            position: absolute;/* [10] */
        }

    }

    .c-product-card__expandable-panel {
        overflow: hidden; /* [10] */

        > *:first-child {
            margin-top: $product-card-panel-spacing; /* [1] */
        }

        > *:not(:last-child) {
            margin-bottom: $product-card-panel-spacing;
        }

    }

        .c-product-card__expandable-panel__title {
            margin-bottom: $product-card-panel-title-spacing;
        }

    .c-product-card--inactive {
        border: $product-card-inactive-border;
        background-color: $product-card-inactive-background-color;
        box-shadow: $product-card-inactive-shadow;

        > *:not(.c-product-card__buttons):not(.c-product-card__status) {
            pointer-events: none; /* [15] */
            opacity: $product-card-inactive-opacity; /* [15] */
        }

    }

    .c-product-card--empty {
        border: $product-card-empty-border;
        background-color: $product-card-empty-background-color;
        box-shadow: $product-card-empty-shadow;

        > *:not(.c-product-card__buttons):not(.c-product-card__status) {
            pointer-events: none; /* [15] */
            opacity: $product-card-empty-opacity; /* [15] */
        }

    }

[data-container-query="product-card"] {
    --container-widths: "#{cq-convert-data($product-card-width-queries)}";

    @include container($map: $product-card-width-queries, $size: "compact") {

        .c-product-card__media,
        .c-product-card__meta,
        .c-product-card__status {
            flex-basis: calc(50% - #{$product-card-gap});
        }

        &.c-product-card--empty {

            .c-product-card__buttons {
                position: absolute; /* [16] */
                bottom: $product-card-padding;
                right: $product-card-padding;
            }

        }

        .c-product-card__status {
            order: initial; /* [20] */
        }

        .c-product-card__media,
        .c-product-card__header {
            order: -1; /* [25] */
        }

    }

    @include container($map: $product-card-width-queries, $size: "horizontal") {

        /**
         * HEADER
         */
        .c-product-card__header {
            flex-direction: row;
        }

        /**
         * FEATURES
         */
        .c-product-card__features {
            // flex-container
            gap: $product-card-features-spacing;
            flex-direction: column-reverse; /* [4] */
            justify-content: flex-end; /* [8] */
            align-items: flex-end; /* [6] */

            // flex-item
            order: 1; /* [3] */
        }

            .c-product-card__features__item--fav-button {
                order: 1; /* [18] */
            }

        .c-product-card__features__item--tag {
            margin-right: initial; /* [24] */
        }

        .c-product-card__inline {
            flex-grow: 0; /* [21] */
            flex-shrink: 1; /* [21] */
            flex-basis: calc((100% - #{$product-card-gap} * #{$product-card-inline-element-count - 1}) * #{$product-card-inline-basis-width}); /* [21] */
        }

        .c-product-card__pricing {
            flex-grow: 1; /* [22] */
            flex-basis: 0; /* [22] */
            min-width: 0; /* [22] */
        }

        /**
         * PRICE BOX
         */
         /* [2] */
         .c-price-box__header {
            border: 0;
        }

        /* [2] */
        .c-price-box__header,
        .c-price-box__body__content,
        .c-price-box__additional-info__text {
            text-align: right;
        }

        /* [2] */
        .c-price-box__body {
            align-items: flex-end;
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
        }

        .c-price-box__discount {
            justify-content: flex-end;
        }

        /**
         * PROGRESS
         */
        .c-product-card__progress__label {
            text-align: right;
        }

        .c-product-card__buttons {
            // flex-container
            flex-direction: row-reverse;
            justify-content: flex-start; /* [13] */
            align-items: flex-start;

            // flex-item
            flex-grow: 1;
        }

            .c-product-card__buttons__item {
                flex-basis: auto;
            }

    }

}
