/* ------------------------------------*\
    #LAYOUT
\*------------------------------------ */

@use "sass:math" as *;

/**
 * The layout system uses `display: inline-block` to create an extremely
 * powerful, flexible alternative to the traditional grid system. Combine the
 * layout items with the widths-classes found in
 * `10-utilities/_utilities.widths.scss`.
 *
 * [1a] These properties accept a responsive map (responsive-property).
 */

$layout-gutter:                     $GLOBAL-SPACING-UNIT !default; /* [1a] */
$layout-item-spacing-vertical:      $GLOBAL-SPACING-UNIT !default; /* [1a] */

$layout-divider-spacing-horizontal: ($layout-gutter * 2) !default;
$layout-divider-spacing-vertical:   $layout-item-spacing-vertical !default;
$layout-divider-width:              1px !default;
$layout-divider-style:              solid !default;
$layout-divider-color:              inherit !default;
$layout-divider-offset-vertical:    ceil($layout-divider-spacing-vertical - $layout-divider-width * 0.5) !default;
$layout-divider-offset-top:         null !default;

$layout-gap-font-size-reset:        $FS0 !default;

/**
 * [1] Allows us to use the layout object on any type of element.
 * [2] We need to defensively reset any box-model properties.
 * [3] Use the negative margin trick for multi-row grids:
 *     https://csswizardry.com/2011/08/building-better-grid-systems/
 * [4] Workaround for the HTML-comments trick to remove any whitespace between
 *     items in the markup.
 * [4a] Redefine a font-size to compensate [4].
 * [5] Required in order to combine fluid widths with fixed gutters.
 * [6] Allows us to manipulate grids vertically, with text-level properties, etc.
 * [7] Default item alignment is top.
 * [8] By default, all layout items are full-width.
 * [9] Gutters provided by left padding:
 *     https://csswizardry.com/2011/08/building-better-grid-systems/
 * [10] Define a default vertical spacing between layout items. Use `margin-top`
 *      instead of `margin-bottom`, so if a `margin-bottom` is set on the
 *      `.o-layout` container, the margins do not collapse.
 * [11] If we use `.o-layout--flush`, we do not not want vertical spacing, too.
 * [12] When the equal-height and the center variants are combined,
 *      `text-align: center` isn't enough to center the items.
 * [13] Use alignment via flexbox when both equal-height and right modifiers
 *      are used.
 * [14] Use Flexbox to adjust adjacent items to the same height.
 * [15] Hide vertical and horizontal dividers outside of `.o-layout`.
 * [16] Use vertical divider spacing between items.
 * [17] Equalize the bottom margin of `.o-layout`.
 *      Specificity has to be increased by prefix the selector with `:root:root`
 *      to overrule overly specific rules of other components.
 * [18] Use horizontal divider spacing between items.
 * [19] Remove bottom margin of last layout item child.
 * [20] Add shared declarations for vertical and horizontal dividers.
 * [21] Use `::before` for vertical dividers.
 * [22] Position vertical dividers.
 * [23] Center vertical dividers if divider width is an odd value (1, 3, 5, …).
 *      The divider position will be adjusted to the nearest full pixel
 *      in order to avoid half pixel values and anti-aliasing
 *      (depending on the screen resolution).
 * [24] Center vertical dividers if divider width is an even value (2, 4, 6, …).
 * [25] Use `::after` for horizontal dividers.
 * [26] Position horizontal dividers.
 * [27] Center horizontal dividers if divider width is an odd value (1, 3, 5, …).
 *      The divider position will be adjusted to the nearest full pixel
 *      in order to avoid half pixel values and anti-aliasing
 *      (depending on the screen resolution).
 * [28] Center horizontal dividers if divider width is an even value (2, 4, 6, …).
 * [29] On equal-height layouts the flex-item child elements are not taking up the
 *      entire width due to the `display: flex` on the flex-items.
 *      To fix this we have to make them grow as wide as their flex-item parent.
 * [30] The compensation for vertical gaps from [10] can result in overlapping
 *      elements above the layout-object, resulting in blocked interaction
 *      (click, text-selection, etc.). Disable interaction via `pointer-events`
 *      on layout and...
 * [31] ...Restore interaction on content.
 * [32] Prevent too wide content in equal height layout items from affecting the
 *      maximum width of its layout item container.
 * [33] Set vertical offset for the divider line.
 */
.o-layout {
    display: block; /* [1] */
    margin: 0; /* [2] */
    padding: 0; /* [2] */
    font-size: 0; /* [4] */
    text-align: left;
    list-style: none; /* [1] */
    pointer-events: none; /* [30] */

    /* stylelint-disable order/order */
    // Use custom order as reset needs to be first /* [2] */
    @include responsive-property("margin-top", responsive-property-modify($layout-item-spacing-vertical, $factor: -1)); /* [10] */
    @include responsive-property("margin-left", responsive-property-modify($layout-gutter, $factor: -1)); /* [3] */
    /* stylelint-enable order/order */

    > .o-layout__item {
        @include responsive-property("margin-top", $layout-item-spacing-vertical); /* [10] */
    }

    &.o-layout--equal-height {
        display: flex;
        flex-wrap: wrap;

        &.o-layout--right {
            justify-content: flex-end; /* [13] */
        }
    }

}

    .o-layout__item {
        @include responsive-font-size($layout-gap-font-size-reset); /* [4a] */
        @include responsive-property("padding-left", $layout-gutter); /* [9] */
        box-sizing: border-box; /* [5] */
        display: inline-block; /* [6] */
        width: 100%; /* [8] */
        vertical-align: top; /* [7] */
        pointer-events: auto; /* [31] */


        .o-layout--middle > & {
            vertical-align: middle;
        }

        .o-layout--equal-height > & {
            display: flex;

            > * {
                flex-grow: 1; /* [29] */
                flex-shrink: 1;
                flex-basis: auto;
                max-width: 100%; /* [32] */
            }

        }

    }



/**
 * Layout modifier to fill up the layout system from the center.
 */
.o-layout--center {
    text-align: center;

    &.o-layout--equal-height {
        justify-content: center; /* [12] */
    }

    > .o-layout__item {
        text-align: left;
    }

}



/**
 * Layout modifier to fill up the layout system from the right hand side.
 */
.o-layout--right {
    text-align: right;

    > .o-layout__item {
        text-align: left;
    }

}



/**
 * Layout modifier to eliminate the spacing between the layout items.
 */
.o-layout--flush {
    margin-left: 0;
    margin-top: 0; /* [11] */

    > .o-layout__item {
        padding-left: 0;
        margin-top: 0; /* [11] */
    }

}



/**
 * Layout modifier to add horizontal and vertical divider lines
 * between layout items.
 */
.o-layout--divider {
    display: flex; /* [14] */
    flex-wrap: wrap; /* [14] */
    overflow: hidden; /* [15] */
    margin-top: -($layout-divider-offset-vertical); /* [16] */
    margin-left: -($layout-divider-spacing-horizontal); /* [18] */

    &#{&}#{&} {
        margin-bottom: -($layout-divider-offset-vertical); /* [17] */
    }

    > .o-layout__item {
        position: relative;
        margin-top: $layout-divider-offset-vertical; /* [16] */
        padding-left: $layout-divider-spacing-horizontal; /* [18] */
        padding-bottom: $layout-divider-offset-vertical; /* [16] */

        > *:last-child {
            margin-bottom: 0; /* [19] */
        }

        &::before,
        &::after {
            content: ""; /* [20] */
            position: absolute; /* [20] */
            border-width: $layout-divider-width; /* [20] */
            border-color: $layout-divider-color; /* [20] */
        }

        &::before { /* [21] */
            top: -($layout-divider-spacing-vertical); /* [22] */
            left: $layout-divider-spacing-horizontal; /* [22] */
            right: 0; /* [22] */
            border-top-style: $layout-divider-style;

            @if $layout-divider-width % 2 == 1 {
                transform: translateY(-$layout-divider-width * 0.5 - 0.5px); /* [23] */
            } @else {
                transform: translateY(-$layout-divider-width * 0.5); /* [24] */
            }

            margin-top: $layout-divider-offset-top; /* [33] */
        }

        &::after { /* [25] */
            top: 0; /* [26] */
            bottom: $layout-divider-spacing-vertical; /* [26] */
            right: -($layout-divider-spacing-horizontal * 0.5); /* [26] */
            border-left-style: $layout-divider-style;

            @if $layout-divider-width % 2 == 1 {
                transform: translateX($layout-divider-width * 0.5 + 0.5px); /* [27] */
            } @else {
                transform: translateX($layout-divider-width * 0.5); /* [28] */
            }

        }
    }
}
