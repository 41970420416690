// /* ------------------------------------*\
//     #PLYR (Settings)
// \*------------------------------------ */

$plyr-color-main:                   $COLOR-WHITE !default;

$plyr-font-size-small:              map-get($FS0, null) !default;

$plyr-range-track-height:           $RANGE-SLIDER-TRACK-HEIGHT !default;
$plyr-range-thumb-height:           $RANGE-SLIDER-THUMB-SIZE !default;
$plyr-range-thumb-background:       $RANGE-SLIDER-THUMB-BACKGROUND-COLOR !default;
$plyr-range-thumb-scale-active:     ($RANGE-SLIDER-THUMB-SIZE-HOVER / $RANGE-SLIDER-THUMB-SIZE) !default;
$plyr-range-fill-background:        $RANGE-SLIDER-TRACK-FILLED-BACKGROUND-COLOR !default;

$plyr-video-range-track-background: $COLOR-ON-SURFACE-100 !default;

$plyr-tooltip-background:           $TOOLTIP-BACKGROUND !default;
$plyr-tooltip-color:                $TOOLTIP-TEXT-COLOR !default;
$plyr-tooltip-padding:              $TOOLTIP-PADDING-VERTICAL $TOOLTIP-PADDING-HORIZONTAL !default;
$plyr-tooltip-arrow-size:           $TOOLTIP-ARROW-SIZE !default;
$plyr-tooltip-radius:               $TOOLTIP-BORDER-RADIUS !default;
$plyr-tooltip-shadow:               none !default;
$plyr-tooltip-offset:               $TOOLTIP-OFFSET !default;
$plyr-tooltip-offset-at-breakpoint: $plyr-tooltip-offset !default;
$plyr-tooltip-line-height:          $TOOLTIP-LINE-HEIGHT !default;

$plyr-control-icon-size:            icon-size(xsmall) !default;
$plyr-control-spacing:              $GLOBAL-SPACING-UNIT !default;

$plyr-captions-background:          $COLOR-WHITE !default;
$plyr-captions-text-color:          $COLOR-BASE-TEXT !default;
$plyr-font-size-captions-small:     map-get($FS0, null) !default;
$plyr-font-size-captions-medium:    map-get($FS1, null) !default;
$plyr-font-size-captions-large:     map-get($FS1, null) !default;
$plyr-font-size-time:               $TOOLTIP-TEXT-SIZE !default;
