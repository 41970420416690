// /* ------------------------------------*\
//     #FONTS
// \*------------------------------------ */

// @sass-export-section="fontSettings"

// The font-weights are defined as numerical values instead of named values
// in order to avoid browser mapping issues
// (see https://www.webtype.com/info/articles/fonts-weights/).
//
// As the values can differ to each font these values should be checked
// and modified per brand.

$GLOBAL-FONT-WEIGHT-LIGHT:   300 !default;
$GLOBAL-FONT-WEIGHT-REGULAR: 400 !default;
$GLOBAL-FONT-WEIGHT-BOLD:    700 !default;

// Use relative value. Static value in styleguide is `0.75px`
$GLOBAL-FONT-LETTER-SPACING: 0.05em !default;

$BASE-FONT:                  "skoda-next" !default;
$BASE-FONT-FALLBACKS:        Arial, sans-serif !default;
$BASE-FONT-VARIANTS: (
    "bold":    $GLOBAL-FONT-WEIGHT-BOLD,
    "regular": $GLOBAL-FONT-WEIGHT-REGULAR,
    "light":   $GLOBAL-FONT-WEIGHT-LIGHT,
) !default;

$FONT-REVISION:              null !default;

// @end-sass-export-section
