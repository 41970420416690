/* ------------------------------------*\
    #ACCORDION
\*------------------------------------ */

@use "sass:math" as *;

$accordion-breakpoint:                          s !default;

$accordion-icon-size:                           icon-size(small) !default;
$accordion-icon-spacing-left:                   auto !default;
$accordion-icon-spacing-right:                  $GLOBAL-SPACING-UNIT !default;
$accordion-icon-spacing-top:                    $GLOBAL-SPACING-UNIT-SMALL !default;
$accordion-icon-spacing-top-large:              $accordion-icon-spacing-top !default;
$accordion-icon-spacing-top-breakpoint:         m !default;
$accordion-icon-color:                          $COLOR-BASE-TEXT !default;

$accordion-bg-color:                            null !default;

// Border settings
$accordion-border-color:                        $COLOR-BRAND !default;
$accordion-border-top:                          none !default;
$accordion-border-bottom:                       1px solid $accordion-border-color !default;

$accordion-header-border:                       1px solid $accordion-border-color !default;
$accordion-header-spacing-top:                  $GLOBAL-SPACING-UNIT-SMALL !default;
$accordion-header-spacing-bottom:               $accordion-header-spacing-top !default;
$accordion-header-spacing-left:                 0 !default;
$accordion-header-spacing-right:                ($accordion-icon-spacing-right * 2) + $accordion-icon-size !default;

$accordion-header-indentation:                  false !default;
$accordion-header-indentation-spacing:          null !default;

$accordion-header-split-spacing-right:          $accordion-header-spacing-right !default;
$accordion-header-split-spacing-left:           $accordion-header-spacing-left !default;
$accordion-header-split-label-spacing-vertical: $GLOBAL-SPACING-UNIT-XSMALL !default;
$accordion-header-split-label-spacing-between:  $GLOBAL-SPACING-UNIT-SMALL !default;

$accordion-title-margin-bottom:                 0 !default;
$accordion-title-font-size:                     $FS0 !default;
$accordion-title-font-family:                   $BASE-FONT-FAMILY !default;
$accordion-title-line-height:                   $GLOBAL-LINE-HEIGHT !default;
$accordion-title-text-color:                    $COLOR-BASE-TEXT !default;
$accordion-title-length:                        50em !default;
$accordion-title-weight:                        $GLOBAL-FONT-WEIGHT-BOLD !default;

$accordion-subtitle-weight:                     null !default;
$accordion-subtitle-spacing:                    null !default;

$accordion-panel-spacing-top:                   0px !default; // stylelint-disable-line length-zero-no-unit
$accordion-panel-spacing-bottom:                $GLOBAL-SPACING-UNIT !default;
$accordion-panel-spacing-left:                  0px !default; // stylelint-disable-line length-zero-no-unit
$accordion-panel-spacing-right:                 $GLOBAL-SPACING-UNIT !default;

// Accordion with different bg color
$accordion-background:                          true !default;
$accordion-panel-bg-color:                      map-get($BACKGROUND-UTILITIES, "alternative") !default;
$accordion-panel-bg-spacing-top:                $GLOBAL-SPACING-UNIT !default;
$accordion-panel-bg-spacing-bottom:             $GLOBAL-SPACING-UNIT !default;
$accordion-panel-bg-spacing-left:               $GLOBAL-SPACING-UNIT-SMALL !default;
$accordion-panel-bg-spacing-right:              $accordion-panel-bg-spacing-left !default;
$accordion-panel-bg-divider-top-width:          null !default;
$accordion-panel-bg-divider-top-color:          null !default;

// Uses CSS generated icon
$accordion-enable-cssonly-icon:                 true !default;
$accordion-css-icon-stroke-width:               2px !default;

// State classes
$accordion-title-state-expanded:                ".is-active" !default;
$accordion-panel-state-expanded:                "[aria-hidden='false']" !default;
$accordion-panel-state-expanded-transition-end: ".is-expanded" !default;

$accordion-content-font-weight:                 null !default;

// Use separate icons for expanding/collapsing if $accordion-enable-cssonly-icon
// is set to `false`.
$accordion-icon-collapsed:                      get-icon(semantic-expand) !default;
$accordion-icon-transform-collapsed:            rotateX(0) !default;
$accordion-icon-expanded:                       null !default;
$accordion-icon-transform-expanded:             rotateX(180deg) !default;

// Animations
$accordion-transition-duration:                 $GLOBAL-TRANSITION-DURATION-MEDIUM * 2 !default;
$accordion-panel-transition-duration-factor:    2 !default;
$accordion-panel-transition-duration:           div($accordion-transition-duration, $accordion-panel-transition-duration-factor) !default;
$accordion-icon-animation-duration-factor:      3 !default;
$accordion-icon-animation-duration:             div($accordion-transition-duration, $accordion-icon-animation-duration-factor) !default;

$accordion-overflow-offset:                     0.5rem !default; // [20]

$accordion-themes: (
    "alternative": (
        "panel-background": $COLOR-BASE-BACKGROUND
    )
) !default;

/**
 * [1]  Border below and above the accordion component.
 * [2]  Reset browser-default button styles.
 * [3]  Max line-length.
 * [4]  Lower duration for collapsing to focus on the expanding content.
 * [5]  Remove whitespace from heading (`h*`), `line-height` is set on button.
 * [6]  Do not use `border-top` on first item, because it’s set with
 *      `$accordion-border-top`.
 * [7]  Add offset for CSS-generated icons to accommodate for different
 *      `stroke-width`.
 * [8]  Use `grid-template-rows` to transition the height.
 *      No more choppy `max-height` transition. (see https://www.stefanjudis.com/snippets/how-to-animate-height-with-css-grid/)
 * [9]  Hide panel overflow content (e.g. expanded Combo Box) and only allow
 *      overflow after the finished transition via the dynamically added
 *      `$accordion-panel-state-expanded-transition-end` class.
 * [10] Disable the top border for sibling accordions only if there is
 *      no bottom border.
 * [11] Use pseudo elements to fake vertical padding because actual paddings
 *      would unintentionally enlarge the `.c-accordion__content` grid item.
 * [12] Align `.c-accordion__title-label` to the left and right via flexbox.
 * [13] Stack `.c-accordion__title-label`s vertically in small viewports.
 * [14] Add vertical space between `.c-accordion__title-label` in small
 *      viewports.
 * [15] Push info icon to the left in splitted header titles.
 * [16] Add a space between both splitted header title parts.
 * [17] Defines the indentation width of the header.
 * [18] Resolves the `z-index problem of the tooltip.
 * [19] Since `[role=button]` does not inherit any button styles we need to
 *      manually set them here.
 * [20] To prevent cut-off, overflowing stylistic features (e.g. box shadow),
 *      a large enough padding (`$accordion-overflow-offset`) is added and
 *      compensated with negative margins. Additionally the panel content is
 *      being hidden via `opacity` when the panel is closed.
 * [21] Disable extra padding from [20] for Accordion with panel
 *      background color to avoid visible background outside of the accordion.
 * [22] Prevent layout issues with absolute positioned elements inside the panel.
 */
.c-accordion {
    position: relative;
    border-top: $accordion-border-top; /* [1] */
    border-bottom: $accordion-border-bottom; /* [1] */
    background-color: $accordion-bg-color;

    & + & {
        @if ($accordion-border-top != "none" and $accordion-border-top != 0) and
            ($accordion-border-bottom != "none" and $accordion-border-bottom != 0) {
            border-top: none; /* [10] */
        }
    }

}

    .c-accordion__header {
        @include responsive-property(
            ("padding-top",    $accordion-header-spacing-top),
            ("padding-right",  $accordion-header-spacing-right),
            ("padding-bottom", $accordion-header-spacing-bottom),
            ("padding-left",   $accordion-header-spacing-left)
        );
        position: relative;
        width: 100%;
        margin: 0;
        cursor: pointer;

        @if ($accordion-header-indentation) {

            &:hover {

                .c-accordion__title,
                .c-accordion__subtitle {
                    position: relative; /* [18] */
                    z-index: 1; /* [18] */
                    transform: translateX($accordion-header-indentation-spacing); /* [17] */
                }

            }

            .c-accordion__title,
            .c-accordion__subtitle {
                @include transition(transform);
            }

        }

        * + & {
            border-top: $accordion-header-border; /* [6] */
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    .c-accordion__title {
        @include responsive-font-size(
            $font-size:   $accordion-title-font-size,
            $line-height: $accordion-title-line-height
        );
        margin-bottom: $accordion-title-margin-bottom;
    }

    .c-accordion__title-label {
        @include reset-button($remove-focus: true); /* [2] */
        display: inline-flex; /* [19] */
        max-width: $accordion-title-length; /* [3] */
        font-family: $accordion-title-font-family; /* [19] */
        font-weight: $accordion-title-weight;
        letter-spacing: normal; /* [19] */
        line-height: 1.15; /* [19] */
        text-align: left;
        color: $accordion-title-text-color;
        cursor: pointer;
    }

    .c-accordion__title-info-icon {
        margin-right: auto; /* [15] */
    }

    .c-accordion__title-icon {
        @include responsive-property(
            ("top",    $accordion-icon-spacing-top),
            ("right",  $accordion-icon-spacing-right),
            ("left",   $accordion-icon-spacing-left)
        );
        position: absolute;
        width: $accordion-icon-size;
        height: $accordion-title-line-height;

        @if ($accordion-enable-cssonly-icon == true) {

            &::before,
            &::after {
                @include transition($transition-property: "transform");
                content: "";
                position: absolute;
                top: 50%;
                width: ($accordion-icon-size * 0.5);
                height: $accordion-css-icon-stroke-width;
                transform-origin: bottom; // @TODO: check if this has any effect as no `transform` is set
                background-color: $accordion-icon-color;

                .c-accordion__header#{$accordion-title-state-expanded} & {
                    transform-origin: top;
                }

            }

            &::before {
                right: 50%;
                transform: translateY(-50%) translateX(1px) rotate(45deg); /* [7] */

                .c-accordion__header#{$accordion-title-state-expanded} & {
                    transform: translateY(-50%) translateX(1px) rotate(-45deg); /* [7] */
                }

            }

            &::after {
                left: 50%;
                transform: translateY(-50%) translateX(-1px) rotate(-45deg); /* [7] */

                .c-accordion__header#{$accordion-title-state-expanded} & {
                    transform: translateY(-50%) translateX(-1px) rotate(45deg); /* [7] */
                }

            }

        } @else {

            @include media-query(">#{$accordion-icon-spacing-top-breakpoint}") {
                @include responsive-property(
                    ("top", $accordion-icon-spacing-top-large)
                );
            }

            &::before {
                @include transition(
                    $transition-property: transform,
                    $transition-duration: $accordion-icon-animation-duration,
                );
                @include font-size(
                    $font-size:   $accordion-icon-size,
                    $line-height: 1
                );
                @include icon-font();
                content: $accordion-icon-collapsed;
                transform: $accordion-icon-transform-collapsed;
                color: $accordion-icon-color;

                .c-accordion__header#{$accordion-title-state-expanded} & {
                    content: $accordion-icon-expanded;
                    transform: $accordion-icon-transform-expanded;
                }

            }

        }

    }

    .c-accordion__subtitle {
        margin-top: $accordion-subtitle-spacing;
        font-weight: $accordion-subtitle-weight;
    }

    .c-accordion__panel {
        --accordion-overflow-offset: #{$accordion-overflow-offset}; /* [20] */
        @include responsive-property(
            ("--accordion-panel-padding-top", $accordion-panel-spacing-top),
            ("--accordion-panel-padding-right", $accordion-panel-spacing-right),
            ("--accordion-panel-padding-bottom", $accordion-panel-spacing-bottom),
            ("--accordion-panel-padding-left", $accordion-panel-spacing-left)
        );
        @include transition(
            $transition-property: grid-template-rows,
            $transition-duration: $accordion-panel-transition-duration,
        ); /* [8] */
        display: grid; /* [8] */
        grid-template-rows: 0fr; /* [8] */

        &#{$accordion-panel-state-expanded} {
            grid-template-rows: 1fr; /* [8] */
        }
        @if ($accordion-background) {
            .c-accordion--bg-panel & {
                --accordion-overflow-offset: 0px; /* [21] */ /* stylelint-disable-line length-zero-no-unit */
                @include responsive-property(
                    ("--accordion-panel-padding-top", $accordion-panel-bg-spacing-top),
                    ("--accordion-panel-padding-right", $accordion-panel-bg-spacing-right),
                    ("--accordion-panel-padding-bottom", $accordion-panel-bg-spacing-bottom),
                    ("--accordion-panel-padding-left", $accordion-panel-bg-spacing-left)
                );
                background-color: $accordion-panel-bg-color;
                background-image: linear-gradient(to bottom, $accordion-panel-bg-divider-top-color $accordion-panel-bg-divider-top-width, transparent $accordion-panel-bg-divider-top-width);

                @include vary($loop: $accordion-themes) {
                    background-color: vary-get("panel-background");
                }

            }
        }

    }

    .c-accordion__content {
        position: relative; /* [22] */
        overflow: hidden; /* [9] */
        margin-right: calc(var(--accordion-overflow-offset) * -1); /* [20] */
        margin-bottom: calc(var(--accordion-overflow-offset) * -1); /* [20] */
        margin-left: calc(var(--accordion-overflow-offset) * -1); /* [20] */
        padding-right: calc(var(--accordion-panel-padding-right, 0px) + var(--accordion-overflow-offset)); /* [20] */
        padding-left: calc(var(--accordion-panel-padding-left, 0px) + var(--accordion-overflow-offset)); /* [20] */
        font-weight: $accordion-content-font-weight;
        opacity: 0; /* [20] */
        transition: opacity 0s; /* [20] */
        transition-delay: var(--transition-duration); /* [20] */

        #{$accordion-panel-state-expanded} & {
            opacity: 1; /* [20] */
            transition: none; /* [20] */
        }

        #{$accordion-panel-state-expanded}#{$accordion-panel-state-expanded-transition-end} & {
            overflow: visible; /* [9] */
        }

        &::before,
        &::after { /* [11] */
            content: "";
            display: block;
            height: calc(var(--accordion-panel-padding-top) + var(--accordion-overflow-offset)); /* [20] */
        }

        &::after { /* [11] */
            height: calc(var(--accordion-panel-padding-bottom) + var(--accordion-overflow-offset)); /* [20] */
        }

        > *:last-child {
            margin-bottom: 0;
        }

    }

    .c-accordion--header-split {

        .c-accordion__header {
            padding-right: $accordion-header-split-spacing-right;
            padding-left: $accordion-header-split-spacing-left;
        }

        .c-accordion__title-label {
            display: inline-flex; /* [12] */
            flex-direction: row; /* [12] */
            justify-content: space-between; /* [12] */
            align-items: flex-start; /* [12] */
            width: 100%; /* [12] */
            max-width: none; /* [12] */

            @include media-query("<=#{$accordion-breakpoint}") {
                flex-direction: column; /* [13] */
            }
        }

        .c-accordion__title-label-text {

            + .c-accordion__title-label-text {
                margin-left: $accordion-header-split-label-spacing-between; /* [16] */

                @include media-query("<=#{$accordion-breakpoint}") {
                    margin-top: $accordion-header-split-label-spacing-vertical; /* [14] */
                    margin-left: 0; /* [16] */
                }
            }
        }
    }
