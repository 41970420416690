// /* ------------------------------------*\
//     #FORM-FIELD (Settings)
// \*------------------------------------ */

@use "sass:math" as *;

$form-field-label-text-size:                       $GLOBAL-INPUT-LABEL-SIZE !default;
$form-field-label-line-height:                     1 !default;

$form-field-label-spacing:                         $GLOBAL-INPUT-LABEL-SPACING !default;
$form-field-label-spacing-hint:                    0 !default;
$form-field-label-spacing-textarea:                0 !default;
$form-field-label-floating-scale-in:               div(map-get($GLOBAL-INPUT-FONT-SIZE, null), $GLOBAL-INPUT-LABEL-SIZE) !default;

$form-field-label-font-weight:                     $GLOBAL-INPUT-LABEL-FONT-WEIGHT !default;
$form-field-label-font-weight-hint:                $form-field-label-font-weight !default;
$form-field-label-padding-vertical:                $GLOBAL-INPUT-PADDING-VERTICAL !default;

$form-field-label-floating:                        true !default;
$form-field-label-color-success:                   false !default; // opt-out, does not create declaration with important() mixin
$form-field-label-color-success-hover:             $GLOBAL-INPUT-LABEL-COLOR-ACTIVE !default;
$form-field-label-color-error:                     false !default;
$form-field-label-color-error-hover:               $COLOR-ALERT !default;


// Place label in center of input.
$form-field-label-floating-position-in:            calc(100% + #{$form-field-label-text-size}) !default;
$form-field-label-floating-position-out:           100% !default;

$form-field-label-floating-translate-out:          ($GLOBAL-INPUT-PADDING-HORIZONTAL, $form-field-label-floating-position-out, 0) !default;
$form-field-label-floating-translate-in:           ($GLOBAL-INPUT-PADDING-HORIZONTAL, $form-field-label-floating-position-in, 0) !default;

$form-field-label-floating-translate-in-textarea:  $form-field-label-floating-translate-in !default;
$form-field-label-floating-translate-out-textarea: $form-field-label-floating-translate-out !default;
