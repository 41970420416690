/* ------------------------------------*\
    #BUTTONS
\*------------------------------------ */

// Define your desired button height.
// NOTE: This just applies, if you define the `$button-line-height` with a `px`
// value. If you need to define your line-height with another unit, you need to
// construct the height of your button yourself by altering the
// `$button-padding-vertical` variable.
$button-height:                                $BUTTON-HEIGHT !default;

// By default, the defined `$button-height` is a min-height, meaning the button
// grows in case of multiline button text. By enabling this variable, you can
// prevent the button from growing so it keeps its height no matter its content.
$button-height-fixed:                          false !default;

// Prefer to define your line-height with a `px` value, so the desired
// button height you defined above can be calculated automatically.
$button-line-height:                           $BUTTON-LINE-HEIGHT !default;

// Assign the button text-size by assigning a font-size from
// `_settings.font-sizes.scss`.
$button-text-size:                             $FS0 !default;

// Initiate `$button-padding-vertical`, so it can be used and altered in the
// following @if condition.
$button-padding-vertical:                      null !default;

// If `$button-line-height` is a `px` value, we can use that and the
// `$button-height` to automatically calculate the appropriate
// `$button-padding-vertical` value, so it yields the desired button height.
@if (unit($button-line-height) == "px") {
    $button-padding-vertical:                  ($button-height - $button-line-height) * 0.5;
} @else {
    // If the `$button-line-height` is not a `px` value, the above calculation
    // wouldn't work. In that case, the @if condition above wouldn't apply and
    // instead, the following variable assignment would apply.
    $button-padding-vertical:                  $GLOBAL-SPACING-UNIT-SMALL;
}

@if ($button-height-fixed == true) {
    $button-padding-vertical:                  0;
}

$button-padding-horizontal:                    $GLOBAL-SPACING-UNIT !default;

// Set a general `border-radius` value (for all four corners).
$button-border-radius:                         $GLOBAL-INPUT-BORDER-RADIUS !default;

$button-transition-property:                   $GLOBAL-TRANSITION-PROPERTY !default;
$button-transition-duration:                   $GLOBAL-TRANSITION-DURATION !default;
$button-transition-timing-function:            $GLOBAL-TRANSITION-TIMING-FUNCTION !default;

// Misc text styles.
$button-text-transform:                        inherit !default;
$button-link-text-transform:                   null !default;
$button-font-family:                           $BASE-FONT-FAMILY !default;
$button-font-weight:                           $GLOBAL-FONT-WEIGHT-REGULAR !default;
$button-link-font-weight:                      $button-font-weight !default;
$button-letter-spacing:                        null !default;

// Define the spacing between the icon and the text.
$button-text-icon-spacing:                     $GLOBAL-SPACING-UNIT-XSMALL !default;

// Define if the icon in front of the the button-link has some sort of
// special cosmetics (via the `icon-variant` mixin) or is just a plain icon.
$button-link-simple:                           false !default;

// Add a transparent border to avoid jumping link on hover
$button-link-border:                           1px solid transparent !default;

// Define the icon size. Note, that a size larger than the line-height of the
// button’s text size might result in a taller button when the button’s
// line-height IS NOT a fixed value.
$button-icon-size:                             icon-size(small) !default;

// Define the icon sizes for the icon-only button variants.
$button-icon-only-size:                        icon-size(medium) !default;
$button-icon-only-size-small:                  icon-size(small) !default;
// Define the box/circle sizes of the icons for the icon-only button variants.
$button-icon-only-box-size:                    "medium" !default;
$button-icon-only-box-size-small:              "small" !default;
$button-icon-only-circle-size:                 "medium" !default;
$button-icon-only-circle-size-small:           "small" !default;

$button-icon-only-border-width:                $ICON-BORDER-WIDTH !default;
$button-icon-only-border-style:                $ICON-BORDER-STYLE !default;

$button-focus-disable-outline:                 true !default;

// Since we animate the `max-width` we need a large enough value for the
// transition.
$button-incognito-max-width:                   30em !default;

// Putting all cosmetic styles in a map to manage everything at one place.
// This makes the whole thing more readable and also gives us the opportunity
// to easily add more button variants later on just by adding a new entry
// to the map.
$button-variants: (
    primary: (
        background:                          $COLOR-BRAND,
        background-hover:                    $COLOR-BASE,
        background-active:                   null,
        background-disabled:                 rgba($COLOR-INACTIVE, $OPACITY-MEDIUM),
        background-disabled-hover:           rgba($COLOR-INACTIVE, $OPACITY-MEDIUM),
        box-shadow:                          null,
        box-shadow-hover:                    null,
        box-shadow-active:                   null,
        box-shadow-disabled:                 null,
        box-shadow-disabled-hover:           null,
        text-color:                          $COLOR-WHITE,
        text-color-hover:                    $COLOR-WHITE,
        text-color-active:                   null,
        text-color-disabled:                 rgba($COLOR-BASE, $OPACITY-MEDIUM),
        text-color-disabled-hover:           rgba($COLOR-BASE, $OPACITY-MEDIUM),
        border-width:                        $GLOBAL-INPUT-BORDER-WIDTH,
        border-width-disabled:               $GLOBAL-INPUT-BORDER-WIDTH,
        border-style:                        $GLOBAL-INPUT-BORDER-STYLE,
        border-style-disabled:               $GLOBAL-INPUT-BORDER-STYLE,
        border-color:                        $COLOR-BRAND,
        border-color-hover:                  $COLOR-BRAND,
        border-color-active:                 null,
        border-color-disabled:               rgba($COLOR-INACTIVE, $OPACITY-MEDIUM),
        border-color-disabled-hover:         rgba($COLOR-INACTIVE, $OPACITY-MEDIUM),
        link-text-color:                     $COLOR-BASE-TEXT,
        link-text-color-disabled:            rgba($COLOR-BASE, $OPACITY-MEDIUM),
        link-text-color-hover:               $COLOR-BASE-TEXT,
        link-text-color-active:              null,
        link-icon-background-color:          $COLOR-BRAND,
        link-icon-background-color-hover:    $COLOR-BASE,
        link-icon-background-color-active:   null,
        link-icon-background-color-disabled: rgba($COLOR-INACTIVE, $OPACITY-MEDIUM),
        link-icon-color:                     $COLOR-WHITE,
        link-icon-color-hover:               $COLOR-WHITE,
        link-icon-color-active:              null,
        link-icon-color-disabled:            rgba($COLOR-BASE, $OPACITY-MEDIUM),
        link-simple-icon-color:              $COLOR-BRAND,
        link-simple-icon-color-hover:        $COLOR-BRAND,
        link-simple-icon-color-active:       null,
        link-simple-icon-color-disabled:     rgba($COLOR-BASE, $OPACITY-MEDIUM),
        link-icon-border-color:              $COLOR-BRAND,
        link-icon-border-color-hover:        $COLOR-BRAND,
        link-icon-border-color-active:       null,
        link-icon-border-color-disabled:     rgba($COLOR-INACTIVE, $OPACITY-MEDIUM),
        icon-only-color:                     $COLOR-WHITE,
        icon-only-color-hover:               $COLOR-WHITE,
        icon-only-color-active:              null,
        icon-only-background:                $COLOR-BRAND,
        icon-only-background-hover:          $COLOR-BASE,
        icon-only-background-active:         null,
        icon-only-border-color:              $COLOR-BRAND,
        icon-only-border-color-hover:        $COLOR-BRAND,
        icon-only-border-color-active:       null,
    ),
    secondary: (
        background:                          $COLOR-WHITE,
        background-hover:                    $COLOR-BASE,
        background-active:                   null,
        background-disabled:                 rgba($COLOR-INACTIVE, $OPACITY-MEDIUM),
        background-disabled-hover:           rgba($COLOR-INACTIVE, $OPACITY-MEDIUM),
        box-shadow:                          null,
        box-shadow-hover:                    null,
        box-shadow-active:                   null,
        box-shadow-disabled:                 null,
        box-shadow-disabled-hover:           null,
        text-color:                          $COLOR-BASE,
        text-color-hover:                    $COLOR-WHITE,
        text-color-active:                   null,
        text-color-disabled:                 rgba($COLOR-BASE, $OPACITY-MEDIUM),
        text-color-disabled-hover:           rgba($COLOR-BASE, $OPACITY-MEDIUM),
        border-width:                        $GLOBAL-INPUT-BORDER-WIDTH,
        border-width-disabled:               $GLOBAL-INPUT-BORDER-WIDTH,
        border-style:                        $GLOBAL-INPUT-BORDER-STYLE,
        border-style-disabled:               $GLOBAL-INPUT-BORDER-STYLE,
        border-color:                        $COLOR-BRAND,
        border-color-hover:                  $COLOR-BRAND,
        border-color-active:                 null,
        border-color-disabled:               rgba($COLOR-INACTIVE, $OPACITY-MEDIUM),
        border-color-disabled-hover:         rgba($COLOR-INACTIVE, $OPACITY-MEDIUM),
        link-text-color:                     $COLOR-BASE-TEXT,
        link-text-color-disabled:            rgba($COLOR-BASE, $OPACITY-MEDIUM),
        link-text-color-active:              null,
        link-text-color-hover:               $COLOR-BASE-TEXT,
        link-icon-background-color:          $COLOR-WHITE,
        link-icon-background-color-hover:    $COLOR-BASE,
        link-icon-background-color-active:   null,
        link-icon-background-color-disabled: rgba($COLOR-INACTIVE, $OPACITY-MEDIUM),
        link-icon-color:                     $COLOR-BASE,
        link-icon-color-hover:               $COLOR-WHITE,
        link-icon-color-active:              null,
        link-icon-color-disabled:            rgba($COLOR-BASE, $OPACITY-MEDIUM),
        link-icon-border-color:              $COLOR-BRAND,
        link-icon-border-color-hover:        $COLOR-BRAND,
        link-icon-border-color-active:       null,
        link-icon-border-color-disabled:     rgba($COLOR-INACTIVE, $OPACITY-MEDIUM),
        icon-only-color:                     $COLOR-BASE,
        icon-only-color-hover:               $COLOR-WHITE,
        icon-only-color-active:              null,
        icon-only-background:                $COLOR-WHITE,
        icon-only-background-hover:          $COLOR-BASE,
        icon-only-background-active:         null,
        icon-only-border-color:              $COLOR-BRAND,
        icon-only-border-color-hover:        $COLOR-BRAND,
        icon-only-border-color-active:       null,
    ),
    action: (
        background:                $COLOR-HIGHLIGHT,
        background-hover:          $COLOR-HIGHLIGHT,
        background-active:         $COLOR-BASE,
        background-disabled:       rgba($COLOR-INACTIVE, $OPACITY-MEDIUM),
        background-disabled-hover: rgba($COLOR-INACTIVE, $OPACITY-MEDIUM),
        text-color-active:         $COLOR-WHITE,
        text-color-disabled:       rgba($COLOR-BASE, $OPACITY-MEDIUM),
        text-color-disabled-hover: rgba($COLOR-BASE, $OPACITY-MEDIUM),
        border-style:              none,
        border-radius:             calc(var(--button-height) / 2),
        padding-horizontal:        calc(var(--button-height) / 2),
    ),
) !default;
$button-variants-brand-overwrites: () !default;
$button-variants-brand: map-deep-merge($button-variants, $button-variants-brand-overwrites) !default;

$button-themes: (
    null:  $button-variants,
    brand: $button-variants-brand,
) !default;

// Re-declaring the padding variables for later usage down below by calculating
// it with the border. In order to achieve the desired button height with the
// padding, we have to subtract the border which technically does add to the
// padding, but does not visually. That’s why we subtract the border-width, so
// we land at the correct padding.
// DO NOT ADD `!default`, since this would result in the upper variables
// overriding these ones, which isn’t wanted and thus won’t work ultimately.
/* stylelint-disable scss/no-duplicate-dollar-variables */
$button-padding-vertical:                      ($button-padding-vertical - map-deep-get($button-variants, primary, border-width)); /* stylelint-disable-line scss/dollar-variable-default */
$button-padding-horizontal:                    ($button-padding-horizontal - map-deep-get($button-variants, primary, border-width)); /* stylelint-disable-line scss/dollar-variable-default */
/* stylelint-enable scss/no-duplicate-dollar-variables */

$button-icon-round:                             false !default; /* [16] */

/**
 * [1] `inline-flex` is a good method to center elements vertically inside of
 *     the button.
 * [2] Center text, and text to icon horizontally and vertically inside of the
 *     button.
 * [3] Make the cursor behaviour the same for `<button>` and `<a>` elements.
 * [4] `<a>` elements are left aligned by default. Override this to match
 *     `<button>` and `<a>` elements.
 * [5] Text left, icon right.
 * [6] Icon left, text right.
 * [7] Increase specificity, so that the styles don’t get overridden later on by different themes.
 *      @TODO: This is definitely a code smell and should be refactored.
 *             https://jira.platform.vwfs.io/browse/BRON-6709
 * [8] Fix for iOS bug, where the button text is not centered (BRON-534). Just
 *     occurs in iOS 10 or lower (every browser), for the full-width button and
 *     only, if it's a `<button>` element.
 * [9] Prevent text selection on all button (sub-) elements, especially for
 *      touch-devices, i.e. a `<span>` element in the button.
 * [10] Prevent the padding from becoming negative.
 * [11] Although this declaration is in an if-condition that only matches for
 *      fixed height buttons, it is still a sensible idea to assign a
 *      min-height rather than a fixed height, so the button grows if the text
 *      does not fit vertically.
 * [12] Remove transition causing delay with animation of the inner icon of the
 *      `.c-btn--icon` variant on hover. This effect would be visible if the
 *      text- and icon-color would change on hover.
 * [13] Limit the height of `.c-btn--link`, so it does not inherit its height
 *      from `.c-btn` and only takes up the height it needs.
 * [14] Override line-height, set by default button component. This is needed, so
 *      that the icon gets its correct height.
 * [15] Align the content left for link buttons. This only has an effect if
 *      the button is larger than its content, e.g. when combining a
 *      `.c-btn--link` with `.c-btn--full`.
 * [16] If the icon only variant needs a special border-radius, i.e. VW6.
 * [17] Also apply focus styles when an element is focused that is a child
 *      of a shared parent element. This is needed for buttons in the
 *      upload component (`.c-upload`).
 * [18] The native `disabled` attribute prevents`:hover` and `:active` events,
 *      but we need to proactively exclude synthetic `.is-disabled` selectors
 *      from receiving `:active` styles.
 * [19] Without a transparent border the link will "jump" on hover.
 * [20] Raise the specificity to prevent leaks from `.c-btn--secondary` in a theme context.
 *      @TODO: This is definitely a code smell and should be refactored.
 *             https://jira.platform.vwfs.io/browse/BRON-6709
 * [21] Margin between icon and text.
 * [22] Negative horizontal icon margin that centers the icon when collapsed and
 *      ensures that it remains fixed during the transition.
 * [23] We don’t want the centered alignment of the default button here,
 * [24] Since the Incognito icon is always square, or circular, when collapsed,
 *      it must be at least as wide as it is tall.
 * [25] We need a maximum width so that the expanded Incognito button
 *      (when used in the Float Grid) is not wider than the viewport.
 * [26] We need to hide overflowing content otherwise the text would be
 *      visible during the transition.
 * [27] Truncate the text with an ellipse (…) if it does not fit.
 * [28] Use CSS custom properties conditionals to control the the collapsed
 *      state where the text is hidden and only the icon is shown. This way we
 *      control enable the collapsed state from other components as well.
 *      Learn more about this technique here:
 *      - https://dev.to/siddharthshyniben/conditional-logic-with-css-the-css-custom-property-trick-44hb
 *      - https://lea.verou.me/2020/10/the-var-space-hack-to-toggle-multiple-values-with-one-custom-property/
 * [29] When the reverse selector is applied, reverse the flex-flow of the button.
 *      Force the flex-order of the icon element `.c-btn__icon` to always be displayed first
 *      when it is the first child element of the button.
 * [30] Reset all button child elements spacings and apply them via `column-gap`
 *      on the main button element.
 * [31] Ensure icon is centered horizontally & vertically.
 */
/* stylelint-disable selector-max-class, selector-max-specificity */
.c-btn {
    // We are using CSS custom properties here, so we can overwrite these values
    // easily within button variants or could use them for dynamic calculations.
    --button-height: #{$button-height};
    --button-padding-horizontal: #{$button-padding-horizontal};
    --button-padding-vertical: #{if($button-padding-vertical < 0, 0, $button-padding-vertical)}; /* [10] */
    --button-icon-size: #{$button-icon-size};
    --button-text-icon-spacing: #{$button-text-icon-spacing};
    --button-border-width: 0px; /* stylelint-disable-line length-zero-no-unit */
    @include responsive-font-size($button-text-size);
    @include transition(
        $transition-property:        $button-transition-property,
        $transition-duration:        $button-transition-duration,
        $transition-timing-function: $button-transition-timing-function,
    );
    display: inline-flex; /* [1] */
    align-items: center; /* [2] */
    justify-content: center; /* [2] */
    flex-direction: var(--button-flex-direction, row);
    padding: var(--button-padding-vertical) var(--button-padding-horizontal);
    border-color: var(--button-border-color);
    border-radius: $button-border-radius;
    font-family: $button-font-family;
    font-weight: $button-font-weight;
    text-decoration: none;
    text-transform: $button-text-transform;
    line-height: $button-line-height;
    text-align: center; /* [4] */
    vertical-align: middle;
    background-color: var(--button-background);
    color: var(--button-text-color);
    box-shadow: var(--button-box-shadow);
    cursor: pointer; /* [3] */

    @if ($button-height-fixed == true) {
        min-height: $button-height; /* [11] */
    }

    /// Mixin to generate CSS custom properties with theme value as fallback/default.
    /// We could use the CSS custom properties to overwrite individual
    /// button CSS properties in other components (e.g. Notification).
    ///
    /// @param {String} $entity - Vary loop entity
    /// @param {String} $name - Name of the CSS custom property
    /// @param {string | Null} $state [null] - State (e.g. hover) for which the property should be generated
    @mixin _button-css-custom-property($entity, $name, $state: null) {
        $state: if($state, "-" + $state, null);
        $cssCustomProperty: --button-#{$entity}-#{$name}#{$state};
        $themeValue: if(vary-get($name + $state), ", " + vary-get($name + $state), null);

        // stylelint-disable-next-line order/order
        --button-#{$name}: var(#{$cssCustomProperty}#{$themeValue});
    }

    /// Mixin that generates a few CSS custom properties with the
    /// `_button-css-custom-property` mixin.
    ///
    /// @param {String} $entity - Vary loop entity
    /// @param {string | Null} $state [null] - State (e.g. hover) for which the property should be generated
    @mixin _button-css-custom-properties($entity, $state: null) {
        @include _button-css-custom-property($entity, border-color, $state);
        @include _button-css-custom-property($entity, background, $state);
        @include _button-css-custom-property($entity, text-color, $state);
        @include _button-css-custom-property($entity, box-shadow, $state);
    }

    @include vary($loop: $button-themes) {
        // Use the `$button-variants` Sass map to loop through all cosmetic
        // declarations and assign them once in this Sass partial.
        @include vary($loop: map-get($button-themes, $vary-loop-entity), $create: "custom") {
            // Extract modifier selector into variable, so we can change it
            // dynamically, depending on the theme we’re in.
            $_button-modifier-scope: ".c-btn--#{$vary-loop-entity}";

            // If the theme is "primary", remove the modifier selector by making it
            // an empty string.
            @if ($vary-loop-entity == "primary") {
                $_button-modifier-scope: "";
            }

            &#{$_button-modifier-scope} {
                // Utilize CSS custom properties with the default value as fallback
                // so that certain property values can be overridden in other contexts/components.
                @include _button-css-custom-properties($vary-loop-entity);

                // Set some properties as CSS custom properties so that they can be
                // overridden in some context or could be used for dynamic calculations.
                @if (vary-get(height)) {
                    --button-height: #{vary-get(height)};
                }
                @if (vary-get(padding-horizontal)) {
                    --button-padding-horizontal: #{vary-get(padding-horizontal)};
                }
                @if (vary-get(icon-size)) {
                    --button-icon-size: #{icon-size(vary-get(icon-size))};
                }
                @if (vary-get(text-icon-spacing)) {
                    --button-text-icon-spacing: #{vary-get(text-icon-spacing)};
                }
                @if (vary-get(border-width)) {
                    --button-border-width: #{vary-get(border-width)};

                    @if (vary-get(border-width) > 0) {
                        --button-padding-horizontal: #{($button-padding-horizontal - vary-get(border-width))};
                        --button-padding-vertical: #{if($button-padding-vertical < 0, 0, ($button-padding-vertical - vary-get(border-width)))}; /* [10] */
                    }

                }
                min-height: vary-get(height);
                border-width: var(--button-border-width);
                border-style: vary-get(border-style);
                border-radius: vary-get(border-radius);
                line-height: vary-get(line-height);

                &:hover,
                &:focus,
                label:focus-within & { /* [17] */
                    @include _button-css-custom-properties($vary-loop-entity, hover);

                    @if ($button-focus-disable-outline) {
                        outline: none;
                    }

                }

                &:not(.is-disabled):active { /* [18] */
                    @include _button-css-custom-properties($vary-loop-entity, active);
                }

                &[disabled],
                &.is-disabled {
                    @include _button-css-custom-properties($vary-loop-entity, disabled);

                    &:hover,
                    &:focus {
                        @include _button-css-custom-properties($vary-loop-entity, disabled-hover);
                    }

                }

                /**
                 * @TODO: needs refactoring, see [7]
                 * Opt-out btn--link styles for action buttons.
                 *
                 * `action` modifier can not be combined with `link`
                 * avoid unnecessary style generation
                 */
                @if not ($vary-loop-entity == "action") {
                &.c-btn--link {
                    justify-content: flex-end; /* [15] */

                    @if (vary-get("link-simple-icon-color")) {

                        &.c-btn--simple {

                            .c-btn__icon {
                                @include icon-variant(
                                    $variant: false,
                                    $size: $button-icon-only-box-size,
                                    $text-color: vary-get("link-simple-icon-color"),
                                    $background-color: vary-get("link-icon-background-color"),
                                    $border-color: vary-get("link-icon-border-color")
                                );
                            }
                        }
                    }

                    &:hover,
                    &:focus {
                        background: none;
                        box-shadow: none;

                        .c-btn__text {
                            color: vary-get(link-text-color-hover);
                        }

                        .c-btn__icon {
                            @if (not $button-link-simple) {
                                @include icon-variant(
                                    $variant: "square",
                                    $size: $button-icon-only-box-size,
                                    $text-color: vary-get("link-icon-color-hover"),
                                    $background-color: vary-get("link-icon-background-color-hover"),
                                    $border-color: vary-get("link-icon-border-color-hover")
                                );
                            } @else {
                                color: vary-get("link-icon-color-hover");
                            }
                        }
                    }

                    &:not(.is-disabled):active { /* [18] */

                        .c-btn__text {
                            color: vary-get(link-text-color-active);
                        }

                        .c-btn__icon {
                            @if (not $button-link-simple) {
                                @include icon-variant(
                                    $variant: false,
                                    $size: $button-icon-only-box-size,
                                    $text-color: vary-get("link-icon-color-active"),
                                    $background-color: vary-get("link-icon-background-color-active"),
                                    $border-color: vary-get("link-icon-border-color-active")
                                );
                            } @else {
                                color: vary-get("link-icon-color-active");
                            }
                        }

                    }

                    .c-btn__text {
                        color: vary-get(link-text-color);
                    }

                    .c-btn__icon {
                        @if (not $button-link-simple) {
                            @include icon-variant(
                                $variant: "square",
                                $size: $button-icon-only-box-size,
                                $text-color: vary-get("link-icon-color"),
                                $background-color: vary-get("link-icon-background-color"),
                                $border-color: vary-get("link-icon-border-color")
                            );
                        } @else {
                            color: vary-get("link-icon-color");
                        }
                    }

                    @if (vary-get("link-simple-icon-color")) {

                        &.c-btn--simple {

                            .c-btn__icon {
                                @include icon-variant(
                                    $variant: false,
                                    $size: $button-icon-only-box-size,
                                    $text-color: vary-get("link-simple-icon-color"),
                                    $background-color: vary-get("link-icon-background-color"),
                                    $border-color: vary-get("link-icon-border-color")
                                );
                            }
                        }
                    }

                    &[disabled],
                    &.is-disabled {

                        .c-btn__text {
                            color: vary-get(link-text-color-disabled);
                        }

                        .c-btn__icon {
                            @if (not $button-link-simple) {
                                @include icon-variant(
                                    $variant: "square",
                                    $size: $button-icon-only-box-size,
                                    $text-color: vary-get("link-icon-color-disabled"),
                                    $background-color: vary-get("link-icon-background-color-disabled"),
                                    $border-color: vary-get("link-icon-border-color-disabled")
                                );
                            } @else {
                                color: vary-get("link-icon-color-disabled");
                            }
                        }
                    }

                    }
                }

                &.c-btn--simple.c-btn--simple { /* [7] */

                    .c-btn__icon {

                        &::before {
                            padding: 0;
                            border: 0;
                            background: none;
                        }

                    }

                }

                &.c-btn--icon-only.c-btn--icon-only { /* [7] */
                    min-width: 0;
                    border: $button-icon-only-border-width $button-icon-only-border-style vary-get("icon-only-border-color");
                    background-color: vary-get("icon-only-background");

                    .c-btn__icon {
                        color: vary-get("icon-only-color");
                    }

                    &:hover,
                    &:focus {
                        border: $button-icon-only-border-width $button-icon-only-border-style vary-get("icon-only-border-color-hover");
                        background-color: vary-get("icon-only-background-hover");

                        .c-btn__icon {
                            color: vary-get("icon-only-color-hover");
                        }

                    }

                    &:not(.is-disabled):active { /* [18] */
                        border: $button-icon-only-border-width $button-icon-only-border-style vary-get("icon-only-border-color-active");
                        background-color: vary-get("icon-only-background-active");

                        .c-btn__icon {
                            color: vary-get("icon-only-color-active");
                        }

                    }

                    &[disabled],
                    &.is-disabled {
                        border-color: vary-get("border-color-disabled");
                        background-color: vary-get("background-disabled");

                        .c-btn__icon {
                            color: vary-get("text-color-disabled");
                        }
                    }

                }

            }

        }

    }

    &[disabled],
    &.is-disabled {
        pointer-events: none;
        cursor: default;
    }

    &.c-btn--full {
        width: 100%;
    }

    &.c-btn--link.c-btn--link.c-btn--link { /* [20] */
        min-height: auto; /* [13] */
        padding: 0;
        border: $button-link-border; /* [19] */
        background: none;
        box-shadow: none;
    }

    &.c-btn--icon-only {
        min-height: auto;
        padding: 0;
        border: 0;

        @if ($button-icon-round == true) {
            border-radius: 50%; /* [16] */
        }

        &:not(.c-btn-round) {
            width: map-get($ICON-SQUARE-FULL-SIZES, $button-icon-only-box-size);
            height: map-get($ICON-SQUARE-FULL-SIZES, $button-icon-only-box-size);

            &.c-btn--small {
                width: map-get($ICON-SQUARE-FULL-SIZES, $button-icon-only-box-size-small);
                height: map-get($ICON-SQUARE-FULL-SIZES, $button-icon-only-box-size-small);
            }

        }

        &.c-btn--round {
            width: map-get($ICON-ROUND-FULL-SIZES, $button-icon-only-circle-size);
            height: map-get($ICON-ROUND-FULL-SIZES, $button-icon-only-circle-size);
            border-radius: 50%;

            &.c-btn--small {
                width: map-get($ICON-ROUND-FULL-SIZES, $button-icon-only-circle-size-small);
                height: map-get($ICON-ROUND-FULL-SIZES, $button-icon-only-circle-size-small);
            }

        }

    }

    &.c-btn--truncate {
        max-width: 100%;
    }

    &:where(.c-btn--icon.c-btn--reversed),
    &:where(.c-btn--incognito.c-btn--reversed) {
        --button-flex-direction: row-reverse;
    }
    &:where(.c-btn--link:not(.c-btn--reversed)) {
        --button-flex-direction: row-reverse;
    }

    &:where(:not(.c-btn--icon-only, .c-btn--incognito.c-btn--incognito)) { /* [xxx] */
        column-gap: var(--button-text-icon-spacing);

        .c-btn__text,
        .c-btn__icon {
            --button-text-icon-spacing: 0;
            margin: 0;  /* [30] */
        }

    }

    * {
        user-select: none; /* [9] */
    }

    > * {
        transition: inherit;
    }

}

    // We can determine whether the icon is left and the text is right and
    // vice versa via the HTML order of the elements.
    .c-btn__text {
        letter-spacing: $button-letter-spacing;

        &:not(:last-child) {
            margin-right: var(--button-text-icon-spacing); /* [5] */
        }

        &:last-child:not(:only-child) {
            margin-left: var(--button-text-icon-spacing); /* [6] */
        }

        .c-btn.c-btn--full & {
            display: block; /* [8] */
            margin-right: auto; /* [8] */
            margin-left: auto; /* [8] */
        }

        .c-btn.c-btn--link & {
            font-weight: $button-link-font-weight;
            text-transform: $button-link-text-transform;
            text-align: left;
        }

        .c-btn.c-btn--truncate & {
            @include truncate();
        }

    }

    .c-btn__icon.c-btn__icon { /* [7] */
        color: inherit;

        // If the icon shall be the same size as the button text
        // (i.e. `inherit), assign it. Otherwise use the font-size mixin
        // to assign the size to the icon.
        @if $button-icon-size == "inherit" {
            font-size: inherit;
        } @else {
            font-size: var(--button-icon-size);
        }

        &::before {
            transition: none; /* [12] */
        }


        .c-btn.c-btn--link & {
            line-height: 1; /* [14] */
        }

        .c-btn.c-btn--link.c-btn--round & {
            @if (not $button-link-simple) {
                @include icon-variant(
                    $variant: "round",
                    $size:    $button-icon-only-circle-size
                );
            }
        }

        .c-btn.c-btn--link.c-btn--round.c-btn--small.c-btn--link.c-btn--round.c-btn--small & {  /* [7] */
            @if (not $button-link-simple) {
                @include icon-variant(
                    $variant: "round",
                    $size:    $button-icon-only-circle-size-small
                );
            }
        }

        .c-btn.c-btn--link.c-btn--small.c-btn--link.c-btn--small & { /* [7] */
            @if (not $button-link-simple) {
                @include icon-variant(
                    $variant: "square",
                    $size:    $button-icon-only-box-size-small
                );
            }
        }

        .c-btn.c-btn--icon-only & {
            @include font-size(
                $font-size:   $button-icon-only-size,
                $line-height: false
            );
        }

        .c-btn.c-btn--icon-only.c-btn--small & {
            @include font-size(
                $font-size:   $button-icon-only-size-small,
                $line-height: false
            );
        }

    }

    /**
     * Incognito Button
     */
    .c-btn--incognito.c-btn--incognito {
        --button-text-horizontal-spacing: calc(var(--button-text-icon-spacing) + var(--button-icon-size) * 0.5); /* [21] */
        --button-icon-horizontal-offset: calc((var(--button-border-width) + var(--button-padding-horizontal)) * -1); /* [22] */
        --button-collapsed: initial; /* [28] */
        justify-content: space-between; /* [23] */
        min-width: var(--button-height); /* [24] */
        max-width: calc(100vw - var(--page-wrap-padding-horizontal) * 2); /* [25] */
        overflow: hidden; /* [26] */

        &.c-btn--reversed {
            .c-btn__text:first-child {
                margin-left: var(--button-text-margin-when-collapsed, var(--button-text-horizontal-spacing)); /* [21] [28] */
                margin-right: 0;
            }
        }

        .c-btn__text {
            --button-text-max-width-when-collapsed: var(--button-collapsed) 0; /* [28] */
            --button-text-margin-when-collapsed: var(--button-collapsed) 0; /* [28] */
            --button-text-opacity-when-collapsed: var(--button-collapsed) 0; /* [28] */
            max-width: var(--button-text-max-width-when-collapsed, $button-incognito-max-width); /* [25] [28] */
            white-space: nowrap; /* [27] */
            text-overflow: ellipsis; /* [27] */
            opacity: var(--button-text-opacity-when-collapsed, 1); /* [28] */

            &:first-child {
                margin-right: var(--button-text-margin-when-collapsed, var(--button-text-horizontal-spacing)); /* [21] [28] */
            }

            &:last-child {
                margin-left: var(--button-text-margin-when-collapsed, var(--button-text-horizontal-spacing)); /* [21] [28] */
            }
        }

        .c-btn__icon {
            display: inline-flex; /* [31] */
            justify-content: center; /* [31] */
            align-items: center; /* [31] */
            width: var(--button-height);
            margin-left: var(--button-icon-horizontal-offset); /* [22] */
            margin-right: var(--button-icon-horizontal-offset); /* [22] */
        }

        &:where(:not(:hover):not(:focus)) {
            --button-collapsed: ; /* [28] */ /* stylelint-disable-line */
        }

    }
/* stylelint-enable selector-max-class, selector-max-specificity */
