// /* ------------------------------------*\
//     #BREADCRUMB (Settings)
// \*------------------------------------ */

$breadcrumb-link-text-color:         $COLOR-ON-SURFACE-500 !default;
$breadcrumb-link-font-size:          $FS0 !default;
$breadcrumb-font-weight:             $GLOBAL-FONT-WEIGHT-REGULAR !default;
$breadcrumb-link-hover-text-color:   $COLOR-ON-SURFACE-900 !default;
$breadcrumb-item-delimiter-icon:     false !default;
$breadcrumb-item-delimiter:          "/" !default;
$breadcrumb-link-current-text-color: $COLOR-ON-SURFACE-900 !default;
$breadcrumb-item-spacing:            $GLOBAL-SPACING-UNIT-XSMALL !default;
