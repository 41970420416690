/* ------------------------------------*\
    #SITE-NAV
\*------------------------------------ */

$skoda-site-nav-dropdown-top-offset:        $GLOBAL-SPACING-UNIT-XSMALL !default;
$skoda-site-nav-dropdown-border-radius:     $GLOBAL-BORDER-RADIUS !default;
$skoda-site-nav-dropdown-drop-shadow:       $DROP-SHADOW-L !default;
$skoda-site-nav-dropdown-title-padding-top: $GLOBAL-SPACING-UNIT-SMALL !default;

/**
 * 1. Transition the opacity because a slide-in transition would not work very
 *    well with the box shadow of the component.
 */

.c-site-nav-dropdown {

    @include site-nav-breakpoint("above") {

        @include transition();
        margin-top: $skoda-site-nav-dropdown-top-offset;
        border-radius: $skoda-site-nav-dropdown-border-radius;
        box-shadow: $skoda-site-nav-dropdown-drop-shadow;
        opacity: 0; /* [1] */

        &:not(#{$site-nav-dropdown-state-collapsed}) {
            opacity: 1; /* [1] */
        }

    }

}

.c-site-nav-dropdown__title {
    padding-top: $skoda-site-nav-dropdown-title-padding-top;
}
