/* ------------------------------------*\
    #COMPARE-AND-SELECT (Settings)
\*------------------------------------ */

// Use a static spacing, not responsive
$compare-and-select-item-spacing: (
    null: $GLOBAL-SPACING-UNIT,
) !default;

$compare-and-select-padding:                       $CARD-PATTERN-PADDING-HORIZONTAL !default;
$compare-and-select-padding-top:                   $GLOBAL-SPACING-UNIT-LARGE !default;

$compare-and-select-background-color:              $CARD-PATTERN-BACKGROUND-COLOR !default;
$compare-and-select-background-color-checked:      $CARD-PATTERN-BACKGROUND-COLOR !default;
$compare-and-select-box-shadow:                    false !default;
$compare-and-select-border:                        $CARD-PATTERN-BORDER !default;
$compare-and-select-border-color-checked:          $CARD-PATTERN-BORDER-COLOR-SELECTED !default;
$compare-and-select-border-radius:                 $CARD-PATTERN-BORDER-RADIUS !default;

$compare-and-select-title-font-size:               $CARD-PATTERN-TITLE-SIZE !default;

$compare-and-select-label-font-size:               false !default;
$compare-and-select-label-text-transform:          uppercase !default;
$compare-and-select-label-font-weight:             $GLOBAL-FONT-WEIGHT-BOLD !default;
$compare-and-select-label-letter-spacing:          $GLOBAL-FONT-LETTER-SPACING !default;
$compare-and-select-label-color:                   $COLOR-WHITE !default;
$compare-and-select-label-background-color:        $COLOR-BRAND-TERTIARY !default;
$compare-and-select-label-offset:                  $GLOBAL-SPACING-UNIT-XSMALL !default;

// Use static font-size, not responsive
$compare-and-select-title-indicator-font-size: (
    null: map-get($FS4, null),
) !default;

$compare-and-select-icon-size:                     icon-size($CARD-PATTERN-ICON-SIZE) !default;
$compare-and-select-icon-color:                    $CARD-PATTERN-ICON-COLOR !default;

$compare-and-select-legal-font-size:               $FS-2 !default;
$compare-and-select-legal-font-weight:             $GLOBAL-FONT-WEIGHT-REGULAR !default;

$compare-and-select-radio-font-size:               $FS-1 !default;
$compare-and-select-radio-font-weight:             $GLOBAL-INPUT-LABEL-FONT-WEIGHT !default;

$compare-and-select-radio-description-font-size:   $FS-2 !default;
$compare-and-select-radio-description-font-weight: $GLOBAL-FONT-WEIGHT-REGULAR !default;

$compare-and-select-button-full-width:             true !default;
