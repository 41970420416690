/* ----------------------------------------------------------------------*\
    #Sticky Elements (Scopes)

    Define offsets for sticky elements for specific contexts/scopes.

    As per design/recommendation, only 2 sticky elements should be combined.
\*---------------------------------------------------------------------- */

/**
  * [1] Fallback for non-supporting browsers of `:has`.
     Relies on DOM structure (table needs to be in section-nav section).
     @DEPRECATED
 */

:root:has(.c-section-nav) {
    --bron-sticky-offset: #{$section-nav-height};
}

/* [1] */
@include supports-not-has-selector() {

    .c-section-nav__section .c-table--sticky-head {
        --bron-sticky-offset: #{$section-nav-height};
    }

}
