// /* ------------------------------------*\
//     #WIZARD
// \*------------------------------------ */

$skoda-wizard-list-margin-top-at-breakpoint:   -($GLOBAL-SPACING-UNIT-SMALL) !default;
$skoda-wizard-item-dot-size:                   12px !default;
$skoda-wizard-item-dot-size-active:            18px !default;
$skoda-wizard-item-dot-gap:                    2px !default;
$skoda-wizard-item-dot-color:                  $COLOR-INACTIVE !default;
$skoda-wizard-item-dot-color-done:             $COLOR-DECENT !default;
$skoda-wizard-item-dot-border-width:           1.5px !default;
$skoda-wizard-item-line-width:                 2px !default;
$skoda-wizard-item-line-color:                 $skoda-wizard-item-dot-color !default;
$skoda-wizard-item-line-color-done:            $skoda-wizard-item-dot-color-done !default;
$skoda-wizard-item-text-alignment-with-icon:   left !default;
$skoda-wizard-item-padding-left-at-breakpoint: $skoda-wizard-item-dot-size-active !default;
$skoda-wizard-item-spacing-left-at-breakpoint: $GLOBAL-SPACING-UNIT-SMALL !default;

/**
 * [1] We use a linear gradient as line background to create cutouts for the dots.
 * [2] The outer lines of the first and last items are displayed transparently.
 */

.c-wizard {
    --wizard-item-dot-size: #{$skoda-wizard-item-dot-size};
    --wizard-item-dot-color: #{$skoda-wizard-item-dot-color};
    --wizard-item-dot-gap: #{$skoda-wizard-item-dot-gap};
    --wizard-item-dot-border-width: #{$skoda-wizard-item-dot-border-width};
    --wizard-item-line-width: #{$skoda-wizard-item-line-width};
    --wizard-item-line-color: #{$skoda-wizard-item-line-color};
}

        .c-wizard__trigger-icon {
            @include button-icon-states(
                $context-selector: ".c-wizard__trigger",
            );

            @include wizard-collapsed() {
                order: 1;
                margin-left: auto;
            }
        }

    .c-wizard__item {
        --wizard-item-dot-color: #{$skoda-wizard-item-dot-color-done};
        --wizard-item-dot-background-color: var(--wizard-item-dot-color);
        --wizard-item-line-color: #{$skoda-wizard-item-line-color-done};
        --wizard-item-line-before-color: var(--wizard-item-line-color);
        --wizard-item-line-after-color: var(--wizard-item-line-color);

        /* line */
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: var(--wizard-item-line-width);
            transform: translateY(-50%);
            /* stylelint-disable */
            background-image: linear-gradient(
                to var(--wizard-item-line-direction, right),
                var(--wizard-item-line-before-color) calc(50% - var(--wizard-item-dot-size) / 2 - var(--wizard-item-dot-gap)),
                transparent calc(50% - var(--wizard-item-dot-size) / 2 - var(--wizard-item-dot-gap)),
                transparent calc(50% + var(--wizard-item-dot-size) / 2 + var(--wizard-item-dot-gap)),
                var(--wizard-item-line-after-color) 0
            ); /* [1] */
            /* stylelint-enable */
        }

        /* dots */
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            width: var(--wizard-item-dot-size);
            height: var(--wizard-item-dot-size);
            transform: translate(-50%, -50%);
            border: var(--wizard-item-dot-border-width) solid var(--wizard-item-dot-border-color, var(--wizard-item-dot-color));
            border-radius: 50%;
            background-color: var(--wizard-item-dot-background-color);
        }

        /* all items after the active item */
        /* stylelint-disable-next-line selector-max-class */
        &.c-wizard__item.is-active ~ .c-wizard__item {
            --wizard-item-line-color: #{$skoda-wizard-item-line-color};
            --wizard-item-dot-color: #{$skoda-wizard-item-dot-color};
            --wizard-item-dot-background-color: transparent;
        }

        &.is-disabled {
            --wizard-item-dot-color: #{$skoda-wizard-item-dot-color};
        }

        &.is-active {
            --wizard-item-line-after-color: #{$skoda-wizard-item-line-color};
            --wizard-item-dot-size: #{$skoda-wizard-item-dot-size-active};
            --wizard-item-dot-color: #{$skoda-wizard-item-dot-color-done};
        }

        &.is-done,
        &.is-error {

            .c-wizard__link {
                text-align: $skoda-wizard-item-text-alignment-with-icon;
            }

        }

        &:first-child {
            --wizard-item-line-before-color: transparent; /* [2] */
        }

        &:last-child {
            --wizard-item-line-after-color: transparent; /* [2] */
        }

        &:has(.c-wizard__link:hover, .c-wizard__link:focus) {
            --wizard-item-dot-background-color: #{$COLOR-BRAND-SECONDARY};
        }

    }

    .c-wizard__link {

        &::before {
            content: unset;
            padding: 0;
        }

    }

    @include wizard-collapsed($root: true) {

        .c-wizard__list {
            margin-top: $skoda-wizard-list-margin-top-at-breakpoint;
        }

        .c-wizard__item {
            --wizard-item-line-direction: bottom;
            padding-left: ($skoda-wizard-item-padding-left-at-breakpoint + $skoda-wizard-item-spacing-left-at-breakpoint);

            &::before {
                left: calc(#{$skoda-wizard-item-padding-left-at-breakpoint} / 2);
                right: unset; /* stylelint-disable-line */
                bottom: 0;
                width: var(--wizard-item-line-width);
                height: auto;
                transform: translateX(-50%);
            }

            &::after {
                top: 50%;
                left: calc(#{$skoda-wizard-item-padding-left-at-breakpoint} / 2);
            }
        }

    }
