/* ------------------------------------*\
    #LABEL
\*------------------------------------ */

/**
 * Native <label> HTML element.
 */

$label-margin-bottom: $GLOBAL-SPACING-UNIT-XSMALL !default;
$label-color:         $COLOR-BASE-TEXT !default;

label {
    display: inline-block;
    margin-bottom: $label-margin-bottom;
    color: $label-color;
}
