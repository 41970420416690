/* ------------------------------------*\
    #RATIO
\*------------------------------------ */
@use "sass:math" as *;

/**
 * With the Ratio object .o-ratio you can set fixed intrinsic aspect ratios for
 * certain elements (e.g. images, videos, iframes or embedded content) that will
 * be kept regardless of the content even when scaled.
 *
 * See http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */

// The map keys are the strings used in the generated class names, and they can
// follow any convention, as long as they are properly escaped strings. i.e.:
//
//     $ratio-ratios: (
//       "2\\:1"         : (2:1),
//       "4-by-3"        : (4:3),
//       "full-hd"       : (16:9),
//       "card-image"    : (2:3),
//       "golden-ratio"  : (1.618:1) -> non-integers are okay
//     ) !default;

$ratio-ratios: $GLOBAL-RATIOS !default;

/**
 * 1. Create ratio-bound content block, to keep media (e.g. images, videos) in
 *    their correct aspect ratios.
 *    We only set a ratio if a `.o-ratio--` modifier class is set as well to
 *    avoid unwanted layout errors when no specific ratio was specified with the
 *    `.o-ratio--` modifier.
 * 2. The `.o-ratio` container will be used as a box with an intrinsic ratio by
 *    adding a `padding-bottom` with the `.o-ratio--` modifier class which sets
 *    the ratio.
 * 3. The child element will be positioned absolutely so that it covers the
 *    entire width and height of `.o-ratio`.
 */

.o-ratio[class*="o-ratio--"] { /* [1] */
    position: relative; /* [2] */
    display: block; /* [2] */

    &::before {
        content: ""; /* [2] */
        display: block; /* [2] */
        width: 100%; /* [2] */
    }

    > .o-ratio__content,
    > iframe,
    > embed,
    > object {
        position: absolute; /* [3] */
        top: 0; /* [3] */
        bottom: 0; /* [3] */
        left: 0; /* [3] */
        right: 0; /* [3] */
        width: 100%; /* [3] */
        height: 100%; /* [3] */
    }
}


/**
 * Ratio variants.
 * Generate a series of ratio classes to be used like so:
 *
 *   <div class="o-ratio  o-ratio--16:9">
 *
 */
@each $ratio-name, $ratio-value in $ratio-ratios {

    @each $antecedent, $consequent in $ratio-value {

        @if (type-of($antecedent) != number) {
            @error("`#{$antecedent}` needs to be a number.");
        }

        @if (type-of($consequent) != number) {
            @error("`#{$consequent}` needs to be a number.");
        }

        .o-ratio--#{$ratio-name}:before {
            padding-bottom: percentage(div($consequent, $antecedent)); /* [2] */
        }

    }

}

