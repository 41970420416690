/* ------------------------------------*\
    #COMBOBOX
\*------------------------------------ */

$combobox-height:                                          $GLOBAL-INPUT-HEIGHT !default;
$combobox-background-color:                                $GLOBAL-INPUT-BACKGROUND !default;
$combobox-background-color-disabled:                       $GLOBAL-INPUT-BACKGROUND-DISABLED !default;
$combobox-background-color-readonly:                       $GLOBAL-INPUT-BACKGROUND-READONLY !default;
$combobox-border-width:                                    $GLOBAL-INPUT-BORDER-WIDTH !default;
$combobox-border-width-focus:                              $combobox-border-width !default;
$combobox-border-width-open:                               $combobox-border-width !default;
$combobox-border-style:                                    $GLOBAL-INPUT-BORDER-STYLE !default;
$combobox-border-color:                                    $GLOBAL-INPUT-BORDER-COLOR !default;
$combobox-border-color-hover:                              $GLOBAL-INPUT-BORDER-COLOR-HOVER !default;
$combobox-border-color-disabled:                           $GLOBAL-INPUT-BORDER-COLOR-DISABLED !default;
$combobox-border-color-readonly:                           $GLOBAL-INPUT-BORDER-COLOR-READONLY !default;
$combobox-border-color-focus:                              $GLOBAL-INPUT-BORDER-COLOR-FOCUS !default;
$combobox-border-color-open:                               $COLOR-BRAND !default;
$combobox-border:                                          $combobox-border-width $combobox-border-style $combobox-border-color !default;
$combobox-border-side:                                     null !default;
$combobox-padding-vertical:                                $GLOBAL-SPACING-UNIT-XXSMALL !default;
$combobox-padding-horizontal:                              $GLOBAL-INPUT-PADDING-HORIZONTAL !default;
$combobox-disabled-state:                                  "[aria-disabled='true']" !default;
$combobox-readonly-state:                                  "[aria-readonly='true']" !default;

$combobox-input-icon-open:                                 get-icon(semantic-expand) !default;
$combobox-input-icon-close:                                get-icon(semantic-collapse) !default;
$combobox-input-icon-size:                                 icon-size($GLOBAL-INPUT-SELECT-ICON-SIZE) !default;
$combobox-input-icon-color:                                null !default;
$combobox-input-icon-color-disabled:                       $GLOBAL-INPUT-FONT-COLOR-DISABLED !default;
$combobox-input-icon-color-readonly:                       $GLOBAL-INPUT-FONT-COLOR-READONLY !default;
$combobox-input-icon-spacing:                              $GLOBAL-SPACING-UNIT-XSMALL !default;

$combobox-value-presentation-text-color:                   $GLOBAL-INPUT-FONT-COLOR !default;
$combobox-value-presentation-text-color-disabled:          $GLOBAL-INPUT-FONT-COLOR-DISABLED !default;
$combobox-value-presentation-text-color-readonly:          $GLOBAL-INPUT-FONT-COLOR-READONLY !default;
$combobox-value-presentation-text-color-placeholder:       $GLOBAL-INPUT-FONT-COLOR-PLACEHOLDER !default;
$combobox-value-presentation-text-color-placeholder-hover: null !default;
$combobox-value-presentation-text-color-placeholder-focus: $combobox-value-presentation-text-color-placeholder-hover !default;
$combobox-value-presentation-text-weight:                  $GLOBAL-INPUT-FONT-WEIGHT !default;
$combobox-value-presentation-text-weight-placeholder:      $GLOBAL-INPUT-FONT-WEIGHT-PLACEHOLDER !default;
$combobox-value-presentation-line-height:                  1.2 !default;

$combobox-listbox-open-state:                              "[aria-expanded='true']" !default;
$combobox-listbox-close-state:                             "[aria-expanded='false']" !default;
$combobox-listbox-multiselect-state:                       "[aria-multiselectable='true']" !default;
$combobox-listbox-z-index:                                 z("combobox") !default;
$combobox-listbox-max-height:                              240px !default;
$combobox-listbox-padding-vertical:                        0 !default;
$combobox-listbox-padding-horizontal:                      $GLOBAL-INPUT-PADDING-HORIZONTAL !default;
$combobox-listbox-border-width:                            $combobox-border-width !default;
$combobox-listbox-border-style:                            $combobox-border-style !default;
$combobox-listbox-border-color:                            $combobox-border-color !default;
$combobox-listbox-border-color-open:                       $combobox-border-color-open !default;
$combobox-listbox-border:                                  $combobox-listbox-border-width $combobox-listbox-border-style $combobox-listbox-border-color !default;
$combobox-listbox-border-radius:                           $GLOBAL-INPUT-BORDER-RADIUS !default;
$combobox-listbox-background:                              $GLOBAL-INPUT-BACKGROUND !default;
$combobox-listbox-shadow:                                  null !default;

$combobox-item-selected-state:                             "[aria-selected='true']" !default;
$combobox-item-disabled-state:                             "[aria-disabled='true']" !default;
$combobox-item-focused-state:                              ".is-focused" !default;
$combobox-item-font-size:                                  $GLOBAL-INPUT-FONT-SIZE !default;
$combobox-item-font-weight:                                $GLOBAL-FONT-WEIGHT-REGULAR !default;
$combobox-item-font-weight-hover:                          $combobox-item-font-weight !default;
$combobox-item-font-weight-selected:                       $GLOBAL-FONT-WEIGHT-BOLD !default;
$combobox-item-color:                                      $GLOBAL-INPUT-OPTION-COLOR !default;
$combobox-item-color-selected:                             null !default;
$combobox-item-color-hover:                                $COLOR-BASE-TEXT !default;
$combobox-item-color-disabled:                             $GLOBAL-INPUT-OPTION-COLOR-DISABLED !default;
$combobox-item-background-hover:                           $COLOR-ROW-HOVER !default;
$combobox-item-background-selected:                        $COLOR-ROW-SELECTED !default;
$combobox-item-padding-vertical:                           $GLOBAL-SPACING-UNIT-XSMALL !default;
$combobox-item-padding-horizontal:                         $combobox-listbox-padding-horizontal !default;
$combobox-item-border:                                     true !default;
$combobox-item-border-width:                               $combobox-border-width !default;
$combobox-item-border-width-hover:                         null !default;
$combobox-item-border-color:                               $COLOR-SUBTLE !default;
$combobox-item-border-color-hover:                         null !default;
$combobox-item-border-padding-horizontal:                  $combobox-listbox-padding-horizontal !default;
$combobox-item-border-disable-last:                        true !default;
// @TODO: Should be handled via Checkbox.
// Refactor with Checkbox mixin instead of `@extend`.
// BRON-7785
$combobox-item-checkbox-background-selected:               null !default;
$combobox-item-checkbox-background-disabled:               $COLOR-INACTIVE !default;
$combobox-item-checkbox-background-disabled:               if($combobox-item-checkbox-background-disabled, $combobox-item-checkbox-background-disabled, null); /* stylelint-disable-line scss/dollar-variable-default, scss/no-duplicate-dollar-variables */
$combobox-item-checkbox-border-color-selected:             null !default;
$combobox-item-checkbox-border-color-disabled:             null !default;
$combobox-item-checkbox-icon-color:                        null !default;
$combobox-item-checkbox-label-color:                       null !default;

$combobox-item-search-input-padding-horizontal:            $combobox-item-padding-horizontal !default;
$combobox-item-search-input-padding-vertical:              $combobox-item-padding-vertical !default;
$combobox-item-search-input-border:                        $combobox-item-border-width solid $combobox-item-border-color !default;

$combobox-no-options-message-color:                        null !default;
$combobox-no-options-message-font-size:                    null !default;
$combobox-no-options-message-text-align:                   center !default;

/**
 * [1]  Prevent text-selection on (accidental) double-click.
 * [2]  Mimic (select) input style.
 * [3]  Use `calc` only if `padding` is > `0` to prevent division by 0
 *      resulting in invalid calculation and declaration being ignored.
 * [4]  Mimic (custom) checkbox styles without actual checkbox component.
 * [5]  Place the listbox directly under the select label.
 * [6]  Span full width.
 * [7]  Toggle visibility for open/closed state, fade animation.
 * [8]  If the `max-height` of the listbox is exceeded, make it
 *      scrollable.
 * [9]  Do not assign any horizontal padding as it would interfere with the
 *      `:hover` on the items if the borders are not full width.
 * [10] Prevent double-border between combobox and listbox.
 * [11] Act as a visual representation / human readable values,
 *      do not interfere with combobox interaction.
 * [12] Create the border between the dropdown items with a pseudo-element, so
 *      we can better handle the padding + `:hover` on the items.
 * [13] Increase specificity to avoid issues resulting from `@extend` [4].
 * [14] Avoid overlapping icon & value presentation.
 *      BRON-7857 for more robust solution.
 * [15] Maintain default input height
 *      while allowing for some padding for multiline text.
 * [16] Center icon in Firefox.
 * [17] Use `::before` pseudo-element for the border to avoid box-model changes
 *      when the border width changes.
 * [18] Display the `noOptionsMessage` via `[data-combobox-no-options-message]`.
 * [19] Align the search input with the options and separate it via a bottom border.
 * [20] Prevent `noOptionsMessage` from being selectable.
 * [21] Prevent any pointer-events on disabled or readonly variants of the combobox.
 * [22] Apply the respective disabled and readonly colors to the input icon and border.
 * [23] Overwrite color of checkbox label resulting from `@extend` [4].
 * [24] Prevent interaction with options when the Combobox is closed.
 */
.c-combobox__wrapper {
    position: relative;

    > * {
        user-select: none; /* [1] */
    }

}

.c-combobox {
    position: relative;
    display: flex;
    gap: $combobox-input-icon-spacing;
    align-items: center;
    min-height: $combobox-height; /* [2] */
    padding-left: $combobox-padding-horizontal; /* [2] */
    padding-right: $combobox-padding-horizontal; /* [2] */
    padding-top: $combobox-padding-vertical; /* [15] */
    padding-bottom: $combobox-padding-vertical; /* [15] */
    background-color: $combobox-background-color;

    &:hover {
        border-color: $combobox-border-color-hover;
    }

    &::before { /* [17] */
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border#{$combobox-border-side}: $combobox-border; /* [2] */
    }

    // focus-within
    &:focus,
    &:focus-within {
        outline: 0;

        &::before { /* [17] */
            border-width: $combobox-border-width-focus;
            border-color: $combobox-border-color-focus;
            outline: 0;
        }

    }

    // select icon
    &::after {
        @include icon-font();
        @include font-size(
            $font-size:   $combobox-input-icon-size,
            $line-height: false,
        );
        align-self: flex-end;
        margin-top: auto; /* [16] */
        margin-bottom: auto; /* [16] */
        color: $combobox-input-icon-color;
    }

    &#{$combobox-listbox-close-state} {

        &::after {
            content: $combobox-input-icon-open;
        }

    }

    &#{$combobox-listbox-open-state} {

        &::before { /* [17] */
            border-width: $combobox-border-width-open;
            border-color: $combobox-border-color-open;
        }

        &::after {
            content: $combobox-input-icon-close;
        }

    }

    &#{$combobox-disabled-state},
    &#{$combobox-readonly-state} {
       pointer-events: none; /* [21] */
    }

    &#{$combobox-disabled-state} {
        background-color: $combobox-background-color-disabled;

        &::before {
            border-color: $combobox-border-color-disabled; /* [22] */
        }

        &::after {
            color: $combobox-input-icon-color-disabled; /* [22] */
        }

    }

    &#{$combobox-readonly-state} {
        background-color: $combobox-background-color-readonly;

        &::before {
            border-color: $combobox-border-color-readonly; /* [22] */
        }

        &::after {
            color: $combobox-input-icon-color-readonly; /* [22] */
        }

    }

}

    .c-combobox__value-presentation {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        pointer-events: none; /* [11] */
    }

        .c-combobox__value-presentation__text {
            font-weight: $combobox-value-presentation-text-weight;
            line-height: $combobox-value-presentation-line-height;
            word-break: break-word; /* [14] */
            color: $combobox-value-presentation-text-color;

            &.has-placeholder {
                font-weight: $combobox-value-presentation-text-weight-placeholder;
                color: $combobox-value-presentation-text-color-placeholder;

                .c-combobox:hover & {
                    color: $combobox-value-presentation-text-color-placeholder-hover;
                }

                .c-combobox:focus & {
                    color: $combobox-value-presentation-text-color-placeholder-focus;
                }

            }

            #{$combobox-disabled-state} & {
                color: $combobox-value-presentation-text-color-disabled;
            }

            #{$combobox-readonly-state} & {
                color: $combobox-value-presentation-text-color-readonly;
            }

        }

    .c-combobox__listbox-container {
        @include scrollable(
            $bg-color:         $combobox-listbox-background,
            $container-height: $combobox-listbox-max-height,
        );
        @include transition();
        position: absolute;
        z-index: $combobox-listbox-z-index;
        top: 100%; /* [5] */
        right: 0; /* [6] */
        left: 0; /* [6] */
        visibility: hidden; /* [7] */
        opacity: 0; /* [7] */
        max-height: $combobox-listbox-max-height;
        overflow-y: auto; /* [8] */
        padding: $combobox-listbox-padding-vertical 0; /* [9] */
        border: if($combobox-listbox-border, $combobox-listbox-border, null);
        background-color: $combobox-listbox-background;
        border-top-width: 0; /* [10] */
        border-bottom-right-radius: $combobox-listbox-border-radius;
        border-bottom-left-radius: $combobox-listbox-border-radius;
        pointer-events: none; /* [24] */

        .c-combobox#{$combobox-listbox-open-state} & {
            visibility: visible; /* [7] */
            opacity: 1; /* [7] */
            border-color: if($combobox-listbox-border, $combobox-listbox-border-color-open, null);
            box-shadow: $combobox-listbox-shadow;
            pointer-events: initial; /* [24] */
        }

    }

    .c-combobox__item {
        @include transition();
        @include disable-touch-highlight();
        position: relative;
        padding: $combobox-item-padding-vertical $combobox-item-padding-horizontal;
        color: $combobox-item-color;
        cursor: pointer;

        &:not(#{$combobox-item-disabled-state}) {

            &#{$combobox-item-selected-state} {
                background-color: if($combobox-item-background-selected, $combobox-item-background-selected, null);
            }

            &:hover,
            &#{$combobox-item-focused-state} {
                background-color: if($combobox-item-background-hover, $combobox-item-background-hover, null);
                color: $combobox-item-color-hover;
            }

        }

        &#{$combobox-item-disabled-state} {
            pointer-events: none;
        }


        @if ($combobox-item-border) {

            &::after { /* [12] */
                @include transition();
                content: "";
                position: absolute;
                z-index: 1;
                bottom: 0;
                left: $combobox-item-border-padding-horizontal;
                display: block;
                width: if($combobox-item-border-padding-horizontal > 0, calc(100% - #{($combobox-item-border-padding-horizontal * 2)}), 100%); /* [3] */
                height: $combobox-item-border-width;
                background-color: $combobox-item-border-color;
            }

            @if ($combobox-item-border-disable-last) {
                &:last-child::after {
                    display: none;
                }
            }

            &:hover,
            &#{$combobox-item-focused-state} {

                &::after {
                    height: $combobox-item-border-width-hover;
                    background-color: $combobox-item-border-color-hover;
                }

            }

        }

    }

        .c-combobox__item__text {
            @include responsive-font-size(
                $font-size:   $combobox-item-font-size,
                $line-height: false,
                $important:   true,
            );
            display: important(flex, true); /* [13] */
            align-items: center;
            font-weight: important($combobox-item-font-weight, true);

            .c-combobox__listbox#{$combobox-listbox-multiselect-state} & {
                // @TODO: Refactor to use a mixin for
                // Checkbox styles instead of `@extend`.
                // BRON-7785
                @extend .c-checkbox__label; /* [4] */ /* stylelint-disable-line scss/at-extend-no-missing-placeholder */
                color: important($combobox-item-checkbox-label-color, true); /* [23] */
            }

            .c-combobox__item:hover & {
                font-weight: $combobox-item-font-weight-hover;
            }

            .c-combobox__item#{$combobox-item-disabled-state} & {
                color: important($combobox-item-color-disabled, true); /* [13] */

                // checkbox background
                &::before {
                    border-color: important($combobox-item-checkbox-border-color-disabled, true); /* [13] */
                    background-color: important($combobox-item-checkbox-background-disabled, true); /* [13] */
                }
            }

            .c-combobox__item#{$combobox-item-selected-state} & {
                font-weight: important($combobox-item-font-weight-selected, true); /* [13] */
                color: important($combobox-item-color-selected, true); /* [13] */

                // checkbox background
                &::before {
                    border-color: important($combobox-item-checkbox-border-color-selected, true); /* [13] */
                    background-color: important($combobox-item-checkbox-background-selected, true); /* [13] */
                }

                // icon
                &::after {
                    /* [4] */
                    transform: important(scale(1), true); /* [13] */
                    visibility: important(visible, true); /* [13] */
                    color: important($combobox-item-checkbox-icon-color, true); /* [13] */
                }

            }

        }

    .c-combobox__listbox.has-no-options::after { /* [18] */
        content: attr(data-combobox-no-options-message);
        display: block;
        width: 100%;
        padding: $combobox-item-padding-vertical $combobox-item-padding-horizontal;
        font-size: $combobox-no-options-message-font-size;
        text-align: if($combobox-no-options-message-text-align, center, null);
        color: $combobox-no-options-message-color;
        pointer-events: none; /* [20] */
    }

    .c-combobox__search-input {
        padding: $combobox-item-search-input-padding-vertical $combobox-item-search-input-padding-horizontal; /* [19] */
        border-bottom: if($combobox-item-search-input-border, $combobox-item-search-input-border, null); /* [19] */
    }
