/* ------------------------------------*\
    #INDICATOR-DOT (Settings)
\*------------------------------------ */

$indicator-dot-offset:    1px !default;
$indicator-dot-text-size: $GLOBAL-STATUS-TEXT-SIZE !default;

$indicator-dot-themes-overwrites: (
    "info": (
        "text-color": $INDICATOR-DOT-LABEL-COLOR,
    ),
    "success": (
        "text-color": $INDICATOR-DOT-LABEL-COLOR,
    ),
    "warning": (
        "text-color": $INDICATOR-DOT-LABEL-COLOR,
    ),
    "unread": (
        "icon-color": $COLOR-ON-SURFACE-200,
    ),
) !default;
