/* ------------------------------------*\
    #DETAILS
\*------------------------------------ */

/**
 * [1] Set `box-sizing` specifically for `<details>`
 *     incl. child- and pseudo-elements as the elements "resets"
 *     the inheritance/cascade for `box-sizing`.
 *     https://stackoverflow.com/questions/75347448/box-sizing-not-inherited-through-details-element/75349350#75349350
 */
details {

    &,
    &::before,
    &::after,
    > *,
    > *::before,
    > *::after {
        box-sizing: border-box; /* [1] */
    }

}
