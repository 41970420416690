/* ------------------------------------*\
    #INPUT-TEXT
\*------------------------------------ */

@use "sass:selector";

$input-height:                          $GLOBAL-INPUT-HEIGHT !default;
$input-padding:                         $GLOBAL-INPUT-PADDING-HORIZONTAL !default;
$input-font-size:                       $GLOBAL-INPUT-FONT-SIZE !default;
$input-font-weight:                     $GLOBAL-INPUT-FONT-WEIGHT !default;
$input-font-color:                      $GLOBAL-INPUT-FONT-COLOR !default;
$input-font-color-disabled:             $GLOBAL-INPUT-FONT-COLOR-DISABLED !default;
$input-font-color-readonly:             $GLOBAL-INPUT-FONT-COLOR-READONLY !default;
$input-font-color-placeholder:          $GLOBAL-INPUT-FONT-COLOR-PLACEHOLDER !default;
$input-font-weight-placeholder:         $GLOBAL-INPUT-FONT-WEIGHT-PLACEHOLDER !default;
$input-background:                      $GLOBAL-INPUT-BACKGROUND !default;
$input-background-disabled:             $GLOBAL-INPUT-BACKGROUND-DISABLED !default;
$input-background-readonly:             $GLOBAL-INPUT-BACKGROUND-READONLY !default;
$input-border:                          $GLOBAL-INPUT-BORDER !default;
$input-border-bottom-only:              $GLOBAL-INPUT-BORDER-BOTTOM-ONLY !default;
$input-border-width:                    $GLOBAL-INPUT-BORDER-WIDTH !default;
$input-border-width-focus:              $GLOBAL-INPUT-BORDER-WIDTH-FOCUS !default;
$input-border-color:                    $GLOBAL-INPUT-BORDER-COLOR !default;
$input-border-color-hover:              $GLOBAL-INPUT-BORDER-COLOR-HOVER !default;
$input-border-color-focus:              $GLOBAL-INPUT-BORDER-COLOR-FOCUS !default;
$input-border-color-disabled:           $GLOBAL-INPUT-BORDER-COLOR-DISABLED !default;
$input-border-color-readonly:           $GLOBAL-INPUT-BORDER-COLOR-READONLY !default;
$input-border-radius:                   $GLOBAL-INPUT-BORDER-RADIUS !default;
$input-textarea-font-size:              $GLOBAL-INPUT-TEXTAREA-FONT-SIZE !default;
$input-textarea-line-height:            $GLOBAL-INPUT-TEXTAREA-LINE-HEIGHT !default;
$input-textarea-spacing-vertical:       $GLOBAL-INPUT-TEXTAREA-PADDING-VERTICAL !default;
$input-textarea-spacing-horizontal:     $GLOBAL-INPUT-TEXTAREA-PADDING-HORIZONTAL !default;
$input-textarea-border-bottom-only:     $GLOBAL-INPUT-TEXTAREA-BORDER-BOTTOM-ONLY !default;
$input-addon-min-width:                 null !default;
$input-addon-padding:                   $GLOBAL-INPUT-ADDON-PADDING-HORIZONTAL !default;
$input-addon-font-color:                $GLOBAL-INPUT-ADDON-FONT-COLOR !default;
$input-addon-font-color-no-bg:          $GLOBAL-INPUT-ADDON-FONT-COLOR-NO-BG !default;
$input-addon-font-color-no-bg-disabled: $GLOBAL-INPUT-ADDON-FONT-COLOR-DISABLED !default;
$input-addon-font-color-hover:          null !default;
$input-addon-font-color-focus:          null !default;
$input-addon-font-color-disabled:       $GLOBAL-INPUT-ADDON-FONT-COLOR-DISABLED !default;
$input-addon-font-color-readonly:       $GLOBAL-INPUT-ADDON-FONT-COLOR-READONLY !default;
$input-addon-background:                $GLOBAL-INPUT-ADDON-BACKGROUND !default;
$input-addon-background-disabled:       $GLOBAL-INPUT-ADDON-BACKGROUND-DISABLED !default;
$input-addon-background-readonly:       $GLOBAL-INPUT-ADDON-BACKGROUND-READONLY !default;
$input-addon-icon-size:                 icon-size($GLOBAL-INPUT-ADDON-ICON-SIZE) !default;
$input-addon-fake-line-width:           $GLOBAL-INPUT-ADDON-FAKE-LINE-WIDTH !default;
$input-select-dropdown-icon:            get-icon(semantic-expand) !default;
$input-select-dropdown-icon-size:       icon-size($GLOBAL-INPUT-SELECT-ICON-SIZE) !default;
$input-select-dropdown-icon-disabled:   false !default;
$input-select-dropdown-icon-padding:    if($input-border-bottom-only, 0, $input-addon-padding) !default;
$input-select-option-color:             $GLOBAL-INPUT-OPTION-COLOR !default;
$input-select-option-color-disabled:    $GLOBAL-INPUT-OPTION-COLOR-DISABLED !default;
$input-state-icon-padding:              $input-addon-padding !default;
$input-state-icon-size:                 $input-addon-icon-size !default;
$input-counter-font-size:               $GLOBAL-INPUT-COUNTER-FONT-SIZE !default;
$input-counter-font-weight:             $GLOBAL-INPUT-COUNTER-FONT-WEIGHT !default;
$input-counter-spacing-top:             $GLOBAL-INPUT-COUNTER-SPACING-TOP !default;
$input-counter-color:                   $GLOBAL-INPUT-COUNTER-COLOR !default;
$input-counter-color-alert:             $GLOBAL-INPUT-COUNTER-COLOR-ALERT !default;

$input-status-themes: (
    error: (
        font-color:         $GLOBAL-INPUT-FONT-COLOR-ERROR,
        border-color:       $GLOBAL-INPUT-BORDER-COLOR-ERROR,
        border-color-hover: $GLOBAL-INPUT-BORDER-COLOR-ERROR-HOVER,
        addon-color:        $GLOBAL-INPUT-ADDON-FONT-COLOR-ERROR,
        addon-color-no-bg:  $GLOBAL-INPUT-ADDON-FONT-COLOR-NO-BG-ERROR,
        enable-state-icon:  true,
        icon:               get-icon(semantic-error),
        icon-color:         $COLOR-ALERT,
    ),
    success: (
        font-color:         $GLOBAL-INPUT-FONT-COLOR-SUCCESS,
        border-color:       $GLOBAL-INPUT-BORDER-COLOR-SUCCESS,
        border-color-hover: $GLOBAL-INPUT-BORDER-COLOR-SUCCESS-HOVER,
        addon-color:        $GLOBAL-INPUT-ADDON-FONT-COLOR-SUCCESS,
        addon-color-no-bg:  $GLOBAL-INPUT-ADDON-FONT-COLOR-NO-BG-SUCCESS,
        enable-state-icon:  true,
        icon:               get-icon(semantic-success),
        icon-color:         $COLOR-SUCCESS,
    ),
) !default;

$input-status-themes-overwrites: () !default;
$input-status-themes-merged:     map-deep-merge($input-status-themes, $input-status-themes-overwrites, 2) !default;

/**
 * [1] Allow us to style box model properties.
 * [2] Line different sized inputs up a little nicer.
 * [3] Only change border for non-background addons.
 * [4] If the input has rounded borders, the fake line's `width` must have the
 *     same value as the `border-radius` to totally cover the border. The
 *     input's border must be replaced by a border attached to the fake line.
 * [5] Set minimum width for addon to have equal size
 *     up to a certain amount of content.
 * [6] Reset default label spacing.
 * [7] Border-depends on the position of symbol.
 * [8] Control position of addon via CSS - not markup order.
 * [9] Set input height (minus vertical borders).
 * [10] Reset default button-style.
 * [11] Chrome needs a reset appearance, otherwise it sets a default
 *      border-radius, in case it's a `<select>`.
 * [12] Textareas must not get a fixed height and a corresponding line-height...
 * [13] ...but instead get a custom padding-top and -bottom to offset the text
 *      content from the top edge of the textarea.
 * [14] Defensively reset border-radius.
 * [15] Create complete border-radius when there is no `.c-input__addon`.
 * [16] Perfectly align the icon inside of `.c-input__addon`.
 * [17] When the input is a `<select>`, the addon is taken out of the documents
 *      flow, so the border-radius needs to be set explicitly here.
 * [18] Since the the icon is laying on top of the select, a click on the arrow
 *      has to be ignored and looped through to the select itself.
 * [19] If it's a `<select>`, don't let long select input text overflow the
 *      dropdown arrow.
 * [20] Vertical center the icon.
 * [21] Firefox won't hide its dotted outline on focus on select elements. This
 *      fixes it. Be aware: Although it works, it's still a hack!
 * [22] Reset styles of input and `.c-input__addon` for readonly state to
 *      prevent focus styles from being used here.
 * [23] Reset styles of input and `.c-input__addon` for disabled state to
 *      prevent default styles from being used here
 * [24] Prevent IE 11 and Edge from showing a browser default cross icon to clear
 *      the text input.
 * [25] Fixes Firefox issue. Firefox grows the input when an input addon is
 *      present. `min-width: 0` fixes that issue. (https://github.com/philipwalton/flexbugs/tree/38fdbe408e2ae6dcf32bff1e29db41c6ab964f55#flexbug-1)
 * [26] When the dropdown icon is hidden at disabled state, stack the select
 *      above the dropdown. This is done as there is no way
 *      (due to missing parent selector) to select the icon (pseudo element on parent)
 *      when the select is disabled.
 * [27] Inherit the color assigned to `.c-input__addon` instead of applying the
 *      default icon color.
 * [28] Explicitly override the border width if text inputs only have a
 *      `border-bottom`. Due to a lack of specificity, the border has to be
 *      reset for the reversed modifier explicitly.
 * [29] Check if textareas should be included in the border-bottom-only
 *      styling and modify the selector accordingly.
 * [30] Add horizontal padding to textareas if they don’t have
 *      border-bottom-only styling.
 * [31] The fake line to cover the border between input and addon is not needed
 *      in Bronson-Audi, because there is not border to cover.
 * [32] Required to display the border color correct on iOS.
 * [33] Enable flex wrap to allow positioning of the character counter below
 *      textareas.
 * [34] Fix positioning of (non-textarea) input fields (with addon) because
 *      of [33].
 * [35] Stretch the counter to full width to ensure it will be displayed below
 *      textarea.
 * [35] Hide counter for disabled and read-only input fields.
 * [37] Styles for the counter alert state.
 * [38] Requires state-class to be applied to input wrapper (`.c-input`)
 *      instead of actual input. This derives on how state classes are
 *      set on other types of inputs.
 *      But due to the limited styling possibilities of native select,
 *      this is a workaround and can be seen as "progressive enhancement".
 * [39] Conditionally show state icon. Depending on brand e.g. the state-icon
 *      for invalid (error) inputs do not have a state-icon but an error message.
 * [40] Overwrite default `transition-property: all` only when `$input-border-width-focus`
 *      is larger than `$input-border-width` to avoid choppy animations due
 *      `border-width` transitions.
 * [41] Use the same transition for all direct children (e.g. `.c-input__addon`)
 * [42] Align color and font-weight settings according to Combobox styles
 *      as progressive enhancement, which is currently not supported by all
 *      browsers, see also: https://caniuse.com/css-has).
 * [43] Ensure correct option text color (for browsers that support setting
 *      option colors via CSS), prevent inheritance from `<select>`.
 */
.c-input {
    @include transition();
    @if ($input-border-width-focus > $input-border-width) {
        --transition-property: color, background-color, border-color, box-shadow, opacity; /* [40] */
    }
    position: relative;
    display: flex;
    flex-wrap: wrap; /* [33] */

    > * {
        transition: inherit; /* [41] */
    }

    &.c-input--select {

        &::after {
            @include icon-font();
            @include font-size(
                $font-size:   $input-select-dropdown-icon-size,
                $line-height: false,
            );
            content: $input-select-dropdown-icon;
            position: absolute;
            top: 50%; /* [20] */
            right: $input-select-dropdown-icon-padding;
            transform: translateY(-50%); /* [20] */
            border: 0;
            color: $input-addon-font-color-no-bg;
            pointer-events: none; /* [18] */
        }

        /* [38] */
        &.is-disabled {

            &::after {
                color: $input-addon-font-color-disabled;
            }

        }

        .c-input__input {

            /* stylelint-disable selector-max-specificity */
            &:has(:disabled:checked) {
                font-weight: $input-font-weight-placeholder; /* [42] */
                color: $input-font-color-placeholder; /* [42] */
            }
            /* stylelint-enable */

            &:-moz-focusring {
                color: transparent; /* [21] */
                text-shadow: 0 0 0 $input-font-color; /* [21] */
            }

            &::-ms-expand {
                display: none; /* [26] */
            }

        }

        option {
            color: $input-select-option-color; /* [43] */

            &:disabled {
                color: $input-select-option-color-disabled;
            }

        }

    }

    .c-icon {
        @include font-size(
            $font-size:   $input-addon-icon-size,
            $line-height: 1
        );
        vertical-align: middle;
    }

    @if ($input-border-width != $input-border-width-focus) { /* [28] */
        &::before {
            @include transition();
            content: "";
            position: absolute;
            z-index: 1;
            right: 0; /* [33] */
            bottom: 0;
            left: 0; /* [33] */
            height: $input-border-width-focus;
            margin-right: auto; /* [33] */
            margin-left: auto; /* [33] */
            background: $input-border-color-focus;
            opacity: 0;
        }

        &:focus-within {

            &::before {
                opacity: 1;
            }

        }

        .c-form-field.is-readonly &:focus-within {

            /* stylelint-disable-next-line selector-max-specificity */
            &::before {
                background: $input-border-color-readonly; /* [22] */
            }

        }

        /* stylelint-disable-next-line order/order */
        @include vary($loop: $input-status-themes-merged, $create: "custom") {

            @at-root {
                .c-form-field.is-#{$vary-loop-entity} &,
                .c-input.is-#{$vary-loop-entity} {

                    &::before {
                        background: vary-get(border-color);
                    }

                }

                .c-form-field.is-#{$vary-loop-entity} {

                    &:hover,
                    &:focus-within {

                        /* stylelint-disable-next-line selector-max-class */
                        .c-input::before {
                            background: vary-get(border-color-hover);
                        }

                    }

                }

                .c-input.is-#{$vary-loop-entity} {

                    &:hover,
                    &:focus-within {

                        &::before {
                            background: vary-get(border-color-hover);
                        }

                    }

                }

            }

        }

    }

}

    .c-input__input {
        flex-grow: 1; /* [34] */
        flex-shrink: 1; /* [34] */
        flex-basis: 0; /* [34] */
        width: 100%;
        min-width: 0; /* [25] */
        padding: 0 $input-padding;
        border: $input-border;
        border-radius: 0; /* [14] */
        font-weight: $input-font-weight;
        background: $input-background;
        color: $input-font-color;
        box-shadow: none;
        appearance: none; /* [11] */
        @if ($input-border-radius != 0) {
            border-top-left-radius: $input-border-radius;
            border-bottom-left-radius: $input-border-radius;
        }

        @if ($input-border-radius != 0) {
            &:only-child {
                border-radius: $input-border-radius; /* [15] */
            }
        }

        &:not(textarea) {
            @include responsive-font-size(
                $font-size:   $input-font-size,
                $line-height: $input-height,
            ); /* [12] */
            height: $input-height; /* [12] */
        }

        @at-root {

            textarea.c-input__input {
                @include responsive-font-size(
                    $font-size:   $input-textarea-font-size,
                    $line-height: $input-textarea-line-height,
                );
                flex-basis: auto; /* [34] */
                padding-top: $input-textarea-spacing-vertical; /* [13] */
                padding-bottom: $input-textarea-spacing-vertical; /* [13] */
            }

            select.c-input__input {
                border-top-right-radius: $input-border-radius; /* [17] */
                border-bottom-right-radius: $input-border-radius; /* [17] */
            }

        }

        .c-input--select & {
            padding-right: ($input-select-dropdown-icon-padding + $input-addon-padding + $input-addon-icon-size); /* [19] */
        }

        .c-input--reversed & {
            border-right: $input-border;
            border-left: 0; /* [7] */
            @if ($input-border-radius != 0) {
                border-radius: 0;
                border-top-right-radius: $input-border-radius;
                border-bottom-right-radius: $input-border-radius;
            }
        }

        &::placeholder {
            font-weight: $input-font-weight-placeholder;
            color: $input-font-color-placeholder;
            opacity: 1;
        }

        &::-ms-clear {
            display: none; /* [24] */
        }

        /**
         * Hover state styles
         */
        &:hover {
            border-color: $input-border-color-hover;

            ~ .c-input__addon {
                border-color: $input-border-color-hover; /* [3] */
            }

        }

        /**
         * Focus state styles
         */
        &:focus,
        &:focus-visible {
            border-color: $input-border-color-focus;
            outline: none;

            ~ .c-input__addon {
                border-color: $input-border-color-focus;
            }

        }

        /**
         * Disabled state styles
         */
        &.is-disabled,
        &[disabled] {
            border-color: $input-border-color-disabled;
            background-color: $input-background-disabled;
            color: $input-font-color-disabled;
            pointer-events: none;
            cursor: default;
            -webkit-text-fill-color: $input-font-color-disabled;
            opacity: 1; /* [32] */

            @if ($input-select-dropdown-icon-disabled) {
                position: relative; /* [26] */
                z-index: 1; /* [26] */
            }

            ~ .c-input__addon {
                border-color: $input-border-color-disabled !important; /* [23] */ /* stylelint-disable-line itcss/no-at-important */
                background-color: $input-background-disabled; /* [23] */
                color: $input-addon-font-color-disabled; /* [23] */
                pointer-events: none;
                cursor: default;

                &:not(.c-input__addon--no-background) {
                    background-color: $input-addon-background-disabled; /* [23] */
                    color: $input-addon-font-color-disabled; /* [23] */
                }

                /* stylelint-disable-next-line selector-max-class */
                &.c-input__addon--no-background {
                    color: $input-addon-font-color-no-bg-disabled;
                }

            }

        }

        /**
         * Readonly state styles
         */
        &.is-readonly,
        &[readonly] {
            border-color: $input-border-color-readonly;
            background-color: $input-background-readonly;
            color: $input-font-color-readonly;
            pointer-events: none;
            cursor: default;
            -webkit-text-fill-color: $input-font-color-readonly;

            ~ .c-input__addon {
                border-color: $input-border-color-readonly; /* [22] */
                background-color: $input-background-readonly; /* [22] */
                color: $input-addon-font-color-readonly; /* [22] */
                pointer-events: none;
                cursor: default;

                &:not(.c-input__addon--no-background) {
                    background-color: $input-addon-background-readonly; /* [22] */
                }

            }

            &:focus {
                border-color: $input-border-color-readonly; /* [22] */
            }

            &:focus ~ .c-input__addon {
                border-color: $input-border-color-readonly;  /* [22] */
            }

        }

        /**
         * Input status themes
         */
        /* stylelint-disable-next-line order/order */
         @include vary($loop: $input-status-themes-merged, $create: "custom") {

            &.is-#{$vary-loop-entity} {
                border-color: important(vary-get(border-color), true);
                color: vary-get(font-color);

                ~ .c-input__addon,
                ~ .c-input__state-icon {
                    border-color: vary-get(border-color);
                    color: vary-get(addon-color);

                    /* stylelint-disable-next-line selector-max-class */
                    &.c-input__addon--no-background {
                        color: vary-get(addon-color-no-bg);
                    }

                }


                &:focus {
                    box-shadow: vary-get(box-shadow);

                    ~ .c-input__addon,
                    ~ .c-input__state-icon {
                        border-color: vary-get(border-color);
                    }
                }

                &:hover {
                    border-color: vary-get(border-color-hover);

                    ~ .c-input__addon,
                    ~ .c-input__state-icon {
                        border-color: vary-get(border-color-hover);
                    }

                }

            }

        }

        @if ($input-border-bottom-only) {

            $input-border-bottom-only-selector: /* [29] */
                if($input-textarea-border-bottom-only, ".c-input", ".c-input:not(.c-input--textarea)");

            #{$input-border-bottom-only-selector} &, /* [29] */
            .c-input--reversed & {
                border-width: 0; /* [28] */
                border-bottom-width: $input-border-width; /* [28] */
            }

            @if (not $input-textarea-border-bottom-only) { /* [30] */
                .c-input--textarea & {
                    padding-right: $input-textarea-spacing-horizontal;
                    padding-left: $input-textarea-spacing-horizontal;
                }
            }
        }

    }

    .c-input__addon {
        @include responsive-font-size(
            $font-size:   $input-font-size,
            $line-height: 1
        );
        position: relative;
        display: flex; /* [16] */
        align-items: center; /* [16] */
        justify-content: center; /* [16] */
        order: 5; /* [8] */
        margin-bottom: 0; /* [6] */
        padding-right: $input-addon-padding;
        padding-left: $input-addon-padding;
        border: $input-border; /* [7] */
        border-left: 0; /* [7] */
        text-align: center;
        background-color: $input-addon-background;
        color: $input-addon-font-color;
        @if ($input-border-radius != 0) {
            border-top-right-radius: $input-border-radius;
            border-bottom-right-radius: $input-border-radius;
        }

        @if ($input-addon-min-width) {
            &:not(.c-input__addon--no-background) {
                min-width: $input-addon-min-width; /* [5] */
            }
        }

        .c-icon {
            color: inherit; /* [27] */
        }

        .c-input--reversed & {
            order: -5; /* [8] */
            border: $input-border; /* [7] */
            border-right: 0; /* [7] */
            @if ($input-border-radius != 0) {
                border-radius: 0;
                border-top-left-radius: $input-border-radius;
                border-bottom-left-radius: $input-border-radius;
            }
        }

        &:where(button) {
            &:hover {
                color: $input-addon-font-color-hover;
            }

            &:focus-visible {
                color: $input-addon-font-color-focus;
            }
        }

        /**
         * Modify fake line to cover up border between input and addon
         * when `.c-input--reversed` modifier is used.
         */
        &::before {

            .c-input--reversed & {
                right: auto;
                left: 100%;
            }

        }

        @if ($input-border-bottom-only) {
            justify-content: flex-end;
            padding-right: 0;

            &,
            .c-input--reversed & {
                border-width: 0; /* [28] */
                border-bottom-width: $input-border-width; /* [28] */
            }

            .c-input--reversed & {
                justify-content: flex-start;
                padding-right: $input-addon-padding;
                padding-left: 0;
            }

            &::after {
                display: none; /* [31] */
            }
        }

    }

    button.c-input__addon {
        padding-top: 0; /* [10] */
        padding-bottom: 0; /* [10] */
        appearance: none; /* [10] */
        cursor: pointer;

        &:focus {
            outline: 0; /* [10] */
        }

    }

    .c-input__addon--no-background {
        position: relative;
        background-color: $input-background;
        color: $input-addon-font-color-no-bg;
    }

    .c-input__state-icon {
        @include icon-font($line-height: null);
        /* [9] */
        @include font-size(
            $font-size:   $input-state-icon-size,
            $line-height: $input-height - ($input-border-width * 2),
        );
        position: relative;
        padding-right: $input-state-icon-padding;
        padding-left: $input-state-icon-padding;
        border: $input-border;
        background: $input-background;

        .c-input--reversed &,
        &:last-child {
            border-top-right-radius: $input-border-radius;
            border-bottom-right-radius: $input-border-radius;
        }

        /* stylelint-disable-next-line order/order */
        @include vary($loop: $input-status-themes-merged, $create: "custom") {
            .c-input__input.is-#{$vary-loop-entity} ~ & {
                @if (vary-get(enable-state-icon)) {
                    border-color: vary-get(border-color);
                    color: vary-get(icon-color);

                    &::after {
                        content: vary-get(icon);
                    }

                } @else {
                    display: none; /* [39] */
                }

            }

        }

        /**
         * Modify fake line to cover up the ROUNDED border between input and state icon.
         */
        @if ($input-border-radius != 0) {
            &::before {

                .c-input--reversed & { /* [4] */
                    top: -($input-border-width);
                    bottom: -($input-border-width);
                    width: $input-border-radius;
                    border-top: $input-border;
                    border-bottom: $input-border;
                    border-color: inherit;
                }

            }
        }

        @if ($input-border-bottom-only) {
            padding-right: 0;
            border-width: 0; /* [28] */
            border-bottom-width: $input-border-width; /* [28] */
        }
    }

    @if not ($input-border-bottom-only) {
        .c-input__addon,
        .c-input__state-icon {

            /**
            * Fake line to cover up the border between input and addon or state icon.
            * This can not be achieved with sibling selector as this would have to affect
            * the input BEFORE the addon or state icon.
            */
            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 100%;
                bottom: 0;
                display: block;
                width: ($input-addon-fake-line-width * 0.5);
                background-color: inherit;
            }

        }
    }

    .c-input__counter {
        @include responsive-font-size($input-counter-font-size);
        width: 100%; /* [35] */
        padding-top: $input-counter-spacing-top;
        font-weight: $input-counter-font-weight;
        line-height: 1;
        text-align: right;
        color: $input-counter-color;

        .c-input__input ~ & {
            margin-top: $input-counter-spacing-top;
        }

        .c-input__input.is-disabled + &,
        .c-input__input[disabled] + &,
        .c-input__input.is-readonly + &,
        .c-input__input[readonly] + & {
            display: none; /* [35] */
        }

    }

        .c-input__counter--alert { /* [37] */
            color: $input-counter-color-alert;
        }
