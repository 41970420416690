/* ------------------------------------*\
    #TEXT-TRANSFORM
\*------------------------------------ */


/**
 * The following classes will transform text into
 * uppercased, capitalized or lowercased characters.
 */

.u-text-lowercase {
    text-transform: lowercase !important;
}

.u-text-uppercase {
    text-transform: uppercase !important;
}

.u-text-capitalize {
    text-transform: capitalize !important;
}
