/* ------------------------------------*\
    #SMALL
\*------------------------------------ */

/**
 * Smallprint text.
 */

$SMALL-TEXT-SIZE: $FS-2 !default;
$SMALL-TEXT-LINE-HEIGHT: none !default;
$SMALL-TEXT-FONT-WEIGHT: null !default;

small {
    @include responsive-font-size($SMALL-TEXT-SIZE, $SMALL-TEXT-LINE-HEIGHT);

    font-weight: $SMALL-TEXT-FONT-WEIGHT;
}
