/* ------------------------------------*\
   #NOTIFICATION-CENTER (Settings)
\*------------------------------------ */

$notification-center-toggle-badge-transform:          translate(-85%, -50%) !default;
$notification-center-toggle-spacing-left:             0 !default; // spacing created visually via size

$notification-center-toggle-icon-color:               inherit !default;

$notification-center-panel-border-color:              $COLOR-BORDER !default;
$notification-center-panel-box-shadow:                $DROP-SHADOW-XL !default;
$notification-center-panel-border-radius:             $GLOBAL-BORDER-RADIUS !default;
$notification-center-panel-background:                $COLOR-WHITE !default;
$notification-center-panel-font-weight:               $GLOBAL-FONT-WEIGHT-REGULAR !default;

$notification-center-panel-header-background:         $COLOR-WHITE !default;
$notification-center-panel-header-color:              $COLOR-BASE-TEXT !default;
$notification-center-panel-header-border-color:       $COLOR-BORDER !default;

$notification-center-panel-heading-font-size:         $FS2 !default;
$notification-center-panel-heading-font-weight:       $GLOBAL-FONT-WEIGHT-BOLD !default;
$notification-center-item-heading-read-font-weight:   $notification-center-panel-heading-font-weight !default;

$notification-center-item-overlay-color:              $COLOR-SURFACE-PRIMARY !default;

$notification-center-item-padding-horizontal:         $GLOBAL-SPACING-UNIT-LARGE !default;
$notification-center-item-padding-vertical:           $GLOBAL-SPACING-UNIT !default;

$notification-center-panel-header-padding-horizontal: $notification-center-item-padding-horizontal !default;

$notification-center-item-status-position-top:        ($notification-center-item-padding-vertical + 10px) !default; // Align with first line
$notification-center-item-status-position-left:       $notification-center-item-padding-horizontal !default;

$notification-center-item-border-color:               $COLOR-BORDER !default;
$notification-center-item-mark-read-font-size:        $FS-1 !default;

$notification-center-item-close-position-top:         $__SKODA-SPACING-STATIC-12__ !default;
$notification-center-item-close-position-right:       $GLOBAL-SPACING-UNIT !default;
$notification-center-item-close-padding:              0 !default;

$notification-center-item-close-icon-size:            icon-size(xxsmall) !default;
$notification-center-item-close-color:                $COLOR-BASE-TEXT !default;

$notification-center-item-timestamp-color:            $COLOR-ON-SURFACE-500 !default;
$notification-center-item-timestamp-font-weight:      $GLOBAL-FONT-WEIGHT-REGULAR !default;
