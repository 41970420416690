/* ------------------------------------*\
    #MEDIA-GRID (Settings)
\*------------------------------------ */

// Similar to button (link) styles
$media-grid-label-font-size:        $BUTTON-TEXT-SIZE !default;
$media-grid-label-font-weight:      $BUTTON-FONT-WEIGHT !default;
$media-grid-label-color:            $BUTTON-LINK-TEXT-COLOR !default;
$media-grid-label-color-hover:      $BUTTON-LINK-TEXT-COLOR-HOVER !default;
$media-grid-label-text-transform:   false !default;

$media-grid-label-spacing-vertical: $GLOBAL-SPACING-UNIT-XSMALL !default;

$media-grid-image-blur-on-hover:    2px !default;
