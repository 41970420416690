// /* ------------------------------------*\
//     #FLICKITY (Settings)
// \*------------------------------------ */

$flickity-button-size:                              map-get($ICON-SQUARE-FULL-SIZES, medium) !default;
$flickity-button-icon-next:                         get-icon(arrow-right) !default;
$flickity-button-icon-prev:                         get-icon(arrow-left) !default;
$flickity-button-icon-color:                        $COLOR-BASE-TEXT !default;
$flickity-button-offset:                            $GLOBAL-SPACING-UNIT-SMALL !default;

$flickity-button-border-radius:                     50% !default;

$flickity-button-background-color-hover:            $BUTTON-ICON-INTERACTION-BACKGROUND-COLOR-HOVER-ON-LIGHT !default;

$flickity-controls-spacing-vertical:                $GLOBAL-SPACING-UNIT-SMALL !default;
$flickity-controls-spacing-horizontal:              $GLOBAL-SPACING-UNIT-SMALL !default;

$flickity-fullscreen-button-border-radius:          $BUTTON-BORDER-RADIUS !default;
$flickity-fullscreen-button-top:                    auto !default;
$flickity-fullscreen-button-right:                  $flickity-button-offset !default;
$flickity-fullscreen-button-bottom:                 $flickity-fullscreen-button-right !default;
$flickity-fullscreen-button-size:                   map-get($ICON-ROUND-FULL-SIZES, xsmall) !default;
$flickity-fullscreen-button-background-color:       $COLOR-WHITE !default;
$flickity-fullscreen-button-background-color-hover: $flickity-button-background-color-hover !default;
$flickity-fullscreen-icon-size:                     icon-size(xsmall) !default;
$flickity-fullscreen-icon-color:                    $flickity-button-icon-color !default;

$flickity-dot-spacing-horizontal:                   $GLOBAL-SPACING-UNIT-SMALL !default;
$flickity-dot-spacing-vertical:                     $GLOBAL-SPACING-UNIT !default;
$flickity-dot-size:                                 map-get($ICON-ROUND-FULL-SIZES, xsmall) !default;
