/* ------------------------------------*\
    #BUTTONS (Scopes)
\*------------------------------------ */

$scope-media-teaser-button-breakpoint: $media-teaser-breakpoint !default;
$scope-media-teaser-button-full-width: true !default; // @TODO: BRON-6472
$scope-table-button-disabled-color:    $COLOR-BASE-TEXT !default;

/*
 * [1] Set the button width to 100% on media teaser breakpoint.
 * [2] The vertical media teaser's button is always 100% width.
 * [3] Overwrite the disabled styles as they are handled by the overlay.
 */
.c-btn {

    @if ($scope-media-teaser-button-full-width) {
        .c-media-teaser .c-media-teaser__footer & {
            @include media-query("<=#{$scope-media-teaser-button-breakpoint}") {
                width: 100%; /* [1] */
            }
        }

        .c-media-teaser--vertical .c-media-teaser__footer & {
            width: 100%; /* [2] */
        }
    }

    .c-back-to-top & {
        --button-flex-direction: row;
    }

    /* stylelint-disable selector-max-specificity, selector-max-combinators, selector-max-compound-selectors, selector-max-class */
    .c-table-wrapper [aria-disabled="true"] &,
    .c-table-wrapper .is-disabled & {

        .c-btn__text.c-btn__text,
        .c-btn__icon.c-btn__icon {
            color: $scope-table-button-disabled-color; /* [3] */
        }

    }
    /* stylelint-enable selector-max-specificity, selector-max-combinators, selector-max-compound-selectors, selector-max-class */

}
