/* ------------------------------------*\
    #TREE-VIEW
\*------------------------------------ */
$tree-view-breakpoint:                              s !default;

$tree-view-item-background-color:                   null !default;
$tree-view-item-background-color-hover:             $COLOR-PALE !default;
$tree-view-item-background-color-selected:          $COLOR-HIGHLIGHT !default;
$tree-view-item-background-color-disabled:          transparent !default;
$tree-view-item-background-color-disabled-selected: $COLOR-PALE !default;
$tree-view-item-background-color-accent:            null !default;
$tree-view-item-background-color-accent-alt:        null !default;
$tree-view-item-background-color-accent-hover:      null !default;
$tree-view-item-background-color-accent-alt-hover:  null !default;
$tree-view-item-background-color-transition:        background-color $GLOBAL-TRANSITION-DURATION $GLOBAL-TRANSITION-TIMING-FUNCTION !default;

$tree-view-item-border-width:                       1px !default;
$tree-view-item-border-color:                       $COLOR-BORDER !default;
$tree-view-item-border-style:                       solid !default;
$tree-view-item-border:                             $tree-view-item-border-width $tree-view-item-border-color $tree-view-item-border-style !default;
$tree-view-item-focus-offset:                       -($GLOBAL-FOCUS-WIDTH) !default;
$tree-view-item-indentation:                        $GLOBAL-SPACING-UNIT-LARGE !default;
$tree-view-item-indentation-at-breakpoint:          $GLOBAL-SPACING-UNIT-SMALL !default;
$tree-view-item-spacing-vertical:                   $GLOBAL-SPACING-UNIT !default;
$tree-view-item-spacing-vertical-at-breakpoint:     $GLOBAL-SPACING-UNIT-XSMALL !default;

$tree-view-icon:                                    get-icon(semantic-expand) !default;
$tree-view-icon-color:                              $COLOR-BRAND !default;
$tree-view-icon-color-accent:                       $COLOR-BRAND !default;
$tree-view-icon-color-accent-alt:                   $COLOR-BRAND !default;
$tree-view-icon-color-disabled:                     $COLOR-INACTIVE !default;
$tree-view-icon-size:                               icon-size(xsmall) !default;
$tree-view-icon-transform-collapsed:                rotate(-90deg) !default;
$tree-view-icon-transform-expanded:                 rotate(0) !default;
$tree-view-icon-transform-origin:                   center !default;
$tree-view-icon-spacing-vertical:                   $tree-view-item-spacing-vertical !default;
$tree-view-icon-spacing-vertical-at-breakpoint:     $tree-view-item-spacing-vertical-at-breakpoint !default;

$tree-view-item-state-expanded:                     "[aria-expanded='true']" !default;
$tree-view-item-state-collapsed:                    "[aria-expanded='false']" !default;

$tree-view-title-font-family:                       null !default;
$tree-view-title-font-size:                         $FS0 !default;
$tree-view-title-text-color:                        $COLOR-BASE-TEXT !default;
$tree-view-title-text-color-accent:                 $tree-view-title-text-color !default;
$tree-view-title-text-color-accent-alt:             $tree-view-title-text-color !default;
$tree-view-title-text-color-hover:                  $tree-view-title-text-color !default;
$tree-view-title-text-color-selected:               $tree-view-title-text-color !default;
$tree-view-title-text-color-disabled:               $COLOR-INACTIVE !default;

$tree-view-subtitle-font-family:                    $tree-view-title-font-family !default;
$tree-view-subtitle-font-size:                      $tree-view-title-font-size !default;
$tree-view-subtitle-text-color:                     $tree-view-title-text-color !default;
$tree-view-subtitle-text-color-accent:              $tree-view-title-text-color-accent !default;
$tree-view-subtitle-text-color-accent-alt:          $tree-view-title-text-color-accent-alt !default;
$tree-view-subtitle-text-color-hover:               $tree-view-title-text-color-hover !default;
$tree-view-subtitle-text-color-selected:            $tree-view-title-text-color-selected !default;
$tree-view-subtitle-text-color-disabled:            $tree-view-title-text-color-disabled !default;

/**
 * [1] We need to clip the horizontal bounds at root level
 *     to cut off the pseudo-element from [5].
 * [2] Handle the collapse and expanded state of nested lists via the
 *     `aria-expanded` attribute from the parent tree list item.
 * [3] Disable focus styles on the focusable element and pass them down
 *     to the contained tree-view body.
 * [4] Only enable pointer for expandable tree list items.
 * [5] State is handled via tree list item parent but the styles are applied to the
 *     `.c-tree-view__body` and its `::before` pseudo-element. The pseudo-element
 *     carries the background and border state colors.
 * [6] If the parent tree list item receives focus, hover or has the
 *     `.is-selected`, `.is-accented` or `.is-alt-accented` class
 *     style the element accordingly.
 * [7] Display the tree view icon as `::after` pseudo-element of the `.c-tree-view__body`.
 * [8] Change the tree view icon depending on the parent tree list item state from [2].
 * [9] Reset default list styles.
 * [10] Offset the icon by its site which is the item’s margin.
 * [11] The pseudo-element spans across the double of the viewport and is pulled back
 *      by the width of the viewport (-100vw) to be independent of position context and nesting.
 *      The pseudo-element should always cover the whole row.
 * [12] Lower the positioning layer to make the pseudo-element from [5] appear below the row content.
 * [13] Indent nested tree view items.
 */

.c-tree-view {
    margin: 0; /* [9] */
    padding: 0; /* [9] */
    list-style: none; /* [9] */

    &.is-tree-view-root-node {
        overflow-x: hidden; /* [1] */
        border-bottom: $tree-view-item-border;
    }

    #{$tree-view-item-state-collapsed} > & {
        display: none; /* [2] */
    }

    #{$tree-view-item-state-expanded} > & {
        display: block; /* [2] */
    }

}

    .c-tree-view__item {
        @include disable-focus(); /* [3] */
        position: relative;
        margin: 0;

        &[aria-expanded].is-hovered {
            cursor: pointer; /* [4] */
        }

        &[aria-expanded].is-disabled {
            cursor: default;
        }

        & & { /* [13] */
            padding-left: $tree-view-item-indentation;

            @include media-query("<=#{$tree-view-breakpoint}") {
                padding-left: $tree-view-item-indentation-at-breakpoint;
            }
        }

    }

    .c-tree-view__body {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: $tree-view-item-spacing-vertical;
        padding-bottom: $tree-view-item-spacing-vertical;
        padding-left: $tree-view-item-indentation;

        @include media-query("<=#{$tree-view-breakpoint}") {
            padding-top: $tree-view-item-spacing-vertical-at-breakpoint;
            padding-bottom: $tree-view-item-spacing-vertical-at-breakpoint;
            padding-left: $tree-view-item-indentation-at-breakpoint;
        }

        &::before { /* [5] */
            content: "";
            position: absolute;
            z-index: -1; /* [12] */
            top: 0;
            left: -100vw; /* [11] */
            display: block;
            width: 200vw; /* [11] */
            height: 100%;
            border-top: $tree-view-item-border;
            transition: $tree-view-item-background-color-transition;
        }

        .c-tree-view__item > &::before {
            background-color: $tree-view-item-background-color;
        }

        .c-tree-view__item:focus > & { /* [6] */
            @include focus-styles($outline-offset: $tree-view-item-focus-offset);
        }

        .c-tree-view__item.is-hovered > &::before { /* [6] */
            background-color: $tree-view-item-background-color-hover;
        }

        .c-tree-view__item.is-selected > &::before { /* [6] */
            background-color: $tree-view-item-background-color-selected;
        }

        .c-tree-view__item.is-accented > &::before { /* [6] */
            background-color: $tree-view-item-background-color-accent;
        }

        .c-tree-view__item.is-alt-accented > &::before { /* [6] */
            background-color: $tree-view-item-background-color-accent-alt;
        }

        /* stylelint-disable selector-max-class, selector-max-specificity */
        .c-tree-view__item.is-selected.is-accented > &::before,
        .c-tree-view__item.is-hovered.is-accented > &::before { /* [6] */
            background-color: $tree-view-item-background-color-accent-hover;
        }

        .c-tree-view__item.is-selected.is-alt-accented > &::before,
        .c-tree-view__item.is-hovered.is-alt-accented > &::before { /* [6] */
            background-color: $tree-view-item-background-color-accent-alt-hover;
        }
        /* stylelint-enable */

        .c-tree-view__item.is-disabled > &::before { /* [6] */
            background-color: $tree-view-item-background-color-disabled;
        }

        /* stylelint-disable selector-max-class, selector-max-specificity */
        .c-tree-view__item.is-selected.is-disabled > &::before { /* [6] */
            background-color: $tree-view-item-background-color-disabled-selected;
        }
        /* stylelint-enable */

        .c-tree-view__item.is-tree-view-parent-node > & { /* [7] */
            position: relative;

            &::after {
                @include icon-font($defaults: null);
                @include font-size(
                    $font-size: $tree-view-icon-size,
                    $line-height: 1
                );
                @include transition($transition-property: transform);

                content: $tree-view-icon;
                position: absolute;
                top: $tree-view-icon-spacing-vertical;
                left: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                width: $tree-view-item-indentation;
                height: $tree-view-item-indentation;
                margin-left: -($tree-view-item-indentation); /* [10] */
                transform-origin: $tree-view-icon-transform-origin;
                color: $tree-view-icon-color;

                @include media-query("<=#{$tree-view-breakpoint}") {
                    top: $tree-view-icon-spacing-vertical-at-breakpoint;
                    width: $tree-view-item-indentation-at-breakpoint;
                    height: $tree-view-item-indentation-at-breakpoint;
                    margin-left: -($tree-view-item-indentation-at-breakpoint); /* [10] */
                }
            }

        }

        .c-tree-view__item.is-accented > &::after {
            color: $tree-view-icon-color-accent;
        }

        .c-tree-view__item.is-alt-accented > &::after {
            color: $tree-view-icon-color-accent-alt;
        }

        .c-tree-view__item.is-disabled > &::after {
            color: $tree-view-icon-color-disabled;
        }

        .c-tree-view__item#{$tree-view-item-state-expanded} > &::after { /* [8] */
            transform: $tree-view-icon-transform-expanded;
        }

        .c-tree-view__item#{$tree-view-item-state-collapsed} > &::after { /* [8] */
            transform: $tree-view-icon-transform-collapsed;
        }

    }

    .c-tree-view__title {
        @include responsive-font-size($tree-view-title-font-size);
        font-family: $tree-view-title-font-family;

        color: $tree-view-title-text-color;

        /* stylelint-disable selector-max-class */
        .c-tree-view__item.is-hovered > .c-tree-view__body & {
            color: $tree-view-title-text-color-hover;
        }

        .c-tree-view__item.is-selected > .c-tree-view__body & {
            color: $tree-view-title-text-color-selected;
        }

        .c-tree-view__item.is-accented > .c-tree-view__body & {
            color: $tree-view-title-text-color-accent;
        }

        .c-tree-view__item.is-alt-accented > .c-tree-view__body & {
            color: $tree-view-title-text-color-accent-alt;
        }

        .c-tree-view__item.is-disabled > .c-tree-view__body & {
            color: $tree-view-title-text-color-disabled;
        }
        /* stylelint-enable */

    }

    .c-tree-view__subtitle {
        @include responsive-font-size($tree-view-subtitle-font-size);
        font-family: $tree-view-subtitle-font-family;

        color: $tree-view-subtitle-text-color;

        /* stylelint-disable selector-max-class */
        .c-tree-view__item.is-hovered > .c-tree-view__body & {
            color: $tree-view-subtitle-text-color-hover;
        }

        .c-tree-view__item.is-selected > .c-tree-view__body & {
            color: $tree-view-subtitle-text-color-selected;
        }

        .c-tree-view__item.is-accented > .c-tree-view__body & {
            color: $tree-view-subtitle-text-color-accent;
        }

        .c-tree-view__item.is-alt-accented > .c-tree-view__body & {
            color: $tree-view-subtitle-text-color-accent-alt;
        }

        .c-tree-view__item.is-disabled > .c-tree-view__body & {
            color: $tree-view-subtitle-text-color-disabled;
        }
        /* stylelint-enable */

    }
