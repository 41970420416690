/* ------------------------------------*\
    #ICONS
\*------------------------------------ */

@use "sass:map";

$icon-color:           $ICON-COLOR !default;
$icon-text-decoration: none !default;

/**
 * [1] In case the icon is an `<a>` element, this gives us an easy way of
 *     eliminating an underline appearing in IE11. In case you actually want
 *     any text-decoration, you can override the `$icon-text-decoration`
 *     variable.
 */
.c-icon {
    @include icon-font();
    @include reset-button();
    @include font-size(
        $font-size:   icon-size(medium),
        $line-height: false
    );
    text-decoration: $icon-text-decoration; /* [1] */
    color: $icon-color;

    &::before {
        @include transition();
        content: var(--bron-icon);
        display: inline-block;
    }

}



/**
 * Generate fallback-icons.
 * Use key as name and value as icon content.
 */
@if ($FALLBACK-ICONS) {
    // Merge old fallback icons with proper new icon naming for class generation, so
    // both, old and new icons are available as icon classes.
    $FALLBACK-ICONS-MAPPED: ();
    @each $fallback-icon, $data in $FALLBACK-ICONS {
        $name: $fallback-icon;
        $current: map.get($FALLBACK-ICONS, $fallback-icon, "current");
        $FALLBACK-ICONS-MAPPED: map.merge($FALLBACK-ICONS-MAPPED, ($name: map.get($ICONS, $current)));
    }

    $ICONS: map.merge($ICONS, $FALLBACK-ICONS-MAPPED);
}

/**
 * Generate icon definitions for default and optional complexities.
 */
@each $name, $data in $ICON-COMPLEXITY-DATA {
    .c-icon--\[#{$name}\] {
        @include icon-generate-definition($data);
    }
}

/**
 * Extend icon selectors for semantic icons.
 */
@each $name, $content in $SEMANTIC-ICONS {
    @if not ($content == null) {
        .c-icon--\[#{$name}\] {
            @extend .c-icon--\[#{$content}\]; /* stylelint-disable-line scss/at-extend-no-missing-placeholder */
        }
    } @else {
        @include placeholder-icon-semantic($name);
    }
}

/**
 * Extend icon selectors for fallbacks.
 */
@if ($FALLBACK-ICONS) {
    @each $name, $data in $FALLBACK-ICONS {
        $current: map.get($FALLBACK-ICONS, $name, "current");

        @if not ($current == null) {
            .c-icon--\[#{$name}\] {
                @extend .c-icon--\[#{$current}\]; /* stylelint-disable-line scss/at-extend-no-missing-placeholder */
            }
        }

    }
}

/**
 * Generate combo modifier for size & complexity.
 */
@each $size, $complexity in $ICON-SIZE-TO-COMPLEXITY-MAPPING {
    // skip medium (default)
    @if ($complexity) {
        .c-icon--#{$size} {
            @include icon-complexity($complexity);
        }
    }
}

 /**
 * Generate sizing modifiers.
 */
@each $size, $value in $ICON-SIZES {

    // Skip medium (default).
    @if ($size != "medium") {
        .c-icon--#{$size} {
            @include font-size(
                $font-size:   icon-size($size),
                $line-height: false
            );
        }

    }

}
