/* ------------------------------------*\
    #MODAL
\*------------------------------------ */

$modal-max-height:                    45vh !default;
$modal-max-height-stretch:            90vh !default;

$modal-z-index:                       z("modal") !default;

// The border-radius of the modal.
$modal-border-radius:                 null !default;
$modal-close-button-border-radius:    null !default;

// The drop-shadow of the modal.
$modal-shadow:                        $DROP-SHADOW-LARGE !default;

$modal-title-font-size:               $FS3 !default;
$modal-title-line-height:             $GLOBAL-HEADING-BASE-LINE-HEIGHT !default;

// Text-alignment
$modal-alignment:                     left !default;
$modal-title-alignment:               $modal-alignment !default;
$modal-content-alignment:             $modal-alignment !default;
$modal-footer-alignment:              $modal-alignment !default;

// Color for overlay backdrop (the area surrounding the modal)
$modal-overlay-color:                 $GLOBAL-BACKDROP-BACKGROUND !default;
$modal-overlay-filter:                null !default;

// Main background color of the modal itself.
$modal-background-color:              $COLOR-WHITE !default;

// Color of the modal background. Overwrites main background color
$modal-header-background-color:       null !default;

// Color for modal foreground (e.g. the text)
$modal-content-color:                 $COLOR-BASE-TEXT !default;

// if the close button is inline, a padding is added between the button and the heading
$modal-close-button-spacing:          $GLOBAL-SPACING-UNIT-SMALL !default;

// Define what icon should represent the close button of the modal.
$modal-close-button-icon-complexity:  null !default;
$modal-close-button-icon:             semantic-close !default;

$modal-close-button-icon-variant:     null !default;

// Icon size of the close button.
// This has to match any key from the `$ICON-SIZES` map.
$modal-close-button-icon-size:        icon-size(small) !default;

$modal-status-icon-enable:            true !default;
$modal-status-icon-complexity:        null !default;
$modal-status-icon:                   semantic-info !default;
$modal-status-icon-spacing:           $GLOBAL-SPACING-UNIT !default;
$modal-status-icon-size:              medium !default;

// The height of the fading gradient for scrolling.
// The default value is 1.5 times the font-size to ensure a smooth gradient.
$modal-content-fade-height:           1.5em !default;

$modal-actions-spacing-horizontal:    $BUTTON-ITEM-SPACING-VERTICAL !default;
$modal-actions-spacing-vertical:      $BUTTON-ITEM-SPACING-HORIZONTAL !default;
$modal-actions-alignment:             center !default;
$modal-actions-fill-space-breakpoint: none !default;
$modal-actions-min-width:             180px !default;

// A responsive value map can be passed for all paddings.
// See: packages/bronson-core/02-tools/_tools.responsive-property.scss
// Default spacings
$modal-padding-horizontal:            $GLOBAL-SPACING-UNIT !default;
$modal-padding-vertical:              $GLOBAL-SPACING-UNIT !default;

// Header spacings modal
$modal-header-padding-horizontal:     $modal-padding-horizontal !default;
$modal-header-padding-vertical:       $modal-padding-vertical !default;
$modal-header-padding-top:            $modal-header-padding-vertical !default;
$modal-header-padding-bottom:         $modal-header-padding-vertical !default;

// Content spacings. No bottom padding because of content-fade.
// See .c-modal__footer::before
$modal-content-padding-horizontal:    $modal-padding-horizontal !default;
$modal-content-padding-top:           null !default;

// Footer spacings
$modal-footer-padding-horizontal:     $modal-padding-horizontal !default;
$modal-footer-padding-vertical:       $modal-padding-vertical !default;
$modal-footer-padding-top:            $modal-footer-padding-vertical !default;
$modal-footer-padding-bottom:         $modal-footer-padding-vertical !default;

// Spacings for `.c-modal--stretch` variant
$modal-stretch-spacing-top:           $GLOBAL-SPACING-UNIT-LARGE !default;
$modal-stretch-spacing-bottom:        $GLOBAL-SPACING-UNIT-LARGE !default;
$modal-stretch-alignment-left:        auto !default;
$modal-stretch-positioning:           auto !default;

// Map containing different settings for status-icons.
// Map-Name: Status-Name
// border {BOOLEAN}: include bordered icon-variant
$modal-status-icons: (
    info: (
        icon:   semantic-info,
        color:  map-deep-get($STATUS-THEMES, "info", "icon-color"),
        border: false,
    ),
    success: (
        icon:   semantic-success,
        color:  map-deep-get($STATUS-THEMES, "success", "icon-color"),
        border: false,
    ),
    warning: (
        icon:   semantic-warning,
        color:  map-deep-get($STATUS-THEMES, "warning", "icon-color"),
        border: false,
    ),
    error: (
        icon:   semantic-error,
        color:  map-deep-get($STATUS-THEMES, "error", "icon-color"),
        border: false,
    ),
) !default;

// Merge default and overwrite settings to avoid duplications
// and overwrite only necessary settings.
$modal-status-icons-overwrites:       () !default;
$modal-status-icons-merged:           map-deep-merge($modal-status-icons, $modal-status-icons-overwrites, 2) !default;

$modal-widths: (
    medium: (
        min: 500px,
        max: 800px,
    ),
    large: (
        min: 1000px,
        max: 1600px,
    ),
    video: (
        min: 750px,
        max: 1000px,
    ),
) !default;

// Spacing between viewport edge and modal.
// Use the same page-wrap-padding for small viewports by default.
$modal-edge-safespace:                map-get($page-wrap-padding-horizontal, s) !default;

// When defining a variant for the close button, either use `null` or define
// a map with the following structure:
// ```
// $modal-close-button-icon-variant: (
//     "variant":
//     "size":
//     "text-color":
//     "background-color":
//     "border-color":
// )
// ```

/**
 * [1]  Modal has to sit on top of everything else.
 * [2]  Fix wobbling inline elements when hovering buttons in the modal
 *      (e.g. macOS Chrome 78 on non-retina displays).
 * [3]  Center the modal content in the middle of the viewport.
 * [4]  If the modal content exceeds the viewport height, this still makes the
 *      content accessible (i.e. scrollable).
 * [5]  The modal title should always be semantically a `<h1>`, but of course
 *      have a smaller font-size.
 * [6]  Add a near-logarithmic gradient at bottom of content.
 * [7]  Allow click through.
 * [8]  Avoid showing gradient when not necessary and override the default
 *      margin-bottom of the last element in the modal content (e.g. `<p>`
 *      has margin-bottom by default).
 * [9]  Reset default heading element spacing.
 * [10] If $modal-content-padding-horizontal is a map, we need to divide the
 *      values in half for each breakpoint.
 * [11] Loop through paddings, take half of each value and push to new map
 *      $content-padding-horizontal-half.
 * [12] The gradient should not overlay the scrollbar.
 * [13] Indicate interaction on non-interactive elements.
 * [14] Prevent elements inside the modal-trigger catching any events
 *      due to event delegation.
 * [15] Overwrite settings to enable `.c-modal--stretch` modal.
 * [16] Position the `.c-modal--stretch` modal in the center without transition.
 * [17] Add some bottom spacing.
 * [18] Prevent interaction with modal content when closed.
 * [19] Take available space in order for alignment on the parent to take effect.
 * [20] This allows scrolling (e.g. when modal height exceeds viewport height)
 *      while pointer is on the overlay.
 * [21] Reduce the line-height to avoid large spacing.
 * [22] Prevent overscroll in the background outside of the modal.
 * [23] Add second param to `min()` function to maintain safespace
 *      for `stretch` variant.
 *      `min()` function not necessary for non-stretch variants.
 * [24] Inline with status-icon by default.
 *      Wrap title to next line to ensure readability when not enough space for title.
 * [25] Improve readability with adjusted line-height for title,
 *      make it independent of heading level and improve status-icon alignment.
 */

// Don't generate scroll-shadow. This prevents bleeding shadow into content,
// e.g. in responsive modules like images or videos.
@mixin modal-no-scroll-shadow() {
    .c-modal__footer {

        &::before {
            content: none;
        }

    }
}

.c-modal {
    --modal-width-min: #{map-deep-get($modal-widths, medium, min)};
    --modal-width-max: #{map-deep-get($modal-widths, medium, max)};

    &.c-modal--large {
        --modal-width-min: #{map-deep-get($modal-widths, large, min)};
        --modal-width-max: #{map-deep-get($modal-widths, large, max)};
    }

    &.c-modal--video {
        --modal-width-min: #{map-deep-get($modal-widths, video, min)};
        --modal-width-max: #{map-deep-get($modal-widths, video, max)};
    }

    /* stylelint-disable-next-line order/order */
    @include transition();
    position: fixed;
    z-index: $modal-z-index; /* [1] */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    visibility: visible;
    backface-visibility: hidden; /* [2] */

    &[aria-hidden="true"] {
        pointer-events: none; /* [18] */
        opacity: 0;
        visibility: hidden;
    }

}

    .c-modal--center {

        .c-modal__title__wrapper {
            justify-content: center;
        }

        .c-modal__title {
            flex-grow: 0;
            flex-basis: auto;
            text-align: center;
        }

        .c-modal__content {
            text-align: center;
        }

    }

    .c-modal--stretch {

        .c-modal__wrapper {
            position: relative; /* [15] */
            top: $modal-stretch-spacing-top;
            left: $modal-stretch-alignment-left;
            margin: $modal-stretch-positioning;
            margin-bottom: $modal-stretch-spacing-bottom;
            transform: none !important; /* [15] */ /* stylelint-disable-line itcss/no-at-important *//* [15] */ /* [16] */ /* [17] */
        }

    }

    .c-modal--stretch,
    .c-modal--video {

        .c-modal__wrapper {
            max-height: $modal-max-height-stretch;
            overflow-y: auto;
            overscroll-behavior: none; /* [22] */
        }

        .c-modal__content {
            max-height: none;
        }

    }

    .c-modal--no-scroll-shadow {
        @include modal-no-scroll-shadow();
    }

    .c-modal--video {
        @include modal-no-scroll-shadow();

        .c-modal__content {
            margin-right: 0;
            margin-left: 0;
            padding-right: 0;
            padding-left: 0;
        }

    }

/**
 * Styles applied to a js-hook (which is a no-no) is an exception as the trigger
 * can be applied to arbitrary elements but need those additional styles to
 * work properly.
 */
.js-modal-trigger {
    cursor: pointer; /* [13] */

    * {
        pointer-events: none; /* [14] */
    }

}

    .c-modal__overlay {
        @include backdrop(
            $color:  $modal-overlay-color,
            $filter: $modal-overlay-filter,
        );
        z-index: -1; /* [20] */
    }

    .c-modal__wrapper {

        position: fixed; /* [3] */
        top: 50%; /* [3] */
        left: 50%; /* [3] */
        min-width: min(var(--modal-width-min), calc(100vw - #{$modal-edge-safespace * 2}));
        max-width: min(var(--modal-width-max), calc(100vw - #{$modal-edge-safespace * 2})); /* [23] */
        transform: translate(-50%, -50%); /* [3] */
        border-radius: $modal-border-radius;
        background-color: $modal-background-color;
        box-shadow: if($modal-shadow, $modal-shadow, null);
    }

    .c-modal__header {
        @include responsive-property(
            ("padding-top",    $modal-header-padding-top),
            ("padding-left",   $modal-header-padding-horizontal),
            ("padding-right",  $modal-header-padding-horizontal),
            ("padding-bottom", $modal-header-padding-bottom)
        );

        display: flex;
        gap: $modal-close-button-spacing;

        @if ($modal-header-background-color) {
            background-color: $modal-header-background-color;
        }
    }

    .c-modal__title__wrapper {
        display: flex;
        gap: $modal-status-icon-spacing;
        flex-wrap: wrap;
        align-items: center;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        text-align: $modal-title-alignment;
    }

    .c-modal__status-icon {
        @if ($modal-status-icon-enable) {
            align-self: baseline;
            font-size: icon-size($modal-status-icon-size);
            line-height: 1; /* [21] */

            &::before {
                @include icon-font();
            }

            /* stylelint-disable-next-line order/order */
            @include vary($loop: $modal-status-icons-merged, $create: "custom") {
                $_modifier: $vary-loop-entity;
                $_icon:     vary-get("icon");
                $_border:   vary-get("border");

                .c-modal--#{$_modifier} & {
                    color: vary-get("color");

                    @if (vary-get("border") == true) {
                        @include icon-variant(
                            $variant:      "round",
                            $border-color: currentColor,
                            $size:         $modal-status-icon-size
                        );
                    }

                    &::before {
                        content: get-icon($_icon, $modal-status-icon-complexity);
                    }
                }
            }
        } @else {
            display: none;
        }
    }

    .c-modal__title {
        @include responsive-font-size(
            $font-size: $modal-title-font-size,
            $line-height: $modal-title-line-height,
        ); /* [5] */ /* [25] */
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 80%; /* [24] */
        margin-bottom: 0; /* [9] */
    }

    .c-modal__close-btn {
        @include reset-button();

        @if ($modal-close-button-icon-variant) {
            @include icon-variant(
                $variant:          map-get($modal-close-button-icon-variant, "variant"),
                $size:             map-get($modal-close-button-icon-variant, "size"),
                $text-color:       map-get($modal-close-button-icon-variant, "text-color"),
                $background-color: map-get($modal-close-button-icon-variant, "background-color"),
                $border-color:     map-get($modal-close-button-icon-variant, "border-color")
            );
        }

        align-self: baseline;
        margin-left: auto;
        border-radius: $modal-close-button-border-radius;
        line-height: 1;
        cursor: pointer;

        &::before {
            @include icon-font();
            @include font-size(
                $font-size:   $modal-close-button-icon-size,
                $line-height: false
            );
            content: get-icon($modal-close-button-icon, $modal-close-button-icon-complexity);
        }
    }

    .c-modal__content {
        $content-padding-horizontal-half: ();

        @if type-of($modal-content-padding-horizontal) == map {  /* [10] */

            @each $breakpoint, $value in $modal-content-padding-horizontal {  /* [11] */
                $half: null;
                @if ($value != null) {
                    $half: ($value * 0.5);
                }
                $content-padding-horizontal-half: map-merge($content-padding-horizontal-half, ($breakpoint: $half));
            }

        } @else {

            $content-padding-horizontal-half: ($modal-content-padding-horizontal * 0.5);

        }

        @include responsive-property(
            ("padding-left",  $content-padding-horizontal-half),
            ("margin-left",   $content-padding-horizontal-half),
            ("padding-right", $content-padding-horizontal-half),
            ("margin-right",  $content-padding-horizontal-half),
            ("padding-top",   $modal-content-padding-top)
        );
        max-height: $modal-max-height; /* [4] */
        overflow-y: auto; /* [4] */
        overscroll-behavior: none; /* [22] */
        text-align: $modal-content-alignment;
        color: $modal-content-color;

        > *:last-child {
            margin-bottom: $modal-content-fade-height; /* [8] */
        }

    }

    .c-modal__footer {
        @include responsive-property(
            ("padding-top",    $modal-footer-padding-top),
            ("padding-left",   $modal-footer-padding-horizontal),
            ("padding-right",  $modal-footer-padding-horizontal),
            ("padding-bottom", $modal-footer-padding-bottom)
        );
        position: relative;

        text-align: $modal-footer-alignment;

        // Element that adds a gradient at the bottom edge of the modal content
        // to suggest the user that he can scroll down further the modal content.
        &::before {
            @include responsive-property("right", $modal-content-padding-horizontal); /* [12] */

            content: "";
            position: absolute;
            top: -($modal-content-fade-height); /* [6] */
            left: 0;
            height: $modal-content-fade-height;
            background: linear-gradient(rgba($modal-background-color, 0), rgba($modal-background-color, 0.7), rgba($modal-background-color, 0.9), $modal-background-color); /* [6] */
            pointer-events: none; /* [7] */
        }
    }

    .c-modal__actions {
        $_alignment: $modal-actions-alignment;
        @if ($modal-actions-alignment == left) {
            $_alignment: flex-start;
        } @else if ($modal-actions-alignment == right) {
            $_alignment: flex-end;
        }

        display: flex;
        flex-wrap: wrap;
        justify-content: $_alignment;
        column-gap: $modal-actions-spacing-horizontal;
        row-gap: $modal-actions-spacing-vertical;
    }

        .c-modal__actions__item {
            flex-shrink: 1;
            flex-basis: $modal-actions-min-width;

            @if ($modal-actions-fill-space-breakpoint == "none") {
                flex-grow: 0;
            } @else {
                @include media-query("<=#{$modal-actions-fill-space-breakpoint}") {
                    flex-grow: 1;
                }
            }

            > * {
                width: 100%;
                height: 100%;
            }
        }
