/* ------------------------------------*\
    #Legacy Browser Notification
\*------------------------------------ */

/**
 * Display notification only in non-affected browsers.
 * Might be removed in the future if user numbers can be ignored completely
 * instead of providing custom message regarding legacy (outdated) browser.
 */

@include ie-only() {
    @include legacy-browser-notification();
}

@include edge-only() {
    @include legacy-browser-notification();
}
