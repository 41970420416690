/* ------------------------------------*\
    #FLATPICKR (Deprecated)
\*------------------------------------ */

/**
 * Selector for disabled state was changed in flatpickr 4.6
 * Fallback for < 4.6
 */

.flatpickr-day {

    &.disabled {
        color: $flatpickr-day-color-disabled;
        pointer-events: none;
    }

}
