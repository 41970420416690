/* ------------------------------------*\
    #HEADINGS
\*------------------------------------ */

/**
 * Headings 1–6's corresponding classes for double-stranded heading hierarchy:
 * https://csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
 *
 * Use these helper classes to cause heading elements to adopt the font-size of
 * another respective heading level, e.g.:
 *
 * `<h3 class="u-h1">Semantic h3, looks like h1<h3>`
 *
 */

.u-h1 {
    @include responsive-font-size(
        $font-size:   $GLOBAL-HEADING-H1-FONT-SIZE,
        $line-height: $GLOBAL-HEADING-H1-LINE-HEIGHT,
        $important:   true
    );
}

.u-h2 {
    @include responsive-font-size(
        $font-size:   $GLOBAL-HEADING-H2-FONT-SIZE,
        $line-height: $GLOBAL-HEADING-H2-LINE-HEIGHT,
        $important:   true
    );
}

.u-h3 {
    @include responsive-font-size(
        $font-size:   $GLOBAL-HEADING-H3-FONT-SIZE,
        $line-height: $GLOBAL-HEADING-H3-LINE-HEIGHT,
        $important:   true
    );
}

.u-h4 {
    @include responsive-font-size(
        $font-size:   $GLOBAL-HEADING-H4-FONT-SIZE,
        $line-height: $GLOBAL-HEADING-H4-LINE-HEIGHT,
        $important:   true
    );
}

.u-h5 {
    @include responsive-font-size(
        $font-size:   $GLOBAL-HEADING-H5-FONT-SIZE,
        $line-height: $GLOBAL-HEADING-H5-LINE-HEIGHT,
        $important:   true
    );
}

.u-h6 {
    @include responsive-font-size(
        $font-size:   $GLOBAL-HEADING-H6-FONT-SIZE,
        $line-height: $GLOBAL-HEADING-H6-LINE-HEIGHT,
        $important:   true
    );
}

.u-h1,
.u-h2,
.u-h3,
.u-h4,
.u-h5,
.u-h6 {

    // We need to force the block here as h1-h6 have the implicitly.
    display: block;

    /* stylelint-disable-next-line order/order */
    @include heading-base-styles(
        $color:          $GLOBAL-HEADING-COLOR,
        $font-family:    $GLOBAL-HEADING-FONT-FAMILY,
        $font-weight:    $GLOBAL-HEADING-FONT-WEIGHT,
        $letter-spacing: $GLOBAL-HEADING-LETTER-SPACING,
        $margin-bottom:  $GLOBAL-HEADING-MARGIN-BOTTOM,
        $heading-themes: $GLOBAL-HEADING-THEMES,
        $important:      true,
    );

}
