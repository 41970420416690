/* ------------------------------------*\
    #CONTENT-SECTION
\*------------------------------------ */

/**
 * An object wrapping the content of entire sections to ensure a consistent
 * spacing around the sections content.
 */

$content-section-spacing:        $CONTENT-SECTION-SPACING !default;
$content-section-spacing-top:    $CONTENT-SECTION-SPACING-TOP !default;
$content-section-spacing-bottom: $CONTENT-SECTION-SPACING-BOTTOM !default;

.o-content-section {
    padding-top: $content-section-spacing-top;
    padding-bottom: $content-section-spacing-bottom;
}
