/* ------------------------------------*\
   #TOGGLE
\*------------------------------------ */

$toggle-unhidden-state:         '[aria-expanded="true"]' !default;
$toggle-hidden-state:           ':not([aria-expanded="true"])' !default;

// A selector that can be used to check if the toggle is initialized.
$toggle-target-active-selector: "[aria-labelledby]" !default;
$toggle-target-hidden-state:    '[aria-hidden="true"]' !default;
$toggle-target-unhidden-state:  '[aria-hidden="false"]' !default;

/**
 * [1] Handle the hidden/unhidden state for the label.
 */

.o-toggle {

    &#{$toggle-hidden-state} .o-toggle__label--target-unhidden,
    &#{$toggle-unhidden-state} .o-toggle__label--target-hidden {
        display: none; /* [1] */
    }

}
