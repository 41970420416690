/* ------------------------------------*\
    #SPACING-ITEM
\*------------------------------------ */
// @DEPRECATED: remove in v15

.u-spacing-item-flush {
    @include spacing-item-flush();
}

.u-spacing-item-flush-all {
    @include spacing-item-flush($between-modules: true);
}
