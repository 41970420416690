// /* ------------------------------------*\
//     #BUTTONS (Settings)
// \*------------------------------------ */

$button-height-fixed:                true !default;

$button-padding-vertical:            $BUTTON-PADDING-VERTICAL !default;
$button-padding-horizontal:          $BUTTON-PADDING-HORIZONTAL !default;

$button-border-radius:               $BUTTON-BORDER-RADIUS !default;

$button-font-weight:                 $BUTTON-FONT-WEIGHT !default;
$button-letter-spacing:              $BUTTON-LETTER-SPACING !default;

$button-text-icon-spacing:           $BUTTON-TEXT-ICON-SPACING !default;

$button-icon-size:                   icon-size($BUTTON-ICON-SIZE) !default;

$button-icon-only-size:              icon-size(xxsmall) !default;
$button-icon-only-size-small:        icon-size(xxsmall) !default;
$button-icon-only-box-size:          "medium" !default;
$button-icon-only-box-size-small:    "xsmall" !default;
$button-icon-only-circle-size:       "medium" !default;
$button-icon-only-circle-size-small: "xsmall" !default;
$button-icon-only-border-width:      0 !default;

$button-link-simple:                 true !default;
$button-focus-disable-outline:       false !default;

$button-variants-brand-overwrites: (
    primary: (
        text-color-hover:  $BUTTON-FONT-COLOR,
        text-color-active: $BUTTON-FONT-COLOR,
    ),
) !default;

/* stylelint-disable length-zero-no-unit */
$button-variants: (
    primary: (
        background:                          $BUTTON-BACKGROUND-COLOR,
        background-hover:                    $BUTTON-BACKGROUND-COLOR-HOVER,
        background-active:                   $BUTTON-BACKGROUND-COLOR-ACTIVE,
        background-disabled:                 $BUTTON-BACKGROUND-COLOR-DISABLED,
        background-disabled-hover:           null,
        text-color:                          $BUTTON-FONT-COLOR,
        text-color-hover:                    null,
        text-color-disabled:                 $BUTTON-FONT-COLOR-DISABLED,
        text-color-disabled-hover:           null,
        border-width:                        0,
        border-width-disabled:               null,
        border-style:                        null,
        border-style-disabled:               null,
        border-color:                        null,
        border-color-hover:                  null,
        border-color-active:                 null,
        border-color-disabled:               null,
        border-color-disabled-hover:         null,
        link-text-color:                     $BUTTON-LINK-TEXT-COLOR,
        link-text-color-hover:               $BUTTON-LINK-TEXT-COLOR-HOVER,
        link-text-color-disabled:            $BUTTON-LINK-TEXT-COLOR-DISABLED,
        link-text-color-active:              null,
        link-icon-background-color:          null,
        link-icon-background-color-hover:    null,
        link-icon-background-color-active:   null,
        link-icon-background-color-disabled: null,
        link-icon-color:                     $BUTTON-LINK-ICON-COLOR,
        link-icon-color-hover:               $BUTTON-LINK-ICON-COLOR-HOVER,
        link-icon-color-active:              null,
        link-icon-color-disabled:            $BUTTON-LINK-TEXT-COLOR-DISABLED,
        link-simple-icon-color:              null,
        link-simple-icon-color-hover:        null,
        link-simple-icon-color-active:       null,
        link-simple-icon-color-disabled:     null,
        link-icon-border-color:              null,
        link-icon-border-color-hover:        null,
        link-icon-border-color-active:       null,
        link-icon-border-color-disabled:     null,
        icon-only-color:                     $BUTTON-FONT-COLOR,
        icon-only-color-hover:               null,
        icon-only-color-active:              null,
        icon-only-background:                $BUTTON-BACKGROUND-COLOR,
        icon-only-background-hover:          $BUTTON-BACKGROUND-COLOR-HOVER,
        icon-only-background-active:         $BUTTON-BACKGROUND-COLOR-ACTIVE,
        icon-only-border-color:              transparent,
        icon-only-border-color-hover:        null,
        icon-only-border-color-active:       null,
    ),
    secondary: (
        background:                          $BUTTON-SECONDARY-BACKGROUND-COLOR,
        background-hover:                    $BUTTON-SECONDARY-BACKGROUND-COLOR-HOVER,
        background-active:                   $BUTTON-SECONDARY-BACKGROUND-COLOR-ACTIVE,
        background-disabled:                 $BUTTON-SECONDARY-BACKGROUND-COLOR-DISABLED,
        background-disabled-hover:           null,
        box-shadow:                          null,
        box-shadow-hover:                    null,
        box-shadow-active:                   null,
        box-shadow-disabled:                 null,
        box-shadow-disabled-hover:           null,
        text-color:                          $BUTTON-SECONDARY-TEXT-COLOR,
        text-color-hover:                    $BUTTON-SECONDARY-TEXT-COLOR-HOVER,
        text-color-active:                   $BUTTON-SECONDARY-TEXT-COLOR-ACTIVE,
        text-color-disabled:                 $BUTTON-SECONDARY-TEXT-COLOR-DISABLED,
        text-color-disabled-hover:           null,
        border-width:                        null,
        border-width-disabled:               null,
        border-style:                        null,
        border-style-disabled:               null,
        border-color:                        null,
        border-color-hover:                  null,
        border-color-active:                 null,
        border-color-disabled:               null,
        border-color-disabled-hover:         null,
        link-text-color:                     null,
        link-text-color-disabled:            null,
        link-text-color-active:              null,
        link-text-color-hover:               null,
        link-icon-background-color:          null,
        link-icon-background-color-hover:    null,
        link-icon-background-color-active:   null,
        link-icon-background-color-disabled: null,
        link-icon-color:                     $BUTTON-LINK-TEXT-COLOR,
        link-icon-color-hover:               $BUTTON-LINK-TEXT-COLOR-HOVER,
        link-icon-color-active:              null,
        link-icon-color-disabled:            $BUTTON-LINK-TEXT-COLOR-DISABLED,
        link-icon-border-color:              null,
        link-icon-border-color-hover:        null,
        link-icon-border-color-active:       null,
        link-icon-border-color-disabled:     null,
        icon-only-color:                     null,
        icon-only-color-hover:               null,
        icon-only-color-active:              null,
        icon-only-background:                $BUTTON-TERTIARY-BACKGROUND-COLOR,
        icon-only-background-hover:          $BUTTON-TERTIARY-BACKGROUND-COLOR-HOVER,
        icon-only-background-active:         $BUTTON-TERTIARY-BACKGROUND-COLOR-ACTIVE,
        icon-only-border-color:              null,
        icon-only-border-color-hover:        null,
        icon-only-border-color-active:       null,
    ),
    action: (
        background:          $BUTTON-BACKGROUND-COLOR,
        background-hover:    $BUTTON-BACKGROUND-COLOR-HOVER,
        background-active:   $BUTTON-BACKGROUND-COLOR-ACTIVE,
        background-disabled: $BUTTON-BACKGROUND-COLOR-DISABLED,
        text-color-disabled: $BUTTON-FONT-COLOR-DISABLED,
        border-width:        0px,
        border-radius:       calc(var(--button-height) / 2),
        padding-horizontal:  calc(var(--button-height) / 2),
    ),
) !default;
/* stylelint-enable length-zero-no-unit */
