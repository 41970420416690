/* ------------------------------------*\
    #INFO-ICON
\*------------------------------------ */

$info-icon-color:            $COLOR-BRAND !default;
$info-icon-background-color: false !default;
$info-icon-border-color:     $COLOR-BRAND !default;
$info-icon-size:             small !default;
$info-icon-vertical-align:   middle !default;
$info-icon-variant:          "round" !default;
$info-icon-padding:          0 !default;
$info-icon-spacing:          null !default;

// Additional styles for brands that need a CSS border
$info-icon-has-no-border:    false !default;
$info-icon-border-style:     solid !default;
$info-icon-border-width:     1px !default;
$info-icon-padding-after:    50% !default;
$info-icon-radius:           $ICON-ROUND-RADIUS !default;
$info-icon-position:         absolute !default;
$info-icon-transform:        translate(-50%, -50%) !default;

$info-icon-themes: (
    "alternative": (
        "icon-color":            $COLOR-BRAND,
        "icon-background-color": false,
        "icon-border-color":     null
    )
) !default;

/**
 * [1] Vertically align icon, if needed.
 * [2] Reduce the height of Info Icon by removing the padding
 *     inherited from the `icon-variant` mixin.
 * [3] Ensure other components are not affected by the Info Icon.
 * [4] Horizontal spacing.
 * [5] Additional styles for brands that need a CSS border.
 * [6] Fix clipping by slightly offsetting (Safari).
 */
.c-info-icon {
    @if ($info-icon-variant) {
        @include icon-variant(
            $variant:          $info-icon-variant,
            $size:             $info-icon-size,
            $text-color:       $info-icon-color,
            $background-color: $info-icon-background-color,
            $border-color:     $info-icon-border-color,
            $pseudo-element:   after,
        );

        @include vary($loop: $info-icon-themes) {
            @include icon-variant(
                $variant:          $info-icon-variant,
                $size:             $info-icon-size,
                $text-color:       vary-get("icon-color"),
                $background-color: vary-get("icon-background-color"),
                $border-color:     vary-get("icon-border-color"),
                $pseudo-element:   after,
            );
        }
    } @else {
        color: $info-icon-color;
    }

    position: relative; /* [3] */
    margin-top: -100px; /* [3] */ /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    margin-bottom: -100px; /* [3] */ /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    margin-left: $info-icon-spacing; /* [4] */
    padding: 0.03em; /* [6] */ /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    line-height: 1; /* [3] */
    vertical-align: $info-icon-vertical-align; /* [1] */

    &::before {
        padding: $info-icon-padding; /* [2] */
        line-height: 1;
    }

    @if ($info-icon-has-no-border == true) { /* [5] */
        line-height: 0; /* [3] */

        &::after {
            content: "";
            position: $info-icon-position;
            top: $info-icon-radius;
            left: $info-icon-radius;
            padding: $info-icon-padding-after;
            transform: $info-icon-transform;
            border: $info-icon-border-width $info-icon-border-style $info-icon-border-color;
            border-radius: $info-icon-radius;
        }
    }

}
