/* ------------------------------------*\
    #CLEARFIX
\*------------------------------------ */

/**
 * Clearfix utility class.
 */

.u-clearfix {
    @include clearfix();
}
