/* ------------------------------------*\
    #FAV-BUTTON (Deprecated)
\*------------------------------------ */

/**
 * Fallback for icon in markup via SVG
 * Replaced icon via iconfont
 */

.c-fav-button__svg {
    display: none;
}
