/* ------------------------------------*\
    #LIST-BARE
\*------------------------------------ */

/**
 * The list-bare object simply removes any indents and bullet points from lists.
 *
 * If you don't want to use the class, there is also a mixin available.
 * (`02-tools/_tools.list-bare.scss`)
 */

.o-list-bare {
    @include list-bare();
}
