/* ------------------------------------*\
    #WIZARD
\*------------------------------------ */

/// List of breakpoints at which the component collapses.
/// Keep in sync with the variants in `wizard.config.js` and component notes.
/// @type List
$wizard-collapse-breakpoints: (
    s,
    m,
    l
) !default;
$wizard-page-wrap:                               true !default;
$wizard-height:                                  60px !default;
$wizard-spacing-top:                             null !default;
$wizard-spacing-bottom:                          $GLOBAL-SPACING-UNIT !default;
$wizard-background-color:                        $COLOR-PALE !default;
$wizard-padding-vertical-at-breakpoint:          null !default;

$wizard-item-padding-horizontal:                 $GLOBAL-SPACING-UNIT-SMALL !default;
$wizard-item-padding-vertical:                   $GLOBAL-SPACING-UNIT-XSMALL !default;
$wizard-item-padding-vertical-at-breakpoint:     $GLOBAL-SPACING-UNIT-XSMALL !default;
$wizard-item-font-size:                          $FS0 !default;
$wizard-item-font-weight:                        normal !default;
$wizard-item-background-color:                   $wizard-background-color !default;
$wizard-item-background-color-disabled:          $wizard-background-color !default;
$wizard-item-background-color-done:              $COLOR-SUBTLE !default;
$wizard-item-background-color-active:            $COLOR-WHITE !default;
$wizard-item-text-color:                         $COLOR-BASE-TEXT !default;
$wizard-item-text-color-disabled:                $COLOR-INACTIVE !default;
$wizard-item-text-color-done:                    false !default;
$wizard-item-text-color-done-hover:              $COLOR-WHITE !default;
$wizard-item-text-color-active:                  false !default;
$wizard-item-text-color-active-hover:            $COLOR-HIGHLIGHT !default;
$wizard-item-text-color-error:                   $COLOR-ALERT !default;
$wizard-item-text-color-error-hover:             null !default;
$wizard-item-text-alignment:                     left !default;
$wizard-item-alignment-horizontal:               center !default;
$wizard-item-alignment-vertical:                 center !default;

$wizard-indicators:                              true !default;
$wizard-indicator-is-icon:                       true !default;
// Can be `row` or `column`.
$wizard-indicator-orientation:                   row !default;
$wizard-indicator-spacing:                       $GLOBAL-SPACING-UNIT-XSMALL !default;
$wizard-indicator-padding:                       null !default;
$wizard-indicator-border-width:                  1px !default;
$wizard-indicator-border-style:                  solid !default;
$wizard-indicator-border-color:                  null !default;
$wizard-indicator-border-color-disabled:         null !default;
$wizard-indicator-border-color-done:             null !default;
$wizard-indicator-border-color-done-hover:       null !default;
$wizard-indicator-border-color-active:           null !default;
$wizard-indicator-border-color-active-hover:     null !default;
$wizard-indicator-border-color-error:            null !default;
$wizard-indicator-border-radius:                 50% !default;
$wizard-indicator-background-color:              null !default;
$wizard-indicator-background-color-disabled:     null !default;
$wizard-indicator-background-color-done:         null !default;
$wizard-indicator-background-color-done-hover:   null !default;
$wizard-indicator-background-color-active:       null !default;
$wizard-indicator-background-color-active-hover: null !default;
$wizard-indicator-background-color-error:        null !default;
$wizard-indicator-font-size:                     $FS0 !default;
$wizard-indicator-font-weight:                   normal !default;
$wizard-indicator-text-color:                    $COLOR-BASE-TEXT !default;
$wizard-indicator-text-color-disabled:           $wizard-item-text-color-disabled !default;
$wizard-indicator-text-color-done:               $wizard-item-text-color-done !default;
$wizard-indicator-text-color-done-hover:         $wizard-item-text-color-done-hover !default;
$wizard-indicator-text-color-active:             $wizard-item-text-color-active !default;
$wizard-indicator-text-color-active-hover:       $wizard-item-text-color-active-hover !default;
$wizard-indicator-text-color-error:              $wizard-item-text-color-error !default;
$wizard-indicator-icon:                          get-icon(semantic-forward) !default;
$wizard-indicator-icon-has-variant:              true !default;
$wizard-indicator-icon-disabled:                 get-icon(semantic-pause) !default;
$wizard-indicator-icon-done:                     get-icon(semantic-success) !default;
$wizard-indicator-icon-active:                   get-icon(semantic-info) !default;
$wizard-indicator-icon-error:                    get-icon(semantic-error) !default;
$wizard-indicator-icon-size:                     small !default;

// Set default indicator size same as round icon variant.
$wizard-indicator-size:                          map-get($ICON-ROUND-FULL-SIZES, $wizard-indicator-icon-size) !default;

$wizard-stroke-arrows:                           true !default;
$wizard-stroke-arrow-stroke-width:               1px !default;
$wizard-stroke-arrow-height:                     24px !default;
$wizard-stroke-arrow-color:                      $COLOR-BASE !default;
$wizard-stroke-arrow-angle:                      25deg !default;

$wizard-block-arrows:                            true !default;
$wizard-block-arrow-angle:                       15deg !default;

$wizard-trigger-height:                          $wizard-height !default;
$wizard-trigger-remove-focus:                    true !default;
$wizard-trigger-revert:                          false !default; // Optionally revert order of trigger items.
$wizard-trigger-text-color:                      $wizard-item-text-color !default;
$wizard-trigger-text-color-hover:                $wizard-item-text-color-active-hover !default;
$wizard-trigger-background-color:                $COLOR-PALE !default;
$wizard-trigger-spacing-vertical:                $GLOBAL-SPACING-UNIT-XSMALL !default;
$wizard-trigger-icon-expand:                     get-icon(semantic-expand) !default;
$wizard-trigger-icon-collapse:                   get-icon(semantic-collapse) !default;
$wizard-trigger-icon-size:                       $wizard-indicator-icon-size !default;
$wizard-trigger-icon-spacing:                    $wizard-indicator-spacing !default;
$wizard-trigger-icon-color:                      inherit !default;
$wizard-trigger-icon-background:                 transparent !default;
$wizard-trigger-icon-border-color:               transparent !default;
$wizard-trigger-counter-spacing:                 $wizard-indicator-spacing !default;
$wizard-trigger-closed-state-selector:           "[aria-expanded='false']" !default;

$wizard-themes: (
    "alternative": (
        "wizard-background-color": null
    )
) !default;

/**
 * [1] Make sure all items are the same width.
 * [2] If the indicator is positioned above the item text, make sure that the
 *     items are aligned to each other at the top.
 * [3] Force collapsing of items on small screen and align it to the left.
 * [4] Create little stroke arrow between items. The arrows are always placed
 *     in front of the items.
 * [5] Upper half of the stroke arrow.
 * [6] Bottom half of the stroke arrow.
 * [7] Add block arrow to items with certain states (disabled, active) by using
 *     two skewed pseudo elements as background.
 * [8] Fix block arrow items not being wide enough for first and last item and
 *     cut them off when they overflow the container.
 * [9] Change background and text colors for the specific states.
 * [10] Change link text colors for the specific states upon hover/focus.
 * [11] The default items (i.e. the future items) are not clickable.
 * [12] The done and active items have to be made clickable again (see [11]).
 * [13] On small screen, the indicator is always aligned in row with the text
 *      and left aligned.
 * [14] Position content above arrows and overlap arrows for states with
 *      background color.
 * [15] Add relative positioning to have a reference for pseudo elements that
 *      need e.g. absolute positioning (used for arrows and such).
 * [16] Add background colors to all states for small screen list.
 * [17] Stretch wizard link to full height to center its content vertically.
 * [18] Use icon as link indicator if the indicator should not be numbered.
 * [19] Add regular font styles if link indicator should not be numbered instead
 *      of being an icon.
 * [20] Change indicator styling for the specific states.
 * [21] Change indicator styling for the specific states upon hover/focus.
 * [22] Optionally revert order of trigger icon, counter and text.
 * [23] Prevent interaction of the element(s), when modifier is given
 *      except on the .c-wizard__trigger on mobile.
 * [24] Set horizontal link content alignment.
 * [25] Set vertical link content alignment, but always reset to `center` for
 *      collapsed layout in small viewports.
 * [26] If `$wizard-page-wrap: true` limit the wizard width to the page wrap
 *      width.
 * [27] Resize the wizard to the full page wrap width.
 * [28] If an icon is used as indicator but it is set to `none`, still use an empty
 *      `content` attribute to show the pseudo element as an empty indicator.
 */

/// Wizard Collapse Mixin
///
/// Content mixin to alter styles for collapsed state
/// based on media-queries and hooks via data-attributs.
///
/// @param {Boolean} $root [false] Target either the root element or elements inside.
/// @requires {mixin} media-query
/// @requires {variable} $wizard-collapse-breakpoints
/// @access private
@mixin wizard-collapsed($root: false) {
    @each $breakpoint in $wizard-collapse-breakpoints {
        @if ($root) {
            [data-wizard-collapse="#{$breakpoint}"] {
                @include media-query("<=#{$breakpoint}") {
                    @content;
                }
            }
        } @else {
            [data-wizard-collapse="#{$breakpoint}"] & {
                @include media-query("<=#{$breakpoint}") {
                    @content;
                }
            }
        }
    }
}

.c-wizard {
    margin-top: $wizard-spacing-top;
    margin-bottom: $wizard-spacing-bottom;

    @if ($wizard-background-color) {
        background-color: $wizard-background-color;
    }

}

    .c-wizard__list {
        @include list-bare();
        display: flex;
        justify-content: space-between;
        @if ($wizard-indicator-orientation == column) {
            align-items: flex-start; /* [2] */
        } @else {
            align-items: stretch;
        }
        @if ($wizard-page-wrap) { /* [26] */
            @include page-wrap(); /* [26] */
            width: 100%; /* [27] */
        }
        @if ($wizard-block-arrows) {
            overflow: hidden; /* [8] */
        }

        @include vary($loop: $wizard-themes) {
            background-color: vary-get("wizard-background-color");
        }

        @include wizard-collapsed() {
            flex-direction: column; /* [3] */
            align-items: flex-start; /* [3] */
        }
    }

    @include wizard-collapsed($root: true) {
        &.js-is-ready {

            .c-wizard__list.is-closed {
                display: none;
            }
        }
    }

    .c-wizard__item {
        position: relative; /* [15] */
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0; /* [1] */
        padding-top: $wizard-item-padding-vertical;
        padding-right: $wizard-item-padding-horizontal;
        padding-bottom: $wizard-item-padding-vertical;
        padding-left: $wizard-item-padding-horizontal;

        @if ($wizard-height != auto) {
            min-height: $wizard-height;
        }

        @include wizard-collapsed() {
            flex-basis: auto;
            width: 100%;
            min-height: auto;
            padding-top: $wizard-padding-vertical-at-breakpoint;
            padding-right: 0;
            padding-bottom: $wizard-padding-vertical-at-breakpoint;
            padding-left: 0;
            background-color: if($wizard-item-background-color, $wizard-item-background-color, null); /* [16] */

            &.is-disabled {
                background-color: if($wizard-item-background-color-disabled, $wizard-item-background-color-disabled, null); /* [16] */
            }

            &.is-done {
                background-color: if($wizard-item-background-color-done, $wizard-item-background-color-done, null); /* [16] */
            }

            &.is-active {
                background-color: if($wizard-item-background-color-active, $wizard-item-background-color-active, null); /* [16] */
            }
        }

        // Add Stroke arrows
        @if ($wizard-stroke-arrows) {

            &:not(:first-child) {

                /* [4] */
                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    z-index: 1; /* [14] */
                    right: 100%;
                    display: block;
                    width: $wizard-stroke-arrow-stroke-width;
                    height: ($wizard-stroke-arrow-height * 0.5);
                    background-color: $wizard-stroke-arrow-color;

                    @include wizard-collapsed() {
                        display: none;
                    }

                }

                /* [5] */
                &::before {
                    top: auto;
                    bottom: 50%;
                    transform: skew($wizard-stroke-arrow-angle, 0deg);
                }

                /* [6] */
                &::after {
                    top: 50%;
                    transform: skew(-$wizard-stroke-arrow-angle, 0deg);
                }

            }

        }

        // Add Block arrows
        @if ($wizard-block-arrows) {

            &.is-highlight,
            &.is-active {

                /* [7] */
                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    z-index: 2; /* [14] */
                    right: 0;
                    display: block;
                    width: 100%;
                    height: 50%;
                    background-color: if($wizard-item-background-color, $wizard-item-background-color, null);

                    @include wizard-collapsed() {
                        display: none;
                    }

                }

                &::before {
                    top: 0;
                    transform: skew($wizard-block-arrow-angle, 0deg); /* [7] */
                }

                &::after {
                    top: auto;
                    bottom: 0;
                    transform: skew(-$wizard-block-arrow-angle, 0deg); /* [7] */
                }

            }

            &.is-active {

                &::before,
                &::after {
                    background-color: if($wizard-item-background-color-active, $wizard-item-background-color-active, null); /* [9] */
                }

            }

            &:first-child {

                &.is-active {

                    &::before,
                    &::after {
                        width: 125%; /* [8] */
                    }

                }

            }

            &:last-child {

                &.is-active {

                    &::before,
                    &::after {
                        right: auto;
                        left: 0;
                        width: 125%; /* [8] */
                    }

                }

            }

        }

    }

    .c-wizard__link {
        @include transition();
        position: relative; /* [11] */
        z-index: 3; /* [14] */
        display: flex;
        flex-direction: $wizard-indicator-orientation;
        align-items: $wizard-item-alignment-vertical; /* [25] */
        justify-content: $wizard-item-alignment-horizontal; /* [24] */
        height: 100%; /* [17] */
        text-decoration: none;
        text-align: $wizard-item-text-alignment;
        color: $wizard-item-text-color;
        cursor: default; /* [11] */
        pointer-events: none; /* [11] */

        @if ($wizard-item-font-weight != normal) {
            font-weight: $wizard-item-font-weight;
        }

        @include wizard-collapsed() {
            flex-direction: row; /* [13] */
            align-items: center; /* [25] */
            justify-content: flex-start; /* [13] */
            padding-top: $wizard-item-padding-vertical-at-breakpoint;
            padding-bottom: $wizard-item-padding-vertical-at-breakpoint;
            text-align: left;
        }

        .c-wizard__item.is-disabled & {
            @if ($wizard-item-text-color-disabled) {
                color: $wizard-item-text-color-disabled; /* [9] */
            }
        }

        .c-wizard__item.is-done &,
        .c-wizard__item.is-active &,
        .c-wizard__item.is-error & {
            cursor: pointer; /* [12] */
            pointer-events: auto; /* [12] */
        }

        .c-wizard__item.has-no-interaction & {
            pointer-events: none; /* [23] */
        }

        .c-wizard__item.is-done & {
            @if ($wizard-item-text-color-done) {
                color: $wizard-item-text-color-done; /* [9] */
            }
            @if ($wizard-item-text-color-done-hover) {
                &:hover,
                &:focus {
                    color: $wizard-item-text-color-done-hover; /* [10] */
                }
            }
            @if ($wizard-indicators) {
                &:hover,
                &:focus {
                    /* stylelint-disable-next-line selector-max-specificity */
                    &::before {
                        @if ($wizard-indicator-text-color-done-hover) {
                            color: $wizard-indicator-text-color-done-hover; /* [21] */
                        }
                        @if ($wizard-indicator-border-color-done-hover) {
                            border: $wizard-indicator-border-width $wizard-indicator-border-style $wizard-indicator-border-color-done-hover; /* [21] */
                        }
                        @if ($wizard-indicator-background-color-done-hover) {
                            background-color: $wizard-indicator-background-color-done-hover; /* [21] */
                        }
                    }

                }
            }
        }

        .c-wizard__item.is-active & {
            @if ($wizard-item-text-color-active) {
                color: $wizard-item-text-color-active; /* [9] */
            }
            @if ($wizard-item-text-color-active-hover) {
                &:hover,
                &:focus {
                    color: $wizard-item-text-color-active-hover; /* [10] */
                }
            }
            @if ($wizard-indicators) {
                &:hover,
                &:focus {
                    /* stylelint-disable-next-line selector-max-specificity */
                    &::before {
                        @if ($wizard-indicator-text-color-active-hover) {
                            color: $wizard-indicator-text-color-active-hover; /* [21] */
                        }
                        @if ($wizard-indicator-border-color-active-hover) {
                            border: $wizard-indicator-border-width $wizard-indicator-border-style $wizard-indicator-border-color-active-hover; /* [21] */
                        }
                        @if ($wizard-indicator-background-color-active-hover) {
                            background-color: $wizard-indicator-background-color-active-hover; /* [21] */
                        }
                    }

                }
            }
        }

        .c-wizard__item.is-error & {
            @if ($wizard-item-text-color-error) {
                color: $wizard-item-text-color-error; /* [9] */
            }

            @if ($wizard-item-text-color-error-hover) {
                &:hover,
                &:focus {
                    color: $wizard-item-text-color-error-hover; /* [10] */
                }

            }
        }

        // Add Indicator icon in front of link
        @if ($wizard-indicators) {

            @if ($wizard-indicator-icon-has-variant) {
                @include icon-variant( /* [18] */
                    $variant:          "round",
                    $size:             $wizard-indicator-icon-size,
                    $background-color: $wizard-indicator-background-color,
                    $border-color:     $wizard-indicator-border-color
                );
            } @else {
                font-size: $wizard-indicator-icon-size;
            }

            &::before {
                @include transition();
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: $wizard-indicator-size;
                width: $wizard-indicator-size;
                height: $wizard-indicator-size;

                @if ($wizard-indicator-orientation == column) {
                    margin-bottom: $wizard-indicator-spacing;
                }
                @if ($wizard-indicator-orientation == row) {
                    margin-right: $wizard-indicator-spacing;
                }
                @if ($wizard-indicator-text-color) {
                    color: $wizard-indicator-text-color;
                }
                @if ($wizard-indicator-is-icon) {
                    @include icon-font(); /* [18] */
                    @if ($wizard-indicator-icon != none) {
                        content: $wizard-indicator-icon; /* [18] */
                    } @else {
                        content: ""; /* [28] */
                    }
                }
                @if (not $wizard-indicator-is-icon) {
                    @include responsive-font-size(
                        $font-size: $wizard-indicator-font-size,
                        $line-height: 1
                    ); /* [19] */
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: $wizard-indicator-padding;
                    border: $wizard-indicator-border-width $wizard-indicator-border-style $wizard-indicator-border-color;
                    border-radius: $wizard-indicator-border-radius;
                    background-color: $wizard-indicator-background-color;

                    @if ($wizard-indicator-font-weight != normal) {
                        font-weight: $wizard-indicator-font-weight; /* [19] */
                    }
                }

                @include wizard-collapsed() {
                    margin-right: $wizard-indicator-spacing;
                    margin-bottom: 0;
                }

                .c-wizard__item.is-disabled & {
                    @if ($wizard-indicator-is-icon) {
                        @if ($wizard-indicator-icon-disabled != none) {
                            content: $wizard-indicator-icon-disabled; /* [20] */
                        }
                    }
                    @if ($wizard-indicator-border-color-disabled) {
                        border-color: $wizard-indicator-border-color-disabled; /* [20] */
                    }
                    @if ($wizard-indicator-background-color-disabled) {
                        background-color: $wizard-indicator-background-color-disabled; /* [20] */
                    }
                    @if ($wizard-indicator-text-color-disabled) {
                        color: $wizard-indicator-text-color-disabled; /* [20] */
                    }
                }

                .c-wizard__item.is-done & {
                    @if ($wizard-indicator-is-icon) {
                        @if ($wizard-indicator-icon-done != none) {
                            content: $wizard-indicator-icon-done; /* [20] */
                        }
                    }
                    @if ($wizard-indicator-border-color-done) {
                        border-color: $wizard-indicator-border-color-done; /* [20] */
                    }
                    @if ($wizard-indicator-background-color-done) {
                        background-color: $wizard-indicator-background-color-done; /* [20] */
                    }
                    @if ($wizard-indicator-text-color-done) {
                        color: $wizard-indicator-text-color-done; /* [20] */
                    }
                }

                .c-wizard__item.is-active & {
                    @if ($wizard-indicator-is-icon) {
                        @if ($wizard-indicator-icon-active != none) {
                            content: $wizard-indicator-icon-active; /* [20] */
                        }
                    }
                    @if ($wizard-indicator-border-color-active) {
                        border-color: $wizard-indicator-border-color-active; /* [20] */
                    }
                    @if ($wizard-indicator-background-color-active) {
                        background-color: $wizard-indicator-background-color-active; /* [20] */
                    }
                    @if ($wizard-indicator-text-color-active) {
                        color: $wizard-indicator-text-color-active; /* [20] */
                    }
                }

                .c-wizard__item.is-error & {
                    @if ($wizard-indicator-is-icon) {
                        @if ($wizard-indicator-icon-error != none) {
                            content: $wizard-indicator-icon-error; /* [20] */
                        }
                    }
                    @if ($wizard-indicator-border-color-error) {
                        border-color: $wizard-indicator-border-color-error; /* [20] */
                    }
                    @if ($wizard-indicator-background-color-error) {
                        background-color: $wizard-indicator-background-color-error; /* [20] */
                    }
                    @if ($wizard-indicator-text-color-error) {
                        color: $wizard-indicator-text-color-error; /* [20] */
                    }
                }

            }

        }

    }

    .c-wizard__link-title {
        @include responsive-font-size(
            $font-size: $wizard-item-font-size,
            $line-height: 1
        );
    }

    .c-wizard__trigger {
        display: none;

        @include wizard-collapsed() {
            @include reset-button(
                $remove-focus: $wizard-trigger-remove-focus,
            );
            display: flex;
            align-items: center;
            @if ($wizard-trigger-revert) {
                flex-direction: row-reverse; /* [22] */
            }
            @if ($wizard-page-wrap) { /* [26] */
                @include page-wrap(); /* [26] */
            }
            width: 100%;
            @if ($wizard-trigger-height != auto) {
                min-height: $wizard-trigger-height;
            }
            padding-top: $wizard-trigger-spacing-vertical;
            padding-bottom: $wizard-trigger-spacing-vertical;
            color: $wizard-trigger-text-color;
            @if ($wizard-trigger-background-color) {
                background-color: $wizard-trigger-background-color;
            }

            &:hover,
            &:focus {
                color: $wizard-trigger-text-color-hover;
            }
        }

    }

    .c-wizard__trigger-icon {
        @include icon-variant(
            $variant:          "round",
            $size:             $wizard-trigger-icon-size,
            $text-color:       $wizard-trigger-icon-color,
            $background-color: $wizard-trigger-icon-background,
            $border-color:     $wizard-trigger-icon-border-color
        );
        @if ($wizard-trigger-revert) {
            margin-right: 0; /* [22] */
            margin-left: $wizard-trigger-icon-spacing; /* [22] */
        } @else {
            margin-right: $wizard-trigger-icon-spacing;
        }

        &::before {
            @include icon-font();
            content: $wizard-trigger-icon-collapse;
        }

        .c-wizard__trigger#{$wizard-trigger-closed-state-selector} & {

            &::before {
                content: $wizard-trigger-icon-expand;
            }

        }

    }

    .c-wizard__trigger-text {
        @include responsive-font-size(
            $font-size: $wizard-item-font-size,
            $line-height: 1
        );
        @if ($wizard-item-font-weight != normal) {
            font-weight: $wizard-item-font-weight;
        }
        @if ($wizard-trigger-revert) {
            margin-right: auto; /* [22] */
        }
        text-align: left;
    }

    .c-wizard__trigger-counter {
        @include responsive-font-size(
            $font-size: $wizard-item-font-size,
            $line-height: 1
        );
        @if ($wizard-item-font-weight != normal) {
            font-weight: $wizard-item-font-weight;
        }
        @if ($wizard-trigger-revert) {
            margin-right: $wizard-trigger-counter-spacing; /* [22] */
        } @else {
            margin-left: $wizard-trigger-counter-spacing;
        }
    }

.c-wizard--no-interaction .c-wizard__list * {
    pointer-events: none !important; /* stylelint-disable-line itcss/no-at-important */ /* [23] */
}
