/* ------------------------------------*\
    #UPLOAD-CONTAINER
\*------------------------------------ */

$upload-container-field-spacing-bottom:  $GLOBAL-SPACING-UNIT !default;
$upload-container-item-spacing-vertical: $GLOBAL-SPACING-UNIT !default;

/**
 * [1] Reset default list styling.
 * [2] Reset default `<li>` styling.
 */
.c-upload-container {
}

    .c-upload-container__field {
        margin-bottom: $upload-container-field-spacing-bottom;
    }

    .c-upload-container__itemlist {
        @include list-bare(); /* [1] */
    }

    .c-upload-container__item {
        margin-bottom: 0; /* [2] */

        &:not(:last-child) {
            margin-bottom: $upload-container-item-spacing-vertical;
        }

    }
