/* ------------------------------------*\
    #TABS
\*------------------------------------ */

$skoda-tabs-nav-item-spacing:                 $GLOBAL-SPACING-UNIT-XLARGE !default;
$skoda-tabs-nav-item-spacing-small:           $GLOBAL-SPACING-UNIT !default;
$skoda-tabs-nav-item-focus-outline-width:     $GLOBAL-FOCUS-WIDTH !default;
$skoda-tabs-nav-item-focus-outline-color:     $COLOR-BASE !default;
$skoda-tabs-nav-item-focus-outline-offset:    $GLOBAL-SPACING-UNIT-XSMALL !default;
$skoda-tabs-icon-stacked-text-bottom-spacing: $GLOBAL-SPACING-UNIT-XXSMALL !default;

/**

 * [1] Reset default background settings.
 * [2] Reset default flickity background settings.
 * [3] Reset default outline settings.
 * [4] Reduce spacing on smaller screens.
 * [5] Hide bottom border for non-active tabs.
 * [6] Hide browser focus outline for browsers that also support `:focus-visible`.
 * [7] Add outline as per brand guideline, for browsers that support `:focus-visible`.
 */
.c-tabs {

}

    .c-tabs__nav {

        .flickity-prev-next-button {

            &.previous {
                background: transparent; /* [1] */

                &::before {
                    background: linear-gradient(to left, rgba($tabs-slider-background-color, 0) 0%, rgba($tabs-slider-background-color, 1) 30%); /* [1] */
                }

            }

            &.next {
                background: transparent; /* [1] */

                &::before {
                    background: linear-gradient(to right, rgba($tabs-slider-background-color, 0) 0%, rgba($tabs-slider-background-color, 1) 30%); /* [1] */
                }

            }

            &:focus,
            &:focus-visible {
                outline: 0; /* [6] */
                background-color: important(transparent, true); /* [2] */

                &::before {
                    @include focus-styles($outline-color: $skoda-tabs-nav-item-focus-outline-color, $outline-width: $tabs-nav-border-width, $outline-offset: -$tabs-nav-border-width); /* [3] */
                }

            }

        }

    }

    .c-tabs__nav-item {
        margin-right: $skoda-tabs-nav-item-spacing;

        @include media-query("<=s") {
            margin-right: $skoda-tabs-nav-item-spacing-small; /* [4] */
        }

    }

    .c-tabs__nav--icons-stacked {

        .c-tabs__nav-link {

            .c-tabs__text {
                margin-bottom: $skoda-tabs-icon-stacked-text-bottom-spacing;
            }

        }

    }

    .c-tabs__nav-link {

        &:not(.is-active) {

            &::before {
                display: none; /* [5] */
            }

        }

        &:focus-visible {

            &::after { /* [7] */
                content: "";
                position: absolute;
                top: $skoda-tabs-nav-item-focus-outline-offset;
                right: $skoda-tabs-nav-item-focus-outline-offset;
                bottom: $skoda-tabs-nav-item-focus-outline-offset;
                left: $skoda-tabs-nav-item-focus-outline-offset;
                outline: $skoda-tabs-nav-item-focus-outline-width solid $skoda-tabs-nav-item-focus-outline-color;
            }

        }

    }
