// /* ------------------------------------*\
//     #TOGGLE
// \*------------------------------------ */

$skoda-toggle-focus-offset: 2px !default;

.c-toggle {

}

    .c-toggle__input {

        &:focus-visible ~ ::before {
            @include focus-styles(
                $outline-offset: $skoda-toggle-focus-offset,
            );
        }

    }
