/* ------------------------------------*\
   #TOAST-NOTIFICATION
\*------------------------------------ */

$toast-notification-breakpoint:               s !default;
$toast-notification-max-width:                300px !default;
$toast-notification-offset:                   $GLOBAL-SPACING-UNIT !default;
$toast-notification-spacing:                  $GLOBAL-SPACING-UNIT-XSMALL !default;
$toast-notification-background:               $COLOR-PALE !default;
$toast-notification-border:                   null !default;
$toast-notification-text-color:               $COLOR-BLACK !default;
$toast-notification-text-size:                map-get($FS1, null) !default;
$toast-notification-text-font-weight:         null !default;
$toast-notification-text-line-height:         $GLOBAL-LINE-HEIGHT !default;
$toast-notification-padding-vertical:         $GLOBAL-SPACING-UNIT !default;
$toast-notification-padding-horizontal:       $GLOBAL-SPACING-UNIT !default;
$toast-notification-z-index:                  z("toast-notification") !default;
$toast-notification-close-icon:               get-icon(semantic-close) !default;
$toast-notification-close-icon-size:          icon-size(small) !default;
$toast-notification-close-icon-spacing:       $GLOBAL-SPACING-UNIT-XSMALL !default;
$toast-notification-close-icon-color:         currentColor !default;
$toast-notification-close-animation-duration: ($GLOBAL-TRANSITION-DURATION * 2) !default;

$toast-notification-themes:                   $STATUS-THEMES !default;

// Merge default and overwrite settings to avoid duplications
// and overwrite only necessary settings.
$toast-notification-themes-overwrites:    () !default;
$toast-notification-status-themes-merged: map-deep-merge($toast-notification-themes, $toast-notification-themes-overwrites, 2) !default;

// Style-hook: `[data-marketing-theme]`
$toast-notification-marketing-themes-overwrites: () !default;
$toast-notification-marketing-themes:            map-deep-merge($MARKETING-THEMES, $toast-notification-marketing-themes-overwrites, 2) !default;

/**
 * [1] Restrict the height of the container to the viewport height, so it is
 *     scrollable with many items in it.
 * [2] Provide offset spacing for small screens, when the notifications would
 *     otherwise touch the left viewport edge.
 * [3] Offset the notifications to the right with `padding-right` instead of
 *     the `right` property, so the slide-right animation looks authentic.
 * [4] In case of many notifications, ensure scrollability,...
 * [5] ...but prevent on the x-axis.
 * [6] Delay the vertical animation, so the horizontal animation runs first.
 * [7] If there are any elements in notifications that bring spacing with it,
 *     prevent it from screwing up the padding.
 * [8] Shrink size to zero.
 * [9] Also reset margin-bottom as this contributes to the compound height.
 * [10] Reference for the close button.
 * [11] Push the notification out of screen when closed.
 * [12] Preserve space for the close button.
 * [13] Delay the horizontal animation when an item should be added, so the
 *      vertical animation runs first and gives up its space for the new item.
 * [14] Reset the transition-delay for the reversed animation, i.e. the adding
 *      of new notification items.
 * [15] Prevents the notifications to take up any space when it’s closed and
 *      takes care of eliminating any potential scroll bars.
 * [16] Provide a default text color.
 * [17] Make sure the icon color is the same as the text color, hence prevent
 *      any override from the `$ICON-COLOR` settings.
 * [18] Disable transition during initializations.
 */
.c-toast-notifications {
    position: fixed;
    z-index: $toast-notification-z-index;
    top: $toast-notification-offset;
    right: 0; /* [3] */
    max-height: 100vh; /* [1] */
    overflow-x: hidden; /* [5] */
    overflow-y: auto; /* [4] */
    margin-left: $toast-notification-offset; /* [2] */
    padding-right: $toast-notification-offset; /* [3] */
}

.c-toast-notification {
    @include transition($transition-duration: $toast-notification-close-animation-duration);
    position: relative;
    overflow: hidden; /* [15] */
    transition-delay: 0s; /* [14] */

    @include media-query(">#{$toast-notification-breakpoint}") {
        max-width: $toast-notification-max-width;
    }

    &:not(:last-child) {
        margin-bottom: $toast-notification-spacing;
    }

    *:last-child {
        margin-bottom: 0; /* [7] */
    }

    &.is-closed {
        height: 0; /* [8] */
        margin-bottom: 0; /* [9] */
        transition-delay: $toast-notification-close-animation-duration; /* [6] */
        visibility: hidden;
    }

}

    .c-toast-notification__content {
        @include transition($transition-duration: $toast-notification-close-animation-duration);
        position: relative; /* [10] */
        padding: $toast-notification-padding-vertical $toast-notification-padding-horizontal;
        border: $toast-notification-border;
        font-weight: $toast-notification-text-font-weight;
        background-color: $toast-notification-background;
        color: $toast-notification-text-color; /* [16] */
        transition-delay: $toast-notification-close-animation-duration; /* [13] */

        // Add status modifier classes.
        @include vary($create: custom, $loop: $toast-notification-status-themes-merged) {

            .c-toast-notification--#{$vary-loop-entity} & {
                background-color: vary-get("background-color");
                color: vary-get("text-color");
            }

        }

        @include vary($create: custom, $loop: $toast-notification-marketing-themes) {
            .c-toast-notification[data-marketing-theme="#{$vary-loop-entity}"] & {
                border-color: vary-get("border-color");
                background-color: vary-get("background-color");
                color: vary-get("text-color");
            }
        }

        .c-toast-notification.is-closed & {
            transform: translateX(200%); /* [11] */
            transition-delay: 0s; /* [14] */
        }

    }

    .c-toast-notification__body {
        @include font-size(
            $font-size: $toast-notification-text-size,
            $line-height: $toast-notification-text-line-height
        );
        padding-right: ($toast-notification-close-icon-spacing + $toast-notification-close-icon-size); /* [12] */
    }

    .c-toast-notification__close {
        @include reset-button();
        position: absolute;
        top: $toast-notification-padding-vertical;
        right: $toast-notification-padding-horizontal;
        color: $toast-notification-close-icon-color; /* [17] */

        &::before {
            @include icon-font();
            content: $toast-notification-close-icon;
            font-size: $toast-notification-close-icon-size;
        }

    }

.c-toast-notification:not(.js-is-ready) {

    &,
    .c-toast-notification__content {
        transition: important(none, true); /* [18] */
    }

}

