/* ------------------------------------*\
   #OBJECT-FIT
\*------------------------------------ */

/**
 * [1] Scale the width and height of a replacement element (e.g. `<img>` or
 * `<video>`) to its container dimensions.
 */

.u-object-fit-contain {
    @include object-fit($object-fit: contain, $important: true);
    width: 100%; /* [1] */
    height: 100%; /* [1] */
}

.u-object-fit-cover {
    @include object-fit($object-fit: cover, $important: true);
    width: 100%; /* [1] */
    height: 100%; /* [1] */
}
