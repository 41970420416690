/* ------------------------------------*\
    #MEDIA-GRID (Deprecated)
\*------------------------------------ */

/**
 * @FALLBACK:
 * Browsers without `:has` support.
 *
 * This triggers animation on hover/focus on container instead of single elements.
 */

/**
 * [1] Avoid triggering animation on hover for container when in between items.
 * [2] Reset pointer events for items.
 */

@include supports-not-has-selector() {

    .c-media-grid {

        /* stylelint-disable-next-line scss/media-feature-value-dollar-variable */
        @media (prefers-reduced-motion: no-preference) and (pointer: fine) {
            pointer-events: none; /* [1] */

            &:hover .c-media-grid__link .c-media-grid__image,
            &:focus-within .c-media-grid__image {
                opacity: $media-grid-image-opacity-hover;

                @if ($media-grid-image-blur-on-hover) {
                    filter: blur($media-grid-image-blur-on-hover);
                }

            }

            &:hover .c-media-grid__link,
            .c-media-grid__link {

                &:hover,
                &:focus-visible {

                    /* stylelint-disable-next-line selector-max-specificity */
                    .c-media-grid__image {
                        opacity: 1;

                        @if ($media-grid-image-blur-on-hover) {
                            filter: blur(0);
                        }

                    }

                }

            }

            .c-media-grid__item {
                pointer-events: auto; /* [2] */
            }

        }

    }

}
