/* ------------------------------------*\
    #CHECKBOX
\*------------------------------------ */

// The horizontal spacing between checkbox and label text.
$checkbox-spacing:                           $GLOBAL-SPACING-UNIT-SMALL !default;
$checkbox-size:                              28px !default;
$checkbox-radius:                            $GLOBAL-INPUT-BORDER-RADIUS !default;
$checkbox-transition-duration:               $GLOBAL-TRANSITION-DURATION !default;

$checkbox-background-color:                  $COLOR-WHITE !default;
$checkbox-background-color-checked:          $checkbox-background-color !default;
$checkbox-background-color-checked-hover:    null !default;
$checkbox-background-color-disabled:         $COLOR-INACTIVE !default;
$checkbox-background-color-disabled-checked: null !default;
$checkbox-background-color-error:            $checkbox-background-color !default;
$checkbox-background-color-error-checked:    null !default;

$checkbox-border-width:                      $GLOBAL-INPUT-BORDER-WIDTH !default;
$checkbox-border-style:                      $GLOBAL-INPUT-BORDER-STYLE !default;
$checkbox-border-color:                      $GLOBAL-INPUT-BORDER-COLOR !default;
$checkbox-border:                            $checkbox-border-width $checkbox-border-style $checkbox-border-color !default;
$checkbox-border-color-hover:                null !default;
$checkbox-border-color-focus:                $GLOBAL-INPUT-BORDER-COLOR-FOCUS !default;
$checkbox-border-color-checked:              $checkbox-border-color !default;
$checkbox-border-color-checked-hover:        $checkbox-background-color-checked-hover !default;
$checkbox-border-color-disabled:             rgba($COLOR-INACTIVE, 0.5) !default;
$checkbox-border-color-error:                $COLOR-ALERT !default;
$checkbox-border-color-disabled-checked:     null !default;
$checkbox-border-color-error-checked:        null !default;

$checkbox-box-shadow-checked:                null !default;
$checkbox-box-shadow-focus:                  null !default;
$checkbox-box-shadow-disabled:               null !default;
$checkbox-box-shadow-error:                  null !default;

$checkbox-icon:                              get-icon(semantic-checkmark) !default;
$checkbox-icon-size:                         18px !default;
$checkbox-icon-color:                        $COLOR-BASE !default;
$checkbox-icon-color-error:                  $checkbox-icon-color !default;
$checkbox-icon-color-checked:                null !default;
$checkbox-icon-color-disabled:               null !default;
$checkbox-icon-transform:                    scale(0) !default;
$checkbox-icon-transform-checked:            scale(1) !default;

$checkbox-label-text-size:                   $FS0 !default;
$checkbox-label-text-font-weight:            null !default;
$checkbox-label-text-color:                  $COLOR-BASE-TEXT !default;
$checkbox-label-text-color-checked:          null !default;
$checkbox-label-text-color-disabled:         rgba($COLOR-BASE-TEXT, 0.5) !default;
$checkbox-label-text-color-error:            $COLOR-ALERT !default;


// The line height of the label text. This needs to be a `px` value,
// since it's used for calculation.
$checkbox-label-line-height:                 $GLOBAL-LINE-HEIGHT !default;
@if (unit($checkbox-label-line-height) != "px") {
    @error("`$checkbox-label-line-height` needs to be a `px` value!");
}

/**
 * [1] Defensively reset any `margin-bottom`.
 * [2] Set the indent of an adjusting `.c-form-field__extra` via a CSS custom
 *     property.
 * [3] Hide original checkbox.
 * [4] Make it block so everything behaves as expected.
 * [5] Setting the height of the label text to a minimum of the checkbox
 *     size makes the text clickable on its entire height, i.e.: It increases
 *     the click area.
 * [6] Align the first (or only) row of label text vertically centered to the
 *     checkbox.
 * [7] Keep the spacing between checkbox and label text consistent,
 *     independent from the checkbox size.
 * [8] Relation for the checkbox.
 * [9] Vertically center the icon inside of the checkbox.
 * [10] The overlay has the same dimensions as the box but transparent borders
 *      and `background-clip: content-box;` to avoid sometimes invisible borders
 *      in smaller browser zoom levels.
 * [11] Animate the positioning of the overlay over the icon.
 */

.c-checkbox {
    @include disable-touch-highlight();
    margin-bottom: 0; /* [1] */

    + .c-form-field__extra {
        --form-field-extra-indent: #{($checkbox-size + $checkbox-spacing)}; /* [2] */
    }
}

    .c-checkbox__input {
        @include a11y-hidden(); /* [3] */
    }

    .c-checkbox__label {
        $_checkbox-label-padding-top: ceil(($checkbox-size - $checkbox-label-line-height) * 0.5);
        $_checkbox-label-padding-top: if($_checkbox-label-padding-top > 0, $_checkbox-label-padding-top, null); /* stylelint-disable-line scss/no-duplicate-dollar-variables */

        @include responsive-font-size(
            $font-size:   $checkbox-label-text-size,
            $line-height: $checkbox-label-line-height
        );
        position: relative; /* [8] */
        display: block; /* [4] */
        min-height: $checkbox-size; /* [5] */
        padding-top: $_checkbox-label-padding-top; /* [6] */
        padding-left: ($checkbox-size + $checkbox-spacing); /* [7] */
        font-weight: $checkbox-label-text-font-weight;
        color: $checkbox-label-text-color;
        cursor: pointer;

        .c-checkbox__input:checked + & {
            color: $checkbox-label-text-color-checked;
        }

        .c-checkbox__input:focus + &::before {
            border-color: $checkbox-border-color-focus;
            box-shadow: $checkbox-box-shadow-focus;
        }

        .c-checkbox__input[disabled] + & {
            color: $checkbox-label-text-color-disabled;
            cursor: default;
            user-select: none;
        }

        .c-checkbox__input.is-error + & {
            color: $checkbox-label-text-color-error;
        }

        // The checkbox outer box.
        &::before {
            @include transition($transition-duration: $checkbox-transition-duration);
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: $checkbox-size;
            height: $checkbox-size;
            border: $checkbox-border;
            border-radius: $checkbox-radius;
            background-color: $checkbox-background-color;

            // Hover styles.
            .c-checkbox:hover & {
                border-color: $checkbox-border-color-hover;
            }

            // Checked styles.
            .c-checkbox__input:checked + & {
                border-color: $checkbox-border-color-checked;
                background-color: $checkbox-background-color-checked;
                box-shadow: $checkbox-box-shadow-checked;
            }

            // Checked + Hover styles.
            /* stylelint-disable-next-line selector-max-specificity */
            .c-checkbox:hover .c-checkbox__input:not(.is-error, [disabled]):checked + & {
                border-color: $checkbox-border-color-checked-hover;
                background-color: $checkbox-background-color-checked-hover;
            }

            // Disabled styles.
            .c-checkbox__input[disabled] + & {
                border-color: $checkbox-border-color-disabled;
                background-color: $checkbox-background-color-disabled;
                box-shadow: $checkbox-box-shadow-disabled;
            }

            // Error styles.
            .c-checkbox__input.is-error + & {
                border-color: $checkbox-border-color-error;
                background-color: $checkbox-background-color-error;
                box-shadow: $checkbox-box-shadow-error;
            }

            /* stylelint-disable selector-max-specificity */
            // Checked + Disabled styles.
            .c-checkbox__input:checked[disabled] + & {
                border-color: $checkbox-border-color-disabled-checked;
                background-color: $checkbox-background-color-disabled-checked;
            }

            // Checked + Error styles.
            .c-checkbox__input:checked.is-error + & {
                border-color: $checkbox-border-color-error-checked;
                background-color: $checkbox-background-color-error-checked;
            }
            /* stylelint-enable */

        }

        // The checkbox icon.
        &::after {
            @include icon-font($line-height: null);
            @include transition($transition-duration: $checkbox-transition-duration);
            content: $checkbox-icon;
            position: absolute;
            top: 0; /* [10] */
            left: 0; /* [10] */
            width: $checkbox-size; /* [10] */
            height: $checkbox-size; /* [10] */
            transform: $checkbox-icon-transform;
            transform-origin: center center;
            font-size: $checkbox-icon-size;
            line-height: $checkbox-size; /* [9] */
            text-align: center;
            background-clip: content-box; /* [10] */
            background-color: transparent; /* [10] */
            color: $checkbox-icon-color;
            visibility: hidden;

            // Checked styles.
            .c-checkbox__input:checked + & {
                transform: $checkbox-icon-transform-checked;
                color: $checkbox-icon-color-checked;
                visibility: visible;
            }

            // Disabled styles.
            .c-checkbox__input[disabled] + & {
                color: $checkbox-icon-color-disabled;
            }

            // Error styles.
            .c-checkbox__input.is-error + & {
                color: $checkbox-icon-color-error;
            }

        }

    }
