/* ------------------------------------*\
    #BLOCK
\*------------------------------------ */

/**
 * Utility class to make an element `display: block;`.
 */

.u-block {
    display: block !important;
}
