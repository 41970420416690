/* ------------------------------------*\
    #LIST-UI
\*------------------------------------ */

$list-ui-padding-vertical:               $GLOBAL-SPACING-UNIT !default;
$list-ui-padding-horizontal:             0 !default;
$list-ui-padding:                        $list-ui-padding-vertical $list-ui-padding-horizontal !default;
$list-ui-border-width:                   1px !default;
$list-ui-border-style:                   solid !default;
$list-ui-border-color:                   $COLOR-BORDER !default;
$list-ui-border:                         $list-ui-border-width $list-ui-border-style $list-ui-border-color !default;
$list-ui-font-size:                      $FS0 !default;
$list-ui-font-family:                    null !default;
$list-ui-font-weight:                    null !default;
$list-ui-ordered-counter-style-type:     decimal-leading-zero !default;
$list-ui-ordered-counter-font-size:      $FS3 !default;
$list-ui-ordered-counter-font-family:    null !default;
$list-ui-ordered-counter-font-weight:    null !default;
$list-ui-ordered-counter-line-height:    1 !default;
$list-ui-ordered-counter-letter-spacing: null !default;
$list-ui-ordered-counter-alignment:      baseline !default;
$list-ui-ordered-counter-color:          null !default;
$list-ui-ordered-counter-spacing:        $GLOBAL-SPACING-UNIT !default;
$list-ui-ordered-counter-offset:         0.2ex !default; /* stylelint-disable-line unit-disallowed-list */

/**
 * [1] Vertically center counter with first line of text.
 *     Offset to compensate misalignment caused by `baseline`.
 * [2] Pseudo-element for the numbering of the ordered variant.
 * [3] Ensure alignment of numbers independent of number width.
 */

.c-list-ui {
    @include list-bare();
    border-top: $list-ui-border;
}

.c-list-ui--ordered {
    counter-reset: section;
}

    .c-list-ui__item {
        @include responsive-font-size(
            $font-size:   $list-ui-font-size,
            $line-height: false,
            $accept-null: true
        );

        padding: $list-ui-padding;
        border-bottom: $list-ui-border;
        font-family: $list-ui-font-family;
        font-weight: $list-ui-font-weight;

        .c-list-ui--ordered & {
            display: flex; /* [1] */
            align-items: if($list-ui-ordered-counter-alignment, $list-ui-ordered-counter-alignment, null); /* [1] */

            &::before { /* [2] */
                @include responsive-font-size(
                    $font-size:   $list-ui-ordered-counter-font-size,
                    $line-height: $list-ui-ordered-counter-line-height,
                    $accept-null: true
                );
                @include responsive-property("margin-right", $list-ui-ordered-counter-spacing);

                content: counter(section, #{$list-ui-ordered-counter-style-type});
                counter-increment: section;
                transform: if($list-ui-ordered-counter-offset, translateY($list-ui-ordered-counter-offset), null); /* [1] */
                font-family: $list-ui-ordered-counter-font-family;
                font-weight: $list-ui-ordered-counter-font-weight;
                font-variant-numeric: tabular-nums; /* [3] */
                letter-spacing: $list-ui-ordered-counter-letter-spacing; /* [3] */
                color: $list-ui-ordered-counter-color;
            }

        }

    }
