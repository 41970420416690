/* ------------------------------------*\
    #SHOW-MORE
\*------------------------------------ */

@use "../05-objects/_objects.toggle.scss" as toggle;

$show-more-spacing-bottom:                   null !default;
$show-more-spacing-top:                      null !default;

$show-more-toggle-spacing-bottom:            null !default;
$show-more-toggle-spacing-top:               $GLOBAL-SPACING-UNIT !default;

$show-more-items-alignment:                  null !default;

$show-more-icon-complexity:                  null !default;
$show-more-icon-expand:                      get-icon(semantic-expand, $show-more-icon-complexity) !default;
$show-more-icon-collapse:                    get-icon(semantic-collapse, $show-more-icon-complexity) !default;
$show-more-icon-size:                        icon-size(small) !default;

// Set to a reasonable amount.
$show-more-target-max-height:                200em !default;
$show-more-target-animation-duration-expand: $GLOBAL-TRANSITION-DURATION-LONG !default;


/**
 * [1] Remove any trailing spacing for the first-two level of nested last-child elements inside `c-show-more__body`.
 * [2] Render the show more button icon via CSS and default to the collapse state.
 * [4] Truncate any visible overflow.
 * [5] Handle the collapsed state for the toggle content target.
 * [6] Handle the initial state for the toggle content target.
 * [7] Align icon and button text when differ in size.
 */

.c-show-more {
    margin-bottom: $show-more-spacing-bottom;
    margin-top: $show-more-spacing-top;
}

    .c-show-more__body {
        @include transition(
            $transition-property: "visibility, max-height, opacity",
            $transition-duration: $show-more-target-animation-duration-expand
        );
        max-height: $show-more-target-max-height;
        overflow: hidden;
        opacity: 1;
        visibility: visible;

        &#{toggle.$toggle-target-hidden-state} { /* [5] */
            @include transition(
                $transition-property: "visibility, max-height, opacity",
                $transition-delay: "#{$show-more-target-animation-duration-expand}, 0s, 0s;",
            );
            max-height: 0;
            opacity: 0;
            visibility: hidden;
        }

        &:not(#{toggle.$toggle-target-unhidden-state}):not(#{toggle.$toggle-target-hidden-state}) { /* [6] */
            max-height: 0;
            transition-duration: 0s;
            animation-duration: 0s;
        }

        > :last-child,
        > :last-child > :last-child {
            margin-bottom: initial; /* [1] */
        }

    }

    .c-show-more__button {
        --show-more-button-icon: #{$show-more-icon-expand};
        align-items: $show-more-items-alignment; /* [7] */
        margin-bottom: $show-more-toggle-spacing-bottom;
        margin-top: $show-more-toggle-spacing-top;

        &#{toggle.$toggle-unhidden-state} {
            --show-more-button-icon: #{$show-more-icon-collapse};
        }

    }

    .c-show-more__icon::before { /* [2] */
        @include icon-font();
        content: var(--show-more-button-icon);
        font-size: $show-more-icon-size;
    }
