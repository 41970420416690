/* ------------------------------------*\
   #ACTIONS-BUTTONS-CONTAINER
\*------------------------------------ */

@use "../05-objects/_objects.toggle.scss" as toggle;

$action-buttons-container-float-grid-area:          bottom-right !default;
$action-buttons-container-toggle-icon-complexity:   null !default;
$action-buttons-container-toggle-icon-hidden:       semantic-plus !default;
$action-buttons-container-toggle-icon-unhidden:     semantic-minus !default;
$action-buttons-container-max-items:                10 !default;
$action-buttons-container-item-transition-duration: 0.05s !default;

/**
 * [1] Don’t generate a display box for the container and the buttons wrapper
 *     so that the items will be positioned by the outer float grid area Flexbox rules.
 * [2] Position buttons in Float Grid area.
 * [3] Transition items from `0px` height to button height in unhidden state.
 * [4] Increase `transition-delay` for each item (up to `$action-buttons-container-max-items`)
 *     to display one after the other.
 * [5] With `toggle.$toggle-target-active-selector` we can check if the toggle is
 *     working. If not (e.g. with disabled JavaScript), we don’t hide the items.
 * [6] Remove flexbox gap between items in hidden state.
 * [7] Target devices that primary input mechanism can not hover and has not a
 *     fine pointer, so essentially devices with touch capabilities.
 * [8] Increase specificity to override `.c-btn--incognito`.
 * [9] Use a feature of the Incognito Button: CSS custom properties conditionals
 *     to set the collapsed state of a button. Check the related comment in
 *     `_components.buttons.scss`.
 * [10] Fix some unwanted toggle button height change for some brands (e.g. AUDI)
 *      when the container gets expanded in a Float Grid area.
 * [11] We don’t want to animate `transform` for the buttons and the toggle button.
 *      That would result in some weird movements while resizing the browser
 *      window (due to the `transition-delay`).
 * [12] because we already know the width of the collapsed buttons (`--button-height`)
 *      we can use that to calculate the offset in the Float Grid area.
 *      The default offset of `100%` would shift the whole button when it gets
 *      expanded.
 * [13] Extend the Float Grid area offset transformation. For details check the
 *      comments in `_objects.float-grid.scss`.
 * [14] Remove any visible `box-shadow` when in collapsed state.
 */
.c-action-buttons-container,
.c-action-buttons-container__items {
    display: contents; /* [1] */
}

.c-action-buttons-container {
    --action-buttons-container-item-transition-duration: var(--transition-duration-reduced-motion, #{$action-buttons-container-item-transition-duration});
    @include float-grid-area(
        $area: $action-buttons-container-float-grid-area, /* [2] */
    );
}

    .c-action-buttons-container__item {
        --action-buttons-container-item-transition-delay: calc((var(--action-buttons-container-item-transition-duration) * var(--action-buttons-container-item-transition-delay-multiplier, 1)) - var(--action-buttons-container-item-transition-duration)); /* [4] */
        max-height: var(--button-height); /* [3] */
        transition: /* [4] */
            var(--transition-property) var(--action-buttons-container-item-transition-duration) var(--transition-timing-function) var(--action-buttons-container-item-transition-delay),
            opacity calc(var(--action-buttons-container-item-transition-duration) * 5) ease-in-out var(--action-buttons-container-item-transition-delay),
            transform 0s;

        @for $i from 1 through $action-buttons-container-max-items {
            &:nth-child(#{$i}) {
                --action-buttons-container-item-transition-delay-multiplier: #{$i}; /* [4] */
            }
        }

        #{toggle.$toggle-target-active-selector}:not(#{toggle.$toggle-target-unhidden-state}) > & { /* [5] */
            min-height: 0; /* [3] */
            max-height: 0; /* [3] */
            margin-top: calc(var(--float-grid-item-gap) * -1); /* [3] [6] */
            padding-top: 0; /* [3] */
            padding-bottom: 0; /* [3] */
            border-top-width: 0; /* [3] */
            border-bottom-width: 0; /* [3] */
            box-shadow: none; /* [14] */
        }

        /* stylelint-disable-next-line scss/media-feature-value-dollar-variable, order/order */
        @media not all and (hover: hover) and (pointer: fine) { /* [7] */
            &#{&} { /* [8] */
                --button-collapsed: initial; /* [9] */
            }
        }

    }

.c-action-buttons-container__toggle {
    --transition-property: var(--transition-property), transform;
    --transition-duration: var(--transition-duration), 0s;
    --action-buttons-container-toggle-icon: #{get-icon($action-buttons-container-toggle-icon-hidden, $action-buttons-container-toggle-icon-complexity)};
    flex-basis: var(--button-height); /* 10 */

    /* stylelint-disable-next-line scss/media-feature-value-dollar-variable */
    @media not all and (hover: hover) and (pointer: fine) { /* [7] */
        &:not(.is-collapsed) {
            --button-collapsed: initial; /* [9] */
        }
    }

    &#{toggle.$toggle-unhidden-state} {
        --action-buttons-container-toggle-icon: #{get-icon($action-buttons-container-toggle-icon-unhidden, $action-buttons-container-toggle-icon-complexity)};
    }
}

.c-action-buttons-container__toggle__icon::before {
    @include icon-font();
    content: var(--action-buttons-container-toggle-icon);
}

.c-action-buttons-container__item,
.c-action-buttons-container__toggle {
    --grid-area-item-offset: var(--button-height); /* [12] */
    @extend %float-grid-area-offset; /* [13] */
}
