
/* ------------------------------------*\
    #PRODUCT SEARCH FORM (Scopes)
\*------------------------------------ */

/**
 * [1] Align buttons to the right side on horizontal mode and center them
 *     on vertical mode.
 */

 .c-product-search-form {

    .c-btn--small {

        @include media-query("<=#{$product-search-form-breakpoint}") {
            justify-content: center;
        }

    }

}
