// /* ------------------------------------*\
//     #FLICKITY
// \*------------------------------------ */

$flickity-counter-switch-limit: 5 !default;
$flickity-counter-font-weight:  null !default;
$flickity-counter-font-size:    $FS0 !default;
$flickity-counter-color:        inherit !default;

/**
 * [1] Reset default dot styles.
 *     By using `all` does not require to reset all the properties
 *     on dots. This saves code and increases maintainability.
 * [2] Prevent navigation by clicking on count.
 * [3] Reset dot spacings to remove reserved spacing.
 * [4] Inherit typographic styles after [1].
 */

/// Mixin to automatically switch between dot and counter pagination when
/// limit is exceeded.
///
/// CSS generated content shows `x / y`.
/// - `counter-increment` is used for numbering
/// - `::before` pseudo element on the current item is used for the counter
/// - `::after` pseudo element on the last item is used for the denominator
///    including a seperator (`/`).
///
/// @param {Number} $limit Maximum before automatic switch.
/// @param {Number} $font-weight Counter font-weight.
/// @param {String} $font-size Counter font-size.
/// @param {Color} $color Counter color.
@mixin flickity-dot-counter-switch(
    $limit:       $flickity-counter-switch-limit,
    $font-weight: $flickity-counter-font-weight,
    $font-size:   $flickity-counter-font-size,
    $color:       $flickity-counter-color,
) {
    @include at-least($limit + 1) {

        // whitespace between seperator and denominator
        $_space-char: "\00a0";

        counter-increment: slider-count;
        margin: 0; /* [3] */
        cursor: default;
        pointer-events: none; /* [2] */

        &::before,
        &::after {
            all: initial; /* [1] */
            /* stylelint-disable-next-line order/order */
            @include responsive-font-size(
                $font-size:   $font-size,
                $line-height: 1,
            );
            font-family: inherit; /* [4] */
            font-weight: $font-weight;
            color: $color;
            pointer-events: none; /* [2] */
        }

        // current index
        &::before {
            content: counter(slider-count) "#{$_space-char}";
        }

        // seperator + delimiter
        &:last-child::after {
            content: "/#{$_space-char}" counter(slider-count);
            display: block;
        }

        // show only current and last (total)
        &:not(.is-selected) {

            &::before {
                display: none;
            }

        }

        // hide dots
        &::after {
            display: none;
        }

    }

}
