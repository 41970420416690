/* ------------------------------------*\
    #DEFINITION LIST
\*------------------------------------ */

/**
 * @DEPRECATED: The use of `dl` for specific styling is deprecated.
 *              Use the Description List component instead.
 */

// Define weight of definition list title elements
$definition-list-spacing:            $GLOBAL-SPACING-UNIT-SMALL !default;

$definition-list-title-size:         $FS0 !default;
$definition-list-title-spacing:      null !default;
$definition-list-title-weight:       $GLOBAL-FONT-WEIGHT-BOLD !default;
$definition-list-title-color:        $COLOR-BASE-TEXT !default;

$definition-list-description-size:   $FS0 !default;
$definition-list-description-weight: $GLOBAL-FONT-WEIGHT-REGULAR !default;
$definition-list-description-color:  $COLOR-BASE-TEXT !default;


dt {
    @include responsive-font-size($definition-list-title-size);
    margin-bottom: $definition-list-title-spacing;
    font-weight: $definition-list-title-weight;
    color: $definition-list-title-color;
}

dd {
    @include responsive-font-size($definition-list-description-size);
    margin-bottom: $definition-list-spacing;
    font-weight: $definition-list-description-weight;
    color: $definition-list-description-color;

    &:last-child {
        margin-bottom: 0;
    }

}
