// /* ------------------------------------*\
//     #TOGGLE (Settings)
// \*------------------------------------ */

$toggle-bar-width:                  52px !default;
$toggle-bar-height:                 24px !default;
$toggle-bar-spacing:                $GLOBAL-SPACING-UNIT-XSMALL !default;
$toggle-bar-background:             $COLOR-ON-SURFACE-500 !default;
$toggle-bar-background-active:      $COLOR-BRAND !default;

$toggle-bar-border:                 false !default;
$toggle-bar-border-radius:          ($toggle-bar-height * 0.5) !default;

$toggle-handle-width:               18px !default;
$toggle-handle-height:              18px !default;
$toggle-handle-border-radius:       100px !default;
$toggle-handle-box-shadow:          false !default;
$toggle-handle-border:              false !default;
$toggle-handle-color-inactive:      $COLOR-WHITE !default;
$toggle-handle-color-active:        $toggle-handle-color-inactive !default;

$toggle-on-off-handle-color-active: $toggle-handle-color-inactive !default;

$toggle-a-b-handle-color:           $toggle-handle-color-inactive !default;
$toggle-a-b-text-color-inactive:    $COLOR-INACTIVE !default;

$toggle-text-font-size:             $FS-1 !default;
$toggle-text-font-weight:           $GLOBAL-INPUT-LABEL-FONT-WEIGHT !default;
