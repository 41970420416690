/* ------------------------------------*\
    #FOLD-OUT
\*------------------------------------ */

$fold-out-breakpoint:              s !default;
$fold-out-header-spacing:          $GLOBAL-SPACING-UNIT !default;
$fold-out-header-icon:             get-icon(semantic-expand) !default;
$fold-out-header-icon-color:       $COLOR-BRAND !default;
$fold-out-header-open-state:       "[aria-expanded='true']" !default;
$fold-out-header-close-state:      "[aria-expanded='false']" !default;
$fold-out-content-open-state:      "[aria-hidden='false']" !default;
$fold-out-content-close-state:     "[aria-hidden='true']" !default;
$fold-out-icon-pseudo-element:     "::after" !default;
$fold-out-icon-rotate-open-state:  180deg !default;
$fold-out-icon-rotate-close-state: 0 !default;
$fold-out-footer:                  null !default;

$fold-out-header-item-spacing-horizontal: (
    null:                 $GLOBAL-SPACING-UNIT-LARGE,
    $fold-out-breakpoint: $GLOBAL-SPACING-UNIT-SMALL,
) !default;

$fold-out-header-icon-size: (
    null:                 icon-size(medium),
    $fold-out-breakpoint: icon-size(small),
) !default;

/**
 * [1] Reset default `<button>` styles.
 * [2] Provide vertical spacing when the fold-out is expanded.
 * [3] Display the head and footer icon via pseudo element as it is
 *     completely decorative.
 * [4] The footer icon is reversed according to its position.
 * [5] Conditionally display a fold out footer.
 * [6] Force the correct color for the button and its child elements.
 *     For example, Safari 15 on macOS and iOS has made some changes
 *     to the user agent styles, including overriding the color property
 *     of all form elements such as inputs, buttons etc.
 */
.c-fold-out {
    @include page-wrap();
}

    .c-fold-out__header {
        @include reset-button(); /* [1] */
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        text-align: left;
        color: inherit; /* [6] */

        &#{$fold-out-header-open-state} {
            margin-bottom: $fold-out-header-spacing; /* [2] */
        }

        > * {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;

            &:not(:last-child) {
                @include responsive-property("margin-right", $fold-out-header-item-spacing-horizontal);
            }

        }

    }

    .c-fold-out__content {

        &:not(#{$fold-out-content-open-state}) {
            display: none;
        }

        > *:last-child {
            margin-bottom: 0;
        }

    }

    .c-fold-out__footer {
        @include reset-button(); /* [1] */
        display: if($fold-out-footer, flex, none); /* [5] */
        text-align: right;

        @if ($fold-out-footer) {

            #{$fold-out-content-close-state} + & {
                display: none;
            }

            #{$fold-out-content-open-state} + & {
                display: block;
            }

            &#{$fold-out-icon-pseudo-element} {
                transform: $fold-out-icon-rotate-open-state; /* [4] */
            }

        }

    }

    .c-fold-out__header,
    .c-fold-out__footer { /* [3]  */
        position: relative;

        &#{$fold-out-icon-pseudo-element} {
            @include icon-font();
            @include responsive-property(
                ("flex-basis", $fold-out-header-icon-size),
                ("font-size", $fold-out-header-icon-size),
                ("margin-left", $fold-out-header-item-spacing-horizontal),
            );
            content: $fold-out-header-icon;
            color: $fold-out-header-icon-color;
        }

        &#{$fold-out-header-close-state}#{$fold-out-icon-pseudo-element} {
            rotate: $fold-out-icon-rotate-close-state;
        }

        &#{$fold-out-header-open-state}#{$fold-out-icon-pseudo-element} {
            rotate: $fold-out-icon-rotate-open-state;
        }

    }
