/* ------------------------------------*\
    #CAROUSEL
\*------------------------------------ */

$skoda-carousel-counter-offset:                       $flickity-button-offset !default;
$skoda-carousel-counter-padding:                      $GLOBAL-SPACING-UNIT-XSMALL !default;
$skoda-carousel-counter-border-radius:                $GLOBAL-BORDER-RADIUS-SMALL !default;
$skoda-carousel-counter-background-color:             rgba($COLOR-ON-SURFACE-900, 0.75) !default;
$skoda-carousel-counter-font-color-current:           $COLOR-WHITE !default;

$skoda-carousel-fullscreen-counter-offset-horizontal: ($flickity-fullscreen-button-size + (2 * $skoda-carousel-counter-offset)) !default;
$skoda-carousel-fullscreen-counter-offset-vertical:   $skoda-carousel-counter-offset !default;

/**
 * [1] Suppress unwanted spacing in fullscreen mode.
 * [2] Reset `transform` because the pagination does not have
 *     to be displayed centered according to brand guidelines.
 * [3] Enforce counter according to brand guidelines.
 * [4] Overwrite `min-height` so that the background color
 *     of the counter gets the correct height.
 * [5] Enforce visibility of fullscreen button so that there is
 *     no visual gap to pagination once the CSS modifier
 *     `.c-carousel--buttons-on-hover` is set.
 * [6] Handles the right positioning of pagination.
 * [7] Overwrite `outline: 0`, which is inherited
 *     from the Bronson-Default package.
 */
.c-carousel {

    &.is-fullscreen {
        padding-bottom: 0; /* [1] */

        .flickity-page-dots {
            bottom: $skoda-carousel-fullscreen-counter-offset-vertical;
        }

    }

    &[data-carousel-fullscreen="true"] {

        .flickity-page-dots {
            right: $skoda-carousel-fullscreen-counter-offset-horizontal;
        }

    }

    .flickity-page-dots {
        left: auto; /* [6] */
        right: $skoda-carousel-counter-offset; /* [6] */
        padding-left: $skoda-carousel-counter-padding;
        padding-right: $skoda-carousel-counter-padding;
        transform: none; /* [2] */
        border-radius: $skoda-carousel-counter-border-radius;
        background-color: $skoda-carousel-counter-background-color;

        .dot {

            /* stylelint-disable-next-line selector-max-class */
            &.is-selected {

                /* stylelint-disable-next-line selector-max-specificity, selector-max-class, max-nesting-depth */
                &::before {
                    color: important($skoda-carousel-counter-font-color-current, true);
                }

            }

        }

    }

    .flickity-prev-next-button {

        &:focus-visible { /* [7] */
            @include focus-styles();
        }

    }

}

    .c-carousel--buttons-on-hover {

        .flickity-fullscreen-button {
            opacity: 1; /* [5] */
        }

    }
