// /* ------------------------------------*\
//     #CARD-PATTERNS (Settings)
//
//     Global settings for card-like patterns
//     that share visual properties.
// \*------------------------------------ */

@use "sass:map";

$CARD-PATTERN-PADDING:                                          $GLOBAL-SPACING-UNIT !default;
$CARD-PATTERN-PADDING-HORIZONTAL:                               $CARD-PATTERN-PADDING !default;
$CARD-PATTERN-PADDING-VERTICAL:                                 $CARD-PATTERN-PADDING !default;
$CARD-PATTERN-MODULE-SPACING:                                   $GLOBAL-SPACING-UNIT-XSMALL !default;

$CARD-PATTERN-BACKGROUND-COLOR:                                 $COLOR-SURFACE-PRIMARY !default;

$CARD-PATTERN-TEXT-COLOR:                                       $COLOR-BASE-TEXT !default;

// Responsive font size opt-out.
$CARD-PATTERN-TITLE-SIZE: (
    null: map.get($FS2, null),
) !default;

$CARD-PATTERN-TITLE-WEIGHT:                                     $GLOBAL-FONT-WEIGHT-BOLD !default;
$CARD-PATTERN-TITLE-COLOR:                                      $CARD-PATTERN-TEXT-COLOR !default;

// Responsive font size opt-out.
$CARD-PATTERN-SUBTITLE-SIZE: (
    null: map.get($FS0, null),
) !default;

$CARD-PATTERN-SUBTITLE-WEIGHT:                                  $CARD-PATTERN-TITLE-WEIGHT !default;

$CARD-PATTERN-BORDER-RADIUS:                                    $GLOBAL-BORDER-RADIUS !default;

$CARD-PATTERN-BORDER-WIDTH:                                     1px !default;
$CARD-PATTERN-BORDER-STYLE:                                     solid !default;
$CARD-PATTERN-BORDER-COLOR:                                     $COLOR-BORDER !default;
$CARD-PATTERN-BORDER-COLOR-HOVER:                               $COLOR-ON-SURFACE-300 !default;
$CARD-PATTERN-BORDER-COLOR-SELECTED:                            $COLOR-BRAND !default;
$CARD-PATTERN-BORDER-COLOR-SELECTED-HOVER:                      $COLOR-BRAND-HOVER !default;
$CARD-PATTERN-BORDER:                                           $CARD-PATTERN-BORDER-WIDTH $CARD-PATTERN-BORDER-STYLE $CARD-PATTERN-BORDER-COLOR !default;

$CARD-PATTERN-ICON-SIZE:                                        large !default;
$CARD-PATTERN-ICON-COLOR:                                       $CARD-PATTERN-TEXT-COLOR !default;

$CARD-PATTERN-SELECT-INDICATOR-BACKGROUND-SIZE:                 map.get($ICON-ROUND-FULL-SIZES, xsmall) !default;
$CARD-PATTERN-SELECT-INDICATOR-BACKGROUND-COLOR:                $COLOR-SURFACE-SECONDARY !default;
$CARD-PATTERN-SELECT-INDICATOR-BACKGROUND-COLOR-HOVER:          $COLOR-ON-SURFACE-100 !default;
$CARD-PATTERN-SELECT-INDICATOR-BACKGROUND-COLOR-SELECTED:       $COLOR-BRAND !default;
$CARD-PATTERN-SELECT-INDICATOR-BACKGROUND-COLOR-SELECTED-HOVER: $COLOR-BRAND-HOVER !default;

$CARD-PATTERN-SELECT-INDICATOR-ICON:                            semantic-checkmark !default;
$CARD-PATTERN-SELECT-INDICATOR-ICON-SIZE:                       xxsmall !default;
$CARD-PATTERN-SELECT-INDICATOR-ICON-COLOR:                      $COLOR-WHITE !default;

$CARD-PATTERN-INACTIVE-LAYER-BACKGROUND-COLOR:                  $COLOR-INACTIVE-LAYER-COLOR !default;
$CARD-PATTERN-INACTIVE-LAYER-OPACITY:                           $COLOR-INACTIVE-LAYER-OPACITY !default;

/**
 * @TODO:
 * - icon alignment?
 */
