/* ------------------------------------*\
    #CIVIC-COOKIE-CONTROL
\*------------------------------------ */

$ccc-grid-area: bottom-right !default;

/**
 * [1] Hide SVG that does not seem to be used anyway but would cover other
 *     Float Grid elements. `overflow: hidden` would not work properly with the
 *     Float Grid’s column sizing.
 */

/* stylelint-disable selector-max-specificity, selector-max-id, itcss/no-at-important */
.js-enable-3rd-party-float-grid {

    #ccc-icon {
        @include float-grid-area(
            $area: $ccc-grid-area,
            $order: 1,
        );
        position: static !important;
        margin: 0 !important;

        > svg {
            @include a11y-hidden();
        }
    }

}
/* stylelint-enable selector-max-specificity, selector-max-id, itcss/no-at-important */
