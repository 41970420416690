// /* ------------------------------------*\
//     #OBJECT-FIT
// \*------------------------------------ */

/// Only apply position if it is not `center center` (default).
///
/// @access public
/// @param {String} $object-fit [cover] object-fit mode
/// @param {String} $object-position [center center] position x y (top|center|bottom, left|center|right)
/// @param {Boolean} $important [null] Use `!important` on declarations
@mixin object-fit($object-fit: cover, $object-position: null, $important: false) {
    object-fit: important($object-fit, $important);
    object-position: important($object-position, $important);
}
