/* ------------------------------------*\
    #ERROR-MESSAGE
\*------------------------------------ */

$error-message-text-size:        $FS-1 !default;
$error-message-text-font-weight: null !default;
$error-message-spacing-top:      0 !default;
$error-message-spacing-bottom:   null !default;
$error-message-text-color:       $COLOR-ALERT !default;
$error-message-link-text-color:  currentColor !default;

/**
 * [1] Just output the declaration when it's != 0.
 * [2] Hide messages without content. Selector only matches for NO content,
 *     whitespaces & comments are not considered as empty.
 * [3] Match selector even with whitespaces & comments,
 *     analogue to `:blank` (firefox only).
 * [4] Move error message visually to the end in a flexbox context to make it
 *     work properly with `.c-input__counter`.
 */
.c-error-message {
    @include responsive-font-size($error-message-text-size);
    @if not ($error-message-spacing-top == 0 or $error-message-spacing-top == null) {
        margin-top: $error-message-spacing-top; /* [1] */
    }
    @if not ($error-message-spacing-bottom == null) {
        margin-bottom: $error-message-spacing-bottom; /* [1] */
    }
    order: 1;
    font-weight: $error-message-text-font-weight;
    color: $error-message-text-color;

    &:empty {
        display: none; /* [2] */
    }

    &:-moz-only-whitespace {
        display: none; /* [3] */
    }

    a {
        color: $error-message-link-text-color;
    }

}
