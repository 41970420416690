// /* ------------------------------------*\
//     #CLEARFIX
// \*------------------------------------ */

/* stylelint-disable itcss/no-at-important */

// Mixin to drop micro clearfix into a selector. Further reading:
// https://www.cssmojo.com/the-very-latest-clearfix-reloaded/
//
// .usage {
//   @include clearfix();
// }
@mixin clearfix() {

    &::after {
        content: "" !important;
        display: block !important;
        clear: both !important;
    }

}

/* stylelint-enable itcss/no-at-important */
