/* ------------------------------------*\
    #TEXT-TRUNCATE
\*------------------------------------ */

/**
 * A utility class to truncate text with "..." when content is wider than the available space.
 * Works only for single-line truncation
 */

.u-text-truncate {
    @include truncate();
}
