/* ------------------------------------*\
    #CARDS (Scopes)
\*------------------------------------ */

$scope-card-form-field-spacing-vertical: $GLOBAL-FIELDSET-ITEM-SPACING !default;

/**
 * [1] Add vertical spacing between form fields.
 */

.c-card {
}

    .c-card__body {

        .c-form-field + .c-form-field {
            margin-top: $scope-card-form-field-spacing-vertical; /* [1] */
        }

    }
