/* ------------------------------------*\
    #CUSTOM-RANGE-SLIDER
\*------------------------------------ */
@use "sass:math" as *;

$custom-range-slider-track-height:                           $RANGE-SLIDER-TRACK-HEIGHT !default;
$custom-range-slider-track-touch-size:                       40px !default;
$custom-range-slider-track-background-color:                 $RANGE-SLIDER-TRACK-BACKGROUND-COLOR !default;
$custom-range-slider-track-background-color-disabled:        $RANGE-SLIDER-TRACK-BACKGROUND-COLOR-DISABLED !default;
$custom-range-slider-track-border-radius:                    $RANGE-SLIDER-TRACK-BORDER-RADIUS !default;
$custom-range-slider-track-filled-background-color:          $RANGE-SLIDER-TRACK-FILLED-BACKGROUND-COLOR !default;
$custom-range-slider-track-filled-background-color-disabled: $RANGE-SLIDER-TRACK-BACKGROUND-COLOR-DISABLED !default;

$custom-range-slider-thumb-size:                             $RANGE-SLIDER-THUMB-SIZE !default;
$custom-range-slider-thumb-has-border:                       $RANGE-SLIDER-THUMB-HAS-BORDER !default;
$custom-range-slider-thumb-border-color:                     $RANGE-SLIDER-THUMB-BORDER-COLOR !default;
$custom-range-slider-thumb-border-color-disabled:            $RANGE-SLIDER-THUMB-BORDER-COLOR-DISABLED !default;
$custom-range-slider-thumb-border-width:                     $RANGE-SLIDER-THUMB-BORDER-WIDTH !default;
$custom-range-slider-thumb-border:                           $custom-range-slider-thumb-border-width solid $custom-range-slider-thumb-border-color !default;
$custom-range-slider-thumb-border-radius:                    $RANGE-SLIDER-THUMB-BORDER-RADIUS !default;
$custom-range-slider-thumb-background-color:                 $RANGE-SLIDER-THUMB-BACKGROUND-COLOR !default;
$custom-range-slider-thumb-background-color-disabled:        $RANGE-SLIDER-THUMB-BACKGROUND-COLOR-DISABLED !default;
$custom-range-slider-thumb-has-dot:                          $RANGE-SLIDER-THUMB-HAS-DOT !default;
$custom-range-slider-thumb-dot-size:                         $RANGE-SLIDER-THUMB-DOT-SIZE !default;
$custom-range-slider-thumb-dot-background-color:             $RANGE-SLIDER-THUMB-DOT-BACKGROUND-COLOR !default;
$custom-range-slider-thumb-dot-background-color-disabled:    $RANGE-SLIDER-THUMB-DOT-BACKGROUND-COLOR-DISABLED !default;
$custom-range-slider-thumb-offset:                           ceil(($custom-range-slider-track-height * 0.5)) !default;

$custom-range-slider-thumb-transition-property:              $GLOBAL-TRANSITION-PROPERTY !default;
$custom-range-slider-thumb-transition-duration:              $GLOBAL-TRANSITION-DURATION !default;
$custom-range-slider-thumb-transition-timing-function:       $GLOBAL-TRANSITION-TIMING-FUNCTION !default;

$custom-range-slider-spacing-vertical:                       ceil(($custom-range-slider-thumb-size * 0.5)) !default;

$custom-range-slider-pips-display-values:                    true !default;
$custom-range-slider-pips-value-font-size:                   $GLOBAL-FONT-SIZE !default;
$custom-range-slider-pips-value-font-weight:                 $GLOBAL-INPUT-LABEL-FONT-WEIGHT !default;
$custom-range-slider-pips-value-offset-to-marker:            10px !default;
$custom-range-slider-pips-value-color:                       $COLOR-BASE-TEXT !default;
$custom-range-slider-pips-value-color-disabled:              $COLOR-INACTIVE !default;
$custom-range-slider-pips-marker-size:                       8px !default;
$custom-range-slider-pips-marker-background-color:           $custom-range-slider-track-background-color !default;
$custom-range-slider-pips-marker-background-color-disabled:  $custom-range-slider-track-filled-background-color-disabled !default;
$custom-range-slider-pips-marker-has-dot:                    false !default;
$custom-range-slider-pips-marker-dot-size:                   8px !default;
$custom-range-slider-pips-marker-dot-background-color:       $COLOR-WHITE !default;
$custom-range-slider-pips-marker-border-radius:              50% !default;
$custom-range-slider-pips-marker-target-size:                40px !default;

$custom-range-slider-with-values-height:                     ($custom-range-slider-thumb-size + $custom-range-slider-pips-value-offset-to-marker + $custom-range-slider-pips-value-font-size) !default;
$custom-range-slider-with-values-vertical-offset:            calc(50% - #{($custom-range-slider-pips-value-font-size - div($custom-range-slider-with-values-height, $custom-range-slider-pips-value-font-size))}) !default;

 // @TODO: define global tooltip styles in settings layer and use everywhere for tooltip styles (here, tippy, etc.) BRON-4862
$custom-range-slider-tooltip-background:                     $COLOR-BRAND !default;
$custom-range-slider-tooltip-background:                     if($custom-range-slider-tooltip-background, $custom-range-slider-tooltip-background, null); /* stylelint-disable-line scss/dollar-variable-default, scss/no-duplicate-dollar-variables */
$custom-range-slider-tooltip-color:                          $COLOR-WHITE !default;
$custom-range-slider-tooltip-color-disabled:                 null !default;
$custom-range-slider-tooltip-indicator-size:                 10px !default;
$custom-range-slider-tooltip-font-size:                      map-get($FS-1, null) !default;
$custom-range-slider-tooltip-font-weight:                    null !default;
$custom-range-slider-tooltip-line-height:                    1 !default;
$custom-range-slider-tooltip-spacing:                        $GLOBAL-SPACING-UNIT-XSMALL !default; // between handle + tooltip
$custom-range-slider-tooltip-padding:                        $GLOBAL-SPACING-UNIT-SMALL !default;
$custom-range-slider-tooltip-border-radius:                  $GLOBAL-BORDER-RADIUS !default;
$custom-range-slider-tooltip-size:                           ($custom-range-slider-tooltip-padding * 2) + ($custom-range-slider-tooltip-font-size * $custom-range-slider-tooltip-line-height) !default;
$custom-range-slider-tooltip-position:                       $custom-range-slider-thumb-size + $custom-range-slider-tooltip-spacing + $custom-range-slider-tooltip-indicator-size !default;
$custom-range-slider-tooltip-shadow:                         false !default;
$custom-range-slider-tooltip-arrow:                          true !default;

$custom-range-slider-outer-spacing:                          $custom-range-slider-thumb-offset !default;
$custom-range-slider-outer-spacing-with-tooltip:             $custom-range-slider-tooltip-spacing + $custom-range-slider-tooltip-indicator-size + $custom-range-slider-tooltip-size + $custom-range-slider-thumb-offset !default;
/**
 * [1]  Set the left and right margin of the slider to half the size of the thumb.
 * [2]  Add pseudo element so we can set width and height of the range slider
 *      to the size needed.
 * [3]  Center marker on track.
 * [4]  Show marker above track / connect.
 * [5]  Add transition when tapping on track.
 * [6]  Hide the overflow of the connector to make border radius of the track
 *      visible.
 * [7]  Set the spacing between value and marker.
 * [8]  Markers are now generally set `in-range` via script.
 * [9]  Add pseudo-elements for markers (`::before` element) and their dots
 *      (`::after` element).
 * [10] Align elements, so that the thumb is fully placed inside of the range
 *      slider container.
 * [11] Default tooltip display is top - above the current value.
 * [12] Tooltip is displayed below the current value when variable is set to false.
 * [13] Add spacing when tooltip is present.
 * [14] Defines the positioning of the tooltip container and its indicator.
 * [15] Align first (min) and last (max) with the outer boundaries of the track
 *      and pips.
 * [16] Overwrite inline-style set by plugin.
 * [17] Avoid selector being applied to last (max) value if only 1 pip is displayed.
 * [18] Put pseudo-area above track to increase (touch-)target when interacting
 *      with track.
 * [19] Manually update the `.c-custom-range-slider__origin` `width` and `height` to reflect the styles in `noUiSlider`.
 * [20] Reserver space around component to account for thumb and optional tooltip.
 */
.c-custom-range-slider {
    position: relative;
    height: $custom-range-slider-thumb-size;
    margin-right: $custom-range-slider-spacing-vertical; /* [1] */
    margin-left: $custom-range-slider-spacing-vertical; /* [1] */
    margin-top: $custom-range-slider-outer-spacing; /* [20] */

    &::after { /* [2] */
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: $custom-range-slider-track-height;
        transform: translateY(-50%);
        background-color: $custom-range-slider-track-background-color;
        @if ($custom-range-slider-track-border-radius) {
            border-radius: $custom-range-slider-track-border-radius;
        }
    }

    &[disabled] {

        &::after {
            background-color: $custom-range-slider-track-background-color-disabled;
        }

    }

    @if ($custom-range-slider-pips-display-values) {
        &[data-custom-range-slider-pips] {
            height: $custom-range-slider-with-values-height;

            &::after {
                top: $custom-range-slider-with-values-vertical-offset; /* [10] */
            }

        }
    }

}

    .c-custom-range-slider__target {
        position: relative;

        * {
            @include disable-touch-highlight();
            touch-action: none;
            user-select: none;
        }

    }

    .c-custom-range-slider__base {
        position: relative;
        z-index: 1;
        top: 50%;
        width: 100%;
        height: $custom-range-slider-track-height;
        transform: translateY(-50%);
        cursor: pointer;

        /* [15] */
        &::before {
            content: "";
            position: absolute;
            right: 0;
            left: 0;
            height: $custom-range-slider-track-touch-size;
            transform: translateY(-50%);
        }

        .c-custom-range-slider[disabled] & {
            cursor: initial;
        }

        @if ($custom-range-slider-pips-display-values) {
            .c-custom-range-slider[data-custom-range-slider-pips] & {
                top: $custom-range-slider-with-values-vertical-offset; /* [10] */
            }
        }

    }

    .c-custom-range-slider__origin {
        position: absolute;
        right: 0;
        width: 100%; /* [19] */
        height: 100%; /* [19] */

        .c-custom-range-slider--tap & {
            @include transition(); /* [5] */
        }

    }

    .c-custom-range-slider__thumb {
        @include transition(
            $transition-property:        $custom-range-slider-thumb-transition-property,
            $transition-duration:        $custom-range-slider-thumb-transition-duration,
            $transition-timing-function: $custom-range-slider-thumb-transition-timing-function,
        );
        position: absolute;
        top: -($custom-range-slider-thumb-offset);
        right: 0;
        width: $custom-range-slider-thumb-size;
        height: $custom-range-slider-thumb-size;
        transform: translate(50%, -50%);

        background-color: $custom-range-slider-thumb-background-color;
        cursor: grab;

        @if ($custom-range-slider-thumb-has-border) {
            border: $custom-range-slider-thumb-border;
        }

        @if ($custom-range-slider-thumb-border-radius) {
            border-radius: $custom-range-slider-thumb-border-radius;
        }

        .c-custom-range-slider[disabled] & {
            border-color: $custom-range-slider-thumb-border-color-disabled;
            background-color: $custom-range-slider-thumb-background-color-disabled;
            pointer-events: none;
            cursor: initial;
        }

        &:focus {
            outline: none;
        }

        &:active {
            cursor: grabbing;
        }

        @if ($custom-range-slider-thumb-has-dot) {
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: $custom-range-slider-thumb-dot-size;
                height: $custom-range-slider-thumb-dot-size;
                transform: translate(-50%, -50%);
                background-color: $custom-range-slider-thumb-dot-background-color;
                @if ($custom-range-slider-thumb-border-radius) {
                    border-radius: $custom-range-slider-thumb-border-radius;
                }

                .c-custom-range-slider[disabled] & {
                    background-color: $custom-range-slider-thumb-dot-background-color-disabled;
                }

            }

        }

    }

    /**
    * Connects
    */

    .c-custom-range-slider__connect-container {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden; /* [6] */
        @if ($custom-range-slider-track-border-radius) {
            border-radius: $custom-range-slider-track-border-radius;
        }

        /* [18] */
        &::before {
            content: "";
            position: absolute;
            right: 0;
            left: 0;
            height: $custom-range-slider-track-touch-size;
            transform: translateY(-50%);
        }

    }

    .c-custom-range-slider__connect {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        transform-origin: 0 0;
        background-color: $custom-range-slider-track-filled-background-color;

        .c-custom-range-slider--tap & {
            @include transition(); /* [5] */
        }

        .c-custom-range-slider[disabled] & {
            background-color: $custom-range-slider-track-filled-background-color-disabled;
        }

    }

    /**
    * Pips, Markers & Values
    */

    .c-custom-range-slider__pips {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .c-custom-range-slider__marker {
        position: absolute;
        z-index: initial; /* [4] */
        top: 50%; /* [3] */
        display: block;
        width: $custom-range-slider-pips-marker-target-size;
        height: $custom-range-slider-pips-marker-target-size;
        transform: translate(-50%, -50%); /* [3] */
        cursor: pointer;
        user-select: none;

        @if ($custom-range-slider-pips-marker-size) {
            &::before,
            &::after { /* [9] */
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                transform: translate(-50%, -50%);
                cursor: pointer;
                @if ($custom-range-slider-pips-marker-border-radius) {
                    border-radius: $custom-range-slider-pips-marker-border-radius;
                }
            }

            &::before {
                @include transition();
                width: $custom-range-slider-pips-marker-size;
                height: $custom-range-slider-pips-marker-size;
                background-color: $custom-range-slider-pips-marker-background-color;
            }

            @if ($custom-range-slider-pips-marker-has-dot) {
                &::after {
                    width: $custom-range-slider-pips-marker-dot-size;
                    height: $custom-range-slider-pips-marker-dot-size;
                    background-color: $custom-range-slider-pips-marker-dot-background-color;
                }
            }

            &.is-active:first-of-type {

                &::before,
                &::after {
                    background-color: $custom-range-slider-track-filled-background-color; /* [8] */
                }

            }

            &.in-range {

                &::before,
                &::after {
                    background-color: $custom-range-slider-track-filled-background-color;
                }

            }

            .c-custom-range-slider[disabled] & {

                &,
                &::before,
                &::after {
                    touch-action: none;
                    cursor: initial;
                }

                @if ($custom-range-slider-track-background-color-disabled != $custom-range-slider-track-filled-background-color-disabled) {
                    &::before,
                    &::after {
                        background-color: $custom-range-slider-pips-marker-background-color-disabled;
                    }

                    &.is-active,
                    &.in-range {

                        /* stylelint-disable-next-line selector-max-specificity */
                        &::before,
                        &::after {
                            background-color: $custom-range-slider-track-filled-background-color-disabled;
                        }

                    }
                } @else {
                    &::before,
                    &::after {
                        background-color: $custom-range-slider-pips-marker-background-color-disabled;
                    }
                }

            }

            @if ($custom-range-slider-pips-display-values) {
                .c-custom-range-slider[data-custom-range-slider-pips] & {
                    top: $custom-range-slider-with-values-vertical-offset; /* [10] */
                }
            }
        }

    }

    .c-custom-range-slider__value {
        @if ($custom-range-slider-pips-display-values) {
            @include font-size(
                $font-size:   $custom-range-slider-pips-value-font-size,
                $line-height: 1
            );
            position: absolute;
            padding-top: ($custom-range-slider-thumb-size + $custom-range-slider-pips-value-offset-to-marker); /* [7] */
            transform: translateX(-50%);
            font-weight: $custom-range-slider-pips-value-font-weight;
            white-space: nowrap;
            text-align: center;
            color: $custom-range-slider-pips-value-color;
            cursor: pointer;
            user-select: none;

            // first
            &:nth-child(2):not(:last-child) { /* [17] */
                transform: translateX(-#{$custom-range-slider-pips-marker-size * 0.5}); /* [15] */
            }

            // last
            &:last-child {
                right: 0;
                left: auto !important; /* [16] */ /* stylelint-disable-line itcss/no-at-important */
                transform: translateX(#{$custom-range-slider-pips-marker-size * 0.5}); /* [15] */
            }

            .c-custom-range-slider[disabled] & {
                color: $custom-range-slider-pips-value-color-disabled;
                cursor: initial;
                touch-action: none;
            }
        } @else {
            display: none;
        }
    }

    .c-custom-range-slider__tooltip-wrapper {
        position: absolute;
        left: 50%;
        display: block;
        padding: if($custom-range-slider-tooltip-padding > 0, $custom-range-slider-tooltip-padding, null);
        transform: translateX(-50%);
        border-radius: if($custom-range-slider-tooltip-border-radius, $custom-range-slider-tooltip-border-radius, null);
        font-size: $custom-range-slider-tooltip-font-size;
        font-weight: if($custom-range-slider-tooltip-font-weight, $custom-range-slider-tooltip-font-weight, null);
        line-height: $custom-range-slider-tooltip-line-height;
        text-align: center;
        white-space: nowrap;
        background-color: $custom-range-slider-tooltip-background;
        color: $custom-range-slider-tooltip-color;
        cursor: default;

        .c-custom-range-slider[disabled] & {
            color: $custom-range-slider-tooltip-color-disabled;
        }

        @if ($custom-range-slider-tooltip-shadow) {
            box-shadow: $custom-range-slider-tooltip-shadow;
            will-change: transform;

            @supports (filter: drop-shadow($custom-range-slider-tooltip-shadow)) {
                box-shadow: none;
                filter: drop-shadow($custom-range-slider-tooltip-shadow);
            }
        }
    }

    @if ($custom-range-slider-tooltip-arrow) {

        .c-custom-range-slider__arrow { /* [14] */
            content: "";
            position: absolute;
            left: 50%;
            width: $custom-range-slider-tooltip-indicator-size;
            height: $custom-range-slider-tooltip-indicator-size;
            transform: translateX(-50%);
            border-style: solid;
            border-color: transparent;
            border-width: $custom-range-slider-tooltip-indicator-size;
        }

    }

    .c-custom-range-slider[data-custom-range-slider-tooltips] {
        margin-top: $custom-range-slider-outer-spacing-with-tooltip; /* [20] */
    }

    .c-custom-range-slider:not([data-custom-range-slider-tooltip-position="bottom"]) { /* [11] */

        .c-custom-range-slider__tooltip-wrapper {
            bottom: $custom-range-slider-tooltip-position;
        }

        @if ($custom-range-slider-tooltip-arrow) {

            .c-custom-range-slider__arrow {
                top: 100%;
                border-bottom-width: 0;
                border-top-color: $custom-range-slider-tooltip-background;
            }

        }

    }

    [data-custom-range-slider-tooltip-position="bottom"],
    .custom-range-slider-tooltip--bottom { /* [12] */
        margin-bottom: $custom-range-slider-outer-spacing-with-tooltip; /* [20] */

        .c-custom-range-slider__tooltip-wrapper {
            top: $custom-range-slider-tooltip-position;
        }

        @if ($custom-range-slider-tooltip-arrow) {

            .c-custom-range-slider__arrow {
                bottom: 100%;
                border-top-width: 0;
                border-bottom-color: $custom-range-slider-tooltip-background;
            }

        }

    }
