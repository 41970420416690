// /* ------------------------------------*\
//     #ICONS
// \*------------------------------------ */

@use "sass:map";
@use "sass:list";
@use "sass:string";
@use "sass-true/sass/throw" as throw;

// SASS FUNCTIONS
// ------------------------------------ */

//
// [1] Use !important to prevent issues with browser extensions that change fonts.
//

/// Mixin to generate complete icon font CSS.
/// Set args to `null` to disable (conditional declaration).
/// @param {Boolean} $defaults [true] Defensively set args as defaults. If `false` do not set in,
///                                  e.g. when declarations are set on selector outside of mixin.
/// @param {String} $display [inline-block] `display` value
/// @param {Number} $line-height [1] `line-height` value
@mixin icon-font($defaults: true, $display: inline-block, $line-height: 1) {
    display: if($defaults, $display, null);
    font-family: important($ICON-FONT, true); /* [1] */
    font-weight: normal;
    font-style: normal;
    line-height: if($defaults, $line-height, null);
    font-variant: normal;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

/// Get icon unicode for semantic or non-semantic icons.
/// Usage example: get-icon(control-add);
/// Usage example with complexity: get-icon(control-add, simple);
///
/// @TODO: account for icon not in list and return null (?)
///
/// @param {String} $name icon name, e.g. 'control-add' or `semantic-calendar`.
/// @param {String} $complexity (optional) complexity identifier, e.g. 'simple'.
/// @returns {String} matching non-semantic icon name.
@function get-icon($name, $complexity: null) {
    // Store originally provided $name for later use.
    $originalName: $name;

    // Determine if the provided icon name has a 'semantic-' prefix.
    $isSemantic: check-semantic($name);

    @if ($isSemantic) {
        $name: get-semantic-icon($originalName);
    }

    // Find unicode (semantic or non-semantic) in $ICONS map and save it
    // to a variable.
    $unicode: get-escaped-icon-unicode($name);

    // If a complexity is provided, adjust $iconname accordingly.
    @if ($complexity) {
        // check if requested complexity is in the list
        @if (index($ICON-COMPLEXITIES, $complexity)) {
            // Find unicode in $ICONS map and return it.
            $complexUnicode: get-escaped-icon-unicode(#{$name}--#{$complexity});
            // Check if unicode for complexity exists.
            @if ($complexUnicode) {
                $unicode: $complexUnicode;
            } @else {
                @warn("Provided complexity #{$complexity} for icon #{$name} does not exist. Fallback is used.");
            }
        } @else {
            @error ("Provided complexity #{$complexity} is not in $ICON-COMPLEXITIES. Valid complexities: #{$ICON-COMPLEXITIES}");
        }
    }

    @return $unicode;
}


/// Function to assign sizes to icon in your SCSS.
///
/// @param {String} $iconsize Desired icon size (has to match any key of the `$ICON-SIZES` map).
/// @param {Map} $sizes [$ICON-SIZES] Map with scale (key) and size (value).
///
/// @returns {Number} Icon size (usually in px).
@function icon-size($size, $sizes: $ICON-SIZES) {
    @return map.get($sizes, $size);
}

/// Get complexity keyword based on provided size
///
/// @param {String} $size
/// @param {Map} $mapping [$ICON-SIZE-TO-COMPLEXITY-MAPPING]
///
/// @returns {String} Complexity keyword.
@function icon-get-complexity-by-size($size, $mapping: $ICON-SIZE-TO-COMPLEXITY-MAPPING) {
    @return map.get($mapping, $size);
}

// INTERNAL HELPER FUNCTIONS
// ------------------------------------ */

/// Determine if the provided icon name has a 'semantic-' prefix
///
/// @param {String} $name icon name to check.
/// @returns {Boolean} returns `true` if the icon is semantic, otherwise returns `false`.
@function check-semantic($name) {
    @return string.index($name, "semantic-") == 1;
}

/// Get non-semantic icon name from icon mapping in $SEMANTIC-ICONS.
///
/// @param {String} $name semantic icon name.
/// @returns {String} matching non-semantic icon name.
@function get-semantic-icon($name) {
    @return map-get($SEMANTIC-ICONS, $name);
}

/// Get non-semantic icon name from icon mapping in $SEMANTIC-ICONS.
///
/// @param {String} $name Semantic icon name.
/// @param {Map} $mapping [$SEMANTIC-ICONS] Map containing semantic name (value) and mapped, non-semantic content (value).
///
/// @returns {String} Matching non-semantic icon name.
@function icon-get-icon-by-semantic-name($name, $mapping: $SEMANTIC-ICONS) {
    @return map.get($mapping, $name);
}

/// Get icon data based on provided name.
///
/// @param {String} $name Icon name.
/// @param {Map} $data [$ICON-COMPLEXITY-DATA] Map containing nested map with icon name and complexity data
///
/// @return {Map} Map containing complexity data
///
/// @example
///
/// scss:
///
/// // INPUT
/// $data: (
///     calc: (
///         default: "e91d",
///         complex: "e91e",
///         simple: "e91f",
///     );
/// );
///
/// // OUTPUT
/// (
///     default: "e91d",
///     complex: "e91e",
///     simple: "e91f",
/// )
@function icon-get-data($name, $data: $ICON-COMPLEXITY-DATA) {
    @return map.get($data, $name);
}

/// Get escaped unicode for icon from $ICONS map.
/// This is necessary in order to output correct unicode strings in the
/// compiled CSS. Without doing this, only cryptic blank characters would be shown.
/// This is better for debugging. See link below for reference.
///
/// @link https://stackoverflow.com/questions/26111982/sass-3-4-removing-forward-slash-on-a-string/26112274#26112274 Reference for unicode escaping.
/// @param {String} $name icon name.
/// @param {Map} $icons [$ICONS]
/// @returns {String} escaped unicode. If none is found, returns `null`.
@function get-escaped-icon-unicode($name, $icons: $ICONS) {
    $unicode: map-get($icons, $name);

    @if ($unicode) {
        $quoted-unicode: "\"\\#{$unicode}\"";
        $escaped-unicode: unquote($quoted-unicode);
        @return $escaped-unicode;
    } @else {
        @error("Icon \"#{$name}\" not found.");
    }
}

/// Certain icons are not available in the semantic set for all brands.
/// To clearly indicate it, a red dot with a label is shown.
/// In order to use the placeholder, the value in the map needs to be `null`.
/// BRON-2948
///
/// @param {String} $name icon name, e.g. 'control-add' or `semantic-calendar`.
@mixin placeholder-icon-semantic($name) {
    @warn("semantic icon: no definition for #{$name}");

    /* stylelint-disable color-named */
    $_color: red;
    $_text-color: white;
    /* stylelint-enable color-named */
    $_size: 1em;

    .c-icon--\[#{$name}\] {
        position: relative;
        display: inline-block;

        // override icon
        &::before {
            content: "";
            display: block;
            width: $_size;
            height: $_size;
            margin-right: auto;
            margin-left: auto;
            border-radius: 25%;
            background-color: $_color;
        }

        &::after {
            content: "N/A";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: $BASE-FONT;
            font-size: 0.5em;
            text-align: center;
            color: $_text-color;
        }

    }

}



/// Generate a map with complexity data by icon name
///
/// @param {String} $name Icon name.
/// @param {String} $icons [$ICONS] Map of all icons.
///
/// @returns {Map} Nested map with base name, complexity (key) and escaped unicode (value).
///
/// @example
/// generate complexity list for icon
///  add: "e904", => DEFAULT
///  add--simple: "e905", => SIMPLE
///
/// calc: "e91d", => DEFAULT
/// calc--complex: "e91e", => COMPLEX
/// calc--simple: "e91f", => SIMPLE
@function icon-build-complexity-data($name, $icons: $ICONS) {
    $data-name: $name;
    $data-complexity: default;

    $index-complexity-separator: string.index($name, "--");

    @if ($index-complexity-separator) {
        // get complexity from name
        $data-complexity: string.slice($name, $index-complexity-separator + 2); // + 2 to remove `--` and only get complexity

        // name without complexity suffix
        $data-name: string.slice($name, 1, $index-complexity-separator - 1);
    }

    @return (
        $data-name: (
            $data-complexity: get-escaped-icon-unicode($name, $icons)
        )
    );
}


/// Complexity data for all icons.
///
/// @type Map
/// @access public
$ICON-COMPLEXITY-DATA: () !default;

// Get complexity data for all icons
// and populate the global complexity data.
@each $name, $code in $ICONS {
    $ICON-COMPLEXITY-DATA: map.deep-merge(icon-build-complexity-data($name), $ICON-COMPLEXITY-DATA);
}

/// Generate complexity DEFINITIONS.
/// If the toggle (`var()`) is evaluated "truthy",
/// the value will be used for the set complexity.
/// Otherwise the value for the entire property definition is evaluated "falsy"
/// and "skipped".
///
/// @param {Map} $complexity-data
/// @access private
///
/// Icon has complexity "simple" enabled (`--bron-icon-complex: ""`)
/// => generate "simple" definition for icon
/// ```
/// --bron-icon-complexity-simple: var(--bron-icon-simple) <icon-code>
/// ```
///
/// @example
///
/// INPUT
/// $data: (
///   simple: "\e002",
/// )
///
/// OUTPUT
///  --bron-icon-complexity-simple: var(--bron-icon-simple) "\e002";
///
///
/// INPUT
/// $data: (
///   complex: "\e003",
/// )
///
/// OUTPUT
///  --bron-icon-complexity-complex: var(--bron-icon-complex) "\e003";
///
///
/// INPUT
/// $data: (
///   default: "\e003",
/// )
///
/// OUTPUT
/// none
@mixin icon-generate-complexity-definition($complexity-data) {
    @each $complexity, $icon in $complexity-data {
        @if not ($complexity == "default") {
            --bron-icon-complexity-#{$complexity}: var(--bron-icon-#{$complexity}) #{$icon};
        }
    }
}

/// Generate definitions for icons via custom properties
///
/// @access public
/// @param {Map} $complexity-data key-value pair for complexity-unicode
///
/// @example
///
/// INPUT: 3 complexities
/// $data: (
///   default: "\e001",
///   simple: "\e002",
///   complex: "\e003",
/// )
///
/// OUTPUT
///  --bron-icon-complexity-complex: var(--bron-icon-complex) "\e003";
///  --bron-icon-complexity-simple: var(--bron-icon-simple) "\e002";
///  --bron-icon: var(--bron-icon-complexity-complex, var(--bron-icon-complexity-simple, "\e001"));
///
///
///
/// INPUT: 2 complexities
/// $data: (
///   default: "\e001",
///   simple: "\e002",
/// )
///
/// OUTPUT
///  --bron-icon-complexity-simple: var(--bron-icon-simple) "\e002";
///  --bron-icon: var(--bron-icon-complexity-simple, "\e001");
///
///
///
/// INPUT: 1 complexity (default
/// $data: (
///   default: "\e001",
/// )
///
/// OUTPUT
///   --bron-icon: "\e900";
@mixin icon-generate-definition($complexity-data) {
    $default-complexity: map.get($complexity-data, "default");
    $has-complex:        list.index(map.keys($complexity-data), "complex");
    $has-simple:         list.index(map.keys($complexity-data), "simple");

    @include icon-generate-complexity-definition($complexity-data);

    // Going from highest to lowest (default) complexity
    // due to (nested) custom property structure with
    // `var(value, var(nested-value, fallback))`.
    @if ($has-complex) {
        // If complexity definition for "complex" exists,
        // generate complex and simple definition for icon
        // with default complexity as a fallback.
        --bron-icon: var(--bron-icon-complexity-complex, var(--bron-icon-complexity-simple, #{$default-complexity}));
    } @else if ($has-simple) {
        // If complexity definition for "simple" exists,
        // generate simple definition for icon
        // with default complexity as a fallback.
        --bron-icon: var(--bron-icon-complexity-simple, #{$default-complexity});
    } @else {
        // If no complexity is defined,
        // generate icon definition for default "complexity"
        // without fallback.
        --bron-icon: #{$default-complexity};
    }
}


/// Generate custom property "toggle" to enable complexity.
///
/// @link https://dev.to/siddharthshyniben/conditional-logic-with-css-the-css-custom-property-trick-44hb
/// @param {String} $complexity Set toggle for given complexity.
/// @param {List | null} $allow-list [$ICON-COMPLEXITIES]
///
/// @return {String} Custom property suffixed by complexity with empty value.
@mixin icon-complexity($complexity: null, $allow-list: $ICON-COMPLEXITIES) {
    @if ($complexity) and ($allow-list) {
        @if (list.index($allow-list, $complexity)) {
            --bron-icon-#{$complexity}: "";
        } @else {
            @include throw.error(
                $message: "#{$complexity} must be an entry in list of #{$allow-list}.",
                $catch: true,
            );
        }
    }
}
