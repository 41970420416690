// /* ------------------------------------*\
//     #Container Query
// \*------------------------------------ */

/// Container Query
///
/// @param {Map} $map - 1-dimensional map with queries
///                     key[String]: alias
///                     value[Number]: unitless value that reflects px-based value
///                                    (handled via JS, needs to be unitless)
/// @param {String} $size - Create selector based on size alias
/// @param {String} $direction["width"] - Query direction, either "width" or "height"
///
/// @example
///     $module-width-queries: (
///         small:  0,
///         medium: 300,
///         large:  500,
///     );
///
/// Use in container-query specific scope (`[data-container-query="module"]`)
///     [data-container-query="module"] {
///
///         @include container($module-width-queries, "medium") {
///             ...
///         }
///
///     }
///
/// Outputs:
///     [data-container-query="module"][data-container-width~="300"] {
///         ...
///     }
///
/// @example
///     [data-container-query="module"] {
///
///         @include container($module-width-queries, "medium", "height) {
///             ...
///         }
///
///     }
///
/// Outputs:
///     [data-container-query="module"][data-container-height~="300"] {
///         ...
///     }
@mixin container($map, $size, $direction: "width") {
    $value: map-get($map, $size);
    $parent-selector: &;

    // Mixin requires to be nested inside a selector to avoid Sass compiling error
    // `Error: Top-level selectors may not contain the parent selector "&".`.
    @if not ($parent-selector) {
        @error("The container query can not be used as a top-level selector. To avoid side-effects the mixin needs to be nested in a selector");
    }

    // Safe-guard to allow only "width" & "height".
    @if not (($direction == "width") or ($direction == "height")) {
        @error("Only `width` & `height` queries allowed");
    }

    // Safe-guard to ensure only available keys are used.
    @if (map-has-key($map, $size)) {
        &[data-container-#{$direction}~="#{$value}"] {
            @content;
        }
    } @else {
        @error("Key not available in map", $size);
    }
}

/// Convert Sass Map values to CSS Custom Property String to be consumed via JS.
///
/// @param {Map} $map - 1-dimensional map: key[String]: value[Number]
/// @returns {String} - Space-separated String with values.
///
/// @example
///
///     $module-width-queries: (
///         small:  0,
///         medium: 300,
///         large:  500,
///     );
///
///     --container-widths: #{cq-convert-data($module-width-queries)};
///
/// Output:
///     --container-widths: "0 300 500";
@function cq-convert-data($map) {
    $output: "";

    @each $key, $value in $map {
        @if not (type-of($value)  == number) {
            @error("The value must be of type number", $value);
        }

        $index: index(($map), ($key $value));
        // Space separated except for last item
        $delimiter: if($index == length($map), "", " ");

        $output: $output + $value + $delimiter;
    }

    @return $output;
}
