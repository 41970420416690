/* ------------------------------------*\
    #FOOTER
\*------------------------------------ */

// General footer styles
$footer-spacing-bottom:                          $GLOBAL-SPACING-UNIT-LARGE !default;
$footer-background-color:                        $COLOR-PALE !default;
$footer-text-color:                              $COLOR-BASE-TEXT !default;

// Footer breadcrumb
$footer-breadcrumb:                              true !default;
$footer-breadcrumb-background-color:             null !default;
$footer-breadcrumb-spacing-vertical:             $GLOBAL-SPACING-UNIT-SMALL !default;
$footer-breadcrumb-border-bottom:                1px solid $COLOR-BASE !default;

// Footer nav
$footer-nav-breakpoint:                          s !default;
$footer-nav-spacing-vertical:                    $GLOBAL-SPACING-UNIT !default;
$footer-nav-border-bottom:                       0 !default;
$footer-nav-accordion-icon-size:                 icon-size(small) !default;
$footer-nav-accordion-enabled:                   true !default; // Enable/disable accordion on small screens.
$footer-nav-accordion-icon-expand:               get-icon(semantic-expand) !default;
$footer-nav-accordion-icon-collapse:             get-icon(semantic-collapse) !default;
$footer-nav-accordion-icon-color:                $footer-text-color !default;
$footer-nav-title-spacing-bottom:                0 !default;
$footer-nav-title-font-size:                     $FS-1 !default;
$footer-nav-title-font-weight:                   $GLOBAL-FONT-WEIGHT-BOLD !default;
$footer-nav-title-text-color:                    $footer-text-color !default;
$footer-nav-column-min-width:                    200px !default;
$footer-nav-column-spacing-bottom:               $GLOBAL-SPACING-UNIT-SMALL !default;
$footer-nav-column-gap-horizontal:               $GLOBAL-SPACING-UNIT-SMALL !default;
$footer-nav-item-padding-vertical:               0 !default;
$footer-nav-item-padding-vertical-at-breakpoint: 0 !default;
$footer-nav-item-font-family:                    $BASE-FONT-FAMILY !default;
$footer-nav-item-font-size:                      $FS-1 !default;
$footer-nav-item-font-weight:                    null !default;
$footer-nav-item-text-color:                     $footer-text-color !default;
$footer-nav-item-text-color-hover:               $footer-nav-item-text-color !default;
$footer-nav-item-text-decoration:                none !default;
$footer-nav-item-border-bottom:                  0 !default;

// Footer meta nav
$footer-meta-nav-breakpoint:                     s !default;
$footer-meta-nav-background-color:               $footer-background-color !default;
$footer-meta-nav-collapse-at-breakpoint:         true !default;
$footer-meta-nav-spacing-vertical:               $GLOBAL-SPACING-UNIT-SMALL !default;
$footer-meta-nav-spacing-vertical-at-breakpoint: $footer-meta-nav-spacing-vertical !default;
$footer-meta-nav-border-top-at-breakpoint:       0 !default;
$footer-meta-nav-item-delimiter:                 false !default;
$footer-meta-nav-item-delimiter-color:           null !default;
$footer-meta-nav-item-spacing-horizontal:        $GLOBAL-SPACING-UNIT-SMALL !default;
$footer-meta-nav-item-font-size:                 $FS-1 !default;
$footer-meta-nav-item-font-weight:               inherit !default;
$footer-meta-nav-item-text-color:                $footer-text-color !default;
$footer-meta-nav-item-text-color-hover:          $footer-nav-item-text-color !default;
$footer-meta-nav-item-text-decoration:           none !default;
$footer-meta-nav-item-font-transform:            normal !default;

// Footer social media
$footer-social-media-spacing-vertical:           $GLOBAL-SPACING-UNIT-SMALL !default;
$footer-social-media-item-spacing:               $GLOBAL-SPACING-UNIT !default;
$footer-social-media-icon-variant:               null !default;
$footer-social-media-icon-background:            $COLOR-HIGHLIGHT !default;
$footer-social-media-icon-size:                  "medium" !default;
$footer-social-media-icon-color:                 $footer-text-color !default;
$footer-social-media-icon-color-hover:           $footer-nav-item-text-color !default;
$footer-social-media-icon-decoration:            none !default;

// Footer copyright
$footer-copyright-font-size:                     $FS-1 !default;
$footer-copyright-font-weight:                   inherit !default;
$footer-copyright-text-color:                    $footer-text-color !default;
$footer-copyright-text-transform:                normal !default;
$footer-copyright-sign-spacing:                  0.25em !default;
$footer-copyright-justify-content:               null !default;
$footer-copyright-justify-content-at-breakpoint: null !default;

// Footer footnotes
$footer-footnotes-background-color:              $footer-background-color !default;
$footer-footnotes-spacing-top:                   $GLOBAL-SPACING-UNIT-SMALL !default;
$footer-footnotes-border-top:                    1px solid $COLOR-BASE !default;
$footer-footnotes-font-size:                     $FS-2 !default;
$footer-footnotes-font-weight:                   null !default;
$footer-footnotes-text-color:                    $footer-text-color !default;
$footer-footnotes-link-color:                    inherit !default;
$footer-footnotes-link-color-hover:              inherit !default;

/**
 * [1] If no breadcrumb is included in the footer, hide the container.
 * [2] If the meta nav has a deviating background color, assign it.
 * [3] If the footnotes have a deviating background color, assign it.
 * [4] Manage automatic vertical spacing between nav columns when stacked.
 * [5] Responsive behaviour without media-queries, i.e. don’t let the columns
 *     shrink under a width of `$footer-nav-column-min-width`...
 * [6] ...but force a one-column layout at breakpoint and below.
 * [7] Only hide the accordion panel when the JS is loaded.
 * [8] Make sure to just provide the whole accordion-on-small-screen
 *     shizzle-dizzle, when JS is loaded.
 * [9] Reset potential browser default margin for certain HTML elements and
 *     instead assign the bottom spacing to `.c-footer-nav__title`.
 * [10] Make sure that the presented HTML elements make sense for accessibility.
 *      The `.is-heading`/`.is-button` classes are added dynamically via JS, so
 *      with this progressive mechanism we make sure that this all happens just
 *      when JS is loaded.
 * [11] Align meta bar content at the top edge of the flex container.
 * [12] If the meta nav has a deviating background color, we need to add a
 *      padding to the top to give it some space to the footer nav.
 * [13] If the footer nav has a border, we need to add a padding to the top to
 *      give it some space to the footer nav.
 * [14] If the meta nav is the first element inside the footer, it needs a
 *      top padding.
 * [15] If the meta nav is the last element inside the footer, we set the
 *      padding bottom equal to the superior footer padding-bottom.
 * [16] If the meta nav is the only child of the footer, we want the same
 *      padding at the top and the bottom.
 * [17] If the meta nav is the direct following element of the breadcrumb
 *      (i.e. the footer nav is missing), we need to offset the meta nav from
 *      the breadcrumb.
 * [18] If the breadcrumb is hidden, so the meta nav is _visually_ the first
 *      element in the footer, replace the margin with a padding, so the meta
 *      nav items are not stuck at the top of the footer.
 * [19] Enable meta nav bar to occupy relatively more space if necessary.
 * [20] Ensure that social media link spans and thus is clickable over the whole
 *      size of the social media icon.
 * [21] Align sign and text (media-object like).
 * [22] Enforce line break for '.c-footer-copyright' and ensure that full width
 *      of the container is used.
 * [23] Reset potential browser default margin for social media element and links.
 * [24] Ensure wrapping (column layout).
 * [25] Check if the icon should be rendered as either a round or square variant.
 * [26] Prevent doubling the spacing when the breadcrumb is followed by the meta nav.
 */
.c-footer {
    background-color: $footer-background-color;
    color: $footer-text-color;
}

    .c-footer__module {

        @if (not $footer-breadcrumb) {
            &.c-footer__module--breadcrumb {
                display: none; /* [1] */
            }
        }

        @if ($footer-meta-nav-background-color != $footer-background-color) {
            &.c-footer__module--meta-nav {
                background-color: $footer-meta-nav-background-color; /* [2] */
            }
        }

        @if ($footer-breadcrumb-background-color != $footer-background-color) {
            &.c-footer__module--breadcrumb {
                background-color: $footer-breadcrumb-background-color; /* [2] */
            }
        }

        @if ($footer-footnotes-background-color != $footer-background-color) {
            &.c-footer__module--footnotes {
                background-color: $footer-footnotes-background-color; /* [3] */
            }
        }

    }



.c-footer-breadcrumb {
    padding-top: $footer-breadcrumb-spacing-vertical;
    padding-bottom: $footer-breadcrumb-spacing-vertical;
    @if ($footer-breadcrumb-border-bottom != 0) {
        border-bottom: $footer-breadcrumb-border-bottom;
    }
}



.c-footer-nav {
    @include responsive-property(
        ("padding-top", $footer-nav-spacing-vertical),
        ("padding-bottom", $footer-nav-spacing-vertical)
    );
    @if ($footer-nav-border-bottom != 0) {
        border-bottom: $footer-nav-border-bottom;
    }

}

    .c-footer-nav__columns {
        display: flex;
        flex-wrap: wrap;
        margin-top: -($footer-nav-column-spacing-bottom); /* [4] */
    }

    .c-footer-nav__column {
        flex-grow: 1; /* [5] */
        flex-shrink: 1; /* [5] */
        flex-basis: $footer-nav-column-min-width; /* [5] */
        margin-top: $footer-nav-column-spacing-bottom; /* [4] */
        margin-right: $footer-nav-column-gap-horizontal;

        @include media-query("<=#{$footer-nav-breakpoint}") {
            flex-basis: 100%; /* [6] */
            margin-right: 0;
        }

    }

    .c-footer-nav__list {
        @include list-bare();

        @if ($footer-nav-accordion-enabled) {

            @include media-query("<=#{$footer-nav-breakpoint}") {
                &.js-is-ready.is-closed {
                    display: none; /* [7] */
                }
            }

        }

    }

    .c-footer-nav__title {
        @include responsive-property("margin-bottom", $footer-nav-title-spacing-bottom);

        @if ($footer-nav-accordion-enabled) {

            @include media-query("<=#{$footer-nav-breakpoint}") {

                /* stylelint-disable selector-max-specificity, selector-max-class */
                .js-footer-accordion.js-is-ready & { /* [8] */
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;

                    // @TODO: rotation (BRON-2969)
                    &::after {
                        @include icon-font();
                        content: $footer-nav-accordion-icon-expand;
                        font-size: $footer-nav-accordion-icon-size;
                        color: $footer-nav-accordion-icon-color;
                    }

                    &.is-active {

                        &::after {
                            content: $footer-nav-accordion-icon-collapse;
                        }

                    }

                }
                /* stylelint-enable */

            }

        }

    }

    .c-footer-nav__title-label {
        @include responsive-font-size(
            $font-size:   $footer-nav-title-font-size,
            $line-height: auto
        );
        @include reset-button();
        margin-bottom: 0; /* [9] */
        font-family: $footer-nav-item-font-family;
        font-weight: $footer-nav-title-font-weight;
        color: $footer-nav-title-text-color;

        @include media-query("<=#{$footer-nav-breakpoint}") {

            &.is-heading {
                display: none; /* [10] */
            }

        }

        @include media-query(">#{$footer-nav-breakpoint}") {

            &.is-button {
                display: none; /* [10] */
            }

        }

    }

    .c-footer-nav__item {
        @if ($footer-nav-item-padding-vertical != 0) {
            padding-top: $footer-nav-item-padding-vertical;
            padding-bottom: $footer-nav-item-padding-vertical;
        }
        @if ($footer-nav-item-padding-vertical-at-breakpoint != 0) {
            @include media-query("<=#{$footer-nav-breakpoint}") {
                padding-top: $footer-nav-item-padding-vertical-at-breakpoint;
                padding-bottom: $footer-nav-item-padding-vertical-at-breakpoint;
            }
        }
        @if ($footer-nav-item-border-bottom != 0) {
            &:not(:last-child) {
                border-bottom: $footer-nav-item-border-bottom;
            }
        }
    }

    .c-footer-nav__link {
        @include responsive-font-size(
            $font-size:   $footer-nav-item-font-size,
            $line-height: auto
        );
        font-weight: $footer-nav-item-font-weight;
        text-decoration: $footer-nav-item-text-decoration;
        color: $footer-nav-item-text-color;

        &:hover,
        &:focus,
        &:active {
            color: $footer-nav-item-text-color-hover;
        }

    }



.c-footer-meta-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start; /* [11] */
    padding-bottom: $footer-meta-nav-spacing-vertical;

    @if ($footer-meta-nav-background-color != $footer-background-color) {
        padding-top: $footer-meta-nav-spacing-vertical; /* [12] */
    }
    @if ($footer-nav-border-bottom != 0) {
        padding-top: $footer-meta-nav-spacing-vertical; /* [13] */
    }

    @include media-query("<=#{$footer-meta-nav-breakpoint}") {

        @if ($footer-meta-nav-border-top-at-breakpoint != 0) {
            padding-top: $footer-meta-nav-spacing-vertical-at-breakpoint;
            padding-bottom: $footer-meta-nav-spacing-vertical-at-breakpoint;

            .c-footer__module--nav + .c-footer__module--meta-nav & {
                border-top: $footer-meta-nav-border-top-at-breakpoint;
            }

            :not(.c-footer__module--nav) + .c-footer__module--meta-nav.c-footer__module--meta-nav & {
                margin-top: 0;
            }

        }

        > * {
            flex-basis: 100%; /* [24] */
        }

    }

    .c-footer__module--meta-nav:first-child & {
        padding-top: $footer-meta-nav-spacing-vertical; /* [14] */
    }

    .c-footer__module--meta-nav:last-child & {
        padding-bottom: $footer-spacing-bottom; /* [15] */
    }

    .c-footer__module--meta-nav:last-child:only-child & {
        padding-bottom: $footer-meta-nav-spacing-vertical; /* [16] */
    }

    .c-footer__module--breadcrumb + .c-footer__module--meta-nav & {
        @if ($footer-breadcrumb) {
            margin-top: $footer-meta-nav-spacing-vertical; /* [17] */
            padding-top: 0; /* [26] */
        } @else {
            margin-top: 0;
            padding-top: $footer-meta-nav-spacing-vertical; /* [18] */
        }
    }

}



.c-footer-meta-nav {
    @include list-bare();
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1; /* [19] */
    flex-shrink: 1;
    flex-basis: auto;
    padding-bottom: $footer-social-media-spacing-vertical;

    @if ($footer-meta-nav-collapse-at-breakpoint) {
        @include media-query("<=#{$footer-meta-nav-breakpoint}") {
            flex-direction: column;
        }
    }

}

    .c-footer-meta-nav__item {

        &:not(:last-child) {

            @include media-query(">#{$footer-meta-nav-breakpoint}") {
                margin-right: if($footer-meta-nav-item-spacing-horizontal, $footer-meta-nav-item-spacing-horizontal, null);
            }

            @if ($footer-meta-nav-item-delimiter) {

                @include media-query(">#{$footer-meta-nav-breakpoint}") {
                    &::after {
                        content: $footer-meta-nav-item-delimiter;
                        margin-left: $footer-meta-nav-item-spacing-horizontal;
                        color: $footer-meta-nav-item-delimiter-color;
                    }
                }
            }

        }

    }

    .c-footer-meta-nav__link {
        @include responsive-font-size(
            $font-size:   $footer-meta-nav-item-font-size,
            $line-height: auto
        );
        @if (not $footer-meta-nav-item-delimiter) {
            display: block;
        }
        text-decoration: $footer-meta-nav-item-text-decoration;
        color: $footer-meta-nav-item-text-color;
        @if ($footer-meta-nav-item-font-weight != inherit) {
            font-weight: $footer-meta-nav-item-font-weight;
        }
        @if ($footer-meta-nav-item-font-transform != normal) {
            text-transform: $footer-meta-nav-item-font-transform;
        }

        &:hover,
        &:focus,
        &:active {
            color: $footer-meta-nav-item-text-color-hover;
        }

    }

.c-footer-social-media {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    column-gap: $footer-social-media-item-spacing; /* [27] */
    margin: 0; /* [23] */
    padding-bottom: $footer-social-media-spacing-vertical;
    list-style: none;

    @include media-query("<=#{$footer-nav-breakpoint}") {
        justify-content: center;
    }

}

    .c-footer-social-media__item {
        margin-bottom: 0; /* [23] */
    }

    .c-footer-social-media__link {
        display: flex; /* [20] */
    }

    .c-footer-social-media__icon {

        @if ($footer-social-media-icon-variant) { /* [25] */
            @include icon-variant(
                $variant:          $footer-social-media-icon-variant,
                $size:             $footer-social-media-icon-size,
                $text-color:       $footer-social-media-icon-color,
                $background-color: $footer-social-media-icon-background,
            );

            &:hover,
            &:focus,
            &:active {
                color: $footer-social-media-icon-color;
            }
        } @else {
            font-size: icon-size($footer-social-media-icon-size);
            line-height: 1;
            text-decoration: $footer-social-media-icon-decoration;
            color: $footer-social-media-icon-color;

            &:hover,
            &:focus,
            &:active {
                color: $footer-social-media-icon-color-hover;
            }

        }

    }

.c-footer-copyright {
    @include responsive-font-size(
        $font-size:   $footer-copyright-font-size
    );

    display: inline-flex; /* [21] */
    justify-content: $footer-copyright-justify-content;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 100%; /* [22] */

    @if ($footer-copyright-text-color != $footer-text-color) {
        color: $footer-copyright-text-color;
    }
    @if ($footer-copyright-font-weight != inherit) {
        font-weight: $footer-copyright-font-weight;
    }
    @if ($footer-copyright-text-transform != normal) {
        text-transform: $footer-copyright-text-transform;
    }

    @include media-query("<=#{$footer-nav-breakpoint}") {
        justify-content: $footer-copyright-justify-content-at-breakpoint;
    }
}

    .c-footer-copyright__sign {
        margin-right: $footer-copyright-sign-spacing;
    }



.c-footer-footnotes {
    @include responsive-font-size($footer-footnotes-font-size);
    padding-top: $footer-footnotes-spacing-top;
    padding-bottom: $footer-spacing-bottom;
    font-weight: $footer-footnotes-font-weight;
    @if ($footer-footnotes-border-top != 0) {
        border-top: $footer-footnotes-border-top;
    }
    @if ($footer-footnotes-text-color != $footer-text-color) {
        color: $footer-footnotes-text-color;
    }

    a {
        color: $footer-footnotes-link-color;

        &:hover,
        &:focus,
        &:active {
            color: $footer-footnotes-link-color-hover;
        }

    }

}
