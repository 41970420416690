// /* ------------------------------------*\
//     #PRODUCT-CARD (Settings)
// \*------------------------------------ */

$product-card-padding:                    $CARD-PATTERN-PADDING !default;
$product-card-shadow:                     false !default;
$product-card-background-color:           $CARD-PATTERN-BACKGROUND-COLOR !default;
$product-card-border:                     $CARD-PATTERN-BORDER !default;
$product-card-border-radius:              $CARD-PATTERN-BORDER-RADIUS !default;

$product-card-header-module-spacing:      $GLOBAL-SPACING-UNIT-SMALL !default;

$product-card-title-size:                 $CARD-PATTERN-TITLE-SIZE !default;
$product-card-title-font-weight:          $CARD-PATTERN-TITLE-WEIGHT !default;

$product-card-subtitle-size:              $CARD-PATTERN-SUBTITLE-SIZE !default;
$product-card-subtitle-font-weight:       $CARD-PATTERN-SUBTITLE-WEIGHT !default;

$product-card-tag-list-title-size:        $FS0 !default;
$product-card-tag-list-title-font-weight: $CARD-PATTERN-TITLE-WEIGHT !default;

$product-card-inactive-opacity:           1 !default; // handle via overlay
