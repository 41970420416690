/* ------------------------------------*\
    #SPINNER
\*------------------------------------ */

@use "sass:math" as *;

// `$spinner-style` can be either `dots`, `circle`, `stripe`, `logo` or `flashing dots`.
$spinner-style:                 circle !default;
$spinner-size:                  80px !default;
$spinner-size-small:            40px !default;
$spinner-duration:              1s !default;
$spinner-timing:                linear !default;
$spinner-z-index:               z("spinner") !default;
$spinner-backdrop-z-index:      $spinner-z-index - 1 !default;
$spinner-backdrop-color:        $GLOBAL-BACKDROP-BACKGROUND !default;
$spinner-backdrop-filter:       null !default;
$spinner-shadow:                none !default;

// Just relevant for the `circle` variant.
$spinner-border-radius:         50% !default;
$spinner-border-width:          3px !default;
$spinner-border-width-small:    2px !default;
$spinner-border-style:          solid !default;
$spinner-border-color:          $COLOR-SUBTLE !default;
$spinner-border-color-active:   $COLOR-BRAND !default;
$spinner-border:                $spinner-border-width $spinner-border-style $spinner-border-color !default;

// Just relevant for the `dots` variant.
$spinner-steps:                 8 !default;
$spinner-dot-size:              12px !default;
$spinner-dot-size-small:        6px !default;
$spinner-dot-radius:            50% !default;
$spinner-dot-color:             $COLOR-BRAND !default;
$spinner-dot-opacity-default:   0.2 !default;
$spinner-dot-opacity-full:      1 !default;

// Just relevant for the `stripe` variant.
$spinner-stripe-length:         54px !default;
$spinner-stripe-length-small:   5px !default;
$spinner-stripe-breadth:        18px !default;
$spinner-stripe-breadth-small:  2px !default;
$spinner-stripe-radius:         9px !default;
$spinner-stripe-color-default:  $COLOR-SUBTLE !default;
$spinner-stripe-color-active:   $COLOR-BRAND !default;

// Just relevant for the `text` variant.
$spinner-text-padding:          $GLOBAL-SPACING-UNIT !default;
$spinner-text-margin:           $GLOBAL-SPACING-UNIT-SMALL !default;
$spinner-text-background-color: $COLOR-WHITE !default;
$spinner-text-color:            $COLOR-BRAND !default;
$spinner-text-font-size:        $FS0 !default;
$spinner-text-border-radius:    null !default;

$spinner-text-content-widths: (
    null: percentage(div(4, 12)),
    l:    percentage(div(6, 12)),
    m:    percentage(div(8, 12)),
    s:    calc(100vw - #{$GLOBAL-SPACING-UNIT-XLARGE}),
) !default;

// Control the visibility of the spinner via this attribute in the markup.
$spinner-loading-selector:      "[aria-busy='true']" !default;

/**
 * [1] Magic number that places the ‘corner’ dots/stripe correctly to form a
 *     perfect circle. If someone can come up with the correct formula of
 *     calculating this properly...you're a freak! But it’ll be nice,
 *     nonetheless.
 * [2] Use an `inset` box shadow, so that the outer border (this box-shadow one)
 *     and the overlaying inner border of the pseudo element are matching
 *     exactly.
 * [3] Set transition to fade in/out the spinner when it is shown/hidden
 *     dynamically.
 * [4] Take up the size from the parent (`.c-spinner__loader`).
 * [5] Create a Pac-Man shape by omitting the bottom border color.
 * [6] Place the spinner of the full-page, text and section variant precisely
 *     in the center of the backdrop.
 * [7] Remove margins from any element inside the text container.
 */
.c-spinner {

    &.c-spinner--full-page,
    &.c-spinner--section,
    &.c-spinner--text {
        position: relative;

        // The backdrop.
        &::before {
            @include backdrop(
                $color:    $spinner-backdrop-color,
                $position: absolute,
                $filter:   $spinner-backdrop-filter,
            );
            @include transition();
            content: "";
            z-index: $spinner-backdrop-z-index;
            opacity: 0;
            visibility: hidden;
        }

        &#{$spinner-loading-selector} {

            &::before {
                opacity: 1;
                visibility: visible;
            }

        }

    }

    &.c-spinner--full-page,
    &.c-spinner--text {

        &::before {
            position: fixed;
        }

    }

    &.c-spinner--center {
        display: flex;
        justify-content: center;
    }

    &.c-spinner--text {
        text-align: center;
    }

}

    .c-spinner__loader {
        @include transition(); /* [3] */
        position: relative;
        z-index: $spinner-z-index;
        display: none;
        width: $spinner-size;
        height: $spinner-size;

        @if ($spinner-style == circle) {

            // The spinner circle itself.
            &::before {
                content: "";
                position: absolute; /* [4] */
                top: 0; /* [4] */
                left: 0; /* [4] */
                display: block;
                width: $spinner-size; /* [4] */
                height: $spinner-size; /* [4] */
                border-radius: $spinner-border-radius;
                box-shadow: 0 0 0 $spinner-border-width $spinner-border-color inset; /* [2] */

                .c-spinner--small & {
                    width: $spinner-size-small;
                    height: $spinner-size-small;
                    box-shadow: 0 0 0 $spinner-border-width-small $spinner-border-color inset; /* [2] */
                }

            }

            // The overlaying border of the circle.
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: $spinner-size;
                height: $spinner-size;
                border: $spinner-border-width $spinner-border-style $spinner-border-color-active;
                border-radius: $spinner-border-radius;
                animation-name: spinner-circle-animation;
                animation-duration: $spinner-duration;
                animation-timing-function: $spinner-timing;
                animation-iteration-count: infinite;
                border-bottom-color: transparent; /* [5] */

                .c-spinner--small & {
                    width: $spinner-size-small;
                    height: $spinner-size-small;
                    border-width: $spinner-border-width-small;
                }

            }

        }

        .c-spinner#{$spinner-loading-selector} & {
            display: block;
        }

        .c-spinner--small & {
            width: $spinner-size-small;
            height: $spinner-size-small;
        }

        .c-spinner--section &,
        .c-spinner--full-page:not(.c-spinner--text) & {
            position: absolute;
            top: 50%; /* [6] */
            left: 50%; /* [6] */
            transform: translate(-50%, -50%); /* [6] */
        }

        .c-spinner--full-page:not(.c-spinner--text) & {
            position: fixed;
        }

        .c-spinner--text & {
            margin-right: auto;
            margin-left: auto;
        }

    }

    .c-spinner__content {
        @include responsive-property("width", $spinner-text-content-widths);
        position: fixed;
        z-index: $spinner-z-index;
        top: 50%; /* [6] */
        left: 50%; /* [6] */
        display: none;
        padding: $spinner-text-padding;
        transform: translate(-50%, -50%); /* [6] */
        border-radius: $spinner-text-border-radius;
        background-color: $spinner-text-background-color;
        box-shadow: $spinner-shadow;

        .c-spinner#{$spinner-loading-selector} & {
            display: block;
        }

    }

    .c-spinner__text {
        @include responsive-font-size($spinner-text-font-size);
        display: inline-block;
        padding-top: $spinner-text-margin;
        padding-right: $spinner-text-padding;
        padding-left: $spinner-text-padding;
        color: $spinner-text-color;

        * {
            margin: 0; /* [7] */
        }

    }

    .c-spinner__element {

        @if ($spinner-style == dots) {
            position: absolute;
            display: block;
            width: $spinner-dot-size;
            height: $spinner-dot-size;
            border-radius: $spinner-dot-radius;
            background-color: $spinner-dot-color;
            animation-name: spinner-dots-animation;
            animation-duration: $spinner-duration;
            animation-iteration-count: infinite;
            opacity: $spinner-dot-opacity-default;

            .c-spinner.c-spinner--small & {
                width: $spinner-dot-size-small;
                height: $spinner-dot-size-small;
            }

            // Calculate the animation delay for each dot, so that when
            // the duration should change, we don’t have to manually
            // calculate the new delay values again.
            //
            // Iterate through all dots.
            @for $i from 1 through $spinner-steps {

                // Create the `:nth-child` selector for each dot accordingly.
                &:nth-child(#{$i}) {
                    // Calculate the timing offset between each dot (duration / steps)
                    // and multiply this with the number of the current step.
                    animation-delay: div($spinner-duration, $spinner-steps) * ($i);
                }

            }

            // Each dot is placed individually `absolute` in the `.c-spinner`
            // container.
            &:nth-child(1) {
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }

            &:nth-child(2) {
                top: 11.5%; /* [1] */
                right: 11.5%; /* [1] */
            }

            &:nth-child(3) {
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }

            &:nth-child(4) {
                right: 11.5%; /* [1] */
                bottom: 11.5%; /* [1] */
            }

            &:nth-child(5) {
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }

            &:nth-child(6) {
                bottom: 11.5%; /* [1] */
                left: 11.5%; /* [1] */
            }

            &:nth-child(7) {
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }

            &:nth-child(8) {
                top: 11.5%; /* [1] */
                left: 11.5%; /* [1] */
            }

        } @else if ($spinner-style == stripe) {
            position: absolute;
            display: block;
            width: $spinner-stripe-breadth;
            height: $spinner-stripe-length;
            border-radius: $spinner-stripe-radius;
            background-color: $spinner-stripe-color-default;
            animation-name: spinner-stripe-animation;
            animation-duration: $spinner-duration;
            animation-timing-function: $spinner-timing;
            animation-iteration-count: infinite;

            .c-spinner.c-spinner--small & {
                width: $spinner-stripe-breadth-small;
                height: $spinner-stripe-length-small;
            }

            // Calculate the animation delay for each stripe, so that when
            // the duration should change, we don’t have to manually
            // calculate the new delay values again.
            //
            // Iterate through all stripe.
            @for $i from 1 through $spinner-steps {

                // Create the `:nth-child` selector for each stripe accordingly.
                &:nth-child(#{$i}) {
                    // Calculate the timing offset between each stripe (duration / steps)
                    // and multiply this with the number of the current step.
                    animation-delay: div($spinner-duration, $spinner-steps) * ($i);
                }

            }

            // Each stripe is placed individually `absolute` in the `.c-spinner`
            // container.
            &:nth-child(1) {
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }

            &:nth-child(2) {
                top: 11.5%; /* [1] */
                right: 17.5%; /* [1] */
                transform: rotate(0.125turn);
            }

            &:nth-child(3) {
                top: 50%;
                right: 0;
                transform: translate(-($spinner-stripe-breadth *0.5), -(($spinner-stripe-length * 0.5) + ($spinner-stripe-breadth *0.5))) rotate(0.25turn);
                transform-origin: left;

                /* stylelint-disable-next-line selector-max-specificity */
                .c-spinner.c-spinner--small & {
                    transform: translate(-($spinner-stripe-breadth-small *0.5), -(($spinner-stripe-length-small * 0.5) + ($spinner-stripe-breadth-small *0.5))) rotate(0.25turn);
                }

            }

            &:nth-child(4) {
                right: 17.5%; /* [1] */
                bottom: 11.5%; /* [1] */
                transform: rotate(0.375turn);
            }

            &:nth-child(5) {
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }

            &:nth-child(6) {
                bottom: 11.5%; /* [1] */
                left: 17.5%; /* [1] */
                transform: rotate(0.625turn);
            }

            &:nth-child(7) {
                top: 50%;
                left: 0;
                transform: translate(($spinner-stripe-breadth *0.5), -(($spinner-stripe-length * 0.5) + ($spinner-stripe-breadth *0.5))) rotate(0.75turn);
                transform-origin: right;

                /* stylelint-disable-next-line selector-max-specificity */
                .c-spinner.c-spinner--small & {
                    transform: translate(($spinner-stripe-breadth-small *0.5), -(($spinner-stripe-length-small * 0.5) + ($spinner-stripe-breadth-small *0.5))) rotate(0.75turn);
                }

            }

            &:nth-child(8) {
                top: 11.5%; /* [1] */
                left: 17.5%; /* [1] */
                transform: rotate(0.875turn);
            }

        } @else {
            display: none;
        }

    }

// The animation for the `circle` variant.
@keyframes spinner-circle-animation {

    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }

}

// The animation for the `dots` variant.
@keyframes spinner-dots-animation {

    0% {
        opacity: $spinner-dot-opacity-default;
    }

    50% {
        opacity: $spinner-dot-opacity-full;
    }

    100% {
        opacity: $spinner-dot-opacity-default;
    }

}

// The animation for the `stripe` variant.
@keyframes spinner-stripe-animation {

    0% {
        background-color: $spinner-stripe-color-default;
    }

    12.5% {
        background-color: $spinner-stripe-color-active;
    }

    25% {
        background-color: $spinner-stripe-color-default;
    }

}
