/* ------------------------------------*\
    #PRODUCT-SEARCH-FILTER (Scopes)
\*------------------------------------ */

$scope-button-group-item-min-width: 122px !default;

/**
 * [1] Remove horizontal padding for accordions inside product search filters.
 * [2] Decrease button group item width inside product search filters.
 */

.c-product-search-filter {

    .c-accordion__panel {
        --accordion-panel-padding-left: 0px; /* [1] */ // stylelint-disable-line length-zero-no-unit
        --accordion-panel-padding-right: 0px; /* [1] */ // stylelint-disable-line length-zero-no-unit
    }

    .c-button-group {
        grid-template-columns: repeat(auto-fit, minmax($scope-button-group-item-min-width, 1fr)); /* [2] */

        &.c-button-group--auto {
            /* autoprefixer: ignore next */
            grid-template-columns: repeat(auto-fill, minmax($scope-button-group-item-min-width, 1fr)); /* [2] */
        }

    }

}
