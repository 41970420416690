// /* ------------------------------------*\
//     #TABS (Settings)
// \*------------------------------------ */

$tabs-nav-bar-border-exceeds:          false !default;
$tabs-nav-border-width-active:         3px !default;
$tabs-nav-border-color:                $COLOR-BASE !default;
$tabs-nav-border-width-correction:     0 !default;

$tabs-nav-item-spacing:                $GLOBAL-SPACING-UNIT !default;
$tabs-nav-item-spacing-vertical:       $GLOBAL-SPACING-UNIT-SMALL !default;

$tabs-nav-item-font-weight:            $GLOBAL-FONT-WEIGHT-BOLD !default;
$tabs-nav-item-text-size:              $FS0 !default;
$tabs-nav-item-text-color:             $COLOR-ON-SURFACE-700 !default;
$tabs-nav-item-text-color-active:      $COLOR-BASE-TEXT !default;
$tabs-nav-item-text-color-disabled:    $COLOR-INACTIVE !default;

$tabs-icon-size:                       icon-size(xsmall) !default;
$tabs-icon-stacked-size:               $tabs-icon-size !default;
$tabs-icon-only-size:                  icon-size(medium) !default;

$tabs-nav-item-background-color-hover: false !default;
