/* ------------------------------------*\
    #LISTS
\*------------------------------------ */

/* stylelint-disable selector-max-combinators, selector-max-compound-selectors, selector-max-type */

$list-vertical-spacing:          $GLOBAL-SPACING-UNIT-SMALL !default;
$list-vertical-spacing-small:    $GLOBAL-SPACING-UNIT-XSMALL !default;
$list-ordered-horizontal-offset: false !default;
$list-nested-vertical-spacing:   0 !default;

/**
 * [1] Second level lists have a smaller spacing on top and
 *     a normal one at the bottom.
 * [2] Third level lists have a smaller spacing on top and
 *     at the bottom.
 * [3] Offset by one character to align container with 2 digit index
 *     and prevent cut-off.
 *     This depends on the font, font-size and global-spacing, so a flag is used
 *     to selectively set it per brand.
 */
ul,
ol {

    li {
        margin-bottom: $list-vertical-spacing;

        > ul,
        > ol {
            margin-top: $list-vertical-spacing-small; /* [1] */
            margin-bottom: $list-vertical-spacing; /* [1] */

            li {
                margin-bottom: $list-nested-vertical-spacing;
            }

            li > ul {
                margin-bottom: $list-vertical-spacing-small; /* [2] */
            }

        }

    }

}
/* stylelint-enable */

ol {
    @if ($list-ordered-horizontal-offset) {
        margin-left: calc(#{$GLOBAL-SPACING-UNIT} + 1ch); /* [3] */
    }
}
