/* ------------------------------------*\
    #FONT-SIZES
\*------------------------------------ */

/**
 * Utility classes to enforce font sizes.
 */

$text-size-fs7:              null !default;
$text-size-fs7-line-height:  null !default;
$text-size-fs6:              $FS6 !default;
$text-size-fs6-line-height:  null !default;
$text-size-fs5:              $FS5 !default;
$text-size-fs5-line-height:  null !default;
$text-size-fs4:              $FS4 !default;
$text-size-fs4-line-height:  null !default;
$text-size-fs3:              $FS3 !default;
$text-size-fs3-line-height:  null !default;
$text-size-fs2:              $FS2 !default;
$text-size-fs2-line-height:  null !default;
$text-size-fs1:              $FS1 !default;
$text-size-fs1-line-height:  null !default;
$text-size-fs0:              $FS0 !default;
$text-size-fs0-line-height:  null !default;
$text-size-fs-1:             $FS-1 !default;
$text-size-fs-1-line-height: null !default;
$text-size-fs-2:             $FS-2 !default;
$text-size-fs-2-line-height: null !default;
$text-size-fs-3:             $FS-3 !default;
$text-size-fs-3-line-height: null !default;

.u-font-size-fs7 {
    @if $text-size-fs7 {
        @include responsive-font-size(
            $font-size:   $text-size-fs7,
            $line-height: $text-size-fs7-line-height,
            $important:   true
        );
    }
}

.u-font-size-fs6 {
    @if $text-size-fs6 {
        @include responsive-font-size(
            $font-size:   $text-size-fs6,
            $line-height: $text-size-fs6-line-height,
            $important:   true
        );
    }
}

.u-font-size-fs5 {
    @if $text-size-fs5 {
        @include responsive-font-size(
            $font-size:   $text-size-fs5,
            $line-height: $text-size-fs5-line-height,
            $important:   true
        );
    }
}

.u-font-size-fs4 {
    @if $text-size-fs4 {
        @include responsive-font-size(
            $font-size:   $text-size-fs4,
            $line-height: $text-size-fs4-line-height,
            $important:   true
        );
    }
}

.u-font-size-fs3 {
    @if $text-size-fs3 {
        @include responsive-font-size(
            $font-size:   $text-size-fs3,
            $line-height: $text-size-fs3-line-height,
            $important:   true
        );
    }
}

.u-font-size-fs2 {
    @if $text-size-fs2 {
        @include responsive-font-size(
            $font-size:   $text-size-fs2,
            $line-height: $text-size-fs2-line-height,
            $important:   true
        );
    }
}

.u-font-size-fs1 {
    @if $text-size-fs1 {
        @include responsive-font-size(
            $font-size:   $text-size-fs1,
            $line-height: $text-size-fs1-line-height,
            $important:   true
        );
    }
}

.u-font-size-fs0 {
    @if $text-size-fs0 {
        @include responsive-font-size(
            $font-size:   $text-size-fs0,
            $line-height: $text-size-fs0-line-height,
            $important:   true
        );
    }
}

.u-font-size-fs-1 {
    @if $text-size-fs-1 {
        @include responsive-font-size(
            $font-size:   $text-size-fs-1,
            $line-height: $text-size-fs-1-line-height,
            $important:   true
        );
    }
}

.u-font-size-fs-2 {
    @if $text-size-fs-2 {
        @include responsive-font-size(
            $font-size:   $text-size-fs-2,
            $line-height: $text-size-fs-2-line-height,
            $important:   true
        );
    }
}

.u-font-size-fs-3 {
    @if $text-size-fs-3 {
        @include responsive-font-size(
            $font-size:   $text-size-fs-3,
            $line-height: $text-size-fs-3-line-height,
            $important:   true
        );
    }
}
