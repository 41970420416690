/* ------------------------------------*\
    #TAG
\*------------------------------------ */

$tag-text-size:          $GLOBAL-STATUS-TEXT-SIZE !default;
$tag-text-color:         $GLOBAL-STATUS-TEXT-COLOR !default;
$tag-text-transform:     null !default;
$tag-font-weight:        $GLOBAL-FONT-WEIGHT-REGULAR !default;
$tag-letter-spacing:     null !default;
$tag-background-color:   $GLOBAL-STATUS-BACKGROUND-COLOR !default;
$tag-padding-horizontal: $GLOBAL-SPACING-UNIT-XSMALL !default;
$tag-padding-vertical:   3px !default;
$tag-border-radius:      null !default;
$tag-border-width:       1px !default;
$tag-border-style:       null !default;
$tag-border-color:       null !default;

// Always set a border to ensure that all tags have the same height
// if no border-color is defined, we use the bg-color as border-color.
@if $tag-border-color == null {
    $tag-border-color:   $tag-background-color;
}

$tag-border:             $tag-border-width $tag-border-style $tag-border-color !default;

$tag-close-icon:         get-icon(semantic-close) !default;
$tag-close-icon-size:    icon-size(small) !default;
$tag-close-spacing:      $GLOBAL-SPACING-UNIT-XSMALL !default;

$tag-themes-overwrites: () !default;
$tag-themes:            $STATUS-THEMES-EXTENDED !default;
$tag-themes-merged:     map-deep-merge($tag-themes, $tag-themes-overwrites, 2) !default;

/**
 * [1] Vertically align close-icon.
 */
.c-tag {
    @include responsive-font-size($tag-text-size);
    display: inline-flex;
    align-items: center;
    padding: $tag-padding-vertical $tag-padding-horizontal;
    border: if($tag-border, $tag-border, null);
    background-color: $tag-background-color;
    color: $tag-text-color;

    @if $tag-border-radius {
        border-radius: $tag-border-radius;
    }

    @include vary($create: modifier, $loop: $tag-themes-merged) {
        $tag-border-color-modifier: vary-get("border");

        // Always set a border to ensure that all modifier tags have the same height
        // if no border-color is defined, we use the bg-color as border-color.
        @if $tag-border-color-modifier == null {
            $tag-border-color-modifier: vary-get("background-color");
        }

        border-color: $tag-border-color-modifier;
        background-color: vary-get("background-color");
        color: vary-get("text-color");
    }

}

    .c-tag__label {
        display: block;
        min-height: $tag-close-icon-size;
        font-weight: $tag-font-weight;
        text-transform: $tag-text-transform;
        line-height: $tag-close-icon-size;
        letter-spacing: $tag-letter-spacing;
    }

    .c-tag__close {
        @include reset-button();
        position: relative; /* [1] */
        display: inline-flex; /* [1] */
        height: $tag-close-icon-size;
        margin-left: $tag-close-spacing;
        color: inherit;
        cursor: pointer;

        &::before {
            @include icon-font();
            @include font-size(
                $font-size:   $tag-close-icon-size,
                $line-height: false
            );
            content: $tag-close-icon;
        }

    }
