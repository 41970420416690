/* ------------------------------------*\
    #SECTION-HEADINGS
\*------------------------------------ */

$section-headings-spacing-bottom:                  $GLOBAL-SPACING-UNIT-LARGE !default;
$section-headings-spacing-bottom-small:            $GLOBAL-SPACING-UNIT !default;
$section-headings-title-spacing:                   0 !default;
$section-headings-title-size:                      $FS4 !default;
$section-headings-title-size-primary:              $FS6 !default;
$section-heading-title-line-height:                $GLOBAL-HEADING-H3-LINE-HEIGHT !default;
$section-heading-title-line-height-primary:        $GLOBAL-HEADING-H1-LINE-HEIGHT !default;
$section-headings-title-font-weight:               $GLOBAL-FONT-WEIGHT-BOLD !default;
$section-headings-title-text-color:                $COLOR-BRAND !default;
$section-headings-subtitle-color:                  $COLOR-BASE-TEXT !default;
$section-headings-subtitle-size:                   $FS0 !default;
$section-headings-subtitle-spacing-vertical:       $GLOBAL-SPACING-UNIT !default;
$section-headings-subtitle-spacing-vertical-small: 0 !default;
$section-headings-icon-enabled:                    true !default;
$section-headings-icon-size:                       icon-size(medium) !default;
$section-headings-icon-spacing:                    $GLOBAL-SPACING-UNIT-SMALL !default;
$section-headings-icon-color:                      $COLOR-BRAND !default;
$section-headings-primary-border:                  null !default;

/**
 * [1] Wrap text and possible inline elements in `span`. Left empty here for
 *     documentation and prospective usage.
 * [2] Make sure other spacings do not apply.
 */
.c-section-heading {
    margin-bottom: $section-headings-spacing-bottom;

    &.c-section-heading--center {
        text-align: center;

        .c-section-heading__title {
            justify-content: center;
        }

    }

    &.c-section-heading--small-spacing { /* [1] */
        margin-bottom: $section-headings-spacing-bottom-small;

        .c-section-heading__subtitle {
            margin-top: $section-headings-subtitle-spacing-vertical-small;
        }

    }

}

    .c-section-heading__icon {
        @if $section-headings-icon-enabled {
            @include font-size(
                $font-size:   $section-headings-icon-size,
                $line-height: 1
            );
            margin-right: $section-headings-icon-spacing;
            color: $section-headings-icon-color;
        } @else {
            display: none;
        }
    }

    .c-section-heading__title {
        @include responsive-font-size($section-headings-title-size, $section-heading-title-line-height);
        margin-bottom: $section-headings-title-spacing;
        font-weight: $section-headings-title-font-weight;
        color: $section-headings-title-text-color;

        .c-section-heading--primary & {
            @include responsive-font-size($section-headings-title-size-primary, $section-heading-title-line-height-primary);
            margin-bottom: $section-headings-spacing-bottom-small;
            padding-bottom: $section-headings-spacing-bottom-small;
            border-bottom: $section-headings-primary-border;
        }

    }

    .c-section-heading__title-text { /* [1] */
    }

    .c-section-heading__subtitle {
        @include responsive-font-size($section-headings-subtitle-size);
        margin-bottom: 0; /* [2] */
        margin-top: $section-headings-subtitle-spacing-vertical;
        color: $section-headings-subtitle-color;
    }
