/* ------------------------------------*\
    #INDICATOR-DOT
\*------------------------------------ */

$indicator-dot-size:      $INDICATOR-DOT-SIZE !default;
$indicator-dot-offset:    $indicator-dot-size !default;
$indicator-dot-spacing:   $INDICATOR-DOT-SPACING !default;
$indicator-dot-color:     $INDICATOR-DOT-COLOR !default;
$indicator-dot-text-size: null !default;

// @TODO: Refactor _settings.status.scss and components (e. g. Indicator Dot,
//     Status Message, …) to use consistent and global rules to set status
//     text/icon colors.
/* stylelint-disable function-comma-newline-after */
$indicator-dot-themes: map-deep-merge($INDICATOR-DOT-THEMES, (
    "success": (
        "text-color": $COLOR-SUCCESS,
    ),
    "error": (
        "text-color": $COLOR-ALERT,
    ),
    "unread": (
        "icon-color": $COLOR-INFO,
        "text-color":       $GLOBAL-STATUS-TEXT-COLOR,
    ),
), 2) !default;
/* stylelint-enable function-comma-newline-after */

// Merge default and overwrite settings to avoid duplications
// and overwrite only necessary settings.
$indicator-dot-themes-overwrites: () !default;
$indicator-dot-themes-merged:     map-deep-merge($indicator-dot-themes, $indicator-dot-themes-overwrites, 2) !default;

/**
 * [1] Keep dot size, avoid distortion.
 * [2] Align in-line with first line. `baseline` creates an offset.
 */
.c-indicator-dot {
    display: inline-flex;
    align-items: flex-start; /* [2] */
}

    .c-indicator-dot__dot {
        flex-grow: 0; /* [1] */
        flex-shrink: 0; /* [1] */
        flex-basis: auto; /* [1] */
        width: $indicator-dot-size;
        height: $indicator-dot-size;
        margin-right: $indicator-dot-spacing;
        transform: if($indicator-dot-offset, translateY($indicator-dot-offset), null); /* [2] */
        border-radius: 50%;
        background-color: $indicator-dot-color;
    }

    button.c-indicator-dot__dot {
        @include reset-button(
            $background-color: null,
        );
    }

    .c-indicator-dot__label {
        @include responsive-font-size(
            $font-size:   $indicator-dot-text-size,
            $line-height: 1,
            $accept-null: true,
        );
    }

    @include vary($create: custom, $loop: $indicator-dot-themes-merged) {

        .c-indicator-dot--#{$vary-loop-entity} {

            .c-indicator-dot__dot {
                background-color: vary-get("icon-color");
            }

            .c-indicator-dot__label {
                color: vary-get("text-color");
            }

        }

    }
