/* ------------------------------------*\
    #MAIN-WRAPPER
\*------------------------------------ */

/**
 * [1] Stretch the content of the main selector to push
 *     down the footer, when the content is too shallow.
*/

.o-main {
    flex-grow: 1; /* [1] */
    flex-shrink: 1; /* [1] */
    flex-basis: auto; /* [1] */
}
