/* ------------------------------------*\
    #STICKY-CONTAINER
\*------------------------------------ */

$sticky-container-page-wrap:        true !default;
$sticky-container-max-height:       75vh !default;
$sticky-container-z-index:          z("sticky-container") !default;
$sticky-container-padding-vertical: $GLOBAL-SPACING-UNIT !default;
$sticky-container-background-color: $COLOR-WHITE !default;
$sticky-container-box-shadow:       $GLOBAL-BOX-SHADOW !default;
$sticky-container-border-bottom:    null !default;

$sticky-container-spacing:          null !default;
$sticky-container-spacing-small:    null !default;
$sticky-container-breakpoint:       null !default;

/**
 * [1] As the name suggests, the component is sticky. For browsers that don’t
 *     support `position: sticky`, make sure to provide a JS polyfill. By
 *     default, this is provided in Bronson via the stickyfill polyfill.
 *     @DEPRECATED
 * [2] Ensure that the user is not trapped in scrolling hell when the sticky
 *     container contains lot of content and the vertical space is limited (e.g.
 *     on small screen).
 * [3] Make sure that any potential spacing bottom is eliminated.
 */
.c-sticky-container {
    position: sticky; /* [1] */
    z-index: $sticky-container-z-index;
    top: 0; /* [1] */
    max-height: $sticky-container-max-height; /* [2] */
    overflow-y: auto; /* [2] */
    padding-top: $sticky-container-padding-vertical;
    padding-bottom: $sticky-container-padding-vertical;
    border-bottom: $sticky-container-border-bottom;
    background-color: $sticky-container-background-color;
    box-shadow: $sticky-container-box-shadow;
}

    .c-sticky-container__inner {
        @if ($sticky-container-page-wrap) {
            @include page-wrap();
        } @else {
            padding-left: $sticky-container-spacing;
            padding-right: $sticky-container-spacing;

            @include media-query("<=#{$sticky-container-breakpoint}") {
                padding-left: $sticky-container-spacing-small;
                padding-right: $sticky-container-spacing-small;
            }

        }
        width: 100%;

        > *:last-child {
            margin-bottom: 0; /* [3] */
        }

    }
