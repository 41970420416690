// /* ------------------------------------*\
//     #UPLOAD (Settings)
// \*------------------------------------ */

$upload-background:                                 $COLOR-SURFACE-PRIMARY !default;

$upload-border-width:                               1px !default;
$upload-border-width-mouseover:                     2px !default;
$upload-border-style:                               solid !default;
$upload-border-color:                               $COLOR-ON-SURFACE-500 !default;
$upload-border-color-hover:                         $COLOR-ON-SURFACE-900 !default;
$upload-border-color-mouseover:                     $COLOR-BRAND !default;
$upload-border-color-active:                        $COLOR-BRAND !default;
$upload-border-radius:                              $GLOBAL-BORDER-RADIUS-SMALL !default;

$upload-description-text-color:                     $COLOR-BASE-TEXT !default;
$upload-description-text-spacing-bottom:            0 !default;

$upload-description-text-supplementary-font-size:   $FS-1 !default;
$upload-description-text-supplementary-font-weight: $GLOBAL-FONT-WEIGHT-LIGHT !default;
$upload-description-text-supplementary-text-color:  $upload-description-text-color !default;

$upload-loader-text-size:                           $upload-description-text-supplementary-font-size !default;
$upload-loader-text-color:                          $upload-description-text-color !default;
$upload-loader-text-spacing-top:                    $GLOBAL-SPACING-UNIT-XSMALL !default;

$upload-icon-color:                                 $upload-description-text-color !default;

$upload-mouseover-icon:                             get-icon(semantic-file) !default;
$upload-mouseover-icon-color:                       $upload-icon-color !default;
$upload-mouseover-text-size:                        $upload-description-text-supplementary-font-size !default;
$upload-mouseover-font-weight:                      $GLOBAL-FONT-WEIGHT-LIGHT !default;
$upload-mouseover-text-color:                       $upload-description-text-color !default;

$upload-icon-spacing-bottom:                        $GLOBAL-SPACING-UNIT-XSMALL !default;

$upload-disabled-icon:                              get-icon(semantic-error) !default;
$upload-disabled-font-weight:                       $GLOBAL-FONT-WEIGHT-BOLD !default;
$upload-disabled-text-supplementary-text-size:      $upload-description-text-supplementary-font-size !default;
$upload-disabled-text-spacing-bottom:               $upload-description-text-spacing-bottom !default;
$upload-disabled-border-color:                      $COLOR-INACTIVE !default;
