/* ------------------------------------*\
    #PRODUCT-TABLE
\*------------------------------------ */

$product-table-breakpoint:                             s !default;

$product-table-title-font-size:                        $FS3 !default;
$product-table-title-font-weight:                      null !default;
$product-table-title-spacing-bottom:                   $GLOBAL-SPACING-UNIT !default;

$product-table-section-spacing-vertical:               $GLOBAL-SPACING-UNIT !default;
$product-table-section-spacing-vertical-at-breakpoint: $GLOBAL-SPACING-UNIT-SMALL !default;
$product-table-section-border-width:                   1px !default;
$product-table-section-border-style:                   solid !default;
$product-table-section-border-color:                   $COLOR-PALE !default;
$product-table-section-border:                         $product-table-section-border-width $product-table-section-border-style $product-table-section-border-color !default;
$product-table-section-border:                         if($product-table-section-border, $product-table-section-border, null); /* stylelint-disable-line scss/dollar-variable-default */

$product-table-item-term-comment-font-size:            $FS-2 !default;
$product-table-item-term-comment-font-weight:          $GLOBAL-FONT-WEIGHT-REGULAR !default;
$product-table-item-term-comment-text-color:           null !default;

$product-table-item-detail-comment-font-size:          $FS-2 !default;
$product-table-item-detail-comment-font-weight:        $GLOBAL-FONT-WEIGHT-REGULAR !default;
$product-table-item-detail-comment-text-color:         null !default;

$product-table-inner-title-spacing-top:                null !default;
$product-table-inner-title-spacing-bottom:             $GLOBAL-SPACING-UNIT-SMALL !default;
$product-table-inner-title-text-color:                 $COLOR-BASE-TEXT !default;
$product-table-inner-title-font-size:                  $FS-1 !default;
$product-table-inner-title-font-weight:                $GLOBAL-FONT-WEIGHT-BOLD !default;
$product-table-inner-title-alignment:                  center !default;

$product-table-summary-term-font-family:               null !default;
$product-table-summary-term-font-size:                 $FS3 !default;
$product-table-summary-term-font-weight:               $GLOBAL-FONT-WEIGHT-BOLD !default;
$product-table-summary-term-text-color:                null !default;

$product-table-summary-detail-font-family:             null !default;
$product-table-summary-detail-font-size:               $product-table-title-font-size !default;
$product-table-summary-detail-font-weight:             $GLOBAL-FONT-WEIGHT-BOLD !default;
$product-table-summary-detail-text-color:              null !default;
$product-table-summary-border-top-width:               null !default;
$product-table-summary-border-top-style:               null !default;
$product-table-summary-border-top-color:               null !default;

$product-table-information-font-size:                  null !default;
$product-table-information-font-weight:                $GLOBAL-FONT-WEIGHT-REGULAR !default;
$product-table-information-text-color:                 null !default;
$product-table-information-alignment:                  left !default;

$product-table-breakpoints: (
    s: 0,
    m: strip-unit(map-get($BREAKPOINTS, $product-table-breakpoint)),
) !default;

/**
 * [1] Set section spacing responsivly via container query based CSS custom
 *     property.
 *     The selector `.c-product-table` needs to be added to the
 *     `ContainerQuery` `componentSelector` in
 *     `packages/bronson-default/src/js/module/container-query-observer.js`
 *     @TODO: Maybe we could use _real_ container queries (or a polyfill),
 *            so no custom SCSS/JS hybrid solution is needed anymore.
 * [2] Remove bottom margin of the last child of a section.
 * [3] Add a horizontal border between sections at the half of the section spacing.
 */

.c-product-table {
    --container-widths: "#{cq-convert-data($product-table-breakpoints)}"; /* [1] */
    --product-table-section-spacing: #{$product-table-section-spacing-vertical-at-breakpoint * 2}; /* [1] */

    @include container($map: $product-table-breakpoints, $size: "m") {
        --product-table-section-spacing: #{$product-table-section-spacing-vertical * 2}; /* [1] */
    }

    display: flex; /* stylelint-disable-line order/order */
    flex-direction: column;
}

    .c-product-table__title {
        @include responsive-font-size($product-table-title-font-size);
        @include responsive-property("margin-bottom", $product-table-title-spacing-bottom);
        font-weight: $product-table-title-font-weight;
    }

    .c-product-table__section {
        position: relative;

        > :last-child {
            margin-bottom: 0; /* [2] */
        }

        + .c-product-table__section {
            margin-top: var(--product-table-section-spacing); /* [1] */

            &::before { /* [3] */
                content: "";
                position: absolute;
                top: calc(var(--product-table-section-spacing) / -2); /* [1] [3] */
                right: 0;
                left: 0;
                border-top: $product-table-section-border;
            }

        }

    }

    .c-product-table__item-detail-comment {
        @include responsive-font-size($product-table-item-detail-comment-font-size);
        display: block;
        font-weight: $product-table-item-detail-comment-font-weight;
        color: $product-table-item-detail-comment-text-color;
    }

    .c-product-table__item-term-comment {
        @include responsive-font-size($product-table-item-term-comment-font-size);
        display: block;
        font-weight: $product-table-item-term-comment-font-weight;
        color: $product-table-item-term-comment-text-color;
    }

    .c-product-table__inner-title {
        @include responsive-font-size($product-table-inner-title-font-size);
        margin-top: $product-table-inner-title-spacing-top;
        margin-bottom: $product-table-inner-title-spacing-bottom;
        font-weight: $product-table-inner-title-font-weight;
        text-align: $product-table-inner-title-alignment;
        color: $product-table-inner-title-text-color;
    }

    .c-product-table__section--summary { /* [4] */

        &::before {
            border-top-width: $product-table-summary-border-top-width;
            border-top-style: $product-table-summary-border-top-style;
            border-top-color: $product-table-summary-border-top-color;
        }

        .c-product-table__summary-term {
            @include responsive-font-size($product-table-summary-term-font-size);
            font-family: $product-table-summary-term-font-family;
            font-weight: $product-table-summary-term-font-weight;
            color: $product-table-summary-term-text-color;
        }

        .c-product-table__summary-detail {
            @include responsive-font-size($product-table-summary-detail-font-size);
            font-family: $product-table-summary-detail-font-family;
            font-weight: $product-table-summary-detail-font-weight;
            color: $product-table-summary-detail-text-color;
        }

        .c-product-table__information {
            @if ($product-table-information-font-size) {
                @include responsive-font-size($product-table-information-font-size);
            }
            font-weight: $product-table-information-font-weight;
            color: $product-table-information-text-color;
        }

        .c-product-table__information.c-product-table__information.c-product-table__information { /* stylelint-disable-line no-duplicate-selectors, selector-max-class */ /* [4] */
            text-align: $product-table-information-alignment;
        }

    }
