// /* ------------------------------------*\
//     #LOGO
// \*------------------------------------ */

$skoda-logo-focus-offset: $GLOBAL-FOCUS-OFFSET-LARGE !default;

.c-logo {

    &:focus-visible {
        @include focus-styles(
            $outline-offset: $skoda-logo-focus-offset,
        );
    }

}
