/* ------------------------------------*\
    #MEDIA
\*------------------------------------ */

/**
 * An adaption of the famous media object.
 * (http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/)
 *
 * We rewrite the original one with `display: flex` to be more flexible.
 */

$media-spacing:        $GLOBAL-SPACING-UNIT !default;
$media-spacing-at-s:   $media-spacing !default;
$media-spacing-small:  $GLOBAL-SPACING-UNIT-SMALL !default;
$media-spacing-large:  $GLOBAL-SPACING-UNIT-LARGE !default;
$media-spacing-xlarge: $GLOBAL-SPACING-UNIT-XLARGE !default;

/**
 * [1] Make sure the media body takes up all the available space it needs if
 *     its content is not that long.
 * [2] Fix to make text truncate possible inside of media body (needed for the
 *     contact list).
 */
.o-media {
    display: flex;
    flex-direction: row;

    &.o-media--reversed {
        flex-direction: row-reverse;
    }

    /**
     * Stack the image on top of the media content on small screen.
     */
    &.o-media--column\@s {
        @include media-query("<=s") {
            flex-direction: column;
        }
    }

    &.o-media--column-reverse\@s {
        @include media-query("<=s") {
            flex-direction: column-reverse;
        }
    }

}

    .o-media__img {
        flex-grow: 0;
        flex-shrink: 0;
        align-self: flex-start;
        margin-right: $media-spacing;

        .o-media--small & {
            margin-right: $media-spacing-small;
        }

        .o-media--large & {
            margin-right: $media-spacing-large;
        }

        .o-media--xlarge & {
            margin-right: $media-spacing-xlarge;
        }

        .o-media--reversed & {
            margin-right: 0;
            margin-left: $media-spacing;
        }

        .o-media--reversed.o-media--small & {
            margin-left: $media-spacing-small;
        }

        .o-media--reversed.o-media--large & {
            margin-left: $media-spacing-large;
        }

        .o-media--reversed.o-media--xlarge & {
            margin-left: $media-spacing-xlarge;
        }

        .o-media--middle & {
            align-self: center;
        }

        .o-media--bottom & {
            align-self: flex-end;
        }

        .o-media--column\@s & {
            @include media-query("<=s") {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: $media-spacing-at-s;
            }
        }

        .o-media--column-reverse\@s & {
            @include media-query("<=s") {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 0;
                margin-top: $media-spacing-at-s;
            }
        }

        .o-media--reversed.o-media--column\@s & {
            @include media-query("<=s") {
                margin-left: auto;
                margin-right: auto;
            }
        }

        .o-media--reversed.o-media--column-reverse\@s & {
            @include media-query("<=s") {
                margin-left: auto;
                margin-right: auto;
            }
        }

    }

    .o-media__body {
        flex-grow: 1; /* [1] */
        flex-shrink: 1;
        flex-basis: auto;
        min-width: 0; /* [2] */

        > *:last-child {
            margin-bottom: 0;
        }

        .o-media--middle & {
            align-self: center;
        }

        .o-media--bottom & {
            align-self: flex-end;
        }

        .o-media--column-reverse\@s & {
            @include media-query("<=s") {
                margin-bottom: $media-spacing-at-s;
            }
        }

    }
