/* ------------------------------------*\
    #BACKGROUND-COLORS
\*------------------------------------ */

@each $_alias, $_color in $BACKGROUND-UTILITIES {

    [data-theme="#{$_alias}"],
    .u-bg-#{$_alias} {
        background-color: $_color !important;
    }

}
