/* ------------------------------------*\
    #BADGE
\*------------------------------------ */

// @TODO: refactor to utilize global status settings
$badge-color-success:           $COLOR-SUCCESS !default;
$badge-color-warning:           $COLOR-WARNING !default;
$badge-color-notification:      $COLOR-ALERT !default;
$badge-color-error:             $COLOR-ALERT !default;
$badge-text-color-success:      $COLOR-WHITE !default;
$badge-text-color-warning:      $COLOR-BASE-TEXT !default;
$badge-text-color-notification: $COLOR-WHITE !default;
$badge-text-color-error:        $COLOR-WHITE !default;
$badge-icon-size:               icon-size(small) !default;
$badge-label-spacing:           $GLOBAL-SPACING-UNIT-XXSMALL !default;
$badge-label-text-size:         null !default;

/**
 * [1] Display the badge in line with the label and other badges, even if
 *     the badge contains an icon and handles correct wrapping when available
 *     space is exceeded.
 *     Resolves inline-block space issue that can occur for example when
 *     markup is minified.
 * [2] Vertically center the icon inside the badge.
 * [3] Vertically center the label next to the badge.
 * [4] Prevent distortion (keep 1:1 size).
 * [5] Apply all flexbox properties with default values (legacy support).
 */

.c-badge__wrapper {
    display: inline-flex; /* [1] */
    align-items: baseline;

    .c-badge__label {
        margin-left: $badge-label-spacing;
        vertical-align: middle; /* [3] */
    }

    .c-badge {
        flex-grow: 1; /* [4] */
        flex-shrink: 0; /* [4] */
        flex-basis: auto; /* [5] */
    }

}

.c-badge {
    @include badge();
    vertical-align: middle; /* [1] */

    &.c-badge--success,
    &.c-badge--positive {
        background-color: $badge-color-success;
        color: $badge-text-color-success;
    }

    &.c-badge--warning {
        background-color: $badge-color-warning;
        color: $badge-text-color-warning;
    }

    &.c-badge--notification {
        background-color: $badge-color-notification;
        color: $badge-text-color-notification;
    }

    &.c-badge--error,
    &.c-badge--negative {
        background-color: $badge-color-error;
        color: $badge-text-color-error;
    }

    .c-badge__icon {
        font-size: $badge-icon-size;
        line-height: inherit; /* [2] */
        vertical-align: top; /* [3] */
        color: inherit;
    }

}

    .c-badge__label {
        @include responsive-font-size(
            $font-size:   $badge-label-text-size,
            $accept-null: true,
        );
    }
