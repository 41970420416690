/* ------------------------------------*\
    #LIST-CASUAL
\*------------------------------------ */

/**
 * List whose items have more space between them to make the whole list look a
 * bit more spacey.
 */

$list-casual-item-spacing: ($GLOBAL-SPACING-UNIT * 0.5) !default;

.o-list-casual {

    li {
        margin-bottom: $list-casual-item-spacing;
    }

}
