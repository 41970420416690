/* ------------------------------------*\
    #PAGE
\*------------------------------------ */

@use "sass:math" as *;

$page-scoped:                  $GLOBAL-SCOPED !default;
$page-text-font:               $BASE-FONT-FAMILY !default;
$page-text-font-weight:        null !default;
$page-text-size:               $GLOBAL-FONT-SIZE !default;
$page-text-line-height:        $GLOBAL-LINE-HEIGHT !default;
$page-text-color:              $COLOR-BASE-TEXT !default;
$page-background-color:        $COLOR-BASE-BACKGROUND !default;
$page-typography-calculations: true !default;

/**
 * High-, page-level styling.
 *
 * [1] Ensure the page always fills at least the entire height of the viewport.
 * [2] Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *     navigating between pages that do/do not have enough content to produce
 *     scrollbars naturally.
 * [3] Set the default `font-size` and `line-height` for the entire project,
 *     sourced from our base variables. The `font-size` is calculated to exist
 *     in ems, the `line-height` is calculated to exist unitlessly.
 *     navigating between pages that do/do not have enough content to produce
 *     scrollbars naturally.
 * [4] Assign our base `font-family` for the whole site.
 *     We have to inherit `font-family` on `body` to override modern-normalize’s default
 *     system `font-family`. See https://github.com/sindresorhus/modern-normalize/issues/3
 * [5] Set our sites background color globally.
 * [6] Set our base text color globally.
 * [7] Progressively enhanced smooth scrolling.
 *     See https://developer.mozilla.org/de/docs/Web/CSS/scroll-behavior
 * [8] If the user prefers reduced motion, set transition duration to `0`.
 *     The CSS custom property is being used by `02-tools/_tools.transition.scss`.
 * [9] If the user prefers reduced motion, disable smooth scrolling animations.
 */
html {
    @if ($page-scoped == false) {
        min-height: 100%; /* [1] */
        overflow-y: initial; /* [2] */
    }
    font-family: $page-text-font; /* [4] */
    font-weight: $page-text-font-weight;
    background-color: $page-background-color; /* [5] */
    color: $page-text-color; /* [6] */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth; /* [7] */

    @if ($page-typography-calculations) {
        font-size: div($page-text-size, 16px) * 1em; /* [3] */
        line-height: div($page-text-line-height, $page-text-size); /* [3] */
    } @else {
        line-height: $page-text-line-height;
    }

    /* stylelint-disable-next-line scss/media-feature-value-dollar-variable */
    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
        --transition-duration-reduced-motion: 0;
        scroll-behavior: auto;
    }
}

/**
 * Assign the base font-size to the page. The `responsive-font-size` mixin has
 * to be assigned to the body element to work properly for all elements that get
 * their font-size via the `font-size` mixin.
 */
body {
    @include responsive-font-size($FS0);
    font-family: inherit; /* [4] */
}
