// /* ------------------------------------*\
//     #DATA-OVERVIEW (Settings)
// \*------------------------------------ */

$data-overview-border:                              $CARD-PATTERN-BORDER !default;
$data-overview-border-radius:                       $CARD-PATTERN-BORDER-RADIUS !default;

$data-overview-background-color:                    $CARD-PATTERN-BACKGROUND-COLOR !default;

$data-overview-padding-vertical:                    $CARD-PATTERN-PADDING-VERTICAL !default;
$data-overview-padding-horizontal:                  $CARD-PATTERN-PADDING-HORIZONTAL !default;
$data-overview-reduce-padding-at-breakpoint:        false !default;

$data-overview-header-spacing-bottom:               $GLOBAL-SPACING-UNIT !default;
$data-overview-header-spacing-bottom-at-breakpoint: $data-overview-header-spacing-bottom !default;

$data-overview-icon-size:                           icon-size(small) !default;
$data-overview-icon-color:                          $CARD-PATTERN-ICON-COLOR !default;

$data-overview-title-font-size:                     $CARD-PATTERN-TITLE-SIZE !default;
$data-overview-title-text-color:                    $CARD-PATTERN-TITLE-COLOR !default;

$data-overview-button-icon-size:                    icon-size(xxsmall) !default;
$data-overview-button-enable-label:                 false !default;
