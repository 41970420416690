// /* ------------------------------------*\
//     #LAYOUT (Settings)
// \*------------------------------------ */

/**
 * Spacings derive from spacing template in styleguide.
 * https://www.figma.com/file/8ct8rVAKCJyZmDmpjJuVlv/FLOW---Web-%2F-UI-1.0.0?node-id=19673%3A41974
 */

$layout-gutter:                     $LAYOUT-GUTTER !default;
$layout-item-spacing-vertical:      $LAYOUT-GUTTER !default;
$layout-divider-spacing-horizontal: $GLOBAL-SPACING-UNIT-LARGE !default;
$layout-divider-spacing-vertical:   $GLOBAL-SPACING-UNIT-SMALL !default;

$layout-divider-width:              $GLOBAL-BORDER-WIDTH !default;
$layout-divider-color:              $GLOBAL-BORDER-COLOR !default;
$layout-divider-offset-top:         -($layout-divider-width) !default;
