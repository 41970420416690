// /* ------------------------------------*\
//     #RADIO-BUTTON (Settings)
// \*------------------------------------ */

$radio-button-spacing:                    $GLOBAL-SPACING-UNIT-XSMALL !default;
$radio-button-size:                       20px !default;
$radio-button-dot-size:                   10px !default;

$radio-label-text-size:                   $FS-1 !default;
$radio-label-text-font-weight:            $GLOBAL-INPUT-LABEL-FONT-WEIGHT !default;
$radio-label-text-color-disabled:         $GLOBAL-INPUT-LABEL-COLOR-DISABLED !default;

$radio-button-dot-background-color:       $COLOR-BRAND !default;
$radio-button-dot-background-color-hover: $COLOR-BRAND-HOVER !default;
$radio-button-dot-background-disabled:    $COLOR-INACTIVE !default;

$radio-button-border-width:               2px !default;
$radio-button-border-color:               $COLOR-ON-SURFACE-700 !default;
$radio-button-border-color-focus:         $radio-button-border-color !default;
$radio-button-border-color-hover:         $COLOR-ON-SURFACE-900 !default;
$radio-button-border-color-checked:       $radio-button-dot-background-color !default;
$radio-button-border-color-checked-hover: $radio-button-dot-background-color-hover !default;
$radio-button-border-color-disabled:      $COLOR-INACTIVE !default;
