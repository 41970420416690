// /*------------------------------------*\
//     #BADGE (Settings)
// \*------------------------------------*/

$badge-label-text-size:         $GLOBAL-STATUS-TEXT-SIZE !default;
$badge-label-spacing:           $GLOBAL-SPACING-UNIT-XSMALL !default;

$badge-text-color-success:      map-deep-get($STATUS-THEMES, "success", "text-color") !default;
$badge-text-color-warning:      map-deep-get($STATUS-THEMES, "warning", "text-color") !default;
$badge-text-color-error:        map-deep-get($STATUS-THEMES, "error", "text-color") !default;
$badge-text-color-notification: map-deep-get($STATUS-THEMES, "warning", "text-color") !default;
$badge-color-notification:      map-deep-get($STATUS-THEMES, "warning", "background-color") !default;

$badge-icon-size:               icon-size(xxsmall) !default;
