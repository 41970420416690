// /* ------------------------------------*\
//     #PLAY-BUTTON
// \*------------------------------------ */

/**
 * [1] Center icon inside button.
 * [2] Overwrite plyr default.
 * [3] Center play button icon.
 */
$play-button-breakpoint:              s !default;
$play-button-size:                    map-get($ICON-ROUND-FULL-SIZES, "medium") !default;
$play-button-size-at-breakpoint:      map-get($ICON-ROUND-FULL-SIZES, "small") !default;
$play-button-border-width:            2px !default;
$play-button-border-color:            $COLOR-HIGHLIGHT !default;
$play-button-border:                  $play-button-border-width solid $play-button-border-color !default;
$play-button-background:              $COLOR-WHITE !default;
$play-button-background-hover:        $play-button-background !default;
$play-button-icon:                    get-icon(semantic-play) !default;
$play-button-icon-color:              $COLOR-BRAND !default;
$play-button-icon-color-hover:        $play-button-icon-color !default;
$play-button-icon-size:               icon-size(small) !default;
$play-button-icon-size-at-breakpoint: null !default;
$play-button-icon-filter:             null !default;
$play-button-shadow:                  null !default;
$play-button-transition:              $GLOBAL-TRANSITION !default;

/// Apply play-button styles to configurable selectors.
///
/// @param {String} $button-selector - Selector for button
/// @param {String} $icon-selector - Selector for icon (iconfont or svg)
/// @param {String} $label-selector - Selector for label (a11y, visually hidden)
/// @returns {CSS} Complete ruleset for play-button styling.
@mixin play-button(
    $button-selector: ".c-play-button",
    $icon-selector:   ".c-play-button__icon",
    $label-selector:  ".c-play-button__label",
) {
    @if not($play-button-icon) {
        @error("PlayButton: You have to configure `$play-button-icon`.");
    }

    #{$button-selector} {
        --play-button-icon: #{$play-button-icon};
        --play-button-background-color: #{$play-button-background};
        --play-button-background-color-hover: #{$play-button-background-hover};
        --play-button-border: #{$play-button-border};
        --play-button-icon-color: #{$play-button-icon-color};
        --play-button-icon-color-hover: #{$play-button-icon-color-hover};

        display: inline-flex !important; /* [1][2] */ /* stylelint-disable-line itcss/no-at-important */
        align-items: center; /* [1] */
        justify-content: center; /* [2] */
        width: $play-button-size;
        height: $play-button-size;
        padding: 0;
        border: var(--play-button-border);
        border-radius: 100%;
        line-height: 0; /* [3] */
        background: var(--play-button-background-color);
        color: var(--play-button-icon-color);
        box-shadow: $play-button-shadow;
        transition: $play-button-transition;

        @include media-query("<=#{$play-button-breakpoint}") {
            width: $play-button-size-at-breakpoint;
            height: $play-button-size-at-breakpoint;
        }

        &:hover,
        &:focus {
            background: var(--play-button-background-color-hover);
            color: var(--play-button-icon-color-hover);
            filter: $play-button-icon-filter;
        }
    }

    #{$icon-selector} {
        @include icon-font();
        font-size: $play-button-icon-size;

        @include media-query("<=#{$play-button-breakpoint}") {
            font-size: $play-button-icon-size-at-breakpoint;
        }

        &::before {
            content: var(--play-button-icon);
        }

    }

    #{$label-selector} {
        @include a11y-hidden();
    }

}

@include play-button();
