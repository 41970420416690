// /* ------------------------------------*\
//     #FLATPICKR-OVERRIDES
// \*------------------------------------ */

$flatpickr-datepicker-dropdown-input-offset: $GLOBAL-SPACING-UNIT-SMALL !default;

/**
 * [1] Allow to add `padding` to size to avoid shift of dates in calendar layout.
 * [2] Remove "gap", and "restore" by using `padding` to have a gap-less range.
 * [3] Add additional "square" background for start & end.
 * [4] Overlap to create seamless range style.
 * [5] Additional background only to side towards in-range items.
 * [6] InRange-Start background only when ranges are selected or endDate is
 *     followed directly by startDate (the following day).
 *     `:has` is used as a progressive enhancement to avoid "cutoff" start/end
 *     range on dates. For non `:has` fallback see fallback layer.
 * [7] Reset default.
 * [8] Show controls in actual size (icon-size x icon-size), not limited size.
 * [9] Improve space distribution when month selection
 *     is done via `<select>` (`.flatpickr-monthDropdown-months`).
 * [10] Selectors can not be chained (limitation of `button-icon-states()` mixin).
 */

.flatpickr-day {

    &.inRange:not(.startRange, .endRange) {
        box-sizing: content-box; /* [1] */
        margin-left: 0; /* [2] */
        margin-right: 0; /* [2] */
        padding-left: $flatpickr-square-margin-horizontal; /* [2] */
        padding-right: $flatpickr-square-margin-horizontal; /* [2] */
        border-radius: 0;
    }

    &.startRange,
    &.endRange {
        position: relative;

        /* [3] */
        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            height: 100%;
            pointer-events: none;
        }
    }

    /* [6] */
    &.startRange:has(+ .inRange),
    &.startRange:has(+ .endRange) {

        &::before {
            background-color: $flatpickr-day-range-background-color;
        }

    }

    /* [6] */
    &.startRange + .endRange,
    &.inRange + .endRange {

        &::before {
            background-color: $flatpickr-day-range-background-color;
        }

    }

    &.startRange {

        &::before {
            left: 50%; /* [5] */
            right: -($flatpickr-square-margin-horizontal); /* [4] */
        }

    }

    &.endRange {

        &::before {
            left: -($flatpickr-square-margin-horizontal); /* [4] */
            right: 50%; /* [5] */
        }

    }

}

/* [10] */
.flatpickr-prev-month {

    .c-icon {
        @include button-icon-states(
            $focus-state: false, // Flatpickr buttons not keyboard accessible.
        );
    }

}

/* [10] */
.flatpickr-next-month {

    .c-icon {
        @include button-icon-states(
            $focus-state: false, // Flatpickr buttons not keyboard accessible.
        );
    }

}

/* [8] */
.numInputWrapper {

    .flatpickr-months & {

        .arrowUp,
        .arrowDown {
            right: auto; /* [7] */
            left: 100%;
            width: auto;
            height: auto;
        }

        .arrowUp {
            transform: translateY(-50%);
        }

        .arrowDown {
            transform: translateY(50%);
        }

    }

    .flatpickr-monthDropdown-months ~ & {
        margin-right: $flatpickr-datepicker-dropdown-input-offset; /* [9] */
    }

}
