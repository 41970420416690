
/* ------------------------------------*\
    #Indicator Dot (Scopes)
\*------------------------------------ */

$scope-indicator-dot-table-offset-vertical: null !default;

/**
 * [1] Unset (re-) positioning as in those scopes the position is handled differently.
 * [2] Pixel-pushing deluxe! Since the positioning is depending on too many parameters
 *     (`font-size`, `line-height`, etc.) a magic number is used here
 *     to visually align dot to baseline.
 */
.c-indicator-dot__dot {

    .c-inbox-table__status-indicator & {
        transform: none; /* [1] */
    }

    .c-notification-center__item__status & {
        transform: translateY(-50%); /* [1] */
    }

    .c-table__cell-align & {
        @if ($scope-indicator-dot-table-offset-vertical) {
            transform: translateY(#{$scope-indicator-dot-table-offset-vertical}); /* [2] */
        }
    }

}
