/* ------------------------------------*\
    #LABELED-CONTENT-SECTION
\*------------------------------------ */

$labeled-content-section-padding-top:            $CONTENT-SECTION-SPACING-TOP !default;
$labeled-content-section-padding-bottom:         $CONTENT-SECTION-SPACING-BOTTOM !default;

$labeled-content-section-label-spacing:          $GLOBAL-SPACING-UNIT-XSMALL !default;
$labeled-content-section-label-text-color:       null !default;
$labeled-content-section-label-text-font-size:   null !default;
$labeled-content-section-label-text-font-weight: null !default;
$labeled-content-section-label-text-font-family: null !default;
$labeled-content-section-label-text-transform:   null !default;


/**
 * [1] Use the same paddings as the content section object.
 */

.c-labeled-content-section {
    padding-top: $labeled-content-section-padding-top; /* [1] */
    padding-bottom: $labeled-content-section-padding-bottom; /* [1] */
}

.c-labeled-content-section__label {
    margin-bottom: $labeled-content-section-label-spacing;
}

.c-labeled-content-section__label-text {
    @include responsive-font-size(
        $font-size:   $labeled-content-section-label-text-font-size,
        $accept-null: true
    );
    font-family: $labeled-content-section-label-text-font-family;
    font-weight: $labeled-content-section-label-text-font-weight;
    text-transform: $labeled-content-section-label-text-transform;
    color: $labeled-content-section-label-text-color;
}
