/* ------------------------------------*\
    #FONT-FACE
\*------------------------------------ */

@use "sass:meta";
@use "sass:map";

// Base font

// Variable font
@if (meta.variable-exists("BASE-FONT-VARIABLE-VARIATION-AXIS")) {
    @include font-face(
        $font-name: $BASE-FONT,
        $file-name: if(meta.variable-exists(BASE-FONT-FILE-NAME), $BASE-FONT-FILE-NAME, null),
        $weight:    map.get($BASE-FONT-VARIABLE-VARIATION-AXIS, "weight"),
        $stretch:   map.get($BASE-FONT-VARIABLE-VARIATION-AXIS, "stretch"),
        $format:    "woff2-variations",
        $revision:  $FONT-REVISION,
    );
} @else {
    // Font
    @each $face, $weight in $BASE-FONT-VARIANTS {

        @include font-face(
            $font-name: $BASE-FONT,
            $face:      "-" + $face,
            $weight:    $weight,
            $revision:  $FONT-REVISION
        );

    }
}

// Alt font

// Variable font
@if (meta.variable-exists("ALT-FONT-VARIABLE-VARIATION-AXIS")) {
    @include font-face(
        $font-name: $ALT-FONT,
        $file-name: if(meta.variable-exists(ALT-FONT-FILE-NAME), $ALT-FONT-FILE-NAME, null),
        $weight:    map.get($ALT-FONT-VARIABLE-VARIATION-AXIS, "weight"),
        $stretch:   map.get($ALT-FONT-VARIABLE-VARIATION-AXIS, "stretch"),
        $format:    "woff2-variations",
        $revision:  $FONT-REVISION,
    );
} @else {
    // Font
    @each $face, $weight in $ALT-FONT-VARIANTS {

        @include font-face(
            $font-name: $ALT-FONT,
            $face:      "-" + $face,
            $weight:    $weight,
            $revision:  $FONT-REVISION
        );

    }
}
