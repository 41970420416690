// /* ------------------------------------*\
//     #BACKDROP
// \*------------------------------------ */

$backdrop-background: $GLOBAL-BACKDROP-BACKGROUND !default;
$backdrop-filter:     null !default;

// Mixin to extract backdrop stylings.
@mixin backdrop($color: $backdrop-background, $position: fixed, $filter: $backdrop-filter) {
    position: $position;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color;
    backdrop-filter: $filter;
}
