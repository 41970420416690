// /* ------------------------------------*\
//     #SITE-NAV-DROPDOWN (Settings)
// \*------------------------------------ */

$site-nav-dropdown-has-transition:                      false !default;

$site-nav-dropdown-background-color:                    $COLOR-BASE-BACKGROUND !default;

$site-nav-dropdown-list-padding-vertical:               0 !default;
$site-nav-dropdown-list-padding-horizontal:             0 !default;

$site-nav-dropdown-title-font-size:                     $FS-2 !default;
$site-nav-dropdown-title-color:                         $COLOR-ON-SURFACE-500 !default;

$site-nav-dropdown-item-border:                         false !default;

$site-nav-dropdown-link-padding-vertical:               $__SKODA-SPACING-STATIC-12__ !default; // target size: 48px
$site-nav-dropdown-link-padding-vertical-at-breakpoint: $site-nav-dropdown-link-padding-vertical !default;
$site-nav-dropdown-link-font-weight:                    $GLOBAL-FONT-WEIGHT-REGULAR !default;
$site-nav-dropdown-link-color:                          inherit !default;
$site-nav-dropdown-link-color-hover:                    inherit !default;
$site-nav-dropdown-link-background-color-hover:         $COLOR-SURFACE-PRIMARY !default;
$site-nav-dropdown-link-background-color-active:        $site-nav-dropdown-link-background-color-hover !default;
