/* ------------------------------------*\
    #BUTTON-GROUP
\*------------------------------------ */
@use "sass:math" as *;

/**
 * The button group component is a set of buttons to choose between several
 * options in forms. So, technically speaking, it's actually radio buttons
 * or checkboxes, just in the look of a bunch of buttons.
 */

// Define your desired button group item height.
// NOTE: This just applies, if you define the `$button-group-item-line-height` with a
// `px` value. If you need to define your line-height with another unit, you
// need to construct the height of your button yourself by altering the
// `$button-padding-vertical` variable.
$button-group-item-height:                    $BUTTON-HEIGHT !default;

// Prefer to define your line-height with a `px` value, so the desired
// button-group height you defined above can be calculated automatically.
$button-group-item-line-height:               $BUTTON-LINE-HEIGHT !default;

// Define the spacing between the button group items.
$button-group-item-spacing-vertical:          $BUTTON-GROUP-ITEM-SPACING-VERTICAL !default;
$button-group-item-spacing-horizontal:        $BUTTON-GROUP-ITEM-SPACING-HORIZONTAL !default;

// Minimum width for item (https://zpl.io/2po5Rnr)
$button-group-item-min-width:                 152px !default;

$button-group-item-border-width:              $GLOBAL-INPUT-BORDER-WIDTH !default;
$button-group-item-border-style:              $GLOBAL-INPUT-BORDER-STYLE !default;
$button-group-item-border-color:              $GLOBAL-INPUT-BORDER-COLOR !default;
$button-group-item-border-color-hover:        $COLOR-INACTIVE !default;
$button-group-item-border-color-checked:      $COLOR-BRAND !default;
$button-group-item-border-color-disabled:     null !default;
$button-group-item-border:                    $button-group-item-border-width $button-group-item-border-style $button-group-item-border-color !default;
$button-group-item-border-radius:             $GLOBAL-INPUT-BORDER-RADIUS !default;

$button-group-item-padding-vertical:          null !default;
// If `$button-group-item-line-height` is a `px` value, we can use that and the
// `$button-group-item-height` to automatically calculate the appropriate
// `$button-group-item-padding-vertical` value, so it yields the desired button
// height.
@if (unit($button-group-item-line-height) == "px") {
    $button-group-item-padding-vertical: (($button-group-item-height - $button-group-item-line-height - ($button-group-item-border-width * 2)) * 0.5) !default;
}
// If the `$button-group-item-line-height` is not a `px` value, the above
// calculation wouldn't work. In that case, the @if condition above wouldn't
// apply and instead, the following variable assignment would apply.
$button-group-item-padding-vertical:          $GLOBAL-SPACING-UNIT-SMALL !default; /* stylelint-disable-line scss/no-duplicate-dollar-variables */
$button-group-item-padding-horizontal:        $GLOBAL-SPACING-UNIT !default;
$button-group-item-padding:                   $button-group-item-padding-vertical $button-group-item-padding-horizontal !default;

$button-group-item-background-color:          $BUTTON-GROUP-ITEM-BACKGROUND-COLOR !default;
$button-group-item-background-color-hover:    $BUTTON-GROUP-ITEM-BACKGROUND-COLOR-HOVER !default;
$button-group-item-background-color-checked:  $COLOR-BASE !default;
$button-group-item-background-color-disabled: null !default;

$button-group-item-font-size:                 $BUTTON-TEXT-SIZE !default;
$button-group-item-font-weight:               null !default;
$button-group-item-font-color:                $COLOR-BASE-TEXT !default;
$button-group-item-font-color-hover:          $button-group-item-font-color !default;
$button-group-item-font-color-checked:        $COLOR-WHITE !default;
$button-group-item-font-color-disabled:       $COLOR-INACTIVE !default;
$button-group-item-text-transform:            null !default;
$button-group-item-text-letter-spacing:       null !default;

$button-group-icon:                           true !default;
$button-group-icon-spacing:                   $GLOBAL-SPACING-UNIT-XSMALL !default;
$button-group-icon-color:                     null !default;
$button-group-icon-size:                      icon-size(medium) !default;

/**
 * [1] Better alignment management.
 * [2] When the items do have spacing beneath each other, make sure to take care
 *     of the vertical spacing between the items (when multiline) and to
 *     surrounding components.
 * [3] Defensively default to `display: flex`.
 * [4] Center text inside items.
 * [5] If the items do have spacing beneath each other, give each item, except
 *      the last one, a spacing.
 * [6] Make it blocky so it can take up 100% width of its parent.
 * [7] Hide the original radio-button.
 * [8] Reset margin-bottom to 0 (set by `<label>` declaration).
 * [9] Overwrite default icon `transition`
 *      to be in sync with button-group transition.
 * [10] Stretch to the highest item in row.
 * [11] Subtract (compensate) margins to prevent unnecessary wrapping (100+%).
 * [12] Allow growing/shrinking if max-count is not reached (quantity queries).
 * [13] Vertically center item content.
 * [14] Get a min-width of `$button-group-item-min-size` and a max width of
 *      `1fr` for each grid-item.
 * [15] Get equal-height grid-rows.
 * [16] The display of icons in button group items is optional.
 */

// Calculate item sizes
// @param {number} $count: max count per row
@mixin button-group-item-size($count) {
    $_width: percentage(div(1, $count));
    flex-basis: calc(#{$_width} - #{$button-group-item-spacing-horizontal}); /* [11] */

    /* [12] */
    @include at-most($count) {
        flex-grow: 1;
        flex-shrink: 1;
    }

}

/* autoprefixer: ignore next */
.c-button-group {
    display: grid; /* [1] */
    grid-template-columns: repeat(auto-fit, minmax($button-group-item-min-width, 1fr)); /* [14] */
    grid-auto-rows: 1fr; /* [15] */

    @if ($button-group-item-spacing-vertical != 0) {
       row-gap: $button-group-item-spacing-vertical; /* [2] */
    }

    @if ($button-group-item-spacing-horizontal != 0) {
       column-gap: $button-group-item-spacing-horizontal; /* [2] */
    }

    &.c-button-group--auto {
        /* autoprefixer: ignore next */
        grid-template-columns: repeat(auto-fill, minmax($button-group-item-min-width, 1fr)); /* [5] */
    }

}

    .c-button-group__item {
        display: flex; /* [3] */
        margin-bottom: 0; /* [8] */
        text-align: center; /* [4] */
    }

    .c-button-group__label {
        @include transition();
        @include responsive-font-size(
            $font-size:   $button-group-item-font-size,
            $line-height: $button-group-item-line-height
        );
        position: relative;
        display: inline-flex; /* [6] */
        flex-direction: column;
        justify-content: center; /* [13] */
        width: 100%; /* [6] */
        height: 100%; /* [10] */
        padding: $button-group-item-padding;
        border: $button-group-item-border;
        border-radius: $button-group-item-border-radius;
        font-weight: $button-group-item-font-weight;
        text-transform: $button-group-item-text-transform;
        letter-spacing: $button-group-item-text-letter-spacing;
        background-color: $button-group-item-background-color;
        color: $button-group-item-font-color;
        cursor: pointer;

        // :hover & :focus NOT checked
        .c-button-group__item:focus-within &,
        .c-button-group__input:hover + &,
        .c-button-group__input:focus + &,
        &:hover,
        &:focus {
            z-index: 1;
            border-color: $button-group-item-border-color-hover;
            border-radius: $button-group-item-border-radius;
            background-color: $button-group-item-background-color-hover;
            color: $button-group-item-font-color-hover;
        }

        // Place below :hover and :focus to promote :checked state
        .c-button-group__input:checked + & {
            z-index: 1;
            border-color: $button-group-item-border-color-checked;
            background-color: $button-group-item-background-color-checked;
            color: $button-group-item-font-color-checked;
        }

        .c-button-group__input[disabled] + & {
            border-color: $button-group-item-border-color-disabled;
            background-color: $button-group-item-background-color-disabled;
            color: $button-group-item-font-color-disabled;
            cursor: default;
        }

    }

    @if ($button-group-icon) {

        .c-button-group__icon { /* 22 */
            margin-bottom: $button-group-icon-spacing;
            color: $button-group-icon-color;

            &::before {
                font-size: $button-group-icon-size;
                transition: none; /* [9] */
            }

        }

    } @else {
        .c-button-group__icon {
            display: none; /* [16] */
        }
    }

    .c-button-group__input {
        @include a11y-hidden(); /* [7] */
    }
