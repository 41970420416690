// /* ------------------------------------*\
//     #FLEX-ALIGNMENT
// \*------------------------------------ */

/**
 * Get alignment for flexbox-based modules.
 * Remaps alignment (center, left, right) to flexbox values.
 */

/// @param {String} $direction horizontal|vertical
/// @param {String} $align horizontal: left|center|right, vertical: top|center|bottom
/// @return {String} re-mapped alignment value
///
/// @example
/// flex-alignment("left") => flex-start
/// flex-alignment("center") => center
/// flex-alignment("bottom", "vertical") => flex-bottom
@function flex-alignment($align, $direction: "horizontal") {
    @if ($align == "center") {
        @return center;
    }

    @if ($direction == "horizontal") {
        @if ($align == "left") {
            @return flex-start;
        }

        @if ($align == "right") {
            @return flex-end;
        }
    }

    @if ($direction == "vertical") {
        @if ($align == "top") {
            @return flex-start;
        }

        @if ($align == "bottom") {
            @return flex-end;
        }
    }

}
