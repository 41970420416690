// /* ------------------------------------*\
//     #A11Y Hidden
// \*------------------------------------ */

// Mixin to hide content in an accessible way.
// See https://a11yproject.com/posts/how-to-hide-content/ for more information.
@mixin a11y-hidden($important: null) {
    position: absolute !important; /* stylelint-disable-line itcss/no-at-important */
    width: important(1px, $important);
    height: important(1px, $important);
    overflow: important(hidden, $important);
    clip: important(rect(1px, 1px, 1px, 1px), $important);
}
