/* ------------------------------------*\
    #DESCRIPTION-LIST
\*------------------------------------ */

$description-list-color:                          $COLOR-BASE-TEXT !default;
$description-list-group-spacing-vertical:         $GLOBAL-SPACING-UNIT-SMALL !default;
$description-list-group-spacing-horizontal:       $GLOBAL-SPACING-UNIT-SMALL !default;

// When the width of the description list is smaller, the items in the
// “horizontal” variant (and the groups in the “grid” variant) will be displayed
// below the other.
$description-list-horizontal-item-break-width:    35rem !default;

// Vertical spacing between terms and details (and consecutive details).
$description-list-item-spacing-vertical:          null !default;
$description-list-item-spacing-horizontal:        $description-list-group-spacing-horizontal !default;

$description-list-term-font-size:                 $FS0 !default;
$description-list-term-font-weight:               $GLOBAL-FONT-WEIGHT-REGULAR !default;
$description-list-term-color:                     inherit !default;

$description-list-time-font-size:                 $FS-1 !default;
$description-list-time-font-weight:               $GLOBAL-FONT-WEIGHT-REGULAR !default;

$description-list-detail-font-size:               $FS0 !default;
$description-list-detail-font-weight:             $GLOBAL-FONT-WEIGHT-BOLD !default;
$description-list-detail-color:                   inherit !default;
$description-list-detail-item-spacing-horizontal: $description-list-item-spacing-horizontal !default;

// The width of the terms in the “terms short” variant when the width of
// the description list is larger than `$description-list-horizontal-item-break-width`.
$description-list-terms-short-width:              15rem !default;

// Set the container query “breakpoint” in `rem` when the detail elements should
// break below the terms in the “split” variant.
$description-list-split-item-break-width:         20rem !default;
// Map that is needed by the container queries mixin. The “breakpoint” gets
// converted to a unitless pixel value.
$description-list-split-width-queries: (
    s: 0,
    m: strip-unit($description-list-split-item-break-width) * strip-unit($GLOBAL-FONT-SIZE),
) !default;

/**
 * [1] Reset potential default style from generic and element layers.
 * [2] Use `inline-flex` so that child elements,
 *     like `.c-description-list__action`, can be aligned properly.
 * [3] `.c-description-list__text` is needed to not let inline elements be
 *     influenced by the flexbox context.
 * [4] Let `.c-description-list__text` grow so that it plays nice with
 *     `.c-description-list__action` in “split variant”.
 * [5] Let `.c-description-list__action` grow if it’s the only child of
 *     `.c-description-list__detail`.
 * [6] Technique is based on the based on Flexbox Holy Albatross
 *     https://heydonworks.com/article/the-flexbox-holy-albatross
 * [7] Set `flex-basis` to a extreme negative or positive value based on
 *     `$description-list-horizontal-item-break-width` and therefore enlarge the
 *     description list group element to full width or shrink to the `min-width`.
 * [8] Set `min-width`to 50% width (taking the horizontal spacing into account).
 * [9] `flex-end` because we want to align multiple consecutive details to the
 *     right.
 * [10] Let the description list item horizontally grow if the `flex-basis` value
 *     is negative (when the parent is smaller than
 *     `$description-list-horizontal-item-break-width`).
 * [11] Set `flex-basis` to a extreme negative or positive value based on
 *      `$description-list-horizontal-item-break-width` and therefore wrap and
 *      enlarge the description list items to full width or display side by side.
 * [12] Set `min-width` to `50%` width (taking the horizontal spacing into account).
 * [13] Do not allow consecutive details to grow in width so that they are not
 *      displayed below the term when the description list is wider than
 *      `$description-list-horizontal-item-break-width`.
 * [14] Add `0.1px` to the `min-width` to make it a very tiny bit wider than `50%`
 *      so that consecutive details are displayed one below the other and not
 *      side by side.
 * [15] Unset `min-width` because in the “terms short” variant the size of the
 *      term will be determined by the detail width.
 * [16] Calculate the `min-width` by subtracting the terms’ width and horizontal
 *      spacing.
 * [17] Use `container` mixin to right align text above the
 *     `$description-list-split-item-break-width` “breakpoint”.
 *      The selector `.c-description-list--split` needs to be added to the
 *      `ContainerQuery` `componentSelector` in
 *      `packages/bronson-default/src/js/module/container-query-observer.js`
 *      @TODO: Maybe we could use _real_ container queries (or a polyfill),
 *             so no custom SCSS/JS hybrid solution is needed anymore.
 * [18] This a CSS custom properties conditional logic technique (**hack**)
 *      that allows us to set properties depending on the size of `.c-description-list--split`.
 *      `--if-description-list-split-is-wide` can be used as toggle to set
 *      invalid or valid values to CSS properties. For example:
 *          `flex-direction: var(--if-description-list-split-is-wide) column;`
 *      We need this for the Product Table summary in VW6.
 *      Learn more about this technique here:
 *      - https://dev.to/siddharthshyniben/conditional-logic-with-css-the-css-custom-property-trick-44hb
 *      - https://lea.verou.me/2020/10/the-var-space-hack-to-toggle-multiple-values-with-one-custom-property/
 * [19] Let (consecutive) detail elements grow so that that they are right
 *      aligned above the “breakpoint”.
 * [20] Break each detail element into its own line when there is not enough
 *      space besides the term element. Reset again above the “breakpoint”.
 * [21] Display consecutive details one below the other by letting them grow to
 *     `100%` width.
 * [22] Convert the list semantically and stylistically into a table so that it
 *      behaves like a table.
 * [23] We are using `padding` for the spacing because we can't use `gap` or
 *      `margin` in a table.
 * [24] We display details only as table cell, when there are no consecutive
 *      details. Otherwise additional columns would be added to the “table”.
 * [25] Add custom property as an "API" for text-alignment based on scope/context.
 * [26] Reset default element margin.
 * [27] Alter text-alignment based on split-mode condition.
 */

.c-description-list {
    display: flex;
    flex-direction: column;
    row-gap: $description-list-group-spacing-vertical;
    column-gap: $description-list-group-spacing-horizontal;
    margin-bottom: 0; /* [26] */
    text-align: var(--description-list-text-align, left); /* [25] */
    color: $description-list-color;
}

    .c-description-list__group {
        display: flex;
        flex-direction: column;
        row-gap: $description-list-item-spacing-vertical;
        column-gap: $description-list-item-spacing-horizontal;
    }

    .c-description-list__term {
        @include responsive-font-size($description-list-term-font-size);
        margin: 0; /* [1] */
        font-weight: $description-list-term-font-weight;
        text-align: var(--description-list-text-align);
        color: $description-list-term-color;

    }

    .c-description-list__detail {
        --text-align-end: var(--if-description-list-split-is-wide) right; /* [27] */
        --text-align: var(--text-align-end, var(--description-list-text-align));

        @include responsive-font-size($description-list-detail-font-size);
        display: inline-flex; /* [2] */
        flex-wrap: wrap; /* [2] */
        justify-content: space-between; /* [2] */
        align-items: flex-start; /* [2] */
        column-gap: $description-list-detail-item-spacing-horizontal;
        margin: 0; /* [1] */
        font-weight: $description-list-detail-font-weight;
        text-align: var(--text-align);
        color: $description-list-detail-color;

        > :last-child {
            flex-basis: 100%; /* [2] */

            &.c-description-list__action {
                flex-basis: auto; /* [2] */
            }

        }

    }

    .c-description-list__text { /* [3] */
        flex-grow: 1; /* [4] */

        > :last-child {
            margin-bottom: 0;
        }

    }

    .c-description-list__time {
        @include responsive-font-size(
            $font-size:   $description-list-time-font-size,
            $line-height: none,
        );
        font-weight: $description-list-time-font-weight;
    }

    .c-description-list__action {

        &:only-child {
            flex-grow: 1; /* [5] */
        }

    }

    /*
     * Grid variant
     */
    .c-description-list--grid {
        flex-wrap: wrap;
        flex-direction: row;

        > .c-description-list__group {
            flex-basis: calc((#{$description-list-horizontal-item-break-width} - 100%) * 999); /* [6] [7] */
            min-width: calc(50% - #{$description-list-group-spacing-horizontal * 0.5}); /* [8] */
        }

    }

    /*
     * Horizontal variant
     */
    .c-description-list--horizontal {

        .c-description-list__group {
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-end; /* [9] */

            > * {
                flex-grow: 1; /* [6] [10] */
                flex-basis: calc((#{$description-list-horizontal-item-break-width} - 100%) * 999); /* [6] [11] */
                min-width: calc(50% - #{$description-list-group-spacing-horizontal * 0.5}); /* [12] */
            }

        }

        .c-description-list__detail {

            + .c-description-list__detail {
                flex-grow: 0; /* [13] */
                min-width: calc(50% - #{$description-list-group-spacing-horizontal * 0.5} + 0.1px); /* [14] */
            }

        }

    }

    /*
     * Small terms variant (could be combined with the horizontal variants)
     */
    .c-description-list--terms-short {

        .c-description-list__term {
            min-width: unset; /* [15] */ /* stylelint-disable-line scale-unlimited/declaration-strict-value */
        }

        .c-description-list__detail {

            &,
            + .c-description-list__detail {
                min-width: calc(100% - #{$description-list-terms-short-width} - #{$description-list-item-spacing-horizontal}); /* [16] */
            }
        }

    }

    /*
     * Split variant
     */
    .c-description-list--split {
        --container-widths: "#{cq-convert-data($description-list-split-width-queries)}"; /* [17] */
        --if-description-list-split-is-wide: initial; /* [18] */

        .c-description-list__group {
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
        }

        .c-description-list__detail {
            flex-grow: 1; /* [19] */
            flex-basis: 100%; /* [20] */
        }

        /* stylelint-disable-next-line order/order */
        @include container($map: $description-list-split-width-queries, $size: "m") { /* [17] */
            --if-description-list-split-is-wide: ; /* [18] */ /* stylelint-disable-line */

            .c-description-list__detail {
                flex-basis: var(--if-description-list-split-is-wide) unset; /* [20] */ /* stylelint-disable-line scale-unlimited/declaration-strict-value */

                + .c-description-list__detail {
                    flex-basis: 100%; /* [21] */
                }

            }

        }

    }

    /*
     * Table variant
     */
    .c-description-list--table {
        display: table; /* [22] */

        .c-description-list__group {
            display: table-row; /* [22] */

            + .c-description-list__group {

                > :first-of-type { /* stylelint-disable selector-max-combinators, selector-max-compound-selectors */
                    padding-top: $description-list-group-spacing-vertical; /* [23] */
                }
            }

        }

        .c-description-list__term {
            display: table-cell; /* [22] */
            padding-right: ($description-list-item-spacing-horizontal * 0.5); /* [23] */
        }

        .c-description-list__detail {
            display: block;
            padding-left: ($description-list-item-spacing-horizontal * 0.5); /* [23] */

            &:first-of-type:last-child {
                display: table-cell; /* [24] */
            }

            + .c-description-list__detail {
                padding-top: $description-list-item-spacing-vertical; /* [23] */
            }

        }

    }
