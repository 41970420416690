/* ------------------------------------*\
    #PRODUCT-SEARCH-HEADER
\*------------------------------------ */

$product-search-header-breakpoint:                     m !default;
$product-search-header-actions-full-width-breakpoint:  xs !default;
$product-search-header-items-spacing:                  $GLOBAL-SPACING-UNIT !default;

$product-search-header-label-hidden:                   true !default;

$product-search-header-actions-spacing:                $GLOBAL-SPACING-UNIT !default;

$product-search-header-filter-bar-float-grid-area:     bottom !default;
$product-search-header-filter-bar-visible-class:       map-get($INTERSECTION-HOOKS, outside) !default;
$product-search-header-filter-bar-background-color:    $COLOR-WHITE !default;
$product-search-header-filter-bar-spacing-vertical:    $GLOBAL-SPACING-UNIT-XSMALL !default;
$product-search-header-filter-bar-layer:               z("product-search-filter-bar") !default;
$product-search-header-filter-bar-box-shadow:          $GLOBAL-BOX-SHADOW !default;
$product-search-header-filter-bar-box-shadow-inverted: $GLOBAL-BOX-SHADOW-INVERTED !default;
$product-search-header-filter-bar-transition-duration: $GLOBAL-TRANSITION-DURATION !default;

/**
 * [1] Enforce full width when wrapped and in separate row.
 * [2] Show items 50% but account for gap to remove premature wrapping.
 * [3] Overwrite btn modifiers.
 * [4] Position the filter bar fixed to the top on large screens and make it
 *     span the whole width.
 * [5] Offset the bar off-screen to the top.
 * [6] Remove any `box-shadow` since the bar is off-canvas.
 * [7] On smaller viewports the bar is permanently displayed at the bottom of the
 *     screen.
 * [8] Always show `box-shadow` on smaller viewports (when the bar is always
 *     visible).
 * [9]  Display the normal `box-shadow` only on larger screens when revealed.
 * [10] Reveal the bar when its scroll observer has activated it on larger viewports.
 * [11] Remove unwanted vertical spacing.
 * [12] Always hide the title on smaller viewports and only show the button
 *      (when the bar is always visible).
 * [13] Hide the filter toggle button in the static header on small viewports
 *      as it is always visible due to the fixed bottom bar.
 * [14] Hide the filter toggle when the sidebar is already visible.
 * [15] Move filter to Float Grid area (for small viewports).
 */
.c-product-search-header {
    display: flex;
    gap: $product-search-header-items-spacing;
    flex-wrap: wrap;
    justify-content: space-between;
}

    .c-product-search-header__title {
        flex-grow: 1; /* [1] */
        flex-shrink: 1;
        flex-basis: auto;
        align-self: center;
        margin: 0;
    }

    .c-product-search-header__filter-toggle {
        flex-grow: 0; /* [1] */
        flex-shrink: 0;
        flex-basis: auto;
        align-self: center;

        @include media-query(">#{$product-search-header-breakpoint}") {

            .c-product-search--has-sidebar & {
                display: none;
            }

        }

        @include media-query("<=#{$product-search-header-actions-full-width-breakpoint}") {
            flex-grow: 1;
            flex-basis: 100%;

            .c-product-search-header--sticky > & { /* [13] */
                display: none;
            }

        }

        > .c-btn {
            width: 100%;
        }

    }

    .c-product-search-header__body {
        // flex-container
        display: flex;
        gap: $product-search-header-items-spacing;
        justify-content: space-between;
        align-items: baseline;

        // flex-item
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;

        @include media-query("<=#{$product-search-header-breakpoint}") {
            flex-wrap: wrap;
        }

    }

    .c-product-search-header__filter {
        // flex-container
        display: flex;
        gap: $product-search-header-items-spacing;
        align-items: baseline;

        // flex-item
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;

        @include media-query("<=#{$product-search-header-breakpoint}") {
            // flex-container
            flex-wrap: wrap;

            // flex-item
            flex-basis: 100%;
        }

    }

        .c-product-search-header__filter__label {
            @if ($product-search-header-label-hidden) {
                @include a11y-hidden();
            }
        }

        .c-product-search-header__filter__tags {
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
        }

        .c-product-search-header__filter__reset {
            white-space: nowrap;
        }

    .c-product-search-header__actions {
        // flex-container
        display: flex;
        gap: $product-search-header-actions-spacing;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;

        // flex-item
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        margin-left: auto;
    }

        .c-product-search-header__actions__item {

            @include media-query("<=#{$product-search-header-actions-full-width-breakpoint}") {
                flex-grow: 1;
                flex-shrink: 0;
                flex-basis: calc(50% - #{$product-search-header-actions-spacing * 0.5}); /* [2] */

                > * {
                    justify-content: important(center, true); /* [3] */
                    width: 100%;
                }

            }

        }

        .c-product-search-header__actions__item--wrap {

            @include media-query(">#{$product-search-header-breakpoint}") {
                flex-basis: 100%;
            }

        }

    .c-product-search-header__sort {
        // flex-container
        display: flex;
        justify-content: flex-end;

        @include media-query("<=#{$product-search-header-actions-full-width-breakpoint}") {

            > * {
                flex-grow: 1;
            }

        }

    }

    .c-product-search-header__filter-bar {
        @include float-grid-area(
            $area: $product-search-header-filter-bar-float-grid-area,
        ); /* [15] */
        @include transition(
            $transition-property: "visibility, transform",
            $transition-duration: "0s, #{$product-search-header-filter-bar-transition-duration}",
        );
        top: 0; /* [4] */
        right: 0; /* [4] */
        left: 0; /* [4] */
        width: 100%;
        padding-top: $product-search-header-filter-bar-spacing-vertical;
        padding-bottom: $product-search-header-filter-bar-spacing-vertical;
        background-color: $product-search-header-filter-bar-background-color;
        box-shadow: none; /* [6] */

        @include media-query("<=#{$product-search-header-actions-full-width-breakpoint}") {
            display: block; /* [7| */

            &,
            &#{$product-search-header-filter-bar-visible-class} {
                box-shadow: $product-search-header-filter-bar-box-shadow-inverted; /* [8] */
            }

        }
        @include media-query(">#{$product-search-header-actions-full-width-breakpoint}") {
            position: fixed; /* [4] */
            z-index: $product-search-header-filter-bar-layer;
            transform: translateY(-100%); /* [5] */

            &#{$product-search-header-filter-bar-visible-class} {
                box-shadow: $product-search-header-filter-bar-box-shadow; /* [9] */
            }

        }

        @include media-query(">#{$product-search-header-breakpoint}") {

            .c-product-search--has-sidebar &,
            &.c-product-search-header__filter-bar--sidebar {
                display: none; /* [14] */
            }

        }

        &#{$product-search-header-filter-bar-visible-class} {
            transform: translateY(0); /* [10] */
        }

    }

    .c-product-search-header__filter-bar__inner {
        @include page-wrap();
        display: flex;
        gap: $product-search-header-items-spacing;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .c-product-search-header__filter-bar__title {
        align-self: center;
        margin-bottom: 0; /* [11] */

        @include media-query("<=#{$product-search-header-actions-full-width-breakpoint}") {
            display: none; /* [12] */
        }

    }
