// /* ------------------------------------*\
//     #SHADOWS
// \*------------------------------------ */

$GLOBAL-BOX-SHADOW-OFFSET-X:    0 !default;
$GLOBAL-BOX-SHADOW-OFFSET-Y:    2px !default;
$GLOBAL-BOX-SHADOW-BLUR-RADIUS: 5px !default;
$GLOBAL-BOX-SHADOW-SPREAD:      0 !default;
$GLOBAL-BOX-SHADOW-COLOR:       rgba(0, 0, 0, 0.2) !default;
$GLOBAL-BOX-SHADOW:             $GLOBAL-BOX-SHADOW-OFFSET-X $GLOBAL-BOX-SHADOW-OFFSET-Y $GLOBAL-BOX-SHADOW-BLUR-RADIUS $GLOBAL-BOX-SHADOW-SPREAD $GLOBAL-BOX-SHADOW-COLOR !default;
$GLOBAL-BOX-SHADOW-INVERTED:    $GLOBAL-BOX-SHADOW-OFFSET-X (-$GLOBAL-BOX-SHADOW-OFFSET-Y) $GLOBAL-BOX-SHADOW-BLUR-RADIUS $GLOBAL-BOX-SHADOW-SPREAD $GLOBAL-BOX-SHADOW-COLOR !default;

$DROP-SHADOW-COLOR:             rgba(0, 0, 0, 0.3) !default;
// Sizes values could be used for calculating with shadow sizes
// (e.g. Interaction Layer)
$DROP-SHADOW-LARGE-SIZE:        40px !default;
$DROP-SHADOW-SMALL-SIZE:        4px !default;
$DROP-SHADOW-LARGE:             5px 5px $DROP-SHADOW-LARGE-SIZE $DROP-SHADOW-COLOR !default;
$DROP-SHADOW-SMALL:             0 1px $DROP-SHADOW-SMALL-SIZE 0 $DROP-SHADOW-COLOR !default;

$CARD-SHADOW-OFFSET-X:          0 !default;
$CARD-SHADOW-OFFSET-Y:          1px !default;
$CARD-SHADOW-BLUR-RADIUS:       4px !default;
$CARD-SHADOW-SPREAD:            0 !default;
$CARD-SHADOW:                   $CARD-SHADOW-OFFSET-X $CARD-SHADOW-OFFSET-Y $CARD-SHADOW-BLUR-RADIUS $CARD-SHADOW-SPREAD $DROP-SHADOW-COLOR !default;
$CARD-INACTIVE-SHADOW:          null !default;

$SCROLL-SHADOW-COLOR:           rgba(0, 0, 0, 0.2) !default;
