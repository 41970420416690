/* ------------------------------------*\
    #TAG-LIST
\*------------------------------------ */

$tag-list-item-spacing:            $GLOBAL-SPACING-UNIT-XSMALL !default;
$tag-list-item-spacing-horizontal: $tag-list-item-spacing !default;
$tag-list-item-spacing-vertical:   $tag-list-item-spacing !default;
$tag-list-item-text-size:          $GLOBAL-STATUS-TEXT-SIZE !default; // same as for the tag text size.

/**
 * [1] Reset default list styles.
 * [2] Set font size and line height of list element to the same as the tags in
 *     order to prevent the (larger) base line height from adding spacing on top of
 *     the tags.
 */
.c-tag-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: $tag-list-item-spacing-horizontal;
    row-gap: $tag-list-item-spacing-vertical;
    margin: 0; /* [1] */
    padding: 0; /* [1] */
    list-style: none; /* [1] */

    li {
        @include responsive-font-size($tag-list-item-text-size); /* [2] */
        margin-bottom: 0; /* [1] */
    }

}
