/* ------------------------------------*\
    #ANCHOR
\*------------------------------------ */

/**
 * [1] Exclude `.c-btn` because buttons should always have the same behavior in
 *     all states regardless of the element. `where()`is used to not increase
 *     the specificity.
 */
a:where(:not(.c-btn)) { /* [1] */
    @include base-link();
}
