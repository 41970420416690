/* ------------------------------------*\
    #TEXT-ALIGN
\*------------------------------------ */

/**
 * Helper classes to align elements either left, right or center.
 */

.u-text-left {
    text-align: left !important;
}

.u-text-right {
    text-align: right !important;
}

.u-text-center {
    text-align: center !important;
}



// Loop over our breakpoints defined in _settings.responsive.scss
@each $breakpoint in $BREAKPOINTS {

    // Get the name of the breakpoint.
    $alias: nth($breakpoint, 1);

    @include media-query("<=#{$alias}") {

        .u-text-left\@#{$alias} {
            text-align: left !important;
        }

        .u-text-right\@#{$alias} {
            text-align: right !important;
        }

        .u-text-center\@#{$alias} {
            text-align: center !important;
        }

    }

    @include media-query(">#{$alias}") {

        .u-text-left\@#{$alias}-up {
            text-align: left !important;
        }

        .u-text-right\@#{$alias}-up {
            text-align: right !important;
        }

        .u-text-center\@#{$alias}-up {
            text-align: center !important;
        }

    }

}
