/* ------------------------------------*\
   #CAROUSEL
\*------------------------------------ */

// This component is based on the Flickity library. More styles can be found
// in the `../lib/flickity/` directory.
$carousel-breakpoint:                    xs !default;

$carousel-item-spacing:                  null !default;

$carousel-button-opacity:                null !default;
$carousel-button-opacity-disabled:       0 !default;
$carousel-button-opacity-hover:          null !default;
$carousel-button-transform-hover:        null !default;
$carousel-button-width:                  $GLOBAL-POINTER-TARGET-SIZE !default;
$carousel-button-height:                 $carousel-button-width !default;
$carousel-button-border:                 0 !default;
$carousel-button-border-radius:          null !default;
$carousel-button-background-color:       $COLOR-HIGHLIGHT !default;
$carousel-button-background-color-hover: lighten($carousel-button-background-color, 10%) !default;
$carousel-button-icon-size:              icon-size(xsmall) !default;
$carousel-button-icon-color:             $COLOR-BRAND !default;
$carousel-button-offset:                 0 !default;
$carousel-button-icon-complexity:        null !default;
$carousel-button-next-icon:              get-icon(semantic-forward, $carousel-button-icon-complexity) !default;
$carousel-button-prev-icon:              get-icon(semantic-back, $carousel-button-icon-complexity) !default;
$carousel-button-hide-at-breakpoint:     true !default;

$carousel-button-label:                  false !default;
$carousel-button-label-spacing:          $GLOBAL-SPACING-UNIT-SMALL !default;
$carousel-button-label-shift-hover:      null !default;
$carousel-button-label-font-size:        $GLOBAL-FONT-SIZE !default;

// If `true` the dots will be displayed inside the carousel
$carousel-page-dot-position-inside:      false !default;
$carousel-page-dot-offset:               if($carousel-page-dot-position-inside, $GLOBAL-SPACING-UNIT-SMALL, -($GLOBAL-POINTER-TARGET-SIZE)) !default;
// Creates a safe margin if the dot offset is negative.
$carousel-bottom-safe-space:             if($carousel-page-dot-offset <= 0, -($carousel-page-dot-offset), null) !default;

/**
 * [1] Make sure buttons don't get overlapped by an item.
 * [2] Set the width to 100% to ensure that images always are full width with
 *     their default `max-width: 100%;`.
 * [3] Always center the dots.
 * [4] Add a margin, if the dots are placed outside of the carousel.
 * [5] Center the button vertically.
 * [6] Center the icon and label vertically and horizontally within the button.
 * [7] Center the dots horizontally within the carousel.
 * [8] Add horizontal outer spacing to the carousel items
 *     (e.g. if the design requires space for the buttons)
 * [9] Use opacity in combination with the button transition to fade out
 *      disabled buttons.
 * [10] Hide component until Flickity is fully loaded and prevent "Flash of
 *      visible button".
 * [11] Transform the prev/next button icons on `:hover`
 * [12] Shift prev/next button labels horizontally on `:hover`
 * [13] Reverse order of icon and label.
 * [14] Anchor `.c-carousel__button-label` for previous and next buttons
 *      in respect to their position.
 * [15] `.flickity-viewport` has no fixed height set in fullscreen mode,
 *      so we need to make sure the items do not exceed the slider’s boundaries.
 * [16] Make the image always fit inside the fullscreen container.
 * [17] Add pointer target space to the button labels.
 * [18] Always display the buttons with the `.c-carousel--buttons-visible`
 *      modifier class and overwrite package settings.
 * [19] Always hide the buttons with the `.c-carousel--buttons-on-hover`
 *      modifier class and display them only on hover and overwrite package
 *      settings.
 * [20] Always show fullscreen button on "touch" devices.
 * [21] Use `pointer: coarse` media-query to target browsers with
 *      limited precision as primary input device ("touch").
 *      https://caniuse.com/css-media-interaction
 * [22] Position dots at the bottom in fullscreen to be visible.
 */
.c-carousel {
    @include transition(
        $transition-property: opacity,
    );
    position: relative;
    margin-bottom: $carousel-bottom-safe-space; /* [4] */
    opacity: 0; /* [10] */

    &:hover .flickity-prev-next-button,
    &:focus .flickity-prev-next-button {

        &:not([disabled]) {
            opacity: $carousel-button-opacity-hover;
        }

    }

    &.flickity-enabled {
        opacity: 1; /* [10] */
    }

    &.is-fullscreen {

        .c-carousel__item,
        .c-carousel__image {
            height: 100%; /* [15] */
        }

        .c-carousel__image {
            @include object-fit(contain); /* [16] */
        }

        .flickity-page-dots {
            bottom: 0; /* [22] */
        }

    }

    .flickity-page-dots {
        position: absolute;
        bottom: $carousel-page-dot-offset;
        left: 50%; /* [7] */
        transform: translateX(-50%); /* [7] */
    }

    .flickity-prev-next-button {
        @include transition();
        position: absolute;
        z-index: 1; /* [1] */
        top: 50%; /* [5] */
        display: flex; /* [6] */
        align-items: center; /* [6] */
        justify-content: center; /* [6] */
        width: $carousel-button-width;
        height: $carousel-button-height;
        transform: translateY(-50%); /* [5] */
        border: $carousel-button-border;
        border-radius: $carousel-button-border-radius;
        background-color: $carousel-button-background-color;
        color: $carousel-button-icon-color;
        opacity: $carousel-button-opacity;
        cursor: pointer;

        &.previous {
            left: $carousel-button-offset;

            &::before {
                content: $carousel-button-prev-icon;
            }

        }

        &.next {
            right: $carousel-button-offset;

            &::before {
                content: $carousel-button-next-icon;
            }

        }

        @if ($carousel-button-background-color-hover) and ($carousel-button-background-color-hover != $carousel-button-background-color) {
            &:hover,
            &:focus {
                background-color: $carousel-button-background-color-hover;
            }
        }

        &:focus-visible {
            outline: 0;
        }

        &:not([disabled]) {
            opacity: $carousel-button-opacity; /* [10] */
        }

        &[disabled] {
            opacity: $carousel-button-opacity-disabled; /* [9] */
            pointer-events: none; /* [9] */
        }

        @if ($carousel-button-hide-at-breakpoint) {
            @include media-query("<=#{$carousel-breakpoint}") {
                display: none;
            }
        }

        &::before,
        &::after {
            transition: inherit;
        }

        &::before {
            @include font-size(
                $font-size:   $carousel-button-icon-size,
                $line-height: 1,
            );
            display: block;
        }

        // button label
        @if ($carousel-button-label) {
            $_label-offset: $carousel-button-icon-size;

            &::after {
                content: attr(aria-label);
                position: absolute;
                padding-right: $carousel-button-label-spacing; /* [17] */
                padding-left: $carousel-button-label-spacing; /* [17] */
                font-size: $carousel-button-label-font-size;
            }

            &.next {

                &::before {
                    transform-origin: right center; /* [14] */
                }

                &::after {
                    right: $_label-offset;
                }

            }

            &.previous {

                &::before {
                    transform-origin: left center; /* [14] */
                }

                &::after {
                    left: $_label-offset;
                }

            }

        }

        &:hover {

            &::before { /* prev/next button icon */
                transform: $carousel-button-transform-hover; /* [11] */
            }

            /* stylelint-disable-next-line selector-max-specificity */
            &.next::after {
                @if ($carousel-button-label-shift-hover) {
                    transform: translateX(-$carousel-button-label-shift-hover); /* [12] */
                }
            }

            /* stylelint-disable-next-line selector-max-specificity */
            &.previous::after {
                @if ($carousel-button-label-shift-hover) {
                    transform: translateX($carousel-button-label-shift-hover); /* [12] */
                }
            }

        }

    }

}

    .c-carousel__item {
        width: 100%;

        @if ($carousel-item-spacing) { /* [8] */
            @include media-query(">=#{$carousel-breakpoint}") {
                padding-right: $carousel-item-spacing;
                padding-left: $carousel-item-spacing;
            }
        }

    }

    .c-carousel__image {
        @include object-fit();
        width: 100%; /* [2] */
    }

    .c-carousel--buttons-visible { /* [18] */

        .flickity-prev-next-button:not([disabled]) {
            opacity: 1;
        }

    }

    .c-carousel--buttons-on-hover { /* [19] */
        /* stylelint-disable-next-line scss/media-feature-value-dollar-variable */
        @media (pointer: coarse) { /* [21] */
            .flickity-fullscreen-button {
                opacity: important(1, true); /* [20] */
            }
        }

        .flickity-prev-next-button,
        .flickity-fullscreen-button {
            opacity: 0;
        }

        &:hover,
        &:focus {

            .flickity-prev-next-button,
            .flickity-fullscreen-button {

                &:not([disabled]) {
                    opacity: 1;
                }

            }

        }

    }
