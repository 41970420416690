/* ------------------------------------*\
    #INLINE-GROUP
\*------------------------------------ */

/**
 * An object that arranges items with defined horizontal and
 * vertical spacing in a row. If the items do not fit into one row,
 * they will wrap to the next row.
 */

// Spacing between items
$inline-group-spacing-horizontal:       $GLOBAL-SPACING-UNIT-LARGE !default;
$inline-group-spacing-vertical:         $GLOBAL-SPACING-UNIT !default;

// Spacing for small variant
$inline-group-spacing-small-horizontal: $GLOBAL-SPACING-UNIT !default;
$inline-group-spacing-small-vertical:   $GLOBAL-SPACING-UNIT-SMALL !default;

/**
 * [1] We use `display: flex` instead of `display: inline-block`
 *     to avoid unnecessary white space between items.
 * [2] Set spacing between items via `column-gap` and `row-gap` for flexbox.
 * [3] Defensively reset any child element’s margins.
 * [4] For browsers that do not support `gap` for flexbox (https://caniuse.com/flexbox-gap),
 *     create horizontal and vertical spacing between items by adding margins
 *     around all sides of the items and compensate this by adding negative
 *     margins to the container. This could result in overflow issues if
 *     `.o-inline-group`’s parent container has `overflow: hidden` or
 *     `overscroll: scroll`.
 * [5] Stretch items to fill the container.
 * [6] Align items to the bottom.
 * [7] Center items vertically.
 */

.o-inline-group {
    display: flex; /* [1] */
    flex-wrap: wrap; /* [1] */
    column-gap: $inline-group-spacing-horizontal; /* [2] */
    row-gap: $inline-group-spacing-vertical; /* [2] */
}

    .o-inline-group--small {
        column-gap: $inline-group-spacing-small-horizontal; /* [2] */
        row-gap: $inline-group-spacing-small-vertical; /* [2] */
    }

    .o-inline-group__item {

        > * {
            margin: 0; /* [3] */
        }

    }

    .o-inline-group--stretch {

        .o-inline-group__item {
            flex-grow: 1; /* [5] */
        }

    }

    .o-inline-group--bottom {
        align-items: flex-end; /* [6] */
    }

    .o-inline-group--center {
        align-items: center; /* [7] */
    }
