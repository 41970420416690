/* ------------------------------------*\
    #TILE-SELECT
\*------------------------------------ */

// General config
$tile-select-breakpoint:                               s !default;
$tile-select-box-shadow:                               null !default;
$tile-select-opacity-disabled:                         $OPACITY-MEDIUM !default;
$tile-select-opacity-disabled-selected:                null !default;
$tile-select-padding-top:                              $GLOBAL-SPACING-UNIT !default;
$tile-select-padding-bottom:                           $tile-select-padding-top !default;
$tile-select-padding-horizontal:                       $tile-select-padding-top !default;
$tile-select-transition-property:                      color, background-color, border-color !default;
$tile-select-transition-duration:                      $GLOBAL-TRANSITION-DURATION !default;
$tile-select-transition-timing-function:               $GLOBAL-TRANSITION-TIMING-FUNCTION !default;

// Body foreground
$tile-select-color:                                    null !default;
$tile-select-color-hover:                              null !default;
$tile-select-color-selected:                           null !default;
$tile-select-color-disabled:                           null !default;
$tile-select-color-disabled-selected:                  null !default;
$tile-select-color-error:                              null !default;
$tile-select-color-error-hover:                        $tile-select-color-error !default;
$tile-select-color-error-selected:                     null !default;
$tile-select-color-hint-disabled:                      null !default;
$tile-select-color-hint-background-disabled:           null !default;

// Body background
$tile-select-background-color:                         $COLOR-WHITE !default;
$tile-select-background-color-hover:                   null !default;
$tile-select-background-color-selected:                null !default;
$tile-select-background-color-disabled:                null !default;
$tile-select-background-color-disabled-selected:       null !default;
$tile-select-background-color-error:                   null !default;
$tile-select-background-color-error-hover:             $tile-select-background-color-error !default;
$tile-select-background-color-error-selected:          null !default;
$tile-select-background-color-themes: (
    "alternative": (
        "background-color":                         null,
        "background-color-hover":                   null,
        "background-color-selected":                null,
        "background-color-disabled":                null,
        "background-color-disabled-selected":       null,
        "background-color-error":                   null,
        "background-color-error-selected":          null,
        "check-background-color":                   null,
        "check-background-color-hover":             null,
        "check-background-color-selected":          null,
        "check-background-color-disabled":          null,
        "check-background-color-disabled-selected": null,
        "check-background-color-error":             null,
        "check-background-color-error-selected":    null,
    )
) !default;

// Body border
$tile-select-border-radius:                            null !default;
$tile-select-border-color:                             $COLOR-PALE !default;
$tile-select-border-color-hover:                       $COLOR-BRAND !default;
$tile-select-border-color-selected:                    $COLOR-BRAND !default;
$tile-select-border-color-disabled:                    $COLOR-PALE !default;
$tile-select-border-color-disabled-selected:           $COLOR-PALE !default;
$tile-select-border-color-error:                       $COLOR-ALERT !default;
$tile-select-border-color-error-hover:                 $tile-select-border-color-error !default;
$tile-select-border-color-error-selected:              $COLOR-ALERT !default;
$tile-select-border-width:                             1px !default;

// Checkmark config
$tile-select-check-box-shadow:                         null !default;

$tile-select-check-enable-color-change:                true !default;
$tile-select-check-background:                         "::before" !default;
$tile-select-check-foreground:                         "::after" !default;

$tile-select-check-icon:                               get-icon(semantic-checkmark) !default;
$tile-select-check-icon-background-size:               32px !default;
$tile-select-check-icon-size:                          null !default;
$tile-select-check-border-radius:                      50% !default;

// Checkmark foreground
$tile-select-check-color:                              transparent !default;
$tile-select-check-color-hover:                        transparent !default;
$tile-select-check-color-selected:                     $COLOR-WHITE !default;
$tile-select-check-color-disabled:                     transparent !default;
$tile-select-check-color-disabled-selected:            $COLOR-WHITE !default;
$tile-select-check-color-error:                        transparent !default;
$tile-select-check-color-error-hover:                  $tile-select-check-color-error !default;
$tile-select-check-color-error-selected:               $COLOR-WHITE !default;

// Checkmark background
$tile-select-check-background-color:                   $COLOR-WHITE !default;
$tile-select-check-background-color-hover:             $tile-select-check-background-color !default;
$tile-select-check-background-color-selected:          $COLOR-BRAND !default;
$tile-select-check-background-color-disabled:          $tile-select-check-background-color !default;
$tile-select-check-background-color-disabled-selected: $COLOR-PALE !default;
$tile-select-check-background-color-error:             $COLOR-WHITE !default;
$tile-select-check-background-color-error-hover:       $tile-select-check-background-color-error !default;
$tile-select-check-background-color-error-selected:    $COLOR-ALERT !default;

// Checkmark border
$tile-select-check-border-color:                       $tile-select-border-color !default;
$tile-select-check-border-color-hover:                 $tile-select-border-color-hover !default;
$tile-select-check-border-color-selected:              $tile-select-border-color-selected !default;
$tile-select-check-border-color-disabled:              $tile-select-border-color-disabled !default;
$tile-select-check-border-color-disabled-selected:     $tile-select-border-color-disabled-selected !default;
$tile-select-check-border-color-error:                 $tile-select-border-color-error !default;
$tile-select-check-border-color-error-hover:           $tile-select-check-border-color-error !default;
$tile-select-check-border-color-error-selected:        $tile-select-border-color-error-selected !default;

// Body content
$tile-select-icon-size:                                icon-size(large) !default;
$tile-select-icon-color:                               null !default;
$tile-select-icon-color-hover:                         $tile-select-icon-color !default;
$tile-select-icon-color-selected:                      $tile-select-icon-color !default;
$tile-select-icon-color-disabled:                      $tile-select-icon-color !default;
$tile-select-icon-color-disabled-selected:             $tile-select-icon-color !default;
$tile-select-icon-color-error:                         $tile-select-icon-color !default;
$tile-select-icon-color-error-hover:                   $tile-select-icon-color-error !default;
$tile-select-icon-color-error-selected:                $tile-select-icon-color-error !default;
$tile-select-icon-hidden-breakpoint:                   null !default;
$tile-select-title-font-family:                        null !default;
$tile-select-title-font-weight:                        $GLOBAL-FONT-WEIGHT-BOLD !default;
$tile-select-title-font-size:                          $GLOBAL-HEADING-H4-FONT-SIZE !default;
$tile-select-title-line-height:                        $GLOBAL-HEADING-BASE-LINE-HEIGHT !default;
$tile-select-title-color:                              null !default;
$tile-select-title-letter-spacing:                     null !default;
$tile-select-text-align:                               center !default;
$tile-select-top-area-text-align:                      $tile-select-text-align !default;
$tile-select-bottom-area-text-align:                   $tile-select-text-align !default;

$tile-select-title-margin-top: (
    null:                    $GLOBAL-SPACING-UNIT,
    $tile-select-breakpoint: $GLOBAL-SPACING-UNIT-SMALL
) !default;

$tile-select-text-margin-top:                          $tile-select-title-margin-top !default;

$tile-select-footer-margin-top: (
    null:                    $GLOBAL-SPACING-UNIT,
    $tile-select-breakpoint: $GLOBAL-SPACING-UNIT-SMALL,
) !default;

/*
 *  Mixin defines some common styling declarations for the various component
 *  states.
 */
@mixin tile-select-state-styles(
    $foreground-color:         null,
    $background-color:         null,
    $background-color-variant: null,
    $border-color:             null,
    $check-color:              null,
    $check-background-color:   null,
    $check-border-color:       null,
    $icon-color:               null,
    $opacity:                  null,
    $cursor:                   null
) {
    border-color: $border-color;
    background-color: $background-color;
    opacity: $opacity;
    cursor: $cursor;

    @include vary($loop: $tile-select-background-color-themes) {
        background-color: vary-get($background-color-variant);
    }

    &,
    .c-tile-select__title {
        color: $foreground-color;
    }

    .c-tile-select__icon {
        color: $icon-color;
    }

    @if ($tile-select-check-enable-color-change) {
        &#{$tile-select-check-background} {
            border-color: $check-border-color;
            background-color: $check-background-color;

            @include vary($loop: $tile-select-background-color-themes) {
                background-color: vary-get("check-#{$background-color-variant}");
            }
        }

        &#{$tile-select-check-foreground} {
            color: $check-color;
        }
    }

}

/**
 * [1] Compensate for the additional space required for the check mark at the
 *     top.
 * [2] Ensure tile icon foreground and background use common position.
 * [3] Ensure that check icon is centered.
 * [4] Make `.c-tile-select__body-top` take up all available space to push
 *     `.c-tile-select__body-bottom` to the very bottom of the body.
 * [5] Add half the size of the check mark to the top padding to compensate for
 *     the space the check mark takes up.
 * [6] Apply margin if the element is not the first child in it's container.
 * [7] If the icon is hidden on small screens, the title below it should not
 *     have a top margin.
 * [8] Place the pseudo element at the top center of the `.c-tile-select__body`
 *     And translate it back by half it's width and height for it to be
 *     centered.
 * [9] Override hint colors for selected, disabled or disabled selected tiles.
 * [10] Ensure that all content is centered.
 * [11] Ensure that all content has top spacing.
 * [12] Place all footer content in a new line.
 * [13] Make sure, the footer content does not stretch across the parent
 *      container.
 */
.c-tile-select {
    width: 100%;
    height: 100%;
    padding-top: ($tile-select-check-icon-background-size * 0.5); /* [1] */
}

    .c-tile-select__input {
        @include a11y-hidden();
    }

    .c-tile-select__label {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
    }

    .c-tile-select__body {
        @include responsive-property(
            ("padding-top", responsive-property-modify($tile-select-padding-top, $add: $tile-select-check-icon-background-size * 0.5)), /* [5] */
            ("padding-left", $tile-select-padding-horizontal),
            ("padding-bottom", $tile-select-padding-bottom),
            ("padding-right", $tile-select-padding-horizontal)
        );
        position: relative; /* [1] */
        display: flex;
        flex-direction: column;
        height: 100%;
        border: $tile-select-border-width solid $tile-select-border-color;
        border-radius: $tile-select-border-radius;
        background-color: $tile-select-background-color;
        transition-property: $tile-select-transition-property;
        transition-duration: $tile-select-transition-duration;
        transition-timing-function: $tile-select-transition-timing-function;
        box-shadow: $tile-select-box-shadow;

        @include vary($loop: $tile-select-background-color-themes) {
            background-color: vary-get("background-color");
        }

        &,
        .c-tile-select__title {
            color: $tile-select-color;
        }

        @if ($tile-select-check-enable-color-change) {
            &#{$tile-select-check-background},
            &#{$tile-select-check-foreground} { /* [2] */
                content: "";
                position: absolute;
                top: 0; /* [8] */
                left: 50%; /* [8] */
                display: block;
                width: $tile-select-check-icon-background-size;
                height: $tile-select-check-icon-background-size;
                transform: translate(-50%, -50%); /* [8] */
                transition-property: $tile-select-transition-property;
                transition-duration: $tile-select-transition-duration;
                transition-timing-function: $tile-select-transition-timing-function;
            }

            &#{$tile-select-check-background} {
                border: $tile-select-border-width solid $tile-select-check-border-color;
                border-radius: $tile-select-check-border-radius;
                background-color: $tile-select-check-background-color;
                box-shadow: $tile-select-check-box-shadow;

                @include vary($loop: $tile-select-background-color-themes) {
                    background-color: vary-get("check-background-color");
                }
            }

            &#{$tile-select-check-foreground} {
                @include icon-font($line-height: null);
                content: $tile-select-check-icon;
                font-size: $tile-select-check-icon-size;
                line-height: $tile-select-check-icon-background-size; /* [3] */
                text-align: center; /* [3] */
                color: $tile-select-check-color;
            }
        }


        .c-tile-select__icon {
            color: $tile-select-icon-color;
        }

        // Hover and focus state
        .c-tile-select__input + &:hover,
        .c-tile-select__input:focus + & {
            @include tile-select-state-styles(
                $foreground-color:         $tile-select-color-hover,
                $background-color:         $tile-select-background-color-hover,
                $background-color-variant: "background-color-hover",
                $border-color:             $tile-select-border-color-hover,
                $check-color:              $tile-select-check-color-hover,
                $check-background-color:   $tile-select-check-background-color-hover,
                $check-border-color:       $tile-select-check-border-color-hover,
                $icon-color:               $tile-select-icon-color-hover,
                $cursor:                   pointer
            );
        }

        // Selected state
        .c-tile-select__input:checked + & {
            // @TODO: Add global solution for checking `null` variables BRON-9556
            @if ($tile-select-color-selected) {
                --hint-text-color: #{$tile-select-color-selected}; /* [9] */
            }
            @include tile-select-state-styles(
                $foreground-color:         $tile-select-color-selected,
                $background-color:         $tile-select-background-color-selected,
                $background-color-variant: "background-color-selected",
                $border-color:             $tile-select-border-color-selected,
                $check-color:              $tile-select-check-color-selected,
                $check-background-color:   $tile-select-check-background-color-selected,
                $check-border-color:       $tile-select-check-border-color-selected,
                $icon-color:               $tile-select-icon-color-selected
            );
        }

        // Disabled state
        .c-tile-select__input:disabled + & {
            // @TODO: Add global solution for checking `null` variables BRON-9556
            @if ($tile-select-color-hint-disabled) {
                --hint-text-color: #{$tile-select-color-hint-disabled}; /* [9] */
            }
            @if ($tile-select-color-hint-disabled) {
                --hint-text-color-hover: #{$tile-select-color-hint-disabled}; /* [9] */
            }
            @if ($tile-select-color-hint-background-disabled) {
                --hint-background-color: #{$tile-select-color-hint-background-disabled}; /* [9] */
            }
            @include tile-select-state-styles(
                $foreground-color:         $tile-select-color-disabled,
                $background-color:         $tile-select-background-color-disabled,
                $background-color-variant: "background-color-disabled",
                $border-color:             $tile-select-border-color-disabled,
                $check-color:              $tile-select-check-color-disabled,
                $check-background-color:   $tile-select-check-background-color-disabled,
                $check-border-color:       $tile-select-check-border-color-disabled,
                $icon-color:               $tile-select-icon-color-disabled,
                $opacity:                  $tile-select-opacity-disabled,
                $cursor:                   auto
            );
        }

        // Disabled-selected state
        .c-tile-select__input:checked:disabled + & {
            // @TODO: Add global solution for checking `null` variables BRON-9556
            @if ($tile-select-color-hint-disabled) {
                --hint-text-color: #{$tile-select-color-hint-disabled}; /* [9] */
            }
            @if ($tile-select-color-hint-disabled) {
                --hint-text-color-hover: #{$tile-select-color-hint-disabled}; /* [9] */
            }
            @include tile-select-state-styles(
                $foreground-color:         $tile-select-color-disabled-selected,
                $background-color:         $tile-select-background-color-disabled-selected,
                $background-color-variant: "background-color-disabled-selected",
                $border-color:             $tile-select-border-color-disabled-selected,
                $check-color:              $tile-select-check-color-disabled-selected,
                $check-background-color:   $tile-select-check-background-color-disabled-selected,
                $check-border-color:       $tile-select-check-border-color-disabled-selected,
                $icon-color:               $tile-select-icon-color-disabled-selected,
                $opacity:                  $tile-select-opacity-disabled-selected
            );
        }

        // Error and error-hover state
        .is-error & {
            @include tile-select-state-styles(
                $foreground-color:         $tile-select-color-error,
                $background-color:         $tile-select-background-color-error,
                $background-color-variant: "background-color-error",
                $border-color:             $tile-select-border-color-error,
                $check-color:              $tile-select-check-color-error,
                $check-background-color:   $tile-select-check-background-color-error,
                $check-border-color:       $tile-select-check-border-color-error,
                $icon-color:               $tile-select-icon-color-error
            );
        }

        // Error-hover and error-focus state
        .is-error .c-tile-select__input:hover + &,
        .is-error .c-tile-select__input:focus + & {
            @include tile-select-state-styles(
                $foreground-color:         $tile-select-color-error-hover,
                $background-color:         $tile-select-background-color-error-hover,
                $background-color-variant: "background-color-error-hover",
                $border-color:             $tile-select-border-color-error-hover,
                $check-color:              $tile-select-check-color-error-hover,
                $check-background-color:   $tile-select-check-background-color-error-hover,
                $check-border-color:       $tile-select-check-border-color-error-hover,
                $icon-color:               $tile-select-icon-color-error-hover
            );
        }

        // Error-selected state
        .is-error .c-tile-select__input:checked + & {
            @include tile-select-state-styles(
                $foreground-color:         $tile-select-color-error-selected,
                $background-color:         $tile-select-background-color-error-selected,
                $background-color-variant: "background-color-error-selected",
                $border-color:             $tile-select-border-color-error-selected,
                $check-color:              $tile-select-check-color-error-selected,
                $check-background-color:   $tile-select-check-background-color-error-selected,
                $check-border-color:       $tile-select-check-border-color-error-selected,
                $icon-color:               $tile-select-icon-color-error-selected
            );
        }

    }

    .c-tile-select__body-top {
        flex-grow: 1; /* [4] */
        flex-shrink: 1;
        flex-basis: auto;
        text-align: $tile-select-top-area-text-align;
    }

    .c-tile-select__body-bottom {
        @include responsive-property("margin-top", $tile-select-footer-margin-top); /* [11] */
        @include responsive-property("row-gap", $tile-select-footer-margin-top); /* [11] */
        display: flex;
        justify-content: $tile-select-bottom-area-text-align; /* [10] */
        flex-direction: column; /* [12] */
        align-items: center; /* [13] */
    }

    .c-tile-select__icon {
        display: block;
        font-size: $tile-select-icon-size;
    }

    // @DEPRECATED: visibility handled via conditional element or optional utility
    .c-tile-select__icon--hidden-on-small-screens {
        @if ($tile-select-icon-hidden-breakpoint != null) {
            @include media-query("<=#{$tile-select-icon-hidden-breakpoint}") {
                display: none;
            }
        }
    }

    .c-tile-select__title,
    .c-tile-select__text {
        display: block;
    }

    .c-tile-select__title {
        @include responsive-font-size($tile-select-title-font-size);
        font-family: $tile-select-title-font-family;
        font-weight: $tile-select-title-font-weight;
        line-height: $tile-select-title-line-height;
        letter-spacing: $tile-select-title-letter-spacing;
        color: $tile-select-title-color;
    }

    * + .c-tile-select__title { /* [6] */
        @include responsive-property("margin-top", $tile-select-title-margin-top);
    }

    * + .c-tile-select__text { /* [6] */
        @include responsive-property("margin-top", $tile-select-text-margin-top);
    }
