// /* ------------------------------------*\
//     #DESCRIPTION-LIST-ACCORDION (Settings)
// \*------------------------------------ */

$description-list-accordion-accordion-border-bottom: 1px solid $COLOR-BORDER !default;
$description-list-accordion-header-border-top:       $description-list-accordion-accordion-border-bottom !default;

$description-list-accordion-title-value-font-weight: $GLOBAL-FONT-WEIGHT-BOLD !default;
$description-list-accordion-item-value-font-weight:  $description-list-accordion-title-value-font-weight !default;

$description-list-accordion-item-label-font-weight:  $GLOBAL-FONT-WEIGHT-LIGHT !default;

$description-list-accordion-item-spacing-vertical:   $GLOBAL-SPACING-UNIT-XSMALL !default;

$description-list-accordion-total-label-font-size:   $FS1 !default;
$description-list-accordion-total-value-font-size:   $description-list-accordion-total-label-font-size !default;

// Responsive font size opt-out.
$description-list-accordion-title-label-font-size: (
    null: map-get($FS0, null),
) !default;
