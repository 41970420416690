/* ------------------------------------*\
    #PROGRESS
\*------------------------------------ */

$progress-height:                   $PROGRESS-HEIGHT !default;
$progress-value-background:         $PROGRESS-PROGRESS-BACKGROUND !default;
$progress-value-background-success: $PROGRESS-PROGRESS-BACKGROUND-SUCCESS !default;
$progress-value-background-error:   $PROGRESS-PROGRESS-BACKGROUND-ERROR !default;
$progress-border-radius:            $PROGRESS-BORDER-RADIUS !default;
$progress-track-background:         $PROGRESS-TRACK-BACKGROUND !default;
$progress-themes:                   $PROGRESS-THEMES !default;

/**
 * [1] Reset browser-default styles.
 * [2] Set track background. Mainly used for Firefox as Firefox does not support
 *     pseudo element for track.
 */
.c-progress {
    --progress-track-background: #{$progress-track-background};

    @include transition();
    display: block;
    appearance: none; /* [1] */
    width: 100%;
    height: $progress-height;
    border: 0; /* [1] */
    border-radius: if($progress-border-radius, $progress-border-radius, null);
    background-color: var(--progress-track-background); /* [2] */

    // THEMING
    @include vary($loop: $progress-themes) {
        @if (vary-get("background-color")) {
            --progress-track-background: #{vary-get("background-color")};
        }
    }

    /**
     * TRACK ("background)
     */
    &::-webkit-progress-bar {
        border-radius: if($progress-border-radius, $progress-border-radius, null);
        background-color: var(--progress-track-background); /* [2] */

        @include vary($loop: $progress-themes) {
            @if (vary-get("background-color")) {
                --progress-track-background: #{vary-get("background-color")};
            }
        }
    }

    /**
     * VALUE (The "filled" part of the progress bar)
     */
    &::-webkit-progress-value {
        --progress-value-background: #{$progress-value-background};

        @include transition();
        border-radius: if($progress-border-radius, $progress-border-radius, null);
        background-color: var(--progress-value-background);

        @at-root .is-success.c-progress::-webkit-progress-value {
            --progress-value-background: #{$progress-value-background-success};
        }

        @at-root .is-error.c-progress::-webkit-progress-value {
            --progress-value-background: #{$progress-value-background-error};
        }

    }

    /**
     * Firefox-specific styling.
     *
     * Firefox supports only a pseudo element for value.
     * The track background is styled via the `<progress>` element [2].
     */
    &::-moz-progress-bar {
        --progress-value-background: #{$progress-value-background};

        border-radius: if($progress-border-radius, $progress-border-radius, null);
        background-color: var(--progress-value-background);

        @at-root .is-success.c-progress::-moz-progress-bar {
            --progress-value-background: #{$progress-value-background-success};
        }

        @at-root .is-error.c-progress::-moz-progress-bar {
            --progress-value-background: #{$progress-value-background-error};
        }

    }

}
