/* ------------------------------------*\
    #Flatpickr (Fallback)
\*------------------------------------ */

/**
 * [1] Fallback for non-supporting browser for `:has` selector.
 *     This will always show the InRange-Start/End background even when no
 *     range is selected (yet).
 *     https://caniuse.com/css-has
 */
@include supports-not-has-selector() {

    .flatpickr-day {

        &.startRange,
        &.endRange {

            &::before {
                background-color: $flatpickr-day-range-background-color; /* [1] */
            }

        }

    }

}
