/* ------------------------------------*\
    #MEDIA-GRID
\*------------------------------------ */
@use "sass:math" as *;

/**
 * The media grid component places images or icons (with optional text below)
 * next to each other in a smart way, so the amount of items doesn't matter. The
 * items wrap dynamically into new lines, depending on the viewport. It is built
 * with CSS grid.
 */

$media-grid-spacing:                $GLOBAL-SPACING-UNIT !default;
$media-grid-label-font-size:        $FS-1 !default;
$media-grid-label-font-weight:      $GLOBAL-FONT-WEIGHT-BOLD !default;
$media-grid-label-text-transform:   uppercase !default;
$media-grid-label-color:            null !default;
$media-grid-label-color-hover:      null !default;
$media-grid-label-spacing-vertical: $GLOBAL-SPACING-UNIT-XSMALL !default;

$media-grid-image-opacity-hover:    0.3 !default;
$media-grid-image-blur-on-hover:    null !default;

$media-link-focus-outline-width:    2px !default;
$media-link-focus-outline-style:    $GLOBAL-FOCUS-STYLE !default;
$media-link-focus-outline-color:    $GLOBAL-FOCUS-COLOR !default;
$media-link-focus-outline-offset:   2px !default;

$media-grid-item-count:             6 !default;

$media-grid-item-responsive-counts: (
    m:  4,
    s:  3,
    xs: 2,
) !default;

$media-grid-image-aspect-ratios: (
    "1/1",
    "4/3",
    "16/9",
) !default;

/**
 * [1] Fade out all grid item images except the hovered one on hover and focus.
 * [2] Remove link-border for brands with set border.
 * [3] Ensure equal aspect ratios
 *     even if the images have different ratios and sizes.
 * [4] Ensure correct sizing with fixed aspect ratio.
 * [5] Use `auto-fit` to allow horizontal alignment.
 * [6] Set `width` to apply `aspect-ratio` in non-Chromium browsers.
 *     https://stackoverflow.com/a/70160430
 */

.c-media-grid {
    --column-count: #{$media-grid-item-count};

    display: grid;
    gap: $media-grid-spacing;
    grid-template-columns: repeat(auto-fit, calc((100% / var(--column-count) - #{$media-grid-spacing}))); /* [5] */
    justify-content: center; /* [5] */
    margin: 0;

    @each $breakpoint, $count in $media-grid-item-responsive-counts {
        @include media-query("<=#{$breakpoint}") {
            --column-count: #{$count};
        }
    }

    /* [3] */
    @each $ratio in $media-grid-image-aspect-ratios {
        &[data-aspect-ratio="#{$ratio}"] {
            --aspect-ratio: #{$ratio};
        }
    }

    /* stylelint-disable-next-line scss/media-feature-value-dollar-variable */
    @media (prefers-reduced-motion: no-preference) and (pointer: fine) {

        @include supports-has-selector() {
            &:has(.c-media-grid__link:where(:hover, :focus-visible)) .c-media-grid__link:where(:not(:hover, :focus-visible)) {

                .c-media-grid__image {
                    opacity: $media-grid-image-opacity-hover; /* [1] */

                    @if ($media-grid-image-blur-on-hover) {
                        filter: blur($media-grid-image-blur-on-hover);
                    }
                }

            }
        }

    }

}

    .c-media-grid__image {
        @include transition();
        aspect-ratio: var(--aspect-ratio, auto);

        .c-media-grid[data-aspect-ratio] & {
            width: 100%; /* [6] */
            object-fit: contain; /* [4] */
        }

    }

    .c-media-grid__item {
        margin: 0;
        list-style: none;
    }

    .c-media-grid__link {
        display: block;
        border: 0; /* [2] */
        text-decoration: none;
        text-align: center;

        &:focus-visible {
            @include focus-styles(
                $outline-width:  $media-link-focus-outline-width,
                $outline-style:  $media-link-focus-outline-style,
                $outline-color:  $media-link-focus-outline-color,
                $outline-offset: $media-link-focus-outline-offset,
            );
        }

        &:hover,
        &:focus-visible {

            .c-media-grid__label {
                color: $media-grid-label-color-hover;
            }

        }

    }

    .c-media-grid__label {
        @include transition(
            $transition-property: color,
        );
        @include responsive-font-size(
            $font-size:   $media-grid-label-font-size,
            $line-height: 1
        );
        display: block;
        margin-top: $media-grid-label-spacing-vertical;
        font-weight: $media-grid-label-font-weight;
        text-transform: if($media-grid-label-text-transform, $media-grid-label-text-transform, null);
        color: $media-grid-label-color;
    }

