/* ------------------------------------*\
    #IMAGES
\*------------------------------------ */

/**
 * All our images are fluid, thus all getting `max-width: 100%`.
 * Never ever set `width` and `height` attributes in HTML on `<img>` elements!
 *
 * [1] Fluid images for responsive purposes.
 * [2] Setting `vertical-align` removes the whitespace that appears under `img`
 *     elements when they are dropped into a page as-is. This is a afer
 *     alternative to using `display: block;` for the `<img>`.
 * [3] Visually offset `alt` text from surrounding copy (useful if the image may
 *     not load properly).
 */
img {
    max-width: 100%; /* [1] */
    font-style: italic; /* [3] */ // @TODO: BRON-4576
    vertical-align: middle; /* [2] */
}
