/* ------------------------------------*\
    #MEDIA-TEASER
\*------------------------------------ */

$media-teaser-breakpoint:                          s !default;
$media-teaser-is-image-ratio-flexible:             false !default;

// Responsive properties:
$media-teaser-spacing-top:                         null !default;
$media-teaser-spacing-bottom:                      null !default;
$media-teaser-spacing-is-padding:                  false !default;

$media-teaser-vertical-spacing-top:                $media-teaser-spacing-top !default;
$media-teaser-vertical-spacing-bottom:             $media-teaser-spacing-bottom !default;

// Vertical alignment options: "top", "center" or "bottom" (without quotes)
$media-teaser-media-alignment-vertical:            top !default;
$media-teaser-media-transform:                     scale(1.03) !default;

$media-teaser-caption-alignment-vertical:          center !default;
$media-teaser-caption-background-color:            null !default;

// Spacing away from the image:
$media-teaser-caption-spacing-horizontal-outside:  0 !default;
// Spacing between image and caption:
$media-teaser-caption-spacing-horizontal-inside: (
    null:                     $GLOBAL-SPACING-UNIT,
    $media-teaser-breakpoint: 0,
) !default;
$media-teaser-vertical-caption-spacing-horizontal: $media-teaser-caption-spacing-horizontal-outside !default;
$media-teaser-caption-spacing-vertical: (
    null:                     $GLOBAL-SPACING-UNIT-SMALL,
    $media-teaser-breakpoint: $GLOBAL-SPACING-UNIT,
) !default;
$media-teaser-caption-spacing-top:                 $media-teaser-caption-spacing-vertical !default;
$media-teaser-caption-spacing-bottom:              $media-teaser-caption-spacing-vertical !default;
$media-teaser-vertical-caption-spacing-top:        $media-teaser-caption-spacing-top !default;
$media-teaser-vertical-caption-spacing-bottom:     $media-teaser-caption-spacing-bottom !default;

$media-teaser-caption-inner-spacing-default:       $GLOBAL-SPACING-UNIT !default;
// Instead of overwriting `$media-teaser-caption` to customize the different
// elements, you can overwrite only certain values by defining the variable
// `$media-teaser-caption-overwrites` in the brand's settings file.
$media-teaser-caption-overwrites: () !default;
$media-teaser-caption: (
    pretitle: (
        margin-bottom: $media-teaser-caption-inner-spacing-default,
    ),
    title: (
        margin-bottom: $media-teaser-caption-inner-spacing-default,
    ),
    body: (
        margin-bottom: $media-teaser-caption-inner-spacing-default,
    ),
    footer: (
        // add styles here
    )
) !default;
// Deep-merge content configuration with overwrites up to a depth of 2
$media-teaser-caption-merged: map-deep-merge($media-teaser-caption, $media-teaser-caption-overwrites, 2) !default;

$media-teaser-marketing-caption-spacing-horizontal-inside: (
    null:                     $GLOBAL-SPACING-UNIT,
    $media-teaser-breakpoint: $GLOBAL-SPACING-UNIT-SMALL,
) !default;

$media-teaser-themes: (
    "alternative": (
        "caption-background-color": null,
    )
) !default;

// Style-hook: `[data-marketing-theme]`
$media-teaser-marketing-themes: $MARKETING-THEMES !default;

/**
 * [1]  `top` does not have to be aligned, since it's the default.
 * [2]  Convert alignment `bottom` to `flex-end`.
 * [3]  Only align vertically if the ratio is not flexible. If the ratio is
 *      flexible, the image will always take up 100% of the available height and
 *      no alignment is necessary.
 * [4]  Reverse the order of image and the caption.
 * [5]  If inside and outside spacings are the same, there is no need to
 *      overwrite them for the reversed variant.
 * [6]  Add styling for caption elements.
 * [7]  Make both flex-items the same width. Note that setting all flex items
 *      to the same width using `flex: 1 1 0;` will not work here because it
 *      takes paddings into account and will end up with different widths for
 *      some brands.
 * [8]  Remove all bottom-margins from any last element.
 * [9]  Make it configurable if top and bottom spacings are set as margins or
 *      paddings.
 * [10] Set the positioning context for the link spanning.
 * [11] Fake a pointer cursor for the full-link variant.
 * [12] Transform the image when the container receives either a focus-within or hover.
 * [13] This collapses the `.c-media-teaser__media` on content children.
 *      The `0%` is mandatory to make this work on browsers not supporting `align-self`.
 * [14] Clip the image to the `.c-media-teaser__media` when it is scaled.
 * [15] Progressive enhancement for [13].
 * [16] Make the link spanning pseudo from [17] escape its link parent element which has a
 *      relative position context from the normalize styles.
 * [17] This pseudo element makes the parent element spanning across the whole
 *      container set in [10].
 * [18] For the video variant `.c-media-teaser--video` we only span across the
 *      `.c-media-teaser__caption`.
 * [19] Disable transitions for touch devices.
 * [20] Elevate all links and buttons to make them appear on-top of the link span
 *      inside the `.c-media-teaser--full-link` variant.
 */

@mixin media-teaser-align-vertical($alignment) {
    @if ($alignment != top) { /* [1] */
        display: flex;
        flex-direction: column;
        justify-content: if($alignment == bottom, flex-end, $alignment); /* [2] */
    }
}

.c-media-teaser {

    $margin-or-padding: if($media-teaser-spacing-is-padding, "padding", "margin"); /* [9] */

    @include responsive-property(
        ("#{$margin-or-padding}-top",    $media-teaser-spacing-top),
        ("#{$margin-or-padding}-bottom", $media-teaser-spacing-bottom)
    );

    &:not(.c-media-teaser--vertical) {
        @include media-query(">#{$media-teaser-breakpoint}") {
            display: flex;

            .c-media-teaser__media,
            .c-media-teaser__caption {
                flex-grow: 0; // initial value
                flex-shrink: 1; // initial value
                flex-basis: 50%;  /* [7] */
            }

            @if not $media-teaser-is-image-ratio-flexible { /* [3] */
                .c-media-teaser__media {
                    @include media-teaser-align-vertical($media-teaser-media-alignment-vertical);
                }

                .c-media-teaser__image {
                    // @TODO: needed?
                    flex-grow: 0; // initial value
                    flex-basis: auto; // initial value
                }
            }

            .c-media-teaser__caption {
                @include media-teaser-align-vertical($media-teaser-caption-alignment-vertical);
            }

            &.c-media-teaser--reversed {
                flex-direction: row-reverse; /* [4] */

                @if ($media-teaser-caption-spacing-horizontal-inside != $media-teaser-caption-spacing-horizontal-outside) { /* [5] */
                    .c-media-teaser__caption {
                        @include responsive-property(
                            ("padding-right", $media-teaser-caption-spacing-horizontal-inside),
                            ("padding-left",  $media-teaser-caption-spacing-horizontal-outside)
                        );
                    }
                }
            }

        }
    } // &:not(.c-media-teaser--vertical) END

    &.c-media-teaser--vertical {
        @include responsive-property(
            ("#{$margin-or-padding}-top",    $media-teaser-vertical-spacing-top),
            ("#{$margin-or-padding}-bottom", $media-teaser-vertical-spacing-bottom)
        );

        /**
         * Allow bottom alignment for footer when in equal-height layout.
         * Affects only vertical layout (via modifier, not MQ).
         */
        display: flex;
        flex-direction: column;

        .c-media-teaser__caption {
            // flex-container
            display: flex;
            flex-direction: column;

            // flex-item
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
        }

        .c-media-teaser__footer {
            margin-top: auto;
        }

    }

    &[data-marketing-theme] {

        @if not ($media-teaser-caption-background-color) {
            @include vary($create: custom, $loop: $media-teaser-marketing-themes) {
                &[data-marketing-theme="#{$vary-loop-entity}"] {
                    background-color: vary-get("background-color");
                    color: vary-get("text-color");
                }
            }
        }

    }

}

    .c-media-teaser__caption {
        @include responsive-property(
            ("padding-top",      $media-teaser-caption-spacing-top),
            ("padding-right",    $media-teaser-caption-spacing-horizontal-outside),
            ("padding-bottom",   $media-teaser-caption-spacing-bottom),
            ("padding-left",     $media-teaser-caption-spacing-horizontal-inside)
        );

        background-color: $media-teaser-caption-background-color;

        @include vary($loop: $media-teaser-themes) {
            @include responsive-property("background-color", vary-get("caption-background-color"));
        }

        .c-media-teaser--vertical & {
            @include responsive-property(
                ("padding-top",      $media-teaser-vertical-caption-spacing-top),
                ("padding-right",    $media-teaser-vertical-caption-spacing-horizontal),
                ("padding-bottom",   $media-teaser-vertical-caption-spacing-bottom),
                ("padding-left",     $media-teaser-vertical-caption-spacing-horizontal)
            );
        }

        @if ($media-teaser-caption-background-color) {
            @include vary($create: custom, $loop: $media-teaser-marketing-themes) {
                [data-marketing-theme="#{$vary-loop-entity}"] & {
                    background-color: vary-get("background-color");
                }
            }

        }

        // Add horizontal spacing. If a background color is not used by default,
        // this is required to avoid content right next to
        // visual component "boundaries" created by background color.
        [data-marketing-theme] & {
            @include responsive-property(
                ("padding-right", $media-teaser-marketing-caption-spacing-horizontal-inside),
                ("padding-left",  $media-teaser-marketing-caption-spacing-horizontal-inside)
            );
        }

    }


    .c-media-teaser__caption *:last-child {
        margin-bottom: 0; /* [8] */
    }

    @if $media-teaser-is-image-ratio-flexible {
        .c-media-teaser__image {
            @include object-fit();
            width: 100%;
            height: 100%;
        }
    }

    @include responsive-property-map($media-teaser-caption-merged, ".c-media-teaser__"); /* [6] */

.c-media-teaser--full-link {
    position: relative; /* [10] */

    &:hover {
        cursor: pointer; /* [11] */
    }

    &:hover,
    &:focus-within {

        .c-media-teaser__image {
            transform: $media-teaser-media-transform; /* [12] */
        }

    }

    a,
    button { /* [20] */
        position: relative;
        z-index: 1;
    }

    .c-media-teaser__media {
        height: 0%; /* [13] */
        overflow: hidden; /* [14] */

        @supports (align-self: flex-start) { /* [15] */
            align-self: flex-start;
            height: auto;
        }

    }

    .c-media-teaser__image {
        @include transition(
            $transition-property: transform,
            $transition-duration: 2.2s,
            $transition-timing-function: cubic-bezier(0.14, 1.12, 0.67, 0.99)
        );

        /* stylelint-disable-next-line scss/media-feature-value-dollar-variable */
        @media (pointer: coarse) { /* [19] */
            transition: none;
        }

    }


    .c-media-teaser__link {
        position: static; /* [16] */

        &::after { /* [17] */
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
        }

    }

    &.c-media-teaser--video .c-media-teaser__caption {
        position: relative;  /* [18] */
    }

}
