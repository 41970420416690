// /* ------------------------------------*\
//     #TOAST-NOTIFICATION
// \*------------------------------------ */

$skoda-toast-notification-status-border-width:                   $NOTIFICATION-STATUS-BORDER-WIDTH !default;
$skoda-toast-notification-status-themes:                         map-remove($STATUS-THEMES, "info") !default; // Use regular status themes EXCEPT "info".

$skoda-toast-notification-close-background-color-hover:          $BUTTON-ICON-INTERACTION-BACKGROUND-COLOR-HOVER-ON-DARK !default;
$skoda-toast-notification-close-background-size:                 $BUTTON-ICON-INTERACTION-SIZE-SMALL !default;
$skoda-toast-notification-on-light-close-background-color-hover: $BUTTON-ICON-INTERACTION-BACKGROUND-COLOR-HOVER-ON-LIGHT !default;
$skoda-toast-notification-focus-outline-color:                   $COLOR-WHITE !default;
$skoda-toast-notification-on-light-focus-outline-color:          $GLOBAL-FOCUS-COLOR !default;

.c-toast-notification {
    --focus-outline-color: #{$skoda-toast-notification-focus-outline-color};

    &[data-marketing-theme] {
        --focus-outline-color: #{$skoda-toast-notification-on-light-focus-outline-color};
    }

    /* stylelint-disable-next-line order/order */
    @include vary($create: modifier, $loop: $skoda-toast-notification-status-themes) {

        .c-toast-notification__content {
            border-left: 0;

            &::after {
                background-color: vary-get("background-color");
            }

        }

    }

    .c-toast-notification__content {
        position: relative;

        // Toast notification status border.
        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: $skoda-toast-notification-status-border-width;
        }

    }

}

    .c-toast-notification__close {
        @include button-icon-states(
            $size:                   $skoda-toast-notification-close-background-size,
            $background-color-hover: $skoda-toast-notification-close-background-color-hover,
            $focus-outline-color:    $skoda-toast-notification-focus-outline-color,
        );

        [data-marketing-theme] & {

            &:hover::after {
                background-color: $skoda-toast-notification-on-light-close-background-color-hover;
            }

            &::after {
              --focus-outline-color: #{$skoda-toast-notification-on-light-focus-outline-color};
            }

        }

    }
