/* ------------------------------------*\
    #INBOX-TABLE (Settings)
\*------------------------------------ */

$inbox-table-subheading-padding-vertical:      false !default;
$inbox-table-subheading-margin-top:            $GLOBAL-SPACING-UNIT !default;
$inbox-table-subheading-margin-bottom:         $GLOBAL-SPACING-UNIT-XSMALL !default;
$inbox-table-subheading-font-size:             $FS1 !default;
$inbox-table-subheading-font-weight:           $GLOBAL-FONT-WEIGHT-BOLD !default;
$inbox-table-td-indicator-dot-offset-vertical: 0.3em !default; // same value as EMIL `$status-message-icon-shift` as this is the same pattern

$inbox-table-tr-responsive-spacing-vertical:   0 !default;
$inbox-table-td-date-responsive-margin-bottom: $GLOBAL-SPACING-UNIT-SMALL !default;
