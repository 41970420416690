/* ------------------------------------*\
    #BASE-LINK
\*------------------------------------ */

/**
 * Makes it possible to style arbitrary elements and components as inline link.
 * [1] Reset all possible vendor button styles except focus styles.
 * [2] Add the shared link styles to the utility.
 * [3] Make sure to always inline the element.
 * [4] Reset submit input styles.
 */

.u-base-link {
    @include reset-button($remove-focus: false); /* [1] */
    @include base-link($important: true); /* [2] */

    display: inline !important; /* [3] */

    &[type="submit"] {
        appearance: none; /* [4] */
        font-family: inherit; /* [4] */
    }

}
