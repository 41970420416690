/* ------------------------------------*\
    #FORM-FIELD
\*------------------------------------ */

@use "sass:math" as *;

$form-field-label-color:                                $GLOBAL-INPUT-LABEL-COLOR !default;
$form-field-label-color-hover:                          $GLOBAL-INPUT-LABEL-COLOR-HOVER !default;
$form-field-label-color-success:                        $GLOBAL-INPUT-LABEL-COLOR-SUCCESS !default;
$form-field-label-color-success-hover:                  null !default;
$form-field-label-color-active:                         $GLOBAL-INPUT-LABEL-COLOR-ACTIVE !default;
$form-field-label-color-focus:                          $GLOBAL-INPUT-LABEL-COLOR-FOCUS !default;
$form-field-label-color-error:                          $GLOBAL-INPUT-LABEL-COLOR-ERROR !default;
$form-field-label-color-error-hover:                    null !default;
$form-field-label-color-disabled:                       $GLOBAL-INPUT-LABEL-COLOR-DISABLED !default;
$form-field-label-color-readonly:                       $GLOBAL-INPUT-LABEL-COLOR-READONLY !default;
$form-field-label-color-hint:                           $form-field-label-color !default;
$form-field-label-color-hint-link:                      $link-color !default; // @TODO: cross-module reference

// Enable/disable floating labels.
$form-field-label-floating:                             false !default;
$form-field-label-floating-reset-transform-no-floating: false !default;

$form-field-label-spacing:                              $GLOBAL-SPACING-UNIT-XSMALL !default;
$form-field-label-spacing-inline:                       $form-field-label-spacing !default;
$form-field-label-spacing-inline-horizontal:            $form-field-label-spacing-inline !default;
$form-field-label-spacing-inline-vertical:              $form-field-label-spacing-inline !default;

$form-field-label-spacing-textarea:                     $form-field-label-spacing !default;
$form-field-label-spacing-hint:                         $GLOBAL-SPACING-UNIT-XSMALL !default;
$form-field-label-spacing-hint-error:                   ($form-field-label-spacing - $GLOBAL-SPACING-UNIT) !default; // @TODO: remove label-spacing dependency
$form-field-label-text-size:                            $GLOBAL-INPUT-LABEL-SIZE !default;
$form-field-label-line-height:                          none !default;
$form-field-label-line-height-hint:                     none !default;
$form-field-label-font-weight:                          $GLOBAL-INPUT-LABEL-FONT-WEIGHT !default;
$form-field-label-font-weight-hint:                     null !default;

// Calculate target size to have the label the same size as the input when not floated.
$form-field-label-floating-scale-in:                    div(map-get($GLOBAL-INPUT-FONT-SIZE, null), map-get($GLOBAL-INPUT-LABEL-SIZE, null)) !default;
// Set position of floating labels.
$form-field-label-floating-position-border-multiplier:  if($GLOBAL-INPUT-BORDER-BOTTOM-ONLY, 1, 2) !default;

// <input-height> - <input-border> - <label-spacing>
$form-field-label-floating-position-in:                 ($GLOBAL-INPUT-HEIGHT - ($GLOBAL-INPUT-BORDER-WIDTH * $form-field-label-floating-position-border-multiplier) - $form-field-label-spacing) !default;
$form-field-label-floating-position-out:                -($form-field-label-spacing) !default;
$form-field-label-floating-translate-out:               (0, ($form-field-label-floating-position-out), 0) !default;
$form-field-label-floating-translate-in:                ($GLOBAL-INPUT-PADDING-HORIZONTAL, $form-field-label-floating-position-in, 0) !default;

// <input-border> + <padding-vertical> + <text-height>
$form-field-label-floating-position-out-textarea:       -($form-field-label-spacing-textarea) !default;
$form-field-label-floating-position-in-textarea:        ($GLOBAL-INPUT-BORDER-WIDTH + $GLOBAL-INPUT-TEXTAREA-PADDING-VERTICAL + $GLOBAL-INPUT-TEXTAREA-LINE-HEIGHT) !default;

$form-field-label-floating-translate-out-textarea:      (0, $form-field-label-floating-position-out-textarea, 0) !default;
$form-field-label-floating-translate-in-textarea:       ($GLOBAL-INPUT-TEXTAREA-PADDING-HORIZONTAL, $form-field-label-floating-position-in-textarea, 0) !default;

/**
 * [1]  Sets `transform-origin` to bottom/left for all inputs.
 *      This also fixes the blurry text issue when scale is used.
 *      See https://stackoverflow.com/questions/29037176/preventing-blurry-rendering-with-transform-scale/29037803
 * [2]  We have to scale up the label when floated in and not vice versa.
 *      Otherwise, the box of the floated out label would be larger than the
 *      content itself. This causes e.g. an unwanted spacings between the
 *      content of the label and siblings like info icons.
 * [3]  Since the text is scaled up using transform, reduce the max-width
 *      of the label accordingly. Important for labels that break into multiple
 *      lines.
 * [4]  Calculate horizontal and vertical position of floating labels inside textarea.
 * [5]  Prevent all `pointer-events` in order to be able to select
 *      the underneath input element when clicking on the label.
 * [6]  Allow all `pointer-events` on the label when its parent
 *      `.c-form-field` element is active or has focus.
 * [7]  Apply the styles of the input states to the `.c-form-field` element.
 *      We override the default styles via `!important` to avoid potential conflicts.
 * [8]  If a hint is displayed together with an error message,
 *      subtract the margin bottom from the error message to decrease the distance
 *      between hint and error message.
 * [9]  Limit width to its content.
 *      Useful when a link is used and only the text should be clickable.
 * [10] Set a different spacing for the label, which is needed in some cases,
 *      especially in combination with a textarea.
 * [11] Disable transform settings so that the label has the correct position.
 * [12] Remove spacing for floating labels.
 * [13] Handle spacing via transform.
 * [14] Ensure read-only labels can not be interacted with and not rely on
 *      specificity.
 * [15] Remove unwanted spacing that might be applied by default
 *      depending on used element.
 * [16] Indent Form Field Extra if a CSS custom property is set
 *      (by radio button or checkbox)
*/
.c-form-field {
    display: flex;
    flex-direction: column;

    &.c-form-field--inline {
        /**
         * @TODO:
         * check if vertical is sufficient
         * fallbacks: non-gap
         */
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        row-gap: $form-field-label-spacing-inline-vertical;
        column-gap: $form-field-label-spacing-inline-horizontal;

        .c-form-field__label {
            margin: 0;

            @if ($form-field-label-floating) {
                transform: none; /* [11] */
            }

        }

        .c-form-field__box {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
        }

    }

    &.c-form-field--textarea { /* [10] */

        .c-form-field__label {

            @if not ($form-field-label-floating) {
                margin-bottom: $form-field-label-spacing-textarea;
            }

        }

    }

    &.c-form-field--static {

        .c-form-field__label {
            margin-bottom: $form-field-label-spacing;
        }

    }

    &:focus-within {

        .c-form-field__label {
            color: $form-field-label-color-focus;
        }

    }

    &.is-error {

        .c-form-field__hint {
            margin-top: $form-field-label-spacing-hint-error; /* [8] */
        }

    }

    &.is-readonly {

        .c-form-field__label {
            pointer-events: important(none, true); /* [14] */
        }

    }

}

    .c-form-field__hint {
        @if (type-of($form-field-label-text-size) == "map") {
            @include responsive-font-size(
                $font-size:   $form-field-label-text-size,
                $line-height: $form-field-label-line-height-hint,
            );
        } @else {
            // Use non-responsive font-size if opted-out (by not using a map.
            @include font-size(
                $font-size:   $form-field-label-text-size,
                $line-height: $form-field-label-line-height-hint,
            );
        }
        width: fit-content; /* [9] */ /* stylelint-disable-line scale-unlimited/declaration-strict-value */
        margin-top: $form-field-label-spacing-hint;
        margin-bottom: 0; /* [15] */
        font-weight: $form-field-label-font-weight-hint;
        color: $form-field-label-color-hint;
    }

    a {

        &.c-form-field__hint,
        .c-form-field__hint & {
            color: $form-field-label-color-hint-link;
        }

    }

    .c-form-field__label {
        @if (type-of($form-field-label-text-size) == "map") {
            @include responsive-font-size(
                $font-size:   $form-field-label-text-size,
                $line-height: $form-field-label-line-height,
            );
        } @else {
            // Use non-responsive font-size if opted-out (by not using a map.
            @include font-size(
                $font-size:   $form-field-label-text-size,
                $line-height: $form-field-label-line-height,
            );
        }

        position: relative;
        display: inline-block;
        font-weight: $form-field-label-font-weight;
        text-align: left;
        color: $form-field-label-color;

        @if ($form-field-label-floating) {
            @include transition();
            z-index: 2;
            margin-bottom: 0; /* [13] */
            transform: translate3d($form-field-label-floating-translate-out) scale(1);  /* [2] */

            /* stylelint-disable selector-max-class, selector-max-specificity */
            form.is-js-on .c-form-field:not(.c-form-field--no-floating, .c-form-field--inline) & {
                max-width: div(100%, $form-field-label-floating-scale-in); /* [3] */
                transform: translate3d($form-field-label-floating-translate-in) scale($form-field-label-floating-scale-in); /* [2] */
                transform-origin: bottom left; /* [1] */
                pointer-events: none; /* [5] */
            }

            form.is-js-on .c-form-field--textarea:not(.c-form-field--no-floating, .c-form-field--inline) & { /* [4] */
                transform: translate3d($form-field-label-floating-translate-in-textarea) scale($form-field-label-floating-scale-in); /* [2] */
            }

            // @TODO: refactor to allow different styles (e.g. color) for active and focus
            form.is-js-on .c-form-field:not(.c-form-field--no-floating, .c-form-field--inline):focus-within &,
            form.is-js-on .c-form-field:not(.c-form-field--no-floating, .c-form-field--inline).is-active & {
                max-width: div(100%, $form-field-label-floating-scale-in); /* [3] */
                transform: translate3d($form-field-label-floating-translate-out) scale(1); /* [2] */
                color: $form-field-label-color-active;
                pointer-events: all; /* [6] */
            }

            form.is-js-on .c-form-field.c-form-field--no-floating &,
            form.is-js-on .c-form-field:not(.c-form-field--no-floating, .c-form-field--inline).is-active & {

                @if ($form-field-label-floating-reset-transform-no-floating) {
                    transform: none; /* [11] */
                }

            }

            form.is-js-on .c-form-field.c-form-field--static & {

                @if ($form-field-label-floating) {
                    transform: important(none, true); /* [11] */
                }

            }

            form.is-js-on .c-form-field--textarea:not(.c-form-field--no-floating, .c-form-field--inline):focus-within &,
            form.is-js-on .c-form-field--textarea:not(.c-form-field--no-floating, .c-form-field--inline).is-active & {
                transform: translate3d($form-field-label-floating-translate-out-textarea) scale(1); /* [2] */
            }

            form.is-js-on .c-form-field:hover:not(:focus-within, .is-disabled) & {
                color: important($form-field-label-color-hover, true); /* [7] */
            }

            form.is-js-on .c-form-field:not(:focus-within, :hover).is-success & {
                color: important($form-field-label-color-success, true); /* [7] */
            }

            form.is-js-on .c-form-field:not(:focus-within).is-success:hover & {
                color: important($form-field-label-color-success-hover, true); /* [7] */
            }

            form.is-js-on .c-form-field:not(:focus-within, :hover).is-error & {
                color: important($form-field-label-color-error, true); /* [7] */
            }

            form.is-js-on .c-form-field:not(:focus-within).is-error:hover & {
                color: important($form-field-label-color-error-hover, true); /* [7] */
            }

            form.is-js-on .c-form-field.is-disabled & {
                color: important($form-field-label-color-disabled, true); /* [7] */
            }

            form.is-js-on .c-form-field.is-readonly & {
                color: important($form-field-label-color-readonly, true); /* [7] */
            }
            /* stylelint-enable */

        } @else {
            margin-bottom: $form-field-label-spacing; /* [12] */
        }

    }

    .c-form-field__extra {
        padding-left: var(--form-field-extra-indent, 0); /* [16] */

        *:last-child {
            margin-bottom: 0;
        }

    }
