// /* ------------------------------------*\
//     #FONT-SIZE
// \*------------------------------------ */
@use "sass:math" as *;

/// Create a fully formed type style (sizing and vertical rhythm) by passing in a
/// single value.
///
/// @param {Number | String} $font-size [null] - The font size in `px` or as a unit-less CSS custom property (e.g. `var(--font-size)`).
/// @param {String} $line-height [auto] - The line height.
/// @param {Boolean} $important [false] - Whether the styles should be forced with `!important`.
/// @param {Boolean} $scoped [$GLOBAL-SCOPED] - Whether the `font-size` should be calculated for scoped Bronson styles.
///
/// @example
///
/// scss:
///
///     .foo {
///         @include font-size(12px);
///     }
///
/// This will generate a rem-based font-size, as well as a unit-less line-height
/// which will place the element on your baseline, e.g.:
///
/// css:
///
///     .foo {
///         font-size: 0.75rem;
///         line-height: 2;
///     }
///
/// If you do not want to generate a line-height automatically, you
/// simply pass in your own value as a second parameter:
///
/// scss:
///
///     .foo {
///         @include font-size(
///             $font-size:   12px,
///             $line-height: 1.5,
///         );
///     }
///
/// This will yield:
///
/// css:
///
///     .foo {
///         font-size:   0.75rem;
///         line-height: 1.5;
///     }
///
/// This parameter can be any integer, `inherit`, or `normal`. If you don't want
/// a line-height at all, pass in a second parameter of `false`:
///
/// scss:
///
///     .foo {
///         @include font-size(
///             $font-size:   12px,
///             $line-height: false,
///         );
///     }
///
/// This will yield:
///
/// css:
///
///     .foo {
///         font-size: 0.75rem;
///     }
///
/// Instead of a px value you can also pass a CSS custom property without(!) a unit.
/// E.g. `12` = `12px`
///
/// scss:
///
///     .foo {
///         --font-size: #{strip-unit($GLOBAL-FONT-SIZE)};
///         @include font-size(
///             $font-size: var(--font-size),
///             $line-height: false,
///         );
///     }
///
/// This will calculate a rem-based font-size:
///
/// css:
///
///     .foo {
///         --font-size: 12;
///         font-size: calc(var(--font-size) / 18 * 1rem);
///     }
///
/// For scoped styles (when a Bronson component should be added to a Non-Bronson page),
/// pass in `$scoped: true`. In almost all cases, this should be controlled globally
/// via `$GLOBAL-SCOPED`.
///
/// scss:
///
///     .foo {
///         @include font-size(
///             $font-size: 12px,
///             $scoped:    true,
///         );
///     }
///
/// This will yield:
///
/// css:
///
///     .foo {
///           font-size: calc(0.66667 * (18 / var(--bron-root-font-size, 16)) * 1rem);
///     }
///
@mixin font-size($font-size, $line-height: auto, $important: false, $scoped: $GLOBAL-SCOPED) {
    $fontSizeIsCssCustomProperty: false;

    // The font-size parameter must be a number in `px` units.
    @if (type-of($font-size) == number) {
        @if (unit($font-size) != "px") {
            @error("`#{$font-size}` needs to be a pixel value.");
        }
    } @else if (type-of($font-size) == string and starts-with($font-size, "var(")) {
        // Or a CSS custom property without a unit (which cannot be tested with Sass).
        // For example: `var(--font-size-variable)`
        $fontSizeIsCssCustomProperty: true;
    } @else {
        @error("`#{$font-size}` needs to be a number.");
    }

    // The `$important` parameter must be either `true` or `false`.
    @if ($important == true) {
        $important: !important; /* stylelint-disable-line scss/dollar-variable-colon-space-after */
    } @else if ($important == false) {
        $important: null;
    } @else {
        @error("`#{$important}` needs to be `true` or `false`.");
    }

    // Calculate the font-size in `rem` unit out of the given `px` value.
    @if ($scoped) {
        // Consider the CSS custom property `--bron-root-font-size` when
        // compiling a scoped style
        font-size: calc((#{strip-unit($font-size)} / #{strip-unit($GLOBAL-FONT-SIZE)}) * (#{strip-unit($GLOBAL-FONT-SIZE)} / var(--bron-root-font-size, 16)) * 1rem) $important;
    } @else {
        // Simpler calculation for standard non-scoped styles
        @if ($fontSizeIsCssCustomProperty) {
            // Use CSS `calc` if `$font-size` is a CSS custom property
            font-size: calc(#{strip-unit($font-size)} / #{strip-unit($GLOBAL-FONT-SIZE)} * 1rem) $important; /* stylelint-disable-line order/order */
        } @else {
            font-size: div($font-size, $GLOBAL-FONT-SIZE) * 1rem $important;
        }
    }

    // If no specific line-height value is given via mixin parameter,
    // a unit-less value is being calculated so it automatically fits
    // our baseline grid.
    @if $line-height == auto {
        line-height: ceil(div($font-size, $GLOBAL-LINE-HEIGHT)) * div($GLOBAL-LINE-HEIGHT, $font-size) $important;
    } @else {
        @if ($line-height == null) {
            // No output
        } @else if (type-of($line-height) == number or $line-height == inherit or $line-height == normal) {
            line-height: $line-height $important;
        } @else if ($line-height != none and $line-height != false) {
            @warn("“#{$line-height}” is not a valid value for `line-height`.");
        }
    }

}
