/* ------------------------------------*\
    #PRODUCT-INFO
\*------------------------------------ */

$product-info-breakpoint:               s !default;
$product-info-grid-columns:             1fr 1fr !default;
$product-info-grid-row-gap: (
    null:                     $GLOBAL-SPACING-UNIT,
    $product-info-breakpoint: $GLOBAL-SPACING-UNIT,
) !default;
$product-info-grid-column-gap:          $product-info-grid-row-gap !default;

$product-info-padding:                  null !default;

$product-info-image-spacing-bottom:     $GLOBAL-SPACING-UNIT-SMALL !default;

$product-info-title-font-size:          $FS4 !default;
$product-info-title-text-color:         null !default;
$product-info-subtitle-spacing:         $GLOBAL-SPACING-UNIT-XSMALL !default;
$product-info-subtitle-font-family:     null !default;
$product-info-subtitle-font-size:       $FS3 !default;
$product-info-subtitle-text-color:      $product-info-title-text-color !default;

$product-info-caption-text-color:       $COLOR-BASE-TEXT !default;
$product-info-caption-font-size:        null !default;

// Media container styles
$product-info-media-spacing-vertical:   null !default;
$product-info-media-spacing-horizontal: null !default;

// Aside style
$product-info-aside-background-color:   $COLOR-WHITE !default;
$product-info-aside-spacing-vertical:   null !default;
$product-info-aside-spacing-horizontal: null !default;
$product-info-aside-grid-row-gap:       null !default;

/**
 * [1] Conditionally render the info aside as flex container to
 *     set a gap if it was specified.
 * [2] Span header across columns.
 */

.c-product-info {
    @include responsive-property("row-gap", $product-info-grid-row-gap);
    @include responsive-property("column-gap", $product-info-grid-column-gap);
    @include responsive-property("padding", $product-info-padding);

    display: grid;
    grid-template-columns: $product-info-grid-columns;
}

    .c-product-info__media {
        @include responsive-property(
            ("padding-top", $product-info-media-spacing-vertical),
            ("padding-right", $product-info-media-spacing-horizontal),
            ("padding-bottom", $product-info-media-spacing-vertical),
            ("padding-left", $product-info-media-spacing-horizontal)
        );

        grid-column: 1/2;

        @include media-query("<=#{$product-info-breakpoint}") {
            grid-column: 1 / span 2;
        }

    }

    .c-product-info__aside {
        @include responsive-property(
            ("padding-top", $product-info-aside-spacing-vertical),
            ("padding-right", $product-info-aside-spacing-horizontal),
            ("padding-bottom", $product-info-aside-spacing-vertical),
            ("padding-left", $product-info-aside-spacing-horizontal)
        );

        @if ($product-info-aside-grid-row-gap) { /* [1] */
            @include responsive-property("row-gap", $product-info-aside-grid-row-gap);
            display: flex;
            flex-direction: column;
        }

        grid-column: 2/2;

        background-color: if($product-info-aside-background-color, $product-info-aside-background-color, null);

        @include media-query("<=#{$product-info-breakpoint}") {
            grid-column: 1 / span 2;
        }

    }

    .c-product-info__header {
        grid-column: 1 / -1; /* [2] */
    }

    .c-product-info__title {
        @include responsive-font-size($product-info-title-font-size);
        margin: 0;
        color: $product-info-title-text-color;
    }

    .c-product-info__subtitle {
        @include responsive-font-size($product-info-subtitle-font-size);
        margin-top: $product-info-subtitle-spacing;
        margin-bottom: 0;
        font-family: $product-info-subtitle-font-family;
        color: $product-info-subtitle-text-color;
    }

    .c-product-info__figure {

    }

    .c-product-info__image {
        @include responsive-property("margin-bottom", $product-info-image-spacing-bottom);
        display: block;
        max-width: 100%;
    }

    .c-product-info__caption {
        @include responsive-font-size(
            $font-size:   $product-info-caption-font-size,
            $accept-null: true
        );
        color: $product-info-caption-text-color;
    }
