/* ------------------------------------*\
    #ORDERED-LIST
\*------------------------------------ */

$skoda-ordered-list-item-counter-style:         decimal-leading-zero !default;
$skoda-ordered-list-item-counter-position:      absolute !default;
$skoda-ordered-list-item-counter-spacing-right: $GLOBAL-SPACING-UNIT-XSMALL !default;
$skoda-ordered-list-item-counter-offset:        -100% !default;
$skoda-ordered-list-item-counter-name:          $ordered-list-item-counter-name !default;

/**
 * [1] Add a counter and reset it on each `li` nesting. Set the counter style to put in leading zeros.
 * [2] Position the counter absolute and move it by itself and the `$skoda-ordered-list-item-counter-offset` to the left.
 */
.c-ol {

    li {
        position: relative;

        &::before {
            content: counter(#{$skoda-ordered-list-item-counter-name}, #{$skoda-ordered-list-item-counter-style}); /* [1] */
            position: $skoda-ordered-list-item-counter-position; /* [2] */
            padding-right: $skoda-ordered-list-item-counter-spacing-right; /* [2] */
            transform: translateX($skoda-ordered-list-item-counter-offset); /* [2] */
            counter-increment: $skoda-ordered-list-item-counter-name;
        }

    }

}
