/* ------------------------------------*\
    #PLYR-OVERRIDES
\*------------------------------------ */

/**
 * This partial overrides the default styles of plyr that couldn't be handled
 * with the variables in `06-vendor/plyr/_vendor.plyr.settings.scss`.
 */

$plyr-controls-horizontal-spacing:              $plyr-control-spacing !default;
$plyr-controls-item-spacing:                    ($plyr-control-spacing * 0.5) !default;
$plyr-controls-item-spacing-at-breakpoint:      null !default;
$plyr-controls-background-color:                rgba($COLOR-BLACK, $OPACITY-HIGHEST) !default;
$plyr-controls-transition:                      $GLOBAL-TRANSITION !default;
$plyr-controls-transition-reset:                true !default;
$plyr-controls-play-time-color:                 $COLOR-WHITE !default;

$plyr-controls-button-size:                     auto !default;
$plyr-controls-button-size-at-breakpoint:       $plyr-controls-button-size !default;
$plyr-controls-button-background-color-hover:   null !default;
$plyr-controls-button-color-hover:              $COLOR-WHITE !default;
$plyr-controls-button-border-width:             0 !default;
$plyr-controls-button-border-radius:            50% !default;
$plyr-controls-button-border-color:             transparent !default;
$plyr-controls-button-border:                   $plyr-controls-button-border-width solid $plyr-controls-button-border-color !default;

// Focus styles of all controls
$plyr-controls-focus-outline-color:             $plyr-controls-button-color-hover !default;
$plyr-controls-focus-outline-offset:            4px !default;

// Captions
$plyr-controls-captions-padding:                $GLOBAL-SPACING-UNIT !default;

// Caption (Item)
$plyr-controls-caption-padding:                 null !default;
$plyr-controls-caption-border-radius:           null !default;
$plyr-controls-caption-letter-spacing:          null !default;

$plyr-controls-button-overlay-background-hover: $COLOR-WHITE !default;
$plyr-controls-button-overlay-icon-color-hover: $COLOR-BLACK !default;

$plyr-controls-volume-spacing:                  $plyr-control-spacing !default;
$plyr-controls-volume-padding:                  ($plyr-control-spacing * 0.5) !default;
$plyr-controls-volume-width-min:                100px !default;
$plyr-controls-volume-width-max:                150px !default;
$plyr-controls-volume-vertical:                 false !default;
$plyr-controls-volume-vertical-width:           80px !default;
$plyr-controls-volume-vertical-height:          20px !default;
$plyr-controls-volume-vertical-offset-top:      -($plyr-controls-volume-spacing) !default;
$plyr-controls-volume-vertical-offset-left:     ($plyr-controls-volume-vertical-height * 0.5) !default;

$plyr-controls-overlay-size:                    60px !default;
$plyr-controls-overlay-size-at-breakpoint:      44px !default;
$plyr-controls-overlay-border-width:            2px !default;
$plyr-controls-overlay-border-color:            $plyr-color-main !default;
$plyr-controls-overlay-border:                  $plyr-controls-overlay-border-width solid $plyr-controls-overlay-border-color !default;
$plyr-controls-overlay-background:              $COLOR-WHITE !default;
$plyr-controls-overlay-icon-color:              $COLOR-BLACK !default;
$plyr-controls-overlay-icon-size:               icon-size(small) !default;
$plyr-controls-overlay-shadow:                  none !default;

$plyr-controls-time-spacing:                    ($plyr-controls-horizontal-spacing * 2) !default;

// Controls settings and responsive behaviour
$plyr-breakpoint:                               s !default;
$plyr-controls-responsive:                      false !default;

$plyr-controls-height:                          60px !default;
$plyr-controls-height-at-breakpoint:            ($plyr-controls-height * 0.5) !default;

$plyr-control-icon-size:                        icon-size(small) !default;
$plyr-control-icon-size-at-breakpoint:          ($plyr-control-icon-size * 0.5) !default;
$plyr-control-icon-positioning:                 0 auto !default;

$plyr-range-thumb-scale-active:                 1.3 !default;

$plyr-tooltip-offset:                           ($plyr-controls-height * 0.5) - ($plyr-range-thumb-height * 0.5) !default;
$plyr-tooltip-offset-at-breakpoint:             ($plyr-controls-height-at-breakpoint * 0.5) - ($plyr-range-thumb-height * 0.5) !default;
$plyr-tooltip-line-height:                      null !default;

/**
 * [1]  Remove padding and take care of spacing at another place.
 * [2]  Set the height of the control bar to the correct height.
 * [3]  Initially hide the controls, so you don't see them before
 *      the video started...
 * [4]  ...and show them again when the mouse enters the video container.
 * [5]  The plyr video-player notices, when the mouse hasn't been moved for two
 *      seconds. It then puts the `.plyr--hide-controls` class to the container.
 *      Since we've overridden the basic appearance of the controls with [3]
 *      and [4], we need to override it again here, so that the controls
 *      actually hide after two seconds of no mouse movement.
 * [6]  If you specify voices in the captions, two `<span>`s get generated
 *      inside of each other and both get a half-transparent background and
 *      padding by default which looks kinda odd. This override removes
 *      the background of the inner `<span>`.
 * [7]  Button hover styles for controls.
 * [8]  Play Button hover style.
 * [9]  Unfortunately, we cannot use the `translate` trick to center the icon,
 *      because that doesn't work in older Firefox versions (e.g. v52).
 *      See BRON-828.
 * [10] Position tooltip above control section.
 * [11] Despite set options for volume and mute,
 *      the elements get an `[hidden]` attribute resulting in `display:none`.
 * [12] Change the default control-item order: Play, Volume, ...
 * [13] Target area to bridge gap between volume button and volume slider.
 * [14] Fade-in volume slider.
 * [15] Remove spacing when volume slider is vertical.
 * [16] Override specificity set by plugin styles.
 * [17] Prevent interaction with non-visible poster.
 * [18] Prevent overwrite of width declaration from plyr vendor styles.
 * [19] Remove the transparency of the play button.
 * [20] Use `transition-duration: 0s` instead of `transition: none`.
 *      By default the browser set the `transition-property` to `all`
 *      for each element. Setting the `transition-duration` to the default `0s`
 *      renders the `transition-property` irrelevant.
 * [21] Replace the dotted outline with a visual border as a keyboard-only
 *      focus style. The visual border is accomplished with `box-shadow`.
 * [22] Focus styles of all controls.
 * [23] Handles the track color of the `input[type="range"]`.
 *      Already defined in `plyr/src/sass/components/sliders.scss`.
 *      Since we have disabled CSS custom properties within the plyr styles,
 *      we need to reset the track color here. CSS custom properties are
 *      necessary at this point.
 * [24] Specify `transition-property` to avoid performance issues.
 * [25] Increase the `outline-offset` to prevent the focus ring to clash with controls.
 * [26] Stack captions above controls.
 */

.plyr--video {

    .plyr__controls {
        z-index: 20;
        height: $plyr-controls-height; /* [2] */
        padding: $plyr-control-spacing; /* [1] */
        background: $plyr-controls-background-color;
        opacity: 0; /* [3] */

        @if ($plyr-controls-responsive) {

            @include media-query("<=#{$plyr-breakpoint}") {
                height: $plyr-controls-height-at-breakpoint;
                padding-right: ($plyr-controls-horizontal-spacing * 2);
                padding-left: ($plyr-controls-horizontal-spacing * 2);
            }

            svg {
                margin: $plyr-control-icon-positioning;

                @include media-query("<=#{$plyr-breakpoint}") {
                    width: $plyr-control-icon-size-at-breakpoint;
                    height: $plyr-control-icon-size-at-breakpoint;
                    min-width: $plyr-control-icon-size-at-breakpoint;
                }

            }

        }

        .plyr__control {

            &:hover {
                background-color: $plyr-controls-button-background-color-hover; /* [7] */
                color: $plyr-controls-button-color-hover; /* [7] */
            }

        }

    }

    .plyr__control {

        // Focus styles
        &:focus-visible,
        &.plyr__tab-focus {
            @include focus-styles(
                $outline-color:  $plyr-controls-focus-outline-color, /* [22] */
                $outline-offset: $plyr-controls-focus-outline-offset, /* [22] */
            );
            transition-duration: if($plyr-controls-transition-reset, 0s, null); /* [20] */
        }

    }

}

.plyr__captions {
    z-index: 10; /* [26] */
    padding: $plyr-controls-captions-padding;
    pointer-events: none; /* [x] */

    * {
        pointer-events: auto;
    }

    span span {
        background: transparent; /* [6] */
    }

}

.plyr__caption {
    padding: $plyr-controls-caption-padding;
    border-radius: $plyr-controls-caption-border-radius;
    letter-spacing: $plyr-controls-caption-letter-spacing;
}

.plyr__progress__buffer {

    .plyr--video & {
        box-shadow: none;
    }

}

.plyr__controls {

    @include media-query("<=#{$plyr-breakpoint}") {
        height: $plyr-controls-height-at-breakpoint;
    }

    .plyr:hover &,
    .plyr:focus-within & {
        opacity: 1; /* [4] */
    }

    .plyr.plyr--hide-controls:hover &,
    .plyr.plyr--hide-controls:focus-within &, {
        opacity: 0; /* [5] */
    }

    .c-hero-teaser--video & {
        display: none;
    }

    .plyr__controls__item {
        margin-left: $plyr-controls-item-spacing;

        @include media-query("<=#{$plyr-breakpoint}") {
            margin-left: $plyr-controls-item-spacing-at-breakpoint;
        }

        &:first-child {
            margin-right: auto;
            margin-left: 0;
        }

    }

    .plyr__time {
        margin-left: $plyr-controls-time-spacing;

        @include media-query("<=xs") {
            margin-left: $plyr-controls-horizontal-spacing;
        }

    }

}

.plyr__time--current {
    color: $plyr-controls-play-time-color;
}

.plyr__tooltip {
    margin-bottom: $plyr-tooltip-offset; /* [10] */
    padding: $plyr-tooltip-padding;
    line-height: $plyr-tooltip-line-height;

    @if ($plyr-controls-responsive) {

        @include media-query("<=#{$plyr-breakpoint}") {
            margin-bottom: $plyr-tooltip-offset-at-breakpoint;
        }

    }

}

.plyr__control.plyr__control {
    @include disable-touch-highlight();
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: $plyr-controls-button-size;
    height: $plyr-controls-button-size;
    min-width: auto; /* [18] */
    padding: 0;
    border: $plyr-controls-button-border;
    border-radius: $plyr-controls-button-border-radius;

    @if ($plyr-controls-responsive) {

        @include media-query("<=#{$plyr-breakpoint}") {
            width: $plyr-controls-button-size-at-breakpoint;
            height: $plyr-controls-button-size-at-breakpoint;
        }

    }

    .plyr__menu__container & {
        border: 0;
        border-radius: initial;
    }

}

.plyr .plyr__control--overlaid {
    width: $plyr-controls-overlay-size;
    height: $plyr-controls-overlay-size;
    padding: 0;
    border: if($plyr-controls-overlay-border, $plyr-controls-overlay-border, null);
    background: $plyr-controls-overlay-background;
    color: $plyr-controls-overlay-icon-color;
    box-shadow: $plyr-controls-overlay-shadow;
    opacity: 1; /* [19] */

    @if ($plyr-controls-responsive) {

        @include media-query("<=#{$plyr-breakpoint}") {
            width: $plyr-controls-overlay-size-at-breakpoint;
            height: $plyr-controls-overlay-size-at-breakpoint;
        }

    }

    &:hover,
    &:focus-visible,
    &.plyr__tab-focus {
        background: $plyr-controls-button-overlay-background-hover; /* [8] */
        color: $plyr-controls-button-overlay-icon-color-hover; /* [8] */
    }

    .c-hero-teaser--video & {
        display: none;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: $plyr-controls-overlay-icon-size;
        height: $plyr-controls-overlay-icon-size;
        margin-top: -($plyr-controls-overlay-icon-size * 0.5); /* [9] */
        margin-left: -($plyr-controls-overlay-icon-size * 0.5); /* [9] */
    }

}

.plyr__poster {
    visibility: hidden; /* [17] */

    .plyr--stopped.plyr__poster-enabled & {
        visibility: visible;
    }

}

/* stylelint-disable selector-max-specificity */
.plyr--full-ui.plyr--video input[type="range"] {
    cursor: pointer;

    // Pressed styles
    &:active,
    &:hover {

        &::-webkit-slider-thumb {
            transform: scale(#{$plyr-range-thumb-scale-active});
        }

        &::-moz-range-thumb {
            transform: scale(#{$plyr-range-thumb-scale-active});
        }

    }

    // Focus styles
    // Plyr version of `:focus-visible`
    &:focus-visible,
    &.plyr__tab-focus {
        @include focus-styles(
            $outline-color:  $plyr-controls-focus-outline-color, /* [22] */
            $outline-offset: $plyr-controls-focus-outline-offset, /* [22] */
        );


        &::-webkit-slider-runnable-track {
            outline: 0; /* [21] */
        }

        &::-moz-range-track {
            outline: 0; /* [21] */
        }

    }

    // Range track styles
    &::-webkit-slider-runnable-track {
        background-image: linear-gradient(to right, currentColor var(--value, 0%), transparent var(--value, 0%)); /* [23] */
    }

}

.plyr .plyr__volume { /* [11] */
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    min-width: $plyr-controls-volume-width-min;
    max-width: $plyr-controls-volume-width-max;

    @include media-query("<=#{$plyr-breakpoint}") {
        display: none;
    }

    &.plyr__controls__item { /* [16] */
        padding-right: 0; /* [15] */
    }

    &:hover,
    &:focus-within {

        input[type="range"] {
            opacity: 1; /* [14] */
            visibility: visible; /* [14] */
        }

    }

    @if ($plyr-controls-volume-vertical) {
        width: auto; /* [15] */
        min-width: 0; /* [15] */
        max-width: none;

        // Target area
        &::after { /* [13] */
            content: "";
            position: absolute;
            bottom: 100%;
            left: 0;
            display: block;
            width: $plyr-control-icon-size;
            height: $plyr-controls-volume-vertical-width;
        }

        [data-plyr="mute"] {
            width: $plyr-control-icon-size;
        }

        input[type="range"] {
            position: absolute;
            top: $plyr-controls-volume-vertical-offset-top;
            left: $plyr-controls-volume-vertical-offset-left;
            width: $plyr-controls-volume-vertical-width;
            height: $plyr-controls-volume-vertical-height;
            margin: 0;
            padding: $plyr-controls-volume-padding;
            transform: rotate(-90deg);
            transform-origin: 0 0;
            background-color: $plyr-controls-background-color;
            transition: $plyr-controls-transition;
            transition-property: opacity, visibility; /* [24] */
            opacity: 0; /* [14] */
            visibility: hidden; /* [14] */

            @include media-query("<=#{$plyr-breakpoint}") {
                top: -($plyr-controls-height-at-breakpoint * 0.5);
            }

        }

    }

}
/* stylelint-enable selector-max-specificity */

.plyr [data-plyr="mute"] { /* [11] */
    display: inline-flex;
}

.plyr:not(.plyr--captions-enabled) [data-plyr="captions"] {
    display: none; /* [11] */
}


.plyr--captions-enabled [data-plyr="captions"],
.plyr--pip-supported [data-plyr="pip"],
.plyr--airplay-supported [data-plyr="airplay"],
.plyr--fullscreen-enabled [data-plyr="fullscreen"] {
    display: inline-flex;
}
