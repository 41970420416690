// /* ------------------------------------*\
//     #ACCORDION (Settings)
// \*------------------------------------ */

$accordion-bg-color:                           $COLOR-WHITE !default;
$accordion-panel-bg-color:                     $COLOR-SURFACE-PRIMARY !default;

$accordion-enable-cssonly-icon:                false !default;
$accordion-icon-size:                          icon-size(xsmall) !default;
$accordion-icon-spacing-top:                   $GLOBAL-SPACING-UNIT !default;
$accordion-icon-spacing-right:                 $GLOBAL-SPACING-UNIT-XSMALL !default;
$accordion-icon-collapsed:                     get-icon(semantic-plus) !default;
$accordion-icon-expanded:                      get-icon(semantic-minus) !default;
$accordion-icon-transform-collapsed:           none !default;
$accordion-icon-transform-expanded:            none !default;

$accordion-border-color:                       $COLOR-BORDER !default;
$accordion-border-top:                         1px solid $accordion-border-color !default;
$accordion-border-bottom:                      $accordion-border-top !default;

$accordion-header-spacing-top:                 $GLOBAL-SPACING-UNIT !default;

$accordion-header-split-label-spacing-between: $GLOBAL-SPACING-UNIT !default;
$accordion-header-split-spacing-right:         $GLOBAL-SPACING-UNIT + $accordion-icon-size !default;

$accordion-subtitle-spacing:                   $GLOBAL-SPACING-UNIT-XSMALL !default;

// Responsive font size opt-out.
$accordion-title-font-size: (
    null: map-get($FS0, null),
) !default;

// $accordion-overflow-offset: 0 !default;
