/* ------------------------------------*\
    #TOGGLE
\*------------------------------------ */
@use "sass:math" as *;

/* stylelint-disable selector-max-specificity */
// @TODO: consider refactoring with deep nesting, states etc.
// @TODO: make border etc. conditional declarations
// @TODO: disabled state (BRON-3817)

// Toggle
$toggle-transition-time:             $GLOBAL-TRANSITION-DURATION !default;
$toggle-bar-transition-time:         div($toggle-transition-time, 3) !default;

// Toggle Bar
$toggle-bar-width:                   $GLOBAL-SPACING-UNIT-LARGE !default;
$toggle-bar-height:                  $GLOBAL-LINE-HEIGHT !default;
$toggle-bar-spacing:                 $GLOBAL-SPACING-UNIT-SMALL !default;
$toggle-bar-background:              transparent !default;
$toggle-bar-background-active:       $toggle-bar-background !default;
$toggle-bar-border-width:            $GLOBAL-INPUT-BORDER-WIDTH !default;
$toggle-bar-border-color:            $COLOR-INACTIVE !default;
$toggle-bar-border-color-hover:      false !default;
$toggle-bar-border:                  $toggle-bar-border-width solid $toggle-bar-border-color !default;
$toggle-bar-border-radius:           4px !default;

// Toggle Handle
$toggle-handle-width:                6px !default;
$toggle-handle-height:               16px !default;
$toggle-handle-border-width:         2px !default;
$toggle-handle-border-style:         dotted !default;
$toggle-handle-border-color:         $COLOR-SUBTLE !default;
$toggle-handle-border:               $toggle-handle-border-width $toggle-handle-border-style $toggle-handle-border-color !default;
$toggle-handle-border-radius:        4px !default;
$toggle-handle-box-shadow:           0 2px 4px 0 rgba(0, 0, 0, 0.85) !default;
$toggle-handle-color-inactive:       $COLOR-INACTIVE !default;
$toggle-handle-color-active:         $COLOR-BRAND !default;
$toggle-on-off-handle-color-active:  $COLOR-HIGHLIGHT !default;
$toggle-on-off-text-color-inactive:  null !default;

// Toggle Text
$toggle-text-font-size:              null !default;
$toggle-text-font-weight:            null !default;
$toggle-text-color:                  null !default;

/**
 * [1] Hide native checkbox.
 * [2] Give space for the (absolute positioned) toggle.
 * [3] Vertical alignment for toggle and text.
 * [4] Correct alignment.
 * [5] Relative labels to help position the pseudo elements.
 *	   The z-index will be handy later, when the labels that overlap the visual
 *     toggle UI need to be adjusted
 *	   to allow for a user to toggle the toggle without having to move their
 *     mouse/finger to the different sides of the UI.
 * [6] The large padding is used to position the labels on top of the visual UI,
 *     so the toggle UI itself can be mouse clicked or finger tapped to toggle
 *     the current option.
 * [7] Move the 2nd label to have a lower z-index, so when that option is
 *     toggled, the first label will overlay on top of the toggle ui, and the
 *     toggle can be pressed again to toggle back to the previous state.
 * [8] Prevent text selection on fast double clicks.
 */
.c-toggle {
    @include disable-touch-highlight();
}

    .c-toggle__input {
        @include a11y-hidden(); /* [1] */

        &:checked {

            + .c-toggle__text:last-child {

                /**
                 * :checked state.
                 */
                @if ($toggle-bar-background-active != transparent) {
                    &::before {
                        background-color: $toggle-bar-background-active;
                    }
                }

                &::after {
                    transform: translateX($toggle-bar-width - ($toggle-handle-width + ((($toggle-bar-height * 0.5) - ($toggle-handle-width * 0.5)) * 2)));
                    background-color: $toggle-handle-color-active;
                }

            }

        }

        .c-toggle--on-off &:checked {

            + .c-toggle__text:last-child {

                /**
                 * :checked state.
                 */
                &::after {
                    background-color: $toggle-on-off-handle-color-active;
                }

            }

        }

    }

    .c-toggle__text {
        @include responsive-font-size(
            $font-size:   $toggle-text-font-size,
            $line-height: false,
            $accept-null: true,
        );
        position: relative;
        display: inline-block; /* [2] */
        font-weight: $toggle-text-font-weight;
        line-height: $toggle-bar-height; /* [3] */
        color: $toggle-text-color;
        cursor: pointer;
        user-select: none; /* [8] */

        // left label
        &:first-child {
            margin-right: $toggle-bar-spacing;

            .c-toggle--on-off & {
                color: $toggle-on-off-text-color-inactive;
            }
        }

        // right label
        &:last-child {
            padding-left: ($toggle-bar-width + $toggle-bar-spacing); /* [2] */

            &::before,
            &::after {
                content: "";
                position: absolute;
                display: block;
            }

            /**
             * The toggle bar.
             */
            &::before {
                @include transition($transition-duration: $toggle-bar-transition-time);
                top: 0;
                left: 0;
                width: $toggle-bar-width;
                height: $toggle-bar-height;
                border: if($toggle-bar-border, $toggle-bar-border, null);
                border-radius: $toggle-bar-border-radius;
                background-color: $toggle-bar-background;
                transition: $toggle-bar-transition-time;
            }

            @if ($toggle-bar-border-color-hover) {
                &:hover::before {
                    border-color: $toggle-bar-border-color-hover;
                }
            }

            /**
             * The toggle handle.
             */
            &::after {
                @include transition($transition-duration: $toggle-transition-time);
                top: (($toggle-bar-height * 0.5) - ($toggle-handle-height * 0.5)); /* [4] */
                left: (($toggle-bar-height * 0.5) - ($toggle-handle-width * 0.5)); /* [4] */
                width: $toggle-handle-width;
                height: $toggle-handle-height;
                border: if($toggle-handle-border, $toggle-handle-border, null);
                border-radius: $toggle-handle-border-radius;
                background-color: $toggle-handle-color-inactive;
                box-shadow: if($toggle-handle-box-shadow, $toggle-handle-box-shadow, null);
            }

        }

    }

/* stylelint-enable */
