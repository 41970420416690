/* ------------------------------------*\
    #LIST-NESTED-COUNTER
\*------------------------------------ */

$skoda-ordered-list-item-counter-position:      absolute !default;
$skoda-ordered-list-item-counter-spacing-right: $GLOBAL-SPACING-UNIT-XSMALL !default;
$vw6-list-nested-list-item-spacing-vertical:   $GLOBAL-SPACING-UNIT-XSMALL !default;
$skoda-ordered-list-item-counter-offset:        -100% !default;

/**
 * [1] Add spacing between counter and items.
 */
ol.c-list-nested-counter,
.c-list-nested-counter ol {

    li {
        margin-bottom: $vw6-list-nested-list-item-spacing-vertical;

        &::before {
            position: $skoda-ordered-list-item-counter-position; /* [1] */
            padding-right: $skoda-ordered-list-item-counter-spacing-right; /* [1] */
            transform: translateX($skoda-ordered-list-item-counter-offset); /* [1] */
        }

    }

}

