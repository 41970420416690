/* ------------------------------------*\
    #NO-CARPET
\*------------------------------------ */
// @DEPRECATED: remove in v15

/**
 * A utility class to eliminate the `text-decoration: underline`, set by the
 * `<a>` element.
 */

.u-no-carpet {
    text-decoration: none !important;
}
