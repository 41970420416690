// /* ------------------------------------*\
//     #PAGINATION (Settings)
// \*------------------------------------ */

$pagination-is-centered:              true !default;

$pagination-link-spacing-horizontal:  $GLOBAL-SPACING-UNIT-XXSMALL !default;
$pagination-link-border-radius:       $GLOBAL-BORDER-RADIUS-SMALL !default;

$pagination-link-padding-horizontal:  0 !default; // Handled via fixed sizes.
$pagination-link-padding-vertical:    0 !default; // Handled via fixed sizes.

$pagination-link-color:               $COLOR-BASE-TEXT !default;
$pagination-link-color-disabled:      $COLOR-INACTIVE !default;
$pagination-link-bg:                  transparent !default;


$pagination-link-color-active:        $pagination-link-color !default;
$pagination-link-bg-active:           $BUTTON-ICON-INTERACTION-BACKGROUND-COLOR-HOVER-ON-LIGHT !default;

$pagination-link-color-hover:         $pagination-link-color-active !default;
$pagination-link-bg-hover:            $pagination-link-bg-active !default;

$pagination-link-font-weight:         $GLOBAL-FONT-WEIGHT-REGULAR !default;

$pagination-stepper-icon-size:        icon-size(xsmall) !default;
$pagination-stepper-disabled-color:   $pagination-link-color-disabled !default;
$pagination-stepper-disabled-opacity: false !default;
$pagination-stepper-spacing:          $GLOBAL-SPACING-UNIT-LARGE !default;
