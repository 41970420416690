// /* ------------------------------------*\
//     #UNORDERED-LIST (Settings)
// \*------------------------------------ */

$unordered-list-item-spacing-vertical: $GLOBAL-SPACING-UNIT-XSMALL !default;
$unordered-list-indentation:           0 !default;
$unordered-list-use-icon-font:         true !default;
$unordered-list-icon:                  "•" !default;
$unordered-list-icon-size:             $GLOBAL-SPACING-UNIT-SMALL !default;
$unordered-list-icon-line-height:      1.2 !default; // Magic number to vertical align the bullet icon
$unordered-list-item-indentation:      $GLOBAL-SPACING-UNIT-SMALL !default;
