// /* ------------------------------------*\
//     #DISABLE TOUCH HIGHLIGHT
// \*------------------------------------ */

// Remove highlighting the touching area.
// Useful when you want to disable distracting highlights on hover-effects, etc.

/**
 * [1] `transparent` does not work in iOS.
 */
@mixin disable-touch-highlight() {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // [1]
}
