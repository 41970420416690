/* ------------------------------------*\
    #Header
\*------------------------------------ */

$skoda-scope-header-interactive-icon-size: $BUTTON-ICON-INTERACTION-SIZE !default;

/**
 * [1] Override default that has no dedicated setting.
 */
.c-header {

    .c-header__nav {
        margin: 0; /* [1] */
    }

}

.c-context-bar__link,
.c-notification-center__toggle,
.c-site-nav__toggle {
    width: $skoda-scope-header-interactive-icon-size;
    height: $skoda-scope-header-interactive-icon-size;
}

.c-context-bar__link {
    @include button-icon-states();

    &:focus-visible {
        outline: 0;
    }

}

.c-notification-center__toggle {
    @include button-icon-states(
        $selector: "::before",
    );

    &:focus-visible {
        outline: 0;
    }

}

.c-site-nav__toggle {
    @include button-icon-states(
        $handle-position: false,
    );
}
