/* ------------------------------------*\
    #FIELDSET
\*------------------------------------ */

/**
 * Handle spacings between form elements in form sets.
 */

$fieldset-item-spacing: $GLOBAL-FIELDSET-ITEM-SPACING !default;

.o-fieldset {

    &:not(:last-child) {
        margin-bottom: $fieldset-item-spacing;
    }

}

    .o-fieldset__row {

        &:not(:last-child) {
            margin-bottom: $fieldset-item-spacing;
        }

    }
