// /*------------------------------------*\
//     #LEGACY-BROWSER-NOTIFICATION
// \*------------------------------------*/

$legacy-browser-notification-background:    $COLOR-WARNING !default;
$legacy-browser-notification-padding:       $GLOBAL-SPACING-UNIT !default;
$legacy-browser-notification-font-weight:   null !default;
$legacy-browser-notification-text-color:    null !default;
$legacy-browser-notification-texts:         $LEGACY-BROWSER-NOTIFICATION-TEXTS !default;

/**
 * [1] Show English message as default for languages that do not have a dedicated translation.
 */

/// Render notification with pseudo-element with default and multilanguage text.
///
/// @param {String} $selector ["body"] Selector to apply pseudo-element to
/// @param {String} $selector-combinator [null] Optional combinator.
/// Helps making the selector more specific when required (e.g. for demo purposes).
@mixin legacy-browser-notification($selector: "body", $selector-combinator: null) {
    #{$selector}:not([data-legacy-browser-notification-off]) {

        // Text content
        @each $code, $text in $legacy-browser-notification-texts {
            // direct match
            [lang="#{$code}"] #{$selector-combinator} &::before,
            // match with "dialect", e.g. `pt-br`
            [lang*="#{$code}-"] #{$selector-combinator} &::before {
                content: quote($text);
            }
        }

        // Overwrite with custom message when attribute is present
        &[data-legacy-browser-notification]::before {
            content: attr(data-legacy-browser-notification);
        }

        // Style
        &::before {
            $_default-text: map-get($legacy-browser-notification-texts, "en"); /* [1] */
            content: $_default-text; /* [1] */
            display: block;
            padding: $legacy-browser-notification-padding;
            font-weight: $legacy-browser-notification-font-weight;
            background-color: $legacy-browser-notification-background;
            color: $legacy-browser-notification-text-color;
        }

    }

}
