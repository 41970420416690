/* ------------------------------------*\
    #HERO-TEASER (Scopes)
\*------------------------------------ */

/**
 * [1] Invert hint styles on small screens.
 * [2] Disable gradient for controls.
 */
@include media-query("<=#{$hero-teaser-content-break}") {

    .c-hero-teaser {
        @include hint-styles(
            $selectors: $scope-hero-teaser-hint-selectors,
            $inverted:  true, /* [1] */
        );
    }
}

.c-hero-teaser__background-video {

    .plyr--video::after {
        content: unset; /* [2] */
    }

}
