/* ------------------------------------*\
    #FORM-SECTION
\*------------------------------------ */

@use "sass:math" as *;

$form-section-bg-color:                            $COLOR-PALE !default;
$form-section-bg-color-disabled:                   rgba($COLOR-WHITE, 0.75) !default;
$form-section-spacing:                             $GLOBAL-SPACING-UNIT !default;
$form-section-shadow:                              none !default;
$form-section-border-width:                        null !default;
$form-section-border-style:                        null !default;
$form-section-border-color:                        null !default;
$form-section-border-color-hover:                  null !default;
$form-section-border:                              $form-section-border-width $form-section-border-style $form-section-border-color !default;
$form-section-border-radius:                       null !default;

// Can be disabled, e.g. if a border is used instead for states.
$form-section-outline-enable:                      true !default;
$form-section-outline-width-focus:                 $GLOBAL-INPUT-BORDER-WIDTH !default;
$form-section-outline-color-focus:                 $COLOR-HIGHLIGHT !default;
$form-section-outline-width-hover:                 $form-section-outline-width-focus !default;
$form-section-outline-color-hover:                 $form-section-outline-color-focus !default;

$form-section-disabled-grayscale:                  1 !default;
$form-section-disabled-selector:                   '[aria-disabled="true"]' !default;

$form-section-icon-size:                           icon-size(small) !default;
$form-section-icon-size-breakpoint:                xs !default;
$form-section-icon-size-on-breakpoint:             $form-section-icon-size !default;
$form-section-icon-color:                          $COLOR-BRAND !default;

// Use separate icons for expanding/collapsing when custom-icon is set to `false`.
$form-section-icon-collapsed:                      get-icon(semantic-expand) !default;
$form-section-icon-expanded:                       $form-section-icon-collapsed !default;
$form-section-icon-transform-expanded:             rotateX(-180deg) !default;
$form-section-icon-transform-collapsed:            rotateX(0deg) !default;

$form-section-header-spacing-top:                  $GLOBAL-SPACING-UNIT !default;
$form-section-header-spacing-bottom:               $form-section-header-spacing-top !default;
$form-section-header-spacing-left:                 $GLOBAL-SPACING-UNIT !default;
$form-section-header-spacing-right:                $form-section-header-spacing-left !default;
$form-section-header-alignment:                    center !default;
$form-section-header-content-spacing-right:        $GLOBAL-SPACING-UNIT-SMALL !default;

$form-section-title-margin-bottom:                 0 !default;
$form-section-title-font-size:                     $FS1 !default;
$form-section-title-font-family:                   inherit !default;
$form-section-title-font-weight:                   $GLOBAL-FONT-WEIGHT-BOLD !default;
$form-section-title-font-letter-spacing:           inherit !default;
$form-section-title-line-height:                   $GLOBAL-LINE-HEIGHT !default;
$form-section-title-text-color:                    $COLOR-BASE-TEXT !default;
$form-section-title-length:                        30em !default;
$form-section-title-breakpoint:                    s !default;
$form-section-title-link-spacing-left:             $GLOBAL-SPACING-UNIT-SMALL !default;
$form-section-title-link-min-width:                null !default;
$form-section-title-link-white-space:              null !default;


$form-section-subtitle-color:                      $form-section-title-text-color !default;
$form-section-subtitle-font-size:                  $FS0 !default;
$form-section-subtitle-font-weight:                $GLOBAL-FONT-WEIGHT-REGULAR !default;
$form-section-subtitle-line-height:                $GLOBAL-LINE-HEIGHT !default;

$form-section-panel-spacing-top:                   $GLOBAL-SPACING-UNIT !default;
$form-section-panel-spacing-bottom:                $GLOBAL-SPACING-UNIT !default;
$form-section-panel-spacing-left:                  $GLOBAL-SPACING-UNIT !default;
$form-section-panel-spacing-right:                 $GLOBAL-SPACING-UNIT !default;

$form-section-animation-duration:                  1s !default;
$form-section-animation-timing-function:           ease !default;

$form-section-title-state-expanded:                "[aria-expanded='true']" !default;
$form-section-title-state-collapsed:               "[aria-expanded='false']" !default;
$form-section-panel-state-expanded:                "[aria-hidden='false']" !default;

// Validation states
$form-section-validation-icon-size:                $form-section-icon-size !default;
$form-section-validation-icon-size-on-breakpoint:  $form-section-icon-size-on-breakpoint !default;
$form-section-validation-icon-transform-expanded:  none !default;
$form-section-validation-icon-transform-collapsed: none !default;

// Validation state: success
$form-section-validation-border-success:           null !default;
$form-section-validation-icon-color-success:       $COLOR-SUCCESS !default;
$form-section-validation-icon-expanded-success:    get-icon(semantic-checkmark) !default;
$form-section-validation-icon-collapsed-success:   get-icon(semantic-checkmark) !default;

// Validation state: error
$form-section-validation-border-error:             1px solid $COLOR-ALERT !default;
$form-section-validation-icon-color-error:         $COLOR-ALERT !default;
$form-section-validation-icon-expanded-error:      get-icon(semantic-error) !default;
$form-section-validation-icon-collapsed-error:     get-icon(semantic-error) !default;

// Theming
$form-section-themes: (
    "alternative": (
        "background-color": $COLOR-WHITE,
    )
) !default;

/**
 * [1] Border below the last accordion item.
 * [2] Reset various browser and general button styles that would interfere
 *     with form section header or title styles.
 * [3] Max line-length of title.
 * [4] Remove bottom margin of either title, subtitle or last content element.
 * [5] Add spacing between sections, except if it is the only/last item
 *     within its wrapper.
 * [6] Hide button link icon if brand needs it based on variable setting.
 * [7] Reset button link margin if no icon is shown. High specificity needed
 *     in order to overwrite default button styles.
 * [8] Properly align link button vertically with title.
 * [9] Span title wrapper to full width to be able to place the link element
 *      more flexible (e.g. to the right).
 * [10] Cut off title and subtitle only when section is collapsed.
 * [11] Align item to top on small screens to prevent vertical movement upon
 *      opening/closing the section with truncated title and subtitle.
 * [12] Prevent unwanted vertical movement when truncating is triggered upon
 *      opening/closing the section.
 * [13] Align link button vertically with title baseline as the default setting
 *      varies from browser to browser (e.g. Google Chrome).
 * [14] Collapse/expand panel with CSS only by utilizing the `animate-height`
 *      mixin.
 * [15] Covering overlay in disabled state. To also disable all form fields on a
 *      markup level, add the `disabled` attribute to the `fieldset` element.
 *      See https://codepen.io/stevef/post/short-note-on-the-disabled-attribute
 * [16] Stack overlay above stacked elements inside form-section.
 *      Use arbitrary/scoped value as it's not relevant in global stacking
 *      context and therefore not in `$Z-LAYERS`.
 * [17] Grayscale / desaturate via overlay to emphasize the disabled state.
 *      Works only in browsers supporting CSS filter.
 * [18] Disable visual focus indicator in disabled state.
 * [19] "Stack" header (triggers toggle) above disabled section in order to
 *      allow expanding/collapsing form section.
 *      The value is a magic number and only works > 10.
 *      Not in global stacking context and therefore not in `$Z-LAYERS`.
 * [20] Reset heading styles.
 * [21] Set opacity of form labels to `0` to let floating form labels blend in.
 *      Otherwise they would be visible during the form section transition
 *      because of their absolute positioning inside a grid item [23].
 * [22] Increase specificity, so that the styles don't get overwritten by the
 *      icon component later on.
 * [23] Use `grid-template-rows` to transition the height.
 *      No more choppy `max-height` transition. (see https://www.stefanjudis.com/snippets/how-to-animate-height-with-css-grid/)
 * [24] We have to set the vertical paddings for child element instead of the
 *      form section content element. Otherwise the content would not completely
 *      be hidden in collapsed state because it’s a grid item [23].
 * [25] Inherit radius from section to prevent overlay cutting of border.
 */

.c-form-section#{$form-section-disabled-selector} {

    &,
    .c-form-section__header {
        position: relative;
        outline: 0; /* [18] */
        filter: grayscale($form-section-disabled-grayscale); /* [17] */

        &::after { /* [15] */
            content: "";
            position: absolute;
            z-index: 10; /* [16] */
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: inherit; /* [25] */
            background-color: $form-section-bg-color-disabled;
        }

    }

    .c-form-section__header {
        z-index: 20; /* [19] */
        box-shadow: none; /* [18] */
    }

}

.c-form-section {
    position: relative;
    border: $form-section-border;
    border-radius: $form-section-border-radius;
    background-color: $form-section-bg-color;

    @if ($form-section-shadow != none) {
        box-shadow: $form-section-shadow;
    }

    @include vary($loop: $form-section-themes) {
        background-color: vary-get("background-color");
    }

    &:not(:last-child) {
        margin-bottom: $form-section-spacing; /* [5] */
    }

    &:hover {
        border-color: $form-section-border-color-hover;
    }

    &.c-form-section--success {
        border: $form-section-validation-border-success;
    }

    &.c-form-section--error {
        border: $form-section-validation-border-error;
    }

}

    .c-form-section__header {
        @include reset-button(); /* [2] */
        @include responsive-property(
            ("padding-top",    $form-section-header-spacing-top),
            ("padding-right",  $form-section-header-spacing-right),
            ("padding-bottom", $form-section-header-spacing-bottom),
            ("padding-left",   $form-section-header-spacing-left)
        );
        display: flex;
        align-items: $form-section-header-alignment;
        justify-content: space-between;
        width: 100%;
        margin: 0;
        font-family: inherit; /* [2] */
        line-height: inherit; /* [2] */
        text-align: left; /* [2] */
        cursor: pointer;

        @if ($form-section-outline-enable) {
            .c-form-section:not(.is-open) &:focus-within {
                outline: $form-section-outline-width-focus solid $form-section-outline-color-focus;
            }

            .c-form-section:not(.is-open) &:hover {
                outline: $form-section-outline-width-hover solid $form-section-outline-color-hover;
            }
        }

    }

    .c-form-section__header-content {
        flex-grow: 1;
        overflow: hidden;
        padding-right: $form-section-header-content-spacing-right;

        > *:last-child {
            margin-bottom: 0; /* [4] */
        }

    }

    .c-form-section__title {
        @include reset-button(); /* [2] */
        @include responsive-font-size(
            $font-size:   $form-section-title-font-size,
            $line-height: $form-section-title-line-height
        );
        display: flex;
        align-items: baseline; /* [13] */
        width: 100%; /* [9] */
        margin-bottom: $form-section-title-margin-bottom;
        font-family: $form-section-title-font-family; /* [20] */
        font-weight: $form-section-title-font-weight;
        letter-spacing: $form-section-title-font-letter-spacing; /* [20] */
        text-align: left; /* [2] */
        color: $form-section-title-text-color;
        outline: none; /* [2] */
        cursor: pointer;
    }

    .c-form-section__title-text {
        max-width: $form-section-title-length; /* [3] */

        .c-form-section__header#{$form-section-title-state-collapsed}:not(.c-form-section__header--no-truncate) & {
            @include truncate(); /* [10] */
        }

    }

    .c-form-section__title-link.c-form-section__title-link { /* [22] */
        min-width: $form-section-title-link-min-width;
        margin-left: $form-section-title-link-spacing-left;
        white-space: $form-section-title-link-white-space;

        @include media-query("<=#{$form-section-title-breakpoint}") {
            display: none;
        }

    }

    .c-form-section__subtitle {
        @include responsive-font-size(
            $font-size:   $form-section-subtitle-font-size,
            $line-height: $form-section-subtitle-line-height
        );
        display: inline-block; /* [12] */
        overflow: hidden; /* [12] */
        font-weight: $form-section-subtitle-font-weight;
        color: $form-section-subtitle-color;

        .c-form-section__header#{$form-section-title-state-collapsed}:not(.c-form-section__header--no-truncate) & {
            @include truncate(); /* [10] */
        }

    }

    .c-form-section__header-icon {
        @include media-query("<=#{$form-section-title-breakpoint}") {
            align-self: flex-start; /* [11] */
        }

        &::before {
            @include icon-font();
            @include font-size(
                $font-size: $form-section-icon-size,
                $line-height: false,
            );
            content: $form-section-icon-collapsed;
            transform: $form-section-icon-transform-collapsed;
            color: $form-section-icon-color;
            transition: transform div($form-section-animation-duration, 3) $form-section-animation-timing-function;

            @include media-query("<=#{$form-section-icon-size-breakpoint}") {
                @include font-size(
                    $font-size: $form-section-icon-size-on-breakpoint,
                    $line-height: 1
                );
            }

            .c-form-section--success &,
            .c-form-section--error & {
                @include font-size(
                    $font-size: $form-section-validation-icon-size,
                    $line-height: 1
                );
                transform: $form-section-validation-icon-transform-collapsed;

                @include media-query("<=#{$form-section-icon-size-breakpoint}") {
                    @include font-size(
                        $font-size: $form-section-validation-icon-size-on-breakpoint,
                        $line-height: 1
                    );
                }

            }

            .c-form-section--success & {
                content: $form-section-validation-icon-collapsed-success;
                color: $form-section-validation-icon-color-success;
            }

            .c-form-section--error & {
                content: $form-section-validation-icon-collapsed-error;
                color: $form-section-validation-icon-color-error;
            }

            .c-form-section__header#{$form-section-title-state-expanded} & {
                content: $form-section-icon-expanded;
                transform: $form-section-icon-transform-expanded;

                .c-form-section--success &,
                .c-form-section--error & {
                    transform: $form-section-validation-icon-transform-expanded;
                }

                .c-form-section--success & {
                    content: $form-section-validation-icon-expanded-success;
                }

                .c-form-section--error & {
                    content: $form-section-validation-icon-expanded-error;
                }

            }

        }

    }

    .c-form-section__panel {
        @include transition(
            $transition-property: grid-template-rows,
            $transition-duration: $form-section-animation-duration * 0.5,
        ); /* [23] */
        display: grid; /* [23] */
        grid-template-rows: 0fr; /* [23] */

        > * {
            overflow: hidden; /* [23] */
        }

        &:not(#{$form-section-panel-state-expanded}) .c-form-field__label {
            overflow: hidden; /* [24] */
            opacity: if($form-field-label-floating, 0, null); /* [21] */
        }

        &#{$form-section-panel-state-expanded} {
            grid-template-rows: 1fr; /* [23] */
        }

    }

    .c-form-section__content {
        @include responsive-property(
            ("padding-right",  $form-section-panel-spacing-right),
            ("padding-left",   $form-section-panel-spacing-left)
        );

        > :first-child {
            @include responsive-property("padding-top", $form-section-panel-spacing-top); /* [24] */
        }

        > :last-child {
            @include responsive-property("padding-bottom", $form-section-panel-spacing-bottom); /* [24] */
            margin-bottom: 0; /* [4] */
        }

    }
