// /* ------------------------------------*\
//     #CARD (Settings)
// \*------------------------------------ */

$card-padding-horizontal:                          $CARD-PATTERN-PADDING-HORIZONTAL !default;
$card-padding-vertical:                            $CARD-PATTERN-PADDING-VERTICAL !default;
$card-module-spacing:                              $CARD-PATTERN-MODULE-SPACING * 0.5 !default; // Actual value is 1/2 since spacing is added to both top and bottom.

$card-inactive-opacity:                            1 !default;  // Set colors instead of reducing opacity.

$card-background-color:                            $CARD-PATTERN-BACKGROUND-COLOR !default;
$card-border:                                      $CARD-PATTERN-BORDER !default;
$card-border-radius:                               $CARD-PATTERN-BORDER-RADIUS !default;

$card-title-font-size:                             $CARD-PATTERN-TITLE-SIZE !default;
$card-title-font-weight:                           $CARD-PATTERN-TITLE-WEIGHT !default;

$card-subtitle-font-size:                          $CARD-PATTERN-SUBTITLE-SIZE !default;
$card-subtitle-font-weight:                        $CARD-PATTERN-TITLE-WEIGHT !default;

$card-body-extra-spacing-bottom:                   $CARD-PATTERN-MODULE-SPACING !default;
$card-body-text-color:                             $CARD-PATTERN-TEXT-COLOR !default;

$card-shadow:                                      none !default;

$card-icon-size:                                   icon-size($CARD-PATTERN-ICON-SIZE) !default;
$card-icon-color:                                  $CARD-PATTERN-ICON-COLOR !default;

$card-responsive-collapse-icon-size:               icon-size(xsmall) !default;
$card-responsive-collapse-icon-collapsed:          get-icon(semantic-plus) !default;
$card-responsive-collapse-icon-expanded:           $card-responsive-collapse-icon-collapsed !default;
$card-responsive-collapse-icon-transform-expanded: rotateZ(45deg) !default;
$card-responsive-collapse-button-spacing:          $GLOBAL-SPACING-UNIT !default;
