// /* ------------------------------------*\
//     #RESET-BUTTON
// \*------------------------------------ */

/// Reset browser-default button styles.
/// @param {Boolean} $revolver [false] Reset fully. Otherwise only a minimalistic reset is done
///                                    that might leave some styles un-reset.
/// @param {Boolean} $remove-focus [true] Remove default focus styles.
/// @param {Color} $background-color [transparent] Set `background-color`.
@mixin reset-button($revolver: false, $remove-focus: true, $background-color: transparent) {
    padding: 0;
    border: 0;
    background-color: $background-color;

    @if ($revolver) {
        width: auto;
        overflow: visible;
        margin: 0;

        // Inherit font & color from ancestor.
        font: inherit;
        // Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+.
        line-height: normal;
        text-align: inherit;
        text-transform: inherit;
        color: inherit;


        // Corrects font smoothing for webkit.
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;

        // Corrects inability to style clickable `input` types in iOS.
        // stylelint-disable-next-line
        -webkit-appearance: none;

        &::-moz-focus-inner {
            padding: 0;
            border: 0;
        }

    } @else if ($remove-focus) {

        // @TODO: Refactor focus styles for better accessibility
        &:focus {
            outline: 0;
        }

    }

}
