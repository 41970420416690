// /* ------------------------------------*\
//     #COMBOBOX (Settings)
// \*------------------------------------ */

/**
 * [1] Resemble checkbox styles.
 */

@import "components.checkbox.settings"; // [1]

$combobox-height:                               $GLOBAL-INPUT-HEIGHT-STATIC-LABEL !default;
$combobox-padding-vertical:                     0 !default;
$combobox-padding-horizontal:                   $GLOBAL-INPUT-PADDING-HORIZONTAL !default;
$combobox-border-side:                          "-bottom" !default;
$combobox-border-width-focus:                   2px !default;
$combobox-border-width-open:                    $combobox-border-width-focus !default;

$combobox-listbox-padding-horizontal:           $GLOBAL-SPACING-UNIT !default;
$combobox-listbox-border:                       false !default;
$combobox-listbox-background:                   $COLOR-WHITE !default;
$combobox-listbox-shadow:                       $DROP-SHADOW-XS !default;

$combobox-item-padding-vertical:                $__SKODA-SPACING-STATIC-12__ !default;
$combobox-item-border:                          false !default;

$combobox-item-checkbox-background-selected:    $checkbox-background-color-checked !default;
$combobox-item-checkbox-background-disabled:    $checkbox-background-color-disabled !default; // [1]
$combobox-item-checkbox-border-color-selected:  $checkbox-border-color-checked !default;
$combobox-item-checkbox-border-color-disabled:  $checkbox-border-color-disabled !default; // [1]

$combobox-item-search-input-padding-horizontal: $GLOBAL-SPACING-UNIT !default;
$combobox-item-search-input-padding-vertical:   $GLOBAL-SPACING-UNIT-SMALL !default;
$combobox-item-search-input-border:             false !default;
