/* ------------------------------------*\
    #OVERFLOW-HIDDEN
\*------------------------------------ */

/**
 * A utility class for clipping the content at the element’s padding box.
 * No scrollbars are provided, and no support for allowing the user to scroll
 * (such as by dragging or using a scroll wheel) is allowed.
 */

.u-overflow-hidden {
    overflow: important(hidden, true);
}
