/* ------------------------------------*\
    #ITEM-TEASER
\*------------------------------------ */

$item-teaser-breakpoint:                           s !default;
$item-teaser-icon-size:                            icon-size(large) !default;
$item-teaser-icon-size-small:                      icon-size(medium) !default;
$item-teaser-icon-color:                           $COLOR-BRAND !default;
$item-teaser-icon-spacing:                         $GLOBAL-SPACING-UNIT-XSMALL !default;

$item-teaser-inline-icon-margin:                   $GLOBAL-SPACING-UNIT !default;

$item-teaser-title-font-family:                    $GLOBAL-HEADING-FONT-FAMILY !default;
$item-teaser-title-letter-spacing:                 $GLOBAL-HEADING-LETTER-SPACING !default;
$item-teaser-title-font-size-responsive:           false !default;
$item-teaser-title-font-size:                      $GLOBAL-HEADING-H5-FONT-SIZE !default;
$item-teaser-title-line-height:                    $GLOBAL-HEADING-BASE-LINE-HEIGHT !default;
$item-teaser-title-font-weight:                    $GLOBAL-HEADING-FONT-WEIGHT !default;
$item-teaser-title-spacing-vertical:               $GLOBAL-SPACING-UNIT-XSMALL !default;
$item-teaser-title-spacing-vertical-at-breakpoint: $item-teaser-title-spacing-vertical !default;

$item-teaser-inline-title-spacing:                 $GLOBAL-SPACING-UNIT-SMALL !default;
$item-teaser-inline-title-small-spacing:           $GLOBAL-SPACING-UNIT-XSMALL !default;

$item-teaser-alternative-padding:                  $GLOBAL-SPACING-UNIT !default;
$item-teaser-alternative-padding-small:            $GLOBAL-SPACING-UNIT-SMALL !default;
$item-teaser-alternative-border:                   null !default;
$item-teaser-alternative-background-color:         $COLOR-PALE !default;

$item-teaser-text-spacing-vertical:                $GLOBAL-SPACING-UNIT-XSMALL !default;
$item-teaser-text-spacing-vertical-at-breakpoint:  $item-teaser-text-spacing-vertical !default;

$item-teaser-row-spacing-columns:                  $GLOBAL-SPACING-UNIT !default;
$item-teaser-row-spacing-columns-small:            $GLOBAL-SPACING-UNIT-SMALL !default;
$item-teaser-row-spacing-rows:                     $GLOBAL-SPACING-UNIT-XSMALL !default;
$item-teaser-row-spacing-rows-small:               $GLOBAL-SPACING-UNIT-XXSMALL !default;

// Set the container query “breakpoint” in `rem` when the detail elements should
// break below the terms in the “split” variant.
$item-teaser-row-item-break-width:                 31rem !default;
// Map that is needed by the container queries mixin. The “breakpoint” gets
// converted to a unitless pixel value.
$item-teaser-row-width-queries: (
    s: 0,
    m: strip-unit($item-teaser-row-item-break-width) * strip-unit($GLOBAL-FONT-SIZE),
) !default;

// Size should be static, not based on breakpoint.
// This will "disable" `responsive-font-size`.
$item-teaser-title-font-size-static: (
    null: null,
) !default;
/**
 * [1] Increase specificity, so the declaration also works on grey background.
 * [2] Force item wrapping.
 * [3] Rewrite styles to align icon aside to content.
 * [4] Block layout (stacked icon and content) at breakpoint.
 * [5] Optionally disable responsive `font-size`.
 + [6] Use “row” layout when component is wider than `$item-teaser-row-item-break-width`.
 *     @TODO: Refactor to real container queries when browser support is high enough.
 * [7] Break after the title if there are more than 2 body items in total or
 *     the next item is not a button. For browser without `:has` support,
 *     a break is added after the title if there are 3 body items in total.
 * [8] Do not allow a line break in the link or button.
 */

/// Create a block layout (icon above content)
@mixin item-teaser-block() {
    display: flex;
    flex-wrap: wrap; /* [2] */
    text-align: center;

    > * {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%; /* [2] */
    }

}

/// Create an inline layout (icon next to content)
@mixin item-teaser-inline() {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: flex-start;
    text-align: left;

    > * {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
    }

}

.c-item-teaser {
    @include item-teaser-block();
}

    .c-item-teaser__icon {
        @include font-size(
            $font-size:   $item-teaser-icon-size,
            $line-height: 1
        );
        margin-bottom: $item-teaser-icon-spacing;

        &.c-item-teaser__icon { /* [1] */
            color: $item-teaser-icon-color;
        }

        .c-item-teaser--small & {
            @include font-size(
                $font-size:   $item-teaser-icon-size-small,
                $line-height: 1
            );
        }

        .c-item-teaser--inline & { /* [3] */
            align-self: baseline;
            margin-right: $item-teaser-inline-icon-margin;
            margin-bottom: 0;
        }

    }

    .c-item-teaser__title {
        @if ($item-teaser-title-font-size-responsive) {
            @include responsive-font-size( /* [5] */
                $font-size:   $item-teaser-title-font-size-static,
                $line-height: $item-teaser-title-line-height,
            );
        } @else {
            @include responsive-font-size(
                $font-size:   $item-teaser-title-font-size,
                $line-height: $item-teaser-title-line-height,
            );
        }
        margin-bottom: $item-teaser-title-spacing-vertical;
        font-family: $item-teaser-title-font-family;
        font-weight: $item-teaser-title-font-weight;
        letter-spacing: $item-teaser-title-letter-spacing;

        @include media-query("<=#{$item-teaser-breakpoint}") {
            margin-bottom: $item-teaser-title-spacing-vertical-at-breakpoint;
        }

        .c-item-teaser--inline & {
            margin-bottom: $item-teaser-inline-title-spacing;
        }

        .c-item-teaser--small.c-item-teaser--inline & {
            margin-bottom: $item-teaser-inline-title-small-spacing;
        }

    }

    .c-item-teaser__text {
        margin-bottom: 0;
    }

    .c-item-teaser__link {
        margin-top: $item-teaser-text-spacing-vertical;

        @include media-query("<=#{$item-teaser-breakpoint}") {
            margin-top: $item-teaser-text-spacing-vertical-at-breakpoint;
        }

    }

    .c-item-teaser--small {
        --item-teaser-alternative-padding: #{$item-teaser-alternative-padding-small};
        --item-teaser-row-spacing-columns: #{$item-teaser-row-spacing-columns-small};
        --item-teaser-row-spacing-rows: #{$item-teaser-row-spacing-rows-small};
    }

    .c-item-teaser--inline { /* [3] */
        @include item-teaser-inline();
    }

    .c-item-teaser--block {
        @include item-teaser-block();
    }

    .c-item-teaser--alternative {
        padding: var(--item-teaser-alternative-padding, $item-teaser-alternative-padding);
        border: $item-teaser-alternative-border;
        background-color: $item-teaser-alternative-background-color;
    }

    .c-item-teaser--row {
        --container-widths: "#{cq-convert-data($item-teaser-row-width-queries)}";
        display: flex;
        flex-direction: column;
        column-gap: var(--item-teaser-row-spacing-columns, $item-teaser-row-spacing-columns);
        row-gap: var(--item-teaser-row-spacing-rows, $item-teaser-row-spacing-rows);

        .c-item-teaser__icon {
            margin-bottom: 0;
        }

        > .c-item-teaser__body {
            display: flex;
            flex-direction: column;
            column-gap: var(--item-teaser-row-spacing-columns, $item-teaser-row-spacing-columns);
            row-gap: var(--item-teaser-row-spacing-rows, $item-teaser-row-spacing-rows);

            > * {
                margin-top: 0;
                margin-bottom: 0;
            }

        }

        // stylelint-disable-next-line order/order
        @include container($map: $item-teaser-row-width-queries, $size: "m") { /* [6] */
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            text-align: left;

            > * {
                flex: 0 1 auto;
            }

            > .c-item-teaser__icon {
                margin-right: auto;
                margin-bottom: 0;
                margin-left: auto;
            }

            > .c-item-teaser__body {
                flex-wrap: wrap;
                flex-direction: row;
                align-items: center;
                flex-grow: 1;

                > :not(:last-child) {
                    flex-grow: 1;
                }

                /* stylelint-disable selector-max-specificity, selector-max-combinators, selector-max-compound-selectors */
                > .c-item-teaser__title {

                    @include supports-not-has-selector() {
                        &:nth-child(1):nth-last-child(3) {
                            flex-basis: 100%; /* [7] */
                        }
                    }

                    &:has(~ :nth-child(3)),
                    &:has(+ :not(.c-item-teaser__link)) {
                        flex-basis: 100%; /* [7] */
                    }

                }

                > .c-item-teaser__link > * {
                    white-space: nowrap; /* [8] */
                }
                /* stylelint-enable */
            }
        }

    }


    @include media-query("<=#{$item-teaser-breakpoint}") {

        .c-item-teaser--inline\@small-screen { /* [3] */
            @include item-teaser-inline();

            .c-item-teaser__icon {
                align-self: baseline;
                margin-right: $item-teaser-inline-icon-margin;
                margin-bottom: 0;
            }

            .c-item-teaser__title {
                margin-bottom: $item-teaser-inline-title-spacing;
            }

        }

        .c-item-teaser--block\@small-screen { /* [4] */
            @include item-teaser-block();
        }

    }
