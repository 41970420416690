/* ------------------------------------*\
    #SHOW-MORE (Scopes)
\*------------------------------------ */

$scope-show-more-fieldset-spacing:         $GLOBAL-FIELDSET-ITEM-SPACING !default;
$scope-show-more-button-container-spacing: $GLOBAL-SPACING-UNIT !default;

/**
 * [1] Vertical spacing between Fieldset object and Show More.
 * [2] Vertical spacing between Show More and Button Container object.
 */

.c-show-more {

    .o-fieldset + & {

        .c-show-more__button {
            margin-top: 0; /* [1] */

            &[aria-expanded="true"] {
                margin-top: $scope-show-more-fieldset-spacing; /* [1] */
            }

        }

    }

    + .o-button-container {
        margin-top: $scope-show-more-button-container-spacing; /* [2] */
    }
}
