/* ------------------------------------*\
    #HERO-TEASER (Scopes)
\*------------------------------------ */

$scope-hero-teaser-enable-hint-styles:                   true !default;
$scope-hero-teaser-enable-hint-styles-inverted:          $hero-teaser-inverted !default;

$scope-hero-teaser-enable-show-more-spacing:             false !default;
$scope-hero-teaser-show-more-spacing:                    $GLOBAL-SPACING-UNIT-SMALL !default;

$scope-hero-teaser-hint-text-color:                      null !default;
$scope-hero-teaser-hint-text-color-inverted:             $COLOR-WHITE !default;
$scope-hero-teaser-hint-text-color-hover:                null !default;
$scope-hero-teaser-hint-text-color-hover-inverted:       $COLOR-WHITE !default;

$scope-hero-teaser-hint-background-color:                transparent !default;
$scope-hero-teaser-hint-background-color-hover:          null !default;
$scope-hero-teaser-hint-background-color-inverted:       transparent !default;
$scope-hero-teaser-hint-background-color-hover-inverted: null !default;

$scope-hero-teaser-hint-border-color-inverted:           currentColor !default;
$scope-hero-teaser-hint-border-color:                    currentColor !default;

$scope-hero-teaser-hint-selectors: (
    ".c-hero-teaser__pretitle",
    ".c-hero-teaser__title",
    ".c-hero-teaser__subtitle",
    ".c-hero-teaser__text",
) !default;

/// Mixin to add scoped Hint styles.
/// @access private
///
/// @param {List} $selectors [null] - CSS selectors where the Hint styles should be scoped to.
/// @param {Boolean} $inverted [false] - Controls the used color variables.
///
/// @example
///     div {
///         @include hint-styles(
///             $selectors: (".selector-1", ".selector-2", ...),
///             $inverted:  true,
///         );
///     }
@mixin hint-styles($selectors: null, $inverted: false) {

    $_color:                  if($inverted, $scope-hero-teaser-hint-text-color-inverted, $scope-hero-teaser-hint-text-color);
    $_color-hover:            if($inverted, $scope-hero-teaser-hint-text-color-hover-inverted, $scope-hero-teaser-hint-text-color-hover);
    $_background-color:       if($inverted, $scope-hero-teaser-hint-background-color-inverted, $scope-hero-teaser-hint-background-color);
    $_background-color-hover: if($inverted, $scope-hero-teaser-hint-background-color-hover-inverted, $scope-hero-teaser-hint-background-color-hover);
    $_border-color:           if($inverted, $scope-hero-teaser-hint-border-color-inverted, $scope-hero-teaser-hint-border-color);

    @if ($selectors) {
        // Iterates through the given selectors passed into the mixin and
        // generates the respective selectors.
        @each $selector in $selectors {

            #{$selector} {

                // Scoped Hint styles
                .c-hint {
                    border-color: $_border-color;
                    background-color: $_background-color;
                    color: $_color;

                    // @TODO BRON-8557
                    // - add focus state
                    // - hover & focus conditionally
                    &:hover {
                        background-color: $_background-color-hover;
                        color: $_color-hover;
                    }

                }

            }

        }
    }

}

/**
 * [1] If the inverted variant of the Hero Teaser component is enabled via
 *     the `$hero-teaser-inverted` Sass variable, the color must be inverted
 *     manually, since the color cannot be inherited from
 *     `[data-theme="brand"]`.
 * [2] For brands that have not enabled the inverted variant of the
 *     Hero Teaser component via the `$hero-teaser-inverted` Sass variable,
 *     but still need to adjust the color of the Hint component.
 * [3] Scale the video wrapper for background-videos to the parent’s height.
 * [4] Use same size mode as background video (`cover`) to provide a smoother experience before the video is loaded.
 * [5] Bypass the `Show more` settings, as they are hiding the input borders and make the focus styles partially invisible.
 */
.c-hero-teaser {

}

    @if ($scope-hero-teaser-enable-hint-styles) {
        @include hint-styles( /* [2] */
            $selectors: $scope-hero-teaser-hint-selectors,
            $inverted:  false,
        );
    }

    @if ($scope-hero-teaser-enable-hint-styles-inverted) {
        .c-hero-teaser--inverted {
            @include hint-styles(  /* [1] */
                $selectors: $scope-hero-teaser-hint-selectors,
                $inverted:  true,
            );
        }
    }

    .c-hero-teaser__background-video {

        .plyr--video {
            height: inherit; /* [3] */
        }

        .plyr__poster {
            background-size: cover; /* [4] */
        }

    }

    @if ($scope-hero-teaser-enable-show-more-spacing) {
        .c-hero-teaser__layer {

            .c-show-more__body {
                overflow: initial; /* [5] */
                padding-top: $scope-hero-teaser-show-more-spacing; /* [5] */
            }

        }
    }
