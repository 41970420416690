// /* ------------------------------------*\
//     #HERO-TEASER
// \*------------------------------------ */

/**
 * [1] On smaller screens the inverted color scheme is used for all variants.
 * [2] Button elements should be aligned at the bottom.
 * [3] Enable gradient for default variant on bigger screens.
 * [4] Enable gradient for inverted variant on bigger screens.
 * [5] Show above image/video, below content.
 * [6] Reduce video height on small screens.
 * [7] Set height for the overlay for Layer Variant to avoid
 *     exceeding the image height.
 */

$skoda-hero-teaser-content-break:           m !default;
$skoda-hero-teaser-content-color-inverted:  $hero-teaser-content-default-text-color-inverted !default;
$skoda-hero-teaser-content-inner-width:     60% !default;
$skoda-hero-teaser-letter-spacing:          $GLOBAL-FONT-LETTER-SPACING !default;
$skoda-hero-teaser-gradient-color:          $COLOR-BLACK !default;
$skoda-hero-teaser-gradient-color-inverted: $COLOR-WHITE !default;
$skoda-hero-teaser-content-text-spacing:    $GLOBAL-SPACING-UNIT !default;

$skoda-hero-teaser-content-spacing-top: (
    null:                                  $GLOBAL-SPACING-UNIT-XXLARGE,
    $skoda-hero-teaser-content-break:       $GLOBAL-SPACING-UNIT-LARGE,
    $hero-teaser-content-break:            $GLOBAL-SPACING-UNIT,
) !default;

@include media-query("<=#{$hero-teaser-content-break}") {

    .c-hero-teaser {
        @include responsive-property-map($hero-teaser-content-inverted, ".c-hero-teaser__"); /* [1] */
        color: $skoda-hero-teaser-content-color-inverted;
    }

    .c-hero-teaser__title-row {
        line-height: 1.2;
    }
}

    .c-hero-teaser__content {
        @include responsive-property(
            ("padding-top", $skoda-hero-teaser-content-spacing-top),
        );
    }

    .c-hero-teaser__content-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;  /* [2] */
        height: 100%;
    }

    .c-hero-teaser__pretitle {
        letter-spacing: $skoda-hero-teaser-letter-spacing;
    }

    .c-hero-teaser__text {
        margin-top: $skoda-hero-teaser-content-text-spacing;
    }

.c-hero-teaser--inverted {
    @include responsive-property-map($hero-teaser-content-inverted, ".c-hero-teaser__");
    color: $skoda-hero-teaser-content-color-inverted;
}

.c-hero-teaser__background-video {

    video {
        height: auto; /* [6] */
    }

}

@include media-query(">#{$hero-teaser-content-break}") {

    .c-hero-teaser {
        --hero-teaser-overlay-color-start: #{rgba($skoda-hero-teaser-gradient-color, 0.75)};
        --hero-teaser-overlay-color-end: #{rgba($skoda-hero-teaser-gradient-color, 0)};

        // Overlay for increased contrast
        &::after {
            content: "";
            position: absolute;
            z-index: z("hero-teaser-image") + 1; /* [5] */
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, var(--hero-teaser-overlay-color-start) 0, var(--hero-teaser-overlay-color-end) 80%); /* [3] */
            pointer-events: none;
        }

        &.c-hero-teaser--layer {
            &::after {
                @include responsive-property("height", $hero-teaser-height); /* [7] */
            }
        }
    }

    .c-hero-teaser--inverted {
        --hero-teaser-overlay-color-start: #{rgba($skoda-hero-teaser-gradient-color-inverted, 0.75)};
        --hero-teaser-overlay-color-end: #{rgba($skoda-hero-teaser-gradient-color-inverted, 0)};

        &.c-hero-teaser--layer {
            --hero-teaser-overlay-color-start: #{rgba($skoda-hero-teaser-gradient-color-inverted, 0.75)};
            --hero-teaser-overlay-color-end: #{rgba($skoda-hero-teaser-gradient-color-inverted, 0)};
        }
    }

}

@include media-query(">=#{$skoda-hero-teaser-content-break}") {

    .c-hero-teaser:not(.c-hero-teaser--layer) {

        .c-hero-teaser__heading {
            max-width: $skoda-hero-teaser-content-inner-width;
        }
    }

}
