/* ------------------------------------*\
    #LIST-INLINE
\*------------------------------------ */

/**
 * The list-inline object simply removes any indents and bullet points from
 * lists and puts them in a horizontal order.
 * [1] Set an approximately spacing matching the one whitespace character
 *     of the current list.
 */

.o-list-inline {
    display: inline-flex;
    flex-wrap: wrap;
    column-gap: 0.25em; /* [1] */
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
        margin-bottom: 0;
    }

}
