// /* ------------------------------------*\
//     #PAGE-WRAP
// \*------------------------------------ */

/// Small private helper to calculate max-width value for page wrap.
///
/// @param {number} $factor multiply width
/// @param {number} $padding padding (per side, not combined)
/// @returns {CSS} Calculated width.
@function _calc-page-wrap-width($factor, $padding) {
    // Use calc to combine width with paddings, in case pixel values are
    // combined with percentages.
    @return calc(#{$page-wrap-width * $factor } + #{$padding * 2});
}

/// Calculate width for page-wrap
///
/// @param {number} $factor multiply width
/// @returns {CSS} Returns the page wrap width plus two times default padding (left and right).
///                If no width for `$page-wrap-width` was defined, returns `none`.
@function get-page-wrap-width($factor: 1) {
    @if ($page-wrap-width == null) {
        @return none;
    }

    @if (type_of($page-wrap-padding-horizontal) == map) {
        // Create a responsive map that contains the correct `max-width` for
        // every breakpoint.
        $max-width-responsive: ();
        @each $breakpoint, $padding-at-breakpoint in $page-wrap-padding-horizontal {
            $val: _calc-page-wrap-width($factor, $padding-at-breakpoint);
            // Add the value to the responsive-value map.
            $max-width-responsive: map-merge($max-width-responsive, ($breakpoint: $val));
        }
        @return $max-width-responsive;
    }

    // Assume `$page-wrap-padding-horizontal` is a plain value.
    @return _calc-page-wrap-width($factor, $page-wrap-padding-horizontal);
}

/// Mixin to generate page-wrap to limit content.
@mixin page-wrap() {
    @include responsive-property(
        ("max-width",     get-page-wrap-width()),
        ("padding-left",  $page-wrap-padding-horizontal),
        ("padding-right", $page-wrap-padding-horizontal),
        ("--page-wrap",   $page-wrap-padding-horizontal) // could be used by other components
    );
    margin-right: auto;
    margin-left: auto;
}

/// Mixin to undo content limitation of `page-wrap()`.
@mixin undo-page-wrap() {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}
