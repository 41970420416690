/* ------------------------------------*\
    #SCOPED STYLES
\*------------------------------------ */

/**
 * In order to encapsulate Bronson components from the regular styled parts of
 * Non-Bronson pages that use Bronson components, we must reset styles.
 *
 * `$GLOBAL-SCOPED` will be set by Gulp while the scoped styles are being
 * compiled. (see `gulp/tasks/styles.js` and `packages/bronson-default/styleguide/docs/20-how-to/use-bronson-scoped-styles.md`)
 */

@if ($GLOBAL-SCOPED) {

    /**
     * Resets all applied styles to its initial values.
     */
    .bron,
    .bron::before,
    .bron::after,
    .bron *,
    .bron *::before,
    .bron *::after {
        all: initial;
    }

    /**
     * Resets all applied styles to browser/user defaults.
     */
    .bron-html,
    .bron-html::before,
    .bron-html::after,
    .bron-html *,
    .bron-html *::before,
    .bron-html *::after {
        all: revert;
    }

    /**
     * Patch the iframe `[frameborder="0"]` attribute from being overruled by `all: revert;`.
     */
    [frameborder="0"] {
        border: 0;
    }

}
