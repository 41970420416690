/* ------------------------------------*\
    #INLINE-EDITABLE
\*------------------------------------ */

$inline-editable-button-spacing:    $GLOBAL-SPACING-UNIT-XSMALL !default;
$inline-editable-button-display:    block !default;
$inline-editable-input-spacing:     $GLOBAL-SPACING-UNIT-SMALL !default;
$inline-editable-input-font-size:   $GLOBAL-INPUT-FONT-SIZE !default;
$inline-editable-input-font-weight: $GLOBAL-INPUT-FONT-WEIGHT !default;
$inline-editable-input-width:       1em !default;

/**
 * [1] Add proper button spacing.
 * [2] Reset right padding because browser’s already add safe-space there
 *     when not in editing mode.
 * [3] Reset all input properties when in non-editing mode.
 * [4] Hide the submit and reset button when in non-editing mode.
 * [5] Show the submit and reset button and hide the edit button
 *     when in editing mode.
 * [6] Add the addon minimum `min-width` to the input sizer width.
 * [7] Add the dynamic sizer via a pseudo element.
 * [8] The sizer width is controlled via the input value that is being
 *     dynamically updated via the `data-inline-editable-size` attribute.
 * [9] Include the font-size and font-width to make measuring the content more robust.
 * [10] Use an inline grid to position the input and taking up the sizer element’s space.
 * [11] Adjust sizer when in editable mode.
 * [12] Position the input and the sizer in one column and make it grow equally.
 *      The addon is positioned in the second column.
 */

.c-inline-editable {
    display: inline-flex;
    flex-direction: column;

    &:hover {
        cursor: text;
    }

    &[aria-hidden="false"] {

        .c-inline-editable__submit,
        .c-inline-editable__cancel {
            display: $inline-editable-button-display; /* [5] */
        }

        .c-inline-editable__btn {
            display: none; /* [5] */
        }

        .c-input {
            margin-right: $inline-editable-input-spacing; /* [1] */
        }

    }

}

    .c-inline-editable__wrap {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
    }

    .c-inline-editable__input,
    .c-inline-editable__input-addon {
        transition: important(none, true);

        .c-inline-editable:not([aria-hidden="false"]) & { /* [3] */
            height: inherit;
            min-width: inherit;
            padding: inherit;
            border-color: transparent;
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            background-color: inherit;
            color: inherit;
        }

    }

    .c-inline-editable__input {
        grid-area: 1 / 2; /* [12] */
    }

    .c-inline-editable__input-addon {
        grid-area: 1 / 3; /* [12] */
    }

    .c-inline-editable:not([aria-hidden="false"]) .c-inline-editable__input {
        padding-right: 0; /* [2] */
    }

    .c-inline-editable__cancel,
    .c-inline-editable__submit {
        display: none; /* [4] */
    }

    .c-inline-editable__btn,
    .c-inline-editable__cancel {
        margin-left: $inline-editable-button-spacing;
    }

    .c-inline-editable [data-inline-editable-size] {
        display: inline-grid; /* [10] */
    }

    [data-inline-editable-size]::after { /* [7] */
        content: attr(data-inline-editable-size) " "; /* [8] */
        display: block;
        grid-area: 1 / 2; /* [12] */
        white-space: pre;
        // stylelint-disable-next-line
        width: min-content;
        min-width: $inline-editable-input-width;
        visibility: hidden;

        .c-inline-editable[aria-hidden="false"] & { /* [11] */
            @include responsive-font-size($inline-editable-input-font-size); /* [9] */
            @if ($input-addon-min-width) {
                min-width: calc(#{$inline-editable-input-width} + #{$input-addon-min-width}); /* [6] */
            }
            padding-left: $input-addon-padding;
            padding-right: $input-addon-padding;
            font-weight: $inline-editable-input-font-weight; /* [9] */
        }

    }
