/* ------------------------------------*\
    #FORMS
\*------------------------------------ */

/**
 * [1] Remove user agent border style.
 * [2] Remove native search styles as they can not be customized and
 *     visually conflicts with the rest of inputs.
 */
fieldset {
    border: 0; /* [1] */
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; /* [2] */
}
