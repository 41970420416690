/* ------------------------------------*\
    #EFFICIENCY-TAG
\*------------------------------------ */

// We are using relative font size units here, so that the size always adapts to
// the context.
$efficiency-tag-vertical-offset:    -0.1em !default;
$efficiency-tag-min-width:          3.5em !default;
$efficiency-tag-height:             1.6em !default;
$efficiency-tag-padding-horizontal: 0.5em !default;
$efficiency-tag-font-family:        $BASE-FONT !default;
$efficiency-tag-font-size:          0.8em !default;
$efficiency-tag-font-weight:        $GLOBAL-FONT-WEIGHT-BOLD !default;
$efficiency-tag-color:              $COLOR-SUBTLE !default;
$efficiency-tag-text-color:         $COLOR-WHITE !default;
// $efficiency-tag-text-color-dark:    $COLOR-BASE !default; // Not used according to official guidelines despite being not accessible. Keep for later reference/use.

$efficiency-tag-variants: (
    "a\+\+\+": (
        color: #009640,
    ),
    "a\+\+": (
        color: #009640,
    ),
    "a\+": (
        color: #52ae32,
    ),
    "a": (
        color: #c8d400,
    ),
    "b": (
        color: #c8d400,
    ),
    "c": (
        color: #ffed00,
    ),
    "d": (
        color: #ec6608,
    ),
    "e": (
        color: #e30613,
    ),
    "f": (
        color: #e30613,
    ),
    "g": (
        color: #e30613,
    ),
) !default;

/**
 * [1] Reset all properties to their initial values so that no unwanted styles
 *     are leaked into the component.
 * [2] Re-set some properties again after the reset in [1].
 * [3] Center text vertically.
 * [4] The right padding is doubled to account for the reduced height at the
       arrowhead.
 * [5] Shift the tag a tiny bit to the top to align it visually nicer vertically
 *     next to text.
 * [6] We use `clip-path` to make an arrow-like shape.
 */

.c-efficiency-tag {
    all: initial; /* [1] */
    box-sizing: border-box; /* [2] */
    display: inline-flex; /* [3] */
    align-items: center; /* [3] */
    min-width: $efficiency-tag-min-width;
    min-height: $efficiency-tag-height;
    padding-right: ($efficiency-tag-padding-horizontal * 2); /* [4] */
    padding-left: $efficiency-tag-padding-horizontal;
    transform: translateY($efficiency-tag-vertical-offset); /* [5] */
    font-family: $efficiency-tag-font-family;
    font-size: $efficiency-tag-font-size;
    font-weight: $efficiency-tag-font-weight;
    vertical-align: baseline;
    background: var(--efficiency-tag-color, #{$efficiency-tag-color});
    color: var(--efficiency-tag-text-color, #{$efficiency-tag-text-color});
    /* stylelint-disable */
    clip-path: polygon(
        0% 0%, // left top
        calc(100% - #{$efficiency-tag-height * 0.5}) 0%, // right top
        100% 50%, // right center
        calc(100% - #{$efficiency-tag-height * 0.5}) 100%, // right bottom
        0% 100% // left bottom
    ); /* [6] */
    /* stylelint-enable */
}

    /* Variants */
    @each $variant, $props in $efficiency-tag-variants {
        [data-efficiency-tag="#{$variant}" i] {
            --efficiency-tag-color: #{map-get($props, color)};
            @if (map-get($props, text-color)) {
                --efficiency-tag-text-color: #{map-get($props, text-color)};
            }
        }
    }
