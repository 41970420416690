/* ------------------------------------*\
    #COMPONENT-WRAPPER
\*------------------------------------ */

/**
 * The component-wrapper is an object that needs to be the container of every
 * component, which sits directly in a content section. It makes sure that the
 * spacing between the components is always correct.
 */

$component-wrapper-spacing-bottom: $GLOBAL-SPACING-UNIT-LARGE !default;

.o-component-wrapper {

    &:not(:last-child) {
        margin-bottom: $component-wrapper-spacing-bottom;
    }

}
