// /* ------------------------------------*\
//     #FLOAT-GRID
// \*------------------------------------ */

@use "sass:list";

/// Mixin for Float Grid areas.
///
/// @param {String} $area - Float Grid area where the element should be moved to.
/// @param {Number} $order - Position of the element in the Float Grid area.
/// @returns {CSS} Float Grid area CSS custom properties.
///
/// [1] Assign area to CSS custom property that will be used by JavaScript.
/// [2] Add current selector to the list of selectors that will be used by
///     JavaScript.
/// [3] Reset position of element in the Float Grid area.
/// [4] Allowing a custom order or position of the element in the Float Grid area.
/// [5] Since we cannot remove custom properties from a scope, we set the value
///     for child elements to the string(!) `false`. We use this in the
///     `moveElementToGridArea()` script to avoid moving child elements of a
///     Float Grid item into a Float Grid area.
@mixin float-grid-area($area, $position: static, $order: null) {
    --float-grid-area: #{$area}; // [1]
    $float-grid-selectors: list.append($float-grid-selectors, #{&}, "comma") !global; // [2]
    position: $position; // [3]
    order: $order; // [4]

    > * {
        --float-grid-area: false; // [5]
    }
}
