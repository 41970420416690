/* ------------------------------------*\
    #ICON-LIST
\*------------------------------------ */

$icon-list-alignment:                    left !default;
$icon-list-icon-size:                    icon-size(small) !default;
$icon-list-icon-color:                   null !default;
$icon-list-icon-spacing:                 $GLOBAL-SPACING-UNIT-XSMALL !default;
$icon-list-item-spacing-vertical:        null !default;
// By aligning the icon centered vertically to the text with
// `vertical-align: middle`, the center is always a bit off.
// Assigning a magic number is the only adequate solution for this problem.
$icon-list-icon-vertical-compensation:   0.25em !default;
// Aligning the icon horizontally to the very left.
$icon-list-icon-horizontal-compensation: $icon-list-icon-size + $icon-list-icon-spacing !default;

/**
 * [1] Create spacing between icon and text...
 * [2] Reset default list stylings. We do not use the `@include list-bare()`
 *     mixin, since we explicitly do want the margin between the `<li>`s, which
 *     list-bare removes.
 * [3] Magic number to _properly_ align the icon vertically to the text.
 * [4] If the design specifies a spacing between icon list items, set it.
 *     This applies to all items except the first one.
 * [5] Set positioning context for `.c-icon-list__icon`. This helps to make
 *     the icon stay in place in overflow contexts, see BRON-6699.
 */
.c-icon-list {
    margin: 0;
    padding-left: $icon-list-icon-horizontal-compensation; /* [1] */
    text-align: $icon-list-alignment;
    list-style: none; /* [2] */

    li {
        position: relative; /* [5] */
    }

    li + li {
        margin-top: $icon-list-item-spacing-vertical; /* [4] */
    }

}

    .c-icon-list__icon {
        position: absolute;
        line-height: inherit;
        color: $icon-list-icon-color;

        &::before {
            @include font-size(
                $font-size:   $icon-list-icon-size,
                $line-height: 1
            );
            position: absolute;
            top: if($icon-list-icon-vertical-compensation, $icon-list-icon-vertical-compensation, null); /* [3] */
            left: -($icon-list-icon-horizontal-compensation);
        }

    }
