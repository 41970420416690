// /* ------------------------------------*\
//     #TAG
// \*------------------------------------ */

/**
 * [1] Remove line-height and component height dependency based on icon size.
 * [2] Offset to fit into tag without creating extra space.
 */
.c-tag {
}

.c-tag__label {
        min-height: 0; /* [1] */
        line-height: 1; /* [2] */
    }

    .c-tag__close {
        margin-top: -($tag-padding-vertical); /* [2] */
        margin-bottom: -($tag-padding-vertical); /* [2] */
        margin-right: -($tag-padding-horizontal); /* [2] */
    }
