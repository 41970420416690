/* ------------------------------------*\
   #FLOAT-GRID
\*------------------------------------ */

@use "sass:map";
@use "sass:list";

$float-grid-breakpoint:                   s !default;
$float-grid-spacing:                      0 !default;
$float-grid-page-wrap-width:              $page-wrap-width !default;
$float-grid-page-wrap-horizontal-padding: $page-wrap-padding-horizontal !default;
$float-grid-row-gap:                      $GLOBAL-SPACING-UNIT-SMALL !default;
$float-grid-item-gap:                     $float-grid-row-gap !default;
$float-grid-area-padding-horizontal:      $float-grid-row-gap !default;
$float-grid-area-padding-vertical:        null !default;
$float-grid-areas: (
    top: (
        grid-area: t,
    ),
    bottom: (
        grid-area: b,
    ),
    top-left: (
        grid-area:          tl,
        justify-content:    start,
        align-items:        start,
        offset:             true,
        offset-direction:   negative,
        padding-horizontal: $float-grid-area-padding-horizontal,
        padding-vertical:   $float-grid-area-padding-vertical,
    ),
    top-center: (
        grid-area:          tc,
        flex-direction:     row,
        justify-content:    center,
        align-items:        flex-start,
        padding-horizontal: $float-grid-area-padding-horizontal,
        padding-vertical:   $float-grid-area-padding-vertical,
    ),
    top-right: (
        grid-area:          tr,
        justify-content:    flex-start,
        align-items:        flex-end,
        offset:             true,
        padding-horizontal: $float-grid-area-padding-horizontal,
        padding-vertical:   $float-grid-area-padding-vertical,
    ),
    center-left: (
        grid-area:          cl,
        justify-content:    center,
        align-items:        flex-start,
        offset:             true,
        offset-direction:   negative,
        padding-horizontal: $float-grid-area-padding-horizontal,
        padding-vertical:   $float-grid-area-padding-vertical,
    ),
    center-center: (
        grid-area:          cc,
        flex-direction:     row,
        justify-content:    center,
        align-items:        center,
        padding-horizontal: $float-grid-area-padding-horizontal,
        padding-vertical:   $float-grid-area-padding-vertical,
    ),
    center-right: (
        grid-area:          cr,
        justify-content:    center,
        align-items:        flex-end,
        offset:             true,
        padding-horizontal: $float-grid-area-padding-horizontal,
        padding-vertical:   $float-grid-area-padding-vertical,
    ),
    bottom-left: (
        grid-area:          bl,
        justify-content:    flex-end,
        align-items:        flex-start,
        offset:             true,
        offset-direction:   negative,
        padding-horizontal: $float-grid-area-padding-horizontal,
        padding-vertical:   $float-grid-area-padding-vertical,
    ),
    bottom-center: (
        grid-area:          bc,
        flex-direction:     row,
        justify-content:    center,
        align-items:        flex-end,
        padding-horizontal: $float-grid-area-padding-horizontal,
        padding-vertical:   $float-grid-area-padding-vertical,
    ),
    bottom-right: (
        grid-area:          br,
        justify-content:    flex-end,
        align-items:        flex-end,
        offset:             true,
        padding-horizontal: $float-grid-area-padding-horizontal,
        padding-vertical:   $float-grid-area-padding-vertical,
    ),
) !default;

// The Float Grid mixin adds all CSS selectors that are needed by JavaScript to
// this list. The complete list is then being used as a CSS custom property
// `--float-grid-elements` in `_shame.scss`.
$float-grid-selectors: () !default;

/**
 * [1] Consider responsive page wrap paddings when calculating grid template
 *     columns.
 * [2] Define named grid areas (Unfortunately it is not possible with Sass to
 *     define this as a multiline variable).
 * [3] Calculate grid template columns.
 * [4] Set grid template rows.
 * [5] Disable Float Grid pointer events to allow click through the grid.
 * [6] Position items in grid areas as flex items.
 * [7] Generate CSS custom property `--float-grid-areas` that will be used by
 *     JavaScript to build the Float Grid areas’ markup.
 * [8] Generate `data-float-grid-area` CSS rules for each area.
 * [9] Enable pointer events for items in Float Grid areas.
 * [10] Change the minimum Float Grid area size from `max-content` to `0` in
 *      smaller viewports to allow the overlapping of Float Grid area content
 *      with other areas. This is a compromise to give items enough space
 *      (e.g. the Incognito Action Buttons).
 * [11] The item offset is being used to push an item out of a Float Grid area
 *      if enough space is available. `100%` is equal to the item width.
 * [12] Set the direction of the item offset by setting a custom property that
 *      will be used later in the offset calculation. Negative values will push
 *      the item to the left, positive values will push it to the right.
 * [13] Add each Float Grid area selector to the selector list with the calculation.
 *      We are using `@extend' instead of a mixin to avoid duplicating the
 *      complex `transform` property for each area.
 * [14] Calculate the transform property to move items out of Float Grid areas.
 *      Check inline comments for details.
 */

.o-float-grid {
    --page-wrap-padding-horizontal: 0; /* [1] */
    --float-grid-column-min-size: max-content;
    --float-grid-row-min-size: var(--float-grid-column-min-size);
    --float-grid-column-gap: var(--page-wrap-padding-horizontal); /* [1] */
    --float-grid-row-gap: #{$float-grid-row-gap};
    --float-grid-item-gap: #{$float-grid-item-gap};
    position: fixed;
    z-index: z(float-grid);
    top: $float-grid-spacing;
    bottom: $float-grid-spacing;
    left: $float-grid-spacing;
    right: $float-grid-spacing;
    display: grid;
    grid-template-areas:
        "t   t   t   t   t"
        ".   tl  tc  tr  ."
        ".   cl  cc  cr  ."
        ".   bl  bc  br  ."
        "b   b   b   b   b"; /* [2] */
    grid-template-columns: calc((100% - #{$float-grid-page-wrap-width}) / 2 - var(--page-wrap-padding-horizontal)) repeat(3, minmax(var(--float-grid-column-min-size), 1fr)) calc((100% - #{$float-grid-page-wrap-width}) / 2 - var(--page-wrap-padding-horizontal)); /* [1] [3] */
    grid-template-rows: auto repeat(3, minmax(var(--float-grid-row-min-size), 1fr)) auto; /* [4] */
    column-gap: var(--float-grid-column-gap); /* [1] */
    row-gap: var(--float-grid-row-gap);
    pointer-events: none; /* [5] */

    @if (is-map($float-grid-page-wrap-horizontal-padding)) {
        @include responsive-property("--page-wrap-padding-horizontal", $float-grid-page-wrap-horizontal-padding); /* [1] */
    } @else {
        --page-wrap-padding-horizontal: #{$float-grid-page-wrap-horizontal-padding}; /* [1] */
    }

    @include media-query("<#{$float-grid-breakpoint}") {
        --float-grid-column-min-size: 0; /* [10] */
    }
}

.o-float-grid__area {
    --grid-area-item-offset: 100%; /* [11] */
    display: flex; /* [6] */
    gap: var(--float-grid-item-gap);
    flex-direction: column;
    padding-top: var(--float-grid-area-padding-vertical);
    padding-right: var(--float-grid-area-padding-horizontal);
    padding-bottom: var(--float-grid-area-padding-vertical);
    padding-left: var(--float-grid-area-padding-horizontal);
}

$_float-grid-areas-custom-property: (); /* [7] */ /* stylelint-disable-line scss/dollar-variable-default */

@each $area, $props in $float-grid-areas {
    $_float-grid-areas-custom-property: list.append($_float-grid-areas-custom-property, $area); /* [7] */

    [data-float-grid-area="#{$area}"] { /* [8] */
        @if (map.get($props, "padding-horizontal")) {
            --float-grid-area-padding-horizontal: #{map.get($props, "padding-horizontal")};
        }
        @if (map.get($props, "padding-vertical")) {
            --float-grid-area-padding-vertical: #{map.get($props, "padding-vertical")};
        }
        grid-area: map.get($props, "grid-area");
        flex-direction: map.get($props, "flex-direction");
        justify-content: map.get($props, "justify-content");
        align-items: map.get($props, "align-items");

        @if (map.get($props, "offset")) {
            --grid-area-offset-direction: #{if(map.get($props, "offset-direction") == "negative", -1, 1)};  /* [12] */

            > * {
                @extend %float-grid-area-offset; /* [13] */
            }

        }
    }
}

/* stylelint-disable */
%float-grid-area-offset {
    transform: translateX( /* [14] */
        calc(
            min(
                // The offset between the maximum offset and no offset at all:
                max(
                    // the remaining viewport space on one side of the page wrap
                    (100vw - var(--page-wrap-width)) / 2,
                    // or `0px` (to avoid negative values)
                    0px
                ),
                // The maximum item offset:
                //   item offset (default: `100%` of the item width)
                //   + area padding * 2 (to account for padding/space inside and outside of the float grid area)
                var(--grid-area-item-offset) + (var(--float-grid-area-padding-horizontal) * 2)
            )
            // Multiply by `1` or `-1` to set the offset direction.
            // A negative offset is needed on the left side, a positive offset on the right side.
            * var(--grid-area-offset-direction)
        )
    );
}

:root {
    --float-grid-areas: #{$_float-grid-areas-custom-property}; /* [7] */
}

// Increase specificity with an ID to override 3rd-party styles
#float-grid .o-float-grid__area > * {
    pointer-events: auto; /* [9] */
}
/* stylelint-enable */
