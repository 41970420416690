/* ------------------------------------*\
    #SITE-NAV-DROPDOWN
\*------------------------------------ */

// Default value for Bluelabel (https://zpl.io/a71q1K2)
$site-nav-dropdown-width:                                  312px !default;
$site-nav-dropdown-top:                                    100% !default;
$site-nav-dropdown-offset:                                 0 !default;
$site-nav-dropdown-background-color:                       $COLOR-BRAND !default;
$site-nav-dropdown-state-collapsed:                        ".is-collapsed" !default;
$site-nav-dropdown-has-transition:                         true !default;

$site-nav-dropdown-list-padding-horizontal:                $GLOBAL-SPACING-UNIT !default;
$site-nav-dropdown-list-padding-horizontal-at-breakpoint:  $GLOBAL-SPACING-UNIT !default;
$site-nav-dropdown-list-padding-vertical:                  $GLOBAL-SPACING-UNIT-XSMALL !default;
$site-nav-dropdown-list-padding-vertical-at-breakpoint:    0 !default;

$site-nav-dropdown-title-font-family:                      null !default;
$site-nav-dropdown-title-font-size:                        $FS-1 !default;
$site-nav-dropdown-title-color:                            $COLOR-WHITE !default;
$site-nav-dropdown-title-font-weight:                      $GLOBAL-FONT-WEIGHT-BOLD !default;
$site-nav-dropdown-title-padding-horizontal:               $GLOBAL-SPACING-UNIT !default;
$site-nav-dropdown-title-padding-horizontal-at-breakpoint: $GLOBAL-SPACING-UNIT-XSMALL !default;
$site-nav-dropdown-title-padding-vertical:                 $GLOBAL-SPACING-UNIT-XSMALL !default;
$site-nav-dropdown-title-padding-vertical-at-breakpoint:   $GLOBAL-SPACING-UNIT-XSMALL !default;
$site-nav-dropdown-title-margin-bottom:                    null !default;
$site-nav-dropdown-title-text-transform:                   false !default;

$site-nav-dropdown-item-margin-bottom:                     null !default;
$site-nav-dropdown-item-border-width:                      1px !default;
$site-nav-dropdown-item-border-color:                      $COLOR-SUBTLE !default;
$site-nav-dropdown-item-border:                            $site-nav-dropdown-item-border-width solid $site-nav-dropdown-item-border-color !default;

$site-nav-dropdown-link-font-family:                       null !default;
$site-nav-dropdown-link-font-size:                         $FS0 !default;
$site-nav-dropdown-link-font-weight:                       $GLOBAL-FONT-WEIGHT-BOLD !default;
$site-nav-dropdown-link-color:                             $COLOR-WHITE !default;
$site-nav-dropdown-link-color-hover:                       $COLOR-WHITE !default;
$site-nav-dropdown-link-color-active:                      $site-nav-dropdown-link-color-hover !default;
$site-nav-dropdown-link-padding-horizontal:                $GLOBAL-SPACING-UNIT !default;
$site-nav-dropdown-link-padding-horizontal-at-breakpoint:  $GLOBAL-SPACING-UNIT-XSMALL !default;
$site-nav-dropdown-link-padding-vertical:                  $GLOBAL-SPACING-UNIT-XSMALL !default;
$site-nav-dropdown-link-padding-vertical-at-breakpoint:    $GLOBAL-SPACING-UNIT-XSMALL !default;
$site-nav-dropdown-link-background-color-hover:            false !default;
$site-nav-dropdown-link-background-color-active:           false !default;
$site-nav-dropdown-link-text-decoration:                   none !default;
$site-nav-dropdown-link-text-transform:                    false !default;

/**
 * [1] If there is more then one `.c-site-nav__nav`, always align the dropdown
 *     of the last `.c-site-nav__nav` to the right.
 * [2] Make the transition just available for viewport width above breakpoint.
 * [3] Change the list's position for fly-in and fly-out effects.
 * [4] At breakpoint, the dropdown always has full width.
 * [5] Defensively reset any default list-style properties.
 */
.c-site-nav-dropdown {
    max-height: 0;
    visibility: hidden;
    overflow: hidden;

    @include site-nav-breakpoint("above") {
        @if ($site-nav-dropdown-has-transition) {
            @include transition(); /* [2] */
        }
        position: absolute;
        top: $site-nav-dropdown-top;
        left: $site-nav-dropdown-offset;
        width: $site-nav-dropdown-width;
    }

    @include site-nav-breakpoint("below") {
        width: 100%; /* [4] */
    }

    &:not(#{$site-nav-dropdown-state-collapsed}) {
        max-height: none;
        visibility: visible;
    }

}

.c-site-nav__nav:last-child:not(:only-child) .c-site-nav-dropdown {

    @include site-nav-breakpoint("above") {
        right: 0; /* [1] */
        left: auto; /* [1] */
    }

}


    .c-site-nav-dropdown__list {
        margin: 0; /* [5] */
        padding: $site-nav-dropdown-list-padding-vertical $site-nav-dropdown-list-padding-horizontal;
        transform: translateY(-100%); /* [3] */
        list-style: none; /* [5] */
        background-color: $site-nav-dropdown-background-color;

        @include site-nav-breakpoint("above") {
            @if ($site-nav-dropdown-has-transition) {
                @include transition(); /* [2] */
            }
        }

        @include site-nav-breakpoint("below") {
            @include responsive-property(
                ("padding-top",    $site-nav-dropdown-list-padding-vertical-at-breakpoint),
                ("padding-left",   $site-nav-dropdown-list-padding-horizontal-at-breakpoint),
                ("padding-right",  $site-nav-dropdown-list-padding-horizontal-at-breakpoint),
                ("padding-bottom", $site-nav-dropdown-list-padding-vertical-at-breakpoint),
            );
        }

        .c-site-nav-dropdown:not(#{$site-nav-dropdown-state-collapsed}) & {
            transform: translateY(0); /* [3] */
        }

    }

    .c-site-nav-dropdown__title {
        @include responsive-font-size($site-nav-dropdown-title-font-size);
        display: block;
        margin-bottom: $site-nav-dropdown-title-margin-bottom;
        padding: $site-nav-dropdown-title-padding-vertical $site-nav-dropdown-title-padding-horizontal;
        font-family: $site-nav-dropdown-title-font-family;
        font-weight: $site-nav-dropdown-title-font-weight;
        color: $site-nav-dropdown-title-color;

        @if ($site-nav-dropdown-title-text-transform) {
            text-transform: $site-nav-dropdown-title-text-transform;
        }

        @include site-nav-breakpoint("below") {
            @include responsive-property(
                ("padding-top",    $site-nav-dropdown-title-padding-vertical-at-breakpoint),
                ("padding-left",   $site-nav-dropdown-title-padding-horizontal-at-breakpoint),
                ("padding-right",  $site-nav-dropdown-title-padding-horizontal-at-breakpoint),
                ("padding-bottom", $site-nav-dropdown-title-padding-vertical-at-breakpoint),
            );
        }

    }

    .c-site-nav-dropdown__item {
        margin-bottom: $site-nav-dropdown-item-margin-bottom;

        @if ($site-nav-dropdown-item-border) {
            border-bottom: $site-nav-dropdown-item-border;

            &:last-child {

                @include site-nav-breakpoint("below") {
                    border: 0;
                }

            }

        }

    }

    .c-site-nav-dropdown__link {
        @include responsive-font-size($site-nav-dropdown-link-font-size);
        @include transition();
        display: block;
        padding: $site-nav-dropdown-link-padding-vertical $site-nav-dropdown-link-padding-horizontal;
        font-family: $site-nav-dropdown-link-font-family;
        font-weight: $site-nav-dropdown-link-font-weight;
        text-decoration: $site-nav-dropdown-link-text-decoration;
        color: $site-nav-dropdown-link-color;

        @if ($site-nav-dropdown-link-text-transform) {
            text-transform: $site-nav-dropdown-link-text-transform;
        }

        @include site-nav-breakpoint("below") {
            @include responsive-property(
                ("padding-top",    $site-nav-dropdown-link-padding-vertical-at-breakpoint),
                ("padding-left",   $site-nav-dropdown-link-padding-horizontal-at-breakpoint),
                ("padding-right",  $site-nav-dropdown-link-padding-horizontal-at-breakpoint),
                ("padding-bottom", $site-nav-dropdown-link-padding-vertical-at-breakpoint),
            );
        }

        &:hover {
            @if ($site-nav-dropdown-link-background-color-hover) {
                background-color: $site-nav-dropdown-link-background-color-hover;
            }
            color: $site-nav-dropdown-link-color-hover;
        }

        &.is-active,
        &:focus {
            @if ($site-nav-dropdown-link-background-color-active) {
                background-color: $site-nav-dropdown-link-background-color-active;
            }
            color: $site-nav-dropdown-link-color-active;
        }

    }
