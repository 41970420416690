// /* ------------------------------------*\
//     #SECTION-NAV (Settings)
// \*------------------------------------ */

$section-nav-shadow:                     $DROP-SHADOW-L !default;
$section-nav-wrapper-spacing-vertical:   0 !default;
$section-nav-wrapper-spacing-horizontal: $GLOBAL-SPACING-UNIT-SMALL !default;
$section-nav-item-font-weight:           $GLOBAL-FONT-WEIGHT-BOLD !default;
$section-nav-item-font-weight-is-active: $section-nav-item-font-weight !default;
$section-nav-item-spacing-horizontal:    $GLOBAL-SPACING-UNIT-SMALL !default;
$section-nav-item-text-size:             $FS0 !default;
$section-nav-link-color-hover:           $COLOR-BASE-TEXT !default;
$section-nav-link-color-hover-is-active: $COLOR-BASE-TEXT !default;
$section-nav-label-color:                inherit !default;
$section-nav-arrow-color:                currentColor !default;
