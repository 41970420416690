// /* ------------------------------------*\
//     #HERO-TEASER (Settings)
// \*------------------------------------ */

$hero-teaser-content-default-margin-bottom:       $GLOBAL-SPACING-UNIT-XSMALL !default;
$hero-teaser-content-default-text-color:          $COLOR-WHITE !default;
$hero-teaser-content-default-text-color-inverted: $COLOR-BASE-TEXT !default;
$hero-teaser-content-breakpoint-offset:           0 !default;
$hero-teaser-layer-spacing:                       $GLOBAL-SPACING-UNIT-LARGE !default;
$hero-teaser-layer-border-radius:                 $GLOBAL-BORDER-RADIUS !default;
$hero-teaser-layer-border:                        1px solid $COLOR-BORDER !default;

$hero-teaser-content-overwrites: (
    layer: (
        border-radius:  $hero-teaser-layer-border-radius,
    ),
    title-row: (
        font-size:      $FS4,
        line-height:    1,
    ),
    pretitle: (
        font-size:      $FS-3,
        font-weight:    $GLOBAL-FONT-WEIGHT-BOLD,
    ),
    subtitle: (
        font-size:      $FS1,
    ),
) !default;
