/* ------------------------------------*\
    #EXPANDABLE-CONTENT
\*------------------------------------ */

$skoda-expandable-content-border:        $GLOBAL-BORDER-WIDTH solid $GLOBAL-BORDER-COLOR !default;
$skoda-expandable-content-border-color:  $GLOBAL-BORDER-COLOR !default;
$skoda-expandable-content-border-radius: $GLOBAL-BORDER-RADIUS !default;

.c-expandable-content {

    &::before {
        border-color: $skoda-expandable-content-border-color;
    }

    &:not(.is-closed-within)::before {
        border: $skoda-expandable-content-border;
        border-radius: $skoda-expandable-content-border-radius;
    }

}

    .c-expandable-content__content > *:last-child {
        margin-bottom: 0;
    }

    .c-expandable-content__icon {
        @include button-icon-states(
            $context-selector: ".c-expandable-content__trigger",
        );
    }
