/* ------------------------------------*\
    #RADIO-BUTTON
\*------------------------------------ */

// The horizontal spacing between radio button and label text.
$radio-button-spacing:                      $GLOBAL-SPACING-UNIT-SMALL !default;

// The size of the round radio button.
$radio-button-size:                         28px !default;
$radio-button-border-width:                 $GLOBAL-INPUT-BORDER-WIDTH !default;
$radio-button-border-style:                 $GLOBAL-INPUT-BORDER-STYLE !default;
$radio-button-border-color:                 $GLOBAL-INPUT-BORDER-COLOR !default;
$radio-button-border-color-focus:           $GLOBAL-INPUT-BORDER-COLOR-FOCUS !default;
$radio-button-border-color-checked:         $radio-button-border-color !default;
$radio-button-border-color-hover:           $radio-button-border-color-checked !default;
$radio-button-border-color-checked-hover:   null !default;
$radio-button-border-color-disabled:        rgba($COLOR-INACTIVE, 0.5) !default;
$radio-button-border-color-error:           $COLOR-ALERT !default;
$radio-button-border:                       $radio-button-border-width $radio-button-border-style $radio-button-border-color !default;

$radio-button-background-color:             transparent !default;
$radio-button-background-color-checked:     $radio-button-background-color !default;
$radio-button-background-color-error:       null !default;
$radio-button-background-color-disabled:    $radio-button-background-color !default;
$radio-button-background-color-alternative: $radio-button-background-color !default;

// The size of the little dot inside of the radio button;
// Just applies when checked.
$radio-button-dot-size:                     16px !default;
$radio-button-dot-background-color:         $COLOR-BRAND !default;
$radio-button-dot-background-color-hover:   $radio-button-dot-background-color !default;
$radio-button-dot-background-disabled:      rgba($radio-button-dot-background-color, 0.5) !default;
$radio-button-dot-background-error:         $COLOR-ALERT !default;

$radio-label-text-size:                     $FS0 !default;

// The line height of the label text. This needs to be a `px` value,
// since it's used for calculation.
$radio-label-line-height:                   $GLOBAL-LINE-HEIGHT !default;
@if (unit($radio-label-line-height) != "px") {
    @error("`$radio-label-line-height` needs to be a `px` value!");
}

$radio-label-text-font-weight:              null !default;
$radio-label-text-color:                    $COLOR-BASE-TEXT !default;
$radio-label-text-color-checked:            null !default;
$radio-label-text-color-disabled:           rgba($COLOR-BASE-TEXT, 0.5) !default;
$radio-label-text-color-error:              $COLOR-ALERT !default;
$radio-label-text-color-hover:              $radio-label-text-color !default;

$radio-button-has-shadow:                   false !default;
$radio-button-shadow-checked:               none !default;

$radio-button-themes: (
    "alternative": (
        "border-color-disabled":     $radio-button-border-color-disabled,
        "background-color-checked":  $radio-button-background-color-alternative,
        "label-text-color-disabled": $radio-label-text-color-disabled
    )
) !default;

/**
 * [1] Defaulting to inline-block gives us the most flexibility.
 * [2] Defensively reset any margin-bottom.
 * [3] Set the indent of an adjusting `.c-form-field__extra` via a CSS custom
 *     property.
 * [4] Hide original radio button.
 * [5] Make it blocky so everything behaves as expected.
 * [6] Setting the height of the label text to a minimum of the radio button
 *     size makes the text clickable on its entire height, i.e.: It increases
 *     the click area.
 * [7] Align the first (or only) row of label text vertically centered to the
 *     radio button.
 *     Use `max` to ensure the calculation does not result in negative value.
 *     `max()` is the Sass function, not the native CSS function.
 * [8] Keep the spacing between radio button and label text consistent,
 *     independent from the radio button size.
 * [9] Relation for the radio button.
 * [10] Horizontally center the dot inside of the radio button.
 * [11] Scaling seems to be the most efficient and only “nice” method that
 *      works across all browsers. Other methods brought yanky, jumpy
 *      animation artifacts.
 * [12] `.c-radio__label-container` could be an additional container for the
 *      label. For example, it is used by the “Compare and Select” component to
 *      change radio button styles.
 */

.c-radio {
    @include disable-touch-highlight();
    display: inline-block; /* [1] */
    margin-bottom: 0; /* [2] */
    cursor: pointer;

    + .c-form-field__extra {
        --form-field-extra-indent: #{($radio-button-size + $radio-button-spacing)}; /* [3] */
    }
}

    .c-radio__input {
        @include a11y-hidden(); /* [4] */
    }

    .c-radio__label {
        @include responsive-font-size(
            $font-size:   $radio-label-text-size,
            $line-height: $radio-label-line-height
        );
        position: relative; /* [9] */
        display: block; /* [5] */
        min-height: $radio-button-size; /* [6] */
        padding-top: max(ceil(($radio-button-size - $radio-label-line-height) * 0.5), 0); /* [7] */
        padding-left: ($radio-button-size + $radio-button-spacing); /* [8] */
        font-weight: $radio-label-text-font-weight;
        color: $radio-label-text-color;

        &:hover {
            color: $radio-label-text-color-hover;
        }

        .c-radio__input:checked + & {
            color: $radio-label-text-color-checked;
        }

        .c-radio__input:hover + &::before {
            border-color: if($radio-button-border-color-hover, $radio-button-border-color-hover, null);
        }

        .c-radio__input:focus + &::before {
            border-color: if($radio-button-border-color-focus, $radio-button-border-color-focus, null);
        }

        .c-radio__input[disabled] + & {
            color: $radio-label-text-color-disabled;
            cursor: default;

            @include vary($loop: $radio-button-themes) {
                color: vary-get("label-text-color-disabled");
            }
        }

        .c-radio__input.is-error + & {
            color: $radio-label-text-color-error;
        }

        &::before,
        &::after {
            @include transition();
            content: "";
            position: absolute;
            display: block;
            border-radius: 50%;
        }

        /* stylelint-disable selector-max-class, selector-max-specificity */
        // The circle radio-button box.
        &::before {
            top: 0;
            left: 0;
            width: $radio-button-size;
            height: $radio-button-size;
            border: $radio-button-border;
            background-color: $radio-button-background-color;

            @include vary($loop: $radio-button-themes) {
                background-color: vary-get("background-color");
            }

            .c-radio__input:checked + &,
            .c-radio__input:checked + .c-radio__label-container & { /* [12] */
                border-color: $radio-button-border-color-checked;
                background-color: $radio-button-background-color-checked;

                @if ($radio-button-has-shadow) {
                    box-shadow: $radio-button-shadow-checked;
                }

                @include vary($loop: $radio-button-themes) {
                    background-color: vary-get("background-color-checked");
                }
            }

            .c-radio__input:checked:hover:not(.is-error, [disabled]) + & {
                border-color: if($radio-button-border-color-checked-hover, $radio-button-border-color-checked-hover, null);
            }

            // Disabled styles.
            .c-radio__input[disabled] + &,
            .c-radio__input[disabled] + .c-radio__label-container & { /* [12] */
                border-color: $radio-button-border-color-disabled;
                background-color: $radio-button-background-color-disabled;

                @if ($radio-button-has-shadow) {
                    box-shadow: none;
                }

                @include vary($loop: $radio-button-themes) {
                    border-color: vary-get("border-color-disabled");
                    background-color: vary-get("background-color-disabled");
                }
            }

            // Error styles.
            .c-radio__input.is-error + &,
            .c-radio__input.is-error + .c-radio__label-container & { /* [12] */
                border-color: $radio-button-border-color-error;
                background-color: $radio-button-background-color-error;

                @if ($radio-button-has-shadow) {
                    box-shadow: none;
                }
            }

        }

        // The dot inside the radio-button.
        &::after {
            top: ceil(($radio-button-size - $radio-button-dot-size) * 0.5); /* [7] */
            left: ceil(($radio-button-size - $radio-button-dot-size) * 0.5); /* [10] */
            width: $radio-button-dot-size;
            height: $radio-button-dot-size;
            transform: scale(0); /* [11] */
            background-color: $radio-button-dot-background-color;

            :hover + & {
                background-color: $radio-button-dot-background-color-hover;
            }

            .c-radio__input:checked + &,
            .c-radio__input:checked + .c-radio__label-container & { /* [12] */
                transform: scale(1); /* [11] */
            }

            // Disabled styles.
            .c-radio__input[disabled] + &,
            .c-radio__input[disabled] + .c-radio__label-container & { /* [12] */
                background-color: $radio-button-dot-background-disabled;
            }

            // Error styles.
            .c-radio__input.is-error + &,
            .c-radio__input.is-error + .c-radio__label-container & { /* [12] */
                background-color: $radio-button-dot-background-error;
            }

        }
        /* stylelint-enable */

    }
