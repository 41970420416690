/* ------------------------------------*\
    #LISTS
\*------------------------------------ */

/**
 * [1] Align the bullets/numbers of lists horizontally with sourrounding content.
 * [2] Remove extra vertical spacing when nesting lists.
 */
ul, ol {
    margin-left: $GLOBAL-SPACING-UNIT; /* [1] */
}

li {

    > ul,
    > ol {
        margin-bottom: 0; /* [2] */
    }

}
