/* ------------------------------------*\
    #TABLES (Scopes)
\*------------------------------------ */

/**
 * [1] Responsive variant: Prevents the width of `.c-badge` from blowing up
 *     when a combination of CSS grid and CSS flexbox comes into play.
 * [2] Vertically align with text
 */
.c-table-wrapper {

    th,
    td {

        .c-badge__icon {

            &::before {
                color: inherit;
            }

        }

        .c-status-message {
            align-items: baseline; /* [2] */
        }

    }

}

.c-table--responsive {

    @include media-query("<=#{$table-responsive-breakpoint}") {

        [data-columnheader] {

            @supports (display: grid) {

                .c-badge {
                    flex-grow: 0; /* [1] */
                }

            }

        }

    }

}
