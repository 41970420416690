// /* ------------------------------------*\
//     #BROWSER-HACKS
// \*------------------------------------ */

/* stylelint-disable scss/media-feature-value-dollar-variable */

// Target specific browsers with CSS only.
// https://browserhacks.com/

// Safari 9+
// https://browserstrangeness.github.io/css_hacks.html#safari
@mixin safari-only() {
    @supports (-webkit-hyphens:none) {
        @content;
    }
}

@mixin webkit-only() {
    /* stylelint-disable-next-line media-feature-colon-space-after */
    @media screen and (-webkit-min-device-pixel-ratio:0) {
        @content;
    }
}

@mixin ff-only() {
    /* stylelint-disable-next-line function-url-quotes */
    @-moz-document url-prefix() {
        @content;
    }
}

// IE CSS HOOK
// Target only IE10+
// @DEPRECATED LEGACY BROWSER
@mixin ie-only() {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

// Edge Browser 12+ for Windows 10 (all MS Edge)
// https://browserstrangeness.bitbucket.org/css_hacks.html#edge
// @DEPRECATED LEGACY BROWSER
@mixin edge-only() {
    @supports (-ms-ime-align:auto) {
        @content;
    }
}

/* stylelint-enable scss/media-feature-value-dollar-variable */
