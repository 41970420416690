/* ------------------------------------*\
    #CALLOUT
\*------------------------------------ */

/**
 * [1] Override brand theme background with custom callout color.
 */
.c-callout {

    &[data-theme="brand"] {
        /* stylelint-disable-next-line itcss/no-at-important */
        background-color: $callout-background-color !important; /* [1] */
    }

}
