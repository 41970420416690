/* ------------------------------------*\
    #INTERACTION-LAYER (Scopes)
\*------------------------------------ */

$button-bar-breakpoint: m !default;

/**
 * [1] Center button text in smaller viewports.
 * [2] Change `flex-direction` on reversed button-container on smaller viewports
 *     to display call-to-action on top.
 */
 .c-interaction-layer__button-bar {

     @include media-query("<=#{$button-bar-breakpoint}") {

         .c-btn--link {
             justify-content: center; /* [1] */
         }

         .o-button-container {
             flex-direction: column-reverse; /* [2] */
         }

     }

}
