// /* ------------------------------------*\
//     #FONTS
// \*------------------------------------ */

// @sass-export-section="fontSettings"

// The font-weights are defined as numerical values instead of named values
// in order to avoid browser mapping issues
// (see https://www.webtype.com/info/articles/fonts-weights/).
//
// As the values can differ to each font these values should be checked
// and modified per brand.

$GLOBAL-FONT-WEIGHT-REGULAR: 400 !default;
$GLOBAL-FONT-WEIGHT-BOLD:    700 !default;


$BASE-FONT:                  "open-sans" !default;
$BASE-FONT-FALLBACKS:        sans-serif !default;
// `$BASE-FONT-VARIANTS` will be used if `$BASE-FONT-VARIABLE-VARIATION-AXIS`
// is not set.
$BASE-FONT-VARIANTS: (
    "bold": $GLOBAL-FONT-WEIGHT-BOLD,
    "regular": $GLOBAL-FONT-WEIGHT-REGULAR
) !default;
$BASE-FONT-FAMILY:           ($BASE-FONT, $BASE-FONT-FALLBACKS) !default;

// Use `$BASE-FONT-VARIABLE-VARIATION-AXIS` to set variable font variation features.
// If set, `$BASE-FONT-VARIANTS` will be ignored.

$ALT-FONT:                   null !default;
$ALT-FONT-FALLBACKS:         null !default;
$ALT-FONT-VARIANTS:          () !default;
$ALT-FONT-FAMILY:            null !default;

$FONT-REVISION:              null !default;

// @end-sass-export-section
