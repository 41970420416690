/* ------------------------------------*\
    #BREADCRUMB
\*------------------------------------ */

$breadcrumb-background-color:             null !default;
$breadcrumb-padding-horizontal:           0 !default;
$breadcrumb-padding-vertical:             0 !default;
$breadcrumb-border:                       null !default;
$breadcrumb-border-radius:                null !default;
$breadcrumb-font-weight:                  null !default;
$breadcrumb-line-height:                  2 !default;

$breadcrumb-item-spacing:                 $GLOBAL-SPACING-UNIT-SMALL !default;

$breadcrumb-link-text-color:              $COLOR-BASE !default;
$breadcrumb-link-text-decoration:         none !default;
$breadcrumb-link-text-transform:          null !default;
$breadcrumb-link-hover-text-color:        null !default;
$breadcrumb-link-current-text-color:      null !default;
$breadcrumb-link-current-text-decoration: null !default;
$breadcrumb-link-font-size:               $FS-1 !default;

// Define the icon for the breadcrumb. Alternatively, you can set this to
// `false`. In that case, the text of `$breadcrumb-item-delimiter` is used as
// delimiter. In this case, use `$breadcrumb-item-delimiter-text-size` instead
// of `$breadcrumb-item-delimiter-icon-size` to set the size.
$breadcrumb-item-delimiter-icon:          get-icon(semantic-forward) !default;

// This is the delimiter text which is just used if
// `$breadcrumb-item-delimiter-icon` is set to `false`.
$breadcrumb-item-delimiter:               ">" !default;
$breadcrumb-item-delimiter-icon-size:     icon-size(small) !default;
$breadcrumb-item-delimiter-icon-color:    $breadcrumb-link-text-color !default;
$breadcrumb-item-delimiter-font-size:     null !default;

$breadcrumb-themes: (
    "alternative": (
        "background-color": null,
        "border":           null
    )
) !default;

/**
 * [1] Reset default list styles.
 * [2] Easier management of alignment.
 * [3] Let the breadcrumb items wrap into multiline when they don't fit on
 *     screen anymore.
 * [4] Align the items center on the y-axis to each other.
 * [5] Vertically center the item text with the icon.
 * [6] Set spacing between delimiter and following items text.
 * [7] Set spacing between text and delimiter of the same breadcrumb item.
 * [8] If the delimiter is a custom text.
 * [9] If the delimiter is an icon of the icon set.
 */
.c-breadcrumb {

    @include responsive-property(
        ("padding-top",    $breadcrumb-padding-vertical),
        ("padding-left",   $breadcrumb-padding-horizontal),
        ("padding-right",  $breadcrumb-padding-horizontal),
        ("padding-bottom", $breadcrumb-padding-vertical)
    );
    display: flex; /* [2] */
    flex-wrap: wrap; /* [3] */
    align-items: center; /* [4] */
    margin: 0; /* [1] */
    border: $breadcrumb-border;
    border-radius: $breadcrumb-border-radius;
    font-weight: $breadcrumb-font-weight;
    line-height: $breadcrumb-line-height;
    list-style: none; /* [1] */
    background-color: $breadcrumb-background-color;

    @include vary($loop: $breadcrumb-themes) {
        border: vary-get("border");
        background-color: vary-get("background-color");
    }
}

    .c-breadcrumb__item {
        display: flex; /* [5] */
        align-items: center; /* [5] */
        margin-bottom: 0; /* [1] */

        &:not(:last-child) {
            margin-right: $breadcrumb-item-spacing; /* [6] */

            &::after {
                margin-left: $breadcrumb-item-spacing; /* [7] */
                color: $breadcrumb-item-delimiter-icon-color;

                @if ($breadcrumb-item-delimiter-icon == false) { /* [8] */
                    @include responsive-font-size(
                        $font-size: $breadcrumb-item-delimiter-font-size,
                        $line-height: 1,
                        $accept-null: true
                    );
                    content: $breadcrumb-item-delimiter;
                } @else { /* [9] */
                    @include font-size(
                        $font-size:   $breadcrumb-item-delimiter-icon-size,
                        $line-height: 1
                    );
                    @include icon-font($line-height: null);
                    content: $breadcrumb-item-delimiter-icon;
                }

            }

        }

    }

    .c-breadcrumb__link {
        @include responsive-font-size($breadcrumb-link-font-size);
        text-decoration: $breadcrumb-link-text-decoration;
        text-transform: $breadcrumb-link-text-transform;
        color: $breadcrumb-link-text-color;

        &:hover {
            color: $breadcrumb-link-hover-text-color;
        }

        &.is-current {
            text-decoration: $breadcrumb-link-current-text-decoration;
            color: $breadcrumb-link-current-text-color;
        }

        &.is-disabled {
            pointer-events: none;
        }

    }
