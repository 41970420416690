// /* ------------------------------------*\
//     #HINT
// \*------------------------------------ */

.c-hint {

    &:focus-within {
        @include focus-styles();
    }

}
