/* ------------------------------------*\
    #BORDER
\*------------------------------------ */

/**
 * Utility class to make an element have borders like `border: 1px solid red;`.
 */

// @sass-export-section="borderDirections"
$border-directions: (
        null: null,
        "-t": "-top",
        "-r": "-right",
        "-b": "-bottom",
        "-l": "-left",
        "-v": "-left" "-right",
        "-h": "-top" "-bottom",
) !default;
// @end-sass-export-section

@each $direction-namespace, $direction-rules in $border-directions {

    .u-border#{$direction-namespace} {
        @each $direction in $direction-rules {
            border#{$direction}: important($GLOBAL-BORDER-WIDTH $GLOBAL-BORDER-STYLE $GLOBAL-BORDER-COLOR, true);
        }

    }

}
