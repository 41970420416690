/* ------------------------------------*\
    #HERO-TEASER
\*------------------------------------ */

// The point at which the content (the text) is not displayed on top of the
// image anymore.
$hero-teaser-max-width:                                          null !default;
$hero-teaser-content-break:                                      xs !default;
$hero-teaser-page-head-spacing:                                  $GLOBAL-LOGO-HEIGHT + $GLOBAL-SPACING-UNIT-LARGE !default;
$hero-teaser-background:                                         null !default;
$hero-teaser-inverted:                                           true !default;
$hero-teaser-inverted-button:                                    true !default;

$hero-teaser-height: (
    null: 700px,
    s:    540px,
    xs:   320px
) !default;

$hero-teaser-shallow-height: (
    null: 540px,
    s:    480px,
    xs:   280px
) !default;

$hero-teaser-auto-height: 100% !default;

$hero-teaser-layer-widths: ( /* [24] */
small:  460px,
medium: 720px,
large:  960px,
xlarge: 1280px,
) !default;

// define where the content should be anchored in regards to the image. Possible
// values are "top", "bottom" and "middle".
$hero-teaser-content-alignment:                                  "top" !default;
$hero-teaser-content-background:                                 null !default;
$hero-teaser-content-breakpoint-offset:                          $GLOBAL-SPACING-UNIT !default;

// Boolean to optionally deactivate usage of page wrap alignment.
$hero-teaser-content-use-page-wrap:                              true !default;

$hero-teaser-content-padding-top: (
    null:                       $hero-teaser-page-head-spacing,
    $hero-teaser-content-break: 0,
) !default;

$hero-teaser-content-padding-bottom: (
    null:                       $GLOBAL-SPACING-UNIT-LARGE,
    $hero-teaser-content-break: $GLOBAL-SPACING-UNIT-SMALL,
) !default;

// set the defaults for `$hero-teaser-content` here
$hero-teaser-content-default-padding-vertical:                    null !default;
$hero-teaser-content-default-padding-horizontal:                  null !default;
$hero-teaser-content-default-background:                          null !default;
$hero-teaser-content-default-text-color:                          null !default;
$hero-teaser-content-default-text-color-inverted:                 null !default;
$hero-teaser-content-default-margin-bottom:                       null !default;
$hero-teaser-content-default-max-width:                           null !default;

// set the default state for `.c-hero-teaser--inverted`
$hero-teaser-content-default-color-inverted:                      $COLOR-WHITE !default;
$hero-teaser-button-background-color-inverted:                    map-deep-get($button-variants, primary, background) !default;
$hero-teaser-button-color-inverted:                               map-deep-get($button-variants, primary, text-color) !default;
$hero-teaser-button-border-color-inverted:                        map-deep-get($button-variants, primary, border-color) !default;

// set the hover state for `.c-hero-teaser--inverted`
$hero-teaser-button-background-color-hover-inverted:              map-deep-get($button-variants, primary, background-hover) !default;
$hero-teaser-button-color-hover-inverted:                         map-deep-get($button-variants, primary, text-color) !default;
$hero-teaser-button-border-color-hover-inverted:                  map-deep-get($button-variants, primary, border-color) !default;

// set the focus state for `.c-hero-teaser--inverted`
$hero-teaser-button-background-color-focus-inverted:              $hero-teaser-button-background-color-hover-inverted !default;
$hero-teaser-button-color-focus-inverted:                         $hero-teaser-button-color-hover-inverted !default;
$hero-teaser-button-border-color-focus-inverted:                  $hero-teaser-button-border-color-hover-inverted !default;

// set the active state for `.c-hero-teaser--inverted`
$hero-teaser-button-background-color-active-inverted:             transparent !default;
$hero-teaser-button-color-active-inverted:                        map-deep-get($button-variants, primary, link-text-color) !default;
$hero-teaser-button-border-color-active-inverted:                 map-deep-get($button-variants, primary, border-color-active) !default;

$hero-teaser-button-link-default-background-color-hover-inverted: transparent !default;
$hero-teaser-button-link-default-border-color-hover-inverted:     transparent !default;
$hero-teaser-button-link-default-border-bottom-hover-inverted:    1px solid $COLOR-BRAND !default;

$hero-teaser-background-video-icon:                               get-icon(semantic-pause) !default;
$hero-teaser-background-video-icon-paused:                        get-icon(semantic-play) !default;
$hero-teaser-background-video-control-right:                      $GLOBAL-SPACING-UNIT !default;
$hero-teaser-background-video-control-bottom:                     $GLOBAL-SPACING-UNIT !default;

$hero-teaser-layer-content-color:                                 $COLOR-BLACK !default;
$hero-teaser-layer-position:                                      true !default;
$hero-teaser-layer-position-content-break:                        relative !default;
$hero-teaser-layer-spacing-top:                                   $GLOBAL-SPACING-UNIT !default;
$hero-teaser-layer-alignment:                                     center !default;
$hero-teaser-layer-background:                                    rgba($COLOR-WHITE, $OPACITY-HIGHEST) !default;
$hero-teaser-layer-border:                                        null !default;
$hero-teaser-layer-breakpoint:                                    "m" !default;
$hero-teaser-layer-shadow:                                        null !default;
$hero-teaser-layer-spacing:                                       $GLOBAL-SPACING-UNIT !default;
$hero-teaser-layer-width:                                         100% !default;

// Instead of overwriting `$hero-teaser-content` to customize the different
// content elements, you can overwrite only certain values by defining the
// variable `$hero-teaser-content-overwrites` in the brand's settings file.
$hero-teaser-content-overwrites: () !default;
$hero-teaser-content: (
    layer: (
        padding-left:   $hero-teaser-layer-spacing,
        padding-right:  $hero-teaser-layer-spacing,
        margin-top:     null,
    ),
    title: (
        max-width:      $hero-teaser-content-default-max-width,
        margin-bottom:  $hero-teaser-content-default-margin-bottom,
    ),
    title-row: (
        font-size:      $FS6,
        line-height:    null,
        font-weight:    null,
        padding-top:    $hero-teaser-content-default-padding-vertical,
        padding-left:   $hero-teaser-content-default-padding-horizontal,
        padding-bottom: $hero-teaser-content-default-padding-vertical,
        padding-right:  $hero-teaser-content-default-padding-horizontal,
        background:     $hero-teaser-content-default-background,
        color:          $hero-teaser-content-default-text-color,
        margin-bottom:  null,
    ),
    pretitle: (
        font-size:      $FS3,
        line-height:    null,
        font-weight:    null,
        text-transform: null,
        max-width:      $hero-teaser-content-default-max-width,
        padding-top:    $hero-teaser-content-default-padding-vertical,
        padding-left:   $hero-teaser-content-default-padding-horizontal,
        padding-bottom: $hero-teaser-content-default-padding-vertical,
        padding-right:  $hero-teaser-content-default-padding-horizontal,
        background:     $hero-teaser-content-default-background,
        color:          $hero-teaser-content-default-text-color,
        margin-bottom:  $hero-teaser-content-default-margin-bottom,
    ),
    subtitle: (
        font-size:      $FS3,
        line-height:    null,
        font-weight:    null,
        max-width:      $hero-teaser-content-default-max-width,
        padding-top:    $hero-teaser-content-default-padding-vertical,
        padding-left:   $hero-teaser-content-default-padding-horizontal,
        padding-bottom: $hero-teaser-content-default-padding-vertical,
        padding-right:  $hero-teaser-content-default-padding-horizontal,
        background:     $hero-teaser-content-default-background,
        color:          $hero-teaser-content-default-text-color,
        margin-bottom:  $hero-teaser-content-default-margin-bottom,
    ),
    text: (
        font-size:      $FS0,
        line-height:    null,
        font-weight:    null,
        max-width:      $hero-teaser-content-default-max-width,
        padding-top:    $hero-teaser-content-default-padding-vertical,
        padding-left:   $hero-teaser-content-default-padding-horizontal,
        padding-bottom: $hero-teaser-content-default-padding-vertical,
        padding-right:  $hero-teaser-content-default-padding-horizontal,
        background:     $hero-teaser-content-default-background,
        color:          $hero-teaser-content-default-text-color,
        margin-bottom:  $hero-teaser-content-default-margin-bottom,
    ),
    button: (
        margin-bottom:  null,
        margin-top:     $GLOBAL-SPACING-UNIT,
    ),
) !default;

// Deep-merge content configuration with overwrites up to a depth of 2
$hero-teaser-content-merged: map-deep-merge($hero-teaser-content, $hero-teaser-content-overwrites, 2) !default;

$hero-teaser-content-inverted: (
    title-row: (
        color: $hero-teaser-content-default-text-color-inverted
    ),
    pretitle: (
        color: $hero-teaser-content-default-text-color-inverted
    ),
    subtitle: (
        color: $hero-teaser-content-default-text-color-inverted
    ),
    text: (
        color: $hero-teaser-content-default-text-color-inverted
    ),
) !default;

// Provide focus points for the hero-teaser image to chose from. Make sure to
// adhere to `[y-axis]-[x-axis]` notation.
// @TODO: `center-center` missing?
$hero-teaser-focus-points: (
    "top-center",
    "top-left",
    "top-right",
    "center-left",
    "center-right",
    "bottom-center",
    "bottom-left",
    "bottom-right",
) !default;

/**
 * [1]  Reference for the `.c-hero-teaser__image`.
 * [2]  Prepare for worst case: If the text content does not fit the hero-teaser
 *      container, at least cut off its overflow.
 * [3]  Provide anchor point modifiers for the hero-teaser image.
 * [4]  By default, when the image is cropped inside the hero-teaser
 *      container, crop it from the dead center.
 * [5]  Always fill the entire hero-teaser container with the image.
 * [6]  Override standard behaviour, so the image can bleed out over the
 *      viewport edges.
 * [7]  The hero image should not reserve any height, if `.c-hero-teaser--auto`,
        since the content controls the height in this case.
 * [8]  Set position to `relative` so it reserves the necessary height.
 * [9]  Provide the correct spacing between the page-head (i.e. logo) and the
 *      hero-teaser content.
 * [10] Position content on top of image.
 * [11] Reset default spacing.
 * [12] Since the content defines the height of the hero image for
 *      `.c-hero-teaser--auto` and `c-hero-teaser--layer`, centering the content
 *      does not make sense.
 * [13] Provide fallback for existing br tags between title_row spans.
 * [14] If activated (by default), align content horizontally with global
 *      page wrap.
 * [15] Fixes a vertical offset when `$hero-teaser-content-default-margin-bottom` is set.
 *      The `margin-bottom` of the last child of `.c-hero-teaser__heading` creates
 *      an extra space and thus is reset here.
 * [16] Set optional max width and center hero teaser horizontally.
 * [17] Set inverted variant button styles.
 * [18] Stretch the media element to the whole viewport width.
 * [19] Place the background-video controls on top of the hero teaser
 *      media and position it accordingly.
 * [20] Disable pointer events for the content element so that the video controls
 *      can receive events but enable it for content heading and content button children.
 * [21] Make sure the image does not stretch in relation to the hero teaser layer when
 *      expanding in variant `c-hero-teaser--layer`.
 * [22] Position the content in the variant `c-hero-teaser--layer` below the hero
 *      image on small screens.
 * [23] The variant `c-hero-teaser--layer` needs relative positioning in order to
 *      push the content downwards when expanding.
 * [24] The layer should always `$hero-teaser-layer-width`,  unless a different
 *      width is specified via `[data-hero-teaser-layer-size]` for larger viewports.
 * [25] Make sure that the layer never exceeds the width of the hero teaser.
 * [26] Add spacing between header and layer content.
 * [27] Ensure that the image height does not exceed the outer wrapper.
 * [28] Ensure that the layer content is set within the page-wrap.
 * [29] Add variable to set flex-alignment.
 * [30] Add space between layer and previous element (e.g. `.c-hero-teaser__heading`).
 * [31] Remove bottom margin from last layer child element (e.g. `<p>`).
 * [32] Invoke colors when layer and inverted variant are combined.
 */
.c-hero-teaser {
    position: relative; /* [1] */
    overflow: hidden; /* [2] */
    background: $hero-teaser-background;

    @if ($hero-teaser-max-width) {
        max-width: $hero-teaser-max-width; /* [16] */
        margin-right: auto; /* [16] */
        margin-left: auto; /* [16] */
    }

    &.c-hero-teaser--layer {
        @include responsive-property("min-height", $hero-teaser-height); /* [27] */
    }
}

    .c-hero-teaser__background-video {
        position: relative;
        z-index: z("hero-teaser-image");
        width: 100%; /* [18] */

        video {
            @include object-fit();
            @include responsive-property("height", $hero-teaser-height);
            width: 100%; /* [18] */
        }

    }

    .c-hero-teaser__background-video-control {
        position: absolute; /* [19] */

        @include media-query("<=#{$hero-teaser-content-break}") {
            bottom: 50%; /* [19] */
            right: 50%; /* [19] */
            transform: translate3d(50%, 50%, 0);

            &:not(.is-paused) {
                visibility: hidden;
            }

        }

        @include media-query(">#{$hero-teaser-content-break}") {
            bottom: $hero-teaser-background-video-control-bottom; /* [19] */
            right: $hero-teaser-background-video-control-right; /* [19] */
        }

        &:not(.is-paused) {
            --play-button-icon: #{$hero-teaser-background-video-icon};
        }

        &.is-paused {
            --play-button-icon: #{$hero-teaser-background-video-icon-paused};
        }

    }

    .c-hero-teaser__image {
        @include object-fit(); /* [3] [4] */
        @include responsive-property("height", $hero-teaser-height);
        position: relative;
        z-index: z("hero-teaser-image");
        width: 100%;
        min-width: 100%; /* [5] */
        max-width: none; /* [6] */

        .c-hero-teaser--shallow & {
            @include responsive-property("height", $hero-teaser-shallow-height);
        }

        .c-hero-teaser--auto & {
            @include responsive-property("height", $hero-teaser-auto-height); /* [6] */

            @include media-query(">#{$hero-teaser-content-break}") {
                position: absolute; /* [7] */
            }
        }

        .c-hero-teaser--layer & {
            position: absolute; /* [21] */

            @include media-query("<#{$hero-teaser-content-break}") {
                position: $hero-teaser-layer-position-content-break; /* [22] */
            }

        }

        @each $_focus-point in $hero-teaser-focus-points { /* [3] */
            $_position: str-replace($_focus-point, "-", " ");

            .c-hero-teaser--#{$_focus-point} & {
                @include object-fit($object-position: #{$_position});
            }

        }

    }

    // @TODO: BRON-8559
    // This is (currently) bluelabel specific. Extract to brand specific styles,
    // save output for packages that do not need it.
    .c-hero-teaser__play-button {

        &.c-hero-teaser__play-button--video {
            position: absolute;
            z-index: z("hero-teaser-play-button");
            left: 50%;
            transform: translate(-50%, -50%);

            // Safeguard to check if teaser-height is configured via map
            @if (type-of($hero-teaser-height) == map) {
                // Safeguard to check if content-break is set for teaser-height...
                @if (map-has-key($hero-teaser-height, $hero-teaser-content-break)) {
                    top: (map-get($hero-teaser-height, $hero-teaser-content-break) * 0.5);
                // ...otherwise use "default" / "fallback" value `null`
                } @else {
                    top: (map-get($hero-teaser-height, null) * 0.5);
                }
            } @else {
                top: ($hero-teaser-height * 0.5);
            }

            @include media-query(">#{$hero-teaser-content-break}") {
                display: none;
            }
        }

        &.c-hero-teaser__play-button--modal {
            @include media-query("<=#{$hero-teaser-content-break}") {
                display: none;
            }
        }

    }

    .c-hero-teaser__content {
        @include responsive-property(
            ("padding-top",    $hero-teaser-content-padding-top), /* [9] */
            ("padding-bottom", $hero-teaser-content-padding-bottom),
            ("background",     $hero-teaser-content-background)
        );
        position: absolute; /* [10] */
        z-index: z("hero-teaser-content");

        @include media-query("<=#{$hero-teaser-content-break}") {
            position: relative; /* [8] */
            margin-top: $hero-teaser-content-breakpoint-offset;
        }

        .c-hero-teaser:not(.c-hero-teaser--auto):not(.c-hero-teaser--layer) & { /* [12] */
            @include media-query(">#{$hero-teaser-content-break}") {
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                @if ($hero-teaser-content-alignment == "top") {
                    // nothing to see here
                } @else if ($hero-teaser-content-alignment == "bottom") {

                    > * {
                        position: relative;
                        top: 100%;
                        transform: translateY(-100%);
                    }

                } @else if ($hero-teaser-content-alignment == "middle") {

                    > * {
                        position: relative;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                }
            }
        }

        .c-hero-teaser--auto & {
            position: relative;
        }

        @if ($hero-teaser-layer-position) {
            .c-hero-teaser--layer & {
                @include responsive-property("min-height", $hero-teaser-height); /* [27] */
                position: relative; /* [23] */
                display: flex;
                flex-direction: column;
                justify-content: $hero-teaser-layer-alignment; /* [29] */
            }
        }

        .c-hero-teaser--video & {
            pointer-events: none; /* [20] */

            /* stylelint-disable selector-max-combinators, selector-max-compound-selectors */
            .c-hero-teaser__heading *,
            .c-hero-teaser__button * {
                pointer-events: all; /* [20] */
            }

        }
    }

    .c-hero-teaser__content-inner {

        @if $hero-teaser-content-use-page-wrap {
            @include page-wrap(); /* [14] */
        }

        .c-hero-teaser--layer & {
            width: 100%; /* [28] */
        }
    }

    .c-hero-teaser__heading {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h1, h2, h3, h4, h5, h6, p { /* [11] */
            margin-bottom: 0;
        }

        @if ($hero-teaser-content-default-margin-bottom) { /* [15] */
            > :last-child {
                margin-bottom: 0;
            }
        }

    }

    .c-hero-teaser__title > * {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .c-hero-teaser__title-row + br {
        display: none; /* [13] */
    }

    .c-hero-teaser__layer {
        width: var(--hero-teaser-layer-width, #{$hero-teaser-layer-width}); /* [24] */
        max-width: 100%; /* [25] */
        padding: $hero-teaser-layer-spacing;
        box-shadow: $hero-teaser-layer-shadow;
        border: $hero-teaser-layer-border;
        background-color: $hero-teaser-layer-background;

        * + & {
            margin-top: $hero-teaser-layer-spacing-top; /* [30] */
        }

        > :last-child {
            margin-bottom: 0; /* [31] */
        }

        @each $width, $value in $hero-teaser-layer-widths {

            &[data-hero-teaser-layer-size="#{$width}"] {
                @include media-query(">=#{$hero-teaser-layer-breakpoint}") {
                    --hero-teaser-layer-width: #{$value}; /* [24] */
                }
            }
        }
    }

    @include responsive-property-map($hero-teaser-content-merged, ".c-hero-teaser__");

    @if ($hero-teaser-inverted) {
        .c-hero-teaser--inverted {
            @include media-query(">#{$hero-teaser-content-break}") {

                .c-hero-teaser__content {
                    @include responsive-property-map($hero-teaser-content-inverted, ".c-hero-teaser__");
                    color: $hero-teaser-content-default-color-inverted;
                }

                .c-hero-teaser__layer { /* [32] */
                    @include responsive-property-map($hero-teaser-content-merged, ".c-hero-teaser__");
                    color: $hero-teaser-layer-content-color;
                }

                    @if ($hero-teaser-inverted-button) { /* [17] */
                        .c-btn:not(.c-hero-teaser__layer .c-btn) {
                            border-color: $hero-teaser-button-border-color-inverted;
                            background-color: $hero-teaser-button-background-color-inverted;
                            color: $hero-teaser-button-color-inverted;

                            /* stylelint-disable-next-line selector-max-specificity */
                            &:hover {
                                border-color: $hero-teaser-button-border-color-hover-inverted;
                                background-color: $hero-teaser-button-background-color-hover-inverted;
                                color: $hero-teaser-button-color-hover-inverted;
                            }

                            /* stylelint-disable-next-line selector-max-specificity */
                            &:focus {
                                border-color: $hero-teaser-button-border-color-focus-inverted;
                                background-color: $hero-teaser-button-background-color-focus-inverted;
                                color: $hero-teaser-button-color-focus-inverted;
                            }

                            /* stylelint-disable-next-line selector-max-specificity */
                            &:active {
                                border-color: $hero-teaser-button-border-color-active-inverted;
                                background-color: $hero-teaser-button-background-color-active-inverted;
                                color: $hero-teaser-button-color-active-inverted;
                            }

                        }

                        .c-btn--link {

                            &:hover {
                                border-color: $hero-teaser-button-link-default-border-color-hover-inverted;
                                border-bottom: $hero-teaser-button-link-default-border-bottom-hover-inverted;
                                background-color: $hero-teaser-button-link-default-background-color-hover-inverted;
                            }

                        }
                }

            }
        }
    }

    .c-hero-teaser__guiding-line {
        position: absolute;
        z-index: z("hero-teaser-content") + 1;
        right: 0;
        bottom: 0;
        left: 0;
    }
