/* ------------------------------------*\
    #EXPANDABLE-CONTENT
\*------------------------------------ */

$expandable-content-padding-vertical:             $GLOBAL-SPACING-UNIT-LARGE !default;
$expandable-content-padding-top:                  $expandable-content-padding-vertical !default;
$expandable-content-padding-bottom:               $expandable-content-padding-vertical !default;
$expandable-content-content-padding-vertical:     $GLOBAL-SPACING-UNIT !default;
$expandable-content-background-color:             map-get($BACKGROUND-UTILITIES, "alternative") !default;

$expandable-content-state-expanded:               "[aria-hidden='false']" !default;

$expandable-content-icon-size:                    "xsmall" !default;
$expandable-content-icon-color:                   $COLOR-BRAND !default;
$expandable-content-icon-color-hover:             $expandable-content-icon-color !default;
$expandable-content-icon-complexity:              null !default;
$expandable-content-icon:                         get-icon(semantic-expand, $expandable-content-icon-complexity) !default;
// This icon will be used if `$expandable-content-has-close-trigger` is `true`
$expandable-content-icon-close:                   get-icon(semantic-collapse, $expandable-content-icon-complexity) !default;
$expandable-content-icon-background:              transparent !default;
$expandable-content-icon-background-hover:        $expandable-content-icon-background !default;
$expandable-content-icon-rotation:                null !default;
$expandable-content-icon-border-color:            transparent !default;
$expandable-content-icon-border-color-hover:      $expandable-content-icon-border-color !default;
$expandable-content-icon-border-width:            null !default;
// If a breakpoint is defined here, the icon and text of the trigger will be
// inline *above* this breakpoint.
$expandable-content-icon-inline-breakpoint:       false !default;

// Set the font-size of the trigger text. This has to be a fixed font-size and
// not a responsive font-size since we are using this variable for calculation
// stuff.
$expandable-content-trigger-text-size:            $GLOBAL-FONT-SIZE !default;
$expandable-content-trigger-text-color:           $COLOR-BRAND !default;
$expandable-content-trigger-text-color-hover:     null !default;
$expandable-content-trigger-text-transform:       none !default;
$expandable-content-trigger-font-weight:          $GLOBAL-FONT-WEIGHT-BOLD !default;
$expandable-content-trigger-text-icon-gap:        $GLOBAL-SPACING-UNIT-XSMALL !default;

$expandable-content-has-close-trigger:            true !default;
$expandable-content-trigger-close-spacing-bottom: $expandable-content-padding-vertical !default;

// Define the open/close animation duration.
$expandable-content-transition-duration:          $GLOBAL-TRANSITION-DURATION-LONG !default;

// Custom timing function for transition.
$expandable-content-transition-timing-function:   cubic-bezier(0.455, 0.030, 0.515, 0.955) !default; /* stylelint-disable-line number-no-trailing-zeros */

// This offset is needed to let the expandable-content container ‘fly-in’ underneath
// the trigger icon. The value is some kind of ‘magic number’. The bigger the
// value, the more ‘extreme’ the animation.
$expandable-content-animation-offset:             95px !default;

$expandable-content-themes: (
    "alternative": (
        "background-color": $COLOR-BASE-BACKGROUND,
    ),
) !default;

/**
 * [1]  Use pseudo element for collapsible background so it can be animated
 *      (also see [15]).
 * [2]  Default background-color when the background section container is white.
 * [3]  Make sure to just reveal the differing background-color when the extra-
 *      content is visible. Unfortunately, `!important` is necessary so that
 *      the declaration also applies, if the expandable-content component also carries
 *      the `[data-theme]` attribute.
 * [4]  Initially hide the element and set `display: block` by JS on page-load to
 *      show it again. This ensures that in case JS isn't executed for whatever
 *      reason, the trigger is not shown (because a trigger triggers nothing,
 *      when it's lacking the necessary JS-function).
 * [5]  Use `grid-template-rows` to transition the height.
 *      No more choppy `max-height` transition. (see https://www.stefanjudis.com/snippets/how-to-animate-height-with-css-grid/)
 * [6]  Pulling the text up above the icon. The amount comprises of the text-
 *      size plus spacing between the text and the icon
 *      ($expandable-content-trigger-text-icon-gap).
 * [7]  Make sure the line-height equals the font-size so the math is correct.
 *      This will ever only work, if the trigger text is a one-liner!
 * [8]  In case the trigger text is exceedingly long, this ensures the text
 *      will never wrap...
 * [9]  ...and prevent horizontal scrollbar...
 * [10] ...and instead just show an ellipsis.
 * [11] Also hide the overflow on the y-axis, because IE-issues.
 * [12] Animate the icon when triggering the expandable-content.
 * [13] Make the icon box round.
 * [14] We have to use pseudo elements for paddings here. Otherwise it would not
 *      completely hide the content in collapsed state because it’s a grid item.
 *      See [5].
 * [15] The animation of the pseudo element needs to have a shorter duration
 *      than the expandable-content itself. Just dividing it by two makes sure it
 *      always is without creating an extra variable.
 * [16] The animation of the trigger text should be sequentially before the
 *      animation of the expandable-content itself. Dividing it by two with the
 *      default transition duration prevents us from creating a separate
 *      variable.
 * [17] The specificity needs to be raised to trump the selector making the
 *      element visible after the JavaScript was loaded.
 * [18] Disable (an re-establish) pointer-events if trigger button is invisible.
 * [19] We need to set the height of the trigger button in order to add a height
 *      animation to it. The height is a combination of font-size (which is a
 *      responsive map), height of the icon and the gap between icon and text.
 */

.c-expandable-content {
    position: relative;
    z-index: 2;
    top: 0;
    margin-top: $expandable-content-padding-top;
    margin-bottom: $expandable-content-padding-bottom;

    &::before { /* [1] */

        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        background-color: $expandable-content-background-color; /* [2] */

        @include vary($loop: $expandable-content-themes) {
            background-color: vary-get("background-color");
        }
    }

    &.js-is-ready {
        @include transition($transition-duration: $expandable-content-transition-duration); /* [15] */

        &::before {
            @include transition($transition-duration: ($expandable-content-transition-duration * 0.5))  /* [15] */;
        }
    }

    &.c-expandable-content--no-background::before {
        background-color: transparent;
    }

    &.is-closed-within {
        top: $expandable-content-animation-offset;

        &.js-is-ready {
            @include transition($transition-duration: $expandable-content-transition-duration, $custom-properties-only: true);
        }

        &::before {
            background-color: transparent !important; /* [3] */ /* stylelint-disable-line itcss/no-at-important */
        }

    }

}

    .c-expandable-content__trigger {
        @include reset-button();
        display: none; /* [4] */
        width: 100%;

        .c-expandable-content.js-is-ready & {
            display: block; /* [4] */

            @if ($expandable-content-icon-inline-breakpoint) {
                @include media-query(">#{$expandable-content-icon-inline-breakpoint}") {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

    }

    .c-expandable-content__trigger-open {

        .c-expandable-content.js-is-ready & {
            @include transition($transition-duration: $expandable-content-transition-duration);
        }

        .c-expandable-content.is-closed-within & {
            transform: translateY(-($expandable-content-animation-offset));
        }

        @if ($expandable-content-has-close-trigger) {
            height: 0;
            transform: translateY(0);
            opacity: 0;

            &,
            > * {
                pointer-events: none; /* [18] */
            }

            .c-expandable-content.is-closed-within & {
                @include responsive-property(
                    "height",
                    responsive-property-modify(
                        $expandable-content-trigger-text-size,
                        $add: map-get($ICON-ROUND-FULL-SIZES, $expandable-content-icon-size) + $expandable-content-trigger-text-icon-gap
                    ) /* [19] */
                );

                opacity: 1;

                &,
                > * {
                    pointer-events: auto; /* [18] */
                }

            }
        }

    }

    .c-expandable-content__trigger-text {
        @include font-size(
            $font-size:   $expandable-content-trigger-text-size,
            $line-height: 1
        ); /* [7] */
        display: block;
        width: 100%;
        overflow: hidden; /* [9] */ /* [11] */
        font-weight: $expandable-content-trigger-font-weight;
        text-overflow: ellipsis; /* [10] */
        text-transform: $expandable-content-trigger-text-transform;
        text-align: center;
        white-space: nowrap; /* [8] */
        color: $expandable-content-trigger-text-color;

        @if ($expandable-content-icon-inline-breakpoint) {
            @include media-query(">#{$expandable-content-icon-inline-breakpoint}") {
                width: auto;
            }
        }

        .c-expandable-content__trigger:hover & {
            color: $expandable-content-trigger-text-color-hover;
        }

    }

    .c-expandable-content__trigger-text-open {
        top: -($expandable-content-trigger-text-icon-gap + $expandable-content-trigger-text-size); /* [6] */
        opacity: 0;

        .c-expandable-content.js-is-ready & {
            @include transition($transition-duration: ($expandable-content-transition-duration * 0.5)); /* [16] */
        }

        .c-expandable-content.is-closed-within & {
            opacity: 1;
        }

    }

    .c-expandable-content__icon {
        @include icon-variant(
            $variant:          "round",
            $size:             $expandable-content-icon-size,
            $text-color:       $expandable-content-icon-color,
            $background-color: $expandable-content-icon-background,
            $border-color:     $expandable-content-icon-border-color
        );

        display: block;
        margin-top: $expandable-content-trigger-text-icon-gap;
        text-align: center;

        @if ($expandable-content-icon-inline-breakpoint) {
            @include media-query(">#{$expandable-content-icon-inline-breakpoint}") {
                margin-top: 0;
                margin-right: $expandable-content-trigger-text-icon-gap;
            }
        }

        &::before {
            @include icon-font();
            @include transition($transition-duration: ($expandable-content-transition-duration * 0.5));
            content: $expandable-content-icon;
            transform: $expandable-content-icon-rotation; /* [12] */
            border-width: $expandable-content-icon-border-width;

            @if ($expandable-content-icon-rotation) {
                .c-expandable-content.is-closed-within & {
                    transform: rotate(0); /* [12] */
                }
            }

        }

        .c-expandable-content__trigger:hover & {

            &::before {
                border-color: $expandable-content-icon-border-color-hover;
                background-color: $expandable-content-icon-background-hover;
                color: $expandable-content-icon-color-hover;
            }

        }

    }

    .c-expandable-content__collapse {

        .c-expandable-content & {
            @include transition(
                $transition-property: grid-template-rows,
                $transition-duration: $expandable-content-transition-duration,
                $transition-timing-function: $expandable-content-transition-timing-function,
            ); /* [5] */
            display: grid; /* [5] */
            grid-template-rows: 0fr 0fr; /* [5] */

            > * {
                overflow: hidden; /* [5] */
            }

            &#{$expandable-content-state-expanded} {
                grid-template-rows: 1fr min-content; /* [5] */
            }

        }

    }

    .c-expandable-content__content {
        &::before,
        &::after {
            content: "";
            display: block;
            height: $expandable-content-content-padding-vertical; /* [14] */
        }
    }

@if ($expandable-content-has-close-trigger) {
    .c-expandable-content__trigger-close.c-expandable-content__trigger-close {
        @include transition(
            $transition-property: "opacity, padding",
            $transition-duration: $expandable-content-transition-duration,
        );

        :not(#{$expandable-content-state-expanded}) > & {
            pointer-events: none; /* [18] */
            visibility: hidden;
            opacity: 0;
        }

        #{$expandable-content-state-expanded} > & {
            padding-bottom: $expandable-content-trigger-close-spacing-bottom;
            opacity: 1;
        }

        .c-expandable-content__icon::before {
            content: $expandable-content-icon-close;
        }
    }
} @else {
    .c-expandable-content .c-expandable-content__trigger.c-expandable-content__trigger-close { /* [17] */
        display: none;
    }
}
