/* stylelint-disable selector-max-type, selector-max-compound-selectors, selector-max-combinators, max-nesting-depth */

// Keep duplications to separate use-cases instead of combining selectors.
/* stylelint-disable no-duplicate-selectors */

// /* ------------------------------------*\
//     #TABLES
// \*------------------------------------ */

$skoda-table-border:        $table-border-width solid $table-border-color !default;
$skoda-table-border-radius: $GLOBAL-BORDER-RADIUS !default;

/**
 * [1] Ensure visibility, also when header is sticky.
 * [2] Draw line between header/footer and body. This can be done only for the
 *     header/footer as the cells need to be able to have
 *     colorized borders based on state.
 * [3] Vertical borders for header cells that are not full height.
 * [4] Draw "corners" / "edges" on each side.
 * [5] Border on left and right side.
 * [6] Ensure colorized cells have rounded corners that are at the end
 *     to inherit the outer border.
 * [7] Ensure all pseudo element styles are unset to set custom styles
 *     without manual (re)sets.
 * [8] Overwrite default, fix straight line and border-radius.
 * [9] Overwrite defaults (bronson-default).
 * [10] Specificity 💩 – Don’t ask!
 * [11] Sticky column shadow.
 * [12] Recreate bottom border for the sticky column cell in the last row.
 */

/// Apply brand-specific border around tables.
/// Extracted into mixin as it needs to be applied to different contexts
/// and selectors can not be combined (modifier and MQ dependant.
//
// Border needs to be applied to rows/cells as a border around the entire
// table does not work for the different variants (e.g. responsive, auto) and
// the sticky variants.
@mixin table-custom-border() {
    thead,
    tfoot {

        th,
        td {

            &::after {
                all: unset; /* [7] */
            }

            // draw outer border (thead = top, tfoot = bottom)
            &:not(:first-child, :last-child)::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                height: $table-border-width;
                background-color: $table-border-color;
            }

        }

    }

    thead {

        tr {
            border-bottom: $skoda-table-border; /* [2] */
        }

        th {

            /* [3] */
            &::before {
                top: $table-cell-padding-vertical;
                bottom: $table-cell-padding-vertical;
                width: $table-border-width;
                background-color: $table-border-color;
            }

        }

        th,
        td {

            &:not(:first-child, :last-child)::after {
                top: 0; /* [3] */
            }

        }

    }

    /* [4] */
    thead,
    tfoot {

        th,
        td {

            &:first-child,
            &:last-child {

                &::after {
                    all: unset; /* [7] */
                    content: "";
                    position: absolute;
                    z-index: 10;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    pointer-events: none;
                }

            }

            &:first-child::after {
                border-left: $skoda-table-border;
            }

            &:last-child::after {
                border-right: $skoda-table-border;
            }

        }

    }

    /* [4] */
    thead {

        th,
        td {

            &:first-child,
            &:last-child {

                &::after {
                    border-top: $skoda-table-border; /* [2] */
                }

            }

            &:first-child::after {
                border-top-left-radius: $skoda-table-border-radius;
            }

            &:last-child::after {
                border-top-right-radius: $skoda-table-border-radius;
            }

        }

    }

    /* [4] */
    tfoot {

        th,
        td {

            &:not(:first-child, :last-child)::after {
                bottom: 0;
            }

            &:first-child,
            &:last-child {

                &::after {
                    border-bottom: $skoda-table-border;
                }

            }

            &:first-child::after {
                border-bottom-left-radius: $skoda-table-border-radius;
            }

            &:last-child::after {
                border-bottom-right-radius: $skoda-table-border-radius;
            }

        }

    }

    &:not(.c-table--data-controls) tbody {

        tr {

            &:last-child {

                th,
                td {

                    &:first-child::after,
                    &:last-child::after {
                        border: 0; /* [8] */
                    }

                    // Use `::before` to overwrite borders on the side.
                    &:first-child::before,
                    &:last-child::before {
                        all: unset; /* [7] */
                        content: "";
                        position: absolute;
                        z-index: 10;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border-bottom: $skoda-table-border;
                        pointer-events: none;
                    }

                    &:first-child::before {
                        border-left: $skoda-table-border;
                        border-bottom-left-radius: $skoda-table-border-radius;
                    }

                    &:last-child::before {
                        border-right: $skoda-table-border;
                        border-bottom-right-radius: $skoda-table-border-radius;
                    }

                }

            }

        }

    }

    /* [5] */
    tbody {

        th,
        td {

            &:first-child,
            &:last-child {

                // combine with `thead th::before`?
                &::before {
                    all: unset; /* [7] */
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: $table-border-width;
                    background-color: $table-border-color;
                }

            }

            &:first-child::before {
                left: 0;
            }

            &:last-child::before {
                right: 0;
            }

        }

        tr {

            &:last-child {

                > *:first-child {
                    border-bottom-left-radius: $skoda-table-border-radius; /* [6] */
                }

                > *:last-child {
                    border-bottom-right-radius: $skoda-table-border-radius; /* [6] */
                }

            }

        }

    }
}

.c-table-wrapper:not(.c-table--responsive) {
    @include table-custom-border();
}

.c-table--sticky-column {

    /* stylelint-disable */
    &.has-sticky-column.has-sticky-column.has-sticky-column.has-sticky-column { /* [10] */
        tr > :first-child {
            background-color: var(--table-cell-background-color, #{$table-sticky-cell-background-color});
            opacity: 1;

            &::before { /* [11] */
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 100%;
                width: $table-sticky-first-column-shadow-size;
                border: none;
                border-radius: 0;
                background-color: transparent;
                background-image: linear-gradient(
                    to right,
                    rgba(0, 0, 0, 0.1),
                    rgba(0, 0, 0, 0)
                );
            }
        }
        tbody tr:last-child > :first-child::after {
            border-bottom: $table-border-vertical; /* [12] */
        }
    }
    /* stylelint-enable */
}

.c-table--responsive th:first-child {
    padding-left: $table-cell-padding-horizontal;
    padding-right: $table-cell-padding-horizontal;
}

.c-table--responsive {

    @include media-query(">#{$table-responsive-breakpoint}") {
        @include table-custom-border();
    }

    @include media-query("<=#{$table-responsive-breakpoint}") {

        tr {
            border: $skoda-table-border;
            border-radius: $skoda-table-border-radius;
        }

        tr + tr {
            padding-top: 0; /* [9] */
        }

        table tbody > tr:first-child {
            border-left: $skoda-table-border; /* [9] */
        }

        [data-columnheader] {

            &::after {
                all: unset; /* [7] */
                content: "";
                position: absolute;
                top: $table-cell-padding-vertical;
                bottom: $table-cell-padding-vertical;
                left: calc(50% - #{$table-responsive-column-gap});
                width: $table-border-width;
                background-color: $table-border-color;
            }

            &:last-child::before {
                border-bottom-left-radius: $skoda-table-border-radius;
            }

            &:not(:last-child) {
                border-bottom: $skoda-table-border;
            }

        }

    }

}
