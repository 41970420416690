/* ------------------------------------*\
    #PLYR-OVERRIDES
\*------------------------------------ */

$skoda-plyr-progress-spacing-bottom:             $GLOBAL-SPACING-UNIT-XSMALL !default;

$skoda-plyr-controls-padding-vertical:           $GLOBAL-SPACING-UNIT-SMALL !default;
$skoda-plyr-controls-padding-at-breakpoint:      $GLOBAL-SPACING-UNIT-XSMALL !default;

$skoda-plyr-controls-filter-color:               rgba(0, 0, 0, 0.35) !default;
$skoda-plyr-controls-overlay-shadow:             $DROP-SHADOW-PLAY-BUTTON !default;
$skoda-plyr-controls-overlay-size:               200px !default;
$skoda-plyr-controls-overlay-size-at-breakpoint: 100px !default;

$skoda-plyr-control-background-hover-size:       map-get($ICON-ROUND-FULL-SIZES, medium) !default;
$skoda-plyr-control-background-hover:            $BUTTON-ICON-INTERACTION-BACKGROUND-COLOR-HOVER-ON-DARK !default;
$skoda-plyr-control-scale-active:                $BUTTON-SCALE-ACTIVE !default;

$skoda-plyr-plyr-thumb-focus-offset:             4px !default;

$skoda-plyr-volume-thumb-background:             $RANGE-SLIDER-THUMB-BACKGROUND-LIGHT !default;
$skoda-plyr-volume-range-background:             $RANGE-SLIDER-TRACK-BACKGROUND-COLOR-LIGHT !default;
$skoda-plyr-volume-range-background-filled:      $RANGE-SLIDER-TRACK-FILLED-BACKGROUND-COLOR-LIGHT !default;

$skoda-plyr-time-font-size-at-breakpoint:        map-get($FS-2, null) !default;

/**
 * [1] Disable default outline.
 * [2] Apply custom outline to thumb.
 * [3] Reduce specificity to respect visibility settings for certain controls
 *     (e.g. caption).
 * [4] Remove fixed height. Set it here instead of module variable as it is
 *     used for multiple calculations.
 */

// Add scope to match specificity
.plyr--video {

    // Gradient to improve visibility
    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: $skoda-plyr-controls-overlay-size;
        background: linear-gradient(to top, $skoda-plyr-controls-filter-color, transparent);
        pointer-events: none;
        opacity: 0;

        @include media-query("<=#{$plyr-breakpoint}") {
            height: $skoda-plyr-controls-overlay-size-at-breakpoint;
        }

    }


    &:hover {

        &::after {
            opacity: 1;
        }

    }

    .plyr__controls {
        flex-wrap: wrap;
        justify-content: flex-start;
        height: auto; /* [4] */
        padding-top: $skoda-plyr-controls-padding-vertical;
        padding-bottom: $skoda-plyr-controls-padding-vertical;
    }

    :where(.plyr__control) {
        display: inline-flex; /* [3] */
    }

    .plyr__control {

        &:not(.plyr__control--overlaid) {
            width: $skoda-plyr-control-background-hover-size;
            height: $skoda-plyr-control-background-hover-size;

            &:hover {
                background-color: $skoda-plyr-control-background-hover;
            }

        }

        &:active {
            scale: $skoda-plyr-control-scale-active;
        }

    }

    // Increase specificity to overwrite default
    .plyr__controls__item.plyr__progress__container {
        flex-basis: 100%; // enforce own "row"
        order: 50;
        padding-left: 0;
    }

    .plyr__control--overlaid {
        filter: $skoda-plyr-controls-overlay-shadow;
    }

    .plyr__time {
        margin-right: auto; // push subsequent items to the right
    }

    /* stylelint-disable-next-line order/order */
    @include media-query("<=#{$plyr-breakpoint}") {

        .plyr__controls {
            padding: $skoda-plyr-controls-padding-at-breakpoint;
        }

    }

    // Re-layout controls
    @include media-query(">#{$plyr-breakpoint}") {

        .plyr__controls__item.plyr__progress__container {
            order: -50; // place above controls
            margin-left: 0;
            margin-bottom: $skoda-plyr-progress-spacing-bottom;
        }

        .plyr__controls__item {

            &[data-plyr="play"] {
                order: -30;
                margin-right: 0;
            }

            &.plyr__volume {
                order: -30;
            }

        }

    }

    @include media-query("<=#{$plyr-breakpoint}") {

        .plyr__controls__item[data-plyr="play"] {
            display: none;
        }

        .plyr__time {
            margin-left: 0;
            font-size: $skoda-plyr-time-font-size-at-breakpoint;
        }

    }

}

/* stylelint-disable selector-max-specificity */
.plyr--full-ui.plyr--video input[type="range"] {

    &:focus-visible,
    &.plyr__tab-focus,
    &:active {
        outline: 0; /* [1] */

        &::-webkit-slider-thumb {

            @include focus-styles(
                $outline-color:  $plyr-color-main,
                $outline-offset: $skoda-plyr-plyr-thumb-focus-offset,
            );

        }

        &::-moz-range-thumb {

            @include focus-styles(
                $outline-color:  $plyr-color-main,
                $outline-offset: $skoda-plyr-plyr-thumb-focus-offset,
            );

        }

    }

}
/* stylelint-enable selector-max-specificity */

.plyr--full-ui {

    input[data-plyr="volume"][type="range"] {
        color: $skoda-plyr-volume-range-background-filled;

        &::-webkit-slider-thumb {
            background-color: $skoda-plyr-volume-thumb-background;
        }

        &::-moz-range-thumb {
            background-color: $skoda-plyr-volume-thumb-background;
        }

        &::-webkit-slider-runnable-track {
            background-color: $skoda-plyr-volume-range-background;
        }

        &::-moz-range-track {
            background-color: $skoda-plyr-volume-range-background;
        }

    }

}
