// /* ------------------------------------*\
//     #TABLES (Settings)
// \*------------------------------------ */

// Table
$table-border-width:                     1px !default;
$table-border-color:                     $COLOR-BORDER !default;
$table-border-bottom-color:              $table-border-color !default;

// Table head
$table-thead-background-color:           $COLOR-WHITE !default;
$table-thead-border-bottom-width:        0 !default; // Handle via custom borders
$table-thead-border-bottom-color:        $table-border-color !default;
$table-thead-border-bottom:              $table-thead-border-bottom-width solid $table-thead-border-bottom-color !default;
$table-thead-color:                      $COLOR-BASE-TEXT !default;

// Table headings

// Table cells
$table-cell-padding-vertical:            $GLOBAL-SPACING-UNIT-SMALL !default;
$table-cell-padding-horizontal:          $GLOBAL-SPACING-UNIT-SMALL !default;
$table-cell-padding-small:               $GLOBAL-SPACING-UNIT-XSMALL !default;
$table-cell-padding-large:               $GLOBAL-SPACING-UNIT !default;
$table-cell-font-size-small:             map-get($FS-1, null) !default;
$table-cell-font-weight:                 $GLOBAL-FONT-WEIGHT-REGULAR !default; // @TODO: regular for FS0?

// Table footer

// Icons
$table-icon-size:                        icon-size(xsmall) !default;
$table-thead-icon-color:                 $COLOR-BASE-TEXT !default;
$table-sort-icon-size:                   $table-icon-size !default;

// Buttons
$table-button-reset-border-radius:       false !default;

// Table variant Zebra Style
$table-cell-background-color-odd:        $COLOR-WHITE !default;
$table-cell-background-color-even:       $COLOR-SURFACE-SECONDARY !default; // @TODO: proposal, clarify with brand

$table-row-state-border-color:           $table-border-color !default;

// Content highlight
$table-background-color-highlight:       $COLOR-BRAND-QUARTERNARY !default;
$table-cell-highlight-border:            ($table-cell-padding-horizontal * 0.5) solid $COLOR-BASE-BACKGROUND !default;

// Table variant Sticky Table
$table-sticky-has-first-column-shadow:   false !default; // We set the shadow differently for Škoda

// Responsive table variant
$table-responsive-tr-border:             false !default;
$table-responsive-rowheader-color:       $table-thead-color !default;

$table-responsive-cell-border-hide-last: false !default;

// Table Data Controls disabled state
$table-inactive-layer-color:             $COLOR-INACTIVE-LAYER-COLOR !default;
$table-inactive-layer-opacity:           $COLOR-INACTIVE-LAYER-OPACITY !default;
$table-inactive-bottom-width:            1px !default;
$table-inactive-border-left-spacing:     $table-border-width !default;
$table-inactive-border-right-spacing:    $table-border-width !default;
$table-inactive-layer-reset-styles:      true !default;


$table-row-states-overwrites: (
    "success": (
        "color":        null,
        "border-color": $COLOR-SUCCESS,
        "icon-color":   null,
    ),
    "success-hover": (
        "color": null,
    ),
    "success-selected": (
        "color": null,
    ),
    "error": (
        "border-color": $COLOR-ALERT,
        "icon-color":   null,
    ),
) !default;

$table-cell-states-overwrites: (
    "error": (
        "border-color": $COLOR-ALERT,
    ),
) !default;
