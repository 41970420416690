/* ------------------------------------*\
    #FLOAT
\*------------------------------------ */

/**
 * Helper classes to float elements either left, right or none.
 */

.u-float-left {
    float: left !important;
}

.u-float-right {
    float: right !important;
}

.u-float-none {
    float: none !important;
}



// Loop over our breakpoints defined in _settings.responsive.scss
@each $breakpoint in $BREAKPOINTS {

    // Get the name of the breakpoint.
    $alias: nth($breakpoint, 1);

    @include media-query("<=#{$alias}") {

        .u-float-left\@#{$alias} {
            float: left !important;
        }

        .u-float-right\@#{$alias} {
            float: right !important;
        }

        .u-float-none\@#{$alias} {
            float: none !important;
        }

    }

    @include media-query(">#{$alias}") {

        .u-float-left\@#{$alias}-up {
            float: left !important;
        }

        .u-float-right\@#{$alias}-up {
            float: right !important;
        }

        .u-float-none\@#{$alias}-up {
            float: none !important;
        }

    }

}
