/* ------------------------------------*\
    #COMPARE-AND-SELECT
\*------------------------------------ */

@use "sass:math" as *;

$compare-and-select-width: (
    null: percentage(div(4, 12)),
    "m":  percentage(div(5, 12)),
    "s":  percentage(div(8, 12)),
    "xs": percentage(div(10, 12)),
) !default;
$compare-and-select-breakpoint:                           m !default;
$compare-and-select-equal-height-breakpoint:              s !default;

// Use page-wrap spacing definitions < breakpoint
$compare-and-select-offset-at-breakpoint: (
    "m":  map-get($page-wrap-padding-horizontal, "m"),
    "s":  map-get($page-wrap-padding-horizontal, "s"),
    "xs": map-get($page-wrap-padding-horizontal, "xs"),
) !default;

$compare-and-select-item-spacing: (
    null: $GLOBAL-SPACING-UNIT-LARGE,
    "l":  $GLOBAL-SPACING-UNIT,
    "m":  $GLOBAL-SPACING-UNIT-SMALL,
    "s":  $GLOBAL-SPACING-UNIT-XSMALL,
) !default;

$compare-and-select-slider-arrow-icon-size:               null !default;
// `bottom` or `top`
$compare-and-select-slider-navigation-position:           bottom !default;
// This sets the inner padding of the components box for all its content.
$compare-and-select-padding:                              $GLOBAL-SPACING-UNIT !default;
$compare-and-select-padding-top:                          $compare-and-select-padding !default;
$compare-and-select-padding-bottom:                       $compare-and-select-padding !default;
$compare-and-select-box-shadow:                           $GLOBAL-BOX-SHADOW !default;
$compare-and-select-box-shadow-checked:                   $compare-and-select-box-shadow !default;
// Make sure the box-shadow is visible by extending the flickity viewport
$compare-and-select-offset:                               if($compare-and-select-box-shadow or $compare-and-select-box-shadow-checked, 5px, null) !default;
$compare-and-select-offset-top:                           $compare-and-select-offset !default;
$compare-and-select-offset-bottom:                        $compare-and-select-offset !default;
$compare-and-select-background-color:                     $COLOR-WHITE !default;
$compare-and-select-background-color-checked:             $COLOR-PALE !default;
$compare-and-select-border:                               null !default;
$compare-and-select-border-color-checked:                 null !default;
$compare-and-select-border-radius:                        null !default;

$compare-and-select-color:                                null !default;
$compare-and-select-color-checked:                        null !default;
$compare-and-select-color-indicator-checked:              null !default;

// Top position for absolute positioned labels
$compare-and-select-label-top:                            $compare-and-select-padding !default;
$compare-and-select-label-offset:                         0 !default;
$compare-and-select-label-horizontal-centering:           true !default;
$compare-and-select-label-horizontal-padding:             null !default;
$compare-and-select-label-vertical-padding:               null !default;
$compare-and-select-label-font-size:                      $FS-1 !default;
$compare-and-select-label-font-weight:                    $GLOBAL-FONT-WEIGHT-REGULAR !default;
$compare-and-select-label-text-transform:                 null !default;
$compare-and-select-label-letter-spacing:                 null !default;
$compare-and-select-label-color:                          null !default;
$compare-and-select-label-color-checked:                  null !default;
$compare-and-select-label-background-color:               null !default;
$compare-and-select-label-background-color-checked:       null !default;

$compare-and-select-link-color-checked:                   null !default;
$compare-and-select-icon-color-checked:                   null !default;

$compare-and-select-title-horizontal-centering:           false !default;
$compare-and-select-title-vertical-spacing:               $compare-and-select-padding !default;
$compare-and-select-title-font-size:                      $FS0 !default;
$compare-and-select-title-font-weight:                    null !default;
$compare-and-select-title-text-transform:                 null !default;
$compare-and-select-title-color:                          null !default;
$compare-and-select-title-divider:                        null !default;
$compare-and-select-title-divider-checked:                null !default;
$compare-and-select-title-align:                          center !default;

$compare-and-select-title-indicator-font-size:            $FS5 !default;
$compare-and-select-title-indicator-font-weight:          null !default;
$compare-and-select-title-indicator-text-transform:       null !default;
$compare-and-select-title-indicator-color:                null !default;

$compare-and-select-icon-enabled:                         true !default;
$compare-and-select-icon-size:                            icon-size(large) !default;
$compare-and-select-icon-spacing:                         $GLOBAL-SPACING-UNIT-XSMALL !default;
$compare-and-select-icon-color:                           $COLOR-BRAND !default;

$compare-and-select-description-font-size:                $FS-1 !default;
$compare-and-select-description-vertical-spacing:         $compare-and-select-padding !default;

$compare-and-select-button-full-width:                    null !default;
$compare-and-select-button-vertical-spacing:              $compare-and-select-padding !default;

$compare-and-select-legal-font-size:                      $FS-1 !default;
$compare-and-select-legal-font-weight:                    null !default;
$compare-and-select-legal-color:                          null !default;

$compare-and-select-checkbox-label-color:                 null !default;
$compare-and-select-checkbox-label-color-checked:         inherit !default;
$compare-and-select-checkbox-background-color:            null !default;
$compare-and-select-checkbox-icon-color:                  null !default;
$compare-and-select-checkbox-border-color:                null !default;

$compare-and-select-radio-font-size:                      $FS2 !default;
$compare-and-select-radio-font-weight:                    $GLOBAL-FONT-WEIGHT-BOLD !default;
$compare-and-select-radio-description-font-size:          $FS-1 !default;
$compare-and-select-radio-description-font-weight:        $GLOBAL-FONT-WEIGHT-REGULAR !default;
$compare-and-select-radio-description-color:              null !default;
$compare-and-select-radio-label-background-color:         null !default;
$compare-and-select-radio-label-background-color-checked: null !default;
$compare-and-select-radio-label-color:                    inherit !default;
$compare-and-select-radio-label-color-checked:            null !default;
$compare-and-select-radio-border-color:                   null !default;
$compare-and-select-radio-border-color-checked:           $compare-and-select-radio-border-color !default;
$compare-and-select-radio-background-color:               null !default;
$compare-and-select-radio-background-color-checked:       $compare-and-select-radio-background-color !default;
$compare-and-select-radio-dot-background:                 null !default;
$compare-and-select-radio-dot-background-color-hover:     null !default;
$compare-and-select-radio-dot-background-color-checked:   null !default;

$compare-and-select-list-vertical-spacing:                $compare-and-select-padding !default;

$compare-and-select-stacked-color:                        $COLOR-BRAND !default;
$compare-and-select-stacked-color-checked:                $COLOR-BRAND !default;
$compare-and-select-stacked-vertical-spacing:             $compare-and-select-padding !default;
$compare-and-select-stacked-item-spacing:                 $compare-and-select-padding !default;
$compare-and-select-stacked-breakpoint:                   s !default;
$compare-and-select-stacked-breakpoint-value:             map-get($BREAKPOINTS, $compare-and-select-stacked-breakpoint) !default;

$compare-and-select-themes: (
    "alternative": (
        "$compare-and-select-background-color":               null,
        "$compare-and-select-background-color-checked":       null,
        "$compare-and-select-label-color":                    null,
        "$compare-and-select-label-color-checked":            null,
        "$compare-and-select-label-background-color":         null,
        "$compare-and-select-label-background-color-checked": null,
    )
) !default;

/**
 * [1] Only add padding and border to the top if this element is not a
 *     direct sibling of the header element.
 * [2] Hide native input element and do not act as a "scroll anchor"
 *     when the associated label is clicked.
 * [3] Center the icon and title text. `display: flex` is set by `.o-media`.
 * [4] Reset default `margin-bottom`.
 * [5] The button stretches across the full width by default. Optionally it can
 *      be centered via flexbox and `auto` margins.
 * [6] Eliminate the spacing bottom below the very last
 *     element in the container.
 * [7] Although the `margin` property is used here, what it actually does
 *     **visually** is setting a padding. That's why our
 *     `$compare-and-select-padding` variable is used.
 * [8] To align the slider dots and the slider arrows properly, we set the
 *     container to flex and reposition the items with the `order` property.
 *     This prevents us from using `position: absolute` plus magic numbers.
 * [9] Center the dots and the arrows horizontally.
 * [10] Center the dots and the arrows vertically.
 * [11] Force the slider items to take up all horizontal space.
 * [12] Hack to prevent a cut-off of the box-shadow by the necessary
 *      `overflow: hidden` of the slider container.
 * [13] Hide the dots and arrows, if there is nothing to slide.
 * [14] Compensate only above breakpoint. Smaller breakpoints go edge-to-edge,
 *      no compensation required.
 * [15] The actual arrow icons of the slider. The size of the icons has to be
 *      done directly on the pseudo element.
 * [16] `box-shadow` can be used for real box shadow effects or simulated
 *      borders that do not affect the inner element˚s widths.
 * [17] Use flexbox to allow reversed order of total and suffix.
 * [18] Set the spacing between value total and value suffix depending on the
 *      visual order of both elements.
 * [19] Set `position` to allow positioning of inner elements like the label.
 * [20] Increase the radio button label width to the full component width.
 *      them horizontally.
 * [21] Create (responsive) gutter between the slider elements while still
 *      aligning the content correctly horizontally. That is why we need the
 *      negative padding "trick" here.
 * [22] Make all slider elements the same height while pushing the radio addon
 *      and legal text to the bottom.
 * [23] Calculate correct spacing between slider and navigation controls by
 *      substracting the bottom offset if needed. This makes sure that the spacing
 *      in total is always the value we set for the vertical spacing.
 * [24] Hide label without content. Selector only matches for NO content,
 *      whitespaces & comments are not considered as empty.
 * [25] Match selector even with whitespaces & comments,
 *      analogue to `:blank` (firefox only).
 * [26] Move title down if a label is present in order to align titles of items
 *      with and without label side-by-side. We account for the label height using
 *      an offset value.
 * [27] Enables child elements to work with utility class `u-text-truncate`.
 * [28] Make sure when the background has a brand color, the `.c-icon` inherits the parent's color.
 * [29] Allow component to break out of page-wrap to go from edge-to-edge.
 * [30] Enable and disable Flickity with CSS depending on the defined breakpoint.
 *      @see https://flickity.metafizzy.co/options.html#watchcss
 * [31] When stacked enable full viewport width for items.
 * [32] Hide the initial marker for all browsers.
 * [33] Display the `<summary>` element of the parent `<details>` element only on small screens.
 * [34] Retrieve the breakpoint custom property to use within the component script.
 * [35] Remove any inherited margin, e.g. from `.c-compare-and-select__list`.
 * [36] Add bottom spacing to any item when in stacked mode on smaller screens.
 * [37] Rotate the icon to indicate the open `<details>`.
 * [38] Ensure spacing on smaller screens.
 * [39] Remove reserved space for label to reduce whitespace in stacked mode.
 * [40] Override hint colors for selected tiles.
 * [41] Activate the equal height functionality only for larger viewports.
 * [42] Align the pricing component always from the bottom-up.
 * [43] Apply calculated title height.
 */
/* stylelint-disable selector-max-compound-selectors, selector-max-combinators, selector-max-specificity */
.c-compare-and-select-container {

    .o-page-wrap & {
        @include responsive-property("margin-left", responsive-property-modify($compare-and-select-offset-at-breakpoint, $factor: -1)); /* [29] */
        @include responsive-property("margin-right", responsive-property-modify($compare-and-select-offset-at-breakpoint, $factor: -1)); /* [29] */
    }

    .flickity-viewport {
        order: if($compare-and-select-slider-navigation-position == "top", 10, 0); /* [8] */
    }

    .flickity-prev-next-button {
        position: relative; /* [11] */
        display: block; /* [13] */
        transform: none; /* [11] */

        &::before { /* [15] */
            font-size: $compare-and-select-slider-arrow-icon-size;
            vertical-align: middle; /* [10] */
        }

        &.previous {
            order: 1; /* [8] */
        }

        &.next {
            order: 3; /* [8] */
        }

    }

    .flickity-page-dots {
        display: flex; /* [13] */
        order: 2; /* [8] */
    }

}

    .c-compare-and-select-container__inner {
        --bron-compare-and-select-min-height: initial;

        &.flickity-resize {
            @include responsive-property("margin-left", responsive-property-modify($compare-and-select-item-spacing, $factor: -0.5)); /* [21] */
            @include responsive-property("margin-right", responsive-property-modify($compare-and-select-item-spacing, $factor: -0.5)); /* [21] */

            @include media-query("<=#{$compare-and-select-breakpoint}") {
                margin-left: 0 !important; /* [14] */
                margin-right: 0 !important; /* [14] */
            }

            .c-compare-and-select__item,
            .c-compare-and-select {
                height: 100%; /* [22] */
            }

        }

    }

    .c-compare-and-select--stacked {
        --bron-compare-select-accordion-breakpoint: #{$compare-and-select-stacked-breakpoint-value}; /* [34] */

        &::after {
            content: "flickity"; /* [30] */
            display: none; /* [30] */

            @include media-query("<#{$compare-and-select-stacked-breakpoint}") {
                content: ""; /* [30] */
            }

        }

        .c-compare-and-select__item {

            @include media-query("<=#{$compare-and-select-stacked-breakpoint}") {
                width: 100%; /* [31] */
                margin-bottom: $compare-and-select-stacked-item-spacing; /* [36] */
            }

        }

        .c-compare-and-select__label {

            @include media-query("<=#{$compare-and-select-stacked-breakpoint}") {
                &:empty {

                    + .c-compare-and-select__title {
                        margin-top: 0; /* [39] */
                    }

                }

                &:-moz-only-whitespace {

                    + .c-compare-and-select__title {
                        margin-top: 0; /* [39] */
                    }

                }

            }

        }

    }

    .c-compare-and-select__details {
        display: flex;
        margin-right: $compare-and-select-padding;
        margin-left: $compare-and-select-padding;

        @include media-query("<#{$compare-and-select-stacked-breakpoint}") {
            margin-bottom: $compare-and-select-stacked-vertical-spacing; /* [38] */
        }

        &[open] {
            margin-bottom: 0; /* [38] */

            .c-compare-and-select__summary {
                margin-bottom: $compare-and-select-stacked-vertical-spacing;

                @include media-query(">#{$compare-and-select-stacked-breakpoint}") {
                    display: none; /* [33] */
                }

            }

            .c-btn__icon {
                transform: rotate(180deg); /* [37] */
            }

            > * {
                margin-left: 0; /* [35] */
                margin-right: 0; /* [35] */
            }

        }

    }

    .c-compare-and-select__summary {
        list-style: none; /* [32] */
        cursor: pointer;

        &::-webkit-details-marker {
            display: none; /* [32] */
        }

        &::before {

            @include media-query("<=#{$compare-and-select-stacked-breakpoint}") {
                color: $compare-and-select-stacked-color;
            }

        }

    }

    .c-compare-and-select__item {
        @include responsive-property("width", $compare-and-select-width);
        padding-top: $compare-and-select-offset-top; /* [12] */
        padding-bottom: $compare-and-select-offset-bottom; /* [12] */
    }

        .c-compare-and-select {
            @include transition();

            @include responsive-property("margin-left", responsive-property-modify($compare-and-select-item-spacing, $factor: 0.5)); /* [21] */
            @include responsive-property("margin-right", responsive-property-modify($compare-and-select-item-spacing, $factor: 0.5)); /* [21] */
            position: relative; /* [19] */
            display: flex;
            flex-direction: column;
            padding-top: $compare-and-select-padding-top;
            border: $compare-and-select-border;
            border-radius: $compare-and-select-border-radius;
            background-color: if($compare-and-select-background-color, $compare-and-select-background-color, null);
            color: $compare-and-select-color;
            box-shadow: if($compare-and-select-box-shadow, $compare-and-select-box-shadow, null); /* [16] */

            @include vary($loop: $compare-and-select-themes) {
                background-color: vary-get("$compare-and-select-background-color");
            }

        }

        .c-compare-and-select__hidden-input {
            display: none;
        }

            .c-compare-and-select__label {
                position: absolute;
                top: $compare-and-select-label-top;
                align-self: if($compare-and-select-label-horizontal-centering, center, null);
                padding-top: $compare-and-select-label-vertical-padding;
                padding-right: $compare-and-select-label-horizontal-padding;
                padding-bottom: $compare-and-select-label-vertical-padding;
                padding-left: $compare-and-select-label-horizontal-padding;
                font-weight: $compare-and-select-label-font-weight;
                text-transform: $compare-and-select-label-text-transform;
                letter-spacing: $compare-and-select-label-letter-spacing;
                background-color: $compare-and-select-label-background-color;
                color: $compare-and-select-label-color;
                @if ($compare-and-select-label-horizontal-centering) {
                    left: 50%;
                    transform: translateX(-50%);
                    text-align: center;
                } @else {
                    margin-right: $compare-and-select-padding; /* [7] */
                    margin-left: $compare-and-select-padding; /* [7] */
                }
                @if ($compare-and-select-label-font-size) {
                    @include responsive-font-size($compare-and-select-label-font-size);
                }
                @include vary($loop: $compare-and-select-themes) {
                    background-color: vary-get("$compare-and-select-label-background-color");
                    color: vary-get("$compare-and-select-label-color");
                }

                &:empty {
                    display: none; /* [24] */
                }

                &:-moz-only-whitespace {
                    display: none; /* [25] */
                }

            }

            .c-compare-and-select__title {
                display: flex;
                flex-direction: column;
                align-items: flex-alignment($compare-and-select-title-align);
                justify-content: if($compare-and-select-title-horizontal-centering, center, null); /* [3] */
                padding-right: $compare-and-select-padding;
                padding-left: $compare-and-select-padding;
                text-align: $compare-and-select-title-align;

                @if ($compare-and-select-title-divider) {
                    margin-bottom: $compare-and-select-title-vertical-spacing;
                    padding-bottom: $compare-and-select-title-vertical-spacing;
                    border-bottom: $compare-and-select-title-divider;
                } @else {
                    margin-bottom: $compare-and-select-title-vertical-spacing;
                }

                @include media-query(">#{$compare-and-select-equal-height-breakpoint}") { /* [41] */
                    min-height: var(#{--bron-compare-and-select-min-height});  /* [43] */
                }

                .c-compare-and-select__label + & {
                    margin-top: ($compare-and-select-label-top + $compare-and-select-label-offset); /* [26] */
                }

            }

            .c-compare-and-select__icon {
                @if ($compare-and-select-icon-enabled) {
                    @include font-size(
                        $font-size:   $compare-and-select-icon-size,
                        $line-height: 1
                    );
                    margin-bottom: $compare-and-select-icon-spacing;
                    color: $compare-and-select-icon-color;
                } @else {
                    display: none;
                }

            }

            .c-compare-and-select__title-text {
                @include responsive-font-size($compare-and-select-title-font-size);
                max-width: 100%; /* [27] */
                margin-bottom: 0; /* [4] */
                font-weight: $compare-and-select-title-font-weight;
                text-transform: $compare-and-select-title-text-transform;
                color: $compare-and-select-title-color;
            }

            .c-compare-and-select__title-indicator-text {
                @include responsive-font-size($compare-and-select-title-indicator-font-size);
                font-weight: $compare-and-select-title-indicator-font-weight;
                text-transform: $compare-and-select-title-indicator-text-transform;
                color: $compare-and-select-title-indicator-color;
            }

            .c-compare-and-select__description {
                @include responsive-font-size($compare-and-select-description-font-size);
                margin-right: $compare-and-select-padding; /* [7] */
                margin-bottom: $compare-and-select-description-vertical-spacing;
                margin-left: $compare-and-select-padding; /* [7] */
            }

            .c-compare-and-select__pricing {
                display: flex;  /* [42] */
                flex-direction: column;  /* [42] */
                justify-content: flex-end;  /* [42] */
                min-height: var(#{--bron-compare-and-select-min-height});  /* [43] */
                margin-right: $compare-and-select-padding; /* [7] */
                margin-left: $compare-and-select-padding; /* [7] */
            }

            .c-compare-and-select__button {
                margin-right: if($compare-and-select-button-full-width, $compare-and-select-padding, auto); /* [5] */
                margin-left: if($compare-and-select-button-full-width, $compare-and-select-padding, auto); /* [5] */
                margin-bottom: $compare-and-select-padding-bottom;

                .c-compare-and-select > & {
                    margin-bottom: $compare-and-select-button-vertical-spacing;
                }

            }

            .c-compare-and-select__legal {
                @include responsive-font-size($compare-and-select-legal-font-size);
                margin-right: $compare-and-select-padding; /* [7] */
                margin-left: $compare-and-select-padding; /* [7] */
                margin-bottom: $compare-and-select-padding;
                font-weight: $compare-and-select-legal-font-weight;
                color: $compare-and-select-legal-color;
            }

            .c-compare-and-select__list {
                margin-right: $compare-and-select-padding; /* [7] */
                margin-bottom: $compare-and-select-list-vertical-spacing;
                margin-left: $compare-and-select-padding; /* [7] */

                &.c-icon-list li:last-child {
                    margin-bottom: 0; /* [6] */
                }

            }

            .c-compare-and-select__checkbox {
                margin-right: $compare-and-select-padding; /* [7] */
                margin-bottom: $compare-and-select-padding;
                margin-left: $compare-and-select-padding; /* [7] */

                .c-checkbox {
                    color: inherit;
                }

                .c-checkbox__label {
                    color: $compare-and-select-checkbox-label-color;

                    // The checkbox outer box.
                    &::before {
                        border-color: $compare-and-select-checkbox-border-color;
                        background-color: $compare-and-select-checkbox-background-color;
                    }

                    // The check mark icon.
                    &::after {
                        color: $compare-and-select-checkbox-icon-color;
                    }
                }
            }

            .c-compare-and-select__radio {
                display: block; /* [20] */
                padding-top: $compare-and-select-padding;
                padding-right: $compare-and-select-padding; /* [7] */
                padding-bottom: $compare-and-select-padding;
                padding-left: $compare-and-select-padding; /* [7] */
                background-color: $compare-and-select-radio-label-background-color;
                color: inherit;

                .c-radio__input:checked + .c-radio__label-container & {
                    background-color: $compare-and-select-radio-label-background-color-checked;
                }

            }

                .c-compare-and-select__radio-label {
                    @if ($compare-and-select-radio-font-size) {
                        @include responsive-font-size($compare-and-select-radio-font-size);
                    }
                    font-weight: $compare-and-select-radio-font-weight;

                    &, &:hover {
                        color: $compare-and-select-radio-label-color;
                    }

                    .c-radio__input:checked + .c-radio__label-container & {
                        &, &:hover {
                            color: $compare-and-select-radio-label-color-checked;
                        }
                    }

                    // The circle radio-button box.
                    &::before {
                        border-color: $compare-and-select-radio-border-color;
                        background-color: $compare-and-select-radio-background-color;

                        .c-radio__input:checked + .c-radio__label-container & {
                            border-color: $compare-and-select-radio-border-color-checked;
                            background-color: $compare-and-select-radio-background-color-checked;
                        }
                    }

                    // The dot inside the radio-button.
                    &::after {
                        background-color: $compare-and-select-radio-dot-background;

                        .c-radio__input:hover + .c-radio__label-container & {
                            background-color: $compare-and-select-radio-dot-background-color-hover;
                        }

                        .c-radio__input:checked + .c-radio__label-container &,
                        .c-radio__input:checked:hover + .c-radio__label-container & {
                            background-color: $compare-and-select-radio-dot-background-color-checked;
                        }
                    }

                }

                .c-compare-and-select__radio-description {
                    display: block;
                    @if ($compare-and-select-radio-description-font-size) {
                        @include responsive-font-size($compare-and-select-radio-description-font-size);
                    }
                    font-weight: $compare-and-select-radio-description-font-weight;
                    color: $compare-and-select-radio-description-color;
                }

            .c-compare-and-select--selected,
            .c-compare-and-select__hidden-input:checked + .c-compare-and-select {
                --hint-text-color: $compare-and-select-color-checked; /* [40] */
                --hint-text-color-hover: $compare-and-select-color-checked; /* [40] */
                border-color: $compare-and-select-border-color-checked;
                box-shadow: if($compare-and-select-box-shadow-checked, $compare-and-select-box-shadow-checked, null);  /* [16] */
                background-color: if($compare-and-select-background-color-checked, $compare-and-select-background-color-checked, null);
                color: if($compare-and-select-color-checked, $compare-and-select-color-checked, null);

                @include vary($loop: $compare-and-select-themes) {
                    background-color: vary-get("$compare-and-select-background-color-checked");
                }

                .c-compare-and-select__label {
                    background-color: $compare-and-select-label-background-color-checked;
                    color: $compare-and-select-label-color-checked;

                    @include vary($loop: $compare-and-select-themes) {
                        background-color: vary-get("$compare-and-select-label-background-color-checked");
                        color: vary-get("$compare-and-select-label-color-checked");
                    }

                }

                .c-compare-and-select__title {
                    border-bottom: $compare-and-select-title-divider-checked;
                }

                .c-compare-and-select__title-text {
                    color: if($compare-and-select-color-checked, $compare-and-select-color-checked, null);
                }

                .c-compare-and-select__title-indicator-text {
                    color: if($compare-and-select-color-checked, $compare-and-select-color-indicator-checked, null);
                }

                .c-compare-and-select__icon {
                    color: if($compare-and-select-color-checked, $compare-and-select-color-checked, null);
                }

                .c-checkbox__label {
                    color: $compare-and-select-checkbox-label-color-checked;
                }

                a {
                    color: $compare-and-select-link-color-checked;
                }

                .c-compare-and-select__summary {

                    > * {
                        color: $compare-and-select-stacked-color-checked;
                    }

                    &::before {
                        color: $compare-and-select-stacked-color-checked;
                    }

                }

            }

        .c-compare-and-select__footer {
            display: flex;
            flex-direction: column;
            margin-top: auto; /* [22] */
        }

/* stylelint-enable */

.c-compare-and-select--selected,
.c-compare-and-select__hidden-input:checked + .c-compare-and-select,
.c-compare-and-select__radio {

    .c-icon {
        color: if($compare-and-select-icon-color-checked, $compare-and-select-icon-color-checked, null); /* [28] */
    }

}
