// /* ------------------------------------*\
//     #SITE-NAV (Settings)
// \*------------------------------------ */

$site-nav-background-color-at-breakpoint:             $COLOR-BASE-BACKGROUND !default;

$site-nav-divider-at-breakpoint:                      false !default;

$site-nav-toggle-icon-size:                           icon-size(xsmall) !default;

$site-nav-item-spacing-horizontal:                    $GLOBAL-SPACING-UNIT !default;

$site-nav-link-flex-justify-content:                  space-between !default;
$site-nav-link-icon-size:                             icon-size(xxsmall) !default;

$site-nav-link-label-padding-vertical-at-breakpoint:  $__SKODA-SPACING-STATIC-12__ !default; // target size: 48px
$site-nav-link-label-font-weight:                     $GLOBAL-FONT-WEIGHT-REGULAR !default;
$site-nav-link-label-text-color-at-breakpoint:        false !default;
$site-nav-link-label-text-color-hover-at-breakpoint:  false !default;
$site-nav-link-label-line-height:                     1.5 !default;
