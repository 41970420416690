// /* ------------------------------------*\
//     #FAV-BUTTON (Settings)
// \*------------------------------------ */

$fav-button-icon-size:        icon-size($BUTTON-ICON-SIZE) !default;
$fav-button-color-deselected: $BUTTON-LINK-TEXT-COLOR !default;
$fav-button-color-hover:      $BUTTON-LINK-TEXT-COLOR-HOVER !default;
$fav-button-color-active:     $BUTTON-LINK-TEXT-COLOR-FOCUS !default;
$fav-button-color-disabled:   $BUTTON-LINK-TEXT-COLOR-DISABLED !default;
$fav-button-font-weight:      $BUTTON-FONT-WEIGHT !default;
$fav-button-letter-spacing:   $BUTTON-LETTER-SPACING !default;
