/* ------------------------------------*\
    #SHARED
\*------------------------------------ */

/**
 * Where `margin-bottom` is concerned, this value will be the same as the
 * `$GLOBAL-LINE-HEIGHT`. This allows us to keep a consistent vertical rhythm.
 *
 * https://csswizardry.com/2012/06/single-direction-margin-declarations
 */

ul,
ol,
dl,
blockquote,
p,
address,
hr,
table,
pre {
    margin-bottom: $GLOBAL-SPACING-UNIT;
}
