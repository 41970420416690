/* ------------------------------------*\
    #INBOX-TABLE
\*------------------------------------ */

$inbox-table-subheading-padding-vertical:        $GLOBAL-SPACING-UNIT-SMALL !default;
$inbox-table-subheading-margin-top:              $GLOBAL-SPACING-UNIT-LARGE !default;
$inbox-table-subheading-margin-bottom:           $GLOBAL-SPACING-UNIT-SMALL !default;
$inbox-table-subheading-font-size:               $FS0 !default;
$inbox-table-subheading-font-weight:             $GLOBAL-FONT-WEIGHT-REGULAR !default;
$inbox-table-subheading-text-align:              null !default;
$inbox-table-subheading-background-color:        null !default;

$inbox-table-breakpoint:                         "s" !default;

$inbox-table-responsive-border-top:              null !default;

$inbox-table-tr-unread-font-weight:              $GLOBAL-FONT-WEIGHT-BOLD !default;
$inbox-table-tr-responsive-spacing-vertical:     $GLOBAL-SPACING-UNIT !default;

$inbox-table-td-min-height:                      $GLOBAL-SPACING-UNIT-LARGE !default;
$inbox-table-td-font-size:                       $FS0 !default;

// Magic number because the length depends on content and font size of the cells
$inbox-table-td-date-min-width:                  8em !default;
$inbox-table-td-date-responsive-margin-bottom:   $GLOBAL-SPACING-UNIT !default;

$inbox-table-td-extra-info-font-size:            $FS-2 !default;
$inbox-table-td-extra-info-font-weight:          $GLOBAL-FONT-WEIGHT-REGULAR !default;
$inbox-table-td-extra-info-text-transform:       null !default;
$inbox-table-td-extra-info-color:                null !default;

// Use table-cell spacing & indicator-size + spacing
$inbox-table-td-indicator-offset:                $GLOBAL-SPACING-UNIT-SMALL !default; // @TODO: Based on table-cell spacing. Table settings need to be global in order to reuse them.
$inbox-table-td-indicator-dot-offset-vertical:   $INDICATOR-DOT-SIZE !default;
$inbox-table-td-indicator-dot-offset-horizontal: ($INDICATOR-DOT-SPACING + $INDICATOR-DOT-SIZE) !default;
$inbox-table-td-subject-offset:                  $inbox-table-td-indicator-offset + ($inbox-table-td-indicator-dot-offset-horizontal) !default;
$inbox-table-td-subject-offset-at-breakpoint:    ($INDICATOR-DOT-SIZE + $INDICATOR-DOT-SPACING) !default;

$inbox-table-button-spacing-horizontal:          $GLOBAL-SPACING-UNIT-XSMALL !default;

/**
 * [1] Nest `.c-inbox-table` elements to increase specificity that let it
 *     overwrite `.c-table` styles.
 * [2] Define a minimum height to have same table cell heights, regardless of
 *     whether the content is one or two lines long.
 * [3] The date cell should have a `min-width` so that this column is always the
 *     same width in separate tables.
 * [4] Stretch the subject column to the widest possible width.
 * [5] Small viewports: Remove `table` related behavior from all table elements
 *     and format everything as a block-level element.
 * [6] Small viewports: Hide table head row in an accessibly.
 * [7] Small viewports: Position action buttons/icons into the top right corner.
 * [8] Small viewports: Remove bottom table cell borders except for the last
 *     cell.
 * [9] Reset some table link styles for the normal, non-hover state.
*/

// @TODO: resolve nesting
.c-inbox-table {

    .c-inbox-table__subheading { /* [1] */
        @include responsive-font-size(
            $font-size: $inbox-table-subheading-font-size
        );
        margin-top: $inbox-table-subheading-margin-top;
        margin-bottom: $inbox-table-subheading-margin-bottom;
        padding-top: if($inbox-table-subheading-padding-vertical, $inbox-table-subheading-padding-vertical, null);
        padding-bottom: if($inbox-table-subheading-padding-vertical, $inbox-table-subheading-padding-vertical, null);
        font-weight: $inbox-table-subheading-font-weight;
        text-align: $inbox-table-subheading-text-align;
        background-color: $inbox-table-subheading-background-color;
    }

    .c-inbox-table__body {

        .c-table__btn {
            margin-right: $inbox-table-button-spacing-horizontal;
            margin-left: $inbox-table-button-spacing-horizontal;
        }

    }

    .c-inbox-table__tr {

        &.is-unread {

            /* stylelint-disable-next-line selector-max-class */
            .c-inbox-table__td {
                font-weight: $inbox-table-tr-unread-font-weight;
            }

        }

    }

    .c-inbox-table__td {
        @include responsive-font-size(
            $font-size: $inbox-table-td-font-size,
            $line-height: auto,
        );

        height: $inbox-table-td-min-height; /* [2] */
        vertical-align: middle;

    }

        .c-inbox-table__td--date {
            min-width: $inbox-table-td-date-min-width; /* [3] */
            white-space: nowrap;
        }

        .c-inbox-table__td--action {
            padding-top: 0;
            padding-bottom: 0;
        }

        .c-inbox-table__td--subject {
            position: relative;
            width: 100%; /* [4] */
            padding-left: $inbox-table-td-subject-offset;
        }

        .c-inbox-table__td__subject {
            position: relative;
        }

        .c-inbox-table__status-indicator {
            position: absolute;
            top: $inbox-table-td-indicator-dot-offset-vertical;
            left: -($inbox-table-td-indicator-dot-offset-horizontal);
        }

    /**
     * Responsive variant for small viewports
     */
    /* stylelint-disable-next-line order/order */
    @include media-query("<=#{$inbox-table-breakpoint}") {

        &.c-inbox-table {

            table {
                min-width: auto;
                border-top: $inbox-table-responsive-border-top;
            }

            table, thead, tbody, tr, th, td {
                display: block; /* [5] */
            }

            thead tr {
                @include a11y-hidden(); /* [6] */
            }

            tr {
                position: relative; /* [7] */
                margin-top: $inbox-table-tr-responsive-spacing-vertical;
            }

            th, td {
                position: static; /* [7] */
                height: auto;
                padding: 0;

                &::after {
                    left: 0;

                    &:not(:last-child) {
                        border: none; /* [8] */
                    }
                }

            }

            .c-table__btn {
                position: absolute; /* [7] */
                top: 0; /* [7] */
                right: 0; /* [7] */
                width: auto;
                height: auto;
                margin-right: 0;
                margin-left: 0;
            }

            .c-inbox-table__td--date {
                margin-bottom: $inbox-table-td-date-responsive-margin-bottom;
            }

            .c-inbox-table__td--subject {
                position: relative;
                padding-left: $inbox-table-td-subject-offset-at-breakpoint;
                padding-bottom: $inbox-table-tr-responsive-spacing-vertical;
            }

        }

    }

}

    .c-inbox-table__extra-info {
        @include responsive-font-size(
            $font-size: $inbox-table-td-extra-info-font-size
        );
        margin-bottom: 0;
        font-weight: $inbox-table-td-extra-info-font-weight;
        text-transform: $inbox-table-td-extra-info-text-transform;
        color: $inbox-table-td-extra-info-color;
    }

    .c-inbox-table__link {
        &:not(:hover) {
            border-bottom: none; /* [9] */
            text-decoration: none; /* [9] */
            color: inherit; /* [9] */
        }
    }
