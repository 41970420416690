/* ------------------------------------*\
    #TABLES (Scopes)
\*------------------------------------ */

$skoda-table-button-link-color: $BUTTON-LINK-TEXT-COLOR !default;

/**
 * [1] Reset color to default link color as disabled rows have an overlay.
 */
.c-table-wrapper {

    tr.is-disabled,
    tr[aria-disabled="true"] {

        .c-btn--link {

            /* stylelint-disable-next-line */
            .c-btn__icon,
            .c-btn__text {
                color: important($skoda-table-button-link-color, true); /* [1] */
            }

        }

    }

}
