/* ------------------------------------*\
    #PAGE-WRAP
\*------------------------------------ */

// If `$page-wrap-width` was set to `none`, note that `calc-page-wrap-width()`
// will return `none` as well. It is not possible to use percentage values for
// the `max-width` of the variants (e.g. `max-width: percentage(10/12)`), since
// this will limit the content's width also on small screens, which is not the
// desired behavior.
$page-wrap-variants: (
    "medium": (
        max-width: get-page-wrap-width($page-wrap-variant-medium)
    ),
    "small": (
        max-width: get-page-wrap-width($page-wrap-variant-small)
    ),
    "xsmall": (
        max-width: get-page-wrap-width($page-wrap-variant-xsmall)
    ),
    "xxsmall": (
        max-width: get-page-wrap-width($page-wrap-variant-xxsmall)
    ),
) !default;

/**
 * [1] Add page wrap widths as CSS custom property so that it can be used in
 *     calculations (e.g. for the Float grid).
 * [2] Add variants, e.g. `.o-page-wrap--small`.
 */
:root {
    --page-wrap-width: #{$page-wrap-width}; /* [1] */
}

.o-page-wrap {
    @include page-wrap();
    @include responsive-property-map($page-wrap-variants, "&.o-page-wrap--"); /* [2] */
}
