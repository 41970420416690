/* ------------------------------------*\
   #NOTIFICATIONS (Settings)
\*------------------------------------ */

$notification-background-color:               $COLOR-BRAND-TERTIARY !default;
$notification-padding-top:                    $GLOBAL-SPACING-UNIT-SMALL !default;
$notification-padding-bottom:                 $notification-padding-top !default;

$notification-text-color:                     $COLOR-WHITE !default;
$notification-font-size:                      $FS-1 !default;
$notification-line-height:                    1.5 !default;

$notification-title-size:                     $FS0 !default;
$notification-title-color:                    $COLOR-WHITE !default;
$notification-title-spacing-bottom:           $GLOBAL-SPACING-UNIT-XSMALL !default;

$notification-status-icon-size:               xsmall !default;
$notification-status-icon-spacing-horizontal: $GLOBAL-SPACING-UNIT-XSMALL !default;

$notification-close-icon-size:                icon-size(xsmall) !default;

$notification-context-background-color:       $COLOR-SURFACE-SECONDARY !default;
$notification-context-border-width:           0 !default; // disable border

$notification-context-text-color:             $COLOR-BASE-TEXT !default;
$notification-context-title-color:            $notification-context-text-color !default;

$notification-button-styles-overwrites: (
    "background":         $BUTTON-GHOST-ON-DARK-SURFACE-BACKGROUND-COLOR,
    "text-color":         $BUTTON-GHOST-ON-DARK-FONT-COLOR,
    "text-color-hover":   null,
    "border-color":       $BUTTON-GHOST-ON-DARK-SURFACE-BORDER-COLOR,
    "border-color-hover": $BUTTON-GHOST-ON-DARK-SURFACE-BORDER-COLOR,
) !default;

$notification-status-themes-overwrites: (
    info: (
        "text-color":       $notification-text-color,
        "background-color": $notification-background-color,
    ),
    success: (
        "text-color":       $notification-text-color,
        "background-color": $notification-background-color,
        "icon-color":       map-deep-get($STATUS-THEMES, "success", "color"),
    ),
    warning: (
        "text-color":       $notification-text-color,
        "background-color": $notification-background-color,
        "icon-color":       map-deep-get($STATUS-THEMES, "warning", "color"),
    ),
    error: (
        "text-color":       $notification-text-color,
        "background-color": $notification-background-color,
        "icon-color":       map-deep-get($STATUS-THEMES, "error", "color"),
    ),
) !default;

$notification-context-status-themes-overwrites: (
    info: (
        "title-color": $notification-context-text-color,
        "icon-color":  null,
    ),
    success: (
        "title-color": $notification-context-text-color,
    ),
    warning: (
        "title-color": $notification-context-text-color,
        "icon-color":  map-deep-get($STATUS-THEMES, "warning", "color"),
    ),
    error: (
        "title-color": $notification-context-text-color,
    ),
) !default;

$notification-marketing-button-themes: (
    "background":       $BUTTON-SECONDARY-BACKGROUND-COLOR,
    "background-hover": $BUTTON-SECONDARY-BACKGROUND-COLOR-HOVER,
    "text-color":             $BUTTON-SECONDARY-TEXT-COLOR,
    "text-color-hover":       $BUTTON-SECONDARY-TEXT-COLOR-HOVER,
) !default;

$notification-marketing-themes-overwrites: (
    "1": (
        "title-color": map-deep-get($MARKETING-THEMES, "1", "text-color"),
        "buttons":     $notification-marketing-button-themes,
    ),
    "2": (
        "title-color": map-deep-get($MARKETING-THEMES, "2", "text-color"),
        "buttons":     $notification-marketing-button-themes,
    ),
) !default;
