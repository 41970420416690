/* ------------------------------------*\
    #INPUT (Scopes)
\*------------------------------------ */

/**
 * [1] Hide placeholder texts if floating labels are activated.
 * [2] When Floating Label spacing is bigger than `0`, add this space to
 *     the Checkbox or Radio Label to ensure enough space between them.
 * // @TODO: move to fallback layer
 * [3] Add fallback spacing when selector `.c-form-field--static` is not present.
*/

@if ($form-field-label-floating) {

    .c-input__input {

        /* stylelint-disable */
        form.is-js-on .c-form-field:not(.c-form-field--no-floating) & {  /* [1] */

            &::placeholder {
                color: transparent;
                -webkit-text-fill-color: transparent;
            }

            // Disabled state
            &:disabled::placeholder {
                color: important(transparent, true);
                -webkit-text-fill-color: important(transparent, true);
            }

        }
        /* stylelint-enable */

    }

}
