/* ------------------------------------*\
    #PRODUCT-INFO-SUMMARY
\*------------------------------------ */

$product-info-summary-breakpoint:                   s !default;
$product-info-summary-image-max-width:              175px !default;
$product-info-summary-title-spacing-bottom:         $GLOBAL-SPACING-UNIT-XSMALL !default;
$product-info-summary-title-font-size:              $FS4 !default;
$product-info-summary-title-font-weight:            $GLOBAL-FONT-WEIGHT-BOLD !default;
$product-info-summary-title-font-family:            null !default;
$product-info-summary-title-color:                  null !default;
$product-info-summary-subtitle-font-size:           $FS0 !default;
$product-info-summary-subtitle-color:               null !default;
$product-info-summary-subtitle-font-family:         null !default;
$product-info-summary-subtitle-font-weight:         null !default;
$product-info-summary-info-font-size:               $FS2 !default;
$product-info-summary-info-line-height:             none !default;
$product-info-summary-info-font-weight:             $GLOBAL-FONT-WEIGHT-BOLD !default;
$product-info-summary-info-font-family:             null !default;
$product-info-summary-info-color:                   null !default;
$product-info-summary-info-value-font-size:         null !default;
$product-info-summary-info-row-gap:                 null !default;
$product-info-summary-compact-spacing-horizontal:   $GLOBAL-SPACING-UNIT-XXSMALL !default;
$product-info-summary-compact-info-value-font-size: $FS3 !default;
$product-info-summary-item-spacing-horizontal: (
    null:                             $GLOBAL-SPACING-UNIT-LARGE,
    $product-info-summary-breakpoint: $GLOBAL-SPACING-UNIT-SMALL
) !default;

/**
 * [1] In compact mode we remove the bottom spacing from the info label.
 * [2] Pull the info element to the right.
 * [3] Change `flex-direction` to arrange items one below the other.
 */
.c-product-info-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;

    @include media-query("<=#{$product-info-summary-breakpoint}") {
        flex-direction: column; /* [3] */
        align-items: flex-start;
    }

    > * {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;

        &:not(:last-child) {
            @include responsive-property("margin-right", $product-info-summary-item-spacing-horizontal);
        }

    }

    &.c-product-info-summary--compact {

        .c-product-info-summary__img {
            display: none;
        }

        .c-product-info-summary__info {
            flex-direction: row;
            align-items: baseline;
            justify-content: flex-end;
        }

        .c-product-info-summary__info-value {
            @include responsive-font-size($product-info-summary-compact-info-value-font-size);
            margin-left: $product-info-summary-compact-spacing-horizontal;
        }

        .c-product-info-summary__info-label {
            margin-bottom: 0; /* [1] */
        }

        .c-product-info-summary__heading {
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
        }

        .c-product-info-summary__title {
            margin-right: $product-info-summary-compact-spacing-horizontal;
            margin-bottom: 0;
        }

    }

}

    .c-product-info-summary__img {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: $product-info-summary-image-max-width;

        @include media-query("<=#{$fold-out-breakpoint}") {
            display: none;
        }

    }

    .c-product-info-summary__heading {

        @include media-query("<=#{$product-info-summary-breakpoint}") {
            margin-right: auto;
            margin-left: 0;
        }

    }

    .c-product-info-summary__title {
        @include responsive-font-size($product-info-summary-title-font-size);
        display: block;
        margin-bottom: $product-info-summary-title-spacing-bottom;
        font-family: $product-info-summary-title-font-family;
        font-weight: $product-info-summary-title-font-weight;
        color: $product-info-summary-title-color;
    }

    .c-product-info-summary__subtitle {
        @include responsive-font-size($product-info-summary-subtitle-font-size);
        display: block;
        margin-bottom: 0;
        font-family: $product-info-summary-subtitle-font-family;
        font-weight: $product-info-summary-subtitle-font-weight;
        color: $product-info-summary-subtitle-color;

        @include media-query("<=#{$product-info-summary-breakpoint}") {
            display: none;
        }

    }

    .c-product-info-summary__info {
        @include responsive-font-size(
            $font-size:   $product-info-summary-info-font-size,
            $line-height: $product-info-summary-info-line-height
        );
        display: flex;
        flex-direction: column;
        row-gap: $product-info-summary-info-row-gap;
        margin-left: auto; /* [2] */
        font-family: $product-info-summary-info-font-family;
        font-weight: $product-info-summary-info-font-weight;
        text-align: right;
        color: $product-info-summary-info-color;

        @include media-query("<=#{$product-info-summary-breakpoint}") {
            margin-right: auto;
            margin-left: 0;
            text-align: left;
        }

        > *:last-child {
            margin-bottom: 0;
        }

    }

        .c-product-info-summary__info-value {
            @if ($product-info-summary-info-value-font-size) {
                @include responsive-font-size($product-info-summary-info-value-font-size);
            }
        }
