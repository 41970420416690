/* ------------------------------------*\
    #DROPDOWN-CONTAINER
\*------------------------------------ */

$dropdown-container-background-color:           $COLOR-BASE-BACKGROUND !default;
$dropdown-container-border-radius:              null !default;
$dropdown-container-border-color:               $COLOR-BLACK !default;
$dropdown-container-border-size:                $GLOBAL-BORDER-WIDTH !default;
$dropdown-container-drop-shadow:                $DROP-SHADOW-SMALL !default;
$dropdown-container-layer:                      z("interaction-layer") !default;
$dropdown-container-max-width:                  400px !default;
$dropdown-container-min-width:                  200px !default;
$dropdown-container-offset:                     $GLOBAL-SPACING-UNIT-SMALL !default;
$dropdown-container-spacing:                    $GLOBAL-SPACING-UNIT $GLOBAL-SPACING-UNIT-SMALL !default;

$dropdown-container-arrow-size:                 $GLOBAL-ARROW-SIZE !default;
$dropdown-container-has-arrow:                  true !default;

$dropdown-container-divider-color:              $GLOBAL-BORDER-COLOR !default;
$dropdown-container-divider-size:               $GLOBAL-BORDER-WIDTH !default;

$dropdown-container-item-spacing:               $GLOBAL-SPACING-UNIT !default;

$dropdown-container-container-placement-bottom: '[data-popper-placement^="bottom-"]' !default;
$dropdown-container-container-placement-top:    '[data-popper-placement^="top-"]' !default;

$dropdown-container-button-state-open:           '[aria-expanded="true"]' !default;
$dropdown-container-button-color-active:         null !default;

/**
 * [1] Conditionally display an arrow for the dropdown-container.
 * [2] Add open state styles to the button conditionally.
 * [3] Make the trigger wrapper behave like an inline element to track its
 *     collapsed box-dimensions via JavaScript.
 */

.c-dropdown-container {
    --dropdown-container-border-color: #{$dropdown-container-border-color};
    --dropdown-container-border-size: #{$dropdown-container-border-size};
    --dropdown-container-layer: #{$dropdown-container-layer};
    --dropdown-container-max-width: #{$dropdown-container-max-width};
    --dropdown-container-min-width: #{$dropdown-container-min-width};
    --js-dropdown-container-offset: #{$dropdown-container-offset};

    position: relative;
}

    .c-dropdown-container__arrow {

        @if not ($dropdown-container-has-arrow) { /* [1] */
            display: none;
        } @else {
            position: absolute;

            .c-dropdown-container__panel#{$dropdown-container-container-placement-top} & {
                @include triangle-extended(
                    $size:         $dropdown-container-arrow-size,
                    $color:        $dropdown-container-background-color,
                    $border-width: $dropdown-container-border-size,
                    $border-color: $dropdown-container-border-color
                );
            }

            .c-dropdown-container__panel#{$dropdown-container-container-placement-bottom} & {
                @include triangle-extended(
                    $direction:    bottom,
                    $size:         $dropdown-container-arrow-size,
                    $color:        $dropdown-container-background-color,
                    $border-width: $dropdown-container-border-size,
                    $border-color: $dropdown-container-border-color
                );
            }

        }

    }

    .c-dropdown-container__panel {
        @include transition($transition-property: "opacity, visibility");
        z-index: var(--dropdown-container-layer);
        display: flex;
        gap: $dropdown-container-item-spacing;
        flex-direction: column;
        min-width: var(--dropdown-container-min-width);
        max-width: var(--dropdown-container-max-width);
        padding: $dropdown-container-spacing;
        border: var(--dropdown-container-border-size) solid var(--dropdown-container-border-color);
        border-radius: $dropdown-container-border-radius;
        background-color: $dropdown-container-background-color;
        // @TODO: Account for multiple drop shadows
        filter: drop-shadow(#{$dropdown-container-drop-shadow});
        visibility: hidden;
        opacity: 0;

        &[aria-hidden="false"] {
            visibility: visible;
            opacity: 1;
        }

    }

    .c-dropdown-container__divider {
        width: 100%;
        height: $dropdown-container-divider-size;
        background-color: $dropdown-container-divider-color;
    }

    .c-dropdown-container__button {

        &#{$dropdown-container-button-state-open} {
            --button-text-color: #{$dropdown-container-button-color-active}; /* [2] */

            .c-dropdown-container__icon.c-dropdown-container__icon { /* [2] */
                color: #{$dropdown-container-button-color-active};
            }

        }

    }

    .c-dropdown-container__trigger-wrapper {
        display: inline-flex; /* [3] */
    }
