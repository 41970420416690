/* ------------------------------------*\
   #BACK-TO-TOP
\*------------------------------------ */

$back-to-top-bar-background-color:  $COLOR-PALE !default;
$back-to-top-bar-spacing-top:       $GLOBAL-SPACING-UNIT-SMALL !default;
$back-to-top-bar-spacing-bottom:    $GLOBAL-SPACING-UNIT-SMALL !default;
$back-to-top-icon:                  get-icon(semantic-up) !default;
$back-to-top-needs-page-wrap:       true !default;
$back-to-top-needs-page-text-align: right !default;

/**
 * [1] Flexible left or right alignment of the component.
 */
.c-back-to-top {
    @include transition();
    padding-top: if($back-to-top-bar-spacing-top, $back-to-top-bar-spacing-top, null);
    padding-bottom: if($back-to-top-bar-spacing-bottom, $back-to-top-bar-spacing-bottom, null);
    background-color: if($back-to-top-bar-background-color, $back-to-top-bar-background-color, null);
}

    .c-back-to-top__pagewrap {
        text-align: $back-to-top-needs-page-text-align; /* [1] */

        @if ($back-to-top-needs-page-wrap) {
            @include page-wrap();
        }

    }

    .c-back-to-top__icon::before {
        @include icon-font();
        content: $back-to-top-icon;
    }
