/* ------------------------------------*\
    #COMPARE-AND-SELECT
\*------------------------------------ */

$skoda-compare-and-select-border-hover:                        $CARD-PATTERN-BORDER-COLOR-HOVER !default;

$skoda-compare-and-select-indicator-background-selector:       "::before" !default;
$skoda-compare-and-select-indicator-foreground-selector:       "::after" !default;

$skoda-compare-and-select-indicator-offset:                    $GLOBAL-SPACING-UNIT-SMALL !default;

$skoda-compare-and-select-indicator-background-size:           $CARD-PATTERN-SELECT-INDICATOR-BACKGROUND-SIZE !default;
$skoda-compare-and-select-indicator-background-color:          $CARD-PATTERN-SELECT-INDICATOR-BACKGROUND-COLOR !default;
$skoda-compare-and-select-indicator-background-color-hover:    $CARD-PATTERN-SELECT-INDICATOR-BACKGROUND-COLOR-HOVER !default;
$skoda-compare-and-select-indicator-background-color-selected: $CARD-PATTERN-SELECT-INDICATOR-BACKGROUND-COLOR-SELECTED !default;

$skoda-compare-and-select-indicator-icon:                      get-icon($CARD-PATTERN-SELECT-INDICATOR-ICON) !default;
$skoda-compare-and-select-indicator-icon-size:                 icon-size($CARD-PATTERN-SELECT-INDICATOR-ICON-SIZE) !default;
$skoda-compare-and-select-indicator-icon-color:                $CARD-PATTERN-SELECT-INDICATOR-ICON-COLOR !default;

$skoda-compare-and-select-indicator-safe-space:                $skoda-compare-and-select-indicator-icon-size !default;


/**
 * [1] Ensure tile icon foreground and background use common position.
 * [2] Ensure that icon is centered.
 * [3] Create safe-space only for elements that are next to indicator.
 *     This can be an indicator title, label or title.
 * [4] Prevent collision with title elements and indicator.
 * [5] Apply safe-space to both sides to maintain horizontal alignment.
 * [6] Change visuals when toggle items (button, radio-button) is hovered
 *     to indicate that state changes.
 * [7] "Undo" selected state as compare & select items can be selected, but
 *     not toggled off directly (checkbox functionality), they can only be
 *     selected exclusively (radio-button functionality).
 * [8] Mimic tag by reusing it's settings via `@extend`.
 * [9} Ensure correct height without having the same markup as tag (`.c-tag__label`).
 */
.c-compare-and-select {

    &#{$skoda-compare-and-select-indicator-background-selector},
    &#{$skoda-compare-and-select-indicator-foreground-selector} { /* [1] */
        @include transition(
            $transition-property: background-color,
        );

        content: "";
        position: absolute;
        top: $skoda-compare-and-select-indicator-offset;
        right: $skoda-compare-and-select-indicator-offset;
        display: block;
        width: $skoda-compare-and-select-indicator-background-size;
        height: $skoda-compare-and-select-indicator-background-size;
    }

    &#{$skoda-compare-and-select-indicator-background-selector} {
        border-radius: 50%;
        background-color: $skoda-compare-and-select-indicator-background-color;
    }

    &#{$skoda-compare-and-select-indicator-foreground-selector} {
        @include icon-font($line-height: null);
        content: $skoda-compare-and-select-indicator-icon;
        font-size: $skoda-compare-and-select-indicator-icon-size;
        line-height: $skoda-compare-and-select-indicator-background-size; /* [2] */
        text-align: center; /* [2] */
        color: $skoda-compare-and-select-indicator-icon-color;
    }

    // Selected state
    &.c-compare-and-select--selected,
    .c-compare-and-select__hidden-input:checked + & {

        &#{$skoda-compare-and-select-indicator-background-selector} {
            background-color: $skoda-compare-and-select-indicator-background-color-selected;
        }

    }

    /* [6] */
    &:has(.c-compare-and-select__button:hover, .c-compare-and-select__radio:hover) {
        border-color: $skoda-compare-and-select-border-hover;

        &#{$skoda-compare-and-select-indicator-background-selector} {
            background-color: $skoda-compare-and-select-indicator-background-color-hover;
        }

        /* stylelint-disable-next-line selector-max-specificity */
        &.c-compare-and-select--selected,
        .c-compare-and-select__hidden-input:checked + & {

            &#{$skoda-compare-and-select-indicator-background-selector} {
                background-color: $skoda-compare-and-select-indicator-background-color-selected; /* [7] */
            }

        }


    }

}

    .c-compare-and-select__title {

        /* [3] */
        > :first-child {
            padding-right: $skoda-compare-and-select-indicator-safe-space; /* [4] */
            padding-left: $skoda-compare-and-select-indicator-safe-space; /* [5] */
        }

    }

    .c-compare-and-select__label {
        @extend .c-tag; /* [8] */ /* stylelint-disable-line scss/at-extend-no-missing-placeholder */
        line-height: 1; /* [9] */
    }
