/* ------------------------------------*\
    #PRODUCT-SEARCH
\*------------------------------------ */

$product-search-breakpoint:         m !default;
$product-search-gap:                $GLOBAL-SPACING-UNIT !default;
$product-search-sidebar-width:      300px !default;

/**
 * [1] Span header always across all columns.
 * [2] Initially hide sidebar below breakpoint. @TODO: toggle visibility
 */

.c-product-search {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    gap: $product-search-gap;
}

    .c-product-search--has-sidebar {
        @include media-query(">#{$product-search-breakpoint}") {
            grid-template-columns: $product-search-sidebar-width auto;
        }
    }

    .c-product-search__header,
    .c-product-search__aside,
    .c-product-search__footer {
        grid-column: 1 / -1; /* [1] */
    }

    .c-product-search__sidebar {
        display: none; /* [2] */


        .c-product-search--has-sidebar & {
            display: block; /* [2] */

            @include media-query("<=#{$product-search-breakpoint}") {
                display: none; /* [2] */
            }

        }

    }

    .c-product-search__results {
    }

    .c-product-search__aside {

        .c-product-search--has-sidebar & {
            @include media-query(">#{$product-search-breakpoint}") {
                display: none; /* [2] */
            }
        }

    }

    .c-product-search__footer {
    }
