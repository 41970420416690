// /* ------------------------------------*\
//     #ACCORDION
// \*------------------------------------ */

$skoda-accordion-title-color-hover: $COLOR-ON-SURFACE-700 !default;

/**
 * [1] Emulate interactive icon states. Mixin can not be used as the trigger
 *     is not the button itself.
 */
.c-accordion {

}

    .c-accordion__title-icon {
        @include button-icon-states(
            $context-selector: ".c-accordion__header",
            $focus-type:      "focus-within",
        );
    }

    .c-accordion__header {

        &:hover {

            .c-accordion__title-label-text {
                @include transition(
                    $transition-property: color,
                );
                color: $skoda-accordion-title-color-hover;
            }

        }

    }

    .c-accordion__content {

        &::before {
            content: none;
        }

    }
