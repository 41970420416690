// /* ------------------------------------*\
//     #FONT-FACE
// \*------------------------------------ */

$BASE-FONT:           "Comic Sans MS" !default;
$BASE-FONT-FALLBACKS: sans-serif !default;
$FONT-PATH:           "../fonts/fonts" !default;

$ICON-FONT-PATH:      "../fonts/icon-fonts" !default; // [1]

// Variable to be used throughout the project.
$BASE-FONT-FAMILY:    $BASE-FONT, $BASE-FONT-FALLBACKS !default;

/// Font face mixin.
///
/// Just use this mixin if you have self-hosted fonts in your projects. If you
/// are using a service such as Google Fonts or have system fonts included, you
/// don’t need this mixin and the above declared variables suffice.
///
/// @access public
///
/// @param {String} $font-name - Specifies which font family is used.
/// @param {String} $file-name [null] - Specifies the name of the font file.
///                                     If set, the file name will not be generated from
///                                     `$font-name` and `$face`.
/// @param {String} $file-extension [woff2] - Specifies the file extension of the font file.
/// @param {String} $path [$FONT-PATH] - Set the path to the font files.
/// @param {String} $face [null] - Controls which font file is to be taken in which font face.
/// @param {String | Number | List} $weight [normal] - Set the weight of the font (e.g. normal, bold, 400, 700).
///                                                    or the weight value range of a variable font (e.g.`400 700`).
/// @param {String | Number | List} $stretch [null] - Set the stretch value(s) of a variable font (e.g.`100% 130%`).
/// @param {String} $style [normal] - Set the style of the font (e.g. normal, italic).
/// @param {String} $format [woff2] - Specifies the format of the font file (e.g. `woff2`, `woff2-variations`).
/// @param {Boolean} $revision [false] - Set the query string to update cached font files.
/// @param {String} $display [swap] - Specifies how a font should be displayed when it is downloaded
///                                   and ready to use ([ auto | block | swap | fallback | optional ]).
///
/// @example
///     @include font-face(
///         $font-name: "custom-font",
///         $face:      "-regular",
///         $weight:    400,
///     );
///
///     // @font-face {
///     //     font-family: 'custom-font';
///     //     src: url('../fonts/fonts/custom-font-regular.woff2') format('woff2');
///     //     font-weight: 400;
///     //     font-style: normal;
///     //     font-display: swap;
///     // }
///
///     @include font-face(
///         $font-name: "custom-variable-font",
///         $file-name: "custom-variable-font",
///         $weight:    400 700,
///         $stretch:   100% 130%,
///         $format:    woff2-variations,
///     );
///
///     // @font-face {
///     //     font-family: "custom-variable-font";
///     //     src: url("../fonts/fonts/custom-variable-font.woff2") format("woff2-variations");
///     //     font-weight: 400 700;
///     //     font-stretch: 100% 130%;
///     //     font-style: normal;
///     //     font-display: swap;
///     // }
@mixin font-face(
    $font-name,
    $file-name: null,
    $file-extension: "woff2",
    $path: $FONT-PATH,
    $face: null,
    $weight: normal,
    $stretch: null,
    $style: normal,
    $format: "woff2",
    $revision: false,
    $display: swap,
) {

    // Set the query string to update cached font files.
    $_revision: if($revision, "?v=#{$revision}", null);

    $file: if($file-name, $file-name, #{$font-name}#{$face}) + ".#{$file-extension}";

    @font-face {
        font-family: $font-name;
        src: url("#{$path}/#{$file}#{$_revision}") format("#{$format}");
        font-weight: $weight;
        font-stretch: $stretch;
        font-style: $style;
        font-display: $display;
    }

}
