// /* ------------------------------------*\
//     #RANGE-SLIDER
// \*------------------------------------ */

$RANGE-SLIDER-TRACK-HEIGHT:                           2px !default;
$RANGE-SLIDER-TRACK-BACKGROUND-COLOR:                 $COLOR-INACTIVE !default;
$RANGE-SLIDER-TRACK-BACKGROUND-COLOR-DISABLED:        $RANGE-SLIDER-TRACK-BACKGROUND-COLOR !default;
$RANGE-SLIDER-TRACK-BORDER-RADIUS:                    10px !default;
$RANGE-SLIDER-TRACK-FILLED-BACKGROUND-COLOR:          $COLOR-BRAND !default;
$RANGE-SLIDER-TRACK-FILLED-BACKGROUND-COLOR-DISABLED: $RANGE-SLIDER-TRACK-FILLED-BACKGROUND-COLOR !default;

$RANGE-SLIDER-THUMB-SIZE:                             36px !default;
$RANGE-SLIDER-THUMB-HAS-BORDER:                       true !default;
$RANGE-SLIDER-THUMB-BORDER-COLOR:                     $COLOR-HIGHLIGHT !default;
$RANGE-SLIDER-THUMB-BORDER-COLOR-DISABLED:            $COLOR-INACTIVE !default;
$RANGE-SLIDER-THUMB-BORDER-WIDTH:                     1px !default;
$RANGE-SLIDER-THUMB-BORDER-RADIUS:                    50% !default;
$RANGE-SLIDER-THUMB-BACKGROUND-COLOR:                 $COLOR-WHITE !default;
$RANGE-SLIDER-THUMB-BACKGROUND-COLOR-DISABLED:        $RANGE-SLIDER-THUMB-BACKGROUND-COLOR !default;
$RANGE-SLIDER-THUMB-HAS-DOT:                          true !default;
$RANGE-SLIDER-THUMB-DOT-SIZE:                         14px !default;
$RANGE-SLIDER-THUMB-DOT-BACKGROUND-COLOR:             $COLOR-BRAND !default;
$RANGE-SLIDER-THUMB-DOT-BACKGROUND-COLOR-DISABLED:    $COLOR-INACTIVE !default;
