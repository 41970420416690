/* ------------------------------------*\
    #PRICE (Settings)
\*------------------------------------ */

$price-box-shadow:                       $GLOBAL-BOX-SHADOW !default;

$price-box-header-border-integrated:     1px solid $COLOR-BRAND !default;

$price-box-body-padding:                 $GLOBAL-SPACING-UNIT-SMALL !default;

$price-box-price-font-size:              map-get($FS1, "s") !default;
$price-box-price-font-size-small:        map-get($FS1, null) !default;

$price-box-discount-old-price-font-size: $FS0 !default;

$price-box-additional-info-font-size:    $FS-2 !default;
$price-box-additional-info-font-weight:  $GLOBAL-FONT-WEIGHT-REGULAR !default;
