/* ------------------------------------*\
    #CARDS-SLIDER
\*------------------------------------ */

@use "sass:math" as *;

$cards-slider-card-offset-vertical:            $CARD-SHADOW-BLUR-RADIUS !default;
$cards-slider-card-offset-horizontal:          $cards-slider-card-offset-vertical !default;
$cards-slider-card-gutter:                     $GLOBAL-SPACING-UNIT !default;
$cards-slider-selector-initialized:            ".is-initialized" !default;
$cards-slider-enable-overflow-fade:            false !default;
$cards-slider-overflow-fade-size:              10px !default;
$cards-slider-overflow-fade-color:             $COLOR-BASE-BACKGROUND !default;
$cards-slider-overflow-fade-color-alternative: map-get($BACKGROUND-UTILITIES, "alternative") !default;

/**
 * [1] The flickity slider used for this component creates an
 *     `overflow: hidden` which cuts-off the shadow of the cards. Adding the
 *     same amount of padding as the box-shadow spread of the cards
 *     compensates this.
 * [2] Set equal content heights for sliders cells.
 * [3] Reset default flickity next/prev button styles.
 * [4] Soft fade left and right of the slider to prevent a hard overflow/cutoff.
 * [5] Show shadow above slides.
 * [6] Compensate gutter.
 */
.c-cards-slider-container {
    @include transition($transition-property: opacity);

    opacity: 0;

    &#{$cards-slider-selector-initialized} {
        opacity: 1;
    }

    @if ($cards-slider-enable-overflow-fade) {

        // @TODO: extract into reusable code (mixin) for other sliders
        .flickity-viewport {

            /* [4] */
            &::before,
            &::after {
                content: "";
                position: absolute;
                z-index: 10; /* [5] */
                top: 0;
                display: block;
                width: $cards-slider-overflow-fade-size;
                height: 100%;
                pointer-events: none;
            }

            &::before {
                left: 0;
                background: linear-gradient(90deg, rgba($cards-slider-overflow-fade-color, 1) 0, rgba($cards-slider-overflow-fade-color, 0) 100%);
            }

            &::after {
                right: 0;
                background: linear-gradient(90deg, rgba($cards-slider-overflow-fade-color, 0) 0%, rgba($cards-slider-overflow-fade-color, 1) 100%);
            }

            [data-theme="alternative"] & {

                &::before {
                    background: linear-gradient(90deg, rgba($cards-slider-overflow-fade-color-alternative, 1) 0, rgba($cards-slider-overflow-fade-color-alternative, 0) 100%);
                }

                &::after {
                    background: linear-gradient(90deg, rgba($cards-slider-overflow-fade-color-alternative, 0) 0%, rgba($cards-slider-overflow-fade-color-alternative, 1) 100%);
                }

            }

        }

    }

}

.c-cards-slider {
    margin-left: -($cards-slider-card-gutter); /* [6] */

    &.flickity-resize {

        .c-cards-slider__item,
        .c-cards-slider__card-wrapper,
        .c-card {
            height: 100%; /* [2] */
        }

    }

}

    /**
     * On large screens, the cards are shown completely (4 default cards and 5
     * small cards). On smaller screens, the last visible card is always shown
     * half to indicate to the user that there are more cards to scroll to.
     */

    $card-count-breakpoints: (
        null: 4,
        "m":  3,
        "s":  2,
        "xs": 1,
    ) !default;

    .c-cards-slider__item {
        padding-left: $cards-slider-card-gutter;

        @each $breakpoint, $count in $card-count-breakpoints {
            $_width: percentage(div(1, $count));

            @if ($breakpoint == null) {
                width: $_width;
            } @else {
                @include media-query("<=#{$breakpoint}") {
                    width: $_width;
                }
            }

        }

    }

    .c-cards-slider__card-wrapper {
        padding: $cards-slider-card-offset-vertical $cards-slider-card-offset-horizontal; /* [1] */
    }
