// /* ------------------------------------*\
//     #FORM-FIELD
// \*------------------------------------ */

$skoda-form-field-label-color-focus:       $GLOBAL-INPUT-LABEL-COLOR-FOCUS !default;
$skoda-form-field-label-color-filled:      $GLOBAL-INPUT-LABEL-COLOR-ACTIVE !default;
$skoda-form-field-label-color-error-focus: $COLOR-ALERT !default;

/**
 * [1] Set different color for focus than active, which is combined in default.
 * [2] Adjust label color in filled state, for both static and floating labels.
 * [3] Prevent label from creating extra space above form-field.
 */
.c-form-field__label {

    .c-form-field.is-active & {
        color: $skoda-form-field-label-color-filled; /* [2] */
    }

    /* stylelint-disable-next-line selector-max-specificity */
    form.is-js-on .c-form-field:not(.c-form-field--inline):focus-within & {
        color: $skoda-form-field-label-color-focus; /* [1] */
    }

    /* stylelint-disable-next-line selector-max-specificity, selector-max-class */
    form.is-js-on .c-form-field.is-error:not(.c-form-field--inline):focus-within & {
        color: $skoda-form-field-label-color-error-focus;
    }

}
