/* ------------------------------------*\
    #SPACINGS
\*------------------------------------ */

/**
 * Margin and padding helper classes. Use these to tweak layout on a micro level.
 */

@each $property-namespace, $property in $spacing-properties {

    @each $direction-namespace, $direction-rules in $spacing-directions {

        @each $size-namespace, $size in $spacing-sizes {
            .u-#{$property-namespace}#{$direction-namespace}#{$size-namespace} {

                @each $direction in $direction-rules {
                    #{$property}#{$direction}: $size !important;
                }

            }

            // Responsive spacings
            @each $bp-name, $bp-value in $BREAKPOINTS {

                @include media-query("<=#{$bp-name}") {

                    .u-#{$property-namespace}#{$direction-namespace}#{$size-namespace}\@#{$bp-name} {

                        @each $direction in $direction-rules {
                            #{$property}#{$direction}: $size !important;
                        }

                    }

                }

            }

        }

    }

}
