/* ------------------------------------*\
    #ROUND
\*------------------------------------ */

/**
 * A utility class to make elements ‘round’.
 */

.u-round {
    border-radius: 50%;
}
