/* ------------------------------------*\
    #UPLOAD-ITEM
\*------------------------------------ */

// General styling
$upload-item-background-color:                       null !default;
$upload-item-padding:                                $GLOBAL-SPACING-UNIT !default;
$upload-item-padding-horizontal:                     $upload-item-padding !default;
$upload-item-padding-vertical:                       $upload-item-padding !default;
$upload-item-border-width:                           2px !default;
$upload-item-border-style:                           solid !default;
$upload-item-border-color:                           $COLOR-PALE !default;
$upload-item-border:                                 $upload-item-border-width $upload-item-border-style $upload-item-border-color !default;
$upload-item-border-radius:                          5px !default;

// Main icon
$upload-item-icon-breakpoint:                        xs !default;
$upload-item-icon-size:                              icon-size(medium) !default;
$upload-item-icon-color:                             $COLOR-BRAND !default;
$upload-item-icon-vertical-alignment:                stretch !default;
$upload-item-icon-spacing-right:                     $GLOBAL-SPACING-UNIT-SMALL !default;

// Title
$upload-item-title-font-size:                        $FS0 !default;
$upload-item-title-line-height:                      1 !default;
$upload-item-title-font-weight:                      $GLOBAL-FONT-WEIGHT-REGULAR !default;
$upload-item-title-color:                            null !default;
$upload-item-title-spacing-bottom:                   $GLOBAL-SPACING-UNIT-SMALL !default;

// Subtitle below progress bar
$upload-item-subtitle-font-size:                     $FS-1 !default;
$upload-item-subtitle-font-weight:                   null !default;
$upload-item-subtitle-line-height:                   1.5 !default;
$upload-item-subtitle-color:                         null !default;

// Progress bar
$upload-item-progress-spacing:                       $GLOBAL-SPACING-UNIT-SMALL !default;
$upload-item-progress-spacing-top:                   $upload-item-progress-spacing !default;
$upload-item-progress-spacing-bottom:                $upload-item-progress-spacing !default;

// Interaction icons
$upload-item-interaction-remove-focus:               true !default;
$upload-item-interaction-icon-pseudo-element:        "::before" !default;
$upload-item-interaction-icon-spacing-top:           null !default;
$upload-item-interaction-icon-spacing:               $GLOBAL-SPACING-UNIT-SMALL !default;
$upload-item-interaction-icon-spacing-at-breakpoint: $GLOBAL-SPACING-UNIT-XSMALL !default;
$upload-item-interaction-icon-size:                  icon-size(small) !default;
$upload-item-interaction-icon-color:                 $COLOR-BRAND !default;
$upload-item-interaction-icon-main:                  get-icon(semantic-search) !default;
$upload-item-interaction-icon-loading:               get-icon(semantic-close) !default;
$upload-item-interaction-icon-secondary:             get-icon(semantic-delete) !default;
$upload-item-interaction-icon-breakpoint:            s !default;

// Success state
$upload-item-success-status-icon:                    get-icon(semantic-success) !default;
$upload-item-success-status-icon-color:              $COLOR-SUCCESS !default;
$upload-item-success-message-color:                  $COLOR-SUCCESS !default;

// Error state
$upload-item-error-status-icon:                      get-icon(semantic-error) !default;
$upload-item-error-status-icon-color:                $COLOR-ALERT !default;
$upload-item-error-message-color:                    $COLOR-ALERT !default;

// To disable file icons use `true`
$upload-item-icon-is-hidden:                         false !default;

// Image
$upload-item-image-breakpoint:                       xs !default;
$upload-item-image-spacing:                          $GLOBAL-SPACING-UNIT-SMALL !default;
$upload-item-image-width: (
    null: 80px, // Magic number (image size default) based on collab with UXR
    "xs": 60px  // Magic number (image size small viewport) based on collab with UXR
) !default;

// Image link
$upload-item-image-link-focus-outline-width:         2px !default;
$upload-item-image-link-focus-outline-style:         $GLOBAL-FOCUS-STYLE !default;
$upload-item-image-link-focus-outline-color:         $GLOBAL-FOCUS-COLOR !default;
$upload-item-image-link-focus-outline-offset:        2px !default;

// In order to set different file icons based on a file type, you can use the
// map below to define the icon per file type individually per brand.
// The feature is disabled by default.
// To turn it on, set `$upload-item-enable-icon-types` to `true`.
$upload-item-enable-icon-types:                      false !default;
$upload-item-icon-types: (
    "pdf": get-icon(semantic-file)
) !default;

/**
 * [1] Always stretch the content to full-width.
 * [2] Break long file names into multiline.
 * [3] Don’t wrap icons into multiline.
 * [4] Hide file icon if `$upload-item-icon-is-hidden: true`.
 * [5] Place the image below on small viewports.
 * [6] Align items across the row.
 * [7] Make sure the image has proper dimensions.
 * [8] Remove link border for brands with border set.
 */
.c-upload-item {
    display: flex;
    align-items: $upload-item-icon-vertical-alignment;
    background-color: $upload-item-background-color;
    @if ($upload-item-padding > 0) {
        @include responsive-property(
            ("padding-top",    $upload-item-padding-vertical),
            ("padding-bottom", $upload-item-padding-vertical),
            ("padding-left",   $upload-item-padding-horizontal),
            ("padding-right",  $upload-item-padding-horizontal)
        );
    }
    @if ($upload-item-border != 0) {
        border: $upload-item-border;
    }
    @if ($upload-item-border-radius > 0) {
        border-radius: $upload-item-border-radius;
    }

    &.c-upload-item--preview {

        @include media-query("<=#{$upload-item-image-breakpoint}") {
            flex-direction: column-reverse; /* [5] */
            align-items: initial; /* [6] */
        }

    }

}

    .c-upload-item__icon {
        @include font-size(
            $font-size:   $upload-item-icon-size,
            $line-height: 1
        );
        margin-right: $upload-item-icon-spacing-right;
        color: $upload-item-icon-color;

        @include media-query("<=#{$upload-item-icon-breakpoint}") {
            display: none;
        }
    }

    .c-upload-item__content {
        flex-grow: 1; /* [1] */
        flex-shrink: 1;
        flex-basis: auto;
    }

    .c-upload-item__title-row {
        display: flex;
        justify-content: space-between;
    }

    .c-upload-item__title {
        @include responsive-font-size(
            $font-size:   $upload-item-title-font-size,
            $line-height: $upload-item-title-line-height,
        );
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        margin-bottom: $upload-item-title-spacing-bottom;
        font-weight: $upload-item-title-font-weight;
        word-break: break-all; /* [2] */
        color: $upload-item-title-color;
    }

    .c-upload-item__progress {
        display: none;
        margin-top: $upload-item-progress-spacing-top;
        margin-bottom: $upload-item-progress-spacing-bottom;

        .c-upload-item.is-loading &,
        .c-upload-item.is-success &,
        .c-upload-item.is-error & {
            display: block;
        }

    }

    .c-upload-item__subtitle-row {
        @include responsive-font-size(
            $font-size:   $upload-item-subtitle-font-size,
            $line-height: $upload-item-subtitle-line-height,
        );
        margin-bottom: 0;
        font-weight: $upload-item-subtitle-font-weight;
        color: $upload-item-subtitle-color;
    }

    .c-upload-item__interaction-icons {
        display: inline-flex;
        flex-grow: 0;
        flex-shrink: 0; /* [3] */
        flex-basis: auto;
        margin-top: $upload-item-interaction-icon-spacing-top;
        margin-left: $upload-item-interaction-icon-spacing;

        @include media-query("<=#{$upload-item-interaction-icon-breakpoint}") {
            margin-left: ($upload-item-interaction-icon-spacing-at-breakpoint);
        }
    }

    .c-upload-item__interaction-icon,
    .c-upload-item__status-icon {
        @include reset-button(
            $remove-focus: $upload-item-interaction-remove-focus,
        );
        display: inline-flex;

        &#{$upload-item-interaction-icon-pseudo-element} {
            @include icon-font();
            @include font-size(
                $font-size:   $upload-item-interaction-icon-size,
                $line-height: false
            );
            color: $upload-item-interaction-icon-color;
        }


        &:not(:first-child) {
            margin-left: $upload-item-interaction-icon-spacing;

            @include media-query("<=#{$upload-item-interaction-icon-breakpoint}") {
                margin-left: ($upload-item-interaction-icon-spacing-at-breakpoint);
            }

        }

    }

    .c-upload-item__interaction-icon {
        text-decoration: none;
    }

    .c-upload-item__interaction-icon--main {

        .c-upload-item.is-error &,
        .c-upload-item.is-success & {
            display: none;
        }

        &#{$upload-item-interaction-icon-pseudo-element} {
            content: $upload-item-interaction-icon-main;

            .c-upload-item.is-loading & {
                content: $upload-item-interaction-icon-loading;
            }

        }

    }

    .c-upload-item__interaction-icon--secondary {

        .c-upload-item.is-loading &,
        .c-upload-item.is-error &,
        .c-upload-item.is-success & {
            display: none;
        }

        &#{$upload-item-interaction-icon-pseudo-element} {
            content: $upload-item-interaction-icon-secondary;
        }

    }

    .c-upload-item__status-icon {
        display: none;

        .c-upload-item.is-success & {
            display: inline-flex;

            &#{$upload-item-interaction-icon-pseudo-element} {
                content: $upload-item-success-status-icon;
                color: $upload-item-success-status-icon-color;
            }

        }

        .c-upload-item.is-error & {
            display: inline-flex;

            &#{$upload-item-interaction-icon-pseudo-element} {
                content: $upload-item-error-status-icon;
                color: $upload-item-error-status-icon-color;
            }

        }

    }

    .c-upload-item__filesize-progress {
        display: none;

        .c-upload-item.is-loading &,
        .c-upload-item.is-success & {
            display: inline;
        }

    }

    .c-upload-item__filesize {

        .c-upload-item.is-error & {
            display: none;
        }

    }

    .c-upload-item__message {
        display: block;

        .c-upload-item.is-success & {

            &,
            a {
                color: $upload-item-success-message-color;
            }

        }

        .c-upload-item.is-error & {

            &,
            a {
                color: $upload-item-error-message-color;
            }

        }

    }

    .c-upload-item__image-wrapper {
        margin-right: $upload-item-image-spacing;

        @include media-query("<=#{$upload-item-image-breakpoint}") {
            margin-top: $upload-item-image-spacing;
            margin-right: 0;
        }

    }

    .c-upload-item__image {
        @include responsive-property( /* [7] */
            ("width", $upload-item-image-width),
        );
    }

    .c-upload-item__image-link {
        display: inline-block;
        border: 0; /* [8] */
        text-decoration: none;
        text-align: center;

        &:focus-visible {
            @include focus-styles(
                $outline-width:  $upload-item-image-link-focus-outline-width,
                $outline-style:  $upload-item-image-link-focus-outline-style,
                $outline-color:  $upload-item-image-link-focus-outline-color,
                $outline-offset: $upload-item-image-link-focus-outline-offset,
            );
        }

    }

@if ($upload-item-icon-is-hidden) { /* [4] */
    .c-upload-item__icon {
        display: none;
    }
}

/**
 * Switch file icon by file types
 */
@if ($upload-item-enable-icon-types) {
    .c-upload-item__icon {

        @each $icontype, $icon in $upload-item-icon-types {

            &[data-filetype="#{$icontype}"]::before {
                content: #{$icon};
            }

        }

    }

}
