/* ------------------------------------*\
    #MEDIA-TEASER
\*------------------------------------ */

$skoda-media-teaser-border-radius:        $GLOBAL-BORDER-RADIUS !default;

$skoda-media-teaser-pretitle-font-weight: $CARD-PATTERN-SUBTITLE-WEIGHT !default;

$skoda-media-teaser-title-font-size:      $FS2 !default;
$skoda-media-teaser-title-font-weight:    $CARD-PATTERN-TITLE-WEIGHT !default;
$skoda-media-teaser-title-color:          $CARD-PATTERN-TITLE-COLOR !default;

/*
 * [1] Set border radius for media like images and video.
 * [2] If media teaser has a marketing theme, set border radius for the whole
 *     component and remove from included media because of the background color.
 */

.c-media-teaser {
}

    .c-media-teaser__media { /* [1] */
        overflow: hidden;

        > * {
            border-radius: $skoda-media-teaser-border-radius;
        }
    }

    .c-media-teaser__pretitle {
        font-weight: $skoda-media-teaser-pretitle-font-weight;
    }

    .c-media-teaser__title {
        @include responsive-font-size(
            $font-size: $skoda-media-teaser-title-font-size,
        );
        font-weight: $skoda-media-teaser-title-font-weight;
        color: $skoda-media-teaser-title-color;
    }

.c-media-teaser[data-marketing-theme] { /* [2] */
    overflow: hidden;
    border-radius: $skoda-media-teaser-border-radius;

    .c-media-teaser__media > * {
        border-radius: 0;
    }
}
