/* ------------------------------------*\
    #ORDERED-LIST
\*------------------------------------ */

// The nested list indentation is set to `$GLOBAL-SPACING-UNIT` accommodate for the negative `li` marker offset.
$ordered-list-indentation:           $GLOBAL-SPACING-UNIT-LARGE !default;
$ordered-list-item-spacing-vertical: $GLOBAL-SPACING-UNIT-SMALL !default;
$ordered-list-item-counter-style:    decimal !default;
$ordered-list-item-counter-name:     li !default;
$ordered-list-item-padding:          $GLOBAL-SPACING-UNIT-XSMALL !default;

.c-ol {

    &,
    ol {
        counter-reset: $ordered-list-item-counter-name;
        list-style-type: $ordered-list-item-counter-style;

        > li {
            margin-bottom: $ordered-list-item-spacing-vertical;
            padding-left: $ordered-list-item-padding;

            /* stylelint-disable selector-max-combinators, selector-max-compound-selectors, selector-max-type */
            > ol {
                margin-top: $ordered-list-item-spacing-vertical;
                margin-left: $ordered-list-indentation;
            }
            /* stylelint-enable */

        }

    }

}
