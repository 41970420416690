// /* ------------------------------------*\
//     #HINT (Settings)
// \*------------------------------------ */

$hint-text-color:             $COLOR-WHITE !default;
$hint-background-color:       $COLOR-BASE !default;
$hint-background-color-hover: $COLOR-ON-SURFACE-700 !default;
$hint-box-shadow-hover:       false !default;

$hint-min-size:               16px !default;
$hint-min-text-size:          map-get($FS-2, null) !default;
$hint-max-text-size:          map-get($FS-1, null) !default;
$hint-padding-horizontal:     0.3em !default;

$hint-border:                 false !default;
$hint-border-radius:          $hint-min-size !default;
