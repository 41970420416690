/* ------------------------------------*\
    #GUIDING-LINE
\*------------------------------------ */

$guiding-line-enabled:                   false !default;

$guiding-line-breakpoint:                s !default;
$guiding-line-width:                     2px !default;
$guiding-line-spacing-bottom:            $GLOBAL-SPACING-UNIT-SMALL !default;
$guiding-line-border-radius:             3px !default;

$guiding-line-color:                     $COLOR-BASE !default;
$guiding-line-alternative-color:         $COLOR-WHITE !default;

$guiding-line-animation-duration:        3s !default;
$guiding-line-animation-timing-function: cubic-bezier(0.14, 1.12, 0.67, 0.99) !default;

$guiding-line-label-spacing-bottom:      $GLOBAL-SPACING-UNIT-LARGE !default;
$guiding-line-label-font-size:           $FS-1 !default;
$guiding-line-label-color:               $COLOR-BASE-TEXT !default;

$guiding-line-height: (
    null:                     $GLOBAL-SPACING-UNIT-XLARGE,
    $guiding-line-breakpoint: $GLOBAL-SPACING-UNIT-LARGE
) !default;

/**
 * [1] Border radius of the line container.
 * [2] Border radius of the line.
*/

@if ($guiding-line-enabled) {

    .c-guiding-line {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

        .c-guiding-line__line {
            @include responsive-property("height", $guiding-line-height);
            position: relative;
            width: $guiding-line-width;
            overflow: hidden;
            margin-bottom: $guiding-line-spacing-bottom;
            border-radius: $guiding-line-border-radius; /* [1] */

            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                border-radius: $guiding-line-border-radius; /* [2] */
                background: $guiding-line-color;
                animation: $guiding-line-animation-duration $guiding-line-animation-timing-function 0s infinite normal none running guiding-line-animation;
            }
        }

        .c-guiding-line__label {
            @include responsive-font-size(
                $font-size: $guiding-line-label-font-size
            );
            margin-bottom: $guiding-line-label-spacing-bottom;
            color: $guiding-line-label-color;
        }

    @if ($guiding-line-alternative-color) {
        .c-guiding-line--alternative {

            .c-guiding-line__line::before {
                background: $guiding-line-alternative-color;
            }
        }
    }

    @keyframes guiding-line-animation {
        0% {
            transform: translateY(-200%);
        }

        50% {
            transform: translateY(-100%);
        }

        100% {
            transform: translateY(100%);
        }
    }

} @else {

    .c-guiding-line {
        display: none;
    }

}
