// /*------------------------------------*\
//     #SUPPORTS-HAS-SELECTOR
// \*------------------------------------*/

/// Test if the `:has` selector is supported.
///
/// @content The mixin wraps the passed declarations in a supports directive.
@mixin supports-has-selector() {
    @supports selector(:has(.support)) {
        @content;
    }
}

/// Flipped version of the previous mixin to test if a browser does NOT support
/// `:has` selector. (https://caniuse.com/css-has)
///
/// @content The mixin wraps the passed declarations in a supports directive.
@mixin supports-not-has-selector() {
    @supports not selector(:has(.support)) {
        @content;
    }
}
