/* ------------------------------------*\
    #HINT
\*------------------------------------ */
@use "sass:math" as *;

/**
 * This component extends the standard `<sup>` HTML element with the
 * possibility of clicking/hovering over it.
 */

$hint-text-color:             $COLOR-BRAND !default;
$hint-text-color-hover:       $hint-text-color !default;
$hint-background-color:       null !default;
$hint-background-color-hover: null !default;
$hint-text-size:              $FS-2 !default;
$hint-font-weight:            $GLOBAL-FONT-WEIGHT-REGULAR !default;
$hint-min-size:               14px !default;
$hint-offset-vertical:        0 !default;
$hint-spacing-horizontal:     0.3em !default;
$hint-border-width:           1px !default;
$hint-border-style:           solid !default;
$hint-border-color:           currentColor !default;
$hint-border-color-hover:     currentColor !default;
$hint-border:                 $hint-border-width $hint-border-style $hint-border-color !default;
$hint-border-radius:          null !default;
$hint-box-shadow:             null !default;
$hint-box-shadow-hover:       0 0 0 1px currentColor !default;
$hint-transition:             null !default;

// The safe-space should be treated as a read-only variable.
// It is set to prevent content collision with the border.
$hint-safe-space:             1px !default;

$hint-padding-horizontal:     $hint-safe-space !default;

// Get the base font-size from the chosen font-size map.
$hint-min-text-size:          map-get($hint-text-size, null) !default;
$hint-max-text-size:          ($hint-min-text-size * 1.4) !default;
$hint-optimum-text-size:      div($hint-min-text-size, $GLOBAL-FONT-SIZE) * 1em !default;
// This is probably where you gonna start to hate me. But let me explain:
// We set the base font (in px) as the minimum we want as the font-size.
// We recommend to make it grow to its relative counter part.
// We set the maximum font-size to the 1.4-factor of the base, as the
// hint should not outgrow proportionally.
$hint-fluid-font-size:        unquote('clamp(#{$hint-min-text-size}, #{$hint-optimum-text-size}, #{$hint-max-text-size})') !default; /* stylelint-disable-line */
// Calculate a relative min-size based on the base-font size of the hint.
$hint-relative-min-size:      #{div($hint-min-size, $hint-min-text-size) * 1em} !default;

$hint-themes: (
    "brand": (
        "hint-color":       inherit,
        "hint-color-hover": inherit,
    )
) !default;

/**
 * [1] Set a minimum size for the hint relative to its fluid font-size.
 *     So that it can grow equally. Also set a safe space to avoid collision
 *     of content and border.
 * [2] Offset the hint relatively to its superscript position.
 * [3] Center the text vertically in the box via `line-height`.
 * [4] Align the hint vertically in the font’s superscript position.
 * [5] Inherit all font and color related styles and use `!important` to
 *     prevent overriding of the text color on `:hover` or `[data-theme]` scope.
 * [6] Try to use linear numbers instead of old figures to make alignment easier.
 * [7] Enable spacing for pill-shaped hint when content increases.
 * [8] Make sure the hint content fills the entire wrapper.
 * [9] Enable a fully clickable area.
 * [10] Enable hover and focus state styles only if the inner element is a link,
 *      button or is focusable. `:has()` has currently limited browser support.
 *      Therefore we duplicate the CSS rules via a mixin to avoid “invalid” CSS
 *      selectors for browsers that do not support `:has()`.
 */
.c-hint {
    top: $hint-offset-vertical; /* [2] */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: $hint-relative-min-size; /* [1] */
    min-height: $hint-relative-min-size; /* [1] */
    margin-right: $hint-spacing-horizontal;
    margin-left: $hint-spacing-horizontal;
    padding-top: $hint-safe-space; /* [1] */
    padding-bottom: $hint-safe-space; /* [1] */
    padding-left: $hint-padding-horizontal; /* [7] */
    padding-right: $hint-padding-horizontal; /* [7] */
    border: if($hint-border, $hint-border, null);
    border-radius: $hint-border-radius;
    font-size: $hint-fluid-font-size;
    font-weight: $hint-font-weight;
    line-height: important(1, true); /* [3] */
    text-align: center;
    vertical-align: super; /* [4] */
    background-color: var(--hint-background-color, $hint-background-color);
    color: var(--hint-text-color, $hint-text-color);
    box-shadow: if($hint-box-shadow, $hint-box-shadow, null);
    transition: $hint-transition;

    @include vary($loop: $hint-themes) {
        color: vary-get("hint-color");

        &:has(a:hover, button:hover) {
            color: vary-get("hint-color-hover"); /* [10] */
        }

        &:focus-within {
            color: vary-get("hint-color-hover"); /* [10] */
        }

    }

    /// Mixin for shared hover/focus styles
    @mixin _hint-hover() { /* [10] */
        border-color: $hint-border-color-hover; /* [5] */
        background-color: $hint-background-color-hover;
        color: var(--hint-text-color-hover, $hint-text-color-hover);
        box-shadow: if($hint-box-shadow-hover, $hint-box-shadow-hover, null);
    }

    &:has(a:hover, button:hover) {
        @include _hint-hover(); /* [10] */
    }

    &:focus-within {
        @include _hint-hover(); /* [10] */
    }

    > * {
        @include reset-button();
        margin-top: -($hint-safe-space); /* [8] */
        margin-right: -($hint-padding-horizontal); /* [8] */
        margin-bottom: -($hint-safe-space); /* [8] */
        margin-left: -($hint-padding-horizontal); /* [8] */
        padding-top: $hint-safe-space; /* [1] */
        padding-right: $hint-padding-horizontal; /* [7] */
        padding-bottom: $hint-safe-space; /* [1] */
        padding-left: $hint-padding-horizontal; /* [7] */
        font-size: important(inherit, true); /* [5] */
        font-weight: important(inherit, true); /* [5] */
        line-height: important(inherit, true); /* [5] */
        text-decoration: important(none, true); /* [5] */
        font-feature-settings: "lnum" 1; /* [6] */
        color: important(inherit, true); /* [5] */
    }

    > a,
    > button {

        &::after { /* [9] */
            content: "";
            position: absolute;
            top: -($hint-safe-space);
            left: -($hint-padding-horizontal);
            min-width: $hint-relative-min-size;
            min-height: $hint-relative-min-size;
            border-radius: $hint-border-radius;
        }

    }

}
