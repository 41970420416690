/* ------------------------------------*\
    #FORMS
\*------------------------------------ */

$GLOBAL-INPUT-HEIGHT:                         48px !default;
$GLOBAL-INPUT-PADDING-HORIZONTAL:             $GLOBAL-SPACING-UNIT-SMALL !default;
$GLOBAL-INPUT-BACKGROUND:                     $COLOR-WHITE !default;
$GLOBAL-INPUT-BACKGROUND-DISABLED:            $COLOR-PALE !default;
$GLOBAL-INPUT-BACKGROUND-READONLY:            $COLOR-WHITE !default;
$GLOBAL-INPUT-BORDER-BOTTOM-ONLY:             false !default;
$GLOBAL-INPUT-BORDER-WIDTH:                   1px !default;
$GLOBAL-INPUT-BORDER-WIDTH-FOCUS:             $GLOBAL-INPUT-BORDER-WIDTH !default;
$GLOBAL-INPUT-BORDER-STYLE:                   solid !default;
$GLOBAL-INPUT-BORDER-COLOR:                   $COLOR-SUBTLE !default;
$GLOBAL-INPUT-BORDER:                         $GLOBAL-INPUT-BORDER-WIDTH $GLOBAL-INPUT-BORDER-STYLE $GLOBAL-INPUT-BORDER-COLOR !default;
$GLOBAL-INPUT-BORDER-COLOR-HOVER:             $COLOR-HIGHLIGHT !default;
$GLOBAL-INPUT-BORDER-COLOR-FOCUS:             $COLOR-HIGHLIGHT !default;
$GLOBAL-INPUT-BORDER-COLOR-ERROR:             $COLOR-ALERT !default;
$GLOBAL-INPUT-BORDER-COLOR-ERROR-HOVER:       $GLOBAL-INPUT-BORDER-COLOR-ERROR !default;
$GLOBAL-INPUT-BORDER-COLOR-SUCCESS:           $COLOR-SUCCESS !default;
$GLOBAL-INPUT-BORDER-COLOR-SUCCESS-HOVER:     $GLOBAL-INPUT-BORDER-COLOR-SUCCESS !default;
$GLOBAL-INPUT-BORDER-COLOR-DISABLED:          $COLOR-INACTIVE !default;
$GLOBAL-INPUT-BORDER-COLOR-READONLY:          $COLOR-INACTIVE !default;
$GLOBAL-INPUT-BORDER-RADIUS:                  0 !default;
$GLOBAL-INPUT-FONT-FAMILY:                    $BASE-FONT-FAMILY !default;
$GLOBAL-INPUT-FONT-SIZE:                      $FS0 !default;
$GLOBAL-INPUT-FONT-WEIGHT:                    $GLOBAL-FONT-WEIGHT-BOLD !default;
$GLOBAL-INPUT-FONT-COLOR:                     $COLOR-BASE-TEXT !default;
$GLOBAL-INPUT-FONT-COLOR-ERROR:               $COLOR-BASE-TEXT !default;
$GLOBAL-INPUT-FONT-COLOR-SUCCESS:             $COLOR-BASE-TEXT !default;
$GLOBAL-INPUT-FONT-COLOR-DISABLED:            $COLOR-INACTIVE !default;
$GLOBAL-INPUT-FONT-COLOR-READONLY:            $COLOR-INACTIVE !default;
$GLOBAL-INPUT-FONT-COLOR-PLACEHOLDER:         $COLOR-INACTIVE !default;
$GLOBAL-INPUT-FONT-WEIGHT-PLACEHOLDER:        $GLOBAL-FONT-WEIGHT-REGULAR !default;

$GLOBAL-INPUT-LABEL-SIZE:                     $FS-1 !default;
$GLOBAL-INPUT-LABEL-FONT-WEIGHT:              null !default;
$GLOBAL-INPUT-LABEL-COLOR:                    $COLOR-BASE-TEXT !default;
$GLOBAL-INPUT-LABEL-COLOR-HOVER:              null !default;
$GLOBAL-INPUT-LABEL-COLOR-SUCCESS:            $GLOBAL-INPUT-LABEL-COLOR !default;
$GLOBAL-INPUT-LABEL-COLOR-ACTIVE:             $GLOBAL-INPUT-LABEL-COLOR !default;
$GLOBAL-INPUT-LABEL-COLOR-FOCUS:              null !default;
$GLOBAL-INPUT-LABEL-COLOR-ERROR:              $GLOBAL-INPUT-LABEL-COLOR !default;
$GLOBAL-INPUT-LABEL-COLOR-DISABLED:           $GLOBAL-INPUT-LABEL-COLOR !default;
$GLOBAL-INPUT-LABEL-COLOR-READONLY:           $GLOBAL-INPUT-LABEL-COLOR !default;

$GLOBAL-INPUT-TEXTAREA-PADDING-VERTICAL:      $GLOBAL-SPACING-UNIT-SMALL !default;
$GLOBAL-INPUT-TEXTAREA-PADDING-HORIZONTAL:    $GLOBAL-SPACING-UNIT-SMALL !default;
$GLOBAL-INPUT-TEXTAREA-FONT-SIZE:             $GLOBAL-INPUT-FONT-SIZE !default;
$GLOBAL-INPUT-TEXTAREA-LINE-HEIGHT:           $GLOBAL-LINE-HEIGHT !default;
$GLOBAL-INPUT-TEXTAREA-BORDER-BOTTOM-ONLY:    $GLOBAL-INPUT-BORDER-BOTTOM-ONLY !default;

$GLOBAL-INPUT-ADDON-PADDING-HORIZONTAL:       $GLOBAL-SPACING-UNIT-XSMALL !default;
$GLOBAL-INPUT-ADDON-FONT-SIZE:                $GLOBAL-INPUT-FONT-SIZE !default;
$GLOBAL-INPUT-ADDON-FONT-COLOR:               $COLOR-BASE-TEXT !default;
$GLOBAL-INPUT-ADDON-FONT-COLOR-NO-BG:         $COLOR-BRAND !default;
$GLOBAL-INPUT-ADDON-FONT-COLOR-ERROR:         $GLOBAL-INPUT-ADDON-FONT-COLOR !default;
$GLOBAL-INPUT-ADDON-FONT-COLOR-SUCCESS:       $GLOBAL-INPUT-ADDON-FONT-COLOR !default;
$GLOBAL-INPUT-ADDON-FONT-COLOR-NO-BG-ERROR:   $COLOR-ALERT !default;
$GLOBAL-INPUT-ADDON-FONT-COLOR-NO-BG-SUCCESS: $COLOR-SUCCESS !default;
$GLOBAL-INPUT-ADDON-FONT-COLOR-DISABLED:      rgba($COLOR-BASE-TEXT, 0.5) !default;
$GLOBAL-INPUT-ADDON-FONT-COLOR-READONLY:      $GLOBAL-INPUT-FONT-COLOR-READONLY !default;
$GLOBAL-INPUT-ADDON-BACKGROUND:               $COLOR-PALE !default;
$GLOBAL-INPUT-ADDON-BACKGROUND-DISABLED:      $COLOR-INACTIVE !default;
$GLOBAL-INPUT-ADDON-BACKGROUND-READONLY:      $COLOR-INACTIVE !default;
// Cannot use the `icon-size()` function here, since it's not available in
// settings layer, so we assign just the size parameter here and use this in
// the `icon-size()` function call later on in components layer.
$GLOBAL-INPUT-ADDON-ICON-SIZE:                small !default;
$GLOBAL-INPUT-ADDON-FAKE-LINE-WIDTH:          4px !default;

$GLOBAL-INPUT-SELECT-ICON-SIZE:               $GLOBAL-INPUT-ADDON-ICON-SIZE !default;
$GLOBAL-INPUT-OPTION-COLOR:                   $COLOR-BASE-TEXT !default;
$GLOBAL-INPUT-OPTION-COLOR-DISABLED:          rgba($COLOR-BASE-TEXT, 0.5) !default;

$GLOBAL-INPUT-COUNTER-FONT-SIZE:              $FS-2 !default;
$GLOBAL-INPUT-COUNTER-FONT-WEIGHT:            $GLOBAL-FONT-WEIGHT-REGULAR !default;
// To remain relative to the spacing of a brand, a spacing unit was divided.
// (We would probably need an even smaller spacing variable here.)
$GLOBAL-INPUT-COUNTER-SPACING-TOP:            ($GLOBAL-SPACING-UNIT-XXSMALL * 0.5) !default;
// We are using `rem` because the spacing should depend on the base font size
// and not the global spacing
$GLOBAL-INPUT-COUNTER-INPUT-SPACING-BOTTOM:   1rem !default;
$GLOBAL-INPUT-COUNTER-COLOR:                  $COLOR-SUBTLE !default;
$GLOBAL-INPUT-COUNTER-COLOR-ALERT:            $COLOR-ALERT !default;

$GLOBAL-FIELDSET-ITEM-SPACING:                $GLOBAL-SPACING-UNIT !default;
