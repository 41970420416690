// /* ------------------------------------*\
//     #SITE-NAV
// \*------------------------------------ */

// Since the breakpoint where the nav can break into a “burger”-navigation
// has to be controllable via the markup, we have to make the class-selector
// generation dynamic. Because this affects a lot of selectors, we provide a
// local mixin to prevent multiple code duplications of Sass loops and the same
// media-queries all over the place.
// By passing the `$pos` parameter, the mixin adds the media query `above` or
// `below` the breakpoint.
//
// This does not work with nested selector referencing parent (&).
// INVALID example:
// .foo {
//     .bar & {
//         @include site-nav-breakpoint("above") {
//             ...
//         }
//     }
// }
@mixin site-nav-breakpoint($pos) {

    $pos: if($pos == "below", "<=", ">");

    // Use default breakpoint only if no other `.c-site-nav--break@<BREAKPOINT>`
    // class is applied to the `.c-site-nav` element in the markup.
    .c-site-nav:not([class*="c-site-nav--break@"]) & {

        @include media-query("#{$pos}#{$site-nav-height-breakpoint}") {
            @content;
        }

    }

    // Generate modifier classes to be able to control the
    // breakpoint of the `.c-site-nav` element in the markup
    // via `.c-site-nav--break@<BREAKPOINT>` classes.
    @each $alias, $value in $BREAKPOINTS {

        // Generate respective media-query.
        @include media-query("#{$pos}#{$alias}") {

            // Generate respective selector.
            .c-site-nav.c-site-nav--break\@#{$alias} & {
                @content;
            }

        }

    }

    // Modifier class to suppress all applied CSS styles from the custom
    // breakpoint `below` and force the CSS styles from the custom breakpoint
    // `above` in all viewports. Can be controlled via `.c-site-nav--break@none`
    // class on the `.c-site-nav` element in the markup.
    @if ($pos == ">") {

        .c-site-nav.c-site-nav--break\@none & {
            @content;
        }

    }

}
