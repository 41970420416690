/* ------------------------------------*\
    #TIPPYJS
\*------------------------------------ */
@use "sass:math" as *;

/**
 * This is a custom adaptation of the Tippy.js Sass file to make it
 * themable for other brands.
 */

$tippy-max-width:           320px !default;
$tippy-max-height:          300px !default;
$tippy-padding-vertical:    $GLOBAL-SPACING-UNIT-SMALL !default;
$tippy-padding-horizontal:  $GLOBAL-SPACING-UNIT-SMALL !default;
$tippy-background-color:    $COLOR-SUBTLE !default;
$tippy-enable-arrow:        true !default;
$tippy-arrow-color:         $tippy-background-color !default;
$tippy-arrow-size:          $GLOBAL-ARROW-SIZE !default;
$tippy-offset:              0 !default;
$tippy-border-radius:       $GLOBAL-INPUT-BORDER-RADIUS !default;
$tippy-arrow-border-radius: null !default;
$tippy-border-size:         0 !default;
$tippy-border-color:        null !default;
$tippy-box-shadow:          false !default;
$tippy-text-color:          $COLOR-BASE-TEXT !default;
$tippy-text-size:           $FS-1 !default;
$tippy-font-weight:         $GLOBAL-FONT-WEIGHT-REGULAR !default;
// The line height is equal to the `html` element’s line height and calculated
// without unit.
$tippy-line-height:         div($GLOBAL-LINE-HEIGHT, $GLOBAL-FONT-SIZE) !default;
$tippy-link-color:          null !default;
$tippy-link-color-hover:    null !default;
$tippy-placements:          "top", "bottom", "left", "right" !default;
// Since a border-width <= 0 effectively renders the border invisible we use this
// as criteria to differentiate if a border will be applied.
$tippy-has-border:          if($tippy-border-size > 0, true, false) !default;

// Unset arrow-size if arrow is not enabled to not distort the calculations.
@if not ($tippy-enable-arrow) {
    $tippy-arrow-size: 0;
}

@import "_mixins";

/**
 * [1] Reference for the arrow.
 * [2] If the browser supports `filter: drop-shadow`, apply this declaration to
 *     include the arrow in the shadow as well.
 * [3] Defensively reset any spacing-bottom of the last element in the body.
 * [4] Limit the height of the tooltip for large content and make it scrollable.
 * [5] Make sure that “normal” HTML elements like `<h1>` etc. all get the
 *     correct text color, when they are placed inside of the tooltip.
 *     Use `!important` to avoid text color being overwritten by higher specificity.
 * [6] Check if the tooltip will have a border and render the triangle differently
 *     as the original approach uses `border` itself to render the triangle.
 * [7] Overwrite the default hover link border color.
 * [8] Fix issue where the drop shadow was sometimes missing in some versions
 *     of Safari. Unfortunately, we do not have a reliable CSS-only solution
 *     to intercept the Safari browser. Therefore, we add `will-change: transform`
 *     only when a drop shadow is set.
 * [9] Makes sure that the text within the tooltip wraps normally even if
 *     a parent element suppresses line breaks using `white-space: nowrap`.
 * [10] Normalize `font-weight` but exclude `<strong>` and `<b>`.
 */

[data-tippy-root] {
    max-width: $tippy-max-width;
    white-space: normal; /* [9] */
}

.tippy-box {
    @include responsive-font-size($tippy-text-size);
    position: relative; /* [1] */
    border-radius: $tippy-border-radius;
    background-color: $tippy-background-color;

    @if ($tippy-box-shadow) {
        box-shadow: $tippy-box-shadow;

        @supports (filter: drop-shadow($tippy-box-shadow)) {
            box-shadow: none; /* [2] */
            filter: drop-shadow($tippy-box-shadow); /* [2] */
        }

    }

    @if ($tippy-has-border) {
        border-color: $tippy-border-color;
        border-style: solid;
        border-width: $tippy-border-size;
    }

    &:focus {
        @include disable-focus();
    }

    &,
    * {
        color: important($tippy-text-color, true); /* [5] */
    }

    *:not(strong,b) { /* [10] */
        font-weight: $tippy-font-weight;
    }

    &[data-state="visible"] {
        opacity: 1;
    }

    &[data-state="hidden"] {
        opacity: 0;
    }

    a {
        color: $tippy-link-color;

        &:hover {
            color: $tippy-link-color-hover;
            border-bottom-color: $tippy-link-color-hover; /* [7] */
        }

    }

}

.tippy-arrow {

    @if ($tippy-enable-arrow) {
        width: ($tippy-arrow-size * 2);
        height: ($tippy-arrow-size * 2);
    } @else {
        display: none;
    }

    &::before {
        content: "";
        position: absolute;
    }

}

.tippy-content {
    position: relative;
    z-index: 1;
    max-height: $tippy-max-height; /* [4] */
    overflow: auto; /* [4] */
    padding: $tippy-padding-vertical $tippy-padding-horizontal;
    line-height: $tippy-line-height;

    > *:last-child {
        margin-bottom: 0; /* [3] */
    }

}

@each $placement in $tippy-placements {

    .tippy-box {

        &[data-placement^="#{$placement}"] {

            @if ($placement == "top") {
                transform: translateY(-($tippy-arrow-size + $tippy-offset));
            } @else if ($placement == "bottom") {
                transform: translateY($tippy-arrow-size + $tippy-offset);
            } @else if ($placement == "left") {
                transform: translateX(-($tippy-arrow-size + $tippy-offset));
            } @else if ($placement == "right") {
                transform: translateX($tippy-arrow-size + $tippy-offset);
            }

            @if ($tippy-box-shadow) {
                will-change: transform; /* [8] */
            }

            @if ($tippy-enable-arrow) {

                .tippy-arrow {
                    $_direction: opposite-direction($placement);
                    #{$_direction}: 0;

                    &::before {

                        @if ($tippy-has-border) { /* [6] */
                            @include arrow-with-border(
                                $placement,
                                $tippy-border-size,
                                $tippy-border-color,
                                $tippy-arrow-size,
                                $tippy-arrow-border-radius,
                                $tippy-arrow-color
                            );
                        } @else {
                            @include arrow($placement, $tippy-arrow-size, $tippy-arrow-color);
                            @include arrow-position($placement, $tippy-arrow-size, false);
                            @include arrow-transform-origin($placement);
                        }

                    }

                }

            }

        }

    }

}
