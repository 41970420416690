/* ------------------------------------*\
    #PAGINATION
\*------------------------------------ */

$pagination-is-centered:                 false !default;

$pagination-link-spacing-horizontal:     0 !default;
$pagination-link-spacing-vertical:       0 !default;
$pagination-link-padding-horizontal:     ($GLOBAL-SPACING-UNIT * 0.5) !default;
$pagination-link-padding-vertical:       ($GLOBAL-SPACING-UNIT - $GLOBAL-FONT-SIZE) * 0.5 !default;
$pagination-link-border-radius:          null !default;

$pagination-link-color:                  $COLOR-BRAND !default;
$pagination-link-bg:                     $COLOR-BASE-BACKGROUND !default;

$pagination-link-color-disabled:         $COLOR-PALE !default;
$pagination-link-bg-disabled:            $pagination-link-bg !default;

$pagination-link-color-active:           $pagination-link-bg !default;
$pagination-link-bg-active:              $pagination-link-color !default;

$pagination-link-color-hover:            $pagination-link-color-active !default;
$pagination-link-bg-hover:               $pagination-link-bg-active !default;

$pagination-link-font-size:              null !default;
$pagination-link-font-weight:            null !default;
$pagination-link-font-weight-active:     null !default;
$pagination-link-font-weight-hover:      null !default;

// @TODO
// Inherit font-size from parent that ensures same-sized pagination-items
// This does not apply the correct icon-sizes per brand.
$pagination-stepper-icon-color:          null !default;
$pagination-stepper-icon-size:           inherit !default;
$pagination-stepper-icon-prev:           get-icon(semantic-back) !default;
$pagination-stepper-icon-next:           get-icon(semantic-forward) !default;
$pagination-stepper-spacing:             null !default;
$pagination-stepper-disabled-color:      $COLOR-PALE !default;
$pagination-stepper-disabled-opacity:    $OPACITY-MEDIUM !default;
$pagination-stepper-disabled-visibility: null !default;
/**
 * [1] Stepper icon should have same line-height as .c-pagination__link text.
 */
.c-pagination {
}

    .c-pagination__list {
        @include list-bare();
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @if ($pagination-is-centered) {
            justify-content: center;
        }

    }

    .c-pagination__link {
        @include transition();
        display: block;
        margin: $pagination-link-spacing-vertical $pagination-link-spacing-horizontal;
        padding: $pagination-link-padding-vertical $pagination-link-padding-horizontal;
        border-radius: $pagination-link-border-radius;
        font-size: $pagination-link-font-size;
        font-weight: $pagination-link-font-weight;
        text-decoration: none;
        background: $pagination-link-bg;
        color: $pagination-link-color;

        &:not([href]) {
            cursor: inherit;
        }

        /* stylelint-disable selector-max-class, selector-max-specificity */
        &:not(.is-disabled):not(.is-active) {
            &:hover,
            &:focus {
                font-weight: $pagination-link-font-weight-hover;
                background: $pagination-link-bg-hover;
                color: $pagination-link-color-hover;
            }

        }
        /* stylelint-enable */

        &.is-active {
            font-weight: $pagination-link-font-weight-active;
            background: $pagination-link-bg-active;
            color: $pagination-link-color-active;
        }

        &.is-disabled {
            background: $pagination-link-bg-disabled;
            color: $pagination-link-color-disabled;
        }

        &[class*="c-pagination__link--stepper-"] {
            color: $pagination-stepper-icon-color;

            &::before {
                @include icon-font($line-height: inherit); /* [1] */
                font-size: $pagination-stepper-icon-size;
            }

            &.is-disabled {
                color: $pagination-stepper-disabled-color;
                opacity: if($pagination-stepper-disabled-opacity, $pagination-stepper-disabled-opacity, null);
                visibility: $pagination-stepper-disabled-visibility;
            }

            &.c-pagination__link--stepper-prev {
                margin-right: $pagination-stepper-spacing;

                &::before {
                    content: $pagination-stepper-icon-prev;
                }
            }

            &.c-pagination__link--stepper-next {
                margin-left: $pagination-stepper-spacing;

                &::before {
                    content: $pagination-stepper-icon-next;
                }
            }

        }

    }
