/* ------------------------------------*\
    #SPINNER (Scopes)
\*------------------------------------ */

/**
 * [1] Small size in upload context. Use `@extend` as multiple selectors
 *     and properties need to be set.
 */
.c-upload {

    .c-spinner {
        @extend .c-spinner--small; /* [1] */ /* stylelint-disable-line scss/at-extend-no-missing-placeholder */
    }

}
