/* ------------------------------------*\
    #STATUS-MESSAGE
\*------------------------------------ */

/**
 * The status component displays a status icon + text message.
 */

$status-message-icon-size:       icon-size(xsmall) !default;
$status-message-icon-complexity: null !default;
$status-message-icon-margin:     $GLOBAL-SPACING-UNIT-XSMALL !default;
$status-message-icon-shift:      0.25em !default;

// Double-deep-merge status themes with status icons with color overwrites for
// better contrasts and readability. This should match the settings of the
// Indicator Dot for each brand.
//
// @TODO: Refactor _settings.status.scss and components (e. g. Indicator Dot,
//     Status Message, …) to use consistent and global rules to set status
//     text/icon colors.
/* stylelint-disable function-comma-newline-after */
$status-message-themes: map-deep-merge(map-deep-merge($STATUS-THEMES, $STATUS-ICONS), (
    "success": (
        "text-color": $COLOR-SUCCESS,
    ),
    "error": (
        "text-color": $COLOR-ALERT,
    ),
), 2) !default;
/* stylelint-enable function-comma-newline-after */

// Merge default and overwrite settings to avoid duplications
// and overwrite only necessary settings.
$status-message-themes-overwrites: () !default;
$status-message-themes-merged:     map-deep-merge($status-message-themes, $status-message-themes-overwrites, 2) !default;

/**
 * [1] Adjust vertical alignment of icon and first text line.
 */

.c-status-message {
    display: flex;
    align-items: baseline;

    @include vary($create: modifier, $loop: $status-message-themes-merged) {
        color: vary-get("text-color");

        &::before {
            content: get-icon(vary-get("icon"), $status-message-icon-complexity);
            color: vary-get("icon-color");
        }
    }

    &::before {
        @include icon-font(
            $line-height: 0,
        );
        @include font-size(
            $font-size:   $status-message-icon-size,
            $line-height: false,
        );
        margin-right: $status-message-icon-margin;
        transform: translateY(#{$status-message-icon-shift}); /* [1] */
    }

}
