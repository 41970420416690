/* ------------------------------------*\
    #FAV-BUTTON
\*------------------------------------ */
@use "sass:map";

$fav-button-text-size:                  $FS0 !default;
$fav-button-transition-property:        $GLOBAL-TRANSITION-PROPERTY !default;
$fav-button-transition-duration:        $GLOBAL-TRANSITION-DURATION !default;
$fav-button-transition-timing-function: $GLOBAL-TRANSITION-TIMING-FUNCTION !default;
$fav-button-item-spacing:               $GLOBAL-SPACING-UNIT-XSMALL !default;
$fav-button-color-deselected:           $COLOR-BASE-TEXT !default;
$fav-button-color-selected:             $fav-button-color-deselected !default;
$fav-button-color-hover:                $fav-button-color-deselected !default;
$fav-button-color-active:               null !default;
$fav-button-color-disabled:             rgba($COLOR-BASE, $OPACITY-MEDIUM) !default;
$fav-button-state-active:               ".is-active" !default;
$fav-button-letter-spacing:             null !default;
$fav-button-text-transform:             inherit !default;
$fav-button-font-weight:                $GLOBAL-FONT-WEIGHT-REGULAR !default;
$fav-button-icon-size:                  icon-size(small) !default;
$fav-button-icon-deselected:            get-icon(heart) !default;
$fav-button-icon-selected:              get-icon(heart-filled) !default;

/**
 * [1] Reset the browser button styles.
 * [2] Align styles to the Button Link’s implementation.
 * [3] Handles the pressed state.
 * [4] Inherit the transitions like in the Button Link implementation.
 * [5] Visually hide the label for `.c-fav-button--icon-only` variant to improve a11y.
 */

.c-fav-button {
    --fav-button-color: #{$fav-button-color-deselected};
    --fav-button-icon-name: #{$fav-button-icon-deselected};
    --fav-button-item-spacing: #{$fav-button-item-spacing};

    @include reset-button(); /* [1] */
    @include responsive-font-size($fav-button-text-size); /* [2] */
    @include transition(
        $transition-property:        $fav-button-transition-property,
        $transition-duration:        $fav-button-transition-duration,
        $transition-timing-function: $fav-button-transition-timing-function,
    ); /* [2] */

    display: inline-flex; /* [2] */
    align-items: center; /* [2] */
    justify-content: flex-end; /* [2] */
    column-gap: var(--fav-button-item-spacing); /* [2] */
    line-height: 1; /* [2] */
    text-transform: $fav-button-text-transform; /* [2] */
    text-align: left;

    /**
     * @deprecated: Remove `[aria-pressed="true"]` in v15.
     */
    [aria-pressed="true"],
    &#{$fav-button-state-active} {
        --fav-button-icon-name: #{$fav-button-icon-selected}; /* [3] */
        --fav-button-color: #{$fav-button-color-selected}; /* [3] */
    }

    &.c-fav-button--reversed {
        flex-direction: row-reverse;
        text-align: right;
    }

    &:hover {
        --fav-button-color: #{$fav-button-color-hover};
    }

    &:focus,
    &:active {
        --fav-button-color: #{$fav-button-color-active};
    }

    &[disabled] {
        --fav-button-color: #{$fav-button-color-disabled};
    }

}

.c-fav-button__label {
    font-weight: $fav-button-font-weight;
    letter-spacing: $fav-button-letter-spacing;
    color: var(--fav-button-color);
    transition: inherit; /* [4] */

    .c-fav-button--icon-only & {
        @include a11y-hidden(); /* [5] */
    }

}

.c-fav-button__label--on {
    display: none;

    /**
     * @deprecated: Remove `[aria-pressed="true"] &` in v15.
     */
    [aria-pressed="true"] &,
    .c-fav-button#{$fav-button-state-active} & {
        display: block;
    }

}

.c-fav-button__label--off {
    display: block;

    /**
     * @deprecated: Remove `[aria-pressed="true"] &` in v15.
     */
    [aria-pressed="true"] &,
    .c-fav-button#{$fav-button-state-active} & {
        display: none;
    }

}

.c-fav-button__icon {
    font-size: $fav-button-icon-size;
    color: var(--fav-button-color);
    transition: inherit; /* [4] */

    &::before {
        @include icon-font();
        content: var(--fav-button-icon-name);
        font-size: inherit;
        vertical-align: middle;
    }

}
