/* ------------------------------------*\
    #RADIO-BUTTON
\*------------------------------------ */

$skoda-radiobutton-focus-offset: 2px !default;

/**
 * [1] Fix vertical alignment with label.
 * [2] Transition from transparent to target color,
 *     otherwise the transition is not smooth.
 */
.c-radio__label {

    .c-radio__input + &::before {
        @include focus-styles($outline-color: transparent); /* [2] */
    }

    .c-radio__input:focus-visible + &::before {
        @include focus-styles($outline-offset: $skoda-radiobutton-focus-offset);
    }

    &::before {
        top: 2px; /* [1] */ /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    }

    &::after {
        top: 7px; /* [1] */ /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    }

}
