// /* ------------------------------------*\
//     #TOOLTIP
// \*------------------------------------ */

$TOOLTIP-BACKGROUND:         $COLOR-ON-SURFACE-700 !default;
$TOOLTIP-TEXT-COLOR:         $COLOR-WHITE !default;
$TOOLTIP-TEXT-SIZE:          map-get($FS-2, null) !default;
$TOOLTIP-FONT-WEIGHT:        $GLOBAL-FONT-WEIGHT-REGULAR !default;
$TOOLTIP-LINE-HEIGHT:        1.5 !default;
$TOOLTIP-PADDING-VERTICAL:   $GLOBAL-SPACING-UNIT-XSMALL !default;
$TOOLTIP-PADDING-HORIZONTAL: $__SKODA-SPACING-STATIC-12__ !default;
$TOOLTIP-BORDER-RADIUS:      2px !default;
$TOOLTIP-OFFSET:             $GLOBAL-SPACING-UNIT-XSMALL !default;
$TOOLTIP-ARROW-SIZE:         8px !default;
