// /* ------------------------------------*\
//     #WIZARD (Settings)
// \*------------------------------------ */

$wizard-spacing-top:                         $GLOBAL-SPACING-UNIT !default;
$wizard-background-color:                    false !default;
$wizard-stroke-arrows:                       false !default;
$wizard-block-arrows:                        false !default;

$wizard-item-padding-horizontal:             $GLOBAL-SPACING-UNIT-XSMALL !default;
$wizard-item-padding-vertical:               $GLOBAL-SPACING-UNIT-XXSMALL !default;
$wizard-item-font-size:                      $FS-1 !default;
$wizard-item-font-weight:                    bold !default;
$wizard-item-background-color:               false !default;
$wizard-item-background-color-disabled:      false !default;
$wizard-item-background-color-done:          false !default;
$wizard-item-text-color:                     $COLOR-INACTIVE !default;
$wizard-item-text-color-done:                $COLOR-DECENT !default;
$wizard-item-text-color-done-hover:          $COLOR-BASE-TEXT !default;
$wizard-item-text-color-active:              $wizard-item-text-color-done !default;
$wizard-item-text-color-active-hover:        $wizard-item-text-color-done-hover !default;
$wizard-item-text-alignment:                 center !default;

$wizard-indicator-spacing:                   $GLOBAL-SPACING-UNIT-XXSMALL !default;
$wizard-indicator-icon-size:                 xxsmall !default;
$wizard-indicator-size:                      icon-size($wizard-indicator-icon-size) !default;
$wizard-indicator-icon:                      none !default;
$wizard-indicator-icon-disabled:             none !default;
$wizard-indicator-icon-active:               none !default;
$wizard-indicator-text-color-done:           $COLOR-SUCCESS !default;

$wizard-trigger-background-color:            false !default;
$wizard-trigger-text-color:                  $wizard-item-text-color-done !default;
$wizard-trigger-icon-size:                   xsmall !default;
$wizard-trigger-counter-spacing:             $GLOBAL-SPACING-UNIT-XSMALL !default;

