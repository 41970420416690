/* ------------------------------------*\
   #NOTIFICATION-CENTER
\*------------------------------------ */

$skoda-notification-center-item-padding-left:                     ($notification-center-item-padding-horizontal + ($INDICATOR-DOT-SIZE + $INDICATOR-DOT-SPACING)) !default;
$skoda-notification-center-item-background-read:                  $COLOR-WHITE !default;
$skoda-notification-center-item-background-unread:                $COLOR-SURFACE-PRIMARY !default;

$skoda-notification-center-close-background-size:                 $BUTTON-ICON-INTERACTION-SIZE-SMALL !default;

$skoda-notification-center-read-toggle-button-padding-vertical:   $BUTTON-PADDING-VERTICAL !default;
$skoda-notification-center-read-toggle-button-padding-horizontal: $BUTTON-PADDING-VERTICAL !default;

/**
 * [1] Overlay on toggle-hover not needed. Overwrite default.
 * [2] Colorize item background
 *     while maintaining scroll-shadow visibility (item overlay).
 * [2a] Unread status (default).
 * [2b] Read. status.
 * [3] Disable toggle overlay to show item overlay (item background).
 * [4] Imply "read" status by hovering toggle from "unread".
 *     Progressive enhancement:
 *     Use `:has` selector to check for child state and modify on parent level.
 *     Non-supporting browsers do not change background by hovering toggle.
 */
.c-notification-center {
}

    .c-notification-center__item {

        &:has(.c-notification-center__item__mark-read-toggle:hover) {

            /* stylelint-disable-next-line selector-max-specificity */
            &::before {
                background-color: $skoda-notification-center-item-background-read; /* [4] */
            }

        }

        &:not(.c-notification-center__item--read) {
            padding-left: $skoda-notification-center-item-padding-left;

            /* stylelint-disable-next-line selector-max-specificity */
            .c-notification-center__item__mark-read-toggle:hover::before {
                content: unset; /* [3] */
            }

        }

        /* [2] */
        &::before {
            @include transition(
                $transition-property: background-color,
            );
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $skoda-notification-center-item-background-unread; /* [2a] */
            pointer-events: none;
            mix-blend-mode: darken;
        }

        &.c-notification-center__item--read {

            &::before {
                background-color: $skoda-notification-center-item-background-read; /* [2b] */
            }

        }

    }

    .c-notification-center__item__mark-read-toggle {
        padding: $skoda-notification-center-read-toggle-button-padding-vertical $skoda-notification-center-read-toggle-button-padding-horizontal;
    }

    .c-notification-content-center__item__close {
        @include button-icon-states(
            $size: $skoda-notification-center-close-background-size,
        );

        &:focus-visible {
            outline: 0;
        }

    }
