// /* ------------------------------------*\
//     #SCROLLABLE
// \*------------------------------------ */

$scrollable-indicator-color:                        $SCROLL-SHADOW-COLOR !default;
$scrollable-indicator-shadow-size:                  14px !default;
$scrollable-indicator-shadow-cover-size-multiplier: 3 !default;

// Adaptation of https://codepen.io/laustdeleuran/pen/DBaAu
@mixin _scrollable-indicators(
    $bg-color,
    $style,
    $shadow-color,
    $shadow-size,
    $cover-size-multiplier
) {

    $cover-size: $shadow-size * $cover-size-multiplier;

    /* stylelint-disable */
    @if ($style == 'linear') {
        background:
            // Shadow covers
            linear-gradient($bg-color 30%, rgba($bg-color,0)),
            linear-gradient(rgba($bg-color, 0), $bg-color 70%) 0 100%,
            // Shadows
            linear-gradient(to bottom, $shadow-color, rgba($shadow-color, 0)),
            linear-gradient(to top, $shadow-color, rgba($shadow-color, 0)) 0 100%;
    } @else {
        background:
            // Shadow covers
            linear-gradient($bg-color 30%, rgba($bg-color,0)),
            linear-gradient(rgba($bg-color, 0), $bg-color 70%) 0 100%,
            // Shadows
            radial-gradient(farthest-side at 50% 0, $shadow-color, rgba($shadow-color, 0)),
            radial-gradient(farthest-side at 50% 100%, $shadow-color, rgba($shadow-color, 0)) 0 100%;
    }

    background-repeat: no-repeat;
    background-color: $bg-color;
    background-size: 100% $cover-size,
                     100% $cover-size,
                     100% $shadow-size,
                     100% $shadow-size;
    background-attachment: local,
                           local,
                           scroll,
                           scroll;
    /* stylelint-enable */
}

/// Helper to make a container scrollable and add an indicator in the form of
/// a shadow.
/// @param {Number} $container-height [100%] - Container element height
/// @param {Number | String | Null} $bg-color [null] - Background color
/// @param {Color | Null} $style [null] - Could be set to `linear`. Default is a radial gradient.
/// @param {Color} $shadow-color [$scrollable-indicator-color] - Shadow color
/// @param {Number} $shadow-size [$scrollable-indicator-shadow-size] - Shadow height
/// @param {String} $cover-size-multiplier [$scrollable-indicator-shadow-cover-size-multiplier] - Multiplier to calculate the shadow cover height
/// @param {Map} $themes [null] - Map for theming
/// @param {String} $theme-bg-color-variable [background] - Key in $themes that is being used for the color value
@mixin scrollable(
    $container-height: 100%,
    $bg-color: null,
    $style: null,
    $shadow-color: $scrollable-indicator-color,
    $shadow-size: $scrollable-indicator-shadow-size,
    $cover-size-multiplier : $scrollable-indicator-shadow-cover-size-multiplier,
    $themes: null,
    $theme-bg-color-variable: "background"
) {
    @if ($bg-color == null or $bg-color == transparent or opacity($bg-color) != 1) {
        @error("The background color of a component using the scrollable() helper cannot be null or (partly) transparent.");
    }

    @include _scrollable-indicators(
        $bg-color,
        $style,
        $shadow-color,
        $shadow-size,
        $cover-size-multiplier
    );
    max-height: $container-height;
    overflow-y: auto;

    @include vary($loop: $themes) {
        $bg-color-theme: vary-get($theme-bg-color-variable);
        @if (type_of($bg-color-theme) == color) {
            @if ($bg-color-theme == transparent or opacity($bg-color-theme) != 1) {
                @error("The background color of a component using the scrollable() helper cannot be null or (partly) transparent. Check theming colors!");
            } @else {
                @include _scrollable-indicators(
                    $bg-color-theme,
                    $style,
                    $shadow-color,
                    $shadow-size,
                    $cover-size-multiplier
                );
            }
        }
    }
}
