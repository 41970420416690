// /* ------------------------------------*\
//     #TAG (Settings)
// \*------------------------------------ */

$tag-padding-horizontal: $GLOBAL-SPACING-UNIT-XSMALL !default;
$tag-padding-vertical:   6px !default; // Deviate from global spacing scales according to brand guidelines.

$tag-border:             false !default;
$tag-border-radius:      2px !default;

$tag-text-size:          $FS-2 !default;
$tag-text-transform:     uppercase !default;
$tag-font-weight:        $GLOBAL-FONT-WEIGHT-BOLD !default;
$tag-letter-spacing:     $GLOBAL-FONT-LETTER-SPACING !default;

$tag-close-icon-size:    icon-size(xxsmall) !default;
$tag-close-spacing:      $GLOBAL-SPACING-UNIT-XXSMALL !default;
