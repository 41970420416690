/* ------------------------------------*\
    #INTERACTION-LAYER
\*------------------------------------ */

$skoda-interaction-layer-backdrop-filter: $BACKDROP-FILTER !default;

.c-interaction-layer__wrapper {

    &::before {
        backdrop-filter: $skoda-interaction-layer-backdrop-filter;
    }

}

.c-interaction-layer__close-btn {
    @include button-icon-states();
}
