// /* ------------------------------------*\
//    #INSTRUCTION-STEPPER (Settings)
// \*------------------------------------ */

$instruction-stepper-line-gap:                        2px !default;
$instruction-stepper-item-background-color:           $COLOR-ON-SURFACE-100 !default;
$instruction-stepper-item-border-color:               $COLOR-ON-SURFACE-300 !default;
$instruction-stepper-item-border-width:               2px !default;
$instruction-stepper-item-is-active-background-color: $COLOR-ON-SURFACE-700 !default;
$instruction-stepper-item-color:                      $COLOR-ON-SURFACE-300 !default;
$instruction-stepper-small-item-icon-size:            icon-size(xsmall) !default;

$instruction-stepper-connector-size:                  2px !default;
$instruction-stepper-connector-color:                 $COLOR-ON-SURFACE-700 !default;

$instruction-stepper-title-color:                     $COLOR-BASE-TEXT !default;
$instruction-stepper-title-font-size:                 map-get($FS0, null) !default;

$instruction-stepper-description-color:               $COLOR-BASE-TEXT !default;
$instruction-stepper-description-font-size:           map-get($FS-1, null) !default;
$instruction-stepper-description-font-weight:         $GLOBAL-FONT-WEIGHT-LIGHT !default;
