// /* ------------------------------------*\
//     #FOCUS-STYLES
// \*------------------------------------ */

// @TODO: rename to `focus-outline`

/// Mixin for outline styles.
/// Returns a visible outline of the object when in focus.
/// @access public
///
/// @param {String} $outline-width [$GLOBAL-FOCUS-WIDTH] - Size of outline.
/// @param {String} $outline-style [$GLOBAL-FOCUS-STYLE] - Style of outline.
/// @param {Color} $outline-color [$GLOBAL-FOCUS-COLOR] - Color of outline.
/// @param {String} $outline-color [$GLOBAL-FOCUS-OFFSET] - Outline offset.
/// @param {Boolean} $set-props [true] - Control if custom properties should be set. Allow more flexibility via custom properties.
/// @param {Boolean} $important [false] - Option to enforce the attached styles.
/// @output `outline` with the specified parameters.
///
/// @example
///     selector:focus {
///         @include focus-styles();
///     }
@mixin focus-styles(
    $outline-width:  $GLOBAL-FOCUS-WIDTH,
    $outline-style:  $GLOBAL-FOCUS-STYLE,
    $outline-color:  $GLOBAL-FOCUS-COLOR,
    $outline-offset: $GLOBAL-FOCUS-OFFSET,
    $set-props:      true,
    $important:      false,
) {
    @if ($set-props) {
        --focus-outline-color: #{$outline-color};
        --focus-outline-offset: #{$outline-offset};
        --focus-outline-style: #{$outline-style};
        --focus-outline-width: #{$outline-width};
    }

    outline-color: important(var(--focus-outline-color, $GLOBAL-FOCUS-COLOR), $important);
    outline-offset: important(var(--focus-outline-offset, $GLOBAL-FOCUS-OFFSET), $important);
    outline-style: important(var(--focus-outline-style, $GLOBAL-FOCUS-STYLE), $important);
    outline-width: important(var(--focus-outline-width, $GLOBAL-FOCUS-WIDTH), $important);
}

/// Mixin for disabling outline styles.
/// Suppress visible outline styles of the object when in focus.
/// @access public
///
/// @example
///     selector:focus {
///         @include disable-focus();
///     }
@mixin disable-focus() {
    @include focus-styles($outline-color: transparent, $outline-width: 0);
}
