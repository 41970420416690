/* ------------------------------------*\
    #HR
\*------------------------------------ */

$hr-margin-right:  auto !default;
$hr-margin-left:   auto !default;
$hr-margin-bottom: $GLOBAL-SPACING-UNIT-LARGE !default;
$hr-width:         100% !default;
$hr-height:        1px !default;
$hr-color:         $COLOR-BASE !default;

$hr-themes: (
    "alternative": (
        "background": $COLOR-BASE
    )
) !default;

hr {
    display: block;
    width: $hr-width;
    height: $hr-height;
    margin-right: $hr-margin-right;
    margin-bottom: $hr-margin-bottom;
    margin-left: $hr-margin-left;
    border: 0;
    background-color: $hr-color;

    @include vary($loop: $hr-themes) {
        background-color: vary-get("background");
    }
}
