// /* ------------------------------------*\
//     #SHADOWS
// \*------------------------------------ */

/**
 * Flow elevation concept:
 * https://www.figma.com/file/6qOAnI1JSFb2CLT4E3xahQ/FLOW---Web-%2F-Styles?node-id=4762%3A0
 */

$GLOBAL-BOX-SHADOW-OFFSET-X: 0 !default;
$GLOBAL-BOX-SHADOW-OFFSET-Y: 0 !default;
$GLOBAL-BOX-SHADOW-COLOR:    rgba(0, 0, 0, 0.15) !default;

$DROP-SHADOW-COLOR:          $GLOBAL-BOX-SHADOW-COLOR !default;

$DROP-SHADOW-SIZE-XS:        8px !default;
$DROP-SHADOW-SIZE-S:         25px !default;
$DROP-SHADOW-SIZE-M:         25px !default;
$DROP-SHADOW-SIZE-L:         32px !default;
$DROP-SHADOW-SIZE-XL:        64px !default;

$DROP-SHADOW-OFFSET-XS:      0 !default;
$DROP-SHADOW-OFFSET-S:       2px !default;
$DROP-SHADOW-OFFSET-M:       5px !default;
$DROP-SHADOW-OFFSET-L:       8px !default;
$DROP-SHADOW-OFFSET-XL:      24px !default;

$DROP-SHADOW-XS:             0 $DROP-SHADOW-OFFSET-XS $DROP-SHADOW-SIZE-XS $DROP-SHADOW-COLOR !default;
$DROP-SHADOW-S:              0 $DROP-SHADOW-OFFSET-S $DROP-SHADOW-SIZE-S $DROP-SHADOW-COLOR !default;
$DROP-SHADOW-M:              0 $DROP-SHADOW-OFFSET-M $DROP-SHADOW-SIZE-M $DROP-SHADOW-COLOR !default;
$DROP-SHADOW-L:              0 $DROP-SHADOW-OFFSET-L $DROP-SHADOW-SIZE-L $DROP-SHADOW-COLOR !default;
$DROP-SHADOW-XL:             0 $DROP-SHADOW-OFFSET-XL $DROP-SHADOW-SIZE-XL $DROP-SHADOW-COLOR !default;

$DROP-SHADOW-PLAY-BUTTON:    drop-shadow($GLOBAL-BOX-SHADOW-OFFSET-X $GLOBAL-BOX-SHADOW-OFFSET-Y $DROP-SHADOW-SIZE-XS rgba(0, 0, 0, 0.35)) !default;
