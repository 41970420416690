/* ------------------------------------*\
    #UPLOAD-ITEM
\*------------------------------------ */

/**
 * [1] Avoid duplicated outline.
 */
.c-upload-item {

}

    .c-upload-item__title-row {
        align-items: center;
    }

    .c-upload-item__interaction-icon {
        @include button-icon-states();
        position: relative;

        &:focus-visible {
            outline: 0;
        }

    }
