// /* ------------------------------------*\
//     #FOLD-OUT (Settings)
// \*------------------------------------ */

$fold-out-breakpoint:              s !default;
$fold-out-header-spacing:          0 !default;
$fold-out-icon-rotate-close-state: 0deg !default;
$fold-out-icon-rotate-open-state:  45deg !default;
$fold-out-icon-pseudo-element:     "::before" !default;
$fold-out-header-icon:             get-icon(semantic-plus) !default;
$fold-out-header-icon-color:       $COLOR-BASE !default;
$fold-out-header-icon-size: (
    null:                 icon-size(small),
    $fold-out-breakpoint: icon-size(small)
) !default;
$fold-out-header-item-spacing-horizontal: (
    null:                 $GLOBAL-SPACING-UNIT-SMALL,
    $fold-out-breakpoint: $GLOBAL-SPACING-UNIT-SMALL,
) !default;
