/* ------------------------------------*\
   #NOTIFICATION-CENTER
\*------------------------------------ */

$notification-center-breakpoint:                                   $GLOBAL-PAGE-HEAD-BREAKPOINT !default;

$notification-center-toggle-icon:                                  get-icon(semantic-notification) !default;
$notification-center-toggle-icon-size:                             icon-size($GLOBAL-PAGE-HEAD-MODULE-ICON-SIZE) !default;
$notification-center-toggle-icon-size-at-breakpoint:               null !default;
$notification-center-toggle-icon-color:                            $COLOR-BRAND !default;

$notification-center-toggle-spacing-left:                          $GLOBAL-SPACING-UNIT-SMALL !default;
$notification-center-toggle-spacing-right-at-breakpoint:           ($badge-size * 0.5) !default;

$notification-center-toggle-badge-position-top:                    50% !default;
$notification-center-toggle-badge-position-left:                   100% !default;
// Offset can be custom ("magic numbers") as it depends on the content of the notification icon on how much is covered.
$notification-center-toggle-badge-transform:                       translate(-25%, -50%) !default;
$notification-center-toggle-badge-horizontal-offset:               null !default;
$notification-center-toggle-badge-vertical-offset:                 null !default;
$notification-center-toggle-badge-horizontal-offset-at-breakpoint: null !default;
$notification-center-toggle-badge-vertical-offset-at-breakpoint:   null !default;

$notification-center-panel-state-expanded:                         "[aria-hidden='false']" !default;
$notification-center-panel-z-index-at-breakpoint:                  z("notifications") !default;
$notification-center-panel-position-top:                           $site-nav-dropdown-top !default;
$notification-center-panel-spacing-top:                            null !default;
// Use a font size relative unit like `em`. The width should be dependent on the font size so that line lengths (but not
// the exact width in `px`) should be similar for different brands.
$notification-center-panel-width:                                  38em !default;
$notification-center-panel-max-width:                              null !default;
$notification-center-panel-max-height:                             calc(100vh - #{$GLOBAL-PAGE-HEAD-HEIGHT} - 80px) !default;
$notification-center-panel-border-radius:                          null !default;
$notification-center-panel-font-size:                              $FS-2 !default;
$notification-center-panel-font-weight:                            null !default;
$notification-center-panel-background:                             $COLOR-BASE-BACKGROUND !default;
$notification-center-panel-border-color:                           null !default;
$notification-center-panel-box-shadow:                             $GLOBAL-BOX-SHADOW !default;

$notification-center-panel-header-height:                          $GLOBAL-PAGE-HEAD-HEIGHT-AT-BREAKPOINT !default;
$notification-center-panel-header-padding-horizontal:              $page-wrap-padding-horizontal !default;
$notification-center-panel-header-color:                           $COLOR-BRAND !default;
$notification-center-panel-header-background:                      $COLOR-PALE !default;
$notification-center-panel-header-border-color:                    null !default;

$notification-center-panel-heading-font-size:                      $FS4 !default;
$notification-center-panel-heading-font-weight:                    null !default;

$notification-center-panel-close-font-size:                        null !default;
$notification-center-panel-close-color:                            null !default;
$notification-center-panel-close-color-hover:                      null !default;
$notification-center-panel-close-icon:                             get-icon(semantic-close) !default;
$notification-center-panel-close-icon-size:                        null !default;
$notification-center-panel-close-icon-color:                       null !default;
$notification-center-panel-close-icon-color-hover:                 null !default;

// Magic number because we don’t have a global variable for this.
$notification-center-item-initial-vertical-offset-at-breakpoint:   -(30px) !default;
$notification-center-item-transition:                              $GLOBAL-TRANSITION !default;
$notification-center-item-padding-horizontal:                      $GLOBAL-SPACING-UNIT-LARGE !default;
$notification-center-item-padding-vertical:                        $GLOBAL-SPACING-UNIT-SMALL !default;
$notification-center-item-child-spacing:                           $GLOBAL-SPACING-UNIT-XSMALL !default;
$notification-center-item-border-color:                            $COLOR-SUBTLE !default;
$notification-center-item-border:                                  1px solid $notification-center-item-border-color !default;
$notification-center-item-font-size:                               null !default;
$notification-center-item-overlay-color:                           $COLOR-PALE !default;
$notification-center-item-overlay-transition:                      $GLOBAL-TRANSITION !default;
$notification-center-item-status-position-top:                     ($notification-center-item-padding-vertical + $INDICATOR-DOT-SIZE) !default;
$notification-center-item-status-position-left:                    ($notification-center-item-padding-horizontal - ($INDICATOR-DOT-SIZE + $INDICATOR-DOT-SPACING)) !default;

$notification-center-item-heading-font-size:                       $FS0 !default;
$notification-center-item-heading-line-height:                     $GLOBAL-HEADING-BASE-LINE-HEIGHT !default;
$notification-center-item-heading-font-weight:                     $GLOBAL-FONT-WEIGHT-BOLD !default;
$notification-center-item-heading-read-font-weight:                $GLOBAL-FONT-WEIGHT-REGULAR !default;
$notification-center-item-heading-link-text-decoration:            none !default;
$notification-center-item-heading-link-border-style:               none !default;
$notification-center-item-heading-spacing-right:                   null !default;

$notification-center-item-close-padding:                           $GLOBAL-SPACING-UNIT-XSMALL !default;
$notification-center-item-close-position-top:                      calc(#{$notification-center-item-padding-vertical} - #{$notification-center-item-close-padding}) !default;
$notification-center-item-close-position-right:                    floor($notification-center-item-padding-horizontal * 0.5) !default;
$notification-center-item-close-icon:                              get-icon(semantic-close) !default;
$notification-center-item-close-icon-size:                         icon-size(xsmall) !default;
$notification-center-item-close-color:                             $COLOR-BRAND !default;

$notification-center-item-timestamp-font-size:                     $FS-3 !default;
$notification-center-item-timestamp-font-weight:                   null !default;
$notification-center-item-timestamp-color:                         null !default;

$notification-center-item-mark-read-font-size:                     $FS-2 !default;

/**
 * [1] Position the Notification Center toggle in the header.
 * [2] Spacing to other header items (e.g. the context bar).
 * [3] Add space for the badge in smaller viewports so that it will not overlap
 *     the navigation toggle “hamburger” icon.
 * [4] Remove unwanted vertical space by making the icon a block element.
 * [5] Position the badge absolutely inside the toggle.
 * [6] Shift the badge horizontally or vertically if necessary. This can also be
 *     set separately for smaller viewports.
 * [7] Add shadow to the bottom or top if content is outside of the viewport.
 * [8] Make panel scrollable.
 * [9] Set panel `z-index` to overlay other elements (e.g. VW6 navigation layers).
 * [10] Fade panel in with a smooth transition in small viewports.
 *      `pointer-events` are disabled in the collapsed state.
 * [11] Push the first item down by the height of the fixed positioned panel
 *      heading.
 * [12] Fixate the panel header to the top and increase `z-index` so it always
 *      stays in front of the panel items.
 * [13] Set panel header padding to a responsive map or fixed value
 *      (default is `$page-wrap-padding-horizontal`)
 * [14] Increase specificity to allow button styles to be overwritten.
 * [15] Display all items below each other, no matter if they are block or
 *      inline elements.
 * [16] Set same spacings between inner item elements.
 * [17] Animate panel items in with a smooth transition in small viewports.
 * [18] Position the status point vertically centered to the first line of the
 *      article heading by dividing the responsive font size of the heading in
 *      half considering its line spacing.
 * [19] Change heading `font-weight` for read items.
 * [20] Remove bottom margin of last child element (e.g. a paragraph).
 * [21] Overwrite `.c-btn` font size.
 * [22] Push link to the left in flexbox context for brands (VW6) that use a
 *      border as link decoration.
 * [23] Show item overlay on hovering/focusing the
 *      `.c-notification-center__item__mark-read-toggle` button.
 */

.c-notification-center {
    display: flex; /* [1] */
    align-items: center; /* [1] */
    height: 100%; /* [1] */
}

    .c-notification-center__toggle {
        @include reset-button($remove-focus: false);
        position: relative; /* [5] */
        margin-left: $notification-center-toggle-spacing-left; /* [2] */

        @include media-query("<=#{$notification-center-breakpoint}") {
            margin-right: if($notification-center-toggle-spacing-right-at-breakpoint, $notification-center-toggle-spacing-right-at-breakpoint, null); /* [3] */
        }

        &::after {
            @include icon-font($defaults: null);
            @include font-size(
                $font-size: $notification-center-toggle-icon-size,
                $line-height: 1
            );
            content: $notification-center-toggle-icon;
            display: block; /* [4] */
            color: $notification-center-toggle-icon-color;

            @if ($notification-center-toggle-icon-size-at-breakpoint) {
                @include media-query("<=#{$notification-center-breakpoint}") {
                    @include font-size(
                        $font-size: $notification-center-toggle-icon-size-at-breakpoint,
                        $line-height: null
                    );
                }
            }
        }
    }

        .c-notification-center__toggle__label {
            @include a11y-hidden();
        }

        .c-notification-center__toggle__badge {
            position: absolute; /* [5] */
            top: $notification-center-toggle-badge-position-top; /* [5] */
            left: $notification-center-toggle-badge-position-left; /* [5] */
            margin-top: $notification-center-toggle-badge-vertical-offset; /* [6] */
            margin-left: $notification-center-toggle-badge-horizontal-offset; /* [6] */
            transform: $notification-center-toggle-badge-transform; /* [5] */

            @include media-query("<=#{$notification-center-breakpoint}") {
                margin-top: $notification-center-toggle-badge-vertical-offset-at-breakpoint; /* [6] */
                margin-left: $notification-center-toggle-badge-horizontal-offset-at-breakpoint; /* [6] */
            }
        }

    .c-notification-center__panel {
        @include scrollable( /* [7] */
            $container-height: 100%,
            $bg-color:         $notification-center-panel-background,
        );
        @include responsive-font-size($notification-center-panel-font-size);
        overflow: auto; /* [8] */
        border-radius: $notification-center-panel-border-radius;
        font-weight: $notification-center-panel-font-weight;

        @include media-query(">#{$notification-center-breakpoint}") {
            position: absolute;
            top: $notification-center-panel-position-top;
            right: 0;
            display: none;
            width: $notification-center-panel-width;
            max-width: $notification-center-panel-max-width;
            max-height: $notification-center-panel-max-height;
            margin-top: $notification-center-panel-spacing-top;
            border: if($notification-center-panel-border-color, 1px solid $notification-center-panel-border-color, null);
            box-shadow: $notification-center-panel-box-shadow;

            &#{$notification-center-panel-state-expanded} {
                display: block;
            }
        }

        @include media-query("<=#{$notification-center-breakpoint}") {
            @include transition($transition-property: opacity);
            position: fixed;
            z-index: $notification-center-panel-z-index-at-breakpoint; /* [9] */
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: none; /* [10] */
            opacity: 0; /* [10] */

            &#{$notification-center-panel-state-expanded} {
                pointer-events: auto; /* [10] */
                opacity: 1; /* [10] */
            }

            > .c-notification-center__item:first-of-type {
                margin-top: $notification-center-panel-header-height; /* [11] */
            }
        }
    }

        .c-notification-center__panel__header {
            position: fixed; /* [12] */
            z-index: 1; /* [12] */
            top: 0; /* [12] */
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: $notification-center-panel-header-height;
            border-bottom: if($notification-center-panel-header-border-color, 1px solid $notification-center-panel-header-border-color, null);
            background-color: $notification-center-panel-header-background;
            color: $notification-center-panel-header-color;

            @if (is-map($notification-center-panel-header-padding-horizontal)) {
                @include responsive-property(
                    ("padding-right", $notification-center-panel-header-padding-horizontal),
                    ("padding-left",  $notification-center-panel-header-padding-horizontal),
                ); /* [13] */
            } @else {
                padding-right: $notification-center-panel-header-padding-horizontal; /* [13] */
                padding-left: $notification-center-panel-header-padding-horizontal; /* [13] */
            }

            @include media-query(">#{$notification-center-breakpoint}") {
                display: none;
            }
        }

        .c-notification-center__panel__heading {
            @include responsive-font-size($notification-center-panel-heading-font-size);
            margin: 0;
            font-weight: $notification-center-panel-heading-font-weight;
        }

        /* stylelint-disable selector-max-class, selector-max-specificity */
        .c-notification-center__panel__close.c-notification-center__panel__close.c-notification-center__panel__close { /* [14] */
            @if ($notification-center-panel-close-font-size) {
                @include responsive-font-size($notification-center-panel-close-font-size);
            }
            color: $notification-center-panel-close-color;

            .c-btn__icon {
                @if ($notification-center-panel-close-icon-size) {
                    @include font-size(
                        $font-size:   $notification-center-panel-close-icon-size,
                        $line-height: 0
                    );
                }
                display: inline-flex;
                color: $notification-center-panel-close-icon-color;

                &:hover,
                &:active {
                    color: $notification-center-panel-close-icon-color-hover;
                }

                &::before {
                    @include icon-font();
                    content: $notification-center-panel-close-icon;
                }
            }

            .c-btn__text { /* [14] */
                color: $notification-center-panel-close-color;

                &:hover,
                &:active {
                    color: $notification-center-panel-close-color-hover;
                }
            }
        }
        /* stylelint-enable */

    .c-notification-center__item {
        @include responsive-font-size(
            $font-size:   $notification-center-item-font-size,
            $accept-null: true,
        );
        position: relative;
        display: flex; /* [15] */
        flex-direction: column; /* [15] */
        padding: $notification-center-item-padding-vertical $notification-center-item-padding-horizontal;

        @include media-query("<=#{$notification-center-breakpoint}") {
            transform: translateY($notification-center-item-initial-vertical-offset-at-breakpoint); /* [17] */
            transition: $notification-center-item-transition; /* [17] */

            .c-notification-center__panel#{$notification-center-panel-state-expanded} & {
                transform: translateY(0); /* [17] */
            }
        }

        + .c-notification-center__item {
            border-top: $notification-center-item-border;
        }

        // any element that has a previous sibling
        > * + * {
            margin-top: $notification-center-item-child-spacing; /* [16] */
            margin-bottom: 0; /* [16] */
        }

    }

        .c-notification-center__item__status {
            position: absolute;
            top: $notification-center-item-status-position-top; /* [18] */
            left: $notification-center-item-status-position-left;
        }

        .c-notification-center__item__heading {
            @include responsive-font-size($notification-center-item-heading-font-size, $notification-center-item-heading-line-height);
            margin-top: 0;
            margin-bottom: 0;
            margin-right: $notification-center-item-heading-spacing-right;
            font-weight: $notification-center-item-heading-font-weight;

            .c-notification-center__item--read & {
                font-weight: $notification-center-item-heading-read-font-weight; /* [19] */
            }
        }

        .c-notification-center__item__heading-link {
            border-style: $notification-center-item-heading-link-border-style;
            text-decoration: $notification-center-item-heading-link-text-decoration;
        }

        .c-notification-center__item__body {

            > :last-child {
                margin-bottom: 0; /* [20] */
            }
        }

        .c-notification-center__item__timestamp {
            @if ($notification-center-item-timestamp-font-size) {
               @include responsive-font-size($notification-center-item-timestamp-font-size);
            }
            font-weight: $notification-center-item-timestamp-font-weight;
            color: $notification-center-item-timestamp-color;
        }

        .c-notification-center__item__mark-read-toggle {
            @if ($notification-center-item-mark-read-font-size) {
                @include responsive-font-size($notification-center-item-mark-read-font-size); /* [21] */
            }
            margin-right: auto; /* [22] */

            @if ($notification-center-item-overlay-color) {
                position: static;

                &::before { /* [23] */
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: $notification-center-item-overlay-color;
                    transition: $notification-center-item-overlay-transition;
                    pointer-events: none;
                    opacity: 0;
                    mix-blend-mode: darken;
                }

                &:hover,
                &:focus {
                    mix-blend-mode: darken;

                    &::before {
                        opacity: 1; /* [23] */
                    }
                }
            }
        }

        .c-notification-content-center__item__close {
            @include reset-button(
                $revolver:     true,
                $remove-focus: false,
            );
            position: absolute;
            top: $notification-center-item-close-position-top;
            right: $notification-center-item-close-position-right;
            padding: $notification-center-item-close-padding;
            transform: translateX(50%);
            color: $notification-center-item-close-color;

            &::before {
                @include icon-font();
                content: $notification-center-item-close-icon;
                font-size: $notification-center-item-close-icon-size;
            }
        }

        .c-notification-content-center__item__close-label {
            @include a11y-hidden();
        }
