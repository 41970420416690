/* ------------------------------------*\
    #HEADER (Scopes)
\*------------------------------------ */

$scope-header-breakpoint:                      $GLOBAL-PAGE-HEAD-BREAKPOINT !default;
$scope-header-module-spacing:                  $GLOBAL-SPACING-UNIT-LARGE !default;
$scope-header-module-spacing-below-breakpoint: $GLOBAL-SPACING-UNIT-SMALL !default;
$scope-header-menu-trigger-position-end:       true !default;

/**
 * [1] Push to right side.
 * [2] No spacing between context-bar & notification-center
 *     to visually group them.
 * [3] Fallback for old markup (context-bar nested in header-nav) for positioning.
 * [4] Layout context-bar with only logo & context-bar in header.
 */
.c-header__content-wrapper {

    @include media-query(">#{$scope-header-breakpoint}") {
        .c-header__logo,
        .c-header__nav {

            &:not(:last-child) {
                margin-right: $scope-header-module-spacing;
            }
        }
    }

    @include media-query("<=#{$scope-header-breakpoint}") {
        .c-header__nav {
            @if ($scope-header-menu-trigger-position-end) {
                margin-left: $scope-header-module-spacing-below-breakpoint;
            }

            > .c-context-bar {
                order: -1; /* [3] */
                margin-right: $scope-header-module-spacing-below-breakpoint;
            }
        }
    }

    .c-context-bar,
    .c-header__notification-center {
        margin-left: auto; /* [1] */
    }

    .c-header__logo {

        + .c-context-bar { /* [4] */
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
        }

    }

}

.c-context-bar ~ .c-header__notification-center {

    @include media-query("<=#{$scope-header-breakpoint}") {
        margin-left: 0; /* [2] */
    }

}
