@use "sass:math" as *;

@mixin arrow($placement, $width, $color) {
    @if ($placement == "top") {
        border-top: $width solid $color;
        border-right: $width solid transparent;
        border-left: $width solid transparent;
    } @else if ($placement == "bottom") {
        border-bottom: $width solid $color;
        border-right: $width solid transparent;
        border-left: $width solid transparent;
    } @else if ($placement == "left") {
        border-left: $width solid $color;
        border-top: $width solid transparent;
        border-bottom: $width solid transparent;
    } @else if ($placement == "right") {
        border-right: $width solid $color;
        border-top: $width solid transparent;
        border-bottom: $width solid transparent;
    }
}

/**
 * [1] Align with arrow reference element.
 */
@mixin arrow-position($placement, $width, $isRoundArrow) {
    @if ($placement == "top") {
        bottom: -($width);
        left: 0; /* [1] */
    } @else if ($placement == "bottom") {
        top: -($width);
        left: 0; /* [1] */
    }

    @if ($isRoundArrow) {
        @if ($placement == "left") {
            right: -($width * 2);
        } @else if ($placement == "right") {
            left: -($width * 2);
        }
    } @else {
        @if ($placement == "left") {
            right: -($width);
        } @else if ($placement == "right") {
            left: -($width);
        }
    }
}

@mixin arrow-transform-origin($placement) {
    @if ($placement == "top") {
        transform-origin: 50% 0%;
    } @else if ($placement == "bottom") {
        transform-origin: 50% 100%;
    } @else if ($placement == "left") {
        transform-origin: 0% 50%;
    } @else if ($placement == "right") {
        transform-origin: 100% 50%;
    }
}

/// A mixin to generate an triangle from an element which can also have a border applied to it.
/// [1] The offset for a triangle with border needs to be calculated taking the border-width into account.
///     Since the triangle is a 45deg rotated square we need calculate it the following way:
///     Half of the triangle size + cathetus of the `border-width`, which is why we divide it with √2.
/// [2] We shift the triangle towards the placement side by the amount of the tooltip size
///     and correct it later with the offset.
/// [3] After rotation, offset triangle to point to the target.
/// [4] Align with arrow reference element.
///
/// @access public
///
/// @param {String} $placement - The placement for the arrow: top, right, bottom or left.
/// @param {Boolean} $border-width - The thickness of the border.
/// @param {Color} $border-color - The color of the border.
/// @param {Number} $size - The size of the arrow in pixels.
/// @param {Number} $border-radius - The radius of the arrow tip in pixels.
/// @param {Color} $color - The overall color of the arrow.
///
/// @example
///     .my-arrow--bordered {
///         @include arrow-with-border(
///             $placement,
///             $tippy-border-size,
///             $tippy-border-color,
///             $tippy-arrow-size,
///             $tippy-arrow-border-radius,
///             $tippy-arrow-color
///         );
///     }
@mixin arrow-with-border($placement, $border-width, $border-color, $size, $border-radius, $color) {
    $SQRT_TWO: 1.41421356237; /* stylelint-disable-line number-max-precision */
    $offset: (($size * 0.5) + div($border-width, $SQRT_TWO)); /* [1] */
    $border: $border-width solid $border-color;

    content: "";
    position: absolute;
    width: $size;
    height: $size;
    transform-origin: center;
    border-top: $border;
    border-left: $border;
    background-color: $color;
    border-top-left-radius: $border-radius;
    #{$placement}: 100%;  /* [2] */

    /* [1] [3] */
    @if ($placement == "top") {
        left: 0; /* [4] */
        transform: rotate(225deg) translate(0, $offset);
    } @else if ($placement == "bottom") {
        left: 0; /* [4] */
        transform: rotate(45deg) translate($offset, 0);
    } @else if ($placement == "left") {
        transform: rotate(135deg) translate($offset, 0);
    } @else if ($placement == "right") {
        transform: rotate(-45deg) translate(0, $offset);
    }

}
