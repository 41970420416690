/* ------------------------------------*\
    #COMBOBOX
\*------------------------------------ */

$skoda-combobox-input-background-color: $GLOBAL-INPUT-BACKGROUND !default;

$skoda-combobox-border-filled:          $GLOBAL-INPUT-BORDER-COLOR-FILLED !default;
$skoda-combobox-listbox-offset:         $GLOBAL-SPACING-UNIT-SMALL !default;

/**
 * [1] Apply `border-radius` to all sides, default only applies to bottom.
 * [2] Visually center of visual fake container (input + label).
 */
.c-combobox__wrapper {

    input[name="combobox-values"] {

        &[value]:not([value=""]) ~ .c-combobox:not(#{$combobox-disabled-state}):not(#{$combobox-readonly-state}):not(:focus)::before {
            border-color: $skoda-combobox-border-filled;
        }

    }

}

.c-combobox {

    .c-form-field & {
        background-color: $skoda-combobox-input-background-color;

        // Chevron
        &::after {
            position: absolute;
            top: 0; /* [2] */
            right: $combobox-padding-horizontal;
        }

    }

}


    .c-combobox__listbox-container {
        transform: translateY($skoda-combobox-listbox-offset);
        border-radius: $combobox-listbox-border-radius; /* [1] */
    }
