// /* ------------------------------------*\
//     #TOAST-NOTIFICATION (Settings)
// \*------------------------------------ */


$toast-notification-background:               $COLOR-BRAND-TERTIARY !default;

$toast-notification-padding-vertical:         $GLOBAL-SPACING-UNIT-SMALL !default;

$toast-notification-text-color:               $COLOR-WHITE !default;
$toast-notification-text-size:                map-get($FS-1, null) !default;
$toast-notification-text-line-height:         1.5 !default;

$toast-notification-close-icon-size:          icon-size(xxsmall) !default;
$toast-notification-close-animation-duration: $GLOBAL-TRANSITION-DURATION !default;

$toast-notification-themes-overwrites: (
    info: (
        "text-color":       $toast-notification-text-color,
        "background-color": $toast-notification-background,
    ),
    success: (
        "text-color":       $toast-notification-text-color,
        "background-color": $toast-notification-background,
    ),
    warning: (
        "text-color":       $toast-notification-text-color,
        "background-color": $toast-notification-background,
    ),
    error: (
        "text-color":       $toast-notification-text-color,
        "background-color": $toast-notification-background,
    ),
) !default;
