// /* ------------------------------------*\
//     #INTERACTION-LAYER
// \*------------------------------------ */


$interaction-layer-z-index:                       z("interaction-layer") !default;

// The drop-shadow of the interaction-layer
$interaction-layer-shadow:                        $DROP-SHADOW-SMALL !default;
$interaction-layer-box-shadow-size:               $DROP-SHADOW-SMALL-SIZE !default;

// Main background color of the interaction-layer
$interaction-layer-background-color:              $COLOR-WHITE !default;

$interaction-layer-backdrop-color:                $GLOBAL-BACKDROP-BACKGROUND !default;

// Color for interaction-layer foreground
$interaction-layer-content-color:                 $COLOR-BASE-TEXT !default;

// Close button icon
$interaction-layer-close-button-icon:             get-icon(semantic-close) !default;
$interaction-layer-close-button-icon-size:        icon-size(small) !default;
$interaction-layer-close-button-icon-color:       $COLOR-BASE-TEXT !default;
$interaction-layer-close-button-icon-color-hover: $interaction-layer-close-button-icon-color !default;

// The height of the fading gradient for scrolling
$interaction-layer-content-fade-height:           1.5em !default;

// Responsive spacings
$interaction-layer-padding-horizontal: (
    null: $GLOBAL-SPACING-UNIT-LARGE,
    s:    $GLOBAL-SPACING-UNIT-SMALL,
) !default;

$interaction-layer-padding-vertical: (
    null: $GLOBAL-SPACING-UNIT,
    s:    $GLOBAL-SPACING-UNIT-SMALL,
) !default;

$interaction-layer-content-padding: (
    null: $GLOBAL-SPACING-UNIT-LARGE,
    s:    $GLOBAL-SPACING-UNIT-SMALL,
) !default;

$interaction-layer-footer-padding: (
    null: $GLOBAL-SPACING-UNIT-LARGE,
    s:    $GLOBAL-SPACING-UNIT-SMALL,
) !default;

// Header spacings
$interaction-layer-header-padding-horizontal:     $interaction-layer-padding-horizontal !default;
$interaction-layer-header-padding-top:            $interaction-layer-padding-vertical !default;
$interaction-layer-header-padding-bottom:         0 !default;

// Content spacings
$interaction-layer-content-padding-horizontal:    $interaction-layer-content-padding !default;
$interaction-layer-content-padding-top:           $GLOBAL-SPACING-UNIT-XSMALL !default;

// Footer spacings
$interaction-layer-footer-padding-horizontal:     $interaction-layer-footer-padding !default;
$interaction-layer-footer-padding-vertical:       $interaction-layer-padding-vertical !default;
$interaction-layer-footer-padding-top:            $GLOBAL-SPACING-UNIT-XSMALL !default;
$interaction-layer-footer-padding-bottom:         $interaction-layer-footer-padding-vertical !default;

// Responsive width default
$interaction-layer-width: (
    null: 50vw,
    s:    calc(100vw - #{$GLOBAL-SPACING-UNIT}),
) !default;

// Responsive width small
$interaction-layer-width-small: (
    null: 33vw,
    s:    calc(100vw - #{$GLOBAL-SPACING-UNIT}),
) !default;

// Responsive width large
$interaction-layer-width-large: (
    null: 66vw,
    s:    calc(100vw - #{$GLOBAL-SPACING-UNIT}),
) !default;

$interaction-layer-max-width:                     $interaction-layer-width !default;
$interaction-layer-max-width-small:               $interaction-layer-width-small !default;
$interaction-layer-max-width-large:               $interaction-layer-width-large !default;

// Animation settings
$interaction-layer-show:                          "[aria-hidden='false']" !default;
$interaction-layer-transform-default:             translateX(-100%) !default;

$interaction-layer-button-bar-width:              100% !default;
$interaction-layer-button-bar-padding-vertical:   $GLOBAL-SPACING-UNIT-SMALL !default;
$interaction-layer-button-bar-shadow-offset-y:    -($GLOBAL-BOX-SHADOW-OFFSET-Y) !default;
$interaction-layer-button-bar-shadow:             $GLOBAL-BOX-SHADOW-OFFSET-X $interaction-layer-button-bar-shadow-offset-y $GLOBAL-BOX-SHADOW-BLUR-RADIUS $GLOBAL-BOX-SHADOW-SPREAD $GLOBAL-BOX-SHADOW-COLOR !default;

/**
 * [1]  The Interaction Layer is placed above every other element.
 * [2]  Remove browser based focus color in Chrome.
 * [3]  Align the content at the right side of the viewport.
 * [4]  If the content exceeds the viewport height, this still makes the
 *      content accessible (i.e. scrollable).
 * [5]  Prevent interaction with content when closed.
 * [6]  The content allows any kind of elements. This ensures, that
 *      elements carrying a default margin-bottom (`<p>` etc.) get
 *      consciously reset to let the surrounding padding do its job properly.
 * [7]  Avoid showing gradient when not necessary.
 * [8]  Indicate actions on non-interactive elements.
 * [9]  Prevent elements inside the `.js-interaction-layer-trigger` catching any
 *      events due to event delegation.
 * [10] Add styling to display a clickable icon button.
 * [11] Hide with slide out animation (left to right).
 * [12] Show with slide in animation (right to left).
 * [13] Add a modal backdrop layer.
 * [14] Always keep the button bar on the bottom of the interaction layer,
 *      regardless of the amount of content placed inside.
 * [15] Always show button bar in front of interaction layer content.
 * [16] Prevent overscroll in the container.
 * [17] Prevent horizontal shift when scrollbar gets visible during runtime
 *      by increased content height (e.g. expanding components).
 */

.c-interaction-layer__wrapper {
    position: fixed;
    z-index: $interaction-layer-z-index; /* [1] */
    top: 0;
    bottom: 0;

    &:focus { /* [2] */
        border: none;
        outline: none;
    }

    &#{$interaction-layer-show} {

        .c-interaction-layer {
            transform: $interaction-layer-transform-default;
        }
    }

}

.c-interaction-layer {
    @include responsive-property(
        ("width", $interaction-layer-width),
        ("max-width", $interaction-layer-max-width)
    );
    @include transition(transform);
    position: fixed;
    left: 100%;
    display: flex; /* [14] */
    flex-direction: column; /* [14] */
    height: 100vh;
    overflow-y: auto; /* [4] */
    transform: translateX(#{$interaction-layer-box-shadow-size});
    background-color: $interaction-layer-background-color;
    box-shadow: if($interaction-layer-shadow, $interaction-layer-shadow, null);
    overscroll-behavior: none; /* [16] */
    scrollbar-gutter: stable; /* [17] */
}

    .c-interaction-layer--large {

        .c-interaction-layer {
            @include responsive-property(
                ("width", $interaction-layer-width-large),
                ("max-width", $interaction-layer-max-width-large)
            );
        }

    }

    .c-interaction-layer--small {

        .c-interaction-layer {
            @include responsive-property(
                ("width", $interaction-layer-width-small),
                ("max-width", $interaction-layer-max-width-small)
            );
        }

    }

    .c-interaction-layer--modal {

        &::before { /* [13] */
            content: "";
            position: fixed;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: none;
            width: 100vw;
            height: 100vh;
            background-color: $interaction-layer-backdrop-color;
        }

        &[aria-hidden="false"]::before {
            display: block;
        }

    }

    .c-interaction-layer__header {
        @include responsive-property(
            ("padding-top", $interaction-layer-header-padding-top),
            ("padding-left", $interaction-layer-header-padding-horizontal),
            ("padding-right", $interaction-layer-header-padding-horizontal),
            ("padding-bottom", $interaction-layer-header-padding-bottom)
        );
    }

    .c-interaction-layer__close-btn {
        @include reset-button();
        cursor: pointer;

        &::before { /* [10] */
            @include font-size(
                $font-size: $interaction-layer-close-button-icon-size,
                $line-height: false
            );
            @include icon-font();
            content: $interaction-layer-close-button-icon;
            color: $interaction-layer-close-button-icon-color;
        }

        &:hover,
        &:focus {

            &::before {
                color: $interaction-layer-close-button-icon-color-hover;
            }

        }

    }

    .c-interaction-layer__content {
        @include responsive-property(
            ("padding-left", $interaction-layer-content-padding-horizontal),
            ("padding-right", $interaction-layer-padding-horizontal),
            ("padding-top", $interaction-layer-content-padding-top)
        );
        padding-bottom: $interaction-layer-content-fade-height; /* [7] */
        color: $interaction-layer-content-color;

        > *:last-child {
            margin-bottom: 0; /* [6] */
        }
    }

    .c-interaction-layer__footer {
        @include responsive-property(
            ("padding-top", $interaction-layer-footer-padding-top),
            ("padding-left", $interaction-layer-footer-padding-horizontal),
            ("padding-right", $interaction-layer-footer-padding-horizontal),
            ("padding-bottom", $interaction-layer-footer-padding-bottom)
        );

        position: relative;
    }

/**
 * Styles applied to a js-hook (which is a no-no) is an exception as the trigger
 * can be applied to arbitrary elements but need those additional styles to
 * work properly.
 */
.js-interaction-layer-trigger {
    cursor: pointer; /* [8] */

    * {
        pointer-events: none; /* [9] */
    }

}

.c-interaction-layer__button-bar {
    @include responsive-property(
        ("padding-left", $interaction-layer-padding-horizontal),
        ("padding-right", $interaction-layer-padding-horizontal),
    );
    position: sticky; /* [14] */
    z-index: 100; /* [15] */
    bottom: 0; /* [14] */
    width: $interaction-layer-button-bar-width;
    margin-top: auto; /* [14] */
    padding-top: $interaction-layer-button-bar-padding-vertical;
    padding-bottom: $interaction-layer-button-bar-padding-vertical;
    background-color: $interaction-layer-background-color;
    box-shadow: $interaction-layer-button-bar-shadow;

}
