// /* ------------------------------------*\
//     #CAROUSEL (Scope Settings)
// \*------------------------------------ */

// `pc` as shorthand for `product-card` scope be less verbose.
$skoda-carousel-pc-spacing-top:              $GLOBAL-SPACING-UNIT-XXSMALL !default;
$skoda-carousel-pc-control-buttons-spacing:  $GLOBAL-SPACING-UNIT-XXSMALL !default;
$skoda-carousel-pc-control-button-size:      map-get($ICON-SQUARE-FULL-SIZES, xsmall) !default;
$skoda-carousel-pc-control-icon-size:        icon-size(xsmall) !default;
$skoda-carousel-pc-dots-padding:             ($skoda-carousel-counter-padding * 0.5) !default;
$skoda-carousel-pc-count-font-color-current: $COLOR-BASE-TEXT !default;

/**
 * [1] Reset defaults.
 */
.c-product-card {

    .c-carousel {
        margin-bottom: $GLOBAL-SPACING-UNIT-LARGE;

        .flickity-page-dots {
            bottom: auto; /* [1] */
            right: auto; /* [1] */
            top: calc(100% + #{$skoda-carousel-pc-spacing-top});
            padding-top: $skoda-carousel-pc-dots-padding;
            padding-bottom: $skoda-carousel-pc-dots-padding;
            background-color: transparent;
        }

        .dot {
            height: auto;
            min-height: auto;

            /* stylelint-disable-next-line selector-max-class */
            &.is-selected {

                /* stylelint-disable-next-line selector-max-specificity, selector-max-class, max-nesting-depth */
                &::before {
                    color: important($skoda-carousel-pc-count-font-color-current, true);
                }

            }

        }

        .flickity-prev-next-button {
            width: $skoda-carousel-pc-control-button-size;
            height: $skoda-carousel-pc-control-button-size;

            &::before {
                font-size: $skoda-carousel-pc-control-icon-size;
            }

            /* stylelint-disable-next-line selector-max-class */
            &.next {
                right: $skoda-carousel-pc-control-buttons-spacing;
            }

            /* stylelint-disable-next-line selector-max-class */
            &.previous {
                left: $skoda-carousel-pc-control-buttons-spacing;
            }

        }

        .flickity-fullscreen-button {
            right: $skoda-carousel-pc-control-buttons-spacing;
            bottom: $skoda-carousel-pc-control-buttons-spacing;
        }

    }

}
