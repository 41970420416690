// /* ------------------------------------*\
//     #BUTTONS
// \*------------------------------------ */

$BUTTON-ITEM-SPACING:                      $GLOBAL-SPACING-UNIT-SMALL !default;
$BUTTON-ITEM-SPACING-VERTICAL:             $BUTTON-ITEM-SPACING !default;
$BUTTON-ITEM-SPACING-HORIZONTAL:           $BUTTON-ITEM-SPACING !default;

$BUTTON-GROUP-ITEM-SPACING:                $GLOBAL-SPACING-UNIT !default;
$BUTTON-GROUP-ITEM-SPACING-VERTICAL:       $BUTTON-GROUP-ITEM-SPACING !default;
$BUTTON-GROUP-ITEM-SPACING-HORIZONTAL:     $BUTTON-GROUP-ITEM-SPACING !default;
$BUTTON-GROUP-ITEM-BACKGROUND-COLOR:       transparent !default;
$BUTTON-GROUP-ITEM-BACKGROUND-COLOR-HOVER: transparent !default;
$BUTTON-LINE-HEIGHT:                       20px !default;
$BUTTON-TEXT-SIZE:                         $FS-1 !default;
$BUTTON-HEIGHT:                            $GLOBAL-INPUT-HEIGHT !default;
$BUTTON-MIN-WIDTH-IN-CONTAINER:            100px !default;
$BUTTON-MAX-WIDTH-IN-CONTAINER:            300px !default;
