/* ------------------------------------*\
    #INBOX-TABLE
\*------------------------------------ */

$skoda-inbox-table-border-color:                   $COLOR-BORDER !default;
$skoda-inbox-table-border-radius:                  $GLOBAL-BORDER-RADIUS !default;

$skoda-inbox-table-responsive-tr-transition:       $GLOBAL-TRANSITION !default;
$skoda-inbox-table-responsive-tr-padding:          $GLOBAL-SPACING-UNIT-SMALL !default;
$skoda-inbox-table-responsive-tr-background-hover: $COLOR-ROW-HOVER !default;

/**
 * [1] Increase specificity to override default table and inbox table styles (so sad).
 * [2] Use a pseudo element to add a border with rounded corners to the table.
 * [3] Disable EMIL borders based on `td, th` pseudo elements in favor of [2].
 * [4] Disable unneeded `::after` pseudo element in `th`s which prevents buttons
 *     from being clicked in the header.
 * [5] Remove column dividers between empty `th`s in `thead`. Because we are
 *     checking for `:empty`, the cells must not even contain whitespace.
 *     (This will change with browser support for CSS Selectors Level 4: https://drafts.csswg.org/selectors-4/#the-empty-pseudo)
 * [6] Small viewports: Add hover effect to the `tr` instead of `td, th`.
 *
 */

.c-inbox-table.c-inbox-table { /* [1] */

    table {
        position: relative; /* [2] */
        border: none; /* [3] */

        &::after { /* [2] */
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px solid $skoda-inbox-table-border-color;
            border-radius: $skoda-inbox-table-border-radius;
            pointer-events: none;
        }
    }

    /* stylelint-disable selector-max-specificity, selector-max-class */
    &.c-inbox-table.c-inbox-table.c-inbox-table.c-inbox-table { /* [1] */

        td {
            &::before {
                display: none; /* [3] */
            }
        }

        th {
            &::after {
                display: none; /* [4] */
            }

            &:empty::before {
                display: none; /* [5] */
            }

        }

    }

    /* stylelint-disable-next-line order/order */
    @include media-query("<=#{$inbox-table-breakpoint}") {

        tr {
            padding: $skoda-inbox-table-responsive-tr-padding;
            transition: $skoda-inbox-table-responsive-tr-transition; /* [6] */

            &:hover {
                background-color: $skoda-inbox-table-responsive-tr-background-hover; /* [6] */

                th, td {
                    background-color: transparent; /* [6] */
                }
            }
        }

        th, td {

            &:not(:last-child) {
                &::after {
                    display: none; /* [3] */
                }
            }

        }

        .c-table__btn.c-table__btn {
            top: $skoda-inbox-table-responsive-tr-padding;
            right: $skoda-inbox-table-responsive-tr-padding;
        }

    }
    /* stylelint-enable selector-max-specificity, selector-max-class */

}

