// /* ------------------------------------*\
//     #CUSTOM-RANGE-SLIDER
// \*------------------------------------ */

/**
 * [1] Show when outline for focus and when currently dragged (`--active`).
 * [2] Improve outline animation by applying a transparent outline without
 *     offset by default which gets animated. Otherwise the outline just
 *     disappears on "blur".
 * [3] No option for different padding directions in default.
 */

$skoda-custom-range-slider-thumb-size-hover:           $RANGE-SLIDER-THUMB-SIZE-HOVER !default;
$skoda-custom-range-slider-thumb-focus-offset:         4px !default;

$skoda-custom-range-slider-tooltip-padding-vertical:   $TOOLTIP-PADDING-VERTICAL !default;
$skoda-custom-range-slider-tooltip-padding-horizontal: $TOOLTIP-PADDING-HORIZONTAL !default;

.c-custom-range-slider {

}

    .c-custom-range-slider__thumb {

        .c-custom-range-slider:not([disabled]) & {

            &::before {
                /* [2] */
                @include focus-styles(
                    $outline-color:  transparent,
                    $outline-offset: 0,
                );
                @include transition(
                    $transition-duration: $custom-range-slider-thumb-transition-duration,
                );
            }

            &:active,
            &:hover,
            &:focus-visible {

                /* stylelint-disable-next-line selector-max-specificity */
                &::before {
                    width: $skoda-custom-range-slider-thumb-size-hover;
                    height: $skoda-custom-range-slider-thumb-size-hover;
                }

            }

            /* [1] */
            &:active,
            &:focus-visible {

                /* stylelint-disable-next-line selector-max-specificity */
                &::before {
                    @include focus-styles(
                        $outline-offset: $skoda-custom-range-slider-thumb-focus-offset,
                    );
                }

            }

        }

    }

    .c-custom-range-slider__tooltip-wrapper {
        padding: $skoda-custom-range-slider-tooltip-padding-vertical $skoda-custom-range-slider-tooltip-padding-horizontal; /* [3] */
    }
