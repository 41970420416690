/* ------------------------------------*\
    #PAGINATION
\*------------------------------------ */

$skoda-pagination-link-size:         36px !default;
$skoda-pagination-link-scale-active: $BUTTON-SCALE-ACTIVE !default;

/**
 * [1] Unset hover from default link.
 * [2] Focus styles handled via pseudo element.
 */
.c-pagination {

}

    .c-pagination__link {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:not([class*="c-pagination__link--stepper-"]) {
            width: $skoda-pagination-link-size;
            height: $skoda-pagination-link-size;
        }

        &[class*="c-pagination__link--stepper-"]:not(.is-disabled) {
            @include button-icon-states();
            scale: 1;

            &:hover,
            &:focus {
                background-color: important(transparent, true); /* [1] */
            }

            &:focus-visible {
                outline: 0; /* [2] */
            }

            &:active {
                scale: $skoda-pagination-link-scale-active;
            }

        }

    }
