/* ------------------------------------*\
    #CALLOUT
\*------------------------------------ */

/**
 * Use a callout to display a prominent message with a call to action.
 */

// Breakpoint
$callout-breakpoint:                       xs !default;

// Colors
$callout-color:                            $COLOR-WHITE !default;
$callout-background-color:                 $COLOR-BRAND !default;

// Spacing
$callout-spacing-vertical:                 $GLOBAL-SPACING-UNIT-LARGE !default;
$callout-spacing-vertical-at-breakpoint:   $GLOBAL-SPACING-UNIT !default;
$callout-spacing-horizontal:               $GLOBAL-SPACING-UNIT-XLARGE !default;
$callout-spacing-horizontal-at-breakpoint: $callout-spacing-vertical-at-breakpoint !default;
$callout-item-spacing:                     null !default;

// Media
$callout-media-margin-bottom:              $GLOBAL-SPACING-UNIT !default;

// Image
$callout-image-height:                     144px !default;

// Title
$callout-title-font-size:                  $GLOBAL-HEADING-H4-FONT-SIZE !default;
$callout-title-line-height:                $GLOBAL-HEADING-H4-LINE-HEIGHT !default;
$callout-title-spacing-bottom:             null !default;

/**
 * [1] Magic number defined in Abstract to limit the height of the image.
 * [2] Make the image always fit inside the element’s / container’s size.
 * [3] Set spacing for every direct child element of the callout
 *     except the last one. Lower the selector specificity via `:where`
 *     to make it overrideable via other selectors (see title and media).
 */
.c-callout {
    padding: $callout-spacing-vertical $callout-spacing-horizontal;
    text-align: center;
    background-color: $callout-background-color;
    color: $callout-color;

    @include media-query("<=#{$callout-breakpoint}") {
        padding: $callout-spacing-vertical-at-breakpoint $callout-spacing-horizontal-at-breakpoint;
    }

    > :where(* + *:not(:last-child)) { /* [3] */
        margin-bottom: $callout-item-spacing;
    }

}

    .c-callout__media {
        margin-bottom: $callout-media-margin-bottom;
    }

    .c-callout__image {
        height: important($callout-image-height, true); /* [1] */
        object-fit: contain; /* [2] */
    }

    .c-callout__title {
        @include responsive-font-size(
            $font-size: $callout-title-font-size,
            $line-height: $callout-title-line-height
        );
        margin-bottom: $callout-title-spacing-bottom;
    }
