/* ------------------------------------*\
    #TABS
\*------------------------------------ */

// The tab-bar
$tabs-nav-height:                      $GLOBAL-SPACING-UNIT !default;
$tabs-nav-background-color:            transparent !default;
$tabs-nav-bar-border:                  true !default;
$tabs-nav-bar-border-exceeds:          true !default;
$tabs-nav-border-width:                1px !default;
$tabs-nav-border-width-active:         2px !default;
$tabs-nav-border-width-hover:          null !default;
$tabs-nav-border-style:                solid !default;
$tabs-nav-border-color:                $COLOR-SUBTLE !default;
$tabs-nav-border-color-active:         $COLOR-BRAND !default;
$tabs-nav-border-color-hover:          $tabs-nav-border-color-active !default;
$tabs-nav-border-color-disabled:       $COLOR-SUBTLE !default;
$tabs-nav-border:                      $tabs-nav-border-width $tabs-nav-border-style $tabs-nav-border-color !default;

// The single tab-items in the tab-bar
$tabs-nav-item-text-size:              $FS-1 !default;
$tabs-nav-item-text-color:             $COLOR-BASE-TEXT !default;
$tabs-nav-item-text-color-active:      $COLOR-BRAND !default;
$tabs-nav-item-text-color-hover:       $tabs-nav-item-text-color-active !default;
$tabs-nav-item-text-color-disabled:    $COLOR-SUBTLE !default;
$tabs-nav-item-text-transform:         none !default;
$tabs-nav-item-font-weight:            $GLOBAL-FONT-WEIGHT-REGULAR !default;
$tabs-nav-item-font-weight-active:     $tabs-nav-item-font-weight !default;
$tabs-nav-item-spacing:                $GLOBAL-SPACING-UNIT-LARGE !default;
$tabs-nav-item-spacing-vertical:       null !default;
$tabs-nav-item-background-color-hover: $COLOR-SUBTLE !default;

// The content below the tab-bar
$tabs-content-padding-vertical:        $GLOBAL-SPACING-UNIT !default;
$tabs-content-padding-horizontal:      0 !default;
$tabs-content-padding:                 $tabs-content-padding-vertical $tabs-content-padding-horizontal !default;

// Icons in tab-nav-bar-items
// @TODO: define icon-color: all states
$tabs-icon-size:                       icon-size(small) !default;
$tabs-icon-stacked-size:               icon-size(medium) !default;
$tabs-icon-only-size:                  null !default;
$tabs-icon-spacing:                    $GLOBAL-SPACING-UNIT-XSMALL !default;
$tabs-icon-stacked-spacing:            $GLOBAL-SPACING-UNIT-XSMALL !default;

$tabs-nav-border-width-correction:     if($tabs-nav-border-width != 0, -($tabs-nav-border-width + 1), null) !default; /* [8] */

// Control-handles if the tab-bar is wider than the screen
$tabs-slider-background-color:         $COLOR-BASE-BACKGROUND !default;
$tabs-slider-arrow-size:               icon-size(xsmall) !default;
$tabs-slider-arrow-color:              null !default;

$tabs-nav-themes: (
    "alternative": (
        "nav-background-color":        null,
        "nav-slider-background-color": $COLOR-PALE,
        "item-background-color-hover": $COLOR-WHITE,
        "nav-bar-border-color":        $COLOR-BRAND,
        "nav-bar-border-color-active": $COLOR-WHITE,
        "nav-bar-border-color-hover":  $COLOR-WHITE,
        "nav-border-color-disabled":   $COLOR-INACTIVE
    ),
) !default;


/**
 * [1] We need to defensively reset any default list-style properties.
 * [2] Remove whitespace for inline(-block) elements.
 * [3] Draw the line through, it will be hidden by parents overflow hidden.
 *     This is the line that exceeds to the right.
 * [4] Divide the amount of spacing by two, so that the sum of both paddings
 *     equals the variable.
 * [5] The ::before pseudo element represents the border-bottom below each item.
 * [6] The ::after pseudo element represents the border that exceeds all the
 *     way to the right. To make this component compliant to the Bluelabel
 *     design, this exceeding border cannot be build with a border-bottom on the
 *     list element.
 * [7] Make the border long enough.
 * [8] Eliminate any additional margin inside the tab content.
 * [9] Place the text out of the viewport so that is still accessible.
 * [10] Enable/disable pointer events prev/next buttons.
 */
.c-tabs {

}

    .c-tabs__nav {
        overflow: hidden;
        margin: 0; /* [1] */
        padding: 0; /* [1] */
        font-size: 0; /* [2] */
        background-color: $tabs-nav-background-color;
        list-style: none;

        @include vary($loop: $tabs-nav-themes) {
            background-color: vary-get("nav-background-color");
        }

        .flickity-prev-next-button {
            position: absolute;
            top: 50%;
            height: 100%; /* [7] */
            margin-top: $tabs-nav-border-width-correction; /* [8] */
            transform: translateY(-50%);
            pointer-events: auto; /* [10] */

            &.next {
                right: 0;
                justify-content: flex-end;
                background: linear-gradient(90deg, rgba($tabs-slider-background-color, 0) 0%, rgba($tabs-slider-background-color, 1) 40%); /* [9] */

                @include vary($loop: $tabs-nav-themes) {
                    background: linear-gradient(90deg, rgba(vary-get("nav-slider-background-color"), 0) 0%, rgba(vary-get("nav-slider-background-color"), 1) 40%); /* [9] */
                }

            }

            &.previous {
                left: 0;
                justify-content: flex-start;
                background: linear-gradient(90deg, rgba($tabs-slider-background-color, 1) 60%, rgba($tabs-slider-background-color, 0) 100%); /* [9] */

                @include vary($loop: $tabs-nav-themes) {
                    background: linear-gradient(90deg, rgba(vary-get("nav-slider-background-color"), 1) 40%, rgba(vary-get("nav-slider-background-color"), 0) 100%); /* [9] */
                }

            }

            &:disabled {
                pointer-events: none; /* [10] */
                opacity: 0;
            }

            &::before {
                @include font-size($tabs-slider-arrow-size);
                color: $tabs-slider-arrow-color;
            }

        }

    }

    .c-tabs__nav-item {
        display: inline-flex;
        margin-bottom: 0;
        white-space: nowrap;
    }

    .c-tabs__nav-link {
        @include reset-button();
        @include responsive-font-size(
            $font-size:   $tabs-nav-item-text-size,
            $line-height: $tabs-nav-height
        );
        @include responsive-property( /* [4] */
            ("padding-left", responsive-property-modify($tabs-nav-item-spacing, $factor: 0.5)),
            ("padding-right", responsive-property-modify($tabs-nav-item-spacing, $factor: 0.5)),
            ("padding-top", responsive-property-modify($tabs-nav-item-spacing-vertical, $factor: 0.5)),
            ("padding-bottom", responsive-property-modify($tabs-nav-item-spacing-vertical, $factor: 0.5)),
        );

        position: relative;
        display: inline-block;
        font-weight: $tabs-nav-item-font-weight;
        text-decoration: none;
        text-transform: $tabs-nav-item-text-transform;
        color: $tabs-nav-item-text-color;

        &:hover,
        &:focus {
            background-color: if($tabs-nav-item-background-color-hover, $tabs-nav-item-background-color-hover, null);
            color: $tabs-nav-item-text-color-hover;

            @include vary($loop: $tabs-nav-themes) {
                background-color: vary-get("item-background-color-hover");
            }

            &::before {
                height: $tabs-nav-border-width-hover;
                background-color: $tabs-nav-border-color-hover;

                @include vary($loop: $tabs-nav-themes) {
                    background-color: vary-get("nav-bar-border-color-hover");
                }
            }

        }

        &.is-active {
            font-weight: $tabs-nav-item-font-weight-active;
            color: $tabs-nav-item-text-color-active;

            &::before {
                height: $tabs-nav-border-width-active;
                background-color: $tabs-nav-border-color-active;

                @include vary($loop: $tabs-nav-themes) {
                    background-color: vary-get("nav-bar-border-color-active");
                }
            }

        }

        @if ($tabs-nav-bar-border) {
            &::before { /* [5] */
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                display: block;
                width: 100%;
                height: $tabs-nav-border-width;
                background-color: $tabs-nav-border-color;

                @include vary($loop: $tabs-nav-themes) {
                    background-color: vary-get("nav-bar-border-color");
                }
            }

            @if ($tabs-nav-bar-border-exceeds) { /* [3] */ /* [6] */
                &::after {

                    .c-tabs__nav-item:last-child & {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 100%;
                        display: block;
                        width: 99999px; /* [7] */ /* stylelint-disable-line scale-unlimited/declaration-strict-value */
                        height: $tabs-nav-border-width;
                        background-color: $tabs-nav-border-color;

                        @include vary($loop: $tabs-nav-themes) {
                            background-color: vary-get("nav-bar-border-color");
                        }
                    }

                }

            }
        }

        &.is-disabled {
            color: $tabs-nav-item-text-color-disabled;
            cursor: default;
            pointer-events: none;

            &::before {
                background-color: $tabs-nav-border-color-disabled;

                @include vary($loop: $tabs-nav-themes) {
                    background-color: vary-get("nav-border-color-disabled");
                }
            }

        }

        .c-tabs__nav.c-tabs__nav--icons & {
            display: flex;
            align-items: center;
        }

        .c-tabs__nav.c-tabs__nav--icons-stacked & {
            @include responsive-font-size(
                $font-size:   $tabs-nav-item-text-size,
                $line-height: 1
            );
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: $tabs-icon-stacked-spacing;
        }

        .c-tabs__nav--icons-only & {
            @include responsive-font-size(
                $font-size:   $tabs-nav-item-text-size,
                $line-height: 1
            );
            padding-top: $tabs-icon-spacing;
            padding-bottom: $tabs-icon-spacing;

            .c-tabs__text {
                position: absolute;
                left: -100vw; /* [9] */
            }

        }

    }

    .c-tabs__icon {

        .c-tabs__nav.c-tabs__nav--icons & {
            @include font-size(
                $font-size:   $tabs-icon-size,
                $line-height: 1
            );
            margin-right: $tabs-icon-spacing;
        }

        .c-tabs__nav.c-tabs__nav--icons-stacked & {
            @include font-size(
                $font-size:   $tabs-icon-stacked-size,
                $line-height: 1
            );
            margin-top: $tabs-icon-stacked-spacing;
            margin-bottom: $tabs-icon-stacked-spacing;
        }

        .c-tabs__nav.c-tabs__nav--icons-only & {
            @if ($tabs-icon-only-size) {
                @include font-size(
                    $font-size:   $tabs-icon-only-size,
                    $line-height: 1
                );
            }
        }


    }

    .c-tabs__content-wrapper {

    }

    .c-tabs__content {
        display: block;
        padding: $tabs-content-padding;

        > *:last-child {
            margin-bottom: 0; /* [8] */
        }

        &[aria-hidden="true"] {
            display: none;
        }

    }
