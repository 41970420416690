/* ------------------------------------*\
    #BUTTON-GROUP
\*------------------------------------ */

$skoda-button-group-item-background-color-checked-hover: $BUTTON-SECONDARY-BACKGROUND-COLOR-HOVER !default;
$skoda-button-group-item-background-color-focus:         $button-group-item-background-color !default;

.c-button-group {

}

    .c-button-group__label {

        .c-button-group__input:focus-visible ~ & {
            @include focus-styles();
        }

        .c-button-group__input:checked + &:hover {
            border-color: $skoda-button-group-item-background-color-checked-hover;
            background-color: $skoda-button-group-item-background-color-checked-hover;
        }

        .c-button-group__input:not(:checked):focus + & {
            background-color: $skoda-button-group-item-background-color-focus;
        }

    }
