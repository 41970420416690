/* ------------------------------------*\
    #TOOLTIP
\*------------------------------------ */

/**
 * These are the styles exclusively for the tooltip link, i.e. the trigger for
 * the tooltip popup. The styles for the tooltip popup are handled by Tippy.js
 * to be found in the lib-folder .
 */

$tooltip-text-color:      inherit !default;
$tooltip-text-decoration: underline !default;
$tooltip-text-cursor:     pointer !default;

.c-tooltip {
    @include reset-button();
    text-decoration: $tooltip-text-decoration;
    color: $tooltip-text-color;
    cursor: $tooltip-text-cursor;
}
