/* ------------------------------------*\
    #BUTTON-CONTAINER
\*------------------------------------ */

$button-container-spacing-horizontal: $BUTTON-ITEM-SPACING-VERTICAL !default;
$button-container-spacing-vertical:   $BUTTON-ITEM-SPACING-HORIZONTAL !default;

// Derived from what made sense visually, e.g. in Card context.
$button-container-button-min-width:   $BUTTON-MIN-WIDTH-IN-CONTAINER !default;
$button-container-button-max-width:   $BUTTON-MAX-WIDTH-IN-CONTAINER !default;
$button-container-breakpoint:         s !default;
$button-container-nav-alignment:      center !default;
$button-container-nav-button-width:   max-content !default;

/**
 * [1] If it’s the only button, limit its width on larger viewports.
 * [2] Show last button (usually CTA) on top when buttons are stacked vertically.
 * [3] Combine positioning options for nav modifier based on defined alignment.
 *     This allows flexibility for brands with one-markup.
 * [4] Make sure that the buttons in a navigational context
 *     collapse to its content.
 * [5] Set a max-width for the nav buttons as we allow them
 *     to grow via its content. Allow full width buttons for small
 *     viewports as they are stacked on top each other but
 *     limit them on larger viewports.
 * [6] On larger viewports we allow the button to grow up to its half-width
 *     depending on the but assure also a min-width.
 * [7] Both reverse modifier share the same reversed flex direction.
 * [8] Apply `row-reverse` only for non-vertical directions and bigger viewports
 *     and reset the button spacings accordingly.
 * [9] Override the @extend for smaller viewports as the nav-variant always
 *      uses centered items.
 * [10] Pull the reversed items to the opposite side.
 * [11] Wrap if the items exceed the available space.
 * [12] Make the button at least as wide to fit the content inside the current available space.
 * [13] The stretch variant forces a full-width layout.
 */
.o-button-container {
    display: flex;
    flex-wrap: wrap; /* [11] */
    align-items: center;
    width: important(100%, true);
    column-gap: $button-container-spacing-horizontal;
    row-gap: $button-container-spacing-vertical;
}

.o-button-container--stretch { /* [13] */

    .o-button-container__button {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        width: 100%;
        max-width: initial;
    }

}

.o-button-container--nowrap {
    flex-wrap: nowrap;
}

.o-button-container--wrap-reverse {
    flex-wrap: wrap-reverse;
}

.o-button-container--center {
    justify-content: center;
}

.o-button-container--left {
    justify-content: flex-start;
}

.o-button-container--right {
    justify-content: flex-end;
}

.o-button-container--nav {
    @extend .o-button-container--#{$button-container-nav-alignment}; /* [3] */ /* stylelint-disable-line scss/at-extend-no-missing-placeholder */

    @include media-query("<=#{$button-container-breakpoint}") {
        flex-direction: column-reverse;
        align-items: center /* [9] */;

        .o-button-container__button {
            width: 100%;
        }

    }

    .o-button-container__button {
        flex-grow: 0;

        @include media-query(">#{$button-container-breakpoint}") {
            width: $button-container-nav-button-width; /* [4] */
            max-width: 50%; /* [5] */
        }

    }

}

.o-button-container--reverse {
    flex-direction: row-reverse; /* [8] */

    &.o-button-container--left {

        .o-button-container__button:first-child {
            margin-right: auto; /* [10] */
        }

    }

    &.o-button-container--right {

        .o-button-container__button:last-child {
            margin-left: auto; /* [10] */
        }

    }

    /* stylelint-disable-next-line order/order */
    @include media-query("<=#{$button-container-breakpoint}") {

        &.o-button-container--left\@s {

            .o-button-container__button:first-child {
                margin-right: auto; /* [10] */
            }

        }

        &.o-button-container--right\@s {

            .o-button-container__button:last-child {
                margin-left: auto; /* [10] */
            }

        }

    }

}

.o-button-container--vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &.o-button-container--left {
        align-items: flex-start;
    }

    &.o-button-container--center {
        align-items: center;
    }

    &.o-button-container--right {
        align-items: flex-end;
    }

    &.o-button-container--reverse {
        flex-direction: column-reverse;
    }

    /* stylelint-disable-next-line order/order */
    @include media-query("<=#{$button-container-breakpoint}") {

        &.o-button-container--left\@s {
            align-items: flex-start;
        }

        &.o-button-container--center\@s {
            align-items: center;
        }

        &.o-button-container--right\@s {
            align-items: flex-end;
        }

        &.o-button-container--reverse\@s {
            flex-direction: column-reverse;
        }

    }
}

// Modifiers for small viewports only.
.o-button-container--left\@s {

    @include media-query("<=#{$button-container-breakpoint}") {
        justify-content: flex-start;
    }

}

.o-button-container--center\@s {

    @include media-query("<=#{$button-container-breakpoint}") {
        justify-content: center;
    }

}

.o-button-container--right\@s {

    @include media-query("<=#{$button-container-breakpoint}") {
        justify-content: flex-end;
    }

}

.o-button-container--vertical\@s {

    @include media-query("<=#{$button-container-breakpoint}") {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        &.o-button-container--left,
        &.o-button-container--left\@s {
            align-items: flex-start;
        }

        &.o-button-container--center,
        &.o-button-container--center\@s {
            align-items: center;
        }

        &.o-button-container--right,
        &.o-button-container--right\@s {
            align-items: flex-end;
        }

        &.o-button-container--reverse,
        &.o-button-container--reverse\@s {
            flex-direction: column-reverse;
        }

    }

}

.o-button-container--reverse\@s { /* [7] */

    @include media-query("<=#{$button-container-breakpoint}") {
        flex-direction: row-reverse; /* [8] */

        &.o-button-container--left,
        &.o-button-container--left\@s {

            .o-button-container__button:first-child {
                margin-right: auto; /* [10] */
            }

        }

        &.o-button-container--right,
        &.o-button-container--right\@s {

            .o-button-container__button:last-child {
                margin-left: auto; /* [10] */
            }

        }

    }

}

.o-button-container--stretch\@s {

    @include media-query("<=#{$button-container-breakpoint}") {

        .o-button-container__button {
            width: unquote("max(#{$button-container-button-min-width}, #{calc(100% - #{$button-container-spacing-horizontal})})"); /* [6] */
            max-width: initial;
        }

    }

}

// Basic button styles.
.o-button-container__button {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    width: unquote("max(#{$button-container-button-min-width}, #{calc(50% - #{$button-container-spacing-horizontal * 0.5})})"); /* [6] */
    max-width: $button-container-button-max-width;
    min-width: fit-content; /* [12] */ /* stylelint-disable-line */

    @include media-query(">#{$button-container-breakpoint}") {

        &:only-child {
            max-width: 50%; /* [1] */
        }

    }

}
