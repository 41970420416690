/* ------------------------------------*\
    #INPUT
\*------------------------------------ */

$skoda-input-height:                  $GLOBAL-INPUT-HEIGHT-STATIC-LABEL !default;
$skoda-input-padding-bottom:          $GLOBAL-INPUT-PADDING-VERTICAL !default;
$skoda-input-padding-top:             (($skoda-input-padding-bottom * 2) + $GLOBAL-INPUT-LABEL-SIZE) !default;
$skoda-input-label-offset-horizontal: $GLOBAL-INPUT-PADDING-HORIZONTAL !default;
$skoda-input-border-filled:           $GLOBAL-INPUT-BORDER-COLOR-FILLED !default;
$skoda-input-border-width-error:      $GLOBAL-INPUT-BORDER-WIDTH-ERROR !default;
$skoda-input-border-radius:           $GLOBAL-INPUT-BORDER-RADIUS !default;

$skoda-input-addon-min-width:         calc(4ch + #{$GLOBAL-INPUT-ADDON-PADDING-HORIZONTAL * 2}) !default;

$skoda-input-select-icon-position:    0 !default;

/**
 * [1] Placeholder, non-filled.
 *     `:placeholder-shown` can not be utilized due to partly custom element
 *     structure with wrappers and pseudo-elements.
 * [2] Filled inputs.
 * [3] Vertically center next to input.
 * [4] Visually center of visual fake container (input + label)
 *     in form-field context.
 * [5] Exclude height adjustment for combobox (search) to use regular input height..
 * [6] Addon can contain 4 characters while maintaining alignment with other inputs.
 *     See input component notes for details.
 */
.c-input {

    &,
    &.c-input--reversed {

        /* stylelint-disable-next-line selector-max-class */
        .c-input__input.c-input__input,
        .c-input__addon {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            line-height: 1;
        }

    }


    &.c-input--select {

        @at-root .c-form-field:not(.c-form-field--inline) & {

            &::after {
                top: $skoda-input-select-icon-position; /* [4] */
                transform: none; /* [4] */
            }

        }

    }

}

    .c-input__addon {

        .c-input__input:where([disabled], [readonly]) ~ &:not(.c-input__addon--no-background) {
            min-width: $skoda-input-addon-min-width; /* [6] */
        }

        // Replicate some styles from the `button-icon-states()` mixin
        // due to the complex selector/conditions.
        &:where(button):has(.c-icon) {

            .c-icon {
                @include button-icon-states(
                    $focus-state:     false, // Focus handled via button
                    $handle-position: false,
                );

                position: relative;

                &::after {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

            }

            &:focus-visible {
                outline: 0;

                /* stylelint-disable-next-line selector-max-specificity */
                .c-icon::after {
                    @include focus-styles();
                }

            }

            // Overwrite default
            &::after {
                display: block;
            }

        }

    }

    .c-form-field:not(.c-form-field--inline):not(.c-form-field--no-floating):not(.c-form-field--textarea) {

        /* stylelint-disable-next-line selector-max-specificity */
        .c-input__input {
            padding-top: $skoda-input-padding-top;
            padding-bottom: $skoda-input-padding-bottom;
        }

    }

    .c-form-field.c-form-field--textarea {

        .c-input__input {
            padding-top: $skoda-input-padding-top;
        }

    }

    .c-form-field--inline {

        .c-form-field__label {
            transform: none; /* [3] */
        }

    }

    .c-form-field--static {

        .c-form-field__label {
            margin-bottom: $form-field-label-spacing;
        }

    }

    .c-form-field:not(.c-form-field--no-floating, .c-form-field--inline, .c-form-field--static) .c-form-field__label {
        position: absolute; /* [3] */
    }

    .c-form-field--no-floating:not(.c-form-field--static) {

        // Mimic "fake" container to create same look as if the label is
        // moved inside container (floating label).
        .c-form-field__label {
            padding-top: $form-field-label-spacing;
            padding-left: $skoda-input-label-offset-horizontal;
            padding-right: $skoda-input-label-offset-horizontal;
            transform: none;
            border-top-left-radius: $skoda-input-border-radius;
            border-top-right-radius: $skoda-input-border-radius;
            background-color: $input-background;
        }

        .c-input__input {
            height: $skoda-input-height;
        }

        .c-combobox .c-input__input {
            height: $input-height; /* [5] */
        }

        .c-input__input,
        .c-input__addon {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

    }

    /* stylelint-disable selector-max-specificity */
    .c-input__input:not(.c-inline-editable__input, .is-error, [disabled], [readonly], :focus) {

        /* [1] */
        &:not([value]),
        &[value=""] {

        }

        /* [2] */
        &[value]:not([value=""]),
        &:not(:placeholder-shown) {

            &,
            ~ .c-input__addon {
                border-color: $skoda-input-border-filled;
            }
        }

    }

    .c-input__input.is-error {

        &[value]:not([value=""]),
        &:not(:placeholder-shown) {

            &,
            ~ .c-input__addon,
            ~ .c-input__state-icon {
                border-bottom-width: $skoda-input-border-width-error;
            }
        }

    }
    /* stylelint-enable selector-max-specificity */

    .c-input__state-icon,
    .c-input__addon {
        padding-right: $input-state-icon-padding;

        .c-input--reversed & {
            padding-left: $input-state-icon-padding;
            padding-right: 0;
        }

        .c-input--reversed &,
        &:last-child {
            border-bottom-right-radius: 0;
        }

    }

    button.c-input__addon,
    a.c-input__addon {
        text-decoration: underline;

        &:focus-visible {
            @include focus-styles();
        }

    }
