/* ------------------------------------*\
    #FLOATING-BAR
\*------------------------------------ */

$floating-bar-breakpoint:       s !default;
$floating-bar-background-color: $COLOR-BASE-BACKGROUND !default;
$floating-bar-shadow:           $GLOBAL-BOX-SHADOW !default;
$floating-bar-border-bottom:    none !default;
$floating-bar-visible-selector: intersection(outside, above-leave) !default;
$floating-bar-transition:       opacity $GLOBAL-TRANSITION-DURATION $GLOBAL-TRANSITION-TIMING-FUNCTION, transform $GLOBAL-TRANSITION-DURATION $GLOBAL-TRANSITION-TIMING-FUNCTION !default;
$floating-bar-icon:             get-icon(semantic-collapse) !default;
$floating-bar-icon-color:       $COLOR-BRAND !default;
$floating-bar-icon-size: (
    null:                 icon-size(medium),
    $fold-out-breakpoint: icon-size(small)
) !default;
$floating-bar-spacing-horizontal: (
    null:                     $GLOBAL-SPACING-UNIT-LARGE,
    $floating-bar-breakpoint: $GLOBAL-SPACING-UNIT-SMALL
) !default;
$floating-bar-spacing-vertical: (
    null:                     $GLOBAL-SPACING-UNIT-SMALL,
    $floating-bar-breakpoint: $GLOBAL-SPACING-UNIT-XSMALL
) !default;
/**
 * [1] FLIP the transform to hoist element in the transform context to optimise transition. https://aerotwist.com/blog/flip-your-animations/
 * [2] Disable potential links styles.
 * [3] Display the action icon here flush-right aligned with the page-wrap.
 */

.c-floating-bar {
    @include responsive-property(
        ("padding-top", $floating-bar-spacing-vertical),
        ("padding-bottom", $floating-bar-spacing-vertical)
    );
    position: fixed;
    z-index: z("floating-bar");
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    transform: translateY(-100%); /* [1] */
    border-bottom: $floating-bar-border-bottom; /* [2] */
    text-decoration: none; /* [2] */
    background-color: $floating-bar-background-color;
    transition: $floating-bar-transition;
    opacity: 0;
    box-shadow: $floating-bar-shadow;

    &#{$floating-bar-visible-selector} {
        transform: translateY(0); /* [1] */
        opacity: 1;
    }


}

    .c-floating-bar__inner {
        @include page-wrap();

        display: flex;
        justify-content: space-between;
        align-items: center;

        &::after { /* [3] */
            @include icon-font();
            @include responsive-property(
                ("flex-basis", $floating-bar-icon-size),
                ("font-size", $floating-bar-icon-size),
                ("margin-left", $floating-bar-spacing-horizontal)
            );
            content: $floating-bar-icon;
            color: $floating-bar-icon-color;
        }

    }
