/* ------------------------------------*\
    #UNORDERED-LIST
\*------------------------------------ */

// List item settings
$unordered-list-indentation:           null !default;
$unordered-list-item-spacing-vertical: null !default;
$unordered-list-item-indentation:      null !default;

// List icon settings
$unordered-list-use-icon-font:         false !default;
$unordered-list-icon:                  null !default;
$unordered-list-icon-size:             icon-size(small) !default;
$unordered-list-icon-line-height:      null !default;
$unordered-list-icon-font-weight:      $GLOBAL-FONT-WEIGHT-REGULAR !default;
$unordered-list-icon-offset:           -($unordered-list-icon-size) !default;
$unordered-list-icon-base-color:       inherit !default;

/**
 * [1] Make sure the selector is also applied to nested `<ul>` elements.
 * [2] If a custom list icon is set, we explicitly set the list’s `list-style` to `none`,
 *     otherwise we do fall back to the inherited `list-style` of the `ul`.
 * [2] Unset the initial `margin-left` from the `ul`.
 * [3] Check if a custom list icon is set. If so, disable `list-style` on the list
 *     and apply a custom icon via pseudo element.
 * [4] Whether to use an icon from the icon set or (a) custom char(s).
 *     In the case of custom char(s) any text-level styling should happen in the brand itself,
 *     as the default component cannot augment any possible style combination.
 * [5] Style only unordered list items.
 */
.c-ul {

    &,
    ul { /* [1] */
        margin-left: $unordered-list-indentation; /* [2] */
        list-style: if($unordered-list-icon, none, null); /* [3] */

        > li { /* [5] */
            margin-top: $unordered-list-item-spacing-vertical;
            margin-left: $unordered-list-item-indentation;

            @if ($unordered-list-icon) { /* [3] */
                position: relative;

                &::before {
                    content: $unordered-list-icon;
                    position: absolute;
                    left: $unordered-list-icon-offset;
                    color: $unordered-list-icon-base-color;

                    @if ($unordered-list-use-icon-font) { /* [4] */
                        @include icon-font();
                        @include font-size($font-size: $unordered-list-icon-size, $line-height: $unordered-list-icon-line-height);
                    } @else {
                        font-weight: $unordered-list-icon-font-weight;
                    }

                }

            }

        }

    }

}
