// /* ------------------------------------*\
//     #BASE-LINK
// \*------------------------------------ */

// Base settings
$link-color:                     inherit !default;
$link-cursor:                    pointer !default;
$link-text-decoration:           underline !default;
$link-text-decoration-thickness: null !default;
$link-text-underline-offset:     null !default;
$link-border-bottom:             null !default;
$link-border-bottom-color:       null !default;
$link-spacing-bottom:            null !default;
$link-position-context:          null !default;
$link-has-transition:            false !default;

// hover settings
$link-border-color-hover:        null !default;
$link-color-hover:               null !default;

// focus settings
$link-border-color-focus:        null !default;
$link-color-focus:               null !default;

// active settings
$link-border-color-active:       null !default;
$link-color-active:              null !default;

// additional theming
$link-themes: () !default;

/// A mixin to generate basic links styles.
///
/// @access public
/// @param {Boolean} $important [null] Use `!important` on declarations.
@mixin base-link($important: null) {
    position: important($link-position-context, $important);
    padding-bottom: important($link-spacing-bottom, $important);
    border-bottom: important($link-border-bottom, $important);
    text-decoration: important($link-text-decoration, $important);
    text-decoration-thickness: $link-text-decoration-thickness;
    text-underline-offset: $link-text-underline-offset;
    color: important($link-color, $important);
    cursor: important($link-cursor, $important);
    border-bottom-color: important($link-border-bottom-color, $important); /* [2] */

    @if ($link-has-transition) {
        @include transition();
    }

    @if ($link-themes) {
        &:where(:not(.c-btn)) {
            @include vary($loop: $link-themes) {
                color: important(vary-get("text-color"), $important);
            }
        }
    }

    &:hover {
        color: important($link-color-hover, $important);
        border-bottom-color: important($link-border-color-hover, $important); /* [2] */
    }

    &:focus {
        color: important($link-color-focus, $important);
        border-bottom-color: important($link-border-color-focus, $important); /* [2] */
    }

    &:active {
        color: important($link-color-active, $important);
        border-bottom-color: important($link-border-color-active, $important); /* [2] */
    }

    // Additional theming via @content.
    @content;

}
