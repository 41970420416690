// /* ------------------------------------*\
//     #MEDIA-QUERY-TOOLS
// \*------------------------------------ */

// We are using include-media as media-query manager:
// https://include-media.com/

// Defines a number to be added or subtracted from each unit when declaring
// breakpoints with exclusive intervals
@use "sass:math" as *;

$unit-intervals: (
    "px": 1,
    "em": 0.01,
    "rem": 0.1
) !default;

// Defines whether support for media queries is available, useful for creating
// separate stylesheets for browsers that don't support media queries.
$im-media-support: true !default;

// Selects which breakpoint to emulate when support for media queries is
// disabled. Media queries that start at or intercept the breakpoint will be
// displayed, any others will be ignored.
$im-no-media-breakpoint: "m" !default;

/// Selects which media expressions are allowed in an expression for it to be
// used when media queries are not supported.
$im-no-media-expressions: ("screen", "portrait", "landscape") !default;

// Log a message either with `@error` if supported else with `@warn`,
// using `feature-exists('at-error')` to detect support.
@function log($message) {
    @if feature-exists("at-error") {
        @error ($message);
    } @else {
        @warn($message);
        $_: noop();
    }

    @return $message;
}

// Wrapper mixin for the log function so it can be used with a more friendly
// API than `@if log('..') {}` or `$_: log('..')`. Basically, use the function
// within functions because it is not possible to include a mixin in a function
// and use the mixin everywhere else because it's much more elegant.
@mixin log($message) {
    @if log($message) {}
}

// Function with no `@return` called next to `@warn` in Sass 3.3
// to trigger a compiling error and stop the process.
@function noop() {}

// Determines whether a list of conditions is intercepted by the static breakpoint.
@function im-intercepts-static-breakpoint($conditions...) {
    $no-media-breakpoint-value: map-get($BREAKPOINTS, $im-no-media-breakpoint);

    @if not $no-media-breakpoint-value {
        @if log('`#{$im-no-media-breakpoint}` is not a valid breakpoint.') {}
    }

    @each $condition in $conditions {
        @if not map-has-key($MEDIA-EXPRESSIONS, $condition) {
            $operator: get-expression-operator($condition);
            $prefix: get-expression-prefix($operator);
            $value: get-expression-value($condition, $operator);

            @if ($prefix == "max" and $value <= $no-media-breakpoint-value) or
                ($prefix == "min" and $value > $no-media-breakpoint-value) {
                @return false;
            }
        }
        @else if not index($im-no-media-expressions, $condition) {
            @return false;
        }
    }

    @return true;
}

// Get operator of an expression
@function get-expression-operator($expression) {
    @each $operator in (">=", ">", "<=", "<", "≥", "≤") {
        @if str-index($expression, $operator) {
            @return $operator;
        }
    }

    // It is not possible to include a mixin inside a function, so we have to
    // rely on the `log(..)` function rather than the `log(..)` mixin. Because
    // functions cannot be called anywhere in Sass, we need to hack the call in
    // a dummy variable, such as `$_`. If anybody ever raise a scoping issue with
    // Sass 3.3, change this line in `@if log(..) {}` instead.
    $_: log("No operator found in `#{$expression}`.");
}

// Get dimension of an expression, based on a found operator
@function get-expression-dimension($expression, $operator) {
    $operator-index: str-index($expression, $operator);
    $parsed-dimension: str-slice($expression, 0, $operator-index - 1);
    $dimension: "width";

    @if str-length($parsed-dimension) > 0 {
        $dimension: $parsed-dimension;
    }

    @return $dimension;
}

// Get dimension prefix based on an operator
@function get-expression-prefix($operator) {
    @return if(index(("<", "<=", "≤"), $operator), "max", "min");
}

// Get value of an expression, based on a found operator
@function get-expression-value($expression, $operator) {
    $operator-index: str-index($expression, $operator);
    $value: str-slice($expression, $operator-index + str-length($operator));

    @if map-has-key($BREAKPOINTS, $value) {
        $value: map-get($BREAKPOINTS, $value);
    }
    @else {
        $value: to-number($value);
    }

    $interval: map-get($unit-intervals, unit($value));

    @if not $interval {
        // It is not possible to include a mixin inside a function, so we have to
        // rely on the `log(..)` function rather than the `log(..)` mixin. Because
        // functions cannot be called anywhere in Sass, we need to hack the call in
        // a dummy variable, such as `$_`. If anybody ever raise a scoping issue with
        // Sass 3.3, change this line in `@if log(..) {}` instead.
        $_: log("Unknown unit `#{unit($value)}`.");
    }

    @if $operator == ">" {
        $value: $value + $interval;
    } @else if $operator == "<" {
        $value: $value - $interval;
    }

    @return $value;
}

// Parse an expression to return a valid media-query expression
@function parse-expression($expression) {
    // If it is part of $MEDIA-EXPRESSIONS, it has no operator
    // then there is no need to go any further, just return the value
    @if map-has-key($MEDIA-EXPRESSIONS, $expression) {
        @return map-get($MEDIA-EXPRESSIONS, $expression);
    }

    $operator: get-expression-operator($expression);
    $dimension: get-expression-dimension($expression, $operator);
    $prefix: get-expression-prefix($operator);
    $value: get-expression-value($expression, $operator);

    @return "(#{$prefix}-#{$dimension}: #{$value})";
}

// Slice `$list` between `$start` and `$end` indexes
@function slice($list, $start: 1, $end: length($list)) {
    @if length($list) < 1 or $start > $end {
        @return ();
    }

    $result: ();

    @for $i from $start through $end {
        $result: append($result, nth($list, $i));
    }

    @return $result;
}

// Casts a string into a number
@function to-number($value) {
    @if type-of($value) == "number" {
        @return $value;
    } @else if type-of($value) != "string" {
        $_: log("Value for `to-number` should be a number or a string.");
    }

    $first-character: str-slice($value, 1, 1);
    $result: 0;
    $digits: 0;
    $minus: ($first-character == "-");
    $numbers: ("0": 0, "1": 1, "2": 2, "3": 3, "4": 4, "5": 5, "6": 6, "7": 7, "8": 8, "9": 9);

    // Remove +/- sign if present at first character
    @if ($first-character == "+" or $first-character == "-") {
        $value: str-slice($value, 2);
    }

    @for $i from 1 through str-length($value) {
        $character: str-slice($value, $i, $i);

        @if not (index(map-keys($numbers), $character) or $character == ".") {
            @return to-length(if($minus, -$result, $result), str-slice($value, $i));
        }

        @if $character == "." {
            $digits: 1;
        } @else if $digits == 0 {
            $result: $result * 10 + map-get($numbers, $character);
        } @else {
            $digits: ($digits * 10);
            $result: $result + div(map-get($numbers, $character), $digits);
        }
    }

    @return if($minus, -$result, $result);
}

// Add `$unit` to `$value`
@function to-length($value, $unit) {
    $units: ("px": 1px, "cm": 1cm, "mm": 1mm, "%": 1%, "ch": 1ch, "pc": 1pc, "in": 1in, "em": 1em, "rem": 1rem, "pt": 1pt, "ex": 1ex, "vw": 1vw, "vh": 1vh, "vmin": 1vmin, "vmax": 1vmax);

    @if not index(map-keys($units), $unit) {
        $_: log("Invalid unit `#{$unit}`.");
    }

    @return $value * map-get($units, $unit);
}

// This mixin aims at redefining the configuration just for the scope of
// the call. It is helpful when having a component needing an extended
// configuration such as custom breakpoints (referred to as tweakpoints)
// for instance.
@mixin media-context($tweakpoints: (), $tweak-media-expressions: ()) {
    // Save global configuration
    $global-breakpoints: $BREAKPOINTS;
    $global-media-expressions: $MEDIA-EXPRESSIONS;

    // Update global configuration
    $BREAKPOINTS: map-merge($BREAKPOINTS, $tweakpoints) !global;
    $MEDIA-EXPRESSIONS: map-merge($MEDIA-EXPRESSIONS, $tweak-media-expressions) !global;

    @content;

    // Restore global configuration
    $BREAKPOINTS: $global-breakpoints !global;
    $MEDIA-EXPRESSIONS: $global-media-expressions !global;
}

// Generates a media query based on a list of conditions
@mixin media-query($conditions...) {
    @if ($im-media-support and length($conditions) == 0) or
        (not $im-media-support and im-intercepts-static-breakpoint($conditions...)) {
        @content;
    } @else if ($im-media-support and length($conditions) > 0) {
        @media #{unquote(parse-expression(nth($conditions, 1)))} {
            // Recursive call
            @include media-query(slice($conditions, 2)...) {
                @content;
            }
        }
    }
}
