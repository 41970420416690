// /* ------------------------------------*\
//     #PLYR-OVERRIDES (Settings)
// \*------------------------------------ */

$plyr-controls-background-color:                transparent !default;
$plyr-controls-transition-reset:                false !default;

$plyr-controls-overlay-size:                    map-get($ICON-ROUND-FULL-SIZES, medium) !default;
$plyr-controls-overlay-size-at-breakpoint:      $plyr-controls-overlay-size !default;
$plyr-controls-overlay-icon-size:               icon-size(medium) !default;
$plyr-controls-overlay-icon-color:              $COLOR-WHITE !default;
$plyr-controls-overlay-background:              transparent !default;
$plyr-controls-overlay-border:                  false !default;

$plyr-controls-item-spacing:                    $GLOBAL-SPACING-UNIT-XSMALL !default;

$plyr-controls-button-overlay-background-hover: $COLOR-TRANSPARENCY-QUARTERNARY !default;
$plyr-controls-button-overlay-icon-color-hover: $plyr-controls-overlay-icon-color !default;

$plyr-controls-focus-outline-offset:            0 !default; // Handled via button size

$plyr-controls-caption-padding:                 $GLOBAL-SPACING-UNIT-XSMALL $GLOBAL-SPACING-UNIT-SMALL !default;
$plyr-controls-caption-border-radius:           $GLOBAL-BORDER-RADIUS-SMALL !default;
$plyr-controls-caption-letter-spacing:          $GLOBAL-FONT-LETTER-SPACING !default;

$plyr-controls-time-spacing:                    0 !default;
