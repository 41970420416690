// /* ------------------------------------*\
//     #FLATPICKR (Settings)
// \*------------------------------------ */

$flatpickr-square-size:                          34px !default;
$flatpickr-square-margin-horizontal:             $GLOBAL-SPACING-UNIT-XXSMALL !default;
$flatpickr-square-margin-vertical:               $flatpickr-square-margin-horizontal !default;

$flatpickr-border:                               false !default;
$flatpickr-border-radius:                        $GLOBAL-BORDER-RADIUS-SMALL !default;
$flatpickr-box-shadow:                           $DROP-SHADOW-S !default;
$flatpickr-padding-vertical:                     $GLOBAL-SPACING-UNIT !default;
$flatpickr-padding-horizontal:                   $GLOBAL-SPACING-UNIT !default;

$flatpickr-input-color:                          $COLOR-BASE-TEXT !default;
$flatpickr-input-icon-color:                     $flatpickr-input-color !default;
$flatpickr-input-icon-color-disabled:            $GLOBAL-INPUT-LABEL-COLOR-DISABLED !default;
$flatpickr-input-wrapper-background-color-hover: false !default;
$flatpickr-input-icon-background-color-hover:    $COLOR-SURFACE-PRIMARY !default;
$flatpickr-input-icon-color-hover:               false !default;

$flatpickr-year-input-font-weight:               $GLOBAL-FONT-WEIGHT-REGULAR !default;

$flatpickr-month-calendar-spacing:               $GLOBAL-SPACING-UNIT-SMALL !default;

$flatpickr-month-icon-size:                      icon-size(xsmall) !default;
$flatpickr-month-icon-color:                     $COLOR-BASE-TEXT !default;
$flatpickr-month-icon-color-hover:               false !default;
$flatpickr-month-font-size:                      map-get($FS1, null) !default;

$flatpickr-day-color:                            $COLOR-BASE-TEXT !default;
$flatpickr-day-color-disabled:                   $COLOR-ON-SURFACE-300 !default;
$flatpickr-day-background-color-hover:           $COLOR-SURFACE-PRIMARY !default;
$flatpickr-day-font-size:                        map-get($FS0, null) !default;
$flatpickr-day-border-radius:                    50% !default;
$flatpickr-day-range-background-color:           $COLOR-BRAND-SECONDARY-HOVER !default;
$flatpickr-day-range-hover-background-color:     $COLOR-SURFACE-PRIMARY !default;

$flatpickr-day-today-color:                      $flatpickr-day-color !default;
$flatpickr-day-today-border-color:               currentColor !default;

$flatpickr-day-disabled-strikethrough-width:     25px !default;

$flatpickr-weekday-font-weight:                  $GLOBAL-FONT-WEIGHT-REGULAR !default;

$flatpickr-time-input-font-weight:               $GLOBAL-INPUT-LABEL-FONT-WEIGHT !default;
