/* ------------------------------------*\
    #DESCRIPTION-LIST-ACCORDION
\*------------------------------------ */

$description-list-accordion-breakpoint:                         s !default;

$description-list-accordion-accordion-border-top:               none !default;
$description-list-accordion-accordion-border-bottom:            2px solid $COLOR-BORDER !default;

$description-list-accordion-header-spacing-left:                null !default;
$description-list-accordion-header-border-top:                  2px solid $COLOR-BRAND !default;

$description-list-accordion-content-spacing-top:                null !default;
$description-list-accordion-content-spacing-right:              $accordion-header-split-spacing-right !default;
$description-list-accordion-content-spacing-bottom:             null !default;
$description-list-accordion-content-spacing-left:               $accordion-header-split-spacing-left !default;

$description-list-accordion-title-label-font-size:              $FS2 !default;
$description-list-accordion-title-label-font-weight:            $GLOBAL-FONT-WEIGHT-BOLD !default;
$description-list-accordion-title-label-color:                  null !default;
$description-list-accordion-title-label-text-transform:         null !default;

$description-list-accordion-title-value-font-size:              $description-list-accordion-title-label-font-size !default;
$description-list-accordion-title-value-font-weight:            $GLOBAL-FONT-WEIGHT-REGULAR !default;
$description-list-accordion-title-value-color:                  null !default;
$description-list-accordion-title-value-text-transform:         null !default;
$description-list-accordion-title-value-margin-top-responsive:  $GLOBAL-SPACING-UNIT-XXSMALL !default;

$description-list-accordion-item-spacing-vertical:              $GLOBAL-SPACING-UNIT-SMALL !default;

$description-list-accordion-item-label-color-reset:             true !default;
$description-list-accordion-item-label-font-size:               $FS0 !default;
$description-list-accordion-item-label-font-weight:             $GLOBAL-FONT-WEIGHT-BOLD !default;
$description-list-accordion-item-label-color:                   null !default;
$description-list-accordion-item-label-text-transform:          null !default;

$description-list-accordion-item-note-margin-bottom-responsive: $GLOBAL-SPACING-UNIT-XXSMALL !default;
$description-list-accordion-item-note-font-size:                $FS-1 !default;
$description-list-accordion-item-note-font-weight:              $GLOBAL-FONT-WEIGHT-REGULAR !default;
$description-list-accordion-item-note-color:                    null !default;
$description-list-accordion-item-note-text-transform:           null !default;

$description-list-accordion-item-value-font-size:               $description-list-accordion-item-label-font-size !default;
$description-list-accordion-item-value-font-weight:             $GLOBAL-FONT-WEIGHT-REGULAR !default;
$description-list-accordion-item-value-color:                   null !default;
$description-list-accordion-item-value-text-transform:          null !default;

$description-list-accordion-total-background-color:             null !default;
$description-list-accordion-total-padding-top:                  $GLOBAL-SPACING-UNIT-SMALL !default;
$description-list-accordion-total-padding-bottom:               null !default;

$description-list-accordion-total-label-font-family:            null !default;
$description-list-accordion-total-label-font-size:              $FS4 !default;
$description-list-accordion-total-label-font-weight:            $GLOBAL-FONT-WEIGHT-BOLD !default;
$description-list-accordion-total-label-color:                  null !default;
$description-list-accordion-total-label-text-transform:         null !default;

$description-list-accordion-total-note-color:                   null !default;

$description-list-accordion-total-value-font-family:            null !default;
$description-list-accordion-total-value-font-size:              $FS4 !default;
$description-list-accordion-total-value-font-weight:            $GLOBAL-FONT-WEIGHT-BOLD !default;
$description-list-accordion-total-value-color:                  null !default;
$description-list-accordion-total-value-text-transform:         null !default;

/**
 * [1] The bottom accordion border separates the accordion and the total section.
 * [2] Align item label and value to the left and right via flexbox.
 * [3] Reset `dl` margins.
 * [4] Stack item label and value vertically in small viewports.
 * [5] Increase specificity to overrule specificity of
 *     `.c-accordion:not(:only-child)`.
 * [6] Increase specificity to overrule specificity of
 *     `.c-accordion--header-split .c-accordion__header`.
 * [7] Make sure to not use the default `<dt>` color.
 */

.c-description-list-accordion {
}

    .c-description-list-accordion__accordion.c-description-list-accordion__accordion { /* [5] */
        border-top: $description-list-accordion-accordion-border-top;
        border-bottom: $description-list-accordion-accordion-border-bottom; /* [1] */
    }

    .c-description-list-accordion__header.c-description-list-accordion__header { /* [6] */
        padding-left: $description-list-accordion-header-spacing-left;

        * + & {
            border-top: $description-list-accordion-header-border-top;
        }
    }

    .c-description-list-accordion__title-label {
        @include responsive-font-size(
            $font-size: $description-list-accordion-title-label-font-size
        );
        font-weight: $description-list-accordion-title-label-font-weight;
        text-transform: $description-list-accordion-title-label-text-transform;
        color: $description-list-accordion-title-label-color;
    }

    .c-description-list-accordion__title-value {
        @include responsive-font-size(
            $font-size: $description-list-accordion-title-value-font-size
        );
        font-weight: $description-list-accordion-title-value-font-weight;
        text-transform: $description-list-accordion-title-value-text-transform;
        color: $description-list-accordion-title-value-color;

        @include media-query("<=#{$description-list-accordion-breakpoint}") {
            margin-top: $description-list-accordion-title-value-margin-top-responsive;
        }
    }

    .c-description-list-accordion__content,
    .c-description-list-accordion__item--total {
        padding-top: $description-list-accordion-content-spacing-top;
        padding-right: $description-list-accordion-content-spacing-right;
        padding-bottom: $description-list-accordion-content-spacing-bottom;
        padding-left: $description-list-accordion-content-spacing-left;
    }

    .c-description-list-accordion__item {
        display: flex; /* [2] */
        flex-direction: row; /* [2] */
        justify-content: space-between; /* [2] */
        align-items: flex-start; /* [2] */
        margin-top: 0; /* [3] */
        margin-bottom: 0; /* [3] */

        @include media-query("<=#{$description-list-accordion-breakpoint}") {
            display: block; /* [4] */
        }

        & + & {
            margin-top: $description-list-accordion-item-spacing-vertical;
        }
    }

        .c-description-list-accordion__item__label {
            color: if($description-list-accordion-item-label-color-reset, inherit, null); /* [7] */
        }

            .c-description-list-accordion__item__label-text {
                @include responsive-font-size(
                    $font-size: $description-list-accordion-item-label-font-size
                );
                font-weight: $description-list-accordion-item-label-font-weight;
                text-transform: $description-list-accordion-item-label-text-transform;
                color: $description-list-accordion-item-label-color;
            }

            .c-description-list-accordion__item__label-note {
                @include responsive-font-size(
                    $font-size: $description-list-accordion-item-note-font-size
                );
                font-weight: $description-list-accordion-item-note-font-weight;
                text-transform: $description-list-accordion-item-note-text-transform;
                color: $description-list-accordion-item-note-color;

                @include media-query("<=#{$description-list-accordion-breakpoint}") {
                    margin-bottom: $description-list-accordion-item-note-margin-bottom-responsive;
                }
            }

        .c-description-list-accordion__item__value {
            @include responsive-font-size(
                $font-size: $description-list-accordion-item-value-font-size
            );
            font-weight: $description-list-accordion-item-value-font-weight;
            text-transform: $description-list-accordion-item-value-text-transform;
            color: $description-list-accordion-item-value-color;
        }

    .c-description-list-accordion__item--total {
        padding-top: $description-list-accordion-total-padding-top;
        padding-bottom: $description-list-accordion-total-padding-bottom;
        background-color: $description-list-accordion-total-background-color;

        .c-description-list-accordion__item__label {
        }

            .c-description-list-accordion__item__label-text {
                @include responsive-font-size(
                    $font-size: $description-list-accordion-total-label-font-size
                );
                font-family: $description-list-accordion-total-label-font-family;
                font-weight: $description-list-accordion-total-label-font-weight;
                text-transform: $description-list-accordion-total-label-text-transform;
                color: $description-list-accordion-total-label-color;
            }

            .c-description-list-accordion__item__label-note {
                color: $description-list-accordion-total-note-color;
            }

        .c-description-list-accordion__item__value {
            @include responsive-font-size(
                $font-size: $description-list-accordion-total-value-font-size
            );
            font-family: $description-list-accordion-total-value-font-family;
            font-weight: $description-list-accordion-total-value-font-weight;
            text-transform: $description-list-accordion-total-value-text-transform;
            color: $description-list-accordion-total-value-color;
        }
    }

