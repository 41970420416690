// /*------------------------------------*\
//     #BUTTONS
// \*------------------------------------*/

$skoda-button-scale-active:                     $BUTTON-SCALE-ACTIVE !default;
$skoda-button-focus-text-color:                 $BUTTON-LINK-TEXT-COLOR-FOCUS !default;
$skoda-button-focus-icon-color:                 $BUTTON-LINK-ICON-COLOR-FOCUS !default;
$skoda-button-focus-outline-width:              $GLOBAL-FOCUS-WIDTH !default;
$skoda-button-focus-outline-offset:             $GLOBAL-FOCUS-OFFSET !default;
$skoda-button-link-focus-outline-offset:        $GLOBAL-FOCUS-OFFSET-LARGE !default;
$skoda-button-focus-background-color:           $BUTTON-BACKGROUND-COLOR-FOCUS !default; // No settings in default
$skoda-button-secondary-focus-background-color: $BUTTON-SECONDARY-BACKGROUND-COLOR !default; // No settings in default

/**
 * `border-radius` is not applied for outline in Safari,
 * uses rectangular shape instead.
 * @link https://bugs.webkit.org/show_bug.cgi?id=20807
 */
.c-btn {

    &:focus-visible {

        /* [1] */
        @include focus-styles(
            $outline-width:  $skoda-button-focus-outline-width,
            $outline-offset: $skoda-button-focus-outline-offset,
        );

    }


    &:not(.c-btn--secondary) {

        &:focus {
            --button-background: #{$skoda-button-focus-background-color};
        }

    }

    &.c-btn--secondary {

        &:focus {
            --button-background: #{$skoda-button-secondary-focus-background-color};
        }

    }

    &:active {
        scale: $skoda-button-scale-active;
    }

    &.c-btn--link {
        border-radius: 0;

        &:focus {

            .c-btn__text {
                color: $skoda-button-focus-text-color;
            }

            .c-btn__icon.c-btn__icon { /* stylelint-disable-line selector-max-specificity, selector-max-class */
                color: $skoda-button-focus-icon-color;
            }

        }

        &:focus-visible {
            @include focus-styles(
                $outline-offset: $skoda-button-link-focus-outline-offset,
            );
        }
    }

}
