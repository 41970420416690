/* ------------------------------------*\
    #WIDTHS
\*------------------------------------ */

@use "sass:math" as *;

$cols: (1, 2, 3, 4, 5, 6, 12) !default;

// Generates widths classes based on the defined breakpoints, named with the
// convention `.u-x/y@breakpoint`. This mixins purpose is just to generate the
// widths classes in the utilities layer, hence this doesn't need to be used
// anywhere else. In other words: Ignore this file (except something's wrong or
// needs to be improved).
@mixin cols($columns) {
    @each $i in $columns {
        @for $n from 1 through $i {

            .u-#{$n}\/#{$i} {
                width: percentage(div($n, $i));
            }

        }
    }

    @each $breakpoint in $BREAKPOINTS {
        $breakpoint-name: nth($breakpoint, 1);

        @include media-query(#{"<=" + $breakpoint-name}) {
            @each $i in $columns {
                @for $n from 1 through $i {

                    .u-#{$n}\/#{$i}\@#{$breakpoint-name} {
                        width: percentage(div($n, $i));
                    }

                }
            }
        }
    }
}


/**
 * A series of widths helper classes that you can use to size things like grid
 * systems. Classes take a fraction-like format (e.g. `.u-2/3`).
 */

// You can control, which denominators are generated as classes by passing them
// as arguments in the following mixin-call:
@include cols($cols);
