// /* ------------------------------------*\
//     #BADGE
// \*------------------------------------ */

/// Mixin for badge stylings.
///
/// @param {String} $badge-size - width, height and line-height of badge
/// @param {String} $badge-text-size - font size of text
/// @param {String} $badge-font-weight - font weight of text
/// @param {String} $badge-color - background color
/// @param {String} $badge-text-color - font color
/// @param {Number} $badge-letter-spacing - letter-spacing for characters inside badge
/// @returns {CSS} Badge styling.
@mixin badge(
        $badge-size:           $badge-size,
        $badge-text-size:      $badge-text-size,
        $badge-font-weight:    $badge-font-weight,
        $badge-color:          $badge-color,
        $badge-text-color:     $badge-text-color,
        $badge-letter-spacing: $badge-letter-spacing,
    ) {
    @include responsive-font-size(
        $font-size:   $badge-text-size,
        $line-height: $badge-size
    );
    position: relative;
    display: inline-block;
    width: $badge-size;
    height: $badge-size;
    border-radius: 50%;
    font-weight: $badge-font-weight;
    text-align: center;
    letter-spacing: $badge-letter-spacing;
    background-color: $badge-color;
    color: $badge-text-color;
}
