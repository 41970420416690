// /* ------------------------------------*\
//     #LIST-BARE
// \*------------------------------------ */

/// The list-bare mixin removes any indents and bullet points from lists.
/// You can also use this as a class in your markup (defined in
/// `05-objects/_objects.list-bare.scss`).
/// An optional parameter `$item-selector` can be passed, which replaces the
/// standard `li` selector for list-items. This reduces the specificity which
/// might help avoid unwanted overrides.
/// @param {String} $item-selector [null] - The item selector to use for the list item styles.
/// @param {String}} $display ["block"]   - The display behavior of the list element.
@mixin list-bare($item-selector: null, $display: block) {
    margin: 0;
    padding: 0;
    list-style: none;

    @if ($item-selector) {

        @at-root #{$item-selector} {
            display: $display;
            margin-bottom: 0;
        }

    } @else {

        li {
            display: block;
            margin-bottom: 0;
        }

    }
}
