/* ------------------------------------*\
    #HEADINGS
\*------------------------------------ */

/**
 * Headings 1–6.
 *
 * Only font-sizes are assigned for `<h1>` – `<h6>` elements.
 * Any additional cosmetic styles are bound to an extra class and never
 * directly to a heading element!
 *
 * The individual font-sizes are defined in `01-settings/_font-sizes.scss`.
 */

h1 {
    @include responsive-font-size(
        $font-size:   $GLOBAL-HEADING-H1-FONT-SIZE,
        $line-height: $GLOBAL-HEADING-H1-LINE-HEIGHT
    );
}

h2 {
    @include responsive-font-size(
        $font-size:   $GLOBAL-HEADING-H2-FONT-SIZE,
        $line-height: $GLOBAL-HEADING-H2-LINE-HEIGHT
    );
}

h3 {
    @include responsive-font-size(
        $font-size:   $GLOBAL-HEADING-H3-FONT-SIZE,
        $line-height: $GLOBAL-HEADING-H3-LINE-HEIGHT
    );
}

h4 {
    @include responsive-font-size(
        $font-size:   $GLOBAL-HEADING-H4-FONT-SIZE,
        $line-height: $GLOBAL-HEADING-H4-LINE-HEIGHT
    );
}

h5 {
    @include responsive-font-size(
        $font-size:   $GLOBAL-HEADING-H5-FONT-SIZE,
        $line-height: $GLOBAL-HEADING-H5-LINE-HEIGHT
    );
}

h6 {
    @include responsive-font-size(
        $font-size:   $GLOBAL-HEADING-H6-FONT-SIZE,
        $line-height: $GLOBAL-HEADING-H6-LINE-HEIGHT
    );
}

h1,
h2,
h3,
h4,
h5,
h6 {

    @include heading-base-styles(
        $color:          $GLOBAL-HEADING-COLOR,
        $font-family:    $GLOBAL-HEADING-FONT-FAMILY,
        $font-weight:    $GLOBAL-HEADING-FONT-WEIGHT,
        $letter-spacing: $GLOBAL-HEADING-LETTER-SPACING,
        $margin-bottom:  $GLOBAL-HEADING-MARGIN-BOTTOM,
        $heading-themes: $GLOBAL-HEADING-THEMES
    );

}
