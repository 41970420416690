/* ------------------------------------*\
    #DEBUG
\*------------------------------------ */

/* stylelint-disable */

// Initially disable debug CSS and enable it in your build-process.
$debug:                 false !default;
$debug-bem-modifier:    true !default;
$debug-layout-modifier: true !default;

@mixin debug($message: "Error", $severity: error) {
    position: relative;
    outline: 1px solid red;

    &::after {
        padding: 2px;
        position: absolute;
        bottom: 100%;
        left: 0;

        @if $severity == error {
            background-color: rgba(255, 0, 0, 0.8);
            color: white;
        }

        @if $severity == warning {
            background-color: rgba(255, 255, 0, 0.8);
            color: black;
        }

        outline: 1px solid red;
        font-size: 12px;
        font-family: monospace;

        @if $severity == error {
            content: "ERROR: " $message;
        }

        @if $severity == warning {
            content: "WARNING: " $message;
        }

    }

}

@mixin reset-debug() {
    position: inherit !important;
    outline: none !important;

    &::after {
        content: normal !important;
    }

}

@if $debug == true {

    @if $debug-bem-modifier == true {

        /**
         * BEM Debug.
         */
        [class*="--"]:not([class*=" "]) {
             @include debug("Seems like you have just a modifier class without the BEM block class assigned. Always also assign the block class alongside the modifier class.");
        }

    }

    @if $debug-layout-modifier == true {

        /**
         * Layout Debug.
         */
        .o-layout {

            > *:not(.o-layout__item) {
                @include debug(".o-layout needs .o-layout__item as a direct child element. Wrap every other component inside .o-layout__item.");
            }

        }

        *:not(.o-layout) > .o-layout__item {
            @include debug(".o-layout__item needs .o-layout as a direct wrapper.");
        }

        .o-layout[class*="c-"],
        .o-layout__item[class*="c-"] {
            @include debug(
                $message:  "Do not mix layout classes with custom components. Rather try to nest your components within a .o-layout__item instead.",
                $severity: warning
            );
        }

        [class=" o-layout__item u-1/1"],
        [class=" o-layout__item  u-1/1"],
        [class="o-layout__item u-1/1 "],
        [class="o-layout__item  u-1/1 "],
        [class=" o-layout__item u-1/1 "],
        [class=" o-layout__item  u-1/1 "],
        [class="o-layout__item u-1/1"],
        [class="o-layout__item  u-1/1"] {
            @include debug(
                $message:  "This row is always full-width so you don't need a .o-layout > .o-layout__item combination together with .u-1/1 at all. If you have control over your HTML here, get rid of those .o-layout/.o-layout__item classes surrounding this element in order to tidy up your markup.",
                $severity: warning
            );
        }

    }

}

/* stylelint-enable */

/**
 * Disable debug on Plyr video controls. The used plugin (Plyr) does not follow
 * the "no modifier without base class" BEM rule.
 *
 * @TODO: find better solution to exclude Plyr.
 */
.plyr__control > svg {
    outline: none !important;
}
