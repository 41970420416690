/* ------------------------------------*\
    #FORM-HEADING
\*------------------------------------ */

$form-heading-spacing:            $GLOBAL-SPACING-UNIT-SMALL !default;
$form-heading-icon-enabled:       true !default; // Optionally hide icon if needed.
$form-heading-icon-title-spacing: $GLOBAL-SPACING-UNIT-XSMALL !default;
$form-heading-icon-size:          icon-size(medium) !default;
$form-heading-title-spacing:      $GLOBAL-SPACING-UNIT-XSMALL !default;
$form-heading-title-font-weight:  $GLOBAL-FONT-WEIGHT-BOLD !default;
$form-heading-title-text-color:   $COLOR-BRAND !default;
$form-heading-title-text-size:    $FS3 !default;
$form-heading-text-color:         $COLOR-BASE-TEXT !default;
$form-heading-text-size:          $FS0 !default;
$form-heading-text-font-weight:   null !default;

/**
 * [1] Reset margin bottom for text and manage it over
 *     `$form-headings-spacing` on `.c-form-heading`.
 * [2] Make sure that an (optional) icon is centered vertically.
 * [3] Nesting required to overrule `.c-icon` selector.
 * [4] Wrap text and possible inline elements in `<span>` because of parent's
 *     flex setting. Left empty here for documentation and prospective usage.
 * [5] Hide icon if disabled.
 * [6] Add bottom spacing to title only if there is no additional sibling element.
 */
.c-form-heading {
    margin-bottom: $form-heading-spacing;
}

    .c-form-heading__title {
        @include responsive-font-size($form-heading-title-text-size);
        display: flex; /* [2] */
        align-items: center; /* [2] */
        font-weight: $form-heading-title-font-weight;
        color: $form-heading-title-text-color;

        &:not(:only-child) {
            margin-bottom: $form-heading-title-spacing; /* [6] */
        }

        .c-form-heading__icon { /* [3] */

            @if ($form-heading-icon-enabled) {
                margin-right: $form-heading-icon-title-spacing;
                font-size: $form-heading-icon-size;
            } @else {
                display: none; /* [5] */
            }

        }

    }

    .c-form-heading__text {
        @include responsive-font-size($form-heading-text-size);
        display: block;
        margin-bottom: 0; /* [1] */
        font-weight: $form-heading-text-font-weight;
        color: $form-heading-text-color;
    }

    .c-form-heading__title-text { /* [4] */
    }
