// /* ------------------------------------*\
//     #RESPONSIVE-PROPERTY
// \*------------------------------------ */

////
/// This tool allows passing a styling-property (e.g. "margin-top") and a value
/// map to generate a responsive styling with media queries for the
/// aforementioned property. It is also possible to pass a list of properties
/// and their value maps to create media query blocks. See ### USE CASE 1 ###
/// and ### USE CASE 2 ### below for usage details.
////

/// Helper to remove quotes if value is string and outputs CSS.
/// @param {String} $property - CSS property.
/// @param {String} $value - CSS value for the above property.
/// @param {String} $breakpoint [not specified] - optional, only for debugging.
/// @output A CSS property and its value.
/// @access private
@mixin _output-responsive-property($property, $value, $breakpoint: "not specified") {
    @if (type_of($value) == string) {
        // remove possible quotes (e.g. "10px" -> 10px)
        $value: unquote($value);
    }
    // this is the only place where this tool outputs anything
    #{$property}: $value;
    // @debug "breakpoint-#{$breakpoint} -> #{$property}: #{$value};";
}

/// Helper to check if suspected plain CSS value is valid. While "null" is not
/// a valid property value, it should not cause an error but just be omitted.
/// @param {String} $property - CSS property.
/// @param {String} $value [not specified] - optional, only for debugging.
/// @throw Invalid value format.
/// @return {Boolean} - true: Responsive property value is valid.
/// @access private
@function _is-valid-responsive-property-value($property, $value: "not specified") {
    @if (type_of($value) == map  or
         type_of($value) == list or
         type_of($value) == bool) {
        @error("Trying to assign invalid value of format '"type_of($value)"' to property '"$property"'");
    }

    @if ($value == null) {
        // "null" isn't a valid CSS property value, but it shouldn't cause an
        // error since it simply means "no values assigned".
        @return false;
    } @else {
        @return true;
    }
}

/// Takes a sass map or arglist plus a `$target-breakpoint`. Returns a map that
/// contains all properties and their respective values that match the
/// `$target-breakpoint`. Also filters out breakpoints that are not defined
/// in `_settings.breakpoints.scss` and throws a warning.
/// @param {Arglist | Map} $responsive-properties - The responsive properties.
/// @param {String} $target-breakpoint - The breakpoint to filter
/// $responsive-properties.
/// @return {Map} - A map with the CSS properties and their values for the
/// target breakpoint.
/// @access private
@function _get-responsive-declarations-by-breakpoint($responsive-properties, $target-breakpoint) {
    /// @type Map
    $declarations-by-breakpoint: ();
    @each $property, $values in $responsive-properties {

        @if (type_of($property) != string) {
            @error( 'A property in a responsive value map has to be a string, e.g. "margin-left".');
        }

        @if (type_of($values) == map) {

            @each $value-breakpoint, $value in $values {
                // Check if the breakpoint is supported and if its the one we
                // are looking for.
                @if (is-valid-breakpoint($value-breakpoint) and $value-breakpoint == $target-breakpoint) {
                    // Add property and value to map.
                    $declarations-by-breakpoint: map-merge($declarations-by-breakpoint, ($property: $value));
                }
            }

        } @else if ($target-breakpoint == null) {

            // `$values` is not a map and the `$target-breakpoint` is null,
            // check if its a plain value.
            @if _is-valid-responsive-property-value($property, $values) {
                // Add property and value to map.
                $declarations-by-breakpoint: map-merge($declarations-by-breakpoint, ($property: $values));
            }

        } @else {
            // `$values` is not a map and `$target-breakpoint` is not null ->
            // omit this value.
        }
    }
    @return $declarations-by-breakpoint;
}

/// ### USE CASE 1 ###
///
/// Allows passing a styling-property (e.g. `margin-top`) and a value map to
/// generate a responsive styling with media queries for the aforementioned
/// property. If a single value is passed, it will be assigned without media
/// queries, e.g. `margin-top: $GLOBAL-SPACING-UNIT;`.
/// If a value map is passed, it has to look like this:
/// ```
/// $value-map: (
///     null: $GLOBAL-SPACING-UNIT-LARGE,
///     "m":  $GLOBAL-SPACING-UNIT,
///     "s":  $GLOBAL-SPACING-UNIT-SMALL,
///     "xs": 0,
/// ) !default;
/// ```
/// The map follows the "desktop first" approach. "null" is the default value,
/// which is assigned without a media query. "m" will be applied to screen sizes
/// "m" and smaller, "s" to screen sizes "s" and smaller and so on.
/// The following break-points can be used:
/// packages/bronson-core/01-settings/_settings.breakpoints.scss
///
/// ### USE CASE 2 ###
///
/// Pass a list of styling-properties and a list of values/value-maps.
/// Example:
/// ```
/// @include responsive-property(
///     ("padding-top",    $padding-top-responsive-map),
///     ("padding-left",   $padding-horizontal-responsive-map),
///     ("padding-right",  $padding-horizontal-responsive-map),
///     ("padding-bottom", 25px)
/// );
/// ```
/// The advantage here is that all responsive CSS declarations will be put in a
/// single media query, instead of creating a media query for every property
/// separately. Example:
/// ```
/// @media (max-width: 1016px)
///  .container {
///    padding-top: 32px;
///    padding-left: 32px;
///    padding-right: 32px;
///  }
/// ```
/// @param {Arglist} $responsive-properties - See ### USE CASE 1 ### and
/// ### USE CASE 2 ### above.
/// @output Generated by _tools/media-query.scss and _output-responsive-property
/// @require {mixin} media-query </_tools.media-query.scss>
/// @throw No property defined, Invalid arguments exceptions.
/// @access public
@mixin responsive-property($responsive-properties...) {

    @if $responsive-properties == null or length($responsive-properties) == 0 {
        @error("No property is defined for the responsive-property mixin.");
    }

    $first-item: nth($responsive-properties, 1);
    @if (type_of($first-item) == string and length($responsive-properties) == 2) {
        // If the first argument is a string, it has to be followed by exactly
        // one more argument, which is the property value or value-map. In this
        // case, we expect ### USE CASE 1 ### as explained above.
        // Rearrange arguments to a Sass map.
        $responsive-properties: ($first-item: nth($responsive-properties, 2));
    } @else if (type_of($first-item) == list) {
        // If the first argument is a list, we expect ### USE CASE 2 ### as
        // explained above. Nothing to do here.
    } @else {
        /* stylelint-disable */
        @error ("Invalid arguments exceptions \n The responsive-property mixin expects, either: \n - Two arguments: A CSS-property and a value/value-map or \n- A list of the above \nSee bronson-core/02-tools/_tools.responsive-property.scss for details on proper usage.");
        /* stylelint-enable */
    }

    // Gather all properties and their values without a breakpoint.
    /// @type Map
    $declaration-block: _get-responsive-declarations-by-breakpoint($responsive-properties, null);
    @each $property, $value in $declaration-block {
        // now output the declaration block
        @include _output-responsive-property($property, $value, null);
    }

    // Loop through breakpoints and output the CSS declarations for each
    // breakpoint in the order defined in `_settings.breakpoints.scss`.
    @each $breakpoint, $breakpoint-width in $BREAKPOINTS {
        // Gather all declarations for this breakpoint.
        $declaration-block: _get-responsive-declarations-by-breakpoint($responsive-properties, $breakpoint);
        // If the map has at least one CSS declaration, create a media-query and
        // output property and value.
        @if (length($declaration-block) > 0) {
            @include media-query("<=#{$breakpoint}") {
                @each $property, $value in $declaration-block {
                    @include _output-responsive-property($property, $value, $breakpoint);
                }
            }
        }
    }
}

/// Takes a map of selectors and CSS properties and their values and generates
/// responsive properties for each of those values.
/// Example map:
/// ```
/// $responsive-property-map: (
///   '.title-selector': (
///       max-width:     $plain-value-or-responsive-map,
///       margin-bottom: $plain-value-or-responsive-map,
///   ),
///   '.another-selector': (
///       font-size:     $plain-value-or-responsive-map,
///       line-height:   $plain-value-or-responsive-map,
///   ),
/// );
/// ```
/// An optional `$selector-prefix` can be passed which will prefix the selectors
/// defined in the map.
/// @param {Map} $responsive-property-map - See example above.
/// @param {String} $selector-prefix - An optional selector prefix for the
/// selectors defined in `$responsive-property-map`.
/// @output CSS selectors with responsive properties.
/// @require {mixin} responsive-font-size </_tools.responsive-font-size.scss>
/// @throw No property defined, Invalid arguments exceptions.
/// @access public
@mixin responsive-property-map($responsive-property-map, $selector-prefix: "") {
    @each $selector, $property-block in $responsive-property-map {
        // the list of responsive properties for this selector
        $responsive-list: ();
        @each $property, $value in $property-block {
            // font-sizes are handled by a separate tool
            @if ($property != "font-size") {
                $responsive-list: append($responsive-list, ($property, $value));
            }
        }

        #{$selector-prefix}#{$selector} {
            // If there is a font-size, pass it to the `responsive-font-size()`
            // tool.
            @include responsive-font-size(
                    $font-size: map-get($property-block, "font-size"),
                    $accept-null: true
            );

            // Check if there's at least one property for this selector.
            @if (length($responsive-list) > 0) {
                // adding `...` to a variable passes it as an `arglist`
                @include responsive-property($responsive-list...);
            }
        }
    }
}

/// Modify all values in a responsive map. Usage:
/// `responsive-property-modify($responsive-property: $map, $factor: -1)`
/// ...negates all values in the map.
/// `responsive-property-modify($responsive-property: $map, $factor: 0.5)`
/// ...halves all values in the map.
/// `responsive-property-modify($responsive-property: $map, add: 10px)`
/// ...adds 10px to all values in the map.
/// `responsive-property-modify($responsive-property)`
/// ...does nothing.
/// @param {Map} $responsive-property - Responsive property map to modify.
/// @param {Number} $factor - Factor to apply to all map values.
/// @param {Number} $add - Value to add to all map values (use negative value.
/// for subtraction).
/// @param {Boolean} $useCalc - Determines if the CSS `calc()` function should
/// be used for the addition. Only has an effect if `$add` does not equal `0`.
/// @return {Map} - The modified map.
@function responsive-property-modify($responsive-property, $factor:1, $add:0, $useCalc: false) {
    @if ($responsive-property == null) {
        @return $responsive-property;
    }
    @if (type-of($responsive-property) == map) {
        $new-responsive-property: ();
        @each $breakpoint, $value in $responsive-property {
            @if $useCalc and $add != 0 {
                $new-responsive-property: map-merge($new-responsive-property, ($breakpoint: "calc(#{$value * $factor} + #{$add})"));
            } @else {
                $new-responsive-property: map-merge($new-responsive-property, ($breakpoint: $value * $factor + $add));
            }
        }
        @return $new-responsive-property;
    }
    // Assume `$responsive-property` is a plain value with or without unit, e.g.
    // "10px" or "10".
    @if $useCalc and $add != 0 {
        @return "calc(#{$responsive-property * $factor} + #{$add})";
    } @else {
        @return $responsive-property * $factor + $add;
    }
}
