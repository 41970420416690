/* ------------------------------------*\
    #CUSTOM-PROGRESS
\*------------------------------------ */

$custom-progress-breakpoint:                           xs !default;

$custom-progress-top-offset:                           100px !default;

$custom-progress-track-height:                         $PROGRESS-HEIGHT-LARGE !default;
$custom-progress-track-background-color:               $PROGRESS-TRACK-BACKGROUND !default;
$custom-progress-track-border-enable:                  false !default;
$custom-progress-track-border-width:                   1px !default;
$custom-progress-track-border-style:                   solid !default;
$custom-progress-track-border-color:                   $custom-progress-track-background-color !default;
$custom-progress-track-border:                         $custom-progress-track-border-width $custom-progress-track-border-style $custom-progress-track-border-color !default;
$custom-progress-track-border-radius:                  if($PROGRESS-BORDER-RADIUS, $PROGRESS-BORDER-RADIUS, null) !default;

$custom-progress-progress-main-background-color:       $PROGRESS-PROGRESS-BACKGROUND !default;
$custom-progress-progress-main-icon-color:             $COLOR-BASE !default;

$custom-progress-progress-start-background-color:      $COLOR-PALE !default;
$custom-progress-progress-start-icon-color:            $COLOR-BASE !default;

$custom-progress-progress-end-background-color:        $COLOR-BASE !default;
$custom-progress-progress-end-icon-color:              $COLOR-WHITE !default;

$custom-progress-thumb-size:                           15px !default;
$custom-progress-thumb-icon-circle-size:               48px !default;
$custom-progress-thumb-icon-circle-size-at-breakpoint: 32px !default;
$custom-progress-thumb-icon-complexity:                null !default;
$custom-progress-thumb-icon-size:                      map-get($ICON-SIZES, small) !default;
$custom-progress-thumb-icon-size-at-breakpoint:        map-get($ICON-SIZES, xsmall) !default;

$custom-progress-height:                               ($custom-progress-track-height + $custom-progress-thumb-size) !default;

$custom-progress-tooltip-text-align:                   center !default;
$custom-progress-tooltip-max-width:                    250px !default;

$custom-progress-description-spacing-top:              $GLOBAL-SPACING-UNIT !default;
$custom-progress-description-item-breakpoint:          xs !default;
$custom-progress-description-item-spacing:             $GLOBAL-SPACING-UNIT !default;

/**
 * [1] Reserve space for tooltip to avoid collision with other components.
 * [2] Apply clipping to achieve border-radius.
 * [3] Prevent subsequent origins blocking interaction on `connect` elements.
 * [4] Indicator for hover that triggers optional tooltip.
 *     `aria-describedby` is set when tooltip is visible.
 * [5] Position tooltip thumbs with icon differently. `3px` is a magic number to
 *     shift the circle edge over the track edge.
 * [6] Shift icon for the last segment to the left side of the segment.
 * [7] Use icons with simple complexity if set.
 * [8] Add invisible pseudo element to increase track height to icon (circle)
 *     height. This increases the hover/clickable area and includes the icon area.
 * [9] The start item is the first child connect and the 2nd child of `.c-custom-progress__connect-container`
 *     which is the first origin.
 * [10] The end item is the last connect and last origin.
 * [11] The main item is defined by the initial visible tooltip and the
 *      dynamically set `.c-custom-progress__step--main` class.
 */

.c-custom-progress {

}

    .c-custom-progress__visualization {
        --custom-progress-thumb-icon-size: #{$custom-progress-thumb-icon-size};
        --custom-progress-thumb-icon-circle-size: #{$custom-progress-thumb-icon-circle-size};

        --custom-progress-connect-main-background-color: #{$custom-progress-progress-main-background-color};
        --custom-progress-connect-main-icon-color: #{$custom-progress-progress-main-icon-color};

        --custom-progress-connect-start-background-color: #{$custom-progress-progress-start-background-color};
        --custom-progress-connect-start-icon-color: #{$custom-progress-progress-start-icon-color};

        --custom-progress-connect-end-background-color: #{$custom-progress-progress-end-background-color};
        --custom-progress-connect-end-icon-color: #{$custom-progress-progress-end-icon-color};

        &[data-custom-progress-theme="alt-1"] {
            --custom-progress-connect-main-background-color: #{$custom-progress-progress-end-background-color};
            --custom-progress-connect-main-icon-color: #{$custom-progress-progress-end-icon-color};

            --custom-progress-connect-start-background-color: #{$custom-progress-progress-main-background-color};
            --custom-progress-connect-start-icon-color: #{$custom-progress-progress-main-icon-color};

            --custom-progress-connect-end-background-color: #{$custom-progress-progress-start-background-color};
            --custom-progress-connect-end-icon-color: #{$custom-progress-progress-start-icon-color};
        }

        &[data-custom-progress-theme="alt-2"] {
            --custom-progress-connect-main-background-color: #{$custom-progress-progress-start-background-color};
            --custom-progress-connect-main-icon-color: #{$custom-progress-progress-start-icon-color};

            --custom-progress-connect-start-background-color: #{$custom-progress-progress-end-background-color};
            --custom-progress-connect-start-icon-color: #{$custom-progress-progress-end-icon-color};

            --custom-progress-connect-end-background-color: #{$custom-progress-progress-main-background-color};
            --custom-progress-connect-end-icon-color: #{$custom-progress-progress-main-icon-color};
        }

        // stylelint-disable-next-line order/order
        @include media-query("<#{$custom-progress-breakpoint}") {
            --custom-progress-thumb-icon-size: #{$custom-progress-thumb-icon-size-at-breakpoint};
            --custom-progress-thumb-icon-circle-size: #{$custom-progress-thumb-icon-circle-size-at-breakpoint};
        }

        /* stylelint-disable-next-line order/order */
        position: relative;
        height: $custom-progress-height;
        margin-top: $custom-progress-top-offset; /* [1] */

        .tippy-content {
            width: max-content; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
            max-width: $custom-progress-tooltip-max-width;
            text-align: $custom-progress-tooltip-text-align;
        }

    }

    .c-custom-progress__target {
        position: relative;

        * {
            touch-action: none;
            user-select: none;
        }
    }

    /* Track */
    .c-custom-progress__base {
        position: relative;
        z-index: 1;
        top: 50%;
        width: 100%;
        height: $custom-progress-track-height;
        transform: translateY(-50%);
        cursor: auto;
    }

    .c-custom-progress__connect-container {
        height: 100%;
        border: if($custom-progress-track-border-enable, $custom-progress-track-border, null);
        background-color: $custom-progress-track-background-color;
        @if ($custom-progress-track-border-radius) {
            clip-path: inset(0 round $custom-progress-track-border-radius); /* [2] */
        }
    }

    .c-custom-progress__origin {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        pointer-events: none; /* [3] */
    }

    .c-custom-progress__thumb {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: transparent; /* [4] */

        /*
         * @DEPRECATED: Remove `.c-custom-progress__thumb--has-icon` when `:has` browser support is sufficient.
         * `where()` is used to get a forgiving selector list: https://drafts.csswg.org/selectors-4/#typedef-forgiving-selector-list
         */
        &:where(
            :has(.c-custom-progress__thumb__icon),
            .c-custom-progress__thumb--has-icon) {
            top: 50%; /* [5] */
            bottom: auto; /* [5] */
            transform: translate(3px, -50%); /* [5] */

            .c-custom-progress__origin:last-child & {
                transform: translate(calc(100% - 3px), -50%); /* [6] */
            }
        }

    }

        .c-custom-progress__thumb__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: var(--custom-progress-thumb-icon-circle-size);
            aspect-ratio: 1;
            border-radius: 50%;
            font-size: var(--custom-progress-thumb-icon-size);
            background: var(--custom-progress-connect-background-color);
            color: var(--custom-progress-connect-icon-color);

            @if ($custom-progress-thumb-icon-complexity == "simple") {
                --bron-icon-simple: ; /* stylelint-disable-line */ /* [7] */
            }
        }

    .c-custom-progress__connect {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        transform-origin: 0 0;
        background-color: var(--custom-progress-connect-background-color);
        color: var(--custom-progress-connect-icon-color);
        cursor: pointer; /* [4] */

        &[aria-describedby] {
            cursor: auto; /* [4] */
        }

        &::before { /* [8] */
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            bottom: 0;
            left: 0;
            height: var(--custom-progress-thumb-icon-circle-size);
            transform: translateY(-50%);
        }

    }

    /* start */ /* [9] */
    .c-custom-progress__connect:first-child,
    .c-custom-progress__origin:nth-child(2) {
        --custom-progress-connect-icon-color: var(--custom-progress-connect-start-icon-color);
        --custom-progress-connect-background-color: var(--custom-progress-connect-start-background-color);
    }


    /* end */ /* [10] */
    .c-custom-progress__connect:last-child,
    .c-custom-progress__origin:last-child {
        --custom-progress-connect-icon-color: var(--custom-progress-connect-end-icon-color);
        --custom-progress-connect-background-color: var(--custom-progress-connect-end-background-color);
    }

    /* main */ /* [11] */
    .c-custom-progress__connect.c-custom-progress__step--main,
    .c-custom-progress__thumb.c-custom-progress__step--main {
        --custom-progress-connect-icon-color: var(--custom-progress-connect-main-icon-color);
        --custom-progress-connect-background-color: var(--custom-progress-connect-main-background-color);
    }


    .c-custom-progress__pips {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .c-custom-progress__marker {
    }

    .c-custom-progress__value {
        position: absolute;
        transform: translateX(-50%);
        text-align: center;
        cursor: pointer;
        user-select: none;
    }

    .c-custom-progress__description {
        display: flex;
        gap: $custom-progress-description-item-spacing;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: $custom-progress-description-spacing-top;
    }

@include media-query(">#{$custom-progress-description-item-breakpoint}") {
    .c-custom-progress__description {
        flex-direction: row;
    }

    .c-custom-progress__description__item {
        flex-basis: calc(50% - #{$custom-progress-description-item-spacing});
    }

    .c-custom-progress__description__item--end {
        margin-left: auto;
    }

}

    .c-custom-progress__description__item {
    }

    .c-custom-progress__description__item--start {
    }

    .c-custom-progress__description__item--end {
    }
