// /* ------------------------------------*\
//     #PAGE-WRAP (Settings)
// \*------------------------------------ */

// @sass-export-section="toolsPageWrap"

// Set according to styleguide:
// https://www.figma.com/file/6qOAnI1JSFb2CLT4E3xahQ/FLOW---Web-%2F-Styles?no%20%20%20%20%20e-id=339%3A2
$page-wrap-width: 1248px !default;

// @end-sass-export-section

/**
 * [1] Add spacing of 96px (not part of global spacing scale) according to styleguide:
 *     https://www.figma.com/file/6qOAnI1JSFb2CLT4E3xahQ/FLOW---Web-%2F-Styles?node-id=4083%3A0
 * [2] Add spacing of 12px (not part of global spacing scale) according to styleguide:
 *     https://www.figma.com/file/6qOAnI1JSFb2CLT4E3xahQ/FLOW---Web-%2F-Styles?node-id=4083%3A0
 */

$page-wrap-padding-horizontal: (
    null: $GLOBAL-SPACING-UNIT-XLARGE * 2, /* [1] */
    "m":  $GLOBAL-SPACING-UNIT-XLARGE,
    "s":  $GLOBAL-SPACING-UNIT,
    "xs": $GLOBAL-SPACING-UNIT * 0.5 /* [2] */
) !default;
