/* ------------------------------------*\
   #PRODUCT SEARCH FORM
\*------------------------------------ */

$product-search-form-breakpoint:               m !default;
$product-search-form-padding:                  $GLOBAL-SPACING-UNIT !default;
$product-search-form-shadow:                   null !default;
$product-search-form-background-color:         $COLOR-BASE-BACKGROUND !default;
$product-search-form-border:                   null !default;
$product-search-form-button-container-spacing: $GLOBAL-SPACING-UNIT-SMALL !default;
$product-search-form-result-button-spacing:    $GLOBAL-SPACING-UNIT-SMALL !default;

/**
 * [1] Change the flex-direction for the vertical mode to display the result
 *     button above the check more filters button.
 * [2] Adapt button width to the media-query breakpoints.
 */
.c-product-search-form {
    display: flex;
    flex-wrap: wrap;
    padding: $product-search-form-padding;
    border: $product-search-form-border;
    background-color: $product-search-form-background-color;
    box-shadow: $product-search-form-shadow;

    > * {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
    }

}

    .c-product-search-form__heading {
    }

    .c-product-search-form__action-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include media-query("<=#{$product-search-form-breakpoint}") {
            flex-direction: column-reverse; /* [1] */
        }

    }

    .c-product-search-form__button-container {
        display: flex;
        gap: $product-search-form-button-container-spacing;
        justify-content: flex-end; /* [1] */
    }

    .c-product-search-form__reset-button {

        @include media-query("<=#{$product-search-form-breakpoint}") {
            display: none;
        }

    }

    .c-product-search-form__result-button {

        @include media-query("<=#{$product-search-form-breakpoint}") {
            width: important(100%, true); /* [2] */
            max-width: important(100%, true); /* [2] */
            margin-bottom: $product-search-form-result-button-spacing;
        }

    }
