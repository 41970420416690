/* ------------------------------------*\
    #RESET
\*------------------------------------ */

/**
 * Besides using normalize.css, it is often advantageous to remove all margins
 * from certain elements.
 */

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
dl,
dd,
ol,
ul,
form,
fieldset,
legend,
figure,
table,
th,
td,
caption,
hr {
    margin: 0;
    padding: 0;
}

/**
 * Some browsers have weird default styles for the <fieldset> element.
 * E.g. Chrome has `min-width: -webkit-min-content;` as default style. We
 * definitely don't want that, so we explicitly set it to `auto` here.
 */
fieldset {
    min-width: auto;
}
