// /* ------------------------------------*\
//     #TRANSITION
// \*------------------------------------ */
@use "sass:meta";

/// Using this mixin instead of assigning transition declarations directly in
/// rulesets ensures the most possible consistency.
///
/// @param {String} $transition-property [$GLOBAL-TRANSITION-PROPERTY] CSS property
/// @param {String} $transition-duration [$GLOBAL-TRANSITION-DURATION] Time values (numeric value with time unit)
/// @param {String} $transition-timing-function [$GLOBAL-TRANSITION-TIMING-FUNCTION] Timing keyword
/// @param {String} $transition-delay [null] Time value (numeric value with time unit)
/// @param {Boolean} $respect-prefers-reduced-motion [true] Respect reduced motion preferences of the user
/// @param {Boolean} $custom-properties-only [false] Respect reduced motion preferences of the user
@mixin transition(
    $transition-property:            $GLOBAL-TRANSITION-PROPERTY,
    $transition-duration:            $GLOBAL-TRANSITION-DURATION,
    $transition-timing-function:     $GLOBAL-TRANSITION-TIMING-FUNCTION,
    $transition-delay:               null,
    $respect-prefers-reduced-motion: true,
    $custom-properties-only:         false
) {
    // Safe-guard against unquoting non-string values.
    $transition-delay: if(meta.type-of($transition-delay) == "string", unquote($transition-delay), $transition-delay);
    $transition-duration: if(meta.type-of($transition-duration) == "string", unquote($transition-duration), $transition-duration);
    // You can use the CSS custom properties to add more transitions to custom
    // transition declarations without losing these settings.
    // E.g. `transition-property: var(--transition-property), opacity`;
    /* stylelint-disable order/order */
    --transition-property: #{unquote($transition-property)};
    @if ($respect-prefers-reduced-motion) {
        // Prefer `--transition-duration-reduced-motion` from a parent element (e.g. `:root`/`html`)
        --transition-duration: var(--transition-duration-reduced-motion, #{$transition-duration});
    } @else {
        --transition-duration: #{$transition-duration};
    }
    --transition-timing-function: #{unquote($transition-timing-function)};
    --transition-delay: #{$transition-delay};

    @if not ($custom-properties-only) {
        transition-property: var(--transition-property);
        transition-duration: var(--transition-duration);
        transition-timing-function: var(--transition-timing-function);
        transition-delay: var(--transition-delay);
    }

}
