/* ------------------------------------*\
    #TEXT-WRAP
\*------------------------------------ */

/**
 * A utility class to enforce text wrapping. With hyphens if possible
 * otherwise with hard word-breaks.
 */

.u-text-wrap {
    @include force-text-wrap();
}
