// /* ------------------------------------*\
//     #TRUNCATE TEXT
// \*------------------------------------ */

/// Truncate text with "..." when content is wider than the available space.
///
/// @param {Number} $multiline [null] - Enable multiline via `line-clamp`
/// with given number
@mixin truncate($multiline: null) {

    @if ($multiline) {
        /* stylelint-disable */
        display: -webkit-box;
        -webkit-line-clamp: $multiline;
        line-clamp: $multiline;
        -webkit-box-orient: vertical;
        box-orient: vertical;
        /* stylelint-enable */
    } @else {
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    overflow: hidden;
}
