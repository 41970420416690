// /* ------------------------------------*\
//     #PLYR (Settings)
// \*------------------------------------ */

// CSS custom properties
// node_modules/plyr/src/sass/lib/css-vars.scss
$css-vars-use-native:                        false !default;

// Breakpoints
// node_modules/plyr/src/sass/settings/breakpoints.scss
$plyr-bp-sm:                                 map-get($BREAKPOINTS, "xs") !default;
$plyr-bp-md:                                 map-get($BREAKPOINTS, "s") !default;
$plyr-bp-lg:                                 map-get($BREAKPOINTS, "l") !default;

// Colors
// node_modules/plyr/src/sass/settings/colors.scss
$plyr-color-main:                            $COLOR-HIGHLIGHT !default;
$plyr-video-background:                      $COLOR-BLACK !default;

// Cosmetic
// node_modules/plyr/src/sass/settings/cosmetics.scss
$plyr-tab-focus-color:                       $plyr-color-main !default;

// Typography
// node_modules/plyr/src/sass/settings/type.scss
$plyr-font-family:                           $BASE-FONT-FAMILY !default;
$plyr-font-size-base:                        map-get($FS0, null) !default;
$plyr-font-size-small:                       map-get($FS-1, null) !default;
$plyr-font-size-large:                       map-get($FS2, null) !default;
$plyr-font-size-xlarge:                      map-get($FS3, null) !default;
$plyr-font-size-time:                        $plyr-font-size-small !default;
$plyr-font-size-menu:                        $plyr-font-size-small !default;
$plyr-font-size-badge:                       map-get($FS-3, null) !default;
$plyr-font-weight-regular:                   $GLOBAL-FONT-WEIGHT-REGULAR !default;
$plyr-font-weight-bold:                      $GLOBAL-FONT-WEIGHT-BOLD !default;
$plyr-line-height:                           $GLOBAL-LINE-HEIGHT !default;
$plyr-font-smoothing:                        true !default;

// Badges
// node_modules/plyr/src/sass/settings/badges.scss
$plyr-badge-background:                      $plyr-color-main !default;
$plyr-badge-text-color:                      $COLOR-WHITE !default;
$plyr-badge-border-radius:                   $GLOBAL-INPUT-BORDER-RADIUS !default;

// Captions
// node_modules/plyr/src/sass/settings/captions.scss
$plyr-captions-background:                   transparentize($COLOR-BASE, $OPACITY-MEDIUM) !default;
$plyr-captions-text-color:                   $COLOR-WHITE !default;
$plyr-font-size-captions-base:               $plyr-font-size-base !default;
$plyr-font-size-captions-small:              $plyr-font-size-small !default;
$plyr-font-size-captions-medium:             ceil($plyr-font-size-base * 1.5) !default;
$plyr-font-size-captions-large:              ($plyr-font-size-base * 2) !default;

// Controls
// node_modules/plyr/src/sass/settings/controls.scss
$plyr-control-icon-size:                     icon-size(small) !default;
$plyr-control-spacing:                       $GLOBAL-SPACING-UNIT-SMALL !default;
$plyr-control-padding:                       $plyr-control-spacing !default;
$plyr-control-radius:                        $GLOBAL-INPUT-BORDER-RADIUS !default;
$plyr-control-toggle-checked-background:     $plyr-color-main !default;
$plyr-video-controls-background:             $COLOR-BRAND !default;
$plyr-video-control-color:                   $plyr-color-main !default;
$plyr-video-control-color-hover:             $COLOR-WHITE !default;
$plyr-video-control-background-hover:        transparent !default;
$plyr-audio-controls-background:             $COLOR-WHITE !default;
$plyr-audio-control-color:                   $plyr-color-main !default;
$plyr-audio-control-color-hover:             $COLOR-BRAND !default;
$plyr-audio-control-background-hover:        transparent !default;

// Enable helpers
// node_modules/plyr/src/sass/settings/helpers.scss
$plyr-border-box:                            true !default;
$plyr-touch-action:                          true !default;
$plyr-sr-only-important:                     true !default;

// Menus
// node_modules/plyr/src/sass/settings/menus.scss
$plyr-menu-background:                       rgba($COLOR-WHITE, $OPACITY-HIGHEST) !default;
$plyr-menu-radius:                           $GLOBAL-INPUT-BORDER-RADIUS !default;
$plyr-menu-color:                            $plyr-color-main !default;
$plyr-menu-shadow:                           $GLOBAL-BOX-SHADOW !default;
$plyr-menu-arrow-size:                       6px !default;
$plyr-menu-item-arrow-size:                  $plyr-menu-arrow-size !default;
$plyr-menu-item-arrow-color:                 $plyr-color-main !default;
$plyr-menu-back-border-color:                $plyr-color-main !default;
$plyr-menu-back-border-shadow-color:         $COLOR-WHITE !default;

// Progress
// node_modules/plyr/src/sass/settings/progress.scss
$plyr-progress-loading-size:                 25px !default;
$plyr-progress-loading-background:           transparentize($COLOR-BASE, $OPACITY-HIGH) !default;
$plyr-video-progress-buffered-background:    transparentize($COLOR-WHITE, $OPACITY-LOW) !default;
$plyr-audio-progress-buffered-background:    transparentize($COLOR-WHITE, $OPACITY-LOW) !default;

// Sliders
// node_modules/plyr/src/sass/settings/sliders.scss
$plyr-range-thumb-height:                    20px !default;
$plyr-range-thumb-background:                $plyr-color-main !default;
$plyr-range-thumb-shadow:                    none !default;
$plyr-range-thumb-active-shadow-width:       3px !default;
$plyr-range-track-height:                    2px !default;
$plyr-range-fill-background:                 $plyr-color-main !default;
$plyr-video-range-track-background:          $plyr-video-progress-buffered-background !default;
$plyr-video-range-thumb-active-shadow-color: rgba($COLOR-WHITE, $OPACITY-HIGHEST) !default;
$plyr-audio-range-track-background:          $plyr-audio-progress-buffered-background !default;
$plyr-audio-range-thumb-active-shadow-color: rgba($COLOR-BRAND, $OPACITY-HIGHEST) !default;

// Tooltips
// node_modules/plyr/src/sass/settings/tooltips.scss
$plyr-tooltip-background:                    transparentize($plyr-color-main, $OPACITY-LOW) !default;
$plyr-tooltip-color:                         $COLOR-WHITE !default;
$plyr-tooltip-padding:                       $GLOBAL-SPACING-UNIT-SMALL !default;
$plyr-tooltip-arrow-size:                    4px !default;
$plyr-tooltip-radius:                        0 !default;
$plyr-tooltip-shadow:                        $GLOBAL-BOX-SHADOW !default;
