/* ------------------------------------*\
    #FOOTER
\*------------------------------------ */

$skoda-footer-meta-nav-spacing-bottom: $GLOBAL-SPACING-UNIT-XLARGE !default;
$skoda-footer-meta-nav-item-spacing:   $GLOBAL-SPACING-UNIT-XSMALL !default;

.c-footer-meta-nav {

    @include media-query("<=#{$footer-meta-nav-breakpoint}") {
        gap: $skoda-footer-meta-nav-item-spacing;
        padding-bottom: $skoda-footer-meta-nav-spacing-bottom;
    }

}
