/* ------------------------------------*\
    #FLOATING-BUTTON
\*------------------------------------ */

$floating-button-grid-area:                 bottom-right !default;
$floating-button-border:                    null !default;
$floating-button-shadow:                    null !default;
$floating-button-background-color:          null !default;
$floating-button-background-color-hover:    null !default;
$floating-button-background-color-disabled: var(--button-background) !default;
$floating-button-icon-only:                 true !default;
$floating-button-icon-only-round:           $floating-button-icon-only !default;
$floating-button-icon-only-size:            map-get($ICON-ROUND-FULL-SIZES, "medium") !default;
$floating-button-icon-only-border-radius:   50% !default;
$floating-button-icon-size:                 icon-size(small) !default;
$floating-button-icon-color:                inherit !default;
$floating-button-label-spacing:             $GLOBAL-SPACING-UNIT-XSMALL !default;
$floating-button-label-color:               $floating-button-icon-color !default;

/**
 * [1] Allow click-through events if click-targets are behind the sticky
 *     back-to-top container.
 * [2] Reset default button padding to prevent issues with size.
 * [3] Ensure correct positioning, independent of parent's spacings etc.
 */

.c-floating-button {
    @include float-grid-area(
        $area: $floating-button-grid-area,
    );
}

    .c-floating-button__button {
        @if ($floating-button-icon-only) {
            width: $floating-button-icon-only-size;
            height: $floating-button-icon-only-size;
        }
        @if ($floating-button-icon-only-round) {
            padding: 0; /* [2] */
            border-radius: $floating-button-icon-only-border-radius;
        }
        border: $floating-button-border;
        background-color: $floating-button-background-color;
        box-shadow: $floating-button-shadow;
        pointer-events: auto; /* [1] */

        &:hover,
        &:focus {
            background-color: $floating-button-background-color-hover;
        }

        &:disabled,
        &[disabled] {
            background-color: $floating-button-background-color-disabled;
        }

    }

    .c-floating-button__icon {
        @if ($floating-button-icon-size) {
            @include font-size(
                $font-size: $floating-button-icon-size,
                $line-height: 1
            );
        }
        color: $floating-button-icon-color;
    }

    .c-floating-button__label {
        @if ($floating-button-icon-only) {
            @include a11y-hidden();
        } @else {
            margin-left: $floating-button-label-spacing;
        }
        color: $floating-button-label-color;
    }
