/* ------------------------------------*\
    #SITE-NAV
\*------------------------------------ */

$site-nav-flex-direction-at-breakpoint:                column !default;
$site-nav-height-breakpoint:                           $GLOBAL-PAGE-HEAD-BREAKPOINT !default;
$site-nav-height:                                      $GLOBAL-PAGE-HEAD-HEIGHT !default;
$site-nav-height-at-breakpoint:                        $GLOBAL-PAGE-HEAD-HEIGHT-AT-BREAKPOINT !default;
$site-nav-spacing-horizontal:                          $GLOBAL-SPACING-UNIT-SMALL !default;
$site-nav-padding-vertical-at-breakpoint:              $GLOBAL-SPACING-UNIT !default;
$site-nav-padding-horizontal-at-breakpoint:            $page-wrap-padding-horizontal !default;
$site-nav-background-color-at-breakpoint:              $COLOR-BRAND !default;
$site-nav-text-transform:                              null !default;

$site-nav-divider-color-at-breakpoint:                 $COLOR-WHITE !default;
$site-nav-divider-width-at-breakpoint:                 1px !default;
$site-nav-divider-at-breakpoint:                       $site-nav-divider-width-at-breakpoint solid $site-nav-divider-color-at-breakpoint !default;

// Alignment can be `left` or `right`
$site-nav-toggle-size:                                 null !default;
$site-nav-toggle-alignment:                            right !default;
$site-nav-toggle-icon:                                 get-icon(semantic-menu) !default;
$site-nav-toggle-icon-state-expanded:                  false !default;
$site-nav-toggle-icon-size:                            icon-size(medium) !default;
$site-nav-toggle-color:                                $ICON-COLOR !default;
$site-nav-toggle-color-hover:                          null !default;
$site-nav-toggle-state-expanded:                       "[aria-expanded='true']" !default;
$site-nav-toggle-label-spacing:                        null !default;
$site-nav-toggle-open-has-label:                       false !default;
$site-nav-toggle-close-has-label:                      false !default;

$site-nav-panel-state-collapsed:                       ".is-collapsed" !default;
$site-nav-panel-state-transition-in:                   null !default;
$site-nav-panel-state-transition-out:                  null !default;
$site-nav-panel-state-has-transition:                  $site-nav-panel-state-transition-in or $site-nav-panel-state-transition-out !default;

$site-nav-item-spacing-horizontal:                     $GLOBAL-SPACING-UNIT-SMALL !default;

$site-nav-link-flex-direction:                         row !default;
$site-nav-link-flex-direction-reverse:                 row-reverse !default;
$site-nav-link-flex-justify-content:                   null !default;
$site-nav-link-icon-size:                              icon-size(small) !default;
$site-nav-link-icon-size-at-breakpoint:                $site-nav-link-icon-size !default;
$site-nav-link-icon-rotation-expanded:                 null !default;
$site-nav-link-label-font-family:                      null !default;
$site-nav-link-label-font-family-at-breakpoint:        null !default;
$site-nav-link-label-font-size:                        $FS0 !default;
$site-nav-link-label-font-size-at-breakpoint:          null !default;
// Make sure that the `line-height` is not smaller than the icon size,
// so that each item, whether with or without an icon, has the same height.
$site-nav-link-label-line-height:                      $site-nav-link-icon-size !default;
$site-nav-link-label-line-height-at-breakpoint:        $site-nav-link-icon-size-at-breakpoint !default;
$site-nav-link-label-font-weight:                      $GLOBAL-FONT-WEIGHT-BOLD !default;
$site-nav-link-label-font-weight-at-breakpoint:        null !default;
$site-nav-link-label-text-align:                       left !default;
$site-nav-link-label-text-transform:                   false !default;
$site-nav-link-label-text-color:                       $COLOR-BASE-TEXT !default;
$site-nav-link-label-text-color-hover:                 $site-nav-link-label-text-color !default;
$site-nav-link-label-text-color-active:                $site-nav-link-label-text-color !default;
$site-nav-link-label-text-color-at-breakpoint:         $COLOR-WHITE !default;
$site-nav-link-label-text-color-hover-at-breakpoint:   $COLOR-WHITE !default;
$site-nav-link-label-text-color-active-at-breakpoint:  $site-nav-link-label-text-color-hover-at-breakpoint !default;
$site-nav-link-label-text-decoration:                  none !default;
$site-nav-link-label-padding-vertical-at-breakpoint:   $GLOBAL-SPACING-UNIT-XSMALL !default;
$site-nav-link-label-padding-horizontal-at-breakpoint: $GLOBAL-SPACING-UNIT-SMALL !default;
$site-nav-link-icon-is-custom:                         false !default;
$site-nav-link-icon:                                   get-icon(semantic-expand) !default;
$site-nav-link-icon-spacing-horizontal:                0 !default;
$site-nav-link-icon-color:                             $site-nav-link-label-text-color !default;
$site-nav-link-icon-color-hover:                       $site-nav-link-icon-color !default;
$site-nav-link-icon-color-active:                      $site-nav-link-icon-color !default;
$site-nav-link-icon-color-at-breakpoint:               $site-nav-link-label-text-color-at-breakpoint !default;
$site-nav-link-icon-color-hover-at-breakpoint:         $site-nav-link-label-text-color-hover-at-breakpoint !default;
$site-nav-link-icon-color-active-at-breakpoint:        $site-nav-link-icon-color-hover-at-breakpoint !default;
$site-nav-link-state-expanded:                         "[aria-expanded='true']" !default;
$site-nav-link-state-active:                           ".is-active" !default;
$site-nav-link-hover-underline-effect:                 false !default;

/**
 * [1]  If there is a `.site-nav--right`, preserve space between the two navs.
 * [2]  Align the toggle to the right side.
 * [3]  Center `.c-site-nav__toggle-icon` and `.c-site-nav__toggle-close-label`.
 * [4]  Hide the label only visibly and not from screen readers. Note that the
 *      label is set to `display: none` when the menu is not expanded since the
 *      label is used exclusively for the close action.
 * [5]  We can either use an icon from the icon font or use a CSS-built triangle.
 *      Control that with the `$site-nav-link-icon-is-custom` feature flag.
 * [6]  Always set the value of `flex-direction` to the opposite value if
 *      `.is-reversed` is used and set it to `row` at the breakpoint.
 * [7]  Add spacing between label and icon, depending on the default position
 *      of the icon (before or after the label).
 * [8]  Make sure the site nav dropdown is aligned to the `.c-site-nav__item.`
 *      and is not overlapping the header.
 * [9]  Place list item on top when collapsed.
 * [10] Reset default list style. Similar to `list-bare` mixin but only
 *      necessary styles for this component and with less generic selectors
 *      and output.
 * [11] Reset default `<button>` styles.
 * [12] If `.c-site-nav__link` is a `button` (triggering a dropdown), hide the
 *      focus outline if the dropdown gets expanded. The focus outline visually
 *      differentiates buttons from links when using a pointing device (such as
 *      a mouse) – which is not wanted here. The transition delay is necessary
 *      to avoid a short flickering of the outline. When navigating by keyboard
 *      the focus outline should be visible for accessibility reasons.
 * [13] Some brands (e.g. VW6) don't need flex-direction.
 * [14] Set margin for reversed link icons, depending on the default position
 *      of the icon (before or after the label). The selector had to be
 *      duplicated because the `site-nav-breakpoint` mixin does not work with
 *      nested selector referencing parent (`&`).
 */
.c-site-nav {
    display: flex;
    width: 100%;
    height: $site-nav-height;

    @include media-query("<=#{$site-nav-height-breakpoint}") {
        height: $site-nav-height-at-breakpoint;
    }

    &,
    .c-site-nav__link-label {
        text-transform: $site-nav-text-transform;
    }

}

    .c-site-nav__toggle {
        @include reset-button();
        display: none;
        color: $site-nav-toggle-color;

        @if ($site-nav-toggle-size) {
            width: $site-nav-toggle-size;
        }

        @if ($site-nav-toggle-alignment == "right") {
            margin-left: auto; /* [2] */
        }

        @include site-nav-breakpoint("below") {
            display: flex; /* [3] */
            justify-content: center;
            align-items: center; /* [3] */
        }

        &:hover,
        &:focus-visible {
            color: $site-nav-toggle-color-hover;
        }

    }

    .c-site-nav__toggle-open-label,
    .c-site-nav__toggle-close-label {
        margin-left: $site-nav-toggle-label-spacing;
    }

    .c-site-nav__toggle-open-label {
        @if not ($site-nav-toggle-open-has-label) {
            @include a11y-hidden();
        }
    }

    .c-site-nav__toggle-close-label {
        display: none;
    }

    .c-site-nav__toggle-icon {
        @include icon-font($line-height: null);
        @include font-size(
            $font-size:   $site-nav-toggle-icon-size,
            $line-height: 1,
        );

        &::before {
            content: $site-nav-toggle-icon;
        }

        &:hover,
        &:focus {
            color: $site-nav-toggle-color-hover;
        }

        .c-site-nav__toggle#{$site-nav-toggle-state-expanded} & {

            ~ .c-site-nav__toggle-open-label {
                display: none;
            }

            ~ .c-site-nav__toggle-close-label {
                display: block;

                @if not ($site-nav-toggle-close-has-label) {
                    @include a11y-hidden(); /* [4] */
                }

            }

            @if ($site-nav-toggle-icon-state-expanded) {
                &::before {
                    content: $site-nav-toggle-icon-state-expanded;
                }
            }

        }

    }

    .c-site-nav__panel {
        display: flex;
        width: 100%;

        @include site-nav-breakpoint("below") {
            @if not ($site-nav-panel-state-has-transition) {
                display: none;
            }
            position: absolute;
            top: 100%;
            right: 0;

            @if ($site-nav-panel-state-has-transition) {
                @include transition("transform");
                transform: $site-nav-panel-state-transition-out;
            }

            &:not(#{$site-nav-panel-state-collapsed}) {
                @if ($site-nav-panel-state-has-transition) {
                    transform: $site-nav-panel-state-transition-in;
                } @else {
                    display: flex;
                }
            }

        }

    }

    .c-site-nav__navs {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include site-nav-breakpoint("below") {
            @include responsive-property(
                ("padding-top",    $site-nav-padding-vertical-at-breakpoint),
                ("padding-left",   $site-nav-padding-horizontal-at-breakpoint),
                ("padding-right",  $site-nav-padding-horizontal-at-breakpoint),
                ("padding-bottom", $site-nav-padding-vertical-at-breakpoint),
            );
            flex-direction: if($site-nav-flex-direction-at-breakpoint, $site-nav-flex-direction-at-breakpoint, null); /* [13] */
            background-color: if($site-nav-background-color-at-breakpoint, $site-nav-background-color-at-breakpoint, null);
        }

    }

    .c-site-nav__nav {
        display: flex;
        align-items: center;
        margin: 0; /* [10] */
        padding: 0; /* [10] */
        list-style: none; /* [10] */

        @include site-nav-breakpoint("below") {
            flex-direction: column;
            align-items: flex-end;
        }

        &:not(:last-child) {
            margin-right: $site-nav-spacing-horizontal; /* [1] */

            @include site-nav-breakpoint("below") {
                margin-right: 0;
            }

        }

    }

    .c-site-nav__item.c-site-nav__item {
        position: relative; /* [8] */
        display: flex;
        align-items: center;
        margin-bottom: 0; /* [10] */

        @include site-nav-breakpoint("above") {
            height: 100%; /* [8] */
        }

        @include site-nav-breakpoint("below") {
            flex-direction: column;
            width: 100%;

            @if ($site-nav-divider-at-breakpoint) {
                border-bottom: $site-nav-divider-at-breakpoint;
            }

            &.c-site-nav__item--top-at-breakpoint {
                order: -1; /* [9] */
            }

        }

        &:not(:last-child) {
            margin-right: $site-nav-item-spacing-horizontal;

            @include site-nav-breakpoint("below") {
                margin-right: 0;
            }

        }

    }

    .c-site-nav__link {
        @include reset-button($remove-focus: false); /* [11] */
        @if ($site-nav-link-hover-underline-effect) {
	        @include hover-underline-effect();
		}
        display: flex;
        justify-content: $site-nav-link-flex-justify-content;
        align-items: center;
        flex-direction: $site-nav-link-flex-direction;
        text-decoration: $site-nav-link-label-text-decoration;

        @include site-nav-breakpoint("below") {
            @include responsive-property(
                ("padding-top",    $site-nav-link-label-padding-vertical-at-breakpoint),
                ("padding-left",   $site-nav-link-label-padding-horizontal-at-breakpoint),
                ("padding-right",  $site-nav-link-label-padding-horizontal-at-breakpoint),
                ("padding-bottom", $site-nav-link-label-padding-vertical-at-breakpoint),
            );
            flex-direction: row; /* [6] */
            width: 100%;
        }

        &.is-reversed {
            flex-direction: $site-nav-link-flex-direction-reverse; /* [6] */
        }

    }

    button.c-site-nav__link {
        outline-width: 0; /* [12] */

        &:focus {
            outline-width: initial; /* [12] */
            transition: 0s outline 0.2s; /* [12] */
        }

        &[aria-expanded="true"]:focus {
            outline-width: 0; /* [12] */
            transition-delay: 0s; /* [12] */
        }

    }

    .c-site-nav__link-label {
        @include transition();
        @include responsive-font-size(
            $font-size:   $site-nav-link-label-font-size,
            $line-height: $site-nav-link-label-line-height,
        );
        font-family: $site-nav-link-label-font-family;
        font-weight: $site-nav-link-label-font-weight;
        color: $site-nav-link-label-text-color;

        @if ($site-nav-link-label-text-transform) {
            text-transform: $site-nav-link-label-text-transform;
        }

        @include site-nav-breakpoint("below") {
            @include responsive-font-size(
                $font-size:   $site-nav-link-label-font-size-at-breakpoint,
                $line-height: $site-nav-link-label-line-height-at-breakpoint,
                $accept-null: true,
            );
            font-family: $site-nav-link-label-font-family-at-breakpoint;
            font-weight: $site-nav-link-label-font-weight-at-breakpoint;
            text-align: $site-nav-link-label-text-align;
            color: if($site-nav-link-label-text-color-at-breakpoint, $site-nav-link-label-text-color-at-breakpoint, null);
        }

        .c-site-nav__link:hover &,
        .c-site-nav__link:focus & {
            color: $site-nav-link-label-text-color-hover;

            @include site-nav-breakpoint("below") {
                color: if($site-nav-link-label-text-color-hover-at-breakpoint, $site-nav-link-label-text-color-hover-at-breakpoint, null);
            }

        }

        .c-site-nav__link#{$site-nav-link-state-active} &,
        .c-site-nav__link#{$site-nav-link-state-expanded} & {
            color: $site-nav-link-label-text-color-active;

            @include site-nav-breakpoint("below") {
                color: if($site-nav-link-label-text-color-active-at-breakpoint, $site-nav-link-label-text-color-active-at-breakpoint, null);
            }

        }

    }

    .c-site-nav__link-icon {
        @include transition();
        display: flex;

        @if ($site-nav-link-flex-direction == row) { /* [7] */
            margin-left: $site-nav-link-icon-spacing-horizontal;
        } @else {
            margin-right: $site-nav-link-icon-spacing-horizontal;
        }

        @if ($site-nav-link-icon-is-custom) { /* [5] */
            position: relative;
            top: ($site-nav-link-icon-size * 0.25);
            width: 0;
            height: 0;
            border-width: ($site-nav-link-icon-size * 0.5);
            border-style: solid;
            border-color: transparent;
            border-top-color: $site-nav-link-icon-color;

            @include site-nav-breakpoint("below") {
                top: ($site-nav-link-icon-size-at-breakpoint * 0.25);
                border-width: ($site-nav-link-icon-size-at-breakpoint * 0.5);
                border-top-color: if($site-nav-link-icon-color-at-breakpoint, $site-nav-link-icon-color-at-breakpoint, null);
            }

            .c-site-nav__link:hover &,
            .c-site-nav__link:focus & {
                border-top-color: $site-nav-link-icon-color-hover;

                @include site-nav-breakpoint("below") {
                    border-top-color: if($site-nav-link-icon-color-hover-at-breakpoint, $site-nav-link-icon-color-hover-at-breakpoint, null);
                }

            }

            .c-site-nav__link#{$site-nav-link-state-active} &,
            .c-site-nav__link#{$site-nav-link-state-expanded} & {
                border-top-color: $site-nav-link-icon-color-active;

                @include site-nav-breakpoint("below") {
                    border-top-color: if($site-nav-link-icon-color-active-at-breakpoint, $site-nav-link-icon-color-active-at-breakpoint, null);
                }

            }

        } @else {
            @include font-size(
                $font-size:   $site-nav-link-icon-size,
                $line-height: 1,
            );
            color: $site-nav-link-icon-color;

            @include site-nav-breakpoint("below") {
                @include font-size(
                    $font-size:   $site-nav-link-icon-size-at-breakpoint,
                    $line-height: 1,
                );
                color: if($site-nav-link-icon-color-at-breakpoint, $site-nav-link-icon-color-at-breakpoint, null);
            }

            &::after {
                @include icon-font();
                content: $site-nav-link-icon;
            }

            .c-site-nav__link:hover &,
            .c-site-nav__link:focus & {
                color: $site-nav-link-icon-color-hover;

                @include site-nav-breakpoint("below") {
                    color: if($site-nav-link-icon-color-hover-at-breakpoint, $site-nav-link-icon-color-hover-at-breakpoint, null);
                }

            }

            .c-site-nav__link#{$site-nav-link-state-active} &,
            .c-site-nav__link#{$site-nav-link-state-expanded} & {
                color: $site-nav-link-icon-color-active;

                @include site-nav-breakpoint("below") {
                    color: if($site-nav-link-icon-color-active-at-breakpoint, $site-nav-link-icon-color-active-at-breakpoint, null);

                    &::after {
                        transform: $site-nav-link-icon-rotation-expanded;
                    }
                }

            }

        }

    }

    /* stylelint-disable-next-line no-duplicate-selectors */
    .c-site-nav__link.is-reversed { /* [14] */

        @if ($site-nav-link-flex-direction == row) {

            @include site-nav-breakpoint("above") {

                .c-site-nav__link-icon {
                    margin-right: $site-nav-link-icon-spacing-horizontal;
                    margin-left: 0;
                }

            }

        } @else {

            @include site-nav-breakpoint("above") {

                .c-site-nav__link-icon {
                    margin-right: 0;
                    margin-left: $site-nav-link-icon-spacing-horizontal;
                }

            }

        }

    }
