/* ------------------------------------*\
    #FLICKITY
\*------------------------------------ */

/**
 * Flickity v2.0.10
 * (https://flickity.metafizzy.co)
 */

$flickity-button-size:                              map-get($ICON-SQUARE-FULL-SIZES, small) !default;
$flickity-button-icon-next:                         get-icon(semantic-forward) !default;
$flickity-button-icon-prev:                         get-icon(semantic-back) !default;
$flickity-button-icon-size:                         icon-size(small) !default;
$flickity-button-icon-color:                        $COLOR-BRAND !default;
$flickity-button-background-color:                  $COLOR-WHITE !default;
$flickity-button-background-color-hover:            null !default;
$flickity-button-border-radius:                     0 !default;
$flickity-button-spacing:                           $GLOBAL-SPACING-UNIT-XSMALL !default;
$flickity-button-offset:                            $GLOBAL-SPACING-UNIT-XXSMALL !default;

$flickity-controls-spacing-vertical:                $GLOBAL-SPACING-UNIT-XSMALL !default;
$flickity-controls-spacing-horizontal:              $GLOBAL-SPACING-UNIT-XSMALL !default;

$flickity-fade-size:                                30px !default;
$flickity-padding:                                  $flickity-fade-size - $flickity-button-offset !default;

$flickity-fullscreen-background-color:              $COLOR-WHITE !default;
// Add the fullscreen spacing to bottom which is the same as the minimum dot size.
$flickity-fullscreen-padding:                       $GLOBAL-POINTER-TARGET-SIZE !default;
$flickity-fullscreen-layer:                         z(modal) !default;
$flickity-fullscreen-button-border-radius:          $GLOBAL-BORDER-RADIUS !default;
$flickity-fullscreen-button-top:                    0 !default;
$flickity-fullscreen-button-right:                  0 !default;
$flickity-fullscreen-button-bottom:                 null !default;
$flickity-fullscreen-button-left:                   null !default;
$flickity-fullscreen-button-size:                   $flickity-button-size !default;
$flickity-fullscreen-button-background-color:       $COLOR-WHITE !default;
$flickity-fullscreen-button-background-color-hover: $COLOR-WHITE !default;
$flickity-fullscreen-button-padding:                null !default;
$flickity-fullscreen-button-border:                 0 !default;
$flickity-fullscreen-icon-enter-fullscreen:         get-icon(semantic-enter-fullscreen) !default;
$flickity-fullscreen-icon-exit-fullscreen:          get-icon(semantic-exit-fullscreen) !default;
$flickity-fullscreen-icon-size:                     icon-size(small) !default;
$flickity-fullscreen-icon-color:                    $COLOR-BRAND !default;

$flickity-dot-width:                                12px !default;
$flickity-dot-height:                               $flickity-dot-width !default;
$flickity-dot-background:                           $COLOR-WHITE !default;
$flickity-dot-background-active:                    $COLOR-HIGHLIGHT !default;
$flickity-dot-border-width:                         1px !default;
$flickity-dot-border-color:                         $COLOR-BRAND !default;
$flickity-dot-border-color-active:                  $flickity-dot-background-active !default;
$flickity-dot-border:                               $flickity-dot-border-width solid $flickity-dot-border-color !default;
$flickity-dot-border-radius:                        50% !default;
$flickity-dot-inner-spacing:                        $GLOBAL-SPACING-UNIT-XXSMALL !default;
$flickity-dot-size:                                 $GLOBAL-POINTER-TARGET-SIZE !default;
$flickity-dot-transform:                            null !default;
$flickity-dot-transform-selected:                   null !default;

/**
 * [1] Reset default list styles.
 * [2] Horizontally center page-dots.
 * [3] Hide default Flickity icon.
 * [4] Remove focus outline styles for the fullscreen button.
 * [5] Hide Flickity’s injected SVG icon.
 * [6] Hide the reveal button when presenting in fullscreen.
 * [7] Set minimal pointer size for the dots.
 * [8] Make space for the dots in the fullscreen mode.
 * [9] Height is set to 0 before being overwritten by min-height to vertically center
 *     the dots in IE11. Otherwise align-items: center would not work in IE11.
 * [10] When the number of dots exceeds the limit, the dots turn into a counter.
 *      CSS generated content shows `x / y`.
 *      - `counter-increment` is used for numbering
 *      - `::before` pseudo element on the current item is used for the counter
 *      - `::after` pseudo element on the last item is used for the denominator
 *         including a seperator (`/`).
 * [11] Force the slider items to take up all horizontal space.
 * [12] Show controls only when slider has slideable items (> 1).
 * [13] Transform the dots (e.g. `scale(1.5)`).
 * [14] Ensure horizontal and vertical centering.
 * [15] Spacing for controls (buttons, dots).
 */

/// Define button size. Use either defined button-size or icon-size, depending on which is larger.
///
/// @param {number} $_button-size [defined button-size]
/// @param {number} $_icon-size [defined icon-size]
/// @returns {number} Size depending on larger size.
@function flickity-button-size($_button-size: $flickity-button-size, $_icon-size: $flickity-button-icon-size) {

    // Make sure the comparing sizes have the same unit
    @if (unit($_button-size) == unit($_icon-size)) {
        // If the icon size is larger, than use this instead
        @if ($_icon-size > $_button-size) {
            @return $_icon-size;
        }
    }

    // If the button size is larger, use it.
    @return $_button-size;
}

@mixin flickity-button-fade($direction: right, $color: $color-white) {
    background-image: linear-gradient(to $direction, rgba(255, 255, 255, 0) 0%, $color $flickity-button-offset * 2);
}

.flickity-enabled {
    position: relative;
    display: flex; /* [8] */
    flex-direction: row; /* [8] */
    flex-wrap: wrap; /* [8] */
    justify-content: center; /* [9] */
    align-items: center;
    row-gap: $flickity-controls-spacing-vertical; /* [15] */
    column-gap: $flickity-controls-spacing-horizontal; /* [15] */
    padding-right: 0;
    padding-left: 0;

    &:focus {
        outline: none;
    }

    /**
     * Draggable.
     */
    &.is-draggable {
        @include disable-touch-highlight();
        user-select: none;

        .flickity-viewport {
            cursor: grab;
        }

        /* stylelint-disable selector-max-class */
        .flickity-viewport.is-pointer-down {
            cursor: grabbing;
        }
        /* stylelint-enable */
    }

    /**
     * Fullscreen.
     */
    &.is-fullscreen {
        position: fixed;
        z-index: $flickity-fullscreen-layer;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-bottom: $flickity-fullscreen-padding; /* [8] */
        background-color: $flickity-fullscreen-background-color;

        .flickity-fullscreen-button-exit {
            display: block;

            &::before {
                content: $flickity-fullscreen-icon-exit-fullscreen;
            }
        }

        .flickity-fullscreen-button-view {
            display: none; /* [6] */
        }

    }

    .flickity-fullscreen-button {
        @include transition();
        position: absolute;
        top: $flickity-fullscreen-button-top;
        right: $flickity-fullscreen-button-right;
        bottom: $flickity-fullscreen-button-bottom;
        left: $flickity-fullscreen-button-left;
        width: $flickity-fullscreen-button-size;
        height: $flickity-fullscreen-button-size;
        padding: $flickity-fullscreen-button-padding;
        border: $flickity-fullscreen-button-border;
        border-radius: $flickity-fullscreen-button-border-radius;
        background-color: $flickity-fullscreen-button-background-color;

        &::before {
            @include icon-font($defaults: null);
            @include font-size(
                $font-size:   $flickity-fullscreen-icon-size,
                $line-height: 1
            );
            content: $flickity-fullscreen-icon-enter-fullscreen;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $flickity-fullscreen-icon-color;
            cursor: pointer;
        }

        @if ($flickity-fullscreen-button-background-color-hover != $flickity-fullscreen-button-background-color) {
            &:hover,
            &:focus {
                background-color: $flickity-fullscreen-button-background-color-hover;
            }
        }

        &:focus-visible {
            outline: 0; /* [4] */
        }

    }

    .flickity-fullscreen-button-exit {
        display: none;  /* [3] */
    }

    .flickity-button-icon {
        display: none; /* [5] */
    }

}

.flickity-viewport {
    position: relative;
    width: 100%; /* [11] */
    height: 100%;
    overflow: hidden;
}

.flickity-slider {
    position: absolute;
    order: 0;
    width: 100%;
    height: 100%;
}


/**
 * Previous/next buttons.
 */
.flickity-prev-next-button {
    display: inline-flex; /* [14] */
    justify-content: center; /* [14] */
    align-items: center; /* [14] */
    width: flickity-button-size();
    height: flickity-button-size();
    padding: 0;
    border: none;
    border-radius: $flickity-button-border-radius;
    background-color: transparent;
    color: $flickity-button-icon-color;
    cursor: pointer;

    // Icon
    &::before {
        @include icon-font($defaults: null);
        @include font-size(
            $font-size:   $flickity-button-icon-size,
            $line-height: 1,
        );
        position: relative;
        z-index: 10;
    }

    &:hover {
        background-color: $flickity-button-background-color-hover;
    }

    // @TODO: Find proper focus outline styles, @see BRON-5147.
    &:focus-visible {
        outline: 0;
    }

    &:disabled {
        opacity: 0; /* [12] */
        visibility: hidden; /* [12] */
    }

    &.previous {
        order: 1;

        &::before {
            content: $flickity-button-icon-prev;
        }

    }

    &.next {
        order: 3;

        &::before {
            content: $flickity-button-icon-next;
        }

    }

}

.flickity-page-dots {
    display: flex; /* [2] */
    align-items: center;
    justify-content: center; /* [2] */
    order: 2;
    margin: 0; /* [1] */
    padding: 0; /* [1] */

    .dot {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 0; /* [9] */
        min-height: $flickity-dot-size; /* [7] */
        margin-right: $flickity-dot-inner-spacing;
        margin-bottom: 0;
        margin-left: $flickity-dot-inner-spacing;
        cursor: pointer;

        &:only-child {
            display: none; /* [12] */
        }

        &::after {
            @include transition();
            content: "";
            display: block;
            width: $flickity-dot-width;
            height: $flickity-dot-height;
            transform: $flickity-dot-transform; /* [13] */
            border: $flickity-dot-border;
            border-radius: $flickity-dot-border-radius;
            background-color: $flickity-dot-background;
        }

        &.is-selected {

            &::after {
                transform: $flickity-dot-transform-selected; /* [13] */
                border-color: $flickity-dot-border-color-active;
                background-color: $flickity-dot-background-active;
            }

        }

    }

}

/* [10] */
.dot {

    .flickity-enabled:not(.c-hero-teaser) & {
        @include flickity-dot-counter-switch();
    }

    .flickity-enabled[data-slider-counter="true"] & {
        @include flickity-dot-counter-switch(
            $limit: 1,
        );
    }

}
