/* ------------------------------------*\
    #VISUALLY-HIDDEN
\*------------------------------------ */

/**
 * A utility class to hide elements visually
 * but keep the element text available to be announced by a screen reader.
 */

.u-visually-hidden {
    @include a11y-hidden($important: true);
}
