/* ------------------------------------*\
    #INFO-ICON (Scopes)
\*------------------------------------ */

/**
 * Hotfix for Info Icon layout issues when the icon is a direct child of
 * `.c-form-field` The size and position of the info icon was affected by the
 * flexbox context. `.c-form-field > .c-info-icon` is not a recommended markup
 * order. Ideally `.c-info-icon` should be inside of `.c-form-field__label`.
 * But we ourselves have wrongly demonstrated this in
 * https://bronson.vwfs.tools/default/v/7.14.0/components/preview/bronson-calculation-page--calculation-page.html)
 * Bug report: https://jira.platform.vwfs.io/browse/BRONSD-135?focusedCommentId=49997&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-49997
 *
 * @TODO: Refactor Info Icon CSS for all brands by simplifying SCSS and making it more robust.
 *
 * [hf-1] Reset negative margins. See [3].
 * [hf-2] Override the default `align-items` value (`stretch`).
 */

.c-form-field {

    > .c-info-icon {
        align-self: center; /* [hf-2] */
        margin-top: 0; /* [hf-1] */
        margin-bottom: 0; /* [hf-1] */
    }

}

/**
 * Fix incorrect positioning in various contexts due to negative margin "hack".
 */

.c-info-icon {

    .c-section-heading & {
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: top;
    }

}
