/* ------------------------------------*\
    #FOLD-OUT
\*------------------------------------ */

$skoda-fold-out-close-button-state-size: $BUTTON-ICON-INTERACTION-SIZE !default;
$skoda-fold-out-close-button-offset:     -6px !default;
$skoda-fold-out-header-height:           ($skoda-fold-out-close-button-state-size + $GLOBAL-FOCUS-WIDTH * 2) !default; /* [1] */

/**
 * [1] Set height to have button (icon) clickable.
 *     Add outline as offset to avoid overflow/cutoffs.
 * [2] Outline handled via icon.
 * [3] Magic numbers for aligning circle for states with icon.
 * [4] Place button (icon) on the right side.
 */
.c-fold-out__header {
    @include button-icon-states(
        $size:              $skoda-fold-out-close-button-state-size,
        $active-state:      false,
        $handle-position:   false,
        $handle-transition: false,
    );

    &:focus-visible {
        outline: 0; /* [2] */
    }

    &#{$fold-out-icon-pseudo-element} {
        order: 1; /* [4] */
    }

    &::after {
        @include transition(
            $transition-property: "background-color",
        );
        right: $skoda-fold-out-close-button-offset; /* [3] */
    }

    &#{$fold-out-header-close-state} {

        &::after {
            top: 50%;
            transform: translateY(-50%);
        }

    }

    &#{$fold-out-header-open-state} {
        height: $skoda-fold-out-header-height; /* [1] */
    }

}
