// /* ------------------------------------*\
//     #ICON-VARIANTS
// \*------------------------------------ */

$icon-enable-variants:   true !default; // Will be modified when opt-out is set.
$icon-border-width:      $ICON-BORDER-WIDTH !default;
$icon-border-style:      $ICON-BORDER-STYLE !default;
$icon-square-radius:     $ICON-SQUARE-RADIUS !default;
$icon-round-radius:      $ICON-ROUND-RADIUS !default;
$icon-round-full-sizes:  $ICON-ROUND-FULL-SIZES !default;
$icon-square-full-sizes: $ICON-SQUARE-FULL-SIZES !default;

// `$ICON-DISABLE-VARIANTS` must be set to `false` (default, see _settings.icon-fonts)
// in order for the mixin to work.
// This is to opt-out of this feature if no variants are wanted.
// Only the icon-size and color will be applied.
@if (variable-exists(ICON-DISABLE-VARIANTS) and ($ICON-DISABLE-VARIANTS == true)) {
    $icon-enable-variants: false;
}

// Mixin to include icon shapes, sizes and style patterns. The shape
// (square or round) as first parameter, followed by the size, text-color,
// background-color and border-color.
//
// Completely custom with all parameters:
// @include icon-variant(
//     $variant:          "round",
//     $size:             "small",
//     $text-color:       hotpink,
//     $background-color: black,
//     $border-color:     red
// );
//
// All parameters are optional, although the cosmetic stylings don’t have any
// default values. This is to prevent unnecessary CSS output when altering
// the size of the icon for a specific scope when the cosmetics should keep
// the same. E.g.:
//
// .foo {
//     @include icon-variant(
//         $variant:          "round",
//         $size:             "medium",
//         $text-color:       $foo-color,
//         $background-color: $foo-background,
//         $border-color:     $foo-border-color
//     );
//
//     &.foo--small {
//         @include icon-variant(
//             $variant: "round",
//             $size:    "small"
//         );  // No need to list the cosmetic again as parameters.
//     }
//
// }
//
//
//
// [1] Explicitly determine the size of the icon, so any potential
//     `.c-icon--[SIZE]` class that is in the markup, is overridden and
//     the desired font-size is taken.
// [2] Calculate the compound size of the icon via the `padding` property to
//     equal the desired sizes defined in the Sass maps. If a border is set,
//     substract it as well from the final padding.
@mixin icon-variant(
    $variant: "square",
    $size: "medium",
    $text-color: null,
    $background-color: null,
    $border-color: null,
    $pseudo-element: before
) {

    @include font-size(
        $font-size:   icon-size($size),
        $line-height: false
    ); // [1]

    @if ($text-color) {
        color: $text-color;
    }

    @if ($icon-enable-variants) {

        // Square variant.
        @if ($variant == "square") {
            &::#{$pseudo-element} {
                border-radius: $icon-square-radius;
                @if ($border-color) {
                    padding: ((map-get($icon-square-full-sizes, $size) - icon-size($size)) * 0.5) - ($icon-border-width); // [2]
                } @else {
                    padding: ((map-get($icon-square-full-sizes, $size) - icon-size($size)) * 0.5); // [2]
                }
            }
        }

        // Round variant.
        @if ($variant == "round") {
            &::#{$pseudo-element} {
                border-radius: $icon-round-radius;
                @if ($border-color) {
                    padding: ((map-get($icon-round-full-sizes, $size) - icon-size($size)) * 0.5) - ($icon-border-width); // [2]
                } @else {
                    padding: ((map-get($icon-round-full-sizes, $size) - icon-size($size)) * 0.5); // [2]
                }
            }
        }

        &::#{$pseudo-element} {
            @if ($border-color) {
                border: $icon-border-width $icon-border-style $border-color;
            }
            @if ($background-color) {
                background-color: $background-color;
            }
        }
    }

}

