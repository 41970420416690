// /* ------------------------------------*\
//     #TILE-SELECT
// \*------------------------------------ */

$skoda-tile-select-check-position:                             $GLOBAL-SPACING-UNIT-SMALL !default;
$skoda-tile-select-border-selected-hover:                      $CARD-PATTERN-BORDER-COLOR-SELECTED-HOVER !default;
$skoda-tile-select-disabled-color:                             $COLOR-INACTIVE !default;
$skoda-tile-select-check-icon-background-color-selected-hover: $CARD-PATTERN-SELECT-INDICATOR-BACKGROUND-COLOR-SELECTED-HOVER !default;

/**
 * [1] Reset default.
 * [2] Handle disabled state via color on all elements, not opacity.
 * [3] Reset compensation as the indicator icon is placed differently in this brand.
 */
.c-tile-select {
    padding-top: 0; /* [3] */
}

     .c-tile-select__body {

        &#{$tile-select-check-background},
        &#{$tile-select-check-foreground} {
            top: $skoda-tile-select-check-position;
            right: $skoda-tile-select-check-position;
            left: auto; /* [1] */
            transform: none; /* [1] */
        }

        &#{$tile-select-check-background} {
            border: 0; /* [1] */
        }

        // Selected @ hover
        /* stylelint-disable-next-line selector-max-specificity */
        .c-tile-select:not(.is-error) .c-tile-select__input[type="checkbox"]:checked:not(:disabled) + &:hover {
            border-color: $skoda-tile-select-border-selected-hover;

            /* stylelint-disable-next-line selector-max-specificity */
            &::before {
                background-color: $skoda-tile-select-check-icon-background-color-selected-hover;
            }

        }

        // Disabled
        .c-tile-select__input:disabled + & {

            *:not(.c-btn, .c-btn__text) {
                color: $skoda-tile-select-disabled-color; /* [2] */
            }

            .c-hint {
                opacity: 0.3;
            }

        }

    }
