// /* ------------------------------------*\
//     #BUTTON-ICON-STATES
// \*------------------------------------ */

/// Create state-based styles for interactive icon buttons.
/// See examples for usage and configuration in unit-test (`_tools.button-icon-states.spec.scss`).
///
/// @param {String} $selector ["::after"] Element selector to apply styles to.
/// @param {String} $context-selector [null] If the state is handled via a different element (context), the element can be set and the button is styled based on the context state.
/// @param {Number} $size [$BUTTON-ICON-INTERACTION-SIZE] Size for background circle.
/// @param {Color} $background-color-hover [$BUTTON-ICON-INTERACTION-BACKGROUND-COLOR-HOVER-ON-LIGHT] Set `background-color` for background circle.
/// @param {Boolean} $focus-state [true] Set default focus styles.
/// @param {String} $focus-type ["focus-visible"] Control focus style ("focus" | "focus-visible" | "focus-within").
/// @param {Color} $focus-outline-color [$GLOBAL-FOCUS-COLOR] Color for focus outline. Passed to `focus-styles()` mixin.
/// @param {Boolean} $active-state [true] Set active state for scaling.
/// @param {Number} $active-scale [$BUTTON-SCALE-ACTIVE] Control scaling value.
/// @param {Number} $z-index [-1] Background behind button. Can be adjusted to avoid stacking issues.
/// @param {Boolean} $handle-position [true] Handle positioning on element via flexbox.
/// @param {Boolean} $handle-transition [true] Handle transition within mixin on element and pseudo-element. Opt-out via `false` to handle transition individually.
@mixin button-icon-states(
    $selector:               "::after",
    $context-selector:       null,
    $size:                   $BUTTON-ICON-INTERACTION-SIZE,
    $background-color-hover: $BUTTON-ICON-INTERACTION-BACKGROUND-COLOR-HOVER-ON-LIGHT,
    $focus-state:            true,
    $focus-type:             "focus-visible",
    $focus-outline-color:    $GLOBAL-FOCUS-COLOR,
    $active-state:           true,
    $active-scale:           $BUTTON-SCALE-ACTIVE,
    $z-index:                -1,
    $handle-position:        true,
    $handle-transition:      true,
) {

    // Determine which selector controls the state.
    // Use `$context-selector` when set, otherwise use the button (mixin context via parent selector `&`).
    $_state-controller: if($context-selector, $context-selector, &);

    // Conditionally set the button selector based on set `$context-selector`.
    // If no context is set, use an empty selector (`null`) to avoid selector chaining (`.button .button`).
    $_button: if($context-selector, &, null);

    @include disable-touch-highlight();

    @if ($handle-transition) {
        @include transition();
    }

    @if ($handle-position) {
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    &#{$selector} {
        content: "";
        position: absolute;
        z-index: $z-index;
        width: $size;
        height: $size;
        border-radius: 50%;
        transition: if($handle-transition, inherit, null);
    }

    @at-root #{$_state-controller}:hover #{$_button} {

        &#{$selector} {
            background-color: $background-color-hover;
        }

    }

    @if ($focus-state) {

        @at-root #{$_state-controller}:#{$focus-type} #{$_button} {

            &#{$selector} {
                @include focus-styles(
                    $outline-offset: 0,
                    $outline-color: $focus-outline-color,
                );
            }

        }

    }

    @if ($active-state) {
        scale: 1;

        @at-root #{$_state-controller}:active #{$_button} {
            scale: $active-scale;
        }
    }

}
