// /* ------------------------------------*\
//     #FORM-SECTION (Settings)
// \*------------------------------------ */

$form-section-outline-enable:                    false !default;

$form-section-border-radius:                     $GLOBAL-BORDER-RADIUS-SMALL !default;
$form-section-border:                            1px solid $COLOR-BORDER !default;
$form-section-border-color-hover:                $COLOR-ON-SURFACE-900 !default;

$form-section-bg-color:                          $COLOR-SURFACE-PRIMARY !default;
$form-section-bg-color-disabled:                 rgba($COLOR-WHITE, 0.5) !default;

$form-section-title-font-size:                   $FS0 !default;
$form-section-title-margin-bottom:               $GLOBAL-SPACING-UNIT-XSMALL !default;
$form-section-subtitle-font-weight:              $GLOBAL-FONT-WEIGHT-LIGHT !default;

$form-section-panel-spacing-top:                 0 !default;

$form-section-icon-size:                         icon-size(xsmall) !default;
$form-section-icon-size-on-breakpoint:           $form-section-icon-size !default;
$form-section-icon-color:                        $COLOR-BASE-TEXT !default;

$form-section-icon-collapsed:                    get-icon(semantic-plus) !default;
$form-section-icon-expanded:                     get-icon(semantic-minus) !default;

$form-section-icon-transform-expanded:           none !default;
$form-section-icon-transform-collapsed:          none !default;

$form-section-validation-icon-expanded-success:  get-icon(check-circle) !default;
$form-section-validation-icon-collapsed-success: $form-section-validation-icon-expanded-success !default;
