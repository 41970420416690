// /* ------------------------------------*\
//     #PLAY-BUTTON
// \*------------------------------------ */

$skoda-play-button-filter:       $DROP-SHADOW-PLAY-BUTTON !default;
$skoda-play-button-scale-active: $BUTTON-SCALE-ACTIVE !default;

.c-play-button {
    filter: $skoda-play-button-filter;

    &:focus-visible {
        @include focus-styles(
            $outline-color: $play-button-border-color,
        );
    }

    &:active {
        scale: $skoda-play-button-scale-active;
    }

}
