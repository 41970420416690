// /* ------------------------------------*\
//     #CUSTOM-RANGE-SLIDER (Settings)
// \*------------------------------------ */

$custom-range-slider-thumb-dot-background-color-disabled:    $COLOR-ON-SURFACE-300 !default;

$custom-range-slider-track-filled-background-color-disabled: $COLOR-ON-SURFACE-200 !default;

$custom-range-slider-pips-value-font-size:                   map-get($FS-1, null) !default;

$custom-range-slider-pips-marker-size:                       2px !default;
$custom-range-slider-pips-marker-background-color:           $COLOR-ON-SURFACE-300 !default;

$custom-range-slider-tooltip-background:                     $TOOLTIP-BACKGROUND !default;
$custom-range-slider-tooltip-color:                          $TOOLTIP-TEXT-COLOR !default;
$custom-range-slider-tooltip-color-disabled:                 null !default;
$custom-range-slider-tooltip-indicator-size:                 $TOOLTIP-ARROW-SIZE !default;
$custom-range-slider-tooltip-font-size:                      $TOOLTIP-TEXT-SIZE !default;
$custom-range-slider-tooltip-font-weight:                    $TOOLTIP-FONT-WEIGHT !default;
$custom-range-slider-tooltip-line-height:                    $TOOLTIP-LINE-HEIGHT !default;
$custom-range-slider-tooltip-spacing:                        $GLOBAL-SPACING-UNIT-XXSMALL !default;
$custom-range-slider-tooltip-padding:                        $TOOLTIP-PADDING-VERTICAL !default; // Individual padding directions set in custom styles
$custom-range-slider-tooltip-border-radius:                  $TOOLTIP-BORDER-RADIUS !default;

$custom-range-slider-thumb-transition-duration:              150ms !default;
