/* ------------------------------------*\
    #HEADER
\*------------------------------------ */

$header-breakpoint:                                       $GLOBAL-PAGE-HEAD-BREAKPOINT !default;
$header-height:                                           $GLOBAL-PAGE-HEAD-HEIGHT !default;
$header-height-at-breakpoint:                             $GLOBAL-PAGE-HEAD-HEIGHT-AT-BREAKPOINT !default;
$header-position:                                         null !default;
$header-static-spacing-bottom:                            0 !default;
$header-static-spacing-bottom-at-breakpoint:              $header-static-spacing-bottom !default;
$header-z-index:                                          z("header") !default;
$header-border-bottom:                                    false !default;
$header-background-color:                                 $COLOR-PALE !default;
$header-box-shadow:                                       false !default;
$header-logo-spacing-horizontal:                          $GLOBAL-SPACING-UNIT-SMALL !default;
$header-has-sub-brand-logo:                               false !default;
$header-sub-brand-logo-spacing-vertical:                  $GLOBAL-SPACING-UNIT-SMALL !default;
$header-sub-brand-logo-spacing-vertical-at-breakpoint:    $GLOBAL-SPACING-UNIT-XSMALL !default;
$header-sub-brand-logo-spacing-left-at-breakpoint:        -($GLOBAL-SPACING-UNIT-SMALL) !default;
$header-sub-brand-logo-spacing-right:                     $GLOBAL-SPACING-UNIT !default;
$header-notification-center-position-first-at-breakpoint: true !default;

/**
 * [1] Use `auto` instead of `0` in case there are other fixed elements above
 *     the header component (e.g. notifications).
 * [2] Only output a background-color if a color is defined. This way, if you
 *     don’t want any background on the header (i.e. transparent), no CSS is
 *     generated.
 * [3] Variant to force a `position: static`. Useful if your default header
 *     is absolute or sticky, but you also need a variant that provides a
 *     static header that sits in the normal flow of the page.
 * [4] Only apply a spacing-bottom if it’s not absolute or fixed positioned.
 * [5] Make sure that all elements of the header are aligned to the outer edges.
 * [6] If the default spacing-bottom for the static header alters on small
 *     screen, assign it for that specific media-query.
 * [7] Position `.c-header__nav` after `.c-header__notification-center` in
 *     small viewports.
 * [8] Make sure that the header always spans all its children.
 * [9] Stretch the log to full height by making the container a flexbox container.
 * [10] Center the sub brand logo vertically in the header.
 * [11] Push the sub brand logo to left.
 * [12] Adjust the spacing between the sub brand logo and the logo in small
 *      viewports to avoid horizontal scrolling.
 * [13] Apply a safe-space to the sub-brand logo on the right-hand side if it is
 *      not the last element and only for larger viewports.
 */

.c-header__wrapper {
    position: relative;
    z-index: $header-z-index;
}

.c-header {
    position: $header-position;
    width: fit-content; /* [8] */ /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    height: $header-height;
    min-width: 100%;
    @if ($header-position == fixed) {
        top: auto; /* [1] */
        left: 0;
    } @else {
        margin-bottom: $header-static-spacing-bottom; /* [4] */
    }
    @if ($header-background-color != transparent) {
        background-color: $header-background-color; /* [2] */
    }
    @if ($header-border-bottom) {
        border-bottom: $header-border-bottom;
    }
    @if ($header-box-shadow) {
        box-shadow: $header-box-shadow;
    }

    @include media-query("<=#{$header-breakpoint}") {
        height: $header-height-at-breakpoint;
    }

    &.c-header--static { /* [3] */
        position: static;
        margin-bottom: $header-static-spacing-bottom; /* [4] */

        @if ($header-static-spacing-bottom-at-breakpoint != $header-static-spacing-bottom) {
            @include media-query("<=#{$header-breakpoint}") {
                margin-bottom: $header-static-spacing-bottom-at-breakpoint; /* [6] */
            }
        }

    }

}

    .c-header__content-wrapper {
        @include page-wrap();
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between; /* [5] */
        height: $header-height;

        @include media-query("<=#{$header-breakpoint}") {
            height: $header-height-at-breakpoint;
        }

    }

    .c-header__logo {
        margin-right: $header-logo-spacing-horizontal;
    }

    @if ($header-has-sub-brand-logo) {
        .c-header__sub-brand-logo {
            display: flex; /* [9] */
            flex-grow: 0;
            height: 100%; /* [10] */
            padding-top: $header-sub-brand-logo-spacing-vertical;
            padding-bottom: $header-sub-brand-logo-spacing-vertical;
            margin-right: auto; /* [11] */

            @include media-query("<=#{$header-breakpoint}") {
                margin-left: $header-sub-brand-logo-spacing-left-at-breakpoint; /* [12] */
                padding-top: $header-sub-brand-logo-spacing-vertical-at-breakpoint;
                padding-bottom: $header-sub-brand-logo-spacing-vertical-at-breakpoint;
            }

            @include media-query(">#{$header-breakpoint}") {
                &:not(:last-child) {
                    margin-right: $header-sub-brand-logo-spacing-right; /* [13] */
                }
            }

            // Sub-Brand Logo in header context
            > * {
                flex-grow: 1;
                height: 100%;
            }
        }
    } @else {
        .c-header__sub-brand-logo {
            display: none;
        }
    }


    .c-header__nav {
        display: flex; /* [6] */

        @include media-query(">#{$header-breakpoint}") {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 100%;
        }

        @if ($header-notification-center-position-first-at-breakpoint) {
            @include media-query("<=#{$header-breakpoint}") {
                order: 1; /* [7] */
            }
        }
    }

    .c-header__notification-center {
        position: relative;
        height: 100%;
    }
