/* ------------------------------------*\
    #INPUT (Scopes)
\*------------------------------------ */

/**
 * [1] Add fallback spacing when selector `.c-form-field--static` is not present.
 * [2] Do not apply fallback to combobox items (inherited via `@extend`).
*/

@if ($form-field-label-floating) {

    .c-form-field:not(.c-form-field--static) {

        .c-radio__label,
        .c-checkbox__label {
            margin-top: $form-field-label-spacing; /* [1] */
        }

        .c-combobox__item__text {
            margin-top: important(0, true); /* [2] */
        }

    }

}
