// /* ------------------------------------*\
//     #PROGRESS
// \*------------------------------------ */

$scope-progress-spacing-in-modal: $GLOBAL-SPACING-UNIT !default;

.c-progress {

    .c-modal & {
        margin-top: $scope-progress-spacing-in-modal;
    }

}
