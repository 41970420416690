/* ------------------------------------*\
    #MAIN-CONTAINER
\*------------------------------------ */

/**
 * [1] Enable the footer to be positioned at the bottom of the viewport
 *     if the content is not as high as the remaining space.
*/

.o-root {
    display: flex; /* [1] */
    flex-direction: column; /* [1] */
    min-height: 100vh; /* [1] */
}
