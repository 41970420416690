/* ------------------------------------*\
    #FLICKITY
\*------------------------------------ */

$skoda-flickity-button-focus-background-color: $COLOR-WHITE !default;
$skoda-flickity-counter-font-color-current:    $COLOR-BASE-TEXT !default;


/**
 * [1] Enforce background color not to change on focus, only on hover.
 * [2] Add brand-specific styles for focus outline.
 */
.flickity-enabled {

    .flickity-prev-next-button,
    .flickity-fullscreen-button {
        @include transition();

        &:focus { /* [1] */
            background-color: important($skoda-flickity-button-focus-background-color, true);
        }

        &:focus-visible { /* [2] */
            @include focus-styles();
        }

    }

    .flickity-page-dots {

        .dot {

            /* stylelint-disable-next-line selector-max-class */
            &.is-selected {

                /* stylelint-disable-next-line selector-max-specificity, selector-max-class, max-nesting-depth */
                &::before {
                    color: important($skoda-flickity-counter-font-color-current, true);
                }

            }

        }

    }

}
