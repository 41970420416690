/* ------------------------------------*\
    #BOX-SIZING
\*------------------------------------ */

/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 * We are overriding modern-normalize's opinionated box-sizing rules.
 */

html {
	box-sizing: border-box;
}

* {

	&,
	&::before,
	&::after {
		box-sizing: inherit;
	}

}
