/* ------------------------------------*\
    #INFO-ICON (Scopes)
\*------------------------------------ */

/**
 * [1] Visually align Info Icon.
 */
.c-info-icon {

    .c-section-heading & {
        vertical-align: baseline; /* [1] */
    }

}
