/* ------------------------------------*\
    #ITEM-TEASER
\*------------------------------------ */

$skoda-item-teaser-border:        $CARD-PATTERN-BORDER !default;
$skoda-item-teaser-border-radius: $CARD-PATTERN-BORDER-RADIUS !default;
/*
 * [1] Align all items at the top.
 * [2] Use “row” layout when component is wider
 *     than `$item-teaser-row-item-break-width`.
 */
.c-item-teaser {

    &.c-item-teaser--block {
        text-align: left;
    }

}

    .c-item-teaser--row {
        align-items: flex-start; /* [1] */

        // stylelint-disable-next-line order/order
        @include container($map: $item-teaser-row-width-queries, $size: "s") { /* [2] */
            @include item-teaser-inline();
        }

    }

    .c-item-teaser--alternative {
        border: $skoda-item-teaser-border;
        border-radius: $skoda-item-teaser-border-radius;
    }
