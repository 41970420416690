// /* ------------------------------------*\
//     #ICON-FONTS
// \*------------------------------------ */

@include font-face(
    $font-name: $ICON-FONT,
    $path:      $ICON-FONT-PATH,
    $revision:  $ICON-FONT-REVISION,
    $display:   block,
);
