/* ------------------------------------*\
    #LIST-NESTED-COUNTER
\*------------------------------------ */

$list-nested-counter-indentation:                $GLOBAL-SPACING-UNIT-LARGE !default;
$list-nested-counter-child-items-spacing-bottom: $GLOBAL-SPACING-UNIT-SMALL !default;
$list-nested-counter-font-weight:                bold !default;
$list-nested-counter-spacing-left:               0 !default;

/**
 * [1] Selector applies to either an ordered list with nested list class (e.g.
 *     `<ol class="c-list-nested-counter">` or to an ordered list with the
 *     nested list classes added to one of it's parents, e.g.
 *     ```
 *     <div class="c-list-nested-counter">
 *         <ol>
 *             ...
 *         </ol>
 *     </div>
 *     ```.
 */
ol.c-list-nested-counter,
.c-list-nested-counter ol { /* [1] */
    margin-left: $list-nested-counter-spacing-left;
    list-style-type: none;
    counter-reset: section;

    ol {
        margin-left: $list-nested-counter-indentation;

        /* stylelint-disable */
        li {
            margin-bottom: $list-nested-counter-child-items-spacing-bottom;
        }
        /* stylelint-enable */

    }

    li::before {
        counter-increment: section;
        content: counters(section, ".") ". ";
        font-weight: $list-nested-counter-font-weight;
    }

}
