/* ------------------------------------*\
    #SECTION-NAV
\*------------------------------------ */

$skoda-section-nav-list-padding-top-at-breakpoint:       $GLOBAL-SPACING-UNIT-SMALL !default;
$skoda-section-nav-list-border-bottom-width:             calc(#{$section-nav-height} + #{$GLOBAL-SPACING-UNIT-XSMALL}) !default;
$skoda-section-nav-link-padding-horizontal:              $GLOBAL-SPACING-UNIT-XSMALL !default;
$skoda-section-nav-link-border-bottom-position:          $GLOBAL-SPACING-UNIT-XSMALL !default;
$skoda-section-nav-link-border-bottom-width-is-active:   3px !default;
$skoda-section-nav-link-border-bottom-color-is-active:   $COLOR-BRAND !default;
$skoda-section-nav-link-focus-outline-width:             $GLOBAL-FOCUS-WIDTH !default;
$skoda-section-nav-link-focus-outline-color:             $COLOR-BASE !default; // @TODO: use global settings
$skoda-section-nav-link-focus-outline-offset-horizontal: $skoda-section-nav-link-focus-outline-width !default;
$skoda-section-nav-link-focus-outline-offset-vertical:   $GLOBAL-SPACING-UNIT-SMALL !default;
$skoda-section-nav-button-margin-bottom-at-breakpoint:   $GLOBAL-SPACING-UNIT-SMALL !default;

/**
 * [1] Mark active link with a left border in smaller viewports.
 * [2] Mark active link with a bottom border in larger viewports.
 * [3] Disable default pseudo element styles, because don’t want to interfere with [4]
 * [4] Add focus rectangle as pseudo element.
 * [5] Use a smaller header shadow, when `.c-section-nav__container` is expanded.
 * [6] Position pseudo-element (circle) on icon.
 *     Magic number via pixel-pushing as there can be no connection/relation be found.
 */

.c-section-nav__container {
    min-height: $section-nav-height;

    .c-section-nav__btn {
        @include media-query("<=#{$section-nav-breakpoint}") {
            margin-bottom: $skoda-section-nav-button-margin-bottom-at-breakpoint;
        }
    }
}

.c-section-nav__list {
    @include media-query("<=#{$section-nav-breakpoint}") {
        padding-top: $skoda-section-nav-list-padding-top-at-breakpoint;
        border-bottom-width: $skoda-section-nav-list-border-bottom-width;
    }
}

.c-section-nav__link {
    padding-right: $skoda-section-nav-link-padding-horizontal;
    padding-left: $skoda-section-nav-link-padding-horizontal;
    color: $COLOR-ON-SURFACE-500;

    &::before {
        all: initial; /* [3] */
    }

    &#{$section-nav-link-active-selector} {

        &::after {
            content: "";
            position: absolute;
            background-color: $skoda-section-nav-link-border-bottom-color-is-active;

            @include media-query("<=#{$section-nav-breakpoint}") { /* [1] */
                top: 0;
                bottom: 0;
                left: calc(var(--page-wrap) * -1);
                width: $skoda-section-nav-link-border-bottom-width-is-active;
            }

            @include media-query(">#{$section-nav-breakpoint}") { /* [2] */
                bottom: $skoda-section-nav-link-border-bottom-position;
                left: 0;
                right: 0;
                height: $skoda-section-nav-link-border-bottom-width-is-active;
            }

        }

    }

    &:focus-visible { /* [4] */
        outline: none;

        &::before {
            content: "";
            position: absolute;
            top: $skoda-section-nav-link-focus-outline-offset-vertical;
            right: $skoda-section-nav-link-focus-outline-offset-horizontal;
            bottom: $skoda-section-nav-link-focus-outline-offset-vertical;
            left: $skoda-section-nav-link-focus-outline-offset-horizontal;
            outline: $skoda-section-nav-link-focus-outline-width solid $skoda-section-nav-link-focus-outline-color;
        }

    }
}

.c-section-nav__btn {
    @include media-query("<=#{$section-nav-breakpoint}") {
        align-self: center;
    }
}

.c-section-nav__header:has(+ .c-section-nav__container.is-visible) {
    box-shadow: $DROP-SHADOW-XS;
}

.c-section-nav__section-label {
    @include button-icon-states(
        $handle-position: false,
        $active-state:    false,
    );

    &::after {
        left: 14px; /* [6] */  /* stylelint-disable-line scale-unlimited/declaration-strict-value */

        @include media-query("<=xs") {
            left: 2px; /* [6] */  /* stylelint-disable-line scale-unlimited/declaration-strict-value */
        }
    }
}

.c-section-nav__scroll-button {
    @include button-icon-states(
        $handle-transition: false,
    );

    &:focus-visible {
        outline: 0;
    }

    &::after {
        @include transition();
    }
}
