/* ------------------------------------*\
    #HIDE
\*------------------------------------ */

/**
 * Hide helper classes to hide elements on certain veiwports.
 */

.u-hide {
    display: none !important;
}



/**
 * Breakpoints specific hide classes.
 */

// Loop over our breakpoints defined in _settings.responsive.scss
@each $breakpoint in $BREAKPOINTS {

    // Get the name of the breakpoint.
    $alias: nth($breakpoint, 1);

    @include media-query("<=#{$alias}") {

        .u-hide\@#{$alias} {
            display: none !important;
        }

    }

    @include media-query(">#{$alias}") {

        .u-hide\@#{$alias}-up {
            display: none !important;
        }

    }

}
