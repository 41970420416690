// /* ------------------------------------*\
//     #PRODUCT-CARD
// \*------------------------------------ */

$skoda-product-card-inactive-layer-color:   $CARD-PATTERN-INACTIVE-LAYER-BACKGROUND-COLOR !default;
$skoda-product-card-inactive-layer-opacity: $CARD-PATTERN-INACTIVE-LAYER-OPACITY !default;

/**
 * [1] Add overlay to visualize blocked inactive/disabled state.
 *     Due to the flexible nature of the component of including
 *     arbitrary elements inside, an overlay is used instead of
 *     colorizing single elements inside which would add complexity and
 *     is error-prone.
 *     Options in default do not satisfy the visual requirements for this brand.
 * [2] Stack above layer, exclude from inactive state.
 */
.c-product-card--inactive {

    /* [1] */
    &::before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        opacity: $skoda-product-card-inactive-layer-opacity;
        border-radius: inherit;
        background-color: $skoda-product-card-inactive-layer-color;
    }

}

    .c-product-card__buttons,
    .c-product-card__status {
        position: relative; /* [2] */
        z-index: 10; /* [2] */
    }
