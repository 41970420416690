// /* ------------------------------------*\
//     #CARD
// \*------------------------------------ */

$skoda-card-inactive-layer-color:        $CARD-PATTERN-INACTIVE-LAYER-BACKGROUND-COLOR !default;
$skoda-card-inactive-layer-opacity:      $CARD-PATTERN-INACTIVE-LAYER-OPACITY !default;
$skoda-card-image-spacing:               ($GLOBAL-SPACING-UNIT - $card-module-spacing) !default;

$skoda-card-icon-background-color-hover: $BUTTON-ICON-INTERACTION-BACKGROUND-COLOR-HOVER-ON-LIGHT !default;

/**
 * [1] Add overlay to visualize blocked inactive/disabled state.
 *     Due to the flexible nature of the component of including
 *     arbitrary elements inside, an overlay is used instead of
 *     colorizing single elements inside which would add complexity and
 *     is error-prone.
 *     Options in default do not satisfy the visual requirements for this brand.
 * [2] Use custom spacing that can not be set via default setting or would
 *     be too complex.
 */
.c-card--inactive {

    /* [1] */
    &::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        opacity: $skoda-card-inactive-layer-opacity;
        border-radius: inherit;
        background-color: $skoda-card-inactive-layer-color;
    }

}

.c-card__image {
    padding-top: $skoda-card-image-spacing; /* [2] */
    padding-bottom: $skoda-card-image-spacing; /* [2] */
}

@include media-query("<=#{$card-expandable-header-breakpoint}") {

    .c-card--responsive-collapse__toggle::before {
        @include transition(
            $transition-property: "transform, background-color",
        );
        border-radius: 50%;

        .c-card__header:hover & {
            background-color: $skoda-card-icon-background-color-hover;
        }

        .c-card__header:focus-within & {
            @include focus-styles();
        }

    }

}
