/* ------------------------------------*\
    #TEXT-COLORS
\*------------------------------------ */

@each $_alias, $_color in $TEXT-COLOR-UTILITIES {

    .u-text-#{$_alias} {
        color: $_color !important;
    }

}
