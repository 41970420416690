/* ------------------------------------*\
    #SITE-NAV
\*------------------------------------ */

$skoda-site-nav-toggle-icon-expanded:                   get-icon(semantic-close) !default;

$skoda-site-nav-panel-box-shadow-at-breakpoint:         $DROP-SHADOW-L !default;
$skoda-site-nav-panel-spacing-top-at-breakpoint:        $GLOBAL-SPACING-UNIT-XSMALL !default;
$skoda-site-nav-panel-spacing-horizontal-at-breakpoint: $GLOBAL-SPACING-UNIT-SMALL !default;
$skoda-site-nav-panel-border-radius-at-breakpoint:      $GLOBAL-BORDER-RADIUS !default;

$skoda-site-nav-link-label-border-bottom:               2px solid transparent !default;
$skoda-site-nav-link-focus-outline-width:               $GLOBAL-FOCUS-WIDTH !default;
$skoda-site-nav-link-focus-outline-offset:              $GLOBAL-FOCUS-OFFSET-LARGE !default;

/**
 * [1] Flip the icon around the horizontal axis so that the chevrons point in the
 *     opposite direction in the expanded state.
 * [2] Add spacing and shadow to panel in small viewports.
 * [3] Set an invisible border to prevent layout shifts when the link gets active.
 */

.c-site-nav__nav {
}

.c-site-nav__panel {

    @include site-nav-breakpoint("below") { /* [2] */
        left: $skoda-site-nav-panel-spacing-horizontal-at-breakpoint;
        width: calc(100% - 2 * #{$skoda-site-nav-panel-spacing-horizontal-at-breakpoint});
        overflow: hidden;
        margin-top: $skoda-site-nav-panel-spacing-top-at-breakpoint;
        border-radius: $skoda-site-nav-panel-border-radius-at-breakpoint;
        box-shadow: $skoda-site-nav-panel-box-shadow-at-breakpoint;
    }

}

.c-site-nav__toggle {
    align-self: center;
}

.c-site-nav__toggle-icon {

    .c-site-nav__toggle#{$site-nav-toggle-state-expanded} & {

        &::before {
            content: $skoda-site-nav-toggle-icon-expanded;
        }

    }

}

.c-site-nav__link-icon {
    @include transition($transition-property: "transform");

    .c-site-nav__link#{$site-nav-link-state-expanded} & {
        transform: scaleY(-1); /* [1] */
    }
}


.c-site-nav__link {

    .c-site-nav__link-label {
        border-bottom: $skoda-site-nav-link-label-border-bottom; /* [3] */
    }

    &#{$site-nav-toggle-state-expanded},
    &.is-active {

        .c-site-nav__link-label {
          border-bottom-color: currentColor;
        }

    }

    &:focus-visible {
        @include focus-styles(
            $outline-width:  $skoda-site-nav-link-focus-outline-width,
            $outline-offset: $skoda-site-nav-link-focus-outline-offset,
        );
    }

    // Override default.site-nav
    &:focus {
        outline-width: $skoda-site-nav-link-focus-outline-width !important; /* stylelint-disable-line itcss/no-at-important */
    }

}
