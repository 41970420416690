/* ------------------------------------*\
    #FLATPICKR-OVERRIDES
\*------------------------------------ */

@use "sass:math" as *;

$flatpickr-day-count:                            7 !default;
$flatpickr-square-size:                          40px !default;
$flatpickr-square-margin-horizontal:             0 !default;
$flatpickr-square-margin-vertical:               0 !default;
// Sum up the widths of all day items to get the inner container width.
// This is needed for the correct row break of the day items later:
$flatpickr-inner-container-width:                $flatpickr-day-count * ($flatpickr-square-size + (2 * $flatpickr-square-margin-horizontal)) !default;
$flatpickr-horizontal-breakpoint:                "<=xs" !default;
$flatpickr-width-below-breakpoint:               90vw !default;
$flatpickr-square-size-below-breakpoint:         div(100%, $flatpickr-day-count) !default;
$flatpickr-border-color:                         $COLOR-BRAND !default;
$flatpickr-border-width:                         1px !default;
$flatpickr-border:                               $flatpickr-border-width solid $flatpickr-border-color !default;
$flatpickr-border-radius:                        0 !default;
$flatpickr-box-shadow:                           false !default;
$flatpickr-z-index:                              z("datepicker") !default;
$flatpickr-padding-vertical:                     $GLOBAL-SPACING-UNIT-SMALL !default;
$flatpickr-padding-horizontal:                   $GLOBAL-SPACING-UNIT-SMALL !default;
// The component width is the inner container width plus the padding and border width.
// Optionally an extra width can be added as CSS custom property (e.g. for the week numbers) [22]:
$flatpickr-width:                                calc(var(--flatpickr-extra-width) + #{$flatpickr-inner-container-width + (2 * ($flatpickr-padding-horizontal + $flatpickr-border-width))}) !default;
$flatpickr-background-color:                     $COLOR-WHITE !default;
$flatpickr-arrow-indicator-height:               0 !default;
$flatpickr-vertical-offset-to-bottom:            -(2 * $flatpickr-padding-vertical + 2 * $flatpickr-border-width + $flatpickr-arrow-indicator-height) !default;
$flatpickr-has-arrow-indicator:                  false !default;
$flatpickr-arrow-indicator-border-width:         9px !default;
$flatpickr-arrow-indicator-border-transparent:   $flatpickr-arrow-indicator-border-width solid transparent !default;
$flatpickr-arrow-indicator-horizontal-offset:    60px !default;

$flatpickr-month-calendar-spacing:               null !default;

$flatpickr-input-color:                          $COLOR-BRAND !default;
$flatpickr-input-wrapper-background-color-hover: $COLOR-PALE !default;
$flatpickr-input-icon-background-color-hover:    $COLOR-SUBTLE !default;
$flatpickr-input-icon-up:                        get-icon(semantic-collapse) !default;
$flatpickr-input-icon-down:                      get-icon(semantic-expand) !default;
$flatpickr-input-icon-color:                     $COLOR-BRAND !default;
$flatpickr-input-icon-color-hover:               $COLOR-BRAND !default;
$flatpickr-input-icon-color-disabled:            $GLOBAL-INPUT-ADDON-FONT-COLOR-DISABLED !default;

$flatpickr-year-icon-wrapper-width:              15px !default;
$flatpickr-year-icon-size:                       icon-size(xsmall) !default;
$flatpickr-year-input-width:                     3.5em !default;
$flatpickr-year-input-border:                    0 !default;
$flatpickr-year-input-font-weight:               $GLOBAL-FONT-WEIGHT-BOLD !default;
$flatpickr-year-input-background-color:          transparent !default;

$flatpickr-month-color:                          $flatpickr-input-color !default;
$flatpickr-month-font-weight:                    $GLOBAL-FONT-WEIGHT-BOLD !default;
$flatpickr-month-font-size:                      map-get($FS2, null) !default;
$flatpickr-month-icon-color:                     $COLOR-BRAND !default;
$flatpickr-month-icon-color-hover:               $COLOR-INACTIVE !default;
$flatpickr-month-icon-size:                      icon-size(small) !default;

$flatpickr-weekdays-background-color:            transparent !default;
$flatpickr-weekday-width:                        $flatpickr-square-size !default;
$flatpickr-weekday-height:                       $flatpickr-square-size !default;
$flatpickr-weekday-color:                        $COLOR-BASE-TEXT !default;
$flatpickr-weekday-font-size:                    map-get($FS-1, null) !default;
$flatpickr-weekday-font-weight:                  $GLOBAL-FONT-WEIGHT-BOLD !default;
$flatpickr-weekday-line-height:                  $flatpickr-square-size !default;

$flatpickr-day-width:                            $flatpickr-square-size !default;
$flatpickr-day-height:                           $flatpickr-square-size !default;
$flatpickr-day-border-radius:                    0 !default;
$flatpickr-day-color:                            $COLOR-BRAND !default;
$flatpickr-day-color-disabled:                   $COLOR-BASE-TEXT !default;
$flatpickr-day-color-hover:                      $flatpickr-day-color !default;
$flatpickr-day-color-selected:                   $COLOR-WHITE !default;
$flatpickr-day-color-selected-hover:             $COLOR-WHITE !default;
$flatpickr-day-font-size:                        map-get($FS-1, null) !default;
$flatpickr-day-font-weight:                      $GLOBAL-FONT-WEIGHT-REGULAR !default;
$flatpickr-day-font-weight-selected:             $GLOBAL-FONT-WEIGHT-REGULAR !default;
$flatpickr-day-font-weight-hover:                $GLOBAL-FONT-WEIGHT-REGULAR !default;
$flatpickr-day-background-color-hover:           $COLOR-PALE !default;
$flatpickr-day-background-color-selected:        $COLOR-BRAND !default;
$flatpickr-day-background-color-selected-hover:  $COLOR-BRAND !default;
$flatpickr-day-today-border-width:               1px !default;
$flatpickr-day-today-background-color:           null !default;
$flatpickr-day-today-border-color:               $COLOR-BRAND !default;
$flatpickr-day-today-border:                     $flatpickr-day-today-border-width solid $flatpickr-day-today-border-color !default;
$flatpickr-day-today-font-weight:                $GLOBAL-FONT-WEIGHT-REGULAR !default;
$flatpickr-day-today-color:                      $COLOR-HIGHLIGHT !default;
$flatpickr-day-range-color:                      null !default;
$flatpickr-day-range-color-start:                $flatpickr-day-color-selected !default;
$flatpickr-day-range-color-end:                  $flatpickr-day-range-color-start !default;
$flatpickr-day-range-background-color:           $COLOR-PALE !default;
$flatpickr-day-range-background-color-start:     $flatpickr-day-background-color-selected !default;
$flatpickr-day-range-background-color-end:       $flatpickr-day-range-background-color-start !default;
$flatpickr-day-range-hover-color:                null !default;
$flatpickr-day-range-hover-background-color:     $COLOR-INACTIVE !default;
$flatpickr-day-range-font-weight:                null !default;
$flatpickr-day-other-month-color:                $COLOR-INACTIVE !default;
$flatpickr-day-disabled-strikethrough-width:     ($flatpickr-square-size * 0.5) !default;
$flatpickr-day-disabled-strikethrough-height:    1px !default;
$flatpickr-day-disabled-strikethrough-color:     currentColor !default;
$flatpickr-day-disabled-strikethrough-rotation:  -30deg !default;

$flatpickr-week-numbers-font-size:               $flatpickr-weekday-font-size !default;
$flatpickr-week-numbers-font-weight:             $flatpickr-weekday-font-weight !default;
$flatpickr-week-numbers-color:                   $flatpickr-weekday-color !default;
$flatpickr-week-numbers-border-width:            1px !default;
$flatpickr-week-numbers-border-color:            $COLOR-BORDER !default;

$flatpickr-time-height:                          $GLOBAL-INPUT-HEIGHT !default;
$flatpickr-time-font-size:                       map-get($FS-1, null) !default;
$flatpickr-time-input-font-weight:               null !default;
$flatpickr-time-color:                           $flatpickr-input-color !default;
$flatpickr-time-icon-size:                       icon-size(xsmall) !default;
$flatpickr-time-icon-spacing-right:              0 !default;
$flatpickr-time-am-pm-min-width:                 90px !default;
$flatpickr-time-icon-color:                      $flatpickr-month-icon-color !default;

$flatpickr-has-transition:                       false !default;

// Mixin to avoid duplicated code as part of creating arrows for the year switcher.
@mixin flatpickr-arrow($border-side) {
    $pseudo-elements: (before: $flatpickr-border-color, after: $flatpickr-background-color);

    @each $pseudo-element, $color in $pseudo-elements {
        &::#{$pseudo-element} {
            border-left: $flatpickr-arrow-indicator-border-transparent;
            border-right: $flatpickr-arrow-indicator-border-transparent;
            border-#{$border-side}: $flatpickr-arrow-indicator-border-width solid $color;
        }
    }
}

/**
 * [1]  Set 'important' to override inline CSS styles set by flatpickr and prevent
 *      rendering issues (especially in Firefox).
 * [2]  Prevent outline if user clicks on the space between root element and inner
 *      container.
 * [3]  Remove border which is displayed on focus.
 * [4]  Optimize the size and position of the calendar for small mobile devices
 * [5]  Add a rectangle that points from the open calendar to the corresponding
 *      input element.
 * [6]  Show cursor pointer on element that is natively not interactive.
 * [7]  Prevent calendar on mobile devices from being wider than on the desktop.
 * [8]  Remove native input spin box.
 * [9]  Don't show outline when clicking on arrows.
 * [10] Correct wrong positioning of `.flatpickr-time` caused by negative margin.
 * [11] Use `line-height` to set the `height` of the timepicker and to ensure
 *      the `inputs` fill the entire space.
 * [12] Only apply the font colors to days in different months if the day is in
 *      "default state". If hover/selected is active the color might collide
 *      with the font color defined here, making the day unreadable.
 * [13] Hide closed datepicker to prevent taking up space.
 * [14] Manages the position of the calendar when opening to top.
 * [15] Make transitions optional.
 * [16] Allows centering the controls for all viewports.
 * [17] Center strike-through above number.
 * [18] Improve alignment for `months` row.
 * [19] Hide controls when disabled and removing them from the accessibility tree.
 * [20] Suppress the cached state of previous interactions.
 * [21] Add an extra week numbers column by adding extra width to the container.
 * [22] Add extra widths to the inner container to account for the extra week
 *      numbers column.
 * [23] The week number should not be interacted with.
 * [24] A vertical border is added as pseudo element to the week number column
 *      to separate it from the rest of the calendar without changing the
 *      element’s width.
 * [25] The border should be only as high as characters in the top row.
 */

/**
 * GENERAL FLATPICKR STYLES
 */

.flatpickr-calendar {
    --flatpickr-extra-width: 0px; /* [21] */ /* stylelint-disable-line length-zero-no-unit */
    position: absolute;
    z-index: $flatpickr-z-index;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    width: $flatpickr-width !important; /* [1] */ /* stylelint-disable-line itcss/no-at-important */
    max-width: $flatpickr-width;  /* [7] */
    padding: $flatpickr-padding-vertical $flatpickr-padding-horizontal;
    border: if($flatpickr-border, $flatpickr-border, null);
    text-align: center;
    background-color: $flatpickr-background-color;
    visibility: hidden;
    outline: 0; /* [2] */
    opacity: 0;
    user-select: none;
    @if ($flatpickr-has-transition) {
        @include transition();
    }
    @if ($flatpickr-border-radius != 0) {
        border-radius: $flatpickr-border-radius;
    }
    @if ($flatpickr-box-shadow) {
        box-shadow: $flatpickr-box-shadow;
    }

    @include media-query($flatpickr-horizontal-breakpoint) { /* [4] */
        left: important(50%, true); /* [1] */
        width: important($flatpickr-width-below-breakpoint, true); /* [1] */
        transform: translateX(-50%);
    }

    &:not(.open) {
        display: none; /* [13] */
    }

    &.open {
        visibility: visible;
        opacity: 1;
    }

    &.hasWeeks {
        --flatpickr-extra-width: #{$flatpickr-square-size + (2 * $flatpickr-square-margin-horizontal)}; /* [22] */
    }

    @if ($flatpickr-has-arrow-indicator) { /* [5] */

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: $flatpickr-arrow-indicator-horizontal-offset;
            pointer-events: none;
        }

        &::after {
          margin: -($flatpickr-border-width) 0;
        }

        &.rightMost,
        &.arrowRight {

            &::before,
            &::after {
                right: $flatpickr-arrow-indicator-horizontal-offset;
                left: auto;
            }

        }

        &.arrowTop {
            @include flatpickr-arrow("bottom");
            margin-top: $flatpickr-arrow-indicator-height;

            &::before, &::after {
                bottom: 100%;
            }

        }

        &.arrowBottom {
            @include flatpickr-arrow("top");

            &::before,
            &::after {
                top: 100%;
            }

        }

        &.arrowCenter {
            right: 50%;
            left: 50%;
        }

    }

    &.arrowBottom {
        margin-top: $flatpickr-vertical-offset-to-bottom; /* [14] */
    }

}

.flatpickr-innerContainer {
    width: calc(#{$flatpickr-inner-container-width} + var(--flatpickr-extra-width));

    @include media-query($flatpickr-horizontal-breakpoint) { /* [4] */
        width: 100%; /* [1] */
    }

}

.flatpickr-input {

    &,
    &[readonly] {
        cursor: pointer;
    }

    &[disabled] {

        ~ .c-input__addon .c-icon {
            color: $flatpickr-input-icon-color-disabled;
        }

    }

}



/**
 * FLATPICKR INPUT WRAPPER STYLES
 */

.numInputWrapper {
    position: relative;
    @if ($flatpickr-has-transition) {
        @include transition();
    }

    &:hover {
        background-color: if($flatpickr-input-wrapper-background-color-hover, $flatpickr-input-wrapper-background-color-hover, null);

        .arrowUp,
        .arrowDown {
            opacity: 1;
            background-color: if($flatpickr-input-wrapper-background-color-hover, $flatpickr-input-wrapper-background-color-hover, null);
        }

    }

    .flatpickr-months & {
        display: inline-block;
        width: $flatpickr-year-input-width;

        .arrowUp,
        .arrowDown {
            @include font-size(
                $font-size:   $flatpickr-year-icon-size,
                $line-height: 1
            );
            right: -($flatpickr-year-icon-wrapper-width);
            width: $flatpickr-year-icon-wrapper-width;
        }

    }

    .flatpickr-time & {
        display: flex;
        flex-grow: 1;

        .arrowUp,
        .arrowDown {
            @include font-size(
                $font-size:   $flatpickr-time-icon-size,
                $line-height: ($flatpickr-time-height * 0.5)
            );
            right: $flatpickr-time-icon-spacing-right;
            width: ($flatpickr-time-height * 0.5);
            color: $flatpickr-time-icon-color;
        }

    }

    .arrowUp,
    .arrowDown {
        @include transition();
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50%;
        overflow: hidden;
        color: $flatpickr-input-icon-color;
        opacity: 0;

        &:hover {
            background-color: if($flatpickr-input-icon-background-color-hover, $flatpickr-input-icon-background-color-hover, null);
            color: if($flatpickr-input-icon-color-hover, $flatpickr-input-icon-color-hover, null);
            cursor: pointer; /* [6] */
        }

        &::before {
            @include icon-font();
        }

    }

    .arrowUp {
        top: 0;

        &::before {
            content: $flatpickr-input-icon-up;
        }

    }

    .arrowDown {
        bottom: 0;

        &::before {
            content: $flatpickr-input-icon-down;
        }

    }

    input[type="number"] {
        appearance: textfield; /* [8] */
    }

    input::-ms-clear {
        display: none; /* [8] */
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        margin: 0; /* [8] */
        appearance: none;  /* [8] */
    }

}

.numInput {
    width: 100%;
    margin: 0;
    padding: 0;
    border: $flatpickr-year-input-border;
    font-weight: $flatpickr-year-input-font-weight;
    text-align: center;
    background-color: $flatpickr-year-input-background-color;
    color: $flatpickr-input-color;

    &:focus {
        outline: 0;
    }

    .flatpickr-time & {
        font-weight: $flatpickr-time-input-font-weight;
        color: $flatpickr-time-color;
    }

}



/**
 * FLATPICKR MONTH STYLES
 */

.flatpickr-months {
    display: flex;
    align-items: center; /* [16] */
    margin-bottom: $flatpickr-month-calendar-spacing;
}

.flatpickr-month {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    line-height: 1;
    text-align: center;
    user-select: none;
}

.flatpickr-current-month {
    @include font-size(
        $font-size: $flatpickr-month-font-size
    );
    font-weight: $flatpickr-month-font-weight;
    color: $flatpickr-month-color;
}

.flatpickr-prev-month,
.flatpickr-next-month {
    display: flex; /* [18] */
    justify-content: center;
    width: $flatpickr-square-size;
    line-height: 1;
    cursor: pointer;

    &:hover {

        .c-icon {
            color: if($flatpickr-month-icon-color-hover, $flatpickr-month-icon-color-hover, null);
        }

    }

    &.flatpickr-disabled {
        opacity: 0; /* [19] */
        visibility: hidden; /* [19] */
        pointer-events: none; /* [20] */
    }

    .c-icon {
        @include font-size(
            $font-size:   $flatpickr-month-icon-size,
            $line-height: 1
        );
        color: $flatpickr-month-icon-color;
    }

}



/**
 * FLATPICKR WEEKDAY STYLES
 */

.flatpickr-weekdays {
    overflow: hidden;
    background-color: $flatpickr-weekdays-background-color;
}

.flatpickr-weekdaycontainer {
    display: flex;
    justify-content: center;
}

.flatpickr-weekday {
    @include font-size(
        $font-size: $flatpickr-weekday-font-size,
        $line-height: $flatpickr-weekday-line-height,
    );
    width: $flatpickr-weekday-width;
    height: $flatpickr-weekday-height;
    margin: $flatpickr-square-margin-vertical $flatpickr-square-margin-horizontal;
    font-weight: $flatpickr-weekday-font-weight;
    color: $flatpickr-weekday-color;
    cursor: default;

    @include media-query($flatpickr-horizontal-breakpoint) { /* [4] */

        @if ($flatpickr-square-margin-horizontal != 0) {
            width: calc(#{$flatpickr-square-size-below-breakpoint} - (2 * #{$flatpickr-square-margin-horizontal}));
            height: calc(#{$flatpickr-square-size-below-breakpoint} - (2 * #{$flatpickr-square-margin-horizontal}));
        } @else {
            width: $flatpickr-square-size-below-breakpoint;
            height: $flatpickr-square-size-below-breakpoint;
        }

    }

}



/**
 * FLATPICKR DAY STYLES
 */
.flatpickr-days {
    width: 100% !important; /* [1] */ /* stylelint-disable-line itcss/no-at-important */
}

.dayContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
 }

.flatpickr-day {
    @include font-size(
        $font-size: $flatpickr-day-font-size,
        $line-height: $flatpickr-weekday-height,
    );
    position: relative;
    width: $flatpickr-day-width;
    height: $flatpickr-day-height;
    margin: $flatpickr-square-margin-vertical $flatpickr-square-margin-horizontal;
    border-radius: $flatpickr-day-border-radius;
    font-weight: $flatpickr-day-font-weight;
    color: $flatpickr-day-color;
    cursor: pointer;
    @if ($flatpickr-has-transition) {
        @include transition();
    }

    @include media-query($flatpickr-horizontal-breakpoint) { /* [4] */

        @if ($flatpickr-square-margin-horizontal != 0) {
            width: calc(#{$flatpickr-square-size-below-breakpoint} - (2 * #{$flatpickr-square-margin-horizontal}));
            height: calc(#{$flatpickr-square-size-below-breakpoint} - (2 * #{$flatpickr-square-margin-horizontal}));
        } @else {
            width: $flatpickr-square-size-below-breakpoint;
            height: $flatpickr-square-size-below-breakpoint;
        }

    }

    &:hover {
        font-weight: $flatpickr-day-font-weight-hover;
        background-color: $flatpickr-day-background-color-hover;
        color: $flatpickr-day-color-hover;
    }

    &.inRange {
        font-weight: $flatpickr-day-range-font-weight;
        background-color: $flatpickr-day-range-background-color;
        color: $flatpickr-day-range-color;

        &:hover {
            background-color: $flatpickr-day-range-hover-background-color;
            color: $flatpickr-day-range-hover-color;
        }

    }

    &.startRange {
        background-color: $flatpickr-day-range-background-color-start;
        color: $flatpickr-day-range-color-start;
    }

    &.endRange {
        background-color: $flatpickr-day-range-background-color-end;
        color: $flatpickr-day-range-color-end;
    }

    &.flatpickr-disabled {
        color: $flatpickr-day-color-disabled;
        pointer-events: none;

        // strike-through
        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 50%; /* [17] */
            left: 50%; /* [17] */
            display: block;
            width: $flatpickr-day-disabled-strikethrough-width;
            height: $flatpickr-day-disabled-strikethrough-height;
            transform: rotate($flatpickr-day-disabled-strikethrough-rotation) translate(-50%, -50%); /* [17] */
            transform-origin: 0; /* [17] */
            background-color: $flatpickr-day-disabled-strikethrough-color;
        }
    }

    /* stylelint-disable selector-max-specificity */
    &:not(.inRange):not(.startRange):not(.endRange):not(.selected):not(:hover):not(:focus) { /* [12] */

        &.prevMonthDay,
        &.nextMonthDay {
            color: $flatpickr-day-other-month-color;
        }

    }
    /* stylelint-enable */

    &.today:not(.flatpickr-disabled) {
        font-weight: $flatpickr-day-today-font-weight;
        @if ($flatpickr-day-today-border) {
            border: $flatpickr-day-today-border;
        }
        @if ($flatpickr-day-today-color) {
            color: $flatpickr-day-today-color;
        }
        background-color: $flatpickr-day-today-background-color;
    }

    &.selected:not(.flatpickr-disabled) {
        font-weight: $flatpickr-day-font-weight-selected;
        background-color: $flatpickr-day-background-color-selected;
        color: $flatpickr-day-color-selected;
        user-select: none;
        outline: 0; /* [3] */

        &:hover {
            background-color: $flatpickr-day-background-color-selected-hover;
            color: $flatpickr-day-color-selected-hover;
        }

    }

}



/**
 * FLATPICKR WEEK NUMBERS
 */
.flatpickr-weekwrapper {
    position: relative;
    float: left;
    pointer-events: none; /* [23] */

    &::before { /* [24] */
        content: "";
        position: absolute;
        top: calc((#{$flatpickr-weekday-height} - #{$flatpickr-day-font-size}) / 2); /* [25] */
        right: 0;
        bottom: 0;
        border-right: $flatpickr-week-numbers-border-width solid $flatpickr-week-numbers-border-color;
    }


    .flatpickr-weekday {
        display: block;
        width: $flatpickr-square-size;
    }

    .flatpickr-day {
        &, &:hover {
            @include font-size(
                $font-size: $flatpickr-week-numbers-font-size,
                $line-height: false,
            );
            display: block;
            font-weight: $flatpickr-week-numbers-font-weight;
            color: $flatpickr-week-numbers-color;
        }

    }

    .flatpickr-weeks {
        display: flex;
        flex-direction: column;
    }

}



/**
 * FLATPICKR TIME STYLES
 */

.flatpickr-time {
    display: flex;
    margin: #{-($flatpickr-padding-vertical)} #{-($flatpickr-padding-horizontal)}; /* [10] */
    color: $flatpickr-time-color;

    &:focus {
        outline: 0; /* [9] */
    }

}

.flatpickr-hour,
.flatpickr-minute,
.flatpickr-second {
    @include font-size(
        $font-size:   $flatpickr-time-font-size,
        $line-height: $flatpickr-time-height /* [11] */
    );
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%; /* [10] */
    border: 0;
    text-align: center;
    background-color: transparent;

    &:focus,
    &:hover {
        outline: 0;
    }

}

.flatpickr-time-separator {
    line-height: $flatpickr-time-height;
    pointer-events: none;
    user-select: none;
}

.flatpickr-am-pm {
    @include font-size(
        $font-size:   $flatpickr-time-font-size,
        $line-height: $flatpickr-time-height /* [11] */
    );
    display: block;
    min-width: $flatpickr-time-am-pm-min-width;
    cursor: pointer;

    &:hover {
        background-color: if($flatpickr-input-wrapper-background-color-hover, $flatpickr-input-wrapper-background-color-hover, null);
    }

}
