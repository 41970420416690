// /* ------------------------------------*\
//     #FOCUS
// \*------------------------------------ */

// Global focus styles.
$GLOBAL-FOCUS-COLOR:        $COLOR-FOCUS !default;
$GLOBAL-FOCUS-WIDTH:        2px !default;
$GLOBAL-FOCUS-STYLE:        solid !default;
$GLOBAL-FOCUS-OFFSET:       1px !default;
$GLOBAL-FOCUS-OFFSET-LARGE: 8px !default;
