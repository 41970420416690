// /* ------------------------------------*\
//     #CAROUSEL (Settings)
// \*------------------------------------ */

$carousel-button-width:                  map-get($ICON-SQUARE-FULL-SIZES, medium) !default;
$carousel-button-background-color:       $COLOR-WHITE !default;
$carousel-button-background-color-hover: $BUTTON-ICON-INTERACTION-BACKGROUND-COLOR-HOVER-ON-LIGHT !default;
$carousel-button-icon-size:              icon-size(small) !default;
$carousel-button-icon-color:             $COLOR-BASE-TEXT !default;
$carousel-button-next-icon:              get-icon(arrow-right) !default;
$carousel-button-prev-icon:              get-icon(arrow-left) !default;
$carousel-button-offset:                 $GLOBAL-SPACING-UNIT-SMALL !default;

$carousel-page-dot-position-inside:      true !default;
