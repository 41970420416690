/* ------------------------------------*\
    #FOOTNOTES (Scopes)
\*------------------------------------ */

$skoda-footnotes-text-color-in-footer: $footer-footnotes-text-color !default;

/**
 * [1] Avoid Inline Link Element styles.
 */
.c-footnotes {

    .c-footer & {
        color: $skoda-footnotes-text-color-in-footer;
    }

    a {
        border-color: inherit; /* [1] */
    }

}
