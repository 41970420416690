// /* ------------------------------------*\
//     #HEADINGS
// \*------------------------------------ */

/// Mixin to add heading styles without adding the font-size or line-height styles
/// across several heading hierarchies, e.g. h1-h6.
/// @access public
///
/// @param {String} $color [null] - The heading color.
/// @param {String} $font-family [null] - The heading font-family.
/// @param {String} $font-weight [null] - The heading font-weight.
/// @param {String} $letter-spacing [null] - The heading letter-spacing.
/// @param {String} $margin-bottom [null] - The heading bottom spacing.
/// @param {Map} $heading-themes [null]  - A map with theme styles.
/// @param {Boolean} $important [false] - Whether the styles should be forced, e.g. for utilities.
///
/// @example
///     h1, h2, h3 {
///         @include heading-base-styles(
///             $color:          $GLOBAL-HEADING-COLOR,
///             $font-family:    $GLOBAL-HEADING-FONT-FAMILY,
///             $font-weight:    $GLOBAL-HEADING-FONT-WEIGHT,
///             $letter-spacing: $GLOBAL-HEADING-LETTER-SPACING,
///             $margin-bottom:  $GLOBAL-HEADING-MARGIN-BOTTOM,
///             $heading-themes: $GLOBAL-HEADING-THEMES
///         );
///     }
///
@mixin heading-base-styles(
    $color: null,
    $font-family: null,
    $font-weight: null,
    $letter-spacing: null,
    $heading-themes: null,
    $margin-bottom: null,
    $important: false
) {
    margin-bottom: important($margin-bottom, $important);
    font-family: important($font-family, $important);
    font-weight: important($font-weight, $important);
    letter-spacing: important($letter-spacing, $important);
    color: important($color, $important);

    @if ($heading-themes) {
        @include vary($loop: $heading-themes) {
            color: important(vary-get("text-color"), $important);
        }
    }

}

/// Mixin to render valid heading styles.
/// @access public
///
/// @param {String} $font-size [null] - The heading responsive font-size.
/// @param {String} $color [null] - The heading line-height.
/// @param {String} $color [null] - The heading color.
/// @param {String} $font-family [null] - The heading font-family.
/// @param {String} $font-weight [null] - The heading font-weight.
/// @param {String} $letter-spacing [null] - The heading letter-spacing.
/// @param {Map} $heading-themes [null]  - A map with theme styles.
/// @param {Boolean} $important [false] - Whether the styles should be forced, e.g. for utilities.
///
/// @example
///     h1 {
///         @include heading(
///             $font-size:      $GLOBAL-HEADING-H1-FONT-SIZE,
///             $line-height:    $GLOBAL-HEADING-H1-LINE-HEIGHT,
///             $color:          $GLOBAL-HEADING-COLOR,
///             $font-family:    $GLOBAL-HEADING-FONT-FAMILY,
///             $font-weight:    $GLOBAL-HEADING-FONT-WEIGHT,
///             $margin-bottom:  $GLOBAL-HEADING-MARGIN-BOTTOM,
///             $letter-spacing: $GLOBAL-HEADING-LETTER-SPACING,
///             $heading-themes: $GLOBAL-HEADING-THEMES
///         );
///     }
///
@mixin heading(
    $font-size,
    $line-height,
    $color: null,
    $font-family: null,
    $font-weight: null,
    $letter-spacing: null,
    $margin-bottom: null,
    $heading-themes: null,
    $important: false
) {

    // Guard against empty line-height and font-sizes.
    @if (($font-size == null) or ($line-height == null)) {
        @error ("heading → $font-size and $line-height must have a value.");
    }

    @include responsive-font-size(
        $font-size:   $font-size,
        $line-height: $line-height,
        $important:   true
    );
    @include heading-base-styles(
        $color:          $color,
        $font-family:    $font-family,
        $font-weight:    $font-weight,
        $letter-spacing: $letter-spacing,
        $margin-bottom:  $margin-bottom,
        $heading-themes: $heading-themes,
        $important:      $important
    );

}
