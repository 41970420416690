// /* ------------------------------------*\
//     #RANGE-SLIDER
// \*------------------------------------ */

$RANGE-SLIDER-TRACK-HEIGHT:                           4px !default;
$RANGE-SLIDER-TRACK-BACKGROUND-COLOR:                 $COLOR-ON-SURFACE-200 !default;
$RANGE-SLIDER-TRACK-BACKGROUND-COLOR-LIGHT:           $__transparency-quarternary__ !default;
$RANGE-SLIDER-TRACK-BACKGROUND-COLOR-DISABLED:        $COLOR-INACTIVE-SECONDARY !default;
$RANGE-SLIDER-TRACK-BORDER-RADIUS:                    $GLOBAL-BORDER-RADIUS-SMALL !default;

$RANGE-SLIDER-TRACK-FILLED-BACKGROUND-COLOR:          $COLOR-BRAND-SECONDARY !default;
$RANGE-SLIDER-TRACK-FILLED-BACKGROUND-COLOR-LIGHT:    $COLOR-WHITE !default;
$RANGE-SLIDER-TRACK-FILLED-BACKGROUND-COLOR-DISABLED: $COLOR-ON-SURFACE-200 !default;

$RANGE-SLIDER-THUMB-SIZE:                             16px !default;
$RANGE-SLIDER-THUMB-SIZE-HOVER:                       18px !default;
$RANGE-SLIDER-THUMB-BACKGROUND-COLOR:                 $COLOR-BRAND !default;
$RANGE-SLIDER-THUMB-BACKGROUND-LIGHT:                 $COLOR-WHITE !default;
$RANGE-SLIDER-THUMB-BACKGROUND-COLOR-DISABLED:        $COLOR-ON-SURFACE-300 !default;
$RANGE-SLIDER-THUMB-HAS-BORDER:                       false !default;
$RANGE-SLIDER-THUMB-HAS-DOT:                          true !default;
