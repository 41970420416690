/* ------------------------------------*\
    #FAV-BUTTON
\*------------------------------------ */

.c-fav-button {

    &:focus-visible {
        @include focus-styles(
            $outline-offset: $GLOBAL-FOCUS-OFFSET-LARGE,
        );
    }

}
