/* ------------------------------------*\
    #SPINNER
\*------------------------------------ */

$skoda-spinner-content-backdrop-filter: $BACKDROP-FILTER !default;

.c-spinner {

    // Backdrop
    &::before {
        backdrop-filter: $skoda-spinner-content-backdrop-filter;
    }

}
