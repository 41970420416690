/* ------------------------------------*\
    #SPACING SETTINGS
\*------------------------------------ */

// @sass-export-section="spacingProperties"
$spacing-properties: (
    "m": "margin",
    "p": "padding",
) !default;
// @end-sass-export-section

// @sass-export-section="spacingDirections"
$spacing-directions: (
    null: null,
    "t": "-top",
    "r": "-right",
    "b": "-bottom",
    "l": "-left",
    "h": "-left" "-right",
    "v": "-top" "-bottom",
) !default;
// @end-sass-export-section

// @sass-export-section="spacingSizes"
$spacing-sizes: (
    "-xxsmall": $GLOBAL-SPACING-UNIT-XXSMALL,
    "-xsmall": $GLOBAL-SPACING-UNIT-XSMALL,
    "-small": $GLOBAL-SPACING-UNIT-SMALL,
    null: $GLOBAL-SPACING-UNIT,
    "-large": $GLOBAL-SPACING-UNIT-LARGE,
    "-xlarge": $GLOBAL-SPACING-UNIT-XLARGE,
    "-none": 0,
) !default;
// @end-sass-export-section
