// /* ------------------------------------*\
//     #PRICE-BOX
// \*------------------------------------ */

$price-box-width-queries: (
    small:  0,
    medium: 260,
) !default;

$price-box-border:                             null !default;
$price-box-border-integrated:                  null !default;
$price-box-shadow:                             null !default;
$price-box-shadow-integrated:                  none !default;

$price-box-header-padding-vertical:            $GLOBAL-SPACING-UNIT-XSMALL !default;
$price-box-header-padding-horizontal:          $GLOBAL-SPACING-UNIT !default;
$price-box-header-background-color:            $COLOR-BRAND !default;
$price-box-header-background-color-integrated: transparent !default;
$price-box-header-color:                       $COLOR-WHITE !default;
$price-box-header-color-integrated:            $COLOR-BASE-TEXT !default;
$price-box-header-border:                      null !default;
$price-box-header-border-integrated:           1px solid $price-box-header-color-integrated !default;
$price-box-header-font-family:                 null !default;
$price-box-header-font-size:                   map-get($FS0, null) !default;
$price-box-header-font-weight:                 $GLOBAL-FONT-WEIGHT-BOLD !default;
$price-box-header-text-transform:              null !default;

$price-box-body-padding-vertical:              $GLOBAL-SPACING-UNIT !default;
$price-box-body-padding-horizontal:            $price-box-body-padding-vertical !default;
$price-box-body-padding:                       $price-box-body-padding-vertical $price-box-body-padding-horizontal !default;
$price-box-body-padding-small:                 $price-box-body-padding-vertical $GLOBAL-SPACING-UNIT-SMALL !default;
$price-box-body-background-color:              $COLOR-PALE !default;
$price-box-body-background-color-integrated:   transparent !default;
$price-box-body-color:                         $COLOR-BASE-TEXT !default;
$price-box-body-content-item-spacing:          null !default;

$price-box-price-font-size:                    map-get($FS5, null) !default;
$price-box-price-font-size-small:              map-get($FS4, null) !default;
$price-box-price-line-height:                  1 !default;
$price-box-price-font-weight:                  $GLOBAL-FONT-WEIGHT-BOLD !default;
$price-box-price-spacing-vertical:             $GLOBAL-SPACING-UNIT-XXSMALL !default;
$price-box-price-color:                        null !default;

$price-box-discount-value-spacing:             $GLOBAL-SPACING-UNIT-XSMALL !default;

$price-box-discount-old-price-font-size:       $FS1 !default;

// Magic number
$price-box-text-after-price-margin-top:        -0.15em !default;

$price-box-additional-info-margin-top:         $GLOBAL-SPACING-UNIT-XXSMALL !default;
$price-box-additional-info-font-size:          $FS-1 !default;
$price-box-additional-info-font-weight:        null !default;
$price-box-additional-info-color:              null !default;
$price-box-additional-info-max-length:         50ch !default;

/**
 * [1] Reset default margin.
 * [2] Ensure crossed-out text if `<s>` is not used or
 *     User-Agent styles are not applied.
 * [3] Ensure price not getting wrapped.
 * [4] Limit the additional info text-length when container
 *     is displayed with a large width to
    *     a) improve readability
    *     b) keep "visual connection" to price information above
 * [5] Allow wrapping in case discount value & price can not be displayed
 *     side-by-side.
 * [6] Wrap text in extra container to avoid overflow and still maintain
 *     text limitation via `max-length`.
 * [7] Set `max-width: 100%` to avoid text overflow.
 *     https://github.com/philipwalton/flexbugs#flexbug-2
 * [8] Conditionally output only shadow (overwrite) when shadow is set initially
 *     set in base variant.
 * [9] It’s easier to control elements in `.c-price-box__body__content` as
 *      flex items (e.g. `margin` for `span`s).
 * [10] Shift the text just a little bit upwards towards the price. We are
 *      using the Sass `or` operator here to avoid a long ternary condition.
 * [11] Consistent vertical spacing between body content items.
 * [12] Reduce spacing and font-size when container has a small width.
 * [13] Reset spacing and font-size when container has a bigger width.
 */
.c-price-box {
    --container-widths: "#{cq-convert-data($price-box-width-queries)}";

    border: $price-box-border;
    box-shadow: $price-box-shadow;

    @include container($map: $price-box-width-queries, $size: "small") {
        .c-price-box__body {
            padding: $price-box-body-padding-small; /* [12] */
        }

        .c-price-box__price {
            @include font-size(
                $font-size:   $price-box-price-font-size-small,
                $line-height: $price-box-price-line-height,
            ); /* [12] */
        }

    }

    @include container($map: $price-box-width-queries, $size: "medium") {
        .c-price-box__header {
            @include font-size($price-box-header-font-size);
        }

        .c-price-box__body {
            padding: $price-box-body-padding; /* [13] */
        }

        .c-price-box__price {
            @include font-size(
                $font-size: $price-box-price-font-size,/* [13] */
                $line-height: $price-box-price-line-height,
            );
        }
    }

    &.c-price-box--integrated {
        box-shadow: if($price-box-shadow, $price-box-shadow-integrated, null); /* [8] */

        @if ($price-box-border-integrated == false) {
            border: none;
        } @else {
            border: $price-box-border-integrated;
        }

    }

}

    .c-price-box__header {
        padding: $price-box-header-padding-vertical $price-box-header-padding-horizontal;
        border-bottom: $price-box-header-border;
        font-family: $price-box-header-font-family;
        font-weight: $price-box-header-font-weight;
        text-align: center;
        text-transform: $price-box-header-text-transform;
        background-color: $price-box-header-background-color;
        color: $price-box-header-color;

        .c-price-box--integrated & {
            border-bottom: $price-box-header-border-integrated;
            background-color: $price-box-header-background-color-integrated;
            color: $price-box-header-color-integrated;
        }

    }

    .c-price-box__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-color: $price-box-body-background-color;
        color: $price-box-body-color;

        .c-price-box--integrated & {
            background-color: $price-box-body-background-color-integrated;
        }

    }

        .c-price-box__body__content {
            display: flex; /* [9] */
            gap: $price-box-body-content-item-spacing; /* [11] */
            flex-direction: column; /* [9] */
            text-align: left;
        }

    .c-price-box__discount {
        display: flex;
        gap: $price-box-discount-value-spacing;
        flex-wrap: wrap; /* [5] */
        align-items: center;
        margin-bottom: $price-box-price-spacing-vertical;
    }

        .c-price-box__discount__old-price {
            @include responsive-font-size($price-box-discount-old-price-font-size);
            text-decoration: line-through; /* [2] */
        }

    .c-price-box__price {
        display: block;
        font-weight: $price-box-price-font-weight;
        color: $price-box-price-color;
    }

    .c-price-box__price,
    .c-price-box__discount__old-price {
        white-space: nowrap; /* [3] */
    }

    .c-price-box__text {

        .c-price-box__price ~ & {
            margin-top: ($price-box-text-after-price-margin-top or null); /* [10] */
        }

    }

    .c-price-box__additional-info {
        max-width: 100%; /* [7] */
        margin-top: $price-box-additional-info-margin-top;
    }

        /* [6] */
        .c-price-box__additional-info__text {
            @include responsive-font-size($price-box-additional-info-font-size);
            max-width: $price-box-additional-info-max-length; /* [4] */
            font-weight: $price-box-additional-info-font-weight;
            text-align: left;
            color: $price-box-additional-info-color;

            &:last-child {
                margin-bottom: 0; /* [1] */
            }

        }
