/* ------------------------------------*\
    #STRONG (`<b>`, `<strong>`)
\*------------------------------------ */

$element-bold-font-weight: $GLOBAL-FONT-WEIGHT-BOLD !default;

/**
 * [1] Set/Overwrite weight that is set by `normalize.css`
 */
b,
strong {
    font-weight: $element-bold-font-weight; /* [1] */
}
