/* ------------------------------------*\
    #BLOCKQUOTE
\*------------------------------------ */

$blockquote-text-size:               $FS3 !default;
$blockquote-text-line-height:        null !default;
$blockquote-text-color:              null !default;
$blockquote-text-style:              null !default;
$blockquote-text-font-family:        null !default;

$blockquote-footer-text-size:        $FS-1 !default;
$blockquote-footer-text-line-height: null !default;
$blockquote-footer-text-color:       null !default;
$blockquote-footer-text-style:       null !default;
$blockquote-footer-font-family:      null !default;

$blockquote-footer-cite-text-style:  normal !default;

$blockquote-themes: (
    "alternative": (
        "blockquote-text-color":        null,
        "blockquote-footer-text-color": null,
    ),
    "brand": (
        "blockquote-text-color":        null,
        "blockquote-footer-text-color": null,
    )
) !default;

.c-blockquote {
    margin-bottom: 0;
}

    .c-blockquote__text {
        @include responsive-font-size(
            $font-size:   $blockquote-text-size,
            $line-height: $blockquote-text-line-height,
        );
        font-family: $blockquote-text-font-family;
        font-style: $blockquote-text-style;
        color: $blockquote-text-color;

        @include vary($loop: $blockquote-themes) {
            color: vary-get("blockquote-text-color");
        }
    }

    .c-blockquote__footer {
        @include responsive-font-size(
            $font-size:   $blockquote-footer-text-size,
            $line-height: $blockquote-footer-text-line-height,
        );
        font-family: $blockquote-footer-font-family;
        font-style: $blockquote-footer-text-style;
        color: $blockquote-footer-text-color;

        @include vary($loop: $blockquote-themes) {
            color: vary-get("blockquote-footer-text-color");
        }
    }

    .c-blockquote__cite {
        font-style: $blockquote-footer-cite-text-style;
    }
