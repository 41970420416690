// /* ------------------------------------*\
//     #ICON-FONTS
// \*------------------------------------ */

// @sass-export-section="iconFontSettings"
$ICON-FONT: "bronson-skoda" !default;

// This creates size-modifiers. The key is used as a modifier for changing the
// icon-size, e.g. `.c-icon.c-icon--small`.
// 'medium' is the default and no modifier is created (no `.c-icon--medium`).
$ICON-SIZES: (
    xxsmall: 20px,
    xsmall:  24px,
    small:   32px,
    medium:  40px,
    large:   48px,
    xlarge:  64px,
    xxlarge: 96px,
) !default;



// Provide a map with sizes for round icons. Its values are the overall size
// of the icon pattern.
$ICON-ROUND-FULL-SIZES: (
    xxsmall: 32px,
    xsmall:  32px,
    small:   40px,
    medium:  44px,
    large:   56px,
    xlarge:  68px,
    xxlarge: 104px,
) !default;

// Provide a map with sizes for square icons. Its values are the overall size
// of the icon pattern.
$ICON-SQUARE-FULL-SIZES: (
    xxsmall: 32px,
    xsmall:  32px,
    small:   40px,
    medium:  44px,
    large:   56px,
    xlarge:  68px,
    xxlarge: 104px,
) !default;



// General icon styles.
$ICON-COLOR: currentColor !default;

// Icon border styles.
$ICON-BORDER-WIDTH: 1px !default;
$ICON-BORDER-STYLE: solid !default;
$ICON-BORDER-COLOR: currentColor !default;

// Square icon styles.
$ICON-SQUARE-RADIUS: 0 !default;

// Round icon styles.
$ICON-ROUND-RADIUS: 50% !default;



/// To include different complexities, enable this variable by providing a map.
/// The list entries are the file-name's suffixes.
/// To disable function, set value to `null`.
///
/// If complexities are set, only `simple` and `complex` are allowed.
///
/// @type List
$ICON-COMPLEXITIES: null !default;

/// The complexity can also be combined with the size, i.e. when assigning a
/// particular size modifier in the markup, the different complexity gets also
/// applied automatically.
///
/// @access public
/// @type Boolean
$ICON-ENABLE-SIZE-COMPLEXITY-COMBO: false !default;

/// This generates classes with the complexity as a modifier to force the
/// chosen complexity.
///
/// @access public
/// @type Boolean
$ICON-ENABLE-COMPLEXITY-CLASS-OVERWRITE: false !default;

/// Combine sizes with complexities (key: size, value: complexity).
///
/// @access public
/// @requires `$ICON-ENABLE-SIZE-COMPLEXITY-COMBO` to be `true`.
/// @type Map
$ICON-SIZE-TO-COMPLEXITY-MAPPING: null !default;



// Name : prefix.
$ICON-SUBSETS: (
    "semantic": "semantic-",
    "brand":  "content-",
) !default;

// @end-sass-export-section
