// /* ------------------------------------*\
//     #EXPANDABLE CONTENT (Settings)
// \*------------------------------------ */

$expandable-content-trigger-text-color:           $COLOR-BASE-TEXT !default;
$expandable-content-trigger-close-spacing-bottom: $GLOBAL-SPACING-UNIT-SMALL !default;

$expandable-content-icon-color:                   $expandable-content-trigger-text-color !default;

$expandable-content-background-color:             $COLOR-SURFACE-PRIMARY !default;

// The vertical padding corresponds to the page wrap padding. However, in order
// to keep it from being too small in small viewports, this value is multiplied
// by `1.5` (a magic number that works quite well here). The maximum value will
// never exceed `$GLOBAL-SPACING-UNIT`.
$expandable-content-content-padding-vertical:     min($GLOBAL-SPACING-UNIT, var(--page-wrap) * 1.5) !default;
