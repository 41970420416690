// /* ------------------------------------*\
//     #BASE SETTINGS
// \*------------------------------------ */

$GLOBAL-FONT-SIZE:            18px !default;

$GLOBAL-BASELINE:             8px !default;

/**
 * Spacings are required settings for all brands (XXSMALL - XXLARGE).
 * - Required to have all scales available in all brands (multibrand & one-markup-approach).
 * - Used for generating of spacing utilities.
 */

$SCALE-FACTOR-XXSMALL:        0.5 !default;
$SCALE-FACTOR-XSMALL:         1 !default;
$SCALE-FACTOR-SMALL:          2 !default;
$SCALE-FACTOR:                3 !default;
$SCALE-FACTOR-LARGE:          4 !default;
$SCALE-FACTOR-XLARGE:         8 !default;
$SCALE-FACTOR-XXLARGE:        $SCALE-FACTOR-XLARGE !default; //fallback to XLARGE if not defined in sub-brands


$GLOBAL-SPACING-UNIT:         $GLOBAL-BASELINE * $SCALE-FACTOR !default;
$GLOBAL-SPACING-UNIT-XXSMALL: $GLOBAL-BASELINE * $SCALE-FACTOR-XXSMALL !default;
$GLOBAL-SPACING-UNIT-XSMALL:  $GLOBAL-BASELINE * $SCALE-FACTOR-XSMALL !default;
$GLOBAL-SPACING-UNIT-SMALL:   $GLOBAL-BASELINE * $SCALE-FACTOR-SMALL !default;
$GLOBAL-SPACING-UNIT-LARGE:   $GLOBAL-BASELINE * $SCALE-FACTOR-LARGE !default;
$GLOBAL-SPACING-UNIT-XLARGE:  $GLOBAL-BASELINE * $SCALE-FACTOR-XLARGE !default;
$GLOBAL-SPACING-UNIT-XXLARGE: $GLOBAL-BASELINE * $SCALE-FACTOR-XXLARGE !default; // not available in _utilities.spacings.settings.scss

$GLOBAL-LINE-HEIGHT:          $GLOBAL-SPACING-UNIT !default;

$GLOBAL-BORDER-RADIUS:        0 !default;

$GLOBAL-ARROW-SIZE:           10px !default;
