/* ------------------------------------*\
    #PRODUCT-INFO-SUMMARY (Scopes)
\*------------------------------------ */

/**
 * [1] Styling of a product info summary in a fold out.
 */
.c-product-info-summary {

    .c-product-info-summary__heading,
    .c-product-info-summary__img,
    .c-product-info-summary__info {

        .c-fold-out__header#{$fold-out-header-open-state} & {
            display: none;
        }

    }

}
