/* ------------------------------------*\
    #MERCURY-CHAT-BOT
\*------------------------------------ */

$mercury-grid-area: bottom-right !default;

/**
 * [1] Move to the first Float Grid area items position.
 */

/* stylelint-disable selector-max-specificity, selector-max-id, itcss/no-at-important */
.js-enable-3rd-party-float-grid {

    #mercury-widget {
        @include float-grid-area(
            $area: $mercury-grid-area,
        );

        display: contents;

        .teaser,
        .toggle-button {
            @extend %float-grid-area-offset;
            order: -1; /* [1] */
        }

        .teaser,
        .open-toggle {
            position: static !important;
            margin: 0 !important;
        }
    }

}
/* stylelint-enable selector-max-specificity, selector-max-id, itcss/no-at-important */
